/* eslint-disable import/no-cycle */
import React, { useMemo, useState } from 'react';
import { Divider } from 'antd';
import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';

import { TitleH4 } from 'styles/v3/variables';

import FilledButton from 'components/v3/Buttons/FilledButton';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { Container, Footer, FooterTitle } from './styles';
import { Filters, FiltersDetailed } from './components/Filters/Filters';
import { UserInfoApply } from './components/UserInfoAppy';
import { ViewUsersSelected } from './components/ViewUsersSelected';

interface ModalBodyDivisionProps {
  userType: UserType;
  members: User[];
  selectedUsers: string[];
  savedUsers: string[];
  handleSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  handleUpdateUsers: () => Promise<void>;
}

export enum SideModalRostersSelected {
  DEFAULT = 'DEFAULT',
  FILTERS = 'FILTERS',
  SELECTED = 'SELECTED',
}

const ModalBody: React.FC<ModalBodyDivisionProps> = ({
  userType,
  members,
  selectedUsers,
  savedUsers,
  handleSelectedUsers,
  handleUpdateUsers,
}) => {
  const [filterDetailed, setFilterDetailed] = useState([
    { value: 'Gender', label: 'Genders' },
    { value: 'Type', label: 'Types' },
    { value: 'Year', label: 'Years' },
    { value: 'Status', label: 'Status' },
  ]);

  const [openSideModal, setOpenSideModal] = useState<SideModalRostersSelected>(
    SideModalRostersSelected.DEFAULT
  );

  const handleSelected = (idUser: string) => {
    if (selectedUsers?.find((id) => id === idUser)) {
      const updateUsers = selectedUsers?.filter((id) => id !== idUser);
      handleSelectedUsers(updateUsers);
    } else {
      handleSelectedUsers((oldSelected) => [...oldSelected, idUser]);
    }
  };

  const handleFilter = (filterBy: string) => {};

  const membersForRoster = useMemo(() => {
    if (!members?.length) return [];


    let allMembers = members.map((member) => member).filter(m =>  !savedUsers.includes(m.id) );

    if (filterDetailed[0].value !== 'Gender') {
      allMembers = allMembers.filter((member) =>
        filterDetailed[0].value.includes(member.gender as string)
      );
    }

    if (filterDetailed[1].value !== 'Type') {
      allMembers = allMembers.filter((member) =>
          filterDetailed[1].value.includes(member.type?.toString() as string)
        );
    }

    if (filterDetailed[2].value !== 'Year') {
      allMembers = allMembers.filter((member) => {
        const year = new Date(member.birthDate as string).getFullYear().toString();

        return filterDetailed[2].value.includes(year);
      }
      );
    }

    if (filterDetailed[3].value !== 'Status') {
      allMembers = allMembers.filter((member) =>
        filterDetailed[3].value.includes(member.documentationVerified?.toString() as string)
      );
    }

    return allMembers;
  }, [filterDetailed, members, setFilterDetailed, savedUsers]);

  const whichIsOpen = () => {
    if (openSideModal === SideModalRostersSelected.SELECTED) {
      const selectedMembers = members.filter((member) =>
        selectedUsers.includes(member.id)
      );
      return (
        <ViewUsersSelected
          users={selectedMembers}
          userType={userType}
          handleUpdateUsers={handleUpdateUsers}
          handleSelected={handleSelected}
          handleGoBack={() =>
            setOpenSideModal(SideModalRostersSelected.DEFAULT)
          }
        />
      );
    }

    if (openSideModal === SideModalRostersSelected.FILTERS) {
      return (
        <FiltersDetailed
          handleGoBack={() =>
            setOpenSideModal(SideModalRostersSelected.DEFAULT)
          }
          usersToFilter={members}
          filterDetailed={filterDetailed}
          setFilterDetailed={setFilterDetailed}
        />
      );
    }

    return (
      <Container>
        <Filters
          handleChange={handleFilter}
          handleDetailedFilters={() =>
            setOpenSideModal(SideModalRostersSelected.FILTERS)
          }
        />

        {membersForRoster.map((user) => (
          <UserInfoApply
            key={`key_for_${user.id}`}
            user={user}
            isUserSelected={
              !!selectedUsers.find((userSelected) => userSelected === user.id)
            }
            handleSelected={handleSelected}
          />
        ))}

        <Divider style={{ margin: 0, marginTop: 100 }} />
        <Footer>
          <FooterTitle>
            <TitleH4>
              {
                members.filter((member) => selectedUsers.includes(member.id))
                  .length
              }{' '}
              {userType} selected
            </TitleH4>
            <TextButtonWithIcon
              customIcon='Right'
              color='grey400'
              align='flex-end'
              isUpper
              isBold={false}
              onClick={() =>
                setOpenSideModal(SideModalRostersSelected.SELECTED)
              }
            >
              {userType === UserType.COACH ? 'View Coaches' : 'View players'}
            </TextButtonWithIcon>
          </FooterTitle>
          <FilledButton onClick={handleUpdateUsers} isUpper isBold={false}>
            {userType === UserType.COACH ? 'Add Coaches To Team' : 'Add Players To Roster'}
          </FilledButton>
        </Footer>
      </Container>
    );
  };

  return whichIsOpen();
};

export default ModalBody;
