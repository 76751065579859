import React, { useCallback, useState } from 'react';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { BodyM, BodyMBold, TitleH2, TitleH4 } from 'styles/v3/variables';
import InputText from 'components/v3/Forms/InputText/InputText';
import { Dropdown, Menu, Pagination } from 'antd';
import { Down, Mail } from '@icon-park/react';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { useGetPaginatedClubs } from 'hooks/v3/clubs/useGetClubs/useGetClubs';
import { useHistory, useParams } from 'react-router-dom';
import { useFilterClubs } from 'pages/V3/Invites/hooks';
import { notification } from 'components/v3/Notification/notification';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { useInviteByEvent } from 'hooks/v3/event/useInviteByEvent/useInviteByEvent';
import { Card, SimpleCard } from '../../../components';
import { NameWithRole } from '../NameWithRole/NameWithRole';
import * as S from './styles';

const INIT_PAGE = 1;
const INIT_SIZE = 4;

export const InviteByList = () => {
  const history = useHistory();
  const params: { eventId?: string } = useParams();
  const { mutateAsync } = useInviteByEvent();
  const [currentPage, setCurrentPage] = useState(INIT_PAGE);
  const [pageSize, setPageSize] = useState(INIT_SIZE);

  const [clubName, setClubName] = useState('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedMembersCount, setSelectedMembersCount] = useState<number>();
  const [selectedClubs, setSelectedClubs] = useState<string[]>([]);
  
  const { data } = useGetPaginatedClubs({
    filters: {
      pageSize,
      page: currentPage - 1,
      name: clubName 
    }
  });

  const cities = Array.from(
    new Set(data?.content?.map((item) => item.city))
  );

  const totalMembers = Array.from(
    new Set(data?.content?.map((item) => item.totalMembers))
  );

  const selectedClubsData = data?.content?.filter((item) =>
    selectedClubs.includes(item.id)
  );

  const filteredClubs = useFilterClubs({
    clubName,
    selectedCity,
    selectedMembersCount,
    clubs: data?.content,
  });

  const handlePaginationChange = useCallback((page, size) => {
    setPageSize(size);
    setCurrentPage(page);
   }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleRemoveCard = (id: string) => {
    setSelectedClubs(selectedClubs?.filter((clubId) => clubId !== id));
  };

  const handleCardSelect = (id: string) => {
    if (selectedClubs.includes(id)) {
      handleRemoveCard(id);
    } else {
      setSelectedClubs([...selectedClubs, id]);
    }
  };

  const handleSendInvites = async () => {
    if (!selectedClubs.length) {
      notification.error({
        message: 'An error occurs!',
        description:
          'Please select at least one club to send the invitation to',
      });
      return;
    }

    if (params.eventId) {
      const payload = {
        eventId: params.eventId,
        receiversIds: selectedClubs,
        type: EventInvitesTypes.EVENT_TO_CLUB,
      };

      await mutateAsync(payload);

      history.push(`/v3/event/${params.eventId}/dashboard`);
    }
  };

  return (
    <S.Container>
      <S.FindWrapper>
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $color='brandPrimary'>Find Clubs for your event</TitleH2>
        <InputText
          id='search'
          icon='Search'
          value={clubName}
          onChange={({ target }) => setClubName(target.value)}
          placeholder='Search by Club name or Club Director name'
        />
        <S.FilterWrapper>
          <BodyMBold $isUpper $color='grey500'>
            Filter by
          </BodyMBold>
          <Dropdown
            overlay={
              <Menu
                selectable
                onSelect={({ key }) => setSelectedCity(key.toString())}
              >
                {cities?.map((city) => (
                  <Menu.Item key={city}>
                    <BodyM>{city}</BodyM>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <S.FilterText>
              <BodyM $isUpper>City</BodyM>
              <Down size={28} />
            </S.FilterText>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu
                selectable
                onSelect={({ key }) => setSelectedMembersCount(Number(key))}
              >
                {totalMembers?.map((count) => (
                  <Menu.Item key={count}>
                    <BodyM>{count}</BodyM>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <S.FilterText>
              <BodyM $isUpper>Number of members</BodyM>
              <Down size={28} />
            </S.FilterText>
          </Dropdown>
        </S.FilterWrapper>
        {!filteredClubs?.length ? (
          <S.FallbackMessageWrapper>
            <TitleH4 $isUpper $color='grey700'>
              didn&apos;t find the club you were looking for?
            </TitleH4>
            <TitleH4
              $color='grey500'
              onClick={() => history.push('/v3/invites/email')}
            >
              Invite via email
            </TitleH4>
          </S.FallbackMessageWrapper>
        ) : (
            <S.ClubsListWrapper>
              {filteredClubs?.map((item) => (
                <Card
                  id={item.id}
                  key={item.id}
                  name={item.name}
                  logoSrc={item.logo}
                  onSelect={handleCardSelect}
                  address={`${item.city} - ${item.state}`}
                  selected={selectedClubs.includes(item.id)}
                  extraBasicInfo={
                    <NameWithRole
                      name={item.directorInfo?.name}
                      roleName={item.directorInfo?.type}
                    />
                  }
                  extraAddressInfo={
                    <BodyM $color='grey400'>{item.totalMembers} Members</BodyM>
                  }
                />
              ))}
          </S.ClubsListWrapper>
        )}
        {!!data?.total &&
          <S.WrapperPagination>
            <Pagination
              total={data.total}
              defaultPageSize={pageSize}
              current={currentPage}
              showSizeChanger={true}
              pageSizeOptions={['4','10','50', '100']}
              onChange={handlePaginationChange}
            />
          </S.WrapperPagination>
        }
      </S.FindWrapper>
      <S.InviteListWrapper>
        <S.InviteListMain>
          <TitleH4 $isUpper>Invite list</TitleH4>
          <BodyM $color='grey400'>
            If you invite teams that have already been invited, they will be
            notified again.
          </BodyM>
          <S.SimpleCardList>
            {selectedClubsData?.map((item) => (
              <SimpleCard
                id={item.id}
                key={item.id}
                name={item.name}
                imgSrc={item.logo}
                handleRemoveCard={handleRemoveCard}
                address={`${item.city} - ${item.state}`}
              />
            ))}
          </S.SimpleCardList>
          <FilledButton isUpper isBold={false} onClick={handleSendInvites}>
            Send invites
          </FilledButton>
        </S.InviteListMain>
        <S.InviteEmailWrapper>
          <BodyM $isUpper>Invite via email</BodyM>
          <Mail size={24} />
        </S.InviteEmailWrapper>
      </S.InviteListWrapper>
    </S.Container>
  );
};
