import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid ${COLORS.brandPrimary};
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${COLORS.grey400};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span`
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.grey200};
`;
