/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';

import { useParams } from 'react-router-dom';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import { toRem } from 'styles/v3/functions';
import { BodyL, BodyS, BodyMBold } from 'styles/v3/variables';
import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { useQueryClient } from '@tanstack/react-query';
import { TeamInformation } from './components/TeamInformation';
import { DivisionAndClubsState } from '../..';

import {
  Container,
  GeneralInfoContainer,
  GeneralInfoHeader,
  ApplicationStatusContainer,
  DivisionContentDatas,
  DivisionData,
  TeamsContainer,
  TeamsList,
} from './styles';

interface ModalBodyDivisionProps {
  divisionAndClubs: DivisionAndClubsState;
  allDivisions: NewAgeDivision[];
}

const ModalBody: React.FC<ModalBodyDivisionProps> = ({
  allDivisions,
  divisionAndClubs,
}) => {
  const queryClient = useQueryClient();

  const { division, divisionOrder, clubInformation } = divisionAndClubs;

  const [lockApplications, setLockApplications] = useState(!!division.locked);

  const { eventId } = useParams<{ eventId: string }>();

  const { mutateAsync } = useUpdateEvent({
    eventId,
  });

  const teamsApplications = divisionOrder.reduce(
    (acc, order) => acc + order.quantity,
    0
  );

  const totalReceived = divisionOrder.reduce(
    (acc, order) => acc + order.total,
    0
  );

  const teamsInformation = divisionOrder.map((order) => {
    const teamClub = clubInformation.find((club) => club.id === order.clubId);

    return {
      clubLogo: teamClub?.logo,
      clubName: teamClub?.name,
      quantityTeams: order.quantity,
    };
  });

  const handleChangeApplication = async () => {
    setLockApplications((prev) => !prev);
    try {
      const updatedDivision = { ...division, locked: !lockApplications };

      const payload: DeepPartial<PatchEventProps> = {
        ageDivisions: allDivisions.map((item) =>
          item.id === division.id ? updatedDivision : item
        ),
      };

      await mutateAsync({ id: eventId, data: payload });
      await queryClient.invalidateQueries(['event-divisions', eventId]);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
  };

  const percentage = (partialValue: number, totalValue: number) => {
    return ((100 * partialValue) / totalValue).toFixed(0);
  };

  useEffect(() => {
    setLockApplications(!!division.locked);
  }, [division.locked]);

  return (
    <Container>
      <GeneralInfoContainer>
        <GeneralInfoHeader>
          <BodyL $color='grey50'>General Info</BodyL>
          <ApplicationStatusContainer>
            <Switch
              size='small'
              checked={lockApplications}
              onChange={handleChangeApplication}
            />
            <BodyS $color={lockApplications ? 'brandPrimary' : 'grey500'}>
              Application {lockApplications ? 'Closed' : 'Open'}
            </BodyS>
          </ApplicationStatusContainer>
        </GeneralInfoHeader>
        <DivisionContentDatas>
          <DivisionData>
            <BodyS $color='grey300'>Occupation Rate</BodyS>
            <BodyMBold $color='grey300'>
              {percentage(
                teamsApplications,
                division.targetNumberOfTeams as number
              )}
              %
            </BodyMBold>
          </DivisionData>

          <DivisionData>
            <BodyS $color='grey300'>Teams / Target</BodyS>
            <BodyMBold $color='grey300'>
              {teamsApplications} / {division.targetNumberOfTeams}
            </BodyMBold>
          </DivisionData>

          <DivisionData>
            <BodyS $color='grey300'>Total Received</BodyS>
            <BodyMBold $color='grey300'>${totalReceived}</BodyMBold>
          </DivisionData>
        </DivisionContentDatas>
      </GeneralInfoContainer>
      {teamsInformation.length ? (
        <TeamsContainer>
          <BodyL $color='grey50'>Teams</BodyL>
          <BodyS
            $isUpper
            $color='grey400'
            style={{ marginTop: toRem(24), marginBottom: toRem(24) }}
          >
            Team name
          </BodyS>

          <TeamsList>
            {teamsInformation.map((information) => {
              const foo = new Array(information.quantityTeams).fill(0);
              return foo.map((_, index) => {
                const clubName =
                  information.quantityTeams > 1
                    ? `${information.clubName} - Team ${index + 1}`
                    : `${information.clubName}`;
                return (
                  <TeamInformation
                    key='test'
                    logo={information.clubLogo as string}
                    teamName={clubName}
                  />
                );
              });
            })}
          </TeamsList>
        </TeamsContainer>
      ) : null}
    </Container>
  );
};

export default ModalBody;
