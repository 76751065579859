import { Modal } from 'antd';

import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

interface RoleCard {
  isEnabled?: boolean;
}

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: ${toRem(468)};
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey950};

    button {
      margin-bottom: 0;
    }

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      border-radius: 0;
      border-top-left-radius: ${toRem(24)};
      border-top-right-radius: ${toRem(24)};
    }
  }

  .ant-modal-header {
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: ${toRem(24)};
    padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(12)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(32)};
`;

export const RolesWrapper = styled.div`
  display: flex;
  gap: ${toRem(18)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;
    gap: ${toRem(18)};
  }
`;

export const RoleCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${toRem(200)};
  height: ${toRem(200)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey950};
  border: ${toRem(1)} solid ${COLORS.grey50};
  transition-duration: 300ms;
  user-select: none;
  cursor: pointer;

  &:not(.disabled):hover {
    transform: scale(1.05);
    color: ${COLORS.brandPrimary};
    background-color: rgba(202, 252, 1, 0.3);
    border-color: ${COLORS.brandPrimary};
  }

  &.disabled {
    background-color: ${COLORS.grey900};
    border-color: ${COLORS.grey600};
    opacity: 0.4;
    cursor: unset;
  }

  p {
    text-transform: capitalize;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    height: ${toRem(150)};
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${toRem(38)};
  height: ${toRem(38)};
  border: none;
  box-shadow: none;
  border-radius: ${toRem(10)};
  background-color: ${COLORS.grey700};
  cursor: pointer;
  transition: all 0.3s;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0 !important;
  }
`;
