import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

import { PenaltyState } from './enums';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 32px;
`;

export const RosterUserListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 12px;
    background: linear-gradient(to left, #252622, transparent);
    z-index: 1;
  }

  &.is-right {
    &:before {
      right: unset;
      left: 0;
      background: linear-gradient(to right, #252622, transparent);
    }
  }
`;

export const RosterUserList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 12px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  &.is-right {
    padding-right: 0;
    padding-left: 12px;
    direction: rtl;
  }
`;

export const GoalItem =
  styled.div <
  { $state: PenaltyState } >
  `
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Boxed Regular';
  font-size: 14px;
  min-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${COLORS.grey400};
  color: ${COLORS.white};

  ${({ $state }) => {
    if ($state === PenaltyState.Goal) {
      return `
        border-color: #0ca32b;
        background-color: #0ca32b;
      `;
    }

    if ($state === PenaltyState.Miss) {
      return `
        border-color: #d30707;
        background-color: #d30707;
      `;
    }

    return ``;
  }}
`;

export const Score = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  min-width: 120px;
  font-family: 'Boxed Regular';
  font-size: 16px;

  span {
    display: block;
    min-width: 40px;
    font-family: 'Boxed Regular Bold';
    font-size: 26px;
    color: ${COLORS.white};
    text-align: center;
  }
`;
