import React from 'react';
import ico_print from 'assets/imgs/icons/ico_printing.svg';
import { PrintButtonProps } from './types';

import * as S from './styles';

export const PrintButton: React.FC<PrintButtonProps> = ({
  text: customText,
  handlePrint: customHandlePrint,
  ...props
}) => {
  const handlePrint = () => {
    if (customHandlePrint) {
      customHandlePrint();
    } else {
      window.print();
    }
  };

  const text = customText || 'Print Game Sheet';

  return (
    <S.Container
      onClick={handlePrint}
      onKeyDown={handlePrint}
      role='button'
      aria-label={text}
      tabIndex={0}
    >
      <S.Icon src={ico_print} alt='Print' />
      <S.Title>{text}</S.Title>
    </S.Container>
  );
};
