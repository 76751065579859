import styled from 'styled-components';
import { Modal } from 'antd';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { BREAKPOINTS } from 'styles/v3/constants';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 24px;
    background-color: ${COLORS.grey900};

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      border-radius: 0px;
      border-top-left-radius: ${toRem(24)};
      border-top-right-radius: ${toRem(24)};
    }
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${toRem(38)};
  height: ${toRem(38)};
  border: none;
  box-shadow: none;
  border-radius: ${toRem(10)};
  background-color: ${COLORS.grey700};
  cursor: pointer;
  transition: all 0.3s;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(8)};
  padding-top: ${toRem(16)};
`;

export const TextInformation = styled.div`
  margin-bottom: ${toRem(8)};
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(16)};
  padding: ${toRem(16)};

  background-color: ${COLORS.grey950};
  border-radius: ${toRem(8)};
`;
