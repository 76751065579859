import React, { useState } from 'react';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { notification } from 'components/v3/Notification/notification';

import OrderService from 'services/v3/Order/OrderService';
import { UpgradeMembershipButtonProps } from './types';
import OutlinedButton from '../OutlinedButton';

export const UpgradeMembershipButton = ({
  cancelUrl,
  successUrl,
  userIds,
  text: buttonText,
  isDisabled = false,
  type,
  buttonProps,
}: UpgradeMembershipButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckout = async () => {
    if (userIds.length === 0 || isLoading || isDisabled) return;

    setIsLoading(true);

    OrderService.getMembershipCheckoutStripeUrl({
      userIds,
      cancelUrl: `${cancelUrl}?checkout=error`,
      successUrl: `${successUrl}?checkout=success`,
    })
      .then((res) => {
        window.location.href = res.data.paymentUrl;
      })
      .catch((err) => {
        notification.error({
          duration: 4,
          message: 'Error',
          description:
            err?.message ||
            'Error creating checkout on stripe. Please try again',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ChosenButton = type === 'outlined' ? OutlinedButton : FilledButton;

  return (
    <ChosenButton
      {...buttonProps}
      disabled={isLoading || isDisabled}
      onClick={handleCheckout}
    >
      {isLoading ? 'Processing...' : buttonText}
    </ChosenButton>
  );
};
