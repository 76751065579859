import React, {
  memo,
  FC,
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';
import { CloseSmall } from '@icon-park/react';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IReportModalProps {
  visible: boolean;
  defaultReport: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const ReportModal: FC<IReportModalProps> = memo(
  ({ visible, defaultReport, onClose, onSubmit }) => {
    const [reportValue, setReportValue] = useState('');

    const handleChangeReport = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        setReportValue(event.currentTarget.value);
      },
      []
    );

    const handleSubmitForm = useCallback(() => {
      onSubmit(reportValue);
      onClose();
      setReportValue('');
    }, [reportValue, onSubmit, onClose]);

    const handleClose = useCallback(() => {
      onClose();
      setReportValue(defaultReport);
    }, [defaultReport, onClose]);

    useEffect(() => {
      setReportValue(defaultReport);
    }, [defaultReport]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={handleClose}
      >
        <S.Header>
          <BodyM $color='grey50'>Game Report</BodyM>
          <S.CloseButton onClick={handleClose}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.Header>
        <S.Container>
          <S.TextareaField
            id='report'
            multiline
            rows={8}
            value={reportValue}
            placeholder='Write your game report here...'
            onChange={handleChangeReport}
          />
          <S.ReportFilledButton
            isUpper
            $disabled={reportValue.length < 3}
            onClick={handleSubmitForm}
          >
            Save report
          </S.ReportFilledButton>
        </S.Container>
      </S.StyledModal>
    );
  }
);

ReportModal.displayName = 'ReportModal';
