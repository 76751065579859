import { MatchEventModel } from 'models/Match/MatchModel';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function formatDateToString(inputDate: Date | string): string {
  const myDate = new Date(inputDate);
  const day = myDate.getDate();

  const month = months[myDate.getMonth()];
  const suffix = getNumberSuffix(day);

  return `${month} ${day}${suffix}`;
}

function getNumberSuffix(day: number) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }

  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function formatTimeAsString(date: string, showMinute = false) {
  const regex = /^\d{4}-\d{2}-\d{2}T(\d{2}):(\d{2})/;
  const match = date.match(regex);
  const mHours = match ? Number(match[1]) : undefined;
  const mMinutes = match ? Number(match[2]) : undefined;
  const myDate = new Date(date);
  const hours = mHours ?? myDate.getHours();
  const minutes = mMinutes ?? myDate.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}${
    showMinute ? `:${formattedMinutes}` : ''
  } ${period}`;
}

export function countTeamAppearances(
  matches: MatchEventModel[]
): Record<string, number> {
  const teamCounts: Record<string, number> = {};

  matches.forEach((match) => {
    const homeId = match?.homeTeam?.clubId;
    const awayId = match?.awayTeam?.clubId;

    teamCounts[homeId] = (teamCounts[homeId] || 0) + 1;

    if (homeId !== awayId) {
      teamCounts[awayId] = (teamCounts[awayId] || 0) + 1;
    }
  });

  return teamCounts;
}

export const mapTimeToPosition = (time: string, stepperIntervals: number) => {
  const [hoursStr, minutesStr] = time.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const totalMinutes = hours * 60 + minutes;
  const position = totalMinutes / stepperIntervals;

  return position;
};
