import { AxiosRequestConfig } from 'axios';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import { CouponView } from './types';

class CouponService {
  public static async getProductCoupons(
    eventId: string, productId?: string
  ): Promise<ServiceResponseV3<CouponView[]>> {

    if(!productId) {
      return ServiceResponseV3.success([], '');
    }

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/coupons/product/${productId}/event/${eventId}`,
      method: 'GET',
    };

    return APIServicesV3.request<CouponView[]>(axiosConfig);
  }

  public static async getUsedCodesForAccount(
    accountId: string
  ): Promise<ServiceResponseV3<string[]>> {
    if (!accountId) {
      return ServiceResponseV3.success([], '');
    }
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/coupons/used-codes-for-connected-account/${accountId}`,
      method: 'GET',
    };
    return APIServicesV3.request<string[]>(axiosConfig);
  }

  public static async validateEventRosterCoupon(
    code: string,
    eventId: string,
    productId: string
  ): Promise<ServiceResponseV3<CouponView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/coupons/validate-event-roster-coupon`,
      method: 'POST',
      data: {
        code,
        productId,
        eventId,
      },
    };
    return APIServicesV3.request<CouponView>(axiosConfig);
  }
}

export default CouponService;
