import React from 'react';
import { ControllButtons } from './styles';
import { ControllButtonsProps } from './types';

function PagesControl({ page, disabled, setPage }: ControllButtonsProps) {
  return (
    <ControllButtons>
      <button
        type='button'
        disabled={page === 0}
        onClick={() => setPage(page - 1)}
      >
        Prev
      </button>
      <button
        type='button'
        disabled={disabled}
        onClick={() => setPage(page + 1)}
      >
        Next
      </button>
    </ControllButtons>
  );
}

export default PagesControl;
