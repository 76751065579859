import { IFutsalEventPayload } from 'services/v3/Match/payload-interfaces';

import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import MatchService from 'services/v3/Match/MatchService';

export const useCreateFutsalEvent = () => {
  return useMutation({
    mutationFn: async (payload: IFutsalEventPayload) =>
      MatchService.createFutsalEvent(payload),
    onSuccess: async () => {
      notification.success({
        message: 'Futsal event was created successfully!',
      });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to create futsal event!',
        description: error.message,
      });
    },
  });
};
