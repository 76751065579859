import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.grey900};
  border-top: 1px solid ${COLORS.grey800};
  padding: ${toRem(16)} ${toRem(40)} ${toRem(16)} ${toRem(24)};

  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: ${toRem(40)};

  position: fixed;
  bottom: 0;
  left: 0;
`;
