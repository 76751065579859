import React from 'react';
import { Drawer } from 'antd';

import { User } from 'models/User/UserModel';
import { Header } from './components/Header';
import { Documents } from './components/Documents';
import * as S from './styles';

type ViewMemberDrawerProps = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
};

export const ViewMemberDrawer = ({
  user,
  isOpen,
  onClose,
}: ViewMemberDrawerProps) => {
  return (
    <Drawer visible={isOpen} placement='right' onClose={onClose}>
      <S.Container>
        <Header user={user} />
        <Documents user={user} />
      </S.Container>
    </Drawer>
  );
};
