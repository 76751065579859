import { AxiosRequestConfig } from 'axios';

import APIServicesV3 from 'services/util/ApiServicesV3';
import { EventListView, EventView } from 'admin/models/event/Event';
import PageResponse from 'services/util/PageResponse';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { NewAgeDivision, PoolCreateRequestBody, TeamCreateRequestBody } from 'admin/models/AgeDivision';
import { ConfigApi } from '../../config.api';
import {
  AllEventInvites,
  ClubsAndDivisionsProps,
  FindSubscribedClubs,
  FindSubscribedReferees,
  GetEventDivisionsOrdered,
  InviteClubsByEvent,
  PagedEventListWithRosterUsers,
  PoolsOfEventResponse,
} from './types';

export default class EventService {
  public static async getActiveEvents(): Promise<
    ServiceResponseV3<PageResponse<EventListView>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `events/active`,
      method: 'GET',
    };
    return APIServicesV3.request<PageResponse<EventListView>>(axiosConfig);
  }

  public static async getActiveEventsPaged(
    size?: number,
    page?: number
  ): Promise<ServiceResponseV3<PageResponse<EventListView>>> {
    const queryParams = [];

    if (size) queryParams.push(`size=${size}`);
    if (page) queryParams.push(`page=${page}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `events/active${queryString}`,
      method: 'GET',
    };
    return APIServicesV3.request<PageResponse<EventListView>>(axiosConfig);
  }

  public static async find(id: string): Promise<ServiceResponseV3<EventView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/with-detailed-venues`,
      method: 'GET',
    };
    return APIServicesV3.request<EventView>(axiosConfig);
  }

  public static async getClubsAndDivisionsPaidForEvent(
    id: string
  ): Promise<ServiceResponseV3<ClubsAndDivisionsProps>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/divisions?sort=asc`,
      method: 'GET',
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async findSubscribedClubs(
    id: string
  ): Promise<ServiceResponseV3<FindSubscribedClubs>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${id}/subscribed/clubs`,
      method: 'GET',
    };
    return APIServicesV3.request<FindSubscribedClubs>(axiosConfig);
  }

  public static async findSubscribedReferees(
    id: string
  ): Promise<ServiceResponseV3<FindSubscribedReferees>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${id}/subscribed/referees`,
      method: 'GET',
    };
    return APIServicesV3.request<FindSubscribedReferees>(axiosConfig);
  }

  public static async getJoinedEvents(
    pageSize = 50
  ): Promise<ServiceResponseV3<PagedEventListWithRosterUsers>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/events/joined',
      method: 'GET',
      params: {
        size: pageSize,
      },
    };
    return APIServicesV3.request<PagedEventListWithRosterUsers>(axiosConfig);
  }

  public static async inviteByEvent(
    body: InviteClubsByEvent
  ): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/event-invites`,
      method: 'POST',
      data: body,
    };
    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async getEventInvites(
    id: string,
    type?: EventInvitesTypes
  ): Promise<ServiceResponseV3<AllEventInvites[]>> {
    let url = `/event-invites/all/${id}`;

    if (type) url += `?type=${type}`;

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url,
      method: 'GET',
    };
    return APIServicesV3.request<AllEventInvites[]>(axiosConfig);
  }

  public static async denyInvite(
    id: string
  ): Promise<ServiceResponseV3<AllEventInvites[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/event-invites/${id}/deny`,
      method: 'POST',
    };
    return APIServicesV3.request<AllEventInvites[]>(axiosConfig);
  }

  public static async updateDivision(
    id: string,
    body: NewAgeDivision
  ): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/division`,
      method: 'PATCH',
      data: {
        division: body,
      },
    };
    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async createPool(
    id: string,
    divisionId: string,
    data?: PoolCreateRequestBody
  ): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/division/${divisionId}/pool/new`,
      method: 'PATCH',
      data: data || {}
    };
    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async createTeamByPoolId(
    id: string,
    divisionId: string,
    poolId: string,
    data?: TeamCreateRequestBody
  ): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/division/${divisionId}/pool/${poolId}/team/new`,
      method: 'PATCH',
      data: data || {}
    };
    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async updateTeamPlacement(
    id: string,
    body: NewAgeDivision
  ): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/team-placement`,
      method: 'PATCH',
      data: {
        division: body,
      },
    };
    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async getPoolsOfEvent(
    idEvent: string
  ): Promise<ServiceResponseV3<PoolsOfEventResponse[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/pools/${idEvent}`,
      method: 'GET',
    };
    return APIServicesV3.request<PoolsOfEventResponse[]>(axiosConfig);
  }

  public static async getEventDivisionsOrdered(
    id: string
  ): Promise<ServiceResponseV3<GetEventDivisionsOrdered>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/events/${id}/list/divisions`,
      method: 'GET',
    };
    return APIServicesV3.request<GetEventDivisionsOrdered>(axiosConfig);
  }
}
