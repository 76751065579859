import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  padding-top: 30px;
`;

export const Title = styled.h2`
  color: ${COLORS.white};
  font-family: 'UF Display';
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const ActionsWrapper = styled.div`
  position: relative;
  width: 100%;

  .ant-tabs-nav {
    padding: 0;
  }
`;

export const SubmitButtonWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
`;
