import { ClubCreation, ClubView } from 'admin/models/club/Club';
import { AxiosRequestConfig } from 'axios';
import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import PageResponse from 'services/util/PageResponse';
import { ClubInvitesViewWithReceiver } from 'models/v3/ClubInvite/ClubInvitesModel';
import { MembersFilterTypes } from 'models/v3/Club/ClubModel';
import {
  ClubFilters,
  InviteMembersByClubDTO,
  UpdateMemberPoolDTO,
} from './types';

class ClubService {
  public static async getMyClubsByType(
    userType: UserType
  ): Promise<ServiceResponseV3<ClubView[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/my-clubs/${userType}/by-user-type`,
      method: 'GET',
    };
    return APIServicesV3.request<ClubView[]>(axiosConfig);
  }

  public static async getClubById(
    id?: string
  ): Promise<ServiceResponseV3<ClubView | undefined>> {
    if (!id) return ServiceResponseV3.success(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/${id}`,
      method: 'GET',
    };
    return APIServicesV3.request<ClubView>(axiosConfig);
  }

  public static async getClubsByUserId(
    userId?: string
  ): Promise<ServiceResponseV3<ClubView[]>> {
    if (!userId) return ServiceResponseV3.success([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/my-clubs/${userId}`,
      method: 'GET',
    };
    return APIServicesV3.request<ClubView[]>(axiosConfig);
  }

  public static async getClubsMembersById(
    id?: string,
    size?: number,
    page?: number,
    type?: MembersFilterTypes
  ): Promise<ServiceResponseV3<PageResponse<User>>> {
    const queryParams = [];

    if (size) queryParams.push(`size=${size}`);
    if (page) queryParams.push(`page=${page}`);
    if (type) queryParams.push(`type=${type}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/${id}/members${queryString}`,
      method: 'GET',
    };
    return APIServicesV3.request<PageResponse<User>>(axiosConfig);
  }

  public static async getMembersWithoutClub(): Promise<
    ServiceResponseV3<User[]>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/clubs/users-no-club`,
      method: 'GET',
    };
    return APIServicesV3.request<User[]>(axiosConfig);
  }

  public static async getClubMembersPool(
    id?: string,
    validateAccess = false
  ): Promise<ServiceResponseV3<User[]>> {
    if (!id) return ServiceResponseV3.success([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/${id}/v3/memberpool`,
      params: {
        validateAccess,
      },
      method: 'GET',
    };
    return APIServicesV3.request<User[]>(axiosConfig);
  }

  public static async updateClubMembersPool(
    id: string,
    dto: UpdateMemberPoolDTO
  ) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/${id}/v3/memberpool`,
      method: 'POST',
      data: dto,
    };
    return APIServicesV3.request(axiosConfig);
  }

  public static async getMyClubs(): Promise<ServiceResponseV3<ClubView[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/clubs/my-clubs-v3`,
      method: 'GET',
    };
    return APIServicesV3.request<ClubView[]>(axiosConfig);
  }

  public static async inviteMembersByClub(
    body: InviteMembersByClubDTO
  ): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/club-invites`,
      method: 'POST',
      data: body,
    };
    return APIServicesV3.request<any>(axiosConfig);
  }

  public static async getMyClubsInvites(
    clubId?: string
  ): Promise<ServiceResponseV3<ClubInvitesViewWithReceiver[]>> {
    if (!clubId) return ServiceResponseV3.success([], '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/club-invites/club/${clubId}`,
      method: 'GET',
    };
    return APIServicesV3.request<ClubInvitesViewWithReceiver[]>(axiosConfig);
  }

  public static async createWithDirector(
    body: ClubCreation
  ): Promise<ServiceResponseV3<ClubView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/clubs/v3',
      method: 'POST',
      data: body,
    };
    return APIServicesV3.request<ClubView>(axiosConfig);
  }

  public static async findAllWithFilters(
    filters?: ClubFilters
  ): Promise<ServiceResponseV3<PageResponse<ClubView>>> {
    const params = new URLSearchParams();

    if (filters?.name) params.append('name', filters.name);
    if (filters?.city) params.append('city', filters.city);
    if (filters?.state) params.append('state', filters.state);
    if (filters?.country) params.append('country', filters.country);
    if (filters?.totalMembers) params.append('totalMembers', filters.totalMembers);
    if (filters?.page) params.append('page', filters.page.toString());
    if (filters?.pageSize) params.append('size', filters.pageSize.toString());

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/clubs/v3',
      method: 'GET',
      params,
    };
    return APIServicesV3.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async acceptOrDenyInviteToJoinClub(inviteId: string, type: 'accept' | 'deny'): Promise<ServiceResponseV3<ClubInvitesViewWithReceiver[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/club-invites/${inviteId}/${type}`,
      method: 'POST',
    };
    return APIServicesV3.request<ClubInvitesViewWithReceiver[]>(axiosConfig);
  }
}

export default ClubService;
