import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  span {
    cursor: pointer;
    font-size: ${toRem(24)};

    &:hover {
      opacity: 0.8;
    }
  }

  & > :first-child {
    color: ${COLORS.supportError};
  }

  & > :last-child {
    color: ${COLORS.brandPrimary};
  }
`;
