import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import { ApplicationState } from 'redux/store';
import { sideModalCloseModal } from 'redux/sideModal/actions';

import V3LayoutBase from 'components/base/Layout/V3LayoutBase/V3LayoutBase';

import EventInfo from 'pages/V3/setupEvent/EventInfo/EventInfo';
import GeneralRules from 'pages/V3/setupEvent/GeneralRules/GeneralRules';
import MatchSettings from 'pages/V3/setupEvent/MatchSettings/MatchSettings';
import Venues from 'pages/V3/setupEvent/Venues/Venues';
import Divisions from 'pages/V3/setupEvent/Divisions/Divisions';
import Courts from 'pages/V3/setupEvent/Courts/Courts';
import Pricing from 'pages/V3/setupEvent/Pricing/Pricing';
import Multipliers from 'pages/V3/setupEvent/Multipliers/Multipliers';
import Tiebreakers from 'pages/V3/setupEvent/Tiebreakers/Tiebreakers';

import { SetupEventModalProps } from './types';

const SetupEventModal: FC<SetupEventModalProps> = ({ auth }: any) => {
  const dispatch = useDispatch();

  const { isOpen, selected: selectedModal } = useSelector(
    (state: ApplicationState) => state.sideModal
  );

  const handleCloseModal = () => {
    dispatch(sideModalCloseModal());
  };

  return (
    <Modal
      isOpen={isOpen && selectedModal !== 'none'}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      bodyOpenClassName='disable-entire-scroll'
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0 ,0, 0.4)',
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        content: {
          background: 'inherit',
          maxWidth: 'fit-content',
          position: 'static',
          borderRadius: '0',
          borderWidth: 0,
          padding: '0',
          margin: '0',
        },
      }}
    >
      <V3LayoutBase layout='sidebarModal' auth={auth}>
        {selectedModal === 'eventInfo' && <EventInfo {...{ auth }} />}
        {selectedModal === 'generalRules' && <GeneralRules {...{ auth }} />}
        {selectedModal === 'matchSettings' && <MatchSettings {...{ auth }} />}
        {selectedModal === 'divisions' && <Divisions {...{ auth }} />}
        {selectedModal === 'venues' && <Venues {...{ auth }} />}
        {selectedModal === 'pricing' && <Pricing {...{ auth }} />}
        {selectedModal === 'courts' && <Courts {...{ auth }} />}
        {selectedModal === 'multipliers' && <Multipliers {...{ auth }} />}
        {selectedModal === 'tiebreakers' && <Tiebreakers {...{ auth }} />}
      </V3LayoutBase>
    </Modal>
  );
};

export default SetupEventModal;
