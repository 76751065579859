import React from 'react';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useHistory, useParams } from 'react-router-dom';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { Header } from './Header/Header';
import { Tabs, ITabsProps } from './Tabs/Tabs';
import * as S from './styles';
import Loading from '../Loading/Loading';

type EventManagerDashboardProps = {
  title?: string,
} & ITabsProps;

export const EventManagerDashboard = ({
  tabs,
  title,
  ...rest
}: EventManagerDashboardProps) => {
  const params: { eventId: string } = useParams();
  const { data: currentEvent } = useGetEventById(params.eventId);
  const history = useHistory();

  const { isStaff } = useProfile();

  if (!currentEvent) return <Loading />;

  if (!currentEvent?.owner && !isStaff) {
    if (currentEvent?.draft) {
      history.push(`/v3/events`);
    } else {
      history.push(`/v3/event/${currentEvent.id}`);
    }
  }

  return (
    <S.Container>
      <Header
        id={currentEvent.id}
        title={title || currentEvent.name}
        imgSrc={currentEvent.data?.eventInfo?.header}
      />
      <Tabs tabs={tabs} {...rest} />
    </S.Container>
  );
};
