export const FREE_DETAILS = [
  { text: 'Vault Player Profile', highlight: false },
  { text: 'View upcoming events', highlight: false },
  {
    text: 'Access to photos and media from United Futsal events',
    highlight: false,
  },
];

export const UPGRADE_DETAILS = [
  { text: 'Vault Player Profile', highlight: false },
  { text: 'View upcoming events', highlight: false },
  {
    text: 'Access to photos and media from United Futsal events',
    highlight: false,
  },
  { text: 'Discounts on United Futsal Merchandise', highlight: true },
  {
    text:
      'Participate in any Officially Sanctioned United Futsal Leagues and Tournaments',
    highlight: true,
  },
  { text: 'Industry - Leading Insurance', highlight: true },
  { text: 'Earn XP to LEVEL UP your player profile', highlight: true },
  {
    text: 'StatTracker+TM System for tracking goals and other metrics.',
    highlight: true,
  },
];
