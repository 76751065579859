import React, { useEffect } from 'react';

import { IAddressEvent } from 'admin/models/event/Event';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import { toRem } from 'styles/v3/functions';
import { BodySBold, TitleH4 } from 'styles/v3/variables';

import ConvertUtil from 'util/ConvertUtil';

import moment from 'moment';
import {
  EventContainer,
  EventIcon,
  EventInformationContainer,
  EventTitle,
  LinkEvent,
  Address,
} from './styles';

export interface EventCardHomeProps {
  id: string;
  title: string;
  logo?: string;
  startDate: Date;
  finalDate: Date;
  isGreen?: boolean;
  venue?: Maybe<IAddressEvent>;
}

const EventCardHome: React.FC<EventCardHomeProps> = ({
  id,
  title,
  logo,
  startDate,
  finalDate,
  isGreen = false,
  venue,
  ...props
}) => {
  const startAt = moment.utc(startDate).format('MMM DD yy');
  const endAt = moment.utc(finalDate).format('MMM DD yy');

  return (
    <EventContainer isGreen={isGreen} {...props}>
      <EventIcon>
        <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
      </EventIcon>
      <EventInformationContainer>
        <EventTitle>
          <TitleH4
            $color='grey50'
            style={{
              maxHeight: 66,
              display: 'block',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              textAlign: 'start',
            }}
          >
            {title}
          </TitleH4>
        </EventTitle>
        <BodySBold $color='grey200' $isUpper style={{ marginTop: toRem(8) }}>
          {startAt} - {endAt}
        </BodySBold>
        {venue && (
          <Address $color='grey200'>
            {venue.address.state} - {venue.address.country}
          </Address>
        )}
        <LinkEvent to={`/v3/event/${id}`}>
          <OutlinedButtonWithIcon color='dark-white' isBold={false} isUpper>
            See Event
          </OutlinedButtonWithIcon>
        </LinkEvent>
      </EventInformationContainer>
    </EventContainer>
  );
};

export default EventCardHome;
