import { NewAgeDivision } from 'admin/models/AgeDivision';

import { DeepPartial } from 'util/types';

import { Fields } from './types';

export const getDefaultStateFields = (
  division: DeepPartial<NewAgeDivision> | undefined
): Fields => {
  return {
    name: {
      error: false,
      required: true,
      value: division?.name ?? '',
    },
    years: {
      error: false,
      required: true,
      value: division?.years ?? '',
    },
    rule: {
      error: false,
      required: true,
      value: division?.rule ?? '',
    },
    gender: {
      error: false,
      required: true,
      value: division?.gender ?? '',
    },
    allowGirlBoysOnAnotherDivision: {
      error: false,
      required: false,
      value: division?.allowGirlBoysOnAnotherDivision ?? false,
    },
    color: {
      error: false,
      required: false,
      value: division?.color ?? '',
    },
    coedRules: {
      error: false,
      required: false,
      value: division?.coedRules ?? '',
    },
    targetNumberOfTeams: {
      error: false,
      required: true,
      value: division?.targetNumberOfTeams ?? '',
    },
    lockDivision: {
      error: false,
      required: false,
      value: division?.lockDivision ?? false,
    },
  };
};
