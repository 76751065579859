import React, { FC, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';

import { EventListView } from 'admin/models/event/Event';
import UserType from 'models/User/UserTypeModel';

import ConvertUtil from 'util/ConvertUtil';
import { formatUserName } from 'util/formatUserName';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetJoinedEvents } from 'hooks/v3/event/useGetJoinedEvents/useGetJoinedEvents';
import { useGetDiscoverEvents } from 'hooks/v3/event/useGetDiscoverEvents/useGetDiscoverEvents';

import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import EventCard from 'components/v3/Cards/EventCardHome';

import { TitleH2, TitleH3, TitleH4, BodyM } from 'styles/v3/variables';

import {
  UserXPInformation,
  UserTypesInformation,
} from './components/UserXPInformation';
import { AccountStatus } from './components/AccountStatus';
import { MAX_OTHER_EVENT_SLIDES } from './configs';

import * as S from './styles'

import 'react-alice-carousel/lib/alice-carousel.css';

const Home: FC = () => {
  const history = useHistory();

  const { currentUser, missingInformation } = useProfile()

  const { data: discoverEventsResponse } = useGetDiscoverEvents(MAX_OTHER_EVENT_SLIDES, 0);
  const { data: joinedEventsResponse } = useGetJoinedEvents(1);

  const joinedEventsTotal = useMemo(() => joinedEventsResponse?.data.total ?? 0, [joinedEventsResponse]);
  const discoverEventsTotal = useMemo(() => discoverEventsResponse?.data.total ?? 0, [discoverEventsResponse]);

  const joinedEvent = useMemo(() => {
    if (joinedEventsResponse) {
      return joinedEventsResponse.data.content[0];
    }

    return null;
  }, [joinedEventsResponse]);

  const discoverEvents = useMemo<Maybe<EventListView>>(() => {
    if (!discoverEventsResponse) {
      return null;
    }

    return discoverEventsResponse.data.content[0];
  }, [discoverEventsResponse, discoverEventsTotal]);

  const profileInfo = useMemo(() => {
    const fullName = formatUserName({
      lastName: currentUser?.lastName || '',
      firstName: currentUser?.firstName || '',
      middleName: currentUser?.middleName || '',
    })

    const isOfficialMember = currentUser?.membershipType === 'OFFICIAL'

    const types = [
      currentUser?.type,
      ...(Array.isArray(currentUser?.types) ? currentUser.types : []),
    ].filter(Boolean) as Array<UserType>;

    const userTypes: UserTypesInformation[] = types.map((userType) => {
      return {
        label: userType,
        value: userType.toLowerCase(),
      };
    });

    return {
      fullName,
      isOfficialMember,
      userTypes
    }

  }, [currentUser]);

  const handleGoToUpgradeMembership = () => {
    history.push('/v3/profile/upgrade-membership');
  };

  const handleRedirectToDiscoverEvents = useCallback(() => {
    history.push('/v3/events?tab=discover-events');
  }, [history]);

  const handleRedirectToCreatedByMe = useCallback(() => {
    history.push('/v3/events?tab=created-by-me');
  }, [history]);

  const handleRedirectToMyEvents = useCallback(() => {
    history.push('/v3/events?tab=my-events');
  }, [history]);

  return (
    <S.Container>
      {!!missingInformation?.length && (
        <AccountStatus />
      )}
      <S.ContainerUser>
        <S.ContainerUserProfile>
          <S.UserProfileImage
            size={160}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(currentUser.photo)}
          />
          <S.UserInformation>
            <S.UserNameAndTeams>
              <TitleH2>{profileInfo.fullName}</TitleH2>
            </S.UserNameAndTeams>
            {!profileInfo.isOfficialMember && (
              <OutlinedButton color='primary' isBold={false} isUpper onClick={handleGoToUpgradeMembership}>
                Upgrade Membership
              </OutlinedButton>
            )}
          </S.UserInformation>
        </S.ContainerUserProfile>
        <UserXPInformation userOptions={profileInfo.userTypes} />
      </S.ContainerUser>
      <S.ContainerEvents>
        <S.EventsContent className={joinedEventsTotal <= 1 ? 'full-width' : ''}>
          <div>
            <TitleH3>Events</TitleH3>
            <TitleH4 $color='grey400'>My Events</TitleH4>
            <S.EventsContainer>
              {joinedEvent && (
                <>
                  <EventCard
                    id={joinedEvent.id}
                    title={joinedEvent.name}
                    startDate={joinedEvent.startDate}
                    finalDate={joinedEvent.endDate}
                    logo={joinedEvent.logo}
                    venue={joinedEvent.venues.length ? joinedEvent.venues[0] : null}
                    isGreen
                  />
                  <S.EventMore
                    onClick={
                      joinedEventsTotal <= 1
                        ? handleRedirectToDiscoverEvents
                        : handleRedirectToMyEvents
                    }
                  >
                    <span>
                      {joinedEventsTotal <= 1
                        ? <PlusOutlined />
                        : `+ ${joinedEventsTotal - 1}`
                      }
                    </span>
                  </S.EventMore>
                </>
              )}
              {!joinedEvent && (
                <S.EmptyEventCard onClick={handleRedirectToDiscoverEvents}>
                  <BodyM>Join the event</BodyM>
                </S.EmptyEventCard>
              )}
            </S.EventsContainer>
          </div>
          <div>
            <S.EventTitleWrapper>
              <TitleH4 $color='grey400'>Other Events</TitleH4>
            </S.EventTitleWrapper>
            <S.EventsContainer>
              {discoverEvents && (
                <>
                  <EventCard
                    key={discoverEvents.id}
                    id={discoverEvents.id}
                    title={discoverEvents.name}
                    startDate={discoverEvents.startDate}
                    finalDate={discoverEvents.endDate}
                    logo={discoverEvents.logo}
                    venue={discoverEvents.venues.length ? discoverEvents.venues[0] : null}
                  />
                  <S.EventMore
                    onClick={
                      discoverEventsTotal <= 1
                        ? handleRedirectToCreatedByMe
                        : handleRedirectToDiscoverEvents
                    }
                  >
                    <span>
                      {discoverEventsTotal <= 1
                        ? <PlusOutlined />
                        : `+ ${discoverEventsTotal - 1}`
                      }
                    </span>
                  </S.EventMore>
                </>
              )}
              {!discoverEvents && (
                <S.EmptyEventCard onClick={handleRedirectToCreatedByMe}>
                  <BodyM>Events coming soon</BodyM>
                </S.EmptyEventCard>
              )}
            </S.EventsContainer>
          </div>
        </S.EventsContent>
      </S.ContainerEvents>
    </S.Container>
  );
};

export default Home;
