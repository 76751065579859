import Keycloak from 'keycloak-js';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import V3LayoutBase from 'components/base/Layout/V3LayoutBase/V3LayoutBase';
import DocumentsApprovalListPageV3 from 'admin/pages/documents/DocumentsApprovalListPageV3';
import { isArray } from 'lodash';
import BaseEventCreatePage from '../admin/pages/base-event/BaseEventCreatePage';
import BaseEventListPage from '../admin/pages/base-event/BaseEventListPage';
import ClubCreatePage from '../admin/pages/clubs/ClubCreatePage';
import ClubListPage from '../admin/pages/clubs/ClubListPage';
import DocumentsApprovalListPage from '../admin/pages/documents/DocumentsApprovalListPage';
import EventCreatePage from '../admin/pages/events/EventCreatePage';
import EventListPage from '../admin/pages/events/EventListPage';
import MembershipCreatePage from '../admin/pages/memberships/MembershipCreatePage';
import MembershipListPage from '../admin/pages/memberships/MembershipListPage';
import MatchSheet from '../admin/pages/tournaments/MatchSheet';
import RosterPrint from '../admin/pages/tournaments/RosterPrint';
import { TournamentsRoutes } from '../admin/pages/tournaments/routes/Tournaments.routes';
import UserEditPage from '../admin/pages/users/UserEditPage';
import UsersListPage from '../admin/pages/users/UsersListPage';
import VenueCreatePage from '../admin/pages/venues/VenueCreatePage';
import VenueListPage from '../admin/pages/venues/VenueListPage';
import LayoutBase from '../components/base/Layout/LayoutBase/LayoutBase';
import UserType from '../models/User/UserTypeModel';
import AdminPanel from '../pages/AdminPanel/AdminPanel';
import ClubDirector from '../pages/ClubDirector/ClubDirector';
import Dashboard from '../pages/Dashboard/Dashboard';
import IndexPage from '../pages/Index/IndexPage';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import MembershipUpgrade from '../pages/MembershipUpgrade/MembershipUpgrade';
import MembershipUpgradeAdditionalInfo from '../pages/MembershipUpgrade/MembershipUpgradeAdditionalInfo';
import MembershipUpgradeCertificate from '../pages/MembershipUpgrade/MembershipUpgradeCertificate';
import MembershipUpgradePayment from '../pages/MembershipUpgrade/MembershipUpgradePayment';
import MySettings from '../pages/MySettings/MySettings.page';
import NotFound from '../pages/NotFound/NotFound';
import PaymentSuccess from '../pages/Payment/PaymentSuccess';
import MyClubPage from '../pages/PlayerPortal/MyClub/MyClub.page';
import RankingsPage from '../pages/Rankings/Rankings.page';
import UserSelection from '../pages/UserSelection/UserSelection.page';
import WizardRoutes from '../pages/Wizard/routes/Wizard.routes';
import XPRating from '../pages/XPRating/XPRating';
import { accountSetUser } from '../redux/account/actions';
import KeycloakUtil from '../util/KeycloakUtil';
import PrivateRoute from './PrivateRoute';
import { v3Routes } from '../pages/V3/routes/V3.routes';
import EventListPageB2b from '../admin/pages/events-b2b/EventB2bListPage';
import ClubPendingPage from '../admin/pages/clubs/ClubPendingPage';

export const routes = [
  {
    path: '/login',
    component: Login,
    private: false,
    layout: 'no-menu',
  },

  ...v3Routes,
  {
    path: '/logout',
    component: Logout,
    private: false,
  },
  {
    path: '/selectuser',
    component: UserSelection,
    private: true,
    layout: 'no-menu',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    private: true,
  },
  {
    path: '/wizard',
    component: WizardRoutes,
    private: true,
    userSelectedNotRequired: true,
    layout: 'menu-empty',
  },
  {
    path: '/membership-upgrade/:userId?',
    component: MembershipUpgrade,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/membership-upgrade-extra-info/:userId?',
    component: MembershipUpgradeAdditionalInfo,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/membership-upgrade-certificate/:userId?',
    component: MembershipUpgradeCertificate,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/membership-upgrade-payment/:userId?',
    component: MembershipUpgradePayment,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/success',
    component: PaymentSuccess,
    private: true,
    layout: 'menu-empty',
  },
  {
    path: '/my-club',
    component: MyClubPage,
    private: true,
    roles: [UserType.PLAYER, UserType.COACH],
  },
  {
    path: '/club-director',
    component: ClubDirector,
    private: true,
    roles: [UserType.CLUB_DIRECTOR],
  },
  {
    path: '/admin-panel',
    component: AdminPanel,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/events',
    component: EventListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/events-b2b',
    component: EventListPageB2b,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/events/create/:id?',
    component: EventCreatePage,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/memberships',
    component: MembershipListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/memberships/create/:id?',
    component: MembershipCreatePage,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/clubs',
    component: ClubListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/clubs-pending',
    component: ClubPendingPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/clubs/create/:id?',
    component: ClubCreatePage,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/document-analysis',
    component: DocumentsApprovalListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/document-analysis-v3',
    component: DocumentsApprovalListPageV3,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/users-list',
    component: UsersListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/users-list/:userId?',
    component: UserEditPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/match-sheet/:matchId',
    component: MatchSheet,
    exact: true,
    private: true,
    layout: 'none',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/tournaments/:eventId?',
    component: TournamentsRoutes,
    private: true,
    roles: [UserType.STAFF, UserType.REFEREE],
  },
  {
    path: '/admin-panel/venues',
    component: VenueListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/venues/create/:id?',
    component: VenueCreatePage,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },

  {
    path: '/admin-panel/base-events',
    component: BaseEventListPage,
    exact: true,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },
  {
    path: '/admin-panel/base-events/create/:id?',
    component: BaseEventCreatePage,
    private: true,
    layout: 'admin',
    roles: [UserType.STAFF],
  },

  {
    path: '/roster-print/:rosterId',
    component: RosterPrint,
    private: true,
    layout: 'none',
    roles: [UserType.STAFF],
  },
  {
    path: '/my-settings',
    component: MySettings,
    private: true,
  },
  {
    path: '/ranking',
    component: RankingsPage,
    private: true,
  },
  {
    path: '/xp-ratings',
    component: XPRating,
    private: true,
  },

  {
    path: '/home',
    component: IndexPage,
    private: true,
  },
  // TODO: comment top and uncomment below to replace the old home to the new one
  // {
  //   path: '/',
  //   component: v3Routes.find((r) => r.path === '/v3/home')?.component ?? IndexPage,
  //   private: false,
  // },
  {
    path: '/*',
    component: NotFound,
    private: false,
    noPaddingOnMobile: false,
  },
];

export default function Routes(): ReactElement {
  const [auth, setAuth] = useState<Keycloak.KeycloakInstance>();
  const dispatch = useDispatch();
  const target = (window.location.pathname + window.location.search).substring(1);
  const targetPage = target !== '' ? target : '/v3/home-page';

  const isB2bRoute = useMemo(() => {
    const targetParamList = targetPage.split('targetPath=');
    const targetList = [targetPage];
    if (targetParamList.length) {
      targetList.push(targetParamList[targetParamList.length - 1]);
    }
    return v3Routes.find((r) => {
      return targetList.find((t) =>
        t.startsWith(
          isArray(r.path) ? r.path[0].substring(1) : r.path.substring(1)
        )
      );
    });
  }, [targetPage]);

  const CurrentLayout =
    isB2bRoute?.path ? V3LayoutBase : LayoutBase;

    useEffect(() => {
    const loadKeycloak = () => {
      const token = localStorage.getItem('kc_token') || '';
      const refreshToken = localStorage.getItem('kc_refreshToken') || '';
      const keycloak = KeycloakUtil.getInstance(
        isB2bRoute?.path ? 'frontend-b2b' : 'frontend'
      );
      let redirectUri = `${process.env.REACT_APP_URL_BASE}/login?targetPath=${targetPage}`;

      if (isB2bRoute?.private) {
        redirectUri = `${process.env.REACT_APP_URL_BASE}/v3/login?targetPath=${targetPage}`;
      }
      keycloak
        .init({
          onLoad: 'check-sso',
          token,
          refreshToken,
          redirectUri,
          pkceMethod: 'S256',
          checkLoginIframe: true,
        })
        .then(() => {
          if (keycloak.token && keycloak.refreshToken) {
            localStorage.setItem('kc_token', keycloak.token || '');
            localStorage.setItem(
              'kc_refreshToken',
              keycloak.refreshToken || ''
            );
            const tokenParsed: any = keycloak;
            dispatch(
              accountSetUser({ id: tokenParsed.sub, email: tokenParsed.email })
            );
          }
          setAuth(keycloak);
        });
    };
    if (!isB2bRoute || isB2bRoute?.private) {
      loadKeycloak();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        {routes.map((route) => {
          const routePath = isArray(route.path) ? route.path[0] : route.path;
          // TODO: Render Login without Base
          return !route.private ? (
            <Route
              key={routePath}
              path={route.path}
              auth={auth}
              exact={route.exact ? route.exact : false}
              render={() => (
                <CurrentLayout auth={auth} layout={route.layout}>
                  <route.component
                    auth={auth as Keycloak.KeycloakInstance}
                    routeFrom={routePath}
                  />
                </CurrentLayout>
              )}
            />
          ) : (
            <PrivateRoute
              key={routePath}
              userSelectedNotRequired={route?.userSelectedNotRequired}
              auth={auth}
              roles={route.roles}
              exact={route.exact ? route.exact : false}
              path={routePath}
              render={() => (
                <CurrentLayout auth={auth} layout={route.layout}  noPaddingOnMobile={route?.noPaddingOnMobile}>
                  <route.component
                    auth={auth as Keycloak.KeycloakInstance}
                    routeFrom={routePath}
                  />
                </CurrentLayout>
              )}
            />
          );
        })}
      </Switch>
    </Router>
  );
}
