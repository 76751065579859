class PageResponse<T> {
  public content: T[];

  public hasNextPage: boolean;

  public hasPreviousPage: boolean;

  public page: number;

  public pageSize: number;

  public total: number;

  constructor(
    content: T[],
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    page: number,
    pageSize: number,
    total: number
  ) {
    this.content = content;
    this.hasNextPage = hasNextPage;
    this.hasPreviousPage = hasPreviousPage;
    this.page = page;
    this.pageSize = pageSize;
    this.total = total;
  }
}

export default PageResponse;
