import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  background-color: ${COLORS.grey850};
  flex-direction: row;
  padding: ${toRem(10)};
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  justify-content: center;
`;

interface ButtonSelectProps {
  active?: boolean;
}
export const ButtonSelect =
  styled.div <
  ButtonSelectProps >
  `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(16)};
  background-color: ${({ active }) =>
    active ? COLORS.grey900 : COLORS.grey850};
  border-radius: ${toRem(12)};
  margin-left: ${toRem(10)};
`;
