import { Menu } from 'antd';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;

  display: flex;
  height: ${toRem(60)};
  padding: ${toRem(24)} ${toRem(16)};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: ${toRem(24)};
  background: ${COLORS.grey900};
  border: ${toRem(2)} solid ${COLORS.grey700};

  span {
    cursor: pointer;

    margin-top: ${toRem(2)};
    color: ${COLORS.white};

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      display: none;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(24)};
`;

export const Flex = styled.div`
  flex: 1;
`;

export const CustomMenu = styled(Menu)`
  width: ${toRem(250)};

  .ant-dropdown-menu-item {
    padding: ${toRem(12)} ${toRem(10)};
  }
`;

export const MenuTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(16)};

  span {
    &::before {
      display: none;
    }

    margin-top: ${toRem(2)};
    color: ${COLORS.brandPrimary};
  }
`;

export const MenuAction = styled.div`
  cursor: pointer;
  margin-top: ${toRem(5)};

  span {
    color: ${COLORS.white};

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const TeamNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(6)};
`;

export const OrderWrapper = styled.div`
  margin-bottom: ${toRem(1.5)};
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(10)};
`;
