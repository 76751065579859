import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Pagination } from 'antd';

import { BodyL, TitleH2 } from 'styles/v3/variables';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useGetMyNotifications } from 'hooks/v3/notifications/useGetMyNotifications/useGetMyNotifications';

import * as S from './styles';
import NotificationItem from './components/Item';
import Tabs from './components/Tabs';

const Notifications: React.FC = () => {
  const [currentPage, setCurrentPage] = React.useState(1);

  const { data: notificationsData } = useGetMyNotifications();
  const hasAnyNotificationsToRead = notificationsData?.filter(
    (n) => !n.alreadySeen
  );

  const setAllAsRead = async () => {
    //
  };

  return (
    <S.Wrapper>
      <TitleH2 $isUpper>Notifications</TitleH2>

      <S.Header>
        <Tabs />
        {hasAnyNotificationsToRead && hasAnyNotificationsToRead.length > 0 && (
          <TextButtonWithIcon
            align='flex-start'
            isUpper
            noPadding
            reverse
            color='brandPrimary'
            customIcon='CheckSmall'
            onClick={setAllAsRead}
          >
            Mark all as read
          </TextButtonWithIcon>
        )}
      </S.Header>
      <S.Container>
        {notificationsData && notificationsData.length > 0 ? (
          notificationsData?.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))
        ) : (
          <BodyL>There are no notifications to view</BodyL>
        )}
      </S.Container>

      {/* <S.WrapperPagination>
        <Pagination
          total={100}
          defaultPageSize={6}
          current={currentPage}
          showSizeChanger={false}
          onChange={setCurrentPage}
        />
      </S.WrapperPagination> */}
    </S.Wrapper>
  );
};

export default withRouter(Notifications);
