import styled from 'styled-components';

import InputText from 'components/v3/Forms/InputText/InputText';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const ContainerUser = styled.div`
  width: 100%;

  display: flex;
  padding: ${toRem(16)};
  align-items: center;
  justify-content: space-between;

  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(16)};
`;

export const UserInformationContent = styled.div`
  display: flex;
  gap: ${toRem(24)};
  align-items: center;
`;

export const UserIconName = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserImage = styled.div`
  width: ${toRem(40)};
  height: ${toRem(40)};
  border-radius: ${toRem(100)};

  margin: 0;
  overflow: hidden;

  background-color: ${COLORS.grey50};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(40)};
    height: ${toRem(40)};
  }
`;

export const UserMemberType = styled.div`
  display: flex;
  gap: ${toRem(8)};
  align-items: center;

  margin-left: ${toRem(24)};
`;

export const UserDocumentsAndMore = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserDocuments = styled.div`
  display: flex;
  padding: ${toRem(8)};
  gap: ${toRem(12)};
  align-items: center;
`;

export const UserDocument = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${toRem(3)};

  span {
    display: flex;
  }
`;

export const JerseyField = styled(InputText)`
  width: ${toRem(80)};
`;
