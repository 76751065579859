import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import ScheduleService from 'services/v3/Schedule/ScheduleService';
import { ScheduleBaseModel } from 'services/v3/Schedule/types';

export const useCreateSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: ScheduleBaseModel) =>
      ScheduleService.createSchedule(dto),
    onSuccess: async () => {
      notification.success({
        message: 'Schedule saved successfully!',
      });
      await queryClient.invalidateQueries(['get-schedule-by-event-id']);
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
