import { Moment } from 'moment';

import { FutsalAction, SoccerPeriod } from 'services/v3/Match/enums';

export enum RosterType {
  Home,
  Away,
}

export interface IMatchInfo {
  time: Moment;
  soccer: SoccerPeriod;
  playGame: boolean;
}

export interface IFutsalSelection {
  roster: RosterType;
  action: FutsalAction;
  text: string;
}
