import React, { memo, FC } from 'react';
import { CloseSmall } from '@icon-park/react';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IRemoveConfirmModalProps {
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const RemoveConfirmModal: FC<IRemoveConfirmModalProps> = memo(
  ({ visible, onClose, onConfirm }) => {
    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={onClose}
      >
        <S.Header>
          <BodyM $color='grey50'>Confirmation</BodyM>
          <S.CloseButton onClick={onClose}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.Header>
        <S.Container>
          <S.Text>Are you sure you want to remove this action?</S.Text>
          <S.GroupActions>
            <S.CloseOutlinedButton isUpper onClick={onClose}>
              Close
            </S.CloseOutlinedButton>
            <S.ConfirmFilledButton isUpper onClick={onConfirm}>
              Confirm
            </S.ConfirmFilledButton>
          </S.GroupActions>
        </S.Container>
      </S.StyledModal>
    );
  }
);

RemoveConfirmModal.displayName = 'RemoveConfirmModal';
