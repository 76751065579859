import React, { memo, FC, useState, useCallback, useMemo } from 'react';
import { Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { IPlayerNumberPayload } from 'services/v3/Rosters/types';
import { IRosterUser } from 'services/v3/Match/interfaces';

import ConvertUtil from 'util/ConvertUtil';

import { BodyLBold } from 'styles/v3/variables';
import { JerseyNumberModal } from './JerseyNumberModal/JerseyNumberModal';

import * as S from './styles';

export enum MemberType {
  Coach = 'Coach',
  Player = 'Player',
}

interface IMemberCardProps {
  user: IRosterUser;
  type: MemberType;
  matchId: string;
  rosterId: string;
  players?: IRosterUser[];
  onAttendanceClick: (
    matchId: string,
    rosterId: string,
    userId: string,
    attend: boolean
  ) => void;
  onUpdatePlayerNumber?: (payload: IPlayerNumberPayload) => void;
}

export const MemberCard: FC<IMemberCardProps> = memo(
  ({
    type,
    user,
    matchId,
    rosterId,
    players,
    onAttendanceClick,
    onUpdatePlayerNumber,
  }) => {
    const { id, attend, firstName, lastName, photo, number } = user;

    const [openedJerseyNumberModal, setOpenedJerseyNumberModal] = useState(
      false
    );

    const jerseyNumber = useMemo(() => {
      if (typeof number !== 'undefined' && number < 10) {
        return `0${number}`;
      }

      return number?.toString() ?? '';
    }, [number]);

    const handleOpenJerseyNumberModal = useCallback(() => {
      setOpenedJerseyNumberModal(true);
    }, []);

    const handleCloseJerseyNumberModal = useCallback(() => {
      setOpenedJerseyNumberModal(false);
    }, []);

    const handleSubmitJerseyNumber = useCallback(
      (number: number) => {
        onUpdatePlayerNumber?.({
          rosterId,
          playerId: id,
          number,
        });

        setOpenedJerseyNumberModal(false);
      },
      [id, rosterId]
    );

    return (
      <S.CardWrapper>
        <S.AvatarProfile
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(photo)}
        />
        <S.Name>
          {firstName} {lastName}
        </S.Name>
        <S.NumberSection>
          {MemberType.Coach === type && <BodyLBold>Coach</BodyLBold>}
          {MemberType.Player === type && !!jerseyNumber.length && (
            <BodyLBold>{jerseyNumber}</BodyLBold>
          )}
          {MemberType.Player === type && (
            <S.EditNumberButton isUpper onClick={handleOpenJerseyNumberModal}>
              {jerseyNumber.length ? 'Edit' : 'Add jersey number'}
            </S.EditNumberButton>
          )}
        </S.NumberSection>
        <S.TogglerWrapper>
          <Switch
            checked={attend}
            onChange={(selected: boolean) =>
              onAttendanceClick(matchId, rosterId, id, selected)
            }
          />
          <S.PlayerAttendStatus className={attend ? 'selected' : ''}>
            {attend ? 'Present' : 'Absent'}
          </S.PlayerAttendStatus>
        </S.TogglerWrapper>
        <JerseyNumberModal
          visible={openedJerseyNumberModal}
          players={players}
          defaultNumber={jerseyNumber}
          onClose={handleCloseJerseyNumberModal}
          onSubmit={handleSubmitJerseyNumber}
        />
      </S.CardWrapper>
    );
  }
);

MemberCard.displayName = 'MemberCard';
