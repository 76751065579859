import React from 'react';

import { BodyLBold, BodyM } from 'styles/v3/variables';
import { CloseSmall, Left as BackButton } from '@icon-park/react';
import * as S from './styles';
import { RostersContainerProps } from './types';
import { ScoreSheetItem } from './components/ScoreSheetItem/ScoreSheetItem';

export const ScoreSheetContainer = ({
  handleCloseModal,
  currentEvent,
  handleBack,
}: RostersContainerProps) => {
  return (
    <S.Container>
      <S.HeaderWrapper>
        <BackButton onClick={handleBack} className='backButton' size='24px' />
        <S.HeaderTitleContainer>
          <BodyLBold>Print Scoresheet</BodyLBold>
        </S.HeaderTitleContainer>
        <CloseSmall
          onClick={handleCloseModal}
          className='closeButton'
          size='24px'
        />
      </S.HeaderWrapper>
      <S.HR />
      <S.BodyWrapper>
        <BodyM className='title'>
          Select how do you want to print your scoresheet
        </BodyM>

        <ScoreSheetItem
          title='Print all scoresheets'
          description='If you select this, you will print the scoresheets from all days of your event.'
          eventId={currentEvent?.id ?? ''}
          type='all-scoresheet'
        />

        <ScoreSheetItem
          title='Print a specific game'
          description='When you select this option, you will see a list of games to print'
          eventId={currentEvent?.id ?? ''}
          type='single-game'
        />
      </S.BodyWrapper>
    </S.Container>
  );
};
