import { useQuery } from '@tanstack/react-query';
import ScheduleService from 'services/v3/Schedule/ScheduleService';

export function useGetScheduleByEventId(eventId?: string) {
  const { data, isLoading, error } = useQuery(
    ['get-schedule-by-event-id', eventId],
    async () => await ScheduleService.getSchedulesByEventId(eventId)
  );
  return {
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
