import React from 'react';
import { ApproveRejectFieldProps } from './types';

import * as S from './styles';

export const ApproveReject = ({
  openApproveModal,
  openRejectModal,
}: ApproveRejectFieldProps) => {
  return (
    <S.Container>
      <S.DenyButton onClick={openRejectModal} />
      <S.ApproveButton onClick={openApproveModal} />
    </S.Container>
  );
};
