import React from 'react';

import { Link, useParams } from 'react-router-dom';
import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import { BodyL, BodyM, BodyS } from 'styles/v3/variables';
import { Right } from '@icon-park/react';
import Loading from 'components/v3/Loading/Loading';
import { useGetGamesCountByEventId } from 'hooks/v3/schedules/useGetGamesCountByEventId';
import { Header } from './components';
import * as S from './styles';
import { options } from './constants';

const Schedule = () => {
  const params = useParams<{ eventId: string }>();

  const { data: response, isLoading } = useGetEvent(params.eventId);
  const { data: gamesCount } = useGetGamesCountByEventId(params.eventId);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <Header event={response?.data} />
      <S.InformationText>
        <BodyS $color='grey300'>
          Select how do you want to navigate the event&apos;s schedule
        </BodyS>
      </S.InformationText>

      {options.map((option) => (
        <S.OptionWrapper key={option.key}>
          <S.OptionTextWrapper>
            {option.key === 'see-all-games' ? (
              <S.SeeAllGamesWrapper>
                <BodyL $color='grey100'>{option.name}</BodyL>
                <BodyS $color='grey400' $isUpper>
                  {gamesCount?.total} Games
                </BodyS>
              </S.SeeAllGamesWrapper>
            ) : (
              <BodyL $color='grey100'>{option.name}</BodyL>
            )}
            <BodyM $color='grey300'>{option.description}</BodyM>
          </S.OptionTextWrapper>
          <Link to={option.to(params.eventId)}>
            <Right size={24} />
          </Link>
        </S.OptionWrapper>
      ))}
    </S.Container>
  );
};

export default Schedule;
