import { AxiosRequestConfig } from 'axios';

import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import APIServicesV3 from 'services/util/ApiServicesV3';
import { ConfigApi } from '../../config.api';
import { NotificationsView } from './types'

export default class NotificationsService {
  public static async getMyNotifications(): Promise<
    ServiceResponseV3<NotificationsView[]>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/notifications',
      method: 'GET',
    };
    return APIServicesV3.request<NotificationsView[]>(axiosConfig);
  }

  public static async setNotificationAsSeen(id: string): Promise<
    ServiceResponseV3<NotificationsView[]>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/notifications/${id}/as-seen`,
      method: 'POST',
    };
    return APIServicesV3.request<NotificationsView[]>(axiosConfig);
  }
}
