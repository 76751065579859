import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.grey950};
  width: 100%;
  border-radius: ${toRem(24)} ${toRem(24)} 0 0;
  gap: ${toRem(48)};

  .ant-tabs-tab-btn {
    p {
      margin-bottom: 0;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 22px;
  }
`;
