import React, { FC, memo } from 'react';

import { IDetailRoster } from 'services/v3/Match/interfaces';

import ConvertUtil from 'util/ConvertUtil';

import { BodyLBold } from 'styles/v3/variables';

import * as S from './styles';

export enum TeamRivalsType {
  TeamAway,
  TeamHome,
}

interface ITeamEventCardProps {
  selected?: boolean;
  type: TeamRivalsType;
  team: Maybe<IDetailRoster>;
  onClick: (team: TeamRivalsType) => void;
}

export const TeamEventCard: FC<ITeamEventCardProps> = memo(
  ({ selected = false, type, team, onClick }) => {
    const handleClick = () => onClick(type);

    return (
      <S.CardWrapper $selected={selected} onClick={handleClick}>
        <S.Avatar>
          {team && (
            <img src={ConvertUtil.getMinioUrl(team.logo)} alt={team.name} />
          )}
        </S.Avatar>
        <S.TextWrapper>
          <S.Title>{team?.clubName || '-'}</S.Title>
          <BodyLBold>{team?.name || 'TBA'}</BodyLBold>
        </S.TextWrapper>
      </S.CardWrapper>
    );
  }
);

TeamEventCard.displayName = 'TeamEventCard';
