import React, { useEffect, useMemo, useState } from 'react';

import { BodyXLBold } from 'styles/v3/variables';
import { TeamCard } from '../TeamCard';
import { useTeamPlacement } from '../../context/TeamPlacementContext';
import * as S from './styles';

export const TeamList = () => {
  const [containerHeight, setContainerHeight] = useState(700);
  const { rosters, openedPools, selectedDivision } = useTeamPlacement();

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (pool) {
        if (pool.clientHeight < 700) return;
        setContainerHeight(pool.clientHeight);
      }
    };

    const pool = document.getElementById('pool-container');

    pool?.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      pool?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [openedPools]);

  useEffect(() => {
    setContainerHeight(700);
  }, [selectedDivision?.id]);

  const selectedAgeDivisionRoster = useMemo(() => {
    if (!selectedDivision || !rosters) return [];

    return rosters.filter(
      (item) => item.ageDivision.id === selectedDivision?.id
    );
  }, [selectedDivision, rosters]);

  return (
    <S.Container>
      <S.Wrapper height={containerHeight} key={selectedDivision?.id}>
        <S.HeaderWrapper>
          <BodyXLBold $color='grey500'>Teams</BodyXLBold>
          <BodyXLBold $color='grey500'>
            {selectedAgeDivisionRoster?.length}
          </BodyXLBold>
        </S.HeaderWrapper>
        {selectedAgeDivisionRoster?.map((item) => (
          <S.TeamWrapper key={item?.id}>
            <TeamCard
              name={item?.club?.name}
              rosterId={item.id}
              logoSrc={item?.club?.logo}
            />
          </S.TeamWrapper>
        ))}
      </S.Wrapper>
    </S.Container>
  );
};
