import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Switch } from 'antd';
import { Country, State } from 'country-state-city';
import ConvertUtil from 'util/ConvertUtil';

import club_placeholder from 'assets/imgs/club_placeholder.png';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';

import InputText from 'components/v3/Forms/InputText/InputText';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';

import {
  BodyLBold,
  BodyM,
  BodyMBold,
  BodyS,
  BodyXL,
  TitleH2,
} from 'styles/v3/variables';

import * as S from './styles';

const PendingClub = () => {
  const params: { clubId: string } = useParams();
  const { data } = useGetClubById(params.clubId);

  const history = useHistory();

  function handlePreviousStep() {
    history.push('/v3/clubs');
  }

  const [defaultCountry, setDefaultCountry] = useState<OptionsType>();
  const [defaultState, setDefaultState] = useState<OptionsType>();

  useEffect(() => {
    const allCountries = Country.getAllCountries().map((i) => ({
      label: `${i.name} ${i.flag}`,
      value: i.isoCode,
    }));
    const findCountry = allCountries.find(
      (country) => data?.country === country.value
    );
    setDefaultCountry(findCountry);

    const statesOfCountry = State.getStatesOfCountry(findCountry?.value).map(
      (i) => ({
        label: i.name,
        value: i.isoCode,
      })
    );

    const findState = statesOfCountry.find(
      (state) => state.value === data?.state
    );
    setDefaultState(findState);
  }, [data]);

  return (
    <>
      <S.PageTitle $maxWidth={636}>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $isUpper>Club waiting Approval.</TitleH2>
      </S.PageTitle>

      <S.Wrapper $maxWidth={636}>
        <S.Form onSubmit={(e) => e.preventDefault()}>
          <S.Fieldset>
            <InputText
              required
              disabled
              id='name'
              placeholder='Club Name'
              label='Club Name'
              defaultValue={data?.name}
              className='input-field'
            />
            <InputText
              required
              disabled
              id='displayName'
              placeholder='Display Name'
              label='Display Name'
              defaultValue={data?.displayName}
              className='input-field'
            />
            <InputText
              disabled
              id='description'
              placeholder='Description of your Club'
              label='Description of your Club'
              defaultValue={data?.description}
              className='input-field'
            />
            <S.SwitchWrapper>
              <Switch checked={data?.allowApplications} disabled />
              <div className='switch-text'>
                <BodyMBold className='switch-title'>
                  Allow applications
                </BodyMBold>
                <BodyS $color='grey400'>
                  This will allow any person on the platform to apply to be in
                  your club. You will be able to approve or deny a application.
                </BodyS>
              </div>
            </S.SwitchWrapper>
          </S.Fieldset>

          <S.Fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Location
              </BodyXL>
            </legend>

            <div className='half-inputs'>
              <Select
                name='country'
                placeholder='Country'
                isDisabled
                value={defaultCountry}
                className='input-field'
              />
              <Select
                name='state'
                placeholder='State'
                isDisabled
                value={defaultState}
                className='input-field'
              />
            </div>
            <InputText
              required
              disabled
              id='city'
              placeholder='City'
              label='City'
              defaultValue={data?.city}
            />
          </S.Fieldset>

          <S.Fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Social Media
              </BodyXL>
            </legend>

            <InputText
              disabled
              id='instagram'
              placeholder='Instagram'
              label='Instagram'
              defaultValue={data?.socialMedias?.instagram}
              className='input-field'
            />
            <InputText
              disabled
              id='tiktok'
              placeholder='TikTok'
              label='TikTok'
              defaultValue={data?.socialMedias?.tiktok}
              className='input-field'
            />
            <InputText
              disabled
              id='x'
              placeholder='X (Formerly Twitter)'
              label='X (Formerly Twitter)'
              defaultValue={data?.socialMedias?.x}
              className='input-field'
            />
            <InputText
              disabled
              id='facebook'
              placeholder='Facebook'
              label='Facebook'
              defaultValue={data?.socialMedias?.facebook}
            />
          </S.Fieldset>

          <S.Fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Identity
              </BodyXL>
            </legend>

            <BodyLBold $color='white' $spanColor='supportError'>
              Logo <span>*</span>
            </BodyLBold>
            <BodyM $color='grey400' className='upload-file-text'>
              Upload a Logo for your club. The logo must be{' '}
              <BodyMBold>512px by 512px</BodyMBold>
            </BodyM>
            <S.ClubLogo>
              <img src={ConvertUtil.getMinioUrl(data?.logo)} alt='Club Logo' />
            </S.ClubLogo>

            <BodyLBold $color='white'>
              Header <BodyS $color='grey400'>(Optional)</BodyS>
            </BodyLBold>
            <BodyM className='upload-file-text'>
              Upload a Header for your club. The header will show up on your
              club page. It must be <BodyMBold>1440px by 250px</BodyMBold>
            </BodyM>
            <S.ClubHeader>
              <img
                src={
                  data?.header
                    ? ConvertUtil.getMinioUrl(data?.header)
                    : club_placeholder
                }
                alt='Club Header'
              />
            </S.ClubHeader>
          </S.Fieldset>
        </S.Form>
      </S.Wrapper>
    </>
  );
};

export default withRouter(PendingClub);
