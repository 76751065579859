import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

export const userTypesRole = [
  {
    label: 'Player',
    value: UserType.PLAYER,
  },
  {
    label: 'Coach',
    value: UserType.COACH,
  },
  {
    label: 'Club Director',
    value: UserType.CLUB_DIRECTOR,
  },
  {
    label: 'Referee',
    value: UserType.REFEREE,
  },
  {
    label: 'Team manager',
    value: UserType.TEAM_MANAGER,
  },
];

export const statusMap: Record<RequestStatus, DocumentStatusLabel> = {
  [RequestStatus.PENDING]: DocumentStatusLabel.PENDING,
  [RequestStatus.APPROVED]: DocumentStatusLabel.APPROVED,
  [RequestStatus.DECLINED]: DocumentStatusLabel.DECLINED,
  [RequestStatus.EXPIRED]: DocumentStatusLabel.EXPIRED,
};
