import styled from 'styled-components';

export const ScheduleCalendarContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const UnSchedule = styled.div`
  width: 100%;
  height: auto;
  background: red;
`;

export const CalendarItem =
  styled.div <
  { $hidden: boolean } >
  `
  ${({ $hidden }) =>
    $hidden &&
    `
    display: none;
  `}
`;
