import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { BodyL, COLORS } from 'styles/v3/variables';

export const Container = styled.div<{ $type: 'disapprove' | 'approve' }>`
  display: flex;
  flex-direction: column;

  gap: ${toRem(16)};

  ${BodyL} {
    margin-right: ${toRem(90)};
  }

  button {
    border: none;
    color: ${({ $type }) =>
      $type === 'approve' ? COLORS.grey700 : COLORS.white};
    background-color: ${({ $type }) =>
      $type === 'approve' ? COLORS.brandPrimary : COLORS.supportError};

    &:hover {
      color: ${({ $type }) =>
        $type === 'approve' ? COLORS.brandPrimary : COLORS.supportError};
    }
  }
`;
