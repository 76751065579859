import React, { useCallback, useEffect, useState } from 'react';
import { InputFormat } from 'components/v3/Forms/InputFormat/InputFormat';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { useGetDeleteSchedule } from 'hooks/v3/schedules/useGetDeleteSchedule';
import { useParams } from 'react-router-dom';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { useGroups } from 'pages/V3/EventManager/components/Groups/context/GroupsContext';
import { DivisionsRules } from 'pages/V3/EventManager/components/Groups/components/DivisionsInfo/components/DivisionsRules';
import * as S from './styles';

export const DivisionsInfo = () => {
  const {
    selectedDivision,
    updateDivisionFn,
    setSelectedDivision,
  } = useGroups();
  const { eventId } = useParams<{ eventId: string }>();
  const { data: currentSchedule } = useGetScheduleByEventId(eventId);
  const { mutateAsync: deleteSchedule } = useGetDeleteSchedule(eventId);

  const initialValues = {
    allowCrossPoolGames: !!selectedDivision?.allowCrossPoolGames,
    gamesPerTeam: selectedDivision?.gamesPerTeam?.toString() || '',
  };

  const [gamesPerTeam, setGamesPerTeam] = useState(initialValues.gamesPerTeam);
  const [allowCrossPoolGames, setAllowCrossPoolGames] = useState(
    initialValues.allowCrossPoolGames
  );

  const debounceGamesPerTeam = useDebounce(gamesPerTeam, 750);

  const handleSaveEdit = useCallback(async () => {
    if (
      selectedDivision &&
      debounceGamesPerTeam &&
      debounceGamesPerTeam !== initialValues.gamesPerTeam
    ) {
      const newDivision: NewAgeDivision = {
        ...selectedDivision,
        gamesPerTeam: Number(gamesPerTeam),
      };

      setSelectedDivision(newDivision);

      await updateDivisionFn({ data: newDivision });
      if (currentSchedule) {
        await deleteSchedule();
      }
    }
  }, [selectedDivision, debounceGamesPerTeam, updateDivisionFn]);

  const handleCheckbox = async () => {
    if (selectedDivision) {
      const updatedAllowCrossPoolGames = !allowCrossPoolGames;

      setAllowCrossPoolGames(updatedAllowCrossPoolGames);

      const newDivision: NewAgeDivision = {
        ...selectedDivision,
        allowCrossPoolGames: updatedAllowCrossPoolGames,
      };

      setSelectedDivision(newDivision);

      await updateDivisionFn({ data: newDivision });
    }
  };

  useEffect(() => {
    setGamesPerTeam(initialValues.gamesPerTeam);
    setAllowCrossPoolGames(initialValues.allowCrossPoolGames);
  }, [selectedDivision]);

  useEffect(() => {
    if (debounceGamesPerTeam) handleSaveEdit();
  }, [debounceGamesPerTeam]);

  return (
    <S.Container>
      {selectedDivision && (
        <>
          <InputFormat
            prefix=''
            id='gamesPerTeam'
            allowNegative={false}
            thousandSeparator={false}
            value={gamesPerTeam || ''}
            label='How many games per team'
            placeholder='How many games per team'
            onChange={({ target }) => setGamesPerTeam(target.value)}
          />
          {/* TODO correct checkbox behaviour, at the moment it filter out pools from the scheduler which is not correct */}
          {/* <S.ElementCheckboxWrapper>
            <InputCheckbox
              id='allowCrossPoolGames'
              onChange={handleCheckbox}
              name='allowCrossPoolGames'
              checked={allowCrossPoolGames}
            />
            <BodyM $color='grey100'>Allow cross pool games</BodyM>
          </S.ElementCheckboxWrapper> */}
        </>
      )}

      <DivisionsRules />
    </S.Container>
  );
};
