export const TAB_ITEMS = [
  {
    name: 'my events',
    link: '/v3/events?tab=my-events',
    path: '/v3/events?tab=my-events',
  },
  {
    name: 'created by me',
    link: '/v3/setup-event/my-events',
    path: '/v3/setup-event/my-events',
  },
  {
    name: 'discover events',
    link: '/v3/events?tab=discover-events',
    path: '/v3/events?tab=discover-events',
  },
];
