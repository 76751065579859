import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

import Trophy1 from 'assets/imgs/trophies/trophy-1.png';
import Trophy2 from 'assets/imgs/trophies/trophy-2.png';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';

export const PlanCardWrapper = styled.div<{
  $theme: 'transparent' | 'primary' | 'secondary';
  $size: 'small' | 'large';
  $disabled?: boolean;
}>`
  .simple-card-wrapper {
    justify-content: space-between;
    width: 345px;
    max-width: ${(props) => (props.$size === 'small' ? '230px' : '345px')};
    height: 100%;
    max-height: ${(props) => {
      if (props.$size === 'small') {
        return '146px';
      } if (props.$disabled) {
        return '355px';
      }
      return 'auto';
    }};
    border-color: ${(props) => {
      if (props.$disabled) return COLORS.grey900;
      if (props.$theme === 'primary') {
        return COLORS.brandPrimary;
      } if (props.$theme === 'secondary') {
        return COLORS.brandSecondary;
      }
      return COLORS.white;
    }};
    color: ${(props) => {
      if (props.$disabled) return COLORS.grey200;
      if (props.$theme === 'primary') {
        return COLORS.grey950;
      }
      return COLORS.white;
    }};
    background-color: ${(props) => {
      if (props.$disabled) return COLORS.grey900;
      if (props.$theme === 'primary') {
        return COLORS.brandPrimary;
      } if (props.$theme === 'secondary') {
        return COLORS.brandSecondary;
      }
      return 'transparent';
    }};

    * {
      pointer-events: none;
    }
    input,
    button {
      pointer-events: all;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .simple-card-wrapper  {
      width: auto;
      max-width: ${toRem(384)};
    }
  }
`;

export const PlanCardContainer = styled.div<{
  $theme: 'transparent' | 'primary' | 'secondary';
  $size: 'small' | 'large';
  $disabled?: boolean;
}>`
  &:after {
    content: '';
    position: absolute;
    width: ${(props) => (props.$size === 'small' ? '0' : '192px')};
    height: ${(props) => (props.$size === 'small' ? '0' : '250px')};
    right: -2px;
    top: -60px;
    background: ${(props) => {
      if (props.$size === 'large' && props.$theme === 'primary') {
        return `url(${Trophy1}) right top no-repeat`;
      } if (props.$size === 'large' && props.$theme === 'secondary') {
        return `url(${Trophy2}) right top no-repeat`;
      }
      return 'none';
    }};
  }

  h2 {
    max-width: 65%;
    margin-bottom: ${toRem(10)};
  }

  .card-title {
    text-transform: uppercase;
  }
  .card-description {
    max-width: 70%;
  }
  .card-price {
    margin: ${(props) => (props.$size === 'large' ? `${toRem(40)} 0` : '0')};
  }

  button {
    width: 100%;
    &.plan-card-see-more-button {
      max-width: 60%;
      margin: 0 auto;
    }
  }
`;

export const DetailsWrapper = styled.ul<{ $show: boolean }>`
  height: ${(props) => (props.$show ? 'auto' : '0')};
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style: none;

  li {
    margin-bottom: ${toRem(12)};

    &:first-child {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`;
