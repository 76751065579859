import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const HeaderWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  button {
    min-height: 0;
    max-height: ${toRem(48)};
    color: ${COLORS.grey300};
  }

  @media screen and (max-width: ${BREAKPOINTS.desktop}) {
    button {
      margin-bottom: ${toRem(8)};
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.tablet}) {
    button {
      margin-bottom: ${toRem(8)};
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    align-items: flex-start;

    gap: ${toRem(16)};

    button {
      width: 100%;
    }
  }
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(4)};

  h1 {
    margin-bottom: 0;
  }
`;
