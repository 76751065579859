import { IMemberAttendancePayload } from 'services/v3/Rosters/types';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RosterService from 'services/v3/Rosters/RosterService';

export const useChangeMemberAttendance = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: IMemberAttendancePayload) =>
      RosterService.changeMemberAttendance(payload),
    onSuccess: async (_, payload) => {
      notification.success({
        message: 'Member was updated successfully!',
      });
      queryClient.invalidateQueries([
        '/change-member-attendance',
        payload.rosterId,
        payload.matchId,
      ]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
