import React from 'react';
import { Dropdown, Menu } from 'antd';
import { BodyL, BodyM, BodyS, BodySBold, COLORS } from 'styles/v3/variables';
import { Dot } from '@icon-park/react';
import { notification } from 'components/v3/Notification/notification';

import { useHistory } from 'react-router-dom';
import { Pools } from 'admin/models/AgeDivision';
import {
  Container,
  ContainerSpace,
  DivisionInformation,
  DivisionNotFormated,
  DivisionTags,
  DivisionTagsWrapper,
  DivisionText,
  DivisionTextBox,
  DivisionTextWrapper,
  TagColor,
} from './styles';

interface EventDivisionProps {
  id: string;
  name: string;
  gender: string;
  totalYears: number;
  firstYear: number;
  allowMixedTeams: boolean;
  bracketType: string;
  pools: Pools[];
  format: string;
  color: string;
}

export const DivisionCard = ({
  id,
  name,
  gender,
  totalYears,
  firstYear,
  allowMixedTeams,
  bracketType,
  pools,
  format,
  color,
}: EventDivisionProps) => {
  const history = useHistory();

  const getGenderLabel = (): string => {
    if (gender === 'MALE') {
      return `Boys`;
    }
    if (gender === 'FEMALE') {
      return `Girls`;
    }
    return '';
  };

  const getAllowedOtherGender = (): string => {
    if (allowMixedTeams) {
      if (gender === 'MALE') {
        return `Allow Girls`;
      }
      if (gender === 'FEMALE') {
        return `Allow Boys`;
      }
    }
    return '';
  };

  const getYear = (): string => {
    if (totalYears > 1) {
      return `${firstYear} ${totalYears}`;
    }
    return `${firstYear} Only`;
  };

  return (
    <>
      <Container>
        <ContainerSpace>
          <TagColor style={{ backgroundColor: color }} />
          <DivisionInformation>
            <DivisionText>
              <BodyL>{name}</BodyL>
              <DivisionTextWrapper>
                <DivisionTextBox>
                  <BodyS>{getGenderLabel()}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  <Dot fill={COLORS.grey800} />
                  <BodyS>{getYear()}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  <Dot fill={COLORS.grey800} />
                  <BodyS>{getAllowedOtherGender()}</BodyS>
                </DivisionTextBox>
              </DivisionTextWrapper>
            </DivisionText>
            {bracketType || pools.length > 0 || format ? (
              <DivisionTagsWrapper>
                {format && (
                  <DivisionTags>
                    <BodyS>Format</BodyS>
                    <BodySBold $color='grey50'>{format}</BodySBold>
                  </DivisionTags>
                )}
                {pools.length > 0 && (
                  <DivisionTags>
                    <BodyS>Pools</BodyS>
                    <BodySBold $color='grey50'>{pools.length}</BodySBold>
                  </DivisionTags>
                )}
                {bracketType && (
                  <DivisionTags>
                    <BodyS>Pools</BodyS>
                    <BodySBold $color='grey50'>{bracketType}</BodySBold>
                  </DivisionTags>
                )}
              </DivisionTagsWrapper>
            ) : (
              <DivisionTagsWrapper>
                <DivisionNotFormated>
                  <BodyS $color='grey400'>Not formated</BodyS>
                </DivisionNotFormated>
              </DivisionTagsWrapper>
            )}
          </DivisionInformation>
        </ContainerSpace>
      </Container>
    </>
  );
};
