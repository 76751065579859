import styled from 'styled-components';
import { color } from 'styles/styleVariables';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  background: ${COLORS.white};
  min-width: 1200px;

  @media print {
    @page {
      size: landscape;
    }
    background: none !important;
  }

  .printButton {
    @media print {
      display: none;
    }
    cursor: pointer;
    position: absolute;
    right: 0;
    background-color: ${color.orange};
    font-weight: 800;
    padding: 10px;
    color: ${color.greyDark};
    width: 50px;
    display: flex;
    width: 50px;
    height: 50px;
    overflow: hidden;
    align-items: center;
    &:hover {
      width: auto;
      div {
        display: block;
      }
    }
    div {
      display: none;
    }
  }
`;

export const Content = styled.div`
  padding: 0 50px;
  color: ${COLORS.grey500};
`;

const font = {
  druk: 'Druk Wide Cyr',
  montserrat: 'Montserrat, sans-serif',
};

export const PrintWrapper =
  styled.div <
  { isMultiple: boolean } >
  `

  display: grid;
  grid-template-columns: 100fr 15fr;

  padding: 0 20px;
  margin: 0 auto;
  margin-top: 20px;

  font-weight: 400;
  @media print {
    .pagebreak {
      page-break-before: always;
    } /* page-break-after works, as well */
  }
  .top {
    width: 100%;
    display: flex;
    .box-tournament,
    .box-club {
      width: 200px;
      .wrapper {
        padding: 20px;
        border-radius: 10px;
        border: 2px solid ${color.greyDark};

        .top-box {
          .title {
            .type {
              font-size: 10px;
            }
            .name {
              font-family: ${font.druk};
              font-size: 12px;
            }
          }
        }
        .logo {
          width: 100px;
          height: 100px;
          border-radius: 80px;
          border: 1px solid #ccc;
          margin: 0 auto;
          margin-top: 20px;
          background-color: ${color.greyDark};
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          align-items: center;
          .img {
            width: 70%;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }
        }
      }
    }
    .box-info {
      text-align: center;
      padding: 10px;
      flex: 1;
      .table {
        text-align: left;
        display: grid;
        grid-column-gap: 5px;
        grid-template-columns: 1fr 2fr;
        width: 80%;
        margin: 20px auto;
      }
    }
  }
`;

export const SideContent = styled.div`
  @media print {
    border-color: #000 !important;
    color: #000 !important;
  }
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  font-size: 12px;
  .content {
    border-bottom: 2px solid ${color.greyLight};
    border-left: 2px solid ${color.greyLight};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 16vh;

    @media print {
      border-color: #000;
    }
    & > div {
      padding: 5px;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      border-bottom: 2px solid ${color.greyLight};
      border-left: 2px solid ${color.greyLight};
      @media print {
        border-color: #000;
      }
      .title {
        margin-left: 5px;
      }
      .info {
        font-weight: 800;
      }
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  @media print {
    border-color: #000 !important;
    color: #000 !important;
  }
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
  text-align: center;
  margin-bottom: 10px;
  align-items: center;
  .img {
    img {
      width: 50px;
    }
  }
  .text-sm {
    font-size: 18px;
    font-weight: 800;
  }
  .text-xs {
    font-size: 14px;
    font-weight: 800;
  }
  .wrapper-foul {
    display: inline-grid;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 2px solid ${color.greyLight};
    border-left: 2px solid ${color.greyLight};
    @media print {
      border-color: #000;
    }

    & > div {
      border-top: 2px solid ${color.greyLight};
      border-right: 2px solid ${color.greyLight};
      position: relative;
      @media print {
        border-color: #000;
      }
      .number {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 2px;
        background-color: ${color.greyLight};
        color: #fff;
        height: 20px;
        width: 20px;
        font-weight: 800;
        font-size: 9px;
        @media print {
          top: 0px;
          left: 0px;
          padding: 0px;
          height: 15px;
          width: 15px;
          position: relative;
          background-color: #000;
          -webkit-print-color-adjust: exact;
          color: #fff;
        }
      }
    }
  }
`;

export const Main = styled.div`
  @media print {
    border-color: #000 !important;
    color: #000 !important;
  }

  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  .listPlayers {
    border-left: 2px solid ${color.greyLight};
    border-bottom: 2px solid ${color.greyLight};
    @media print {
      border-color: #000;
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 5fr 1fr 1fr;
      div {
        padding: 5px;
        min-height: 20px;
        border-top: 2px solid ${color.greyLight};
        border-right: 2px solid ${color.greyLight};
        @media print {
          border-color: #000;
        }
      }
    }
  }
  .goalsTable {
    border-left: 2px solid ${color.greyLight};
    border-bottom: 2px solid ${color.greyLight};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media print {
      border-color: #000;
    }
    & > div {
      padding: 2px;
      min-height: 45px;
      border-top: 2px solid ${color.greyLight};
      border-right: 2px solid ${color.greyLight};
      position: relative;
      @media print {
        border-color: #000;
      }
      .number {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 2px;
        background-color: ${color.greyLight};
        color: #fff;
        height: 20px;
        width: 20px;
        font-weight: 800;
        font-size: 9px;
        @media print {
          top: 0px;
          left: 0px;
          padding: 0px;
          height: 15px;
          width: 15px;
          position: relative;
          background-color: #000;
          -webkit-print-color-adjust: exact;
          color: #fff;
        }
      }
    }
  }
  .coachList {
    margin-top: 10px;
    border-left: 2px solid ${color.greyLight};
    border-bottom: 2px solid ${color.greyLight};
    @media print {
      border-color: #000;
    }
    & > div {
      min-height: 30px;
      padding: 5px;
      border-right: 2px solid ${color.greyLight};
      border-top: 2px solid ${color.greyLight};
      @media print {
        border-color: #000;
      }
    }
  }
`;

export const Border = styled.div`
  border: 2px solid ${color.greyLight};
  @media print {
    border-color: #000;
  }
`;

export const Box = styled.div`
  border: 2px solid ${color.greyLight};
  height: 100%;
  padding: 10px;
  @media print {
    border-color: #000;
  }
`;
