import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import ScheduleService from 'services/v3/Schedule/ScheduleService';

export const useGetDeleteSchedule = (eventId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await ScheduleService.deleteSchedule(eventId);
    },
    onSuccess: async () => {
      notification.success({
        message: 'Schedule removed successfully!',
      });

      await queryClient.invalidateQueries([
        'get-schedule-by-event-id',
        eventId,
      ]);
    },
    onError: (error: any) => {
      notification.error({
        message: `Can't remove schedule`,
        description: error.message,
      });
    },
  });
};
