import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { CONTENT_WIDTH } from '../styles';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  max-width: 1200px;
  padding: ${toRem(50)} 0;

  @media (max-width: ${CONTENT_WIDTH}) {
    padding: 0 ${toRem(20)};
  }
`;
