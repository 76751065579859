/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';

import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'components/v3/Forms/Select/Select';
import { BodyLBold, BodyM } from 'styles/v3/variables';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { motion } from 'framer-motion';
import Loading from 'components/v3/Loading/Loading';

import { ScheduleMatch } from 'models/Schedule/ScheduleModel';
import { Header, TeamDivisionCard } from '../../components';
import * as S from './styles';

type RostersType = {
  id: string;
  games: number;
  clubName?: string;
  clubLogoSrc?: string;
  clubId?: string;
};

const FindYourTeam = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();

  const [selectedDivision, setSelectedDivision] = useState('');

  const { data: response, isLoading: isLoadingEvent } = useGetEvent(eventId);
  const {
    data: schedule,
    isLoading: isLoadingSchedule,
  } = useGetScheduleByEventId(eventId);

  const isLoading = isLoadingEvent || isLoadingSchedule;

  // ENG-240 TODO edited after change ScheduledModel/ScheduledDays
  const rosters = useMemo(() => {
    if (!schedule) return [];

    const daysOfCurrentDivision = schedule.days.filter((day) => {
      const match = day.matches?.find(
        (matchItem) =>
          matchItem.ready && matchItem.ageDivisionId === selectedDivision
      );
      return match;
    });

    const allMatches: ScheduleMatch[] = daysOfCurrentDivision.reduce(
      (acc, day) => {
        if (day?.matches) {
          acc.push(...day.matches);
        }
        return acc;
      },
      [] as ScheduleMatch[]
    );

    return allMatches
      .flatMap((match) => (match ? [match.opponent1, match.opponent2] : []))
      .reduce((acc, opponent) => {
        const { rosterId, clubId } = opponent;
        if (rosterId) {
          const existingRosterIndex = acc.findIndex(
            (roster) => roster.id === rosterId
          );
          if (existingRosterIndex === -1) {
            acc.push({
              clubId,
              games: 1,
              id: rosterId,
              clubName: opponent.clubName,
              clubLogoSrc: opponent.clubLogo,
            });
          } else {
            acc[existingRosterIndex].games += 1;
          }
        }
        return acc;
      }, [] as Array<RostersType>);
  }, [schedule, selectedDivision]);

  const options = useMemo(
    () =>
      response?.data?.ageDivisions?.map((division) => ({
        value: division.id || '',
        label: division.name || '',
      })) || [],
    [response?.data]
  );

  const defaultOption = options.find(
    (option) => option.value === selectedDivision
  );

  const updateQueryParams = (paramName: string, value: string) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set(paramName, value);
    history.push(newUrl.pathname + newUrl.search);
  };

  const handleChangeDivision = (event: any) => {
    setSelectedDivision(event.value);
    updateQueryParams('division', event.value);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const divisionIdFromUrl = urlParams.get('division');
    if (divisionIdFromUrl) setSelectedDivision(divisionIdFromUrl);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <Header
        withGobBack
        withLogo={false}
        event={response?.data}
        customName='Find your team'
      />
      <S.AgeDivisionsWrapper>
        <BodyLBold $color='grey50'>Select Age Division</BodyLBold>
        <Select
          options={options}
          name='ageDivision'
          defaultValue={defaultOption}
          onChange={handleChangeDivision}
          placeholder='Select Age Division'
        />
        <BodyM $color='grey400' $isUpper>
          Teams in this division
        </BodyM>
      </S.AgeDivisionsWrapper>

      <S.RostersWrapper>
        {rosters &&
          rosters?.map((roster) => (
            <motion.div
              key={roster.id}
              initial={{ height: 0, opacity: 0 }}
              animate={{
                height: 'auto',
                opacity: 1,
                transition: {
                  type: 'spring',
                  bounce: 0.3,
                },
              }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                duration: 4 * 0.15,
                type: 'spring',
                bounce: 0,
              }}
            >
              <TeamDivisionCard
                key={roster.id}
                games={roster.games}
                name={roster.clubName}
                clubId={roster.clubId}
                logoSrc={roster.clubLogoSrc}
                divisionId={selectedDivision}
              />
            </motion.div>
          ))}
      </S.RostersWrapper>
    </S.Container>
  );
};

export default FindYourTeam;
