import React, { FC, memo, useState, useMemo, useCallback } from 'react';
import { MultiValue } from 'react-select';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import { RadioGroup } from 'components/v3/Forms/RadioGroup/RadioGroup';
import InputSelect, { OptionsType } from 'components/v3/Forms/Select/Select';

import { BodyM, TitleH4 } from 'styles/v3/variables';

import { GenderOptionValues } from './enums';
import { GENDER_OPTIONS, EMPTY_STATE_MESSAGES } from './configs';

import * as S from './styles';

interface IAgeSectionProps {
  ageDivisions?: NewAgeDivision[];
};

export const AgeSection: FC<IAgeSectionProps> = memo(({ ageDivisions }) => {
  const [selectedYears, setSelectedYears] = useState<MultiValue<OptionsType>>(
    []
  );
  const [gender, setGender] = useState(GenderOptionValues.All);

  const yearsOptions = useMemo(() => {
    return Array.from(
      new Set(ageDivisions?.flatMap((item) => item.years))
    ).map((value) => ({
      value,
      label: value.toString(),
    }))
  }, [ageDivisions]);

  const divisions = useMemo(() => {
    const filteredByGender = ageDivisions?.filter((item) => {

      if (gender === GenderOptionValues.All) {
        return true;
      }

      if (gender) {
        return item.gender.toLowerCase() === gender.toLowerCase();
      }

      return true;
    });

    const filteredByYear = filteredByGender?.filter((item) =>
      selectedYears.length > 0
        ? selectedYears.some((year) =>
            item.years.includes(year.value as number)
          )
        : true
    );

    return filteredByYear;
  }, [ageDivisions, gender, selectedYears]);

  const emptyStateMessage = useMemo(() => {
    return EMPTY_STATE_MESSAGES.find((message) => message.type === gender)?.text || '';
  }, [gender]);

  const getDivisionRules = useCallback((division: NewAgeDivision) => {
    let baseInfo = `${division.gender} - ${division.years}`;

    if (division.gender === 'MALE' && division.allowGirlBoysOnAnotherDivision) {
      baseInfo = `${baseInfo} - Allow Girls`;
    }

    if (division.gender === 'COED' && division.coedRules) {
      baseInfo = `${baseInfo} - ${division.coedRules}`;
    }

    return baseInfo;
  }, []);

  return (
    <S.SectionWrapper>
      <TitleH4 $isUpper>Age divisions</TitleH4>
      <S.FiltersWrapper>
        <S.SelectWrapper>
          <BodyM $isUpper>Year</BodyM>
          <InputSelect
            isMulti
            options={yearsOptions}
            hideSelectedOptions={false}
            onChange={(values) => setSelectedYears(values as any)}
          />
        </S.SelectWrapper>
        <RadioGroup
          label='Gender'
          onChange={(e) => setGender(e.target.value)}
          value={gender}
          options={GENDER_OPTIONS}
        />
      </S.FiltersWrapper>
      <S.CardWrapper>
        {divisions?.map((division) => (
          <S.EventCardDivision
            key={division.id}
            size='small'
            orientation='row'
            stripeColor={division.color}
            title={division.name ?? ''}
            description={getDivisionRules(division)}
          />
        ))}
      </S.CardWrapper>
      {!divisions?.length && (
        <BodyM>{emptyStateMessage}</BodyM>
      )}
    </S.SectionWrapper>
  );
});

AgeSection.displayName = 'AgeSection';
