import React, { FunctionComponent, useMemo } from 'react';
import { COLORS } from 'styles/v3/variables';
import { MatchV3Model } from 'models/Match/MatchModel';
import { BracketRenderProps } from '../../BracketRender';
import { BracketColumn } from '../../styles';
import { BracketGame, SingleItemBracketGame } from '../BracketGame';

export const SingleElimination4Render: FunctionComponent<BracketRenderProps> = ({
  bracketId,
  matches,
  handleOpenEditModal,
  onUpdate,
}) => {
  const [column1, column2] = useMemo(() => {
    const columns: MatchV3Model[][] = [[], []];

    matches.forEach((match) => {
      console.log(match);
      if (match.bracketMatchCount === 1 || match.bracketMatchCount === 2) {
        columns[0].push(match);
      } else if (match.bracketMatchCount === 3) {
        columns[1].push(match);
      }
    });

    return columns;
  }, [matches]);

  return (
    <>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={column1[0]}
          to={{
            color: COLORS.brandPrimary,
            down: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          showPens
          titleSourceEmpty='Select Source'
          onUpdate={onUpdate}
        />
        <BracketGame
          bracketId={bracketId}
          match={column1[1]}
          to={{
            color: COLORS.brandPrimary,
            up: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          showPens
          titleSourceEmpty='Select Source'
          onUpdate={onUpdate}
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={column2[0]}
          to={{
            color: COLORS.brandPrimary,
            side: true,
          }}
          from={{
            up: true,
            down: true,
            color: COLORS.brandPrimary,
          }}
          handleOpenEditModal={handleOpenEditModal}
          onUpdate={onUpdate}
        />
      </BracketColumn>
      <BracketColumn>
        <SingleItemBracketGame
          title='Winner'
          from={{ side: true, color: COLORS.brandPrimary }}
        />
      </BracketColumn>
    </>
  );
};
