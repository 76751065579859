import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-height: ${toRem(45)};

  border-radius: ${toRem(12)};
  padding: ${toRem(8)} ${toRem(12)} ${toRem(8)} ${toRem(12)};

  background-color: ${({ isSelected }) => isSelected ? COLORS.grey900 : 'unset'};

  cursor: pointer;

  transition: all 0.15s;

  span {
    margin: 0;

    svg {
      margin-top: ${toRem(3)};
    }
  }

`;
