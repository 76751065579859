import React from 'react';
import { TableProps } from 'antd/lib/table';

import { StyledTable } from './styles';

export const Table = (props: TableProps<any>) => {
  const { pagination } = props;

  return <StyledTable pagination={{ ...pagination }} {...props} />;
};
