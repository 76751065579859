import React from 'react';

import { TeamPlacementInfo } from '../TeamPlacementInfo';
import { TeamList } from '../TeamsList';
import { PoolsList } from '../PoolsList';
import * as S from './styles';

export const TeamWrapper = () => {
  return (
    <S.Container>
      <S.TeamWrapper>
        <TeamPlacementInfo />
        <TeamList />
        <PoolsList />
      </S.TeamWrapper>
    </S.Container>
  );
};
