import React, { useState, useEffect, useMemo } from 'react';
import {
  ScheduleDay,
  ScheduleMatch,
  ScheduleMatchOpponent,
  ScheduleType,
} from 'models/Schedule/ScheduleModel';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCreateSchedule } from 'hooks/v3/schedules/useCreateSchedule/useCreateSchedule';

import { useUpdateScheduleByEventId } from 'hooks/v3/schedules/useUpdateScheduleByEventId/useUpdateScheduleByEventId';
import moment from 'moment';
import { useGetMathesEvent } from 'hooks/v3/matches/useGetMatchesEvent/useGetMatchesEvent';
import ConvertUtil from 'util/ConvertUtil';
import { useSendNotification } from 'hooks/v3/schedules/useSendNotification/useSendNotification';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import { MatchStatus } from 'models/Match/MatchModel';
import { CustomCalendarProps } from '../../types';
import ScheduledWrapper from '../ScheduledWrapper/ScheduledWrapper';
import CalendarHeader from '../CalendarHeader';

const CustomCalendar = ({
  allSportEvent,
  allDays,
  currentSchedule,
  activeDivision,
  divisionColors,
  scheduleRules,
  poolMatches,
  divisionsData,
}: CustomCalendarProps) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { eventId } = useParams<{ eventId: string }>();
  const { data: matches } = useGetMathesEvent(eventId ?? '');
  const day = params.get('day');
  const history = useHistory();

  const {
    mutateAsync: createSchedule,
    isLoading: isCreating,
  } = useCreateSchedule();
  const {
    mutateAsync: updateSchedule,
    isLoading: isUpdating,
  } = useUpdateScheduleByEventId();
  const { mutateAsync: sendNotification } = useSendNotification(eventId);

  const [activeScheduleType, setActiveScheduleType] = useState<ScheduleType>(
    'POOL_GAMES'
  );

  const matchesArray = useMemo(() => {
    return matches.filter((match) => {
      return (
        day &&
        (match.status === MatchStatus.UNSCHEDULED ||
          (match.date && moment(match.date).format('YYYY-MM-DD') === day))
      );
    });
  }, [matches, day]);

  const blockedSaveSchedule = useMemo(() => {
    return matches?.length > 0
      ? matches?.some((match) => match.status === MatchStatus.UNSCHEDULED)
      : true;
  }, [matches]);

  const allScheduledMatches = useMemo(() => {
    return matches.filter((match) => match.status === MatchStatus.SCHEDULED);
  }, [matches]);

  const daysArray = useMemo(() => { 
    const days = allDays.map((day) => day.date)
    return days.filter((day, index, array) => array.indexOf(day) === index) 
  }, [allDays]);

  const scheduleDays: ScheduleDay[] = useMemo(() => {
    return allDays?.map((dayItem) => {
      const matchesOfCurrentDay: ScheduleMatch[] = allScheduledMatches
        .filter((match) =>
          moment(match.date, 'YYYY-MM-DD').isSame(dayItem?.date)
        )
        .map((match) => {
          const poolMatch = poolMatches.find(
            (divMatch) =>
              divMatch.opponent1.teamId === match.homePlaceHolder.id ||
              divMatch.opponent1.teamId === match.awayPlaceHolder.id ||
              divMatch.opponent2.teamId === match.homePlaceHolder.id ||
              divMatch.opponent2.teamId === match.awayPlaceHolder.id
          );
          const division = divisionsData.find(
            (div) => div.id === match.ageDivisionId
          );
          const modifiedOp1 = ConvertUtil.filterParams({
            clubId: match?.homeTeam?.clubId ?? '',
            score: poolMatch?.opponent1?.score ?? 0,
            result: poolMatch?.opponent1.result,
            clubName: match?.homeTeam?.clubName ?? match.homePlaceHolder.name,
            clubLogo: match.homeTeam?.logo ?? '',
            rosterId: match?.homeTeam?.id ?? poolMatch?.opponent1.rosterId ?? '',
          });
          const modifiedlOp2 = ConvertUtil.filterParams({
            clubId: match?.awayTeam?.clubId ?? '',
            score: poolMatch?.opponent2.score ?? 0,
            result: poolMatch?.opponent2.result ?? 'DRAW',
            clubName: match?.awayTeam?.clubName ?? match.awayPlaceHolder.name,
            clubLogo: match.awayTeam?.logo ?? '',
            rosterId: match?.awayTeam?.id ?? poolMatch?.opponent2.rosterId ?? '',
          });
          return {
            name: match.name,
            opponent1: modifiedOp1 as ScheduleMatchOpponent,
            opponent2: modifiedlOp2 as ScheduleMatchOpponent,
            ready: false,
            type: match.sourceType === 'POOL' ? 'POOL_GAMES' : 'BRACKET_GAMES',
            courtName: match?.subVenue ?? '',
            venueId: match.venue.venueId,
            divisionName: division?.name ?? '',
            venueName: match.venue.venueName,
            matchId: match.matchId,
            ageDivisionId: match.ageDivisionId,
            startTime: moment(match.date).format('YYYY-MM-DDTHH:mm:ss'),
            endTime: moment(match.date)
              .add(
                match.sourceType === 'POOL'
                  ? scheduleRules.poolGamesLength
                  : scheduleRules.bracketGamesLength,
                'minutes'
              )
              .format('YYYY-MM-DDTHH:mm:ss'),
          };
        });
      return {
        type: activeScheduleType,
        day: moment(dayItem?.date).format('YYYY-MM-DD'),
        matches: matchesOfCurrentDay,
      };
    });
  }, [
    allDays,
    activeScheduleType,
    allScheduledMatches,
    scheduleRules.bracketGamesLength,
    scheduleRules.poolGamesLength,
    poolMatches,
    divisionsData,
  ]);

    const isBlockedPublish = useMemo(() => {
      const hasMissingFields: boolean[] = [];
      currentSchedule?.days?.forEach((day) => {
        const isBlockedPublish = day.matches?.every((match) => {
          return (
            match?.opponent1?.clubId &&
            match?.opponent1?.rosterId &&
            match?.opponent1?.clubName &&
            match?.opponent2?.clubId &&
            match?.opponent2?.rosterId &&
            match?.opponent2?.clubName
          );
        });
        hasMissingFields.push(!!isBlockedPublish);
      });

      return !hasMissingFields.every((field) => field);
    }, [currentSchedule]);

  const resendNotification = () => {
    confirm({
      message: 'Do you want to resend the notification?',
      onOk: async () => await sendNotification()
    })
  }

  const handleSaveSchedule = async () => {
    if (!isCreating && !isUpdating) {
      if (currentSchedule) {
        await updateSchedule({
          dto: { days: scheduleDays, ageDivisionId: activeDivision },
          id: eventId,
        });
        if (currentSchedule?.published && !isBlockedPublish) {
          resendNotification();
        }
      } else {
        await createSchedule({
          eventId,
          days: scheduleDays,
        });
      }
      } 
    };

  const handlePlacement = () => {
    history.push(`/v3/event/${eventId}/dashboard/team-placement`);
  };

  useEffect(() => {
    if (window && window.document) {
      allDays[0].courts.map((item) => {
        const contentElement = document.getElementById(`content-${item.name}`);
        if (contentElement) {
          const elementWidth = contentElement.offsetWidth;
          const headerElement = document.getElementById(`header-${item.name}`);
          if (headerElement) {
            const headerWidth = headerElement.offsetWidth;
            if (headerWidth < elementWidth) {
              headerElement.style.width = `${elementWidth}px`;
            }
          }
        }
      });
    }
  }, [allDays, allSportEvent]);

  return (
    <>
      <CalendarHeader
        loading={isCreating || isUpdating}
        setScheduleType={setActiveScheduleType}
        scheduleType={activeScheduleType}
        onHandleSave={handleSaveSchedule}
        onHandlePlacement={handlePlacement}
        currentSchedule={currentSchedule}
        isBlockedPublish={isBlockedPublish}
        isPublished={!!currentSchedule?.published}
        isBlockedSave={blockedSaveSchedule}
      />
      <ScheduledWrapper
        days={daysArray}
        matches={matchesArray}
        courts={allDays[0].courts} // ENG-62 temporary hardcode
        scheduleType={activeScheduleType}
        activeDivision={activeDivision}
        divisionColors={divisionColors}
        scheduleRules={scheduleRules}
      />
    </>
  );
};

export default CustomCalendar;
