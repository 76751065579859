import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    padding: 0;
    border: none;
    min-height: 0;

    &:hover {
      background: unset;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(24)};
`;
