import { OptionsType } from 'components/v3/Forms/Select/Select';

export enum AgeRulesEnums {
  EQUAL = 'EQUAL',
  YOUNGER_WITH_MORE_ONE_YEAR = 'YOUNGER_WITH_MORE_ONE_YEAR',
  YOUNGER_WITH_MORE_TWO_YEAR = 'YOUNGER_WITH_MORE_TWO_YEAR',
  YOUNGER = 'YOUNGER',
}

export enum AgeRulesEnumsToLabel {
  EQUAL = 'Year Only',
  YOUNGER_WITH_MORE_ONE_YEAR = 'Year + 1 Year younger',
  YOUNGER_WITH_MORE_TWO_YEAR = 'Year + 2 Years younger',
  YOUNGER = 'Year + Younger',
}

export const ageRuleOptions: OptionsType[] = [
  {
    value: AgeRulesEnums.EQUAL,
    label: AgeRulesEnumsToLabel.EQUAL,
  },
  {
    value: AgeRulesEnums.YOUNGER_WITH_MORE_ONE_YEAR,
    label: AgeRulesEnumsToLabel.YOUNGER_WITH_MORE_ONE_YEAR,
  },
  {
    value: AgeRulesEnums.YOUNGER_WITH_MORE_TWO_YEAR,
    label: AgeRulesEnumsToLabel.YOUNGER_WITH_MORE_TWO_YEAR,
  },
  {
    value: AgeRulesEnums.YOUNGER,
    label: AgeRulesEnumsToLabel.YOUNGER,
  },
];

export const genderOptions: OptionsType[] = [
  {
    value: 'MALE',
    label: 'Boys',
  },
  {
    value: 'FEMALE',
    label: 'Girls',
  },
  {
    value: 'COED',
    label: 'Coed',
  },
];
