/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Drawer } from 'antd';

import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import RightDrawer from 'components/v3/RightDrawer';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { useGetEventDivisions } from 'hooks/v3/event/useGetEventDivisons/useGetEventDivisons';
import { DivisionsFilters } from './DivisionsFIlters';
import { DivisionsList } from './DivisionsList';
import ModalBody from './Modal/ModalBody/ModalBody';
import ModalHeader from './Modal/ModalHeader/ModalHeader';

export interface DivisionAndClubsState {
  division: NewAgeDivision;
  divisionOrder: {
    description: string;
    id: string;
    itemPrice: number;
    name: string;
    quantity: number;
    total: number;
    clubId: string;
  }[];
  clubInformation: {
    id: string;
    logo: string;
    name: string;
  }[];
}

export const DivisionsTab = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const { mutateAsync } = useUpdateEvent({
    eventId,
  });

  const { data } = useGetEventDivisions({ eventId });

  const [divisionsAndClubs, setDivisionsAndClubs] = useState<
    DivisionAndClubsState[]
  >();
  const [yearForFilter, setYear] = React.useState<number[]>();
  const [filterGender, setFilterGender] = React.useState<string>('');
  const [filterYear, setFilterYear] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentDivision, setCurrentDivision] = React.useState<
    DivisionAndClubsState | undefined
  >(undefined);

  const filterDivision = useMemo(() => {
    return divisionsAndClubs?.filter((divisionAndClub) => {
      return (
        (filterGender === '' ||
          divisionAndClub.division.gender === filterGender) &&
        (filterYear === '' ||
          divisionAndClub.division.years.includes(Number(filterYear)))
      );
    });
  }, [divisionsAndClubs, filterGender, filterYear]);

  const handleSelectGender = useCallback((gender) => {
    setFilterGender(gender);
  }, []);

  const handleSelectYear = useCallback((year) => {
    setFilterYear(year);
  }, []);

  const handleOpenModal = (division: DivisionAndClubsState) => {
    setCurrentDivision(division);
    setIsOpen(true);
  };

  const handleCloseModal = useCallback(() => {
    setCurrentDivision(undefined);
    setIsOpen(false);
  }, []);

  const handleSave = useCallback(
    async (status) => {
      try {
        if (divisionsAndClubs) {
          const allDivisions: NewAgeDivision[] = divisionsAndClubs.map(
            (divisions) => {
              return {
                ...divisions.division,
                locked: status,
              };
            }
          );

          const payload: DeepPartial<PatchEventProps> = {
            ageDivisions: allDivisions,
          };
          await mutateAsync({ id: eventId, data: payload });
        }
      } catch (e) {
        console.error(`Error: ${e}`);
      }
    },
    [divisionsAndClubs, eventId]
  );

  useEffect(() => {
    if (data?.content) {
      const updateDivisionsAndClubs: DivisionAndClubsState[] = [];
      const yearsSet: Set<number> = new Set();

      data?.content?.forEach((clubDivisionData) => {
        clubDivisionData?.ageDivisions?.forEach((division) => {
          division.years.forEach((year) => yearsSet.add(year));

          const divisionOrderEntries = clubDivisionData.orderDetailed.flatMap(
            (order) =>
              order.cart[0]?.b2bEventRosterFee.divisions
                .filter((divisionOrder) => divisionOrder.id === division.id)
                .map((divisionOrder) => ({
                  ...divisionOrder,
                  clubId: order.cart[0]?.b2bEventRosterFee.club.id,
                }))
          );

          const clubInformationEntries = clubDivisionData.orderDetailed.map(
            (order) => order.cart[0]?.b2bEventRosterFee.club
          );

          const existingDivision = updateDivisionsAndClubs.find(
            (info) => info.division.id === division.id
          );

          if (existingDivision) {
            divisionOrderEntries.forEach((orderEntry) => {
              if (
                !existingDivision.divisionOrder.some(
                  (existingOrder) => existingOrder.id === orderEntry.id
                )
              ) {
                existingDivision.divisionOrder.push(orderEntry);
              }
            });
            clubInformationEntries.forEach((clubInfo) => {
              if (
                clubInfo &&
                !existingDivision.clubInformation.some(
                  (existingClub) => existingClub.id === clubInfo.id
                )
              ) {
                existingDivision.clubInformation.push(clubInfo);
              }
            });
          } else {
            updateDivisionsAndClubs.push({
              division,
              divisionOrder: divisionOrderEntries,
              clubInformation: clubInformationEntries.filter(
                (ci) => ci !== undefined
              ),
            });
          }
        });
      });

      setDivisionsAndClubs(updateDivisionsAndClubs);
      setYear(Array.from(yearsSet));
    }
  }, [data]);

  return (
    <>
      <Divider style={{ margin: 0 }} />

      {yearForFilter && (
        <DivisionsFilters
          setGender={handleSelectGender}
          setYear={handleSelectYear}
          yearsToFilter={yearForFilter}
          handleSaveAll={handleSave}
        />
      )}

      <Divider style={{ margin: 0 }} />

      <DivisionsList
        handleOpenModal={handleOpenModal}
        divisionFiltered={filterDivision}
        divisionsAndClubs={divisionsAndClubs}
      />

      <Drawer
        onClose={handleCloseModal}
        visible={isOpen && !!currentDivision}
        title={<ModalHeader title={currentDivision?.division.name as string} />}
      >
        {currentDivision && divisionsAndClubs && (
          <ModalBody
            divisionAndClubs={currentDivision}
            allDivisions={divisionsAndClubs.map(
              (divisions) => divisions.division
            )}
          />
        )}
      </Drawer>
    </>
  );
};
