export default class ResponseUtil {
  static formatInvalidRequestResponse = (response: InvalidRequestResponse) => {
    const details = response?.data?.response?.data?.details || [];
    const message = response?.data?.response?.data?.message || '';
    const reDetails = /Field = ([^,]+), value = '(.*)', expected = (.+)$/;
    return {
      message,
      details: details
        .filter((d) => d.match(reDetails))
        .map((detail) => {
          const match = reDetails.exec(detail);
          if (match) {
            return {
              field: match[1],
              value: match[2],
              expected: match[3],
            };
          }
          return {
            field: '',
            value: '',
            expected: '',
          };
        }),
    };
  };
}
export interface InvalidRequestProps {
  data?: {
    response?: InvalidRequestResponse;
  };
}
export interface InvalidRequestResponse {
  data?: {
    response?: {
      data?: {
        message?: string;
        details?: string[];
      };
    };
  };
}
