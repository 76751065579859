import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { IUpdateDivisionLockPayload } from 'services/v3/B2bEvent/types';

export interface IRosterLockPayload extends IUpdateDivisionLockPayload {
  locked: boolean;
}

export const useUpdateDivisionLockStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ eventId, divisionId }: IRosterLockPayload) =>
      B2bEventService.changeDivisionStatus({ eventId, divisionId }),
    onSuccess: async (_, payload) => {
      const message = payload.locked
        ? `Roster was unlocked successfully!`
        : `Roster was locked successfully!`;

      notification.success({ message });
      queryClient.invalidateQueries([
        '/update-roster-locked-state',
        payload.eventId,
        payload.divisionId,
      ]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to update Roster Locked state!',
        description: error.message,
      });
    },
  });
};
