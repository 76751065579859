import React, { FC } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';

import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import TextButton from 'components/v3/Buttons/TextButton';

import { BodyM, TitleH2 } from 'styles/v3/variables';

import * as S from './styles';

const UserCreated: FC = () => {
  const history = useHistory();

  const params: { userId: string } = useParams();

  return (
    <S.NewUserWrapper>
      <S.UserCreatedBox>
        <TitleH2 $spanColor='brandPrimary'>
          New user created, but it is
          <br />
          <span>not complete</span>.
        </TitleH2>
        <BodyM>
          To participate in any event, create a club or create an event, this
          user will need to complete their profile.
        </BodyM>
        <FilledButtonWithIcon
          isUpper
          onClick={() => history.push(`/v3/user/${params.userId}/info`)}
        >
          Complete Now
        </FilledButtonWithIcon>
        <TextButton isUpper onClick={() => history.go(-2)}>
          Remind me later
        </TextButton>
      </S.UserCreatedBox>
    </S.NewUserWrapper>
  );
};

export default withRouter(UserCreated);
