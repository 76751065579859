import React, { FC } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { User } from 'models/User/UserModel';

import ConvertUtil from 'util/ConvertUtil';

import { COLORS } from 'styles/v3/variables';

import * as S from './styles';

interface IAccountsAvatarGroupProps {
  users: User[];
}

export const AccountsAvatarGroup: FC<IAccountsAvatarGroupProps> = ({
  users,
}) => (
  <S.AvatarGroup
    maxCount={3}
    maxStyle={{
      color: `${COLORS.grey900}`,
      backgroundColor: `${COLORS.grey100}`,
    }}
  >
    {users?.map((user) => (
      <Avatar
        key={user.id}
        alt={user.firstName}
        icon={<UserOutlined />}
        src={ConvertUtil.getMinioUrl(user.photo)}
      />
    ))}
  </S.AvatarGroup>
);
