import { useQuery } from '@tanstack/react-query';
import EventService from 'services/v3/Event/EventService';

export function useGetPoolsEvent(eventId: string) {
  const { data, isLoading, error } = useQuery(
    ['get-pools-by-event-id', eventId],
    async () => await EventService.getPoolsOfEvent(eventId)
  );
  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
