import React, { useEffect } from 'react';

import { BodySBold } from 'styles/v3/variables';
import CouponService from 'services/v3/Coupons/CouponServices';
import { useDispatch, useSelector } from 'react-redux';
import { setSignupCoupon } from 'redux/v3/signupEvent/actions';
import { notification } from 'components/v3/Notification/notification';
import { ApplicationState } from 'redux/store';
import { ClubCouponProps } from './types';
import * as S from './styles';

export const ClubCoupon = ({
  eventId,
  rosterFeeProductId,
}: ClubCouponProps) => {
  const [promoCode, setPromoCode] = React.useState('');
  const [isCouponValid, setIsCouponValid] = React.useState(true);

  const { coupon } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  const dispatch = useDispatch();
  const handleApplyCoupon = async () => {
    if (rosterFeeProductId && promoCode) {
      CouponService.validateEventRosterCoupon(
        promoCode,
        eventId,
        rosterFeeProductId
      )
        .then((res) => {
          setIsCouponValid(true);
          dispatch(setSignupCoupon(res.data));
          notification.success({
            message: 'Congratulations! Your Promo Code is Activated!',
          });
        })
        .catch(() => {
          setIsCouponValid(false);
          dispatch(setSignupCoupon(undefined));
          notification.error({
            message: 'This promo code is not working, try another one',
          });
        });
    }
  };

  const handleSetCoupon = (code: string) => {
    setPromoCode(code);
    setIsCouponValid(true);
  };

  useEffect(() => {
    coupon?.name && setPromoCode(coupon.name);
  }, []);

  return (
    <S.Container>
      {!isCouponValid && (
        <BodySBold $color='supportError'>Invalid Coupon</BodySBold>
      )}
      <S.InputWrapper>
        <S.Input
          onChange={(e) => handleSetCoupon(e.target.value)}
          type='text'
          value={promoCode}
          placeholder='Promo Code'
        />
        <BodySBold
          onClick={handleApplyCoupon}
          $color={promoCode ? 'brandPrimary' : 'grey400'}
        >
          APPLY
        </BodySBold>
      </S.InputWrapper>
    </S.Container>
  );
};
