import styled from 'styled-components';

import Icon from '@icon-park/react/es/all';

import { COLORS } from 'styles/v3/variables';

export const Wrapper =
  styled.div <
  { $isLeft: boolean } >
  `
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px;

  ${(props) =>
    props.$isLeft &&
    `
    justify-content: flex-end;
  `}
`;

export const Avatar =
  styled.div <
  { $isRight: boolean } >
  `
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;

  ${(props) =>
    props.$isRight &&
    `
    order: 2;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const Content =
  styled.div <
  { $isRight: boolean } >
  `
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$isRight &&
    `
    order: 1;
  `}
`;

export const FixTime = styled.span`
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const PlayerNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4b4d46;
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.grey200};
`;

export const PlayerUsername = styled.span`
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.white};
`;

export const RemoveIcon =
  styled(Icon) <
  { $isRight: boolean } >
  `
  font-size: 0;
  cursor: pointer;

  ${(props) =>
    props.$isRight &&
    `
    order: 3;
  `}
`;
