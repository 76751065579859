import { useMemo } from 'react';
import moment from 'moment';
import type { Value as DayPickerValue } from 'react-multi-date-picker';

import { DAY_FORMAT } from './config';

export const useGetFormConfig = (days: DayPickerValue[]) => {
  const daysArray = useMemo(
    (): string[] => {
      return days.map((day: DayPickerValue) => {
        return moment(new Date(day as Date)).format(DAY_FORMAT);
      });
    },
    [days]
  );

  const rangeDays = useMemo(() => {
    const momentDays = daysArray.map(day => moment(day));

    return {
      startDate: moment.min(momentDays).format(DAY_FORMAT),
      endDate: moment.max(momentDays).format(DAY_FORMAT),
    }
  }, [daysArray]);

  return {
    rangeDays,
  };
};
