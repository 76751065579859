import * as React from 'react';
import { Star } from '@icon-park/react';
import { useHistory } from 'react-router-dom';

import FilledButton from 'components/v3/Buttons/FilledButton';

import LogoGrey from 'assets/imgs/logos/logo-vault-grey.png';
import LogoGreen from 'assets/imgs/logos/logo-vault-green.png';

import DateUtil from 'util/DateUtil';

import { BodyL, BodyM, TitleH2, TitleH3 } from 'styles/v3/variables';
import * as S from './styles';
import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard';

interface MembershipCardProps extends RoundedCardProps {
  title: string;
  price?: string;
  description?: string;
  hasSeason?: boolean;
  link?: string;
  logo: 'logo-vault-green' | 'logo-vault-grey';
  theme: 'highlight' | 'secondary';
}

const MembershipCard: React.FC<MembershipCardProps> = ({
  title,
  price,
  description,
  hasSeason,
  logo,
  theme,
  size,
  children,
  orientation,
  link = '/home',
  ...props
}) => {
  const history = useHistory();

  return (
    <S.MembershipCardWrapper $theme={theme}>
      <RoundedCard {...props} size={size} orientation={orientation}>
        <img
          src={logo === 'logo-vault-green' ? LogoGreen : LogoGrey}
          alt='Logo Vault'
        />
        {theme === 'highlight' ? (
          <>
            <TitleH3>{`${title}${
              hasSeason ? DateUtil.formatSeason() : ''
            }`}</TitleH3>
            <S.PriceContainer>
              <TitleH2 $color='brandPrimary'>{price}</TitleH2>
              <BodyM>{description}</BodyM>
            </S.PriceContainer>
            <FilledButton isUpper onClick={() => history.push(link)}>
              Upgrade <Star size={24} />
            </FilledButton>
          </>
        ) : (
          <>
            <BodyL $color='grey300'>{description}</BodyL>
            <TitleH2 $color='grey300'>{title}</TitleH2>
          </>
        )}
        <S.ChildrenContainer>{children}</S.ChildrenContainer>
      </RoundedCard>
    </S.MembershipCardWrapper>
  );
};

export default MembershipCard;
