import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Plus } from '@icon-park/react';

import { ApplicationState } from 'redux/store';
import UserType from 'models/User/UserTypeModel';

import useScreenType from 'hooks/useScreenType';

import { BodyM, TitleH2 } from 'styles/v3/variables';

import { DiscoverEvents, CreatedByMe } from './components';
import { MyEvents } from './components/MyEvents/MyEvents';
import { TABS } from './tabs';

import * as S from './styles';

interface IEventsProps {
  auth: Keycloak.KeycloakInstance;
}

const Events: FC<IEventsProps> = ({ auth }) => {
  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const { isMobile } = useScreenType();

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const isStaffUser =
    currentUser?.type === UserType.STAFF ||
    currentUser?.types?.includes(UserType.STAFF);

  const activeTab = params.get('tab') ?? TABS.DISCOVER_EVENTS;

  const handleTabChange = useCallback(
    (key: string) => {
      history.push(`${location.pathname}?tab=${key}`);
    },
    [history, location.pathname]
  );

  return (
    <S.Container>
      <S.HeaderContainer>
        <TitleH2>Events</TitleH2>
        {isMobile && (
          <>
            {isStaffUser && (
              <Link to='/v3/draft-event/general-event?type=STAFF'>
                <S.NewEventButton>
                  CREATE UNITED FUTSAL EVENT <Plus />
                </S.NewEventButton>
              </Link>
            )}
            <Link to='/v3/draft-event/plan-selection'>
              <S.NewEventButton>
                New Event <Plus />
              </S.NewEventButton>
            </Link>
          </>
        )}
      </S.HeaderContainer>
      <S.Tabs
        type='card'
        activeKey={activeTab}
        onChange={handleTabChange}
        tabBarExtraContent={
          !isMobile && (
            <S.StyledExtraContent>
              {isStaffUser && (
                <Link to='/v3/draft-event/general-event?type=STAFF'>
                  <S.NewEventButton>
                    CREATE UNITED FUTSAL EVENT <Plus />
                  </S.NewEventButton>
                </Link>
              )}
              <Link to='/v3/draft-event/plan-selection'>
                <S.NewEventButton>
                  New Event <Plus />
                </S.NewEventButton>
              </Link>
            </S.StyledExtraContent>
          )
        }
      >
        <S.TabPane
          tab={<BodyM $isUpper>Discover events</BodyM>}
          key={TABS.DISCOVER_EVENTS}
        >
          <DiscoverEvents />
        </S.TabPane>
        <S.TabPane tab={<BodyM $isUpper>My events</BodyM>} key={TABS.MY_EVENTS}>
          <MyEvents />
        </S.TabPane>
        <S.TabPane
          tab={<BodyM $isUpper>Created by me</BodyM>}
          key={TABS.CREATED_BY_ME}
        >
          <CreatedByMe />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};

export default Events;
