import * as React from 'react';

import styled from 'styled-components';
import { COLORS, ColorsInterface } from 'styles/v3/variables';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  icon: React.ReactNode;
  $color?: keyof ColorsInterface;
  $backgroundColor?: keyof ColorsInterface;
}

const IconButton = ({ icon, ...rest }: ButtonProps) => {
  return <Button {...rest}>{icon}</Button>;
};

const Button = styled.button<Pick<ButtonProps, '$backgroundColor' | '$color'>>`
  width: 40px;
  height: 40px;

  padding: 9.4px 0;

  cursor: pointer;

  border: none;
  border-radius: 8px;
  color: ${({ $color }) => $color ? COLORS[$color] : COLORS.grey300};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? COLORS[$backgroundColor] : COLORS.grey800};

  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export default IconButton;
