import React, { useState } from 'react';

import { BodyLBold } from 'styles/v3/variables';
import { ExtraButtons } from './components';
import { SUB_TAB_KEYS } from './sub-tab-keys';

import * as S from './styles';
import { MembersPoolTab } from './components/MembersPoolTab/MembersPoolTab';
import { InvitationsTab } from './components/InvitationsTab/InvitationsTab';
import { ApplicationsTab } from './components/ApplicationsTab/ApplicationsTab';

export const ClubDirectorMembers = () => {
  const [activeTab, setActiveTab] = useState(SUB_TAB_KEYS.MEMBERS_POOL);

  const handleOnChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        activeKey={activeTab}
        onChange={handleOnChange}
        tabBarExtraContent={<ExtraButtons type={activeTab as any} />}
      >
        <S.TabPane key={SUB_TAB_KEYS.MEMBERS_POOL} tab={<BodyLBold>{SUB_TAB_KEYS.MEMBERS_POOL}</BodyLBold>}>
          <MembersPoolTab />
        </S.TabPane>
        <S.TabPane key={SUB_TAB_KEYS.APPLICATIONS} tab={<BodyLBold>{SUB_TAB_KEYS.APPLICATIONS}</BodyLBold>}>
          <ApplicationsTab />
        </S.TabPane>
        <S.TabPane key={SUB_TAB_KEYS.INVITATIONS} tab={<BodyLBold>{SUB_TAB_KEYS.INVITATIONS}</BodyLBold>}>
          <InvitationsTab />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};
