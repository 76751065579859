import * as React from 'react';
import { useEffect } from 'react';
import Keycloak from 'keycloak-js';
import { Redirect, useLocation } from 'react-router-dom';
import ConvertUtil from '../../util/ConvertUtil';
import Loading from '../../components/base/Loading/Loading';

interface LoginProps {
  auth?: Keycloak.KeycloakInstance | undefined;
  routeFrom?: string;
}
interface LocationState {
  from: {
    pathname: string,
  };
}

const Login: React.FC<LoginProps> = (props) => {
  const { auth } = props;
  const location = useLocation<LocationState>();
  // Paramter sent from keyclock.init redirectUri
  const redirectLocation = ConvertUtil.queryParameter(
    'targetPath',
    location.search
  );
  useEffect(() => {
    if (!auth?.authenticated) {
      auth?.login();
    }
  }, [auth]);

  if (auth?.authenticated) {
    if (redirectLocation) {
      // Check if it has some targetUri that came after login
      return <Redirect to={redirectLocation} />;
    }
    return <Redirect to={location.state ? location.state.from : '/home'} />;
  }
  return (
    <div>
      <Loading />
    </div>
  );
};

export default Login;
