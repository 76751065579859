import { useQuery } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import { useHistory } from 'react-router-dom';
import EventService from 'services/v3/Event/EventService';

type UseGetEventDivisionsProps = {
  eventId: string,
};

export const useGetEventDivisionsOrdered = ({
  eventId,
}: UseGetEventDivisionsProps) => {
  const history = useHistory();

  const response = useQuery(
    ['event-divisions-ordered', eventId],
    () => EventService.getEventDivisionsOrdered(eventId),
    {
      onError: (error: any) => {
        notification.error({
          duration: 5000,
          message: 'An error occurs!',
          description: error?.message || 'Unexpected error.',
        });

        if (error.httpCode === 403) history.push('/v3/events');
      },
    }
  );

  return {
    ...response,
    data: response.data?.data,
    isDivisionListFetched: response.isFetched,
  };
};
