import React, { memo, FC } from 'react';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IBackConfirmModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const BackConfirmModal: FC<IBackConfirmModalProps> = memo(
  ({ visible, onCancel, onConfirm }) => (
    <S.StyledModal
      centered
      visible={visible}
      footer={false}
      closable={false}
      onCancel={onCancel}
    >
      <S.Container>
        <BodyM>
          Are you sure you want to go back? Any changes will not be saved.
        </BodyM>
        <S.ButtonsGroup>
          <S.CancelButton isUpper onClick={onCancel}>
            Continue Editing
          </S.CancelButton>
          <S.ConfirmButton isUpper onClick={onConfirm}>
            Cancel Registration
          </S.ConfirmButton>
        </S.ButtonsGroup>
      </S.Container>
    </S.StyledModal>
  )
);

BackConfirmModal.displayName = 'BackConfirmModal';
