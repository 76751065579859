import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RequestService from 'services/v3/Request/RequestService';
import { ApproveOrRejectRequestsProps } from 'services/v3/Request/types';

export const useApproveOrRejectRequests = (invalidateList: Array<any[]>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: ApproveOrRejectRequestsProps) =>
      RequestService.approveOrRejectRequests(dto),
    onSuccess: async () => {
      notification.success({
        message: 'User was approved successfully!',
      });

      if (invalidateList.length > 0) {
        for (const item of invalidateList) {
          queryClient.invalidateQueries(item);
        }
      }
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
