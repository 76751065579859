import { useQuery } from '@tanstack/react-query';

import MatchService from 'services/v3/Match/MatchService';

export function useGetMatchDetailById(matchId: string) {
  const { data, refetch, isFetching } = useQuery(
    ['/get-match-by-id', matchId],
    async () => await MatchService.getMatchDetailById(matchId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return {
    data: data?.data,
    isFetching,
    refetch,
  };
}
