import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';

export const Container =
  styled.div <
  { $renderDivisions: boolean } >
  `
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => (props.$renderDivisions ? toRem(15) : toRem(30))};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    flex-flow: wrap;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${toRem(42)};

  @media screen and (max-width: ${BREAKPOINTS.smallMobile}) {
    & > div:last-child {
      flex-direction: column;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-flow: wrap;
    gap: ${toRem(24)};
  }
`;

interface ImageProps {
  $image: string;
  $selected: boolean;
}

export const Image =
  styled.div <
  ImageProps >
  `
  display: flex;
  width: 160px;
  height: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 139px;
  ${(props) =>
    props.$selected
      ? `border: 6px solid ${COLORS.brandPrimary}`
      : 'border: transparent'};
  background: url(${(props) => props.$image}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;

export const SelectWrapper = styled.div`
  max-width: 280px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;

  .vault-select__control {
    min-height: 25px;

    .vault-select__multi-value {
      color: ${COLORS.grey50};
      background-color: unset;

      .vault-select__multi-value__remove {
        background-color: unset;
        cursor: pointer;
      }
    }

    .vault-select__multi-value__label {
      color: ${COLORS.grey50};
    }
  }
`;

export const DivisionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;
