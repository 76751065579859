export const optionsArray = [
  {
    value: 'Letters (A, B, C…)',
    label: 'Letters (A, B, C…)',
  },
  {
    value: 'Numbers (1, 2, 3…)',
    label: 'Numbers (1, 2, 3…)',
  },
];

export const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DAY_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm:ss';

export const DEFAULT_START_TIME = '8:00:00';

export const DEFAULT_END_TIME = '20:00:00';

export const MAX_DIFFER_TOURNEMENT_DAYS = 14;

export const MAX_DIFFER_OTHER_DAYS = 120;
