import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  max-width: fit-content;
  max-height: fit-content;

  &.full-width {
    max-width: none;
    width: 100%;
  }

  &.offset-bottom {
    margin-bottom: 16px;
  }

  & > * {
    pointer-events: none;
    opacity: 0.6;
  }
`;
