import React, {
  memo,
  FC,
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { IRosterUser } from 'services/v3/Match/interfaces';

import InputText from 'components/v3/Forms/InputText/InputText';

import { BodyXL } from 'styles/v3/variables';

import * as S from './styles';

interface IJerseyNumberModalProps {
  visible: boolean;
  players?: IRosterUser[];
  defaultNumber: string;
  onClose: () => void;
  onSubmit: (number: number) => void;
}

export const JerseyNumberModal: FC<IJerseyNumberModalProps> = memo(
  ({ visible, players, defaultNumber, onClose, onSubmit }) => {
    const [jerseyNumber, setJerseyNumber] = useState('');
    const [error, setError] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      const matchedPlayer = players?.find(
        (player) => player.number === Number(value)
      );

      if (matchedPlayer) {
        setError('Jersey number is already taken');
      } else {
        setError('');
      }

      if (/^\d*$/.test(value)) {
        setJerseyNumber(value);
      }
    };

    const handleSubmitForm = useCallback(() => {
      onSubmit(Number(jerseyNumber));
      setJerseyNumber('');
      setError('');
    }, [jerseyNumber]);

    const handleClose = useCallback(() => {
      setJerseyNumber(defaultNumber);
      setError('');
      onClose();
    }, [defaultNumber, onClose]);

    useEffect(() => {
      setJerseyNumber(defaultNumber);
    }, [defaultNumber]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={handleClose}
      >
        <S.Header>
          <BodyXL $color='grey50'>New Jersey Number</BodyXL>
        </S.Header>
        <S.Container>
          <InputText
            id='number'
            value={jerseyNumber}
            error={!!error.length}
            errorMessage={error}
            onChange={handleChange}
            placeholder='Type jersey number'
            autoComplete='off'
          />
          <S.ButtonsWrapper>
            <S.CloseOutlinedButton isUpper onClick={handleClose}>
              Cancel
            </S.CloseOutlinedButton>
            <S.ConfirmFilledButton
              isUpper
              onClick={handleSubmitForm}
              $disabled={jerseyNumber.length < 1 || !!error.length}
            >
              Save
            </S.ConfirmFilledButton>
          </S.ButtonsWrapper>
        </S.Container>
      </S.StyledModal>
    );
  }
);

JerseyNumberModal.displayName = 'JerseyNumberModal';
