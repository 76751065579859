import { useEffect } from 'react';
import { notification } from 'components/v3/Notification/notification';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const useUpgradeMembersNotification = (
  invalidateQueriesList?: Array<any[]>
) => {
  const queryClient = useQueryClient();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('checkout')) {
      const checkoutParam = queryParams.get('checkout');
      if (checkoutParam === 'success') {
        notification.success({
          duration: 8,
          message: 'Success',
          description: 'User upgrade completed',
        });

        if (invalidateQueriesList?.length) {
          invalidateQueriesList.forEach((queries) => {
            queryClient.invalidateQueries(queries);
          });
        }
      } else if (checkoutParam === 'error') {
        notification.info({
          duration: 8,
          message: 'Payment was not completed',
          description: 'Please try again',
        });
      }
      queryParams.delete('checkout');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);
};
