import React, { useMemo, useState } from 'react';
import { BodyLBold } from 'styles/v3/variables';
import Select from 'components/v3/Forms/Select/Select';
import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import { useParams } from 'react-router-dom';
import Loading from 'components/v3/Loading/Loading';
import { useGetBracketsByEventId } from 'hooks/v3/brackets/useGetBracketsByEventId/useGetBracketsByEventId';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { BracketItem } from 'pages/V3/Print/Bracket/BracketItem';
import { ScheduleMatch } from 'models/Schedule/ScheduleModel';
import { Header } from '../../components';
import { AgeDivisionsWrapper, Container } from './styles';

const BracketResult = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [selectedDivision, setSelectedDivision] = useState('');

  const { data: response, isLoading: isLoadingEvent } = useGetEvent(eventId);
  const { data: brackets } = useGetBracketsByEventId(eventId);
  const { data: currentSchedule } = useGetScheduleByEventId(eventId);

  // ENG-240 TODO edited after change ScheduledModel/ScheduledDays
  const bracketsData = useMemo(() => {
    if (!brackets || !currentSchedule) return [];
    if (!selectedDivision) return [];
    const allMatches: ScheduleMatch[] = currentSchedule.days.reduce(
      (acc, day) => {
        if (day?.matches) {
          acc.push(...day.matches);
        }
        return acc;
      },
      [] as ScheduleMatch[]
    );
    const brecketMapped = brackets.map((bracket) => ({
      ...bracket,
      matches: bracket.matches.map((match) => ({
        ...match,
        opponent1: {
          ...match.opponent1,
          clubName: allMatches.find(
            (m) => m.bracketId === bracket.id && m.matchId === match.id
          )?.opponent1?.clubName,
        },
        opponent2: {
          ...match.opponent2,
          clubName: allMatches.find(
            (m) => m.bracketId === bracket.id && m.matchId === match.id
          )?.opponent2?.clubName,
        },
      })),
    }));

    return brecketMapped.filter(
      (bracket) => bracket.ageDivisionId === selectedDivision
    );
  }, [currentSchedule, brackets, selectedDivision]);

  const options = useMemo(
    () =>
      response?.data?.ageDivisions?.map((division) => ({
        value: division.id || '',
        label: division.name || '',
      })) || [],
    [response?.data]
  );

  const defaultOption = options.find(
    (option) => option.value === selectedDivision
  );

  const handleChangeDivision = (event: any) => {
    setSelectedDivision(event.value);
  };

  return isLoadingEvent ? (
    <Loading />
  ) : (
    <Container>
      <Header
        withGobBack
        withLogo={false}
        event={response?.data}
        customName='SEE BRACKET'
      />
      <AgeDivisionsWrapper>
        <BodyLBold $color='grey50'>Select Age Division</BodyLBold>
        <Select
          options={options}
          name='ageDivision'
          defaultValue={defaultOption}
          onChange={handleChangeDivision}
          placeholder='Select Age Division'
        />
      </AgeDivisionsWrapper>
      {bracketsData?.map((bracket) => (
        <BracketItem bracket={bracket} key={bracket.id} hideHeader />
      ))}
    </Container>
  );
};

export default BracketResult;
