import * as React from 'react';
import InputMask from 'react-input-mask';

import InputText, { InputTextProps } from '../InputText/InputText';

interface InputPhoneProps extends InputTextProps {
  mask?: string;
}

const InputPhone: React.FC<InputPhoneProps> = ({
  mask = '+1 (999) 999-9999',
  value,
  onChange,
  onBlur,
  ...props
}) => {
  return (
    <InputMask
      mask={mask}
      placeholder='+1 (___) ___-____'
      value={value}
      maskPlaceholder={null}
      onChange={onChange}
      onBlur={onBlur}
    >
      <InputText {...props} type='phone' icon='PhoneTelephone' />
    </InputMask>
  );
};
export default InputPhone;
