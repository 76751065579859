import React from 'react';
import moment from 'moment';
import { BodySBold } from 'styles/v3/variables';
import { useHistory, useLocation } from 'react-router-dom';
import { TABS_KEYS } from 'pages/V3/EventManager/tab-keys';
import { HeaderCourts, StyledDays, StyledDay } from './styles';
import { DaysSwitcherProps } from './types';

function DaysSwitcher({
  days,
  currentDay,
  visibleCourts,
  removeAll,
}: DaysSwitcherProps) {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (day: string) => {
    history.push(
      `${location.pathname}?tab=${TABS_KEYS.SCHEDULES}&day=${moment(day).format(
        'YYYY-MM-DD'
      )}`
    );
    removeAll();
  };
  return (
    <StyledDays style={{ width: '100%' }}>
      <div>
        {days.map((day) => (
          <StyledDay
            key={day}
            active={
              moment(currentDay).format('MMMM D') ===
              moment(day).format('MMMM D')
            }
            onClick={() => handleClick(day)}
          >
            {moment(day).format('MMMM D')}
          </StyledDay>
        ))}
      </div>
      <HeaderCourts columns={visibleCourts.length}>
        {visibleCourts.map((court) => {
          return (
            <BodySBold className='item' key={court.id}>
              {court.name}
            </BodySBold>
          );
        })}
      </HeaderCourts>
    </StyledDays>
  );
}

export default DaysSwitcher;
