import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(16)};
  padding: ${toRem(24)} 0;
`;
