import { useQuery } from '@tanstack/react-query';
import ScheduleService from 'services/v3/Schedule/ScheduleService';

export function useGetGamesCountByEventId(eventId?: string) {
  const response = useQuery(
    ['get-games-count-by-event-id', eventId],
    async () => await ScheduleService.getGamesCountByEventId(eventId)
  );

  return {
    ...response,
    data: response.data?.data,
  };
}
