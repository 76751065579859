import { v4 as uuidv4 } from 'uuid';

import { MenuTab as Status } from 'components/base/EventSideMenu/types';

export const gameStatus: Status[] = [
  {
    id: uuidv4(),
    title: 'All games',
    status: 'none',
  },
  {
    id: uuidv4(),
    title: 'Scheduled',
    status: 'none',
  },
  {
    id: uuidv4(),
    title: 'Unscheduled',
    status: 'none',
  },
];
