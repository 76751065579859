import React, { FC, useMemo } from 'react';
import { Camera, IdCardH, IdCardV } from '@icon-park/react';
import { Icon } from '@icon-park/react/es/all';

import { RequestStatus } from 'models/Request/RequestModel';

import { BodyS, COLORS } from 'styles/v3/variables';

import { ItemProps } from './types';

import * as S from './styles';

export const Item: FC<ItemProps> = ({ name, status, permission }) => {
  const isEnabled = useMemo(() => {
    const safeSportPermission = ['COACH', 'CLUB_DIRECTOR', 'REFEREE'];
    const agePermission = ['PLAYER'];

    switch (name) {
      case 'SS':
        return safeSportPermission.includes(permission);
      case 'AV':
        return agePermission.includes(permission);
      default:
        return true;
    }
  }, [name, permission]);

  const CurrentIcon: Icon = useMemo(() => {
    switch (name) {
      case 'SS':
        return IdCardV;
      case 'AV':
        return IdCardH;
      default:
        return Camera;
    }
  }, [name]);

  const iconColor = useMemo(() => {
    switch (status) {
      case RequestStatus.APPROVED:
        return COLORS.green;
      case RequestStatus.PENDING:
        return '#67e4ff';
      case RequestStatus.DECLINED:
      case RequestStatus.EXPIRED:
        return COLORS.supportError;
      default:
        return COLORS.grey400;
    }
  }, [status]);

  if (!isEnabled) {
    return null;
  }

  return (
    <S.Container>
      <S.Icon>
        <CurrentIcon size={20} className='reset' style={{ color: iconColor }} />
      </S.Icon>
      <BodyS>{name}</BodyS>
    </S.Container>
  );
};
