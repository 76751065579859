import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import ScheduleService from 'services/v3/Schedule/ScheduleService';

export const useSendNotification = (eventId: string) => {
  return useMutation({
    mutationFn: async () => {
      await ScheduleService.sendNotification(eventId);
    },
    onSuccess: async () => {
      notification.success({
        message: 'Notification sent successfully',
      });
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
