import React, { useMemo, useState } from 'react';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { BodyXLBold, BodyXL } from 'styles/v3/variables';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { ScheduleMatch, ScheduleType, ScheduleView } from 'models/Schedule/ScheduleModel';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import usePublishSchedule from 'hooks/v3/schedules/usePublishSchedule/usePublishSchedule';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { useSendNotification } from 'hooks/v3/schedules/useSendNotification/useSendNotification';
import { Tooltip } from 'antd';
import PlacementIcon from 'assets/imgs/icons/placement.svg';
import SaveIcon from 'assets/imgs/icons/save.svg';
import { getModalStyles } from 'pages/V3/EventManager/components/ScheduleCalendar/components/Scheduler/lib';
import GameDetailsModal from 'pages/V3/EventManager/components/ScheduleCalendar/components/ScheduleViewModal/GameDetailsModal';
import ScheduleViewModal from 'pages/V3/EventManager/components/ScheduleCalendar/components/ScheduleViewModal/ScheduleViewModal';
import {
  CalendarHeaderContainer,
  CalendarHeaderButtonGroup,
  CalendarHeaderGameSelector,
  CalendarHeaderGameSelectorItem,
  OverlayInnerStyle,
} from './styles';

interface CalendarHeaderProps {
  onHandleSave: () => void;
  onHandlePlacement: () => void;
  setScheduleType: (type: ScheduleType) => void;
  currentSchedule?: ScheduleView;
  isPublished?: boolean;
  loading: boolean;
  scheduleType: ScheduleType;
  isBlockedPublish: boolean;
  isBlockedSave: boolean;
}

const CalendarHeader = ({
  loading,
  setScheduleType,
  onHandleSave,
  isPublished,
  onHandlePlacement,
  scheduleType,
  currentSchedule,
  isBlockedPublish,
  isBlockedSave,
}: CalendarHeaderProps) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { mutateAsync } = usePublishSchedule(eventId);
  const { mutateAsync: sendNotification } = useSendNotification(eventId);

  const [opened, setOpened] = useState<boolean>(false);
  const [openedGameModal, setOpenedGameModal] = useState<boolean>(false);
  const [game, setGame] = useState<ScheduleMatch>({} as ScheduleMatch);
  const [day, setDay] = useState<Date | string>('');

  const handleSave = () => {
    confirm({
      title: 'Schedule save',
      message: 'Are you sure you want to save schedule?',
      onOk: onHandleSave,
    });
  };

  const publish = () => {
    confirm({
      title: 'Publish schedule',
      message: 'Are you sure you want to publish schedule?',
      onOk: async () => {
        await mutateAsync(true);
        await sendNotification();
      },
    });
  };

  const handleOpen = () => {
    setOpened(true);
  };

  const modalStyles = useMemo(() => {
    return getModalStyles('530px');
  }, []);

  const saveDisabled = loading || isBlockedSave;
  const publishDisabled = loading || !currentSchedule || isBlockedPublish;

  return (
    <CalendarHeaderContainer style={{ width: '84%' }}>
      <CalendarHeaderGameSelector>
        <CalendarHeaderGameSelectorItem
          onClick={() => setScheduleType('POOL_GAMES')}
        >
          <BodyXLBold
            style={{
              color: scheduleType === 'POOL_GAMES' ? '#CAFC01' : '#D3D4D0',
            }}
          >
            Pool Games
          </BodyXLBold>
        </CalendarHeaderGameSelectorItem>

        <CalendarHeaderGameSelectorItem
          onClick={() => setScheduleType('BRACKET_GAMES')}
        >
          <BodyXL
            style={{
              color: scheduleType === 'BRACKET_GAMES' ? '#CAFC01' : '#D3D4D0',
            }}
          >
            Bracket Games
          </BodyXL>
        </CalendarHeaderGameSelectorItem>
      </CalendarHeaderGameSelector>
      <CalendarHeaderButtonGroup>
        {/** ENG-320 temporary disable Team placement */}
        <OutlinedButton
          onClick={onHandlePlacement}
          disabled
          className='btn-place'
        >
          Team Placement
          <img style={{ marginLeft: 10 }} src={PlacementIcon} alt='' />
        </OutlinedButton>
        {saveDisabled ? (
          <Tooltip
            title='You should schedule all matches according to the saving schedule'
            color='#292b25'
            overlayInnerStyle={OverlayInnerStyle}
          >
            <span>
              <OutlinedButton disabled className='btn-save'>
                {loading ? 'Saving...' : 'Save'}
                <img style={{ marginLeft: 10 }} src={SaveIcon} alt='' />
              </OutlinedButton>
            </span>
          </Tooltip>
        ) : (
          <OutlinedButton onClick={handleSave} className='btn-save'>
            {loading ? 'Saving...' : 'Save'}
            <img style={{ marginLeft: 10 }} src={SaveIcon} alt='' />
          </OutlinedButton>
        )}
        {isPublished ? (
          <FilledButton onClick={handleOpen}>Open</FilledButton>
        ) : (
          <>
            {publishDisabled ? (
              <Tooltip
                title='You can only Publish this Schedule by filling in all fields for all games'
                color='#292b25'
                overlayInnerStyle={OverlayInnerStyle}
              >
                <span>
                  <FilledButton disabled>Publish</FilledButton>
                </span>
              </Tooltip>
            ) : (
              <FilledButton onClick={publish}>Publish</FilledButton>
            )}
          </>
        )}
        {currentSchedule && (
          <Modal
            isOpen={opened}
            shouldCloseOnEsc
            ariaHideApp={false}
            preventScroll
            shouldCloseOnOverlayClick
            onRequestClose={() => setOpened(false)}
            style={modalStyles}
          >
            <ScheduleViewModal
              schedule={currentSchedule}
              setOpened={setOpened}
              setGame={setGame}
              setDay={setDay}
              openDetailModal={() => setOpenedGameModal(true)}
            />
          </Modal>
        )}
        <Modal
          isOpen={openedGameModal}
          shouldCloseOnEsc
          ariaHideApp={false}
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            setOpenedGameModal(false);
            setOpened(true);
          }}
          style={modalStyles}
        >
          <GameDetailsModal
            day={day}
            game={game}
            setOpened={setOpenedGameModal}
            setOpenedScheduleView={setOpened}
          />
        </Modal>
      </CalendarHeaderButtonGroup>
    </CalendarHeaderContainer>
  );
};

export default CalendarHeader;
