import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TimePicker } from 'antd';
import moment, { Moment } from 'moment';

import { MatchStatus } from 'models/Match/MatchModel';
import { IFutsalEventView, IMatchDetails } from 'services/v3/Match/interfaces';
import {
  IFutsalEventPayload,
  IGameReportPayload,
} from 'services/v3/Match/payload-interfaces';
import { FutsalAction, SoccerPeriod } from 'services/v3/Match/enums';

import { ScoresheetTeamCard } from 'components/v3/Cards/ScoresheetTeamCard/ScoresheetTeamCard';
import { Scoreboard } from 'components/v3/Scoreboard/Scoreboard';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyXLBold } from 'styles/v3/variables';

import { PenaltyShootout } from 'components/v3/Timeline/PenaltyShootout/PenaltyShootout';
import { ReportModal } from './ReportModal/ReportModal';
import { FutsalEventModal } from './FutsalEventModal/FutsalEventModal';
import { FinishGameModal } from './FinishGameModal/FinishGameModal';

import { MATCH_SOCCER, FUTSAL_EVENTS, PENALTY_FUTSAL_EVENTS } from './config';
import { IFutsalSelection, IMatchInfo, RosterType } from './interfaces';

import * as S from './styles';

import './styles.scss';

interface IScoresheetProps {
  match: Maybe<IMatchDetails>;
  matchInfo: IMatchInfo;
  enabledEditFinishedGame: boolean;
  penaltyShootout: Maybe<IFutsalEventView[]>;
  getTeamFouls: (rosterId: string) => number;
  setMatchInfo: (info: IMatchInfo | ((info: IMatchInfo) => IMatchInfo)) => void;
  onCreateFutsalEvent: (payload: IFutsalEventPayload) => void;
  onReportGame: (payload: IGameReportPayload) => void;
  onStartGame: () => void;
  onFinishGame: () => void;
}

export const Scoresheet: FC<IScoresheetProps> = memo(
  ({
    match,
    matchInfo,
    penaltyShootout,
    enabledEditFinishedGame,
    getTeamFouls,
    setMatchInfo,
    onReportGame,
    onCreateFutsalEvent,
    onStartGame,
    onFinishGame,
  }) => {
    const [timeGame, setTimeGame] = useState<Moment>(matchInfo.time);
    const [selectedSoccer, setSelectedSoccer] = useState<SoccerPeriod>(
      matchInfo.soccer
    );
    const [openedEditTime, setOpenedEditTime] = useState(false);
    const [openedReportModal, setOpenedReportModal] = useState(false);
    const [openedFutsalEventModal, setOpenedFutsalEventModal] = useState(false);
    const [openedFinishGameModal, setOpenedFinishGameModal] = useState(false);
    const [futsalSelection, setFutsalSelection] =
      useState<Maybe<IFutsalSelection>>(null);

    const soccerText =
      MATCH_SOCCER.find((soccer) => soccer.type === matchInfo.soccer)?.value ||
      '';

    const displayedGameControl =
      (selectedSoccer !== SoccerPeriod.PENALTY_SHOOTOUT &&
        enabledEditFinishedGame) ||
      (match?.status !== MatchStatus.FINISHED &&
        selectedSoccer !== SoccerPeriod.PENALTY_SHOOTOUT);

    const hasFutsalRedOrYellowCards = useMemo(() => {
      const yellowCardCountMap: Record<string, number> = {};

      if (!openedFinishGameModal) {
        return false;
      }

      for (const event of match?.matchEvents || []) {
        if (event.action === FutsalAction.RED_CARD) {
          return true;
        }

        if (event.action === FutsalAction.YELLOW_CARD) {
          const { userId } = event;

          yellowCardCountMap[userId] = (yellowCardCountMap[userId] || 0) + 1;

          if (yellowCardCountMap[userId] >= 2) {
            return true;
          }
        }
      }

      return false;
    }, [match, openedFinishGameModal]);

    const handleOpenEditTime = useCallback(() => {
      setOpenedEditTime(true);
      setTimeGame(matchInfo.time);
    }, [matchInfo]);

    const handleCancelEditTime = useCallback(() => {
      setTimeGame(matchInfo.time);
      setSelectedSoccer(matchInfo.soccer);
      setOpenedEditTime(false);
    }, [matchInfo]);

    const handleConfirmEditTime = useCallback(() => {
      setOpenedEditTime(false);
      setMatchInfo({
        ...matchInfo,
        time: timeGame,
        soccer: selectedSoccer,
      });
    }, [matchInfo, timeGame, selectedSoccer]);

    const handleOpenReportModal = useCallback(() => {
      setOpenedReportModal(true);
    }, []);

    const handleCloseReportModal = useCallback(() => {
      setOpenedReportModal(false);
    }, []);

    const handleCloseFutsalEventModal = useCallback(() => {
      setOpenedFutsalEventModal(false);
    }, []);

    const handleOpenFinishGameModal = useCallback(() => {
      setOpenedFinishGameModal(true);
    }, []);

    const handleCloseFinishGameModal = useCallback(() => {
      setOpenedFinishGameModal(false);
      setSelectedSoccer(matchInfo.soccer);
    }, [matchInfo]);

    const handleTriggerPlayGame = useCallback(
      (state: boolean) => () => {
        setMatchInfo((prevState) => ({
          ...prevState,
          playGame: state,
        }));

        if (
          state &&
          (match?.status === MatchStatus.READY ||
            match?.status === MatchStatus.SCHEDULED)
        ) {
          onStartGame();
        }
      },
      [match, onStartGame]
    );

    const handleSelectSoccer = useCallback(
      (soccer: SoccerPeriod) => () => {
        setSelectedSoccer(soccer);
      },
      []
    );

    const handleChangeTime = useCallback((selectedTime: Maybe<Moment>) => {
      if (selectedTime) {
        setTimeGame(selectedTime);
      }
    }, []);

    const handleFutsalEvent = useCallback(
      ({ roster, action, text }: IFutsalSelection) =>
        () => {
          setFutsalSelection({
            roster,
            action,
            text,
          });

          setOpenedFutsalEventModal(true);
        },
      []
    );

    const handleGameReport = useCallback(
      (report: string) => {
        if (match) {
          onReportGame({
            report,
            matchId: match.matchId,
          });
        }
      },
      [match, onReportGame]
    );

    const handleFinishGameAndReport = useCallback(
      (report: string) => {
        if (match) {
          report.length &&
            onReportGame({
              report,
              matchId: match.matchId,
            });
          onFinishGame();
          setOpenedEditTime(false);
        }
      },
      [match, onFinishGame]
    );

    const handleConfirmFutsalEventCreation = useCallback(
      (payload: IFutsalEventPayload, roster: Maybe<RosterType>) => {
        onCreateFutsalEvent(payload);
      },
      [matchInfo, onCreateFutsalEvent]
    );

    useEffect(() => {
      if (selectedSoccer === SoccerPeriod.FINISHED_GAME) {
        setOpenedFinishGameModal(true);
      }
    }, [selectedSoccer]);

    useEffect(() => {
      let intervalInstance: number;

      if (matchInfo.playGame) {
        intervalInstance = window.setInterval(() => {
          setMatchInfo((info) => {
            const seconds = info.time.minutes() * 60 + info.time.seconds();

            return {
              ...info,
              time: moment('00:00', 'mm:ss').add(seconds - 1, 'seconds'),
            };
          });
        }, 1000);
      }

      return () => {
        window.clearInterval(intervalInstance);
      };
    }, [matchInfo]);

    return (
      <S.Container>
        <S.ReportButton onClick={handleOpenReportModal}>Report</S.ReportButton>
        <Scoreboard
          awayTeam={match?.awayTeam || null}
          homeTeam={match?.homeTeam || null}
          result={match?.result || null}
          getTeamFouls={getTeamFouls}
        />
        {!openedEditTime && displayedGameControl && (
          <S.BorderWrapper>
            {enabledEditFinishedGame && (
              <S.GameStatus>Game Finished</S.GameStatus>
            )}
            <S.Soccer>{soccerText}</S.Soccer>
            <S.Time>{matchInfo.time.format('mm:ss')}</S.Time>
            <S.ActionButtons>
              <S.StartButton
                isUpper
                $paused={matchInfo.playGame}
                onClick={handleTriggerPlayGame(!matchInfo.playGame)}
              >
                {matchInfo.playGame ? 'Pause' : 'Start'}
              </S.StartButton>
              <S.OutlinedButtonStyled isUpper onClick={handleOpenEditTime}>
                Edit time
              </S.OutlinedButtonStyled>
            </S.ActionButtons>
          </S.BorderWrapper>
        )}
        {match?.status === MatchStatus.FINISHED && !enabledEditFinishedGame && (
          <S.BorderWrapper>
            <BodyXLBold>Game Finished</BodyXLBold>
          </S.BorderWrapper>
        )}
        {!openedEditTime &&
          selectedSoccer === SoccerPeriod.PENALTY_SHOOTOUT && (
            <S.BorderWrapper>
              <S.PenaltyTitle>Penalty Shootout</S.PenaltyTitle>
              <PenaltyShootout
                homePlayers={match?.homeTeam?.players || null}
                awayPlayers={match?.awayTeam?.players || null}
                penaltyShootout={penaltyShootout}
                awayTeamPenaltiShootout={
                  match?.result?.awayTeamPenaltiShootout || 0
                }
                homeTeamPenaltiShootout={
                  match?.result?.homeTeamPenaltiShootout || 0
                }
              />
              <S.PenaltyActionButtons>
                {match?.status === MatchStatus.IN_PROGRESS && (
                  <FilledButton isUpper onClick={handleOpenFinishGameModal}>
                    Finish game
                  </FilledButton>
                )}
                <S.OutlinedButtonStyled isUpper onClick={handleOpenEditTime}>
                  Edit time
                </S.OutlinedButtonStyled>
              </S.PenaltyActionButtons>
            </S.BorderWrapper>
          )}
        {openedEditTime &&
          (match?.status !== MatchStatus.FINISHED ||
            enabledEditFinishedGame) && (
            <S.BorderWrapper>
              <S.GroupSoccer>
                {MATCH_SOCCER.map((soccer) => (
                  <S.SoccerItem
                    key={soccer.type}
                    $active={soccer.type === selectedSoccer}
                    $disabled={
                      match?.status !== MatchStatus.IN_PROGRESS &&
                      soccer.type === SoccerPeriod.FINISHED_GAME
                    }
                    onClick={handleSelectSoccer(soccer.type)}
                  >
                    {soccer.value}
                  </S.SoccerItem>
                ))}
              </S.GroupSoccer>
              <S.TimePickerWrapper>
                <TimePicker
                  value={timeGame}
                  onChange={handleChangeTime}
                  format='mm:ss'
                  placeholder='00:00'
                  popupClassName='scoresheet-time-picker'
                  inputReadOnly
                />
              </S.TimePickerWrapper>
              <S.ActionButtons>
                <S.OutlinedButtonStyled isUpper onClick={handleCancelEditTime}>
                  Cancel
                </S.OutlinedButtonStyled>
                <FilledButton isUpper onClick={handleConfirmEditTime}>
                  Confirm
                </FilledButton>
              </S.ActionButtons>
            </S.BorderWrapper>
          )}
        {((match?.status === MatchStatus.FINISHED && enabledEditFinishedGame) ||
          match?.status === MatchStatus.IN_PROGRESS) && (
          <>
            <S.TeamsSection>
              <ScoresheetTeamCard team={match?.homeTeam || null} venue='Home' />
              <S.ExchangeIcon className='bt-action i-icon-reverse' />
              <ScoresheetTeamCard team={match?.awayTeam || null} venue='Away' />
            </S.TeamsSection>
            <S.ColomnWrapper>
              <S.Column>
                {matchInfo.soccer !== SoccerPeriod.PENALTY_SHOOTOUT &&
                  FUTSAL_EVENTS.map((event) => (
                    <FilledButton
                      key={event.type}
                      isUpper
                      onClick={handleFutsalEvent({
                        roster: RosterType.Home,
                        action: event.type,
                        text: event.value,
                      })}
                    >
                      {event.value}
                    </FilledButton>
                  ))}
                {matchInfo.soccer === SoccerPeriod.PENALTY_SHOOTOUT &&
                  PENALTY_FUTSAL_EVENTS.map((event) => (
                    <FilledButton
                      key={event.type}
                      isUpper
                      onClick={handleFutsalEvent({
                        roster: RosterType.Home,
                        action: event.type,
                        text: event.value,
                      })}
                    >
                      {event.value}
                    </FilledButton>
                  ))}
              </S.Column>
              <S.Column>
                {matchInfo.soccer !== SoccerPeriod.PENALTY_SHOOTOUT &&
                  FUTSAL_EVENTS.map((event) => (
                    <FilledButton
                      key={event.type}
                      isUpper
                      onClick={handleFutsalEvent({
                        roster: RosterType.Away,
                        action: event.type,
                        text: event.value,
                      })}
                    >
                      {event.value}
                    </FilledButton>
                  ))}
                {matchInfo.soccer === SoccerPeriod.PENALTY_SHOOTOUT &&
                  PENALTY_FUTSAL_EVENTS.map((event) => (
                    <FilledButton
                      key={event.type}
                      isUpper
                      onClick={handleFutsalEvent({
                        roster: RosterType.Away,
                        action: event.type,
                        text: event.value,
                      })}
                    >
                      {event.value}
                    </FilledButton>
                  ))}
              </S.Column>
            </S.ColomnWrapper>
          </>
        )}
        {match?.status !== MatchStatus.IN_PROGRESS &&
          match?.status !== MatchStatus.FINISHED && (
            <S.ActionEmptyState>
              Start the game to see the extended version of the game controls
            </S.ActionEmptyState>
          )}
        <ReportModal
          visible={openedReportModal}
          defaultReport={match?.report ?? ''}
          onClose={handleCloseReportModal}
          onSubmit={handleGameReport}
        />
        <FinishGameModal
          visible={openedFinishGameModal}
          requiredField={hasFutsalRedOrYellowCards}
          defaultReport={match?.report ?? ''}
          onClose={handleCloseFinishGameModal}
          onSubmit={handleFinishGameAndReport}
        />
        <FutsalEventModal
          match={match}
          soccer={selectedSoccer}
          coreTime={matchInfo.time}
          futsal={futsalSelection}
          visible={openedFutsalEventModal}
          onClose={handleCloseFutsalEventModal}
          onCreateFutsalEvent={handleConfirmFutsalEventCreation}
        />
      </S.Container>
    );
  }
);

Scoresheet.displayName = 'Scoresheet';
