import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Calendar, LocalTwo } from '@icon-park/react';

import { BodyL } from 'styles/v3/variables';

import { InfoItem } from './components/InfoItem/InfoItem';
import { CheckoutGeneralInfoProps } from './types';

import * as S from './styles';

export const CheckoutGeneralInfo: FC<CheckoutGeneralInfoProps> = ({
  event,
}) => {
  const formattedStartDate = moment(new Date(event.startDate)).format(
    'MMM D YYYY'
  );
  const formattedEndDate = moment(new Date(event.endDate)).format('MMM D YYYY');

  const eventAddress = useMemo(() => {
    const venue = event.venues.length ? event.venues[0] : null;

    if (!venue) return '';

    return `${venue.address.city}, ${venue.address.state}`;
  }, [event.venues]);

  return (
    <S.Container>
      <BodyL>GENERAL INFO</BodyL>
      <InfoItem
        icon={<Calendar />}
        text={`${formattedStartDate} - ${formattedEndDate}`}
      />
      {!!eventAddress.length && (
        <InfoItem icon={<LocalTwo />} text={`${eventAddress}`} />
      )}
    </S.Container>
  );
};
