import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div``;

export const AgeDivisionsWrapper = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    flex-direction: column;

    gap: ${toRem(24)};

    padding: 0 ${toRem(16)};
    margin-bottom: ${toRem(24)};
  }
`;
