import styled from 'styled-components';
import { lighten } from 'polished';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderModal = styled.div`
  display: flex;

  margin-top: ${toRem(-50)};
  margin-bottom: ${toRem(24)};
  gap: ${toRem(16)};
`;

export const ClubIcon = styled.div`
  width: ${toRem(100)};
  height: ${toRem(100)};

  overflow: hidden;

  background-color: ${COLORS.brandPrimary};
  border-radius: 100%;

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(100)};
    height: ${toRem(100)};
  }
`;

export const ClubInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(10)};
`;

export const DirectorInformation = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(10)};

  span {
    display: flex;
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: ${toRem(24)};
  gap: ${toRem(24)};

  .btn-edit-info {
    color: ${COLORS.grey50};
    border-color: ${COLORS.grey700};
    background-color: ${COLORS.grey700};

    &:hover {
      color: ${COLORS.grey50};
      background-color: ${lighten(0.1, COLORS.grey700)};
    }

    span {
      display: flex;
      ::before {
        display: none;
      }
    }
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RosterLockStatus = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(8)};

  span {
    display: flex;
    ::before {
      display: none;
    }
  }
`;

export const ContainerOfUsers = styled.div`
  width: 100%;
  background-color: ${COLORS.grey950};

  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(24)};

  padding: ${toRem(24)};
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const UserInformationContainer = styled.div`
  padding: ${toRem(16)};

  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(16)};
`;

export const UserInformationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(16)};
`;

export const UserIcon = styled.div`
  width: ${toRem(40)};
  height: ${toRem(40)};

  overflow: hidden;

  background-color: ${COLORS.brandPrimary};
  border-radius: 100%;

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(40)};
    height: ${toRem(40)};
  }
`;

export const UserMemberType = styled.div`
  display: flex;
  gap: ${toRem(8)};
  align-items: center;
  margin-top: ${toRem(12)};
  margin-bottom: ${toRem(16)};
`;

export const UserFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
    }
  }
`;

export const UserDocsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${toRem(8)};
  gap: ${toRem(16)};
`;

export const UserDocContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(4)};
`;
