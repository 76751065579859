import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div<{
  $size: string;
  $bgImage?: string;
  $hasIcon?: boolean;
}>`
  width: ${(props) => props.$size};
  height: ${(props) => props.$size};
  border-radius: 50%;
  overflow: hidden;
  display: ${(props) => (props.$hasIcon ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;
  background: ${(props) => {
    if (props.$bgImage) {
      return `url(${props.$bgImage}) center / cover no-repeat white`;
    }
    if (props.$hasIcon) {
      return COLORS.grey950;
    }
    return 'none';
  }};

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .i-icon {
    color: ${COLORS.grey500};
    display: flex;
  }
`;
