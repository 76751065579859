import React, { FC, memo } from 'react';

import { IFutsalEventView, IMatchDetails } from 'services/v3/Match/interfaces';
import { IFutsalEventDeletionPayload } from 'services/v3/Match/payload-interfaces';

import { IGroupedFutsalEvents } from 'pages/V3/GamesControl/interfaces';
import { Timeline } from 'components/v3/Timeline/Timeline';
import { Scoreboard } from 'components/v3/Scoreboard/Scoreboard';

import * as S from './styles';

interface IGameFactsProps {
  match: Maybe<IMatchDetails>;
  penaltyShootout: Maybe<IFutsalEventView[]>;
  futsalEvents: Maybe<IGroupedFutsalEvents[]>;
  getTeamFouls: (rosterId: string) => number;
  onDeleteFutsalEvent: (payload: IFutsalEventDeletionPayload) => void;
}

export const GameFacts: FC<IGameFactsProps> = memo(
  ({
    match,
    futsalEvents,
    penaltyShootout,
    getTeamFouls,
    onDeleteFutsalEvent,
  }) => {
    return (
      <S.Container>
        <S.Wrapper>
          <Scoreboard
            awayTeam={match?.awayTeam || null}
            homeTeam={match?.homeTeam || null}
            result={match?.result || null}
            getTeamFouls={getTeamFouls}
          />
          {!!futsalEvents?.length && (
            <S.BorderWrapper>
              <Timeline
                awayPlayers={match?.awayTeam?.players || null}
                homePlayers={match?.homeTeam?.players || null}
                homeCoaches={match?.homeTeam?.coaches || null}
                awayCoaches={match?.awayTeam?.coaches || null}
                result={match?.result || null}
                events={futsalEvents}
                penaltyShootout={penaltyShootout}
                onDeleteFutsalEvent={onDeleteFutsalEvent}
              />
            </S.BorderWrapper>
          )}
          {!futsalEvents?.length && (
            <S.EmptyState>The game facts are absent</S.EmptyState>
          )}
        </S.Wrapper>
      </S.Container>
    );
  }
);

GameFacts.displayName = 'GameFacts';
