import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${toRem(32)};

  button {
    padding: 0;
  }
`;

export const Tabs = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: ${toRem(10)};
`;

export const TabItem = styled.li`
  font-family: 'Boxed Regular';
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.grey300};
  cursor: pointer;
  border-bottom: 2px solid transparent;

  & + li {
    margin-left: ${toRem(26)};
  }

  &.active {
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active,
  &:hover {
    &:not(.disabled) {
      color: ${COLORS.brandPrimary};
      border-color: ${COLORS.brandPrimary};
    }
  }
`;

export const Container = styled.div`
  border-radius: ${toRem(24)};
  overflow: hidden;
`;

export const NotificationItemContainer =
  styled.div <
  { $unread: boolean } >
  `
  display: flex;
  align-items: center;
  padding: ${toRem(24)} ${toRem(27)};
  background-color: ${(props) =>
    props.$unread ? COLORS.grey900 : COLORS.grey950};

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.grey800};
  }
`;

export const Bullet = styled.span`
  display: block;
  width: ${toRem(14)};
  height: ${toRem(14)};
  border-radius: 50%;
  margin-right: ${toRem(10)};
  background-color: ${COLORS.brandPrimary};
`;

export const Image = styled.img`
  width: ${toRem(50)};
  height: ${toRem(50)};
  margin-right: ${toRem(10)};
  border-radius: 50%;
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1rem;
`;

export const Buttons = styled.div`
  display: flex;

  button {
    & + button {
      margin-left: 1rem;
    }
  }
`;

export const WrapperPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${toRem(10)};
`;
