import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import B2bSetupEventService from 'services/v3/B2bSetupEvent/SetupEventService';
import { sideModalCloseModal } from 'redux/sideModal/actions';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { UFRosterFeeParams } from './types';

const useSetUFEventRosterFee = (eventId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: UFRosterFeeParams) =>
      B2bSetupEventService.setUfEventRosterFee(eventId, dto),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-uf-roster-fee', eventId]);
      dispatch(b2bSetupEventFetchEventRequest(eventId));
      dispatch(sideModalCloseModal());
    },
  });
};

export default useSetUFEventRosterFee;
