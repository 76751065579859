import React, { FC } from 'react';

import { getDivisionInfo } from 'pages/V3/event/CheckoutAsClubDirector/utils';
import StripeCard from 'components/v3/Cards/StripeCard/StripeCard';

import { COLORS } from 'styles/v3/variables';

import { UpdateQty } from './components/UpdateQty/UpdateQty';
import { DivisionItemProps } from './types';

import * as S from './styles';

export const DivisionItem: FC<DivisionItemProps> = ({
  item,
  price,
  divisions,
}) => {
  const subtitle = getDivisionInfo(item);

  return (
    <S.Container>
      <StripeCard
        title={item.name ?? ''}
        subtitle={subtitle}
        description={price ? `$${price}` : ''}
        stripeColor={item.color || COLORS.pink}
        size='small'
        width='100%'
        hasChildren
      >
        <UpdateQty divisions={divisions} divisionId={item.id} />
      </StripeCard>
    </S.Container>
  );
};
