import React from 'react';
import { Avatar } from 'antd';
import { BodyM, BodyS } from 'styles/v3/variables';
import ConvertUtil from 'util/ConvertUtil';
import ClubPlaceholder from 'assets/imgs/avatar_placeholder.png';
import { Right } from '@icon-park/react';
import { Link, useParams } from 'react-router-dom';
import * as S from './styles';

type TeamDivisionCardType = {
  name?: string,
  games?: number,
  logoSrc?: string,
  clubId?: string,
  divisionId?: string,
};

export const TeamDivisionCard = ({
  name,
  games,
  clubId,
  logoSrc,
  divisionId,
}: TeamDivisionCardType) => {
  const { eventId } = useParams<{ eventId: string }>();

  const handleRedirect = (): string | undefined => {
    if (!eventId || !clubId || !divisionId) {
      return undefined;
    }

    return `/v3/event/${eventId}/schedule/team/${clubId}?division=${divisionId}`;
  };

  const cardContent = (
    <S.Container>
      <S.AvatarWrapper>
        <Avatar
          size={48}
          src={logoSrc ? ConvertUtil.getMinioUrl(logoSrc) : ClubPlaceholder}
        />
        <S.TextsWrapper>
          <BodyM $color='grey50' $isUpper>
            {name}
          </BodyM>
          <BodyS $color='grey300' $isUpper>
            {games} Games
          </BodyS>
        </S.TextsWrapper>
      </S.AvatarWrapper>
      <S.ActionWrapper>
        <Right size={24} />
      </S.ActionWrapper>
    </S.Container>
  );

  const redirectUrl = handleRedirect();

  return redirectUrl ? (
    <Link to={redirectUrl}>{cardContent}</Link>
  ) : (
    <>{cardContent}</>
  );
};
