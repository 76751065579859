import { AxiosRequestConfig } from 'axios';
import { UserStats } from 'models/Stats/UserStatsModel';
import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import PageResponse from 'services/util/PageResponse';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';


class StatsService {
  public static async getUserStats(filter?: {
    userIdList?: string[];
    actionList?: string[];
    timeFrame?: string;
  }): Promise<ServiceResponseV3<PageResponse<UserStats>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig('stats'),
      url: '/rankings/users',
      method: 'POST',
      data: filter,
    };
    return APIServicesV3.request<PageResponse<UserStats>>(axiosConfig);
  }
}

export default StatsService;
