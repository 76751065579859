import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div<{ divisionColor?: string }>`
  background-color: ${COLORS.grey900};
  padding: ${toRem(24)} ${toRem(20)};
  width: 100%;
  border-bottom: 1px solid ${COLORS.grey800};
  border-left: 6px solid ${(props) => props.divisionColor ?? COLORS.brandPrimary};
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(16)};
  width: 100%;
`;

export const Tag = styled.div`
  background-color: ${COLORS.grey800};
  padding: ${toRem(6)} ${toRem(12)};
  border-radius: 24px;
`;

export const TeamContainer = styled.div``;

export const TeamItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${toRem(8)};
`;

export const Team = styled.div`
  align-items: center;
  display: flex;
  gap: ${toRem(8)};
`;

export const Score = styled.div`
  align-items: center;
  background-color: ${COLORS.grey800};
  display: flex;
  height: ${toRem(32)};
  justify-content: center;
  width: ${toRem(43)};
  border-radius: 3px;
`;
