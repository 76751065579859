import { Collapse } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled(motion.div)`
  flex: 0 0 65%;

  height: fit-content !important;

  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey950};
  padding: ${toRem(24)} ${toRem(24)} ${toRem(24)} ${toRem(24)};
`;

export const PoolWrapper = styled(Collapse)`
  display: flex;
  flex-direction: column;
  background-color: unset;

  gap: ${toRem(24)};

  padding: ${toRem(24)} 0 ${toRem(16)} 0;
`;

export const PoolPanel = styled(Collapse.Panel)`
  justify-content: space-between;
  background-color: ${COLORS.grey900};
  border-radius: ${toRem(8)} !important;

  .ant-collapse-header {
    justify-content: space-between;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex: 1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(24)};
`;

export const FallbackMessageWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;

  margin-top: ${toRem(32)};

  gap: ${toRem(4)};
`;

export const PoolNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(6)};
`;
