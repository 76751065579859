import React, { useEffect, useMemo } from 'react';
import { COLORS } from 'styles/v3/variables';
import Icon from '@icon-park/react/es/all';
import { BracketView } from 'models/Bracket/BracketModel';
import { useGetMatchesByBracketId } from 'hooks/v3/brackets/useGetMatchesByBracketId/useGetMatchesByBracketId';
import {
  BracketDelete,
  BracketItemContainer,
  BracketItemHeader,
  BracketRenderContainer,
  BracketRenderItem,
} from './styles';
import { getBracketRender } from './components/BracketRenderByType';

interface BracketItemProps {
  bracket: BracketView;
  onDelete?: () => void;
  onUpdate?: () => void;
  handleOpenEditModal?: (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => void;
}

export const BracketItem: React.FunctionComponent<BracketItemProps> = ({
  onDelete,
  bracket,
  handleOpenEditModal,
  onUpdate,
}) => {
  const BracketRender = useMemo(() => {
    return getBracketRender(bracket.type);
  }, [bracket]);

  const { data: matches } = useGetMatchesByBracketId(bracket.id);

  return (
    <BracketItemContainer>
      <BracketItemHeader>
        <p>Main Bracket</p>
        {onDelete && typeof onDelete === 'function' && (
          <BracketDelete $color={COLORS.grey200} onClick={onDelete}>
            <Icon type='delete' size={24} />
          </BracketDelete>
        )}
      </BracketItemHeader>
      <BracketRenderContainer>
        <BracketRenderItem>
          <BracketRender
            matches={matches}
            bracketId={bracket.id}
            handleOpenEditModal={handleOpenEditModal}
            onUpdate={onUpdate}
          />
        </BracketRenderItem>
      </BracketRenderContainer>
    </BracketItemContainer>
  );
};
