import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import { MembershipView } from 'admin/models/membership/Membership';

import { BodyS } from 'styles/v3/variables';

import * as F from './components'

export type DataType = {
  id: string,
  name: string,
  permission: 'PLAYER'| 'COACH' | 'CLUB_DIRECTOR' |'REFEREE' | 'TOURNAMENT_DIRECTOR' | 'STAFF',
  age: number,
  gender: string,
  membership: MembershipView['type'],
  photo: string,
};

export const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render(_, {name, photo}) {
      return <F.Name name={name} photo={photo} />;
    },
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    render(value) {
      return  <F.Permission permission={value} />;
    },
  },
  {
    title: 'XP',
    dataIndex: 'xp',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Age',
    dataIndex: 'age',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Membership',
    dataIndex: 'membership',
    render(value) {
      return <F.Membership membership={value} />;
    },
  },
 
];
