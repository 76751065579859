import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Write, Check, Close } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { NewAgeDivision } from 'admin/models/AgeDivision';

import { AgeRulesEnumsToLabel } from 'pages/V3/setupEvent/Divisions/constants';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButton from 'components/v3/Buttons/FilledButton';
import IconButton from 'components/v3/Buttons/IconButton';

import { COLORS, TitleH2, BodyM, BodyMBold } from 'styles/v3/variables';

import {
  Container,
  HeaderInformationsContainer,
  HeaderTitleContainer,
  AvatarEventInformation,
  EventImage,
  RulesInformation,
  Rules,
  ButtonsHeader,
  LocksContainer,
  EditBt,
  EditFormWrapper,
  EditForm,
  EditInputField,
  EditRosterConfirm,
} from './styles';

interface HeaderRosterEditProps {
  rosterId: Maybe<string>;
  name: string;
  eventName: string;
  eventLogo: string;
  division: NewAgeDivision;
  locksIn?: Date;
  onGoBack: () => void;
  onUpdateRosterName: (rosterId: string, name: string) => void;
  onUpdateDivisionLockStatus: () => void;
}

export const HeaderRosterEdit: FC<HeaderRosterEditProps> = ({
  rosterId,
  name,
  eventName,
  eventLogo,
  division,
  locksIn = new Date(),
  onGoBack,
  onUpdateRosterName,
  onUpdateDivisionLockStatus,
}) => {
  const [editRosterNameShown, setEditRosterNameShown] = useState(false);
  const [rosterNameValue, setRosterNameValue] = useState('');

  const lockDate = new Date(locksIn).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const years = useMemo(() => {
    return division.years.map((year) =>
      AgeRulesEnumsToLabel[division.rule].replace('Year', year.toString())
    );
  }, [division.rule, division.years]);

  const allowGender = useCallback(() => {
    if (division.allowGirlBoysOnAnotherDivision) {
      if (division.gender === 'FEMALE') {
        return `- Boys can Play`;
      }

      if (division.gender === 'MALE') {
        return `- Girls can Play`;
      }
    }

    return '';
  }, [division]);

  const handleEditRosterName = useCallback(() => {
    setEditRosterNameShown(true);
  }, []);

  const handleCloseEditRosterName = useCallback(() => {
    setEditRosterNameShown(false);
    setRosterNameValue(name);
  }, [name]);

  const handleChangeRosterName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRosterNameValue(event.target.value);
    },
    []
  );

  const handleConfirmEditRosterName = useCallback(() => {
    if (rosterId) {
      onUpdateRosterName(rosterId, rosterNameValue);
    }

    setEditRosterNameShown(false);
  }, [rosterId, rosterNameValue, onUpdateRosterName]);

  useEffect(() => {
    setRosterNameValue(name);
  }, [name]);

  return (
    <Container>
      <HeaderInformationsContainer>
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={onGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <HeaderTitleContainer>
          <AvatarEventInformation>
            <EventImage>
              <img src={ConvertUtil.getMinioUrl(eventLogo)} alt='Event Logo' />
            </EventImage>
            <BodyM>{eventName}</BodyM>
          </AvatarEventInformation>
          <EditFormWrapper>
            {!editRosterNameShown && <TitleH2>{rosterNameValue}</TitleH2>}
            {editRosterNameShown && (
              <EditForm>
                <EditInputField
                  id='roster_name'
                  value={rosterNameValue}
                  onChange={handleChangeRosterName}
                />
                <IconButton
                  onClick={handleCloseEditRosterName}
                  icon={<Close size={20} className='reset' />}
                />
                <EditRosterConfirm
                  onClick={handleConfirmEditRosterName}
                  disabled={rosterNameValue === name}
                  icon={
                    <Check
                      size={20}
                      fill={
                        rosterNameValue === name
                          ? COLORS.grey300
                          : COLORS.grey900
                      }
                      className='reset'
                    />
                  }
                />
              </EditForm>
            )}
            {!editRosterNameShown && (
              <EditBt onClick={handleEditRosterName}>
                <Write theme='outline' size='16' />
                Edit name
              </EditBt>
            )}
          </EditFormWrapper>
        </HeaderTitleContainer>
        <RulesInformation>
          <Rules>
            <BodyMBold $color='grey400'>Gender Rules</BodyMBold>
            <BodyM $color='grey400'>
              {division.gender} {allowGender()}
            </BodyM>
          </Rules>
          <Rules>
            <BodyMBold $color='grey400'>Years Allowed</BodyMBold>
            <BodyM $color='grey400'>{years.join(', ')}</BodyM>
          </Rules>
          {division.gender === 'COED' && (
            <Rules>
              <BodyMBold $color='grey400'>Coed Rules</BodyMBold>
              <BodyM $color='grey400'>{division.coedRules}</BodyM>
            </Rules>
          )}
        </RulesInformation>
      </HeaderInformationsContainer>
      <ButtonsHeader>
        <FilledButton
          color={division?.locked ? 'white-dark' : 'primary'}
          isBold={false}
          isUpper
          onClick={onUpdateDivisionLockStatus}
        >
          {division?.locked ? 'Unlock Roster' : 'Lock Roster'}
        </FilledButton>
        <LocksContainer>
          <BodyM $isUpper $color='grey400'>
            Locks
          </BodyM>
          <BodyM $isUpper $color='grey50'>
            {lockDate}
          </BodyM>
        </LocksContainer>
      </ButtonsHeader>
    </Container>
  );
};
