import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { Dot as DotIcon } from '@icon-park/react';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${toRem(12)} 1fr ${toRem(40)};
  column-gap: ${toRem(24)};
  background: ${COLORS.grey950};
  cursor: pointer;
  &:hover {
    background: ${COLORS.grey850};
  }

  align-content: center;
  align-items: center;

  padding: 0;

  border-bottom: 1px solid ${COLORS.grey800};
`;

export const Bar =
  styled.div <
  { $color: string } >
  `
  width: ${toRem(4)};

  ${Container}:hover & {
    width: 100%;
    border-radius: 0 ${toRem(8)} ${toRem(8)} 0;
  }

  min-height: ${toRem(55)};
  height: 100%;
  background: ${(props) => props.$color};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(2)};

  .item-title {
    color: ${COLORS.grey50};
    margin: 0;
  }

  .item-description {
    color: ${COLORS.grey400};
    margin: 0;

    display: flex;
    align-items: center;
    column-gap: ${toRem(4)};
  }
`;

export const SelectButtonContainer = styled.div`
  display: flex;
  width: ${toRem(40)};
  height: ${toRem(40)};
  align-items: center;
  justify-content: center;
  margin-left: ${toRem(-12)};

  ${Container}:hover & {
    border-radius: ${toRem(8)};
    background: ${COLORS.grey800};
  }

  cursor: pointer;

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey100};
    }
  }
`;

export const Dot = styled(DotIcon)`
  display: flex;
  align-items: center;
  justify-content: center;

  .i-icon svg {
    width: ${toRem(12)};
    height: ${toRem(12)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey800};
    }
  }
`;
