import React, { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import EventService from 'services/v3/Event/EventService';
import { BodyM, BodyMBold, TitleH4 } from 'styles/v3/variables';
import { Delete, Down, Up } from '@icon-park/react';
import { useGetEventDivisionsOrdered } from 'hooks/v3/event/useGetEventDivisonsOrdered';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import { useGetDeleteSchedule } from 'hooks/v3/schedules/useGetDeleteSchedule';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { useGroups } from 'pages/V3/EventManager/components/Groups/context/GroupsContext';
import { Pool } from 'pages/V3/EventManager/components/Groups/components/DivisionsList/components/Pool';
import { Header } from 'pages/V3/EventManager/components/Groups/components/DivisionsList/components/Header';

// import { useCreatePool } from 'hooks/v3/event/useCreatePool/useCreatePool';
import * as S from './styles';
import {
  findNextCharForPoolName,
  findNextNumberForPoolName,
} from '../../utils';

export const DivisionsList = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data: currentSchedule } = useGetScheduleByEventId(eventId);
  const { mutateAsync: deleteSchedule } = useGetDeleteSchedule(eventId);

  const {
    poolName,
    selectedDivision,
    updateDivisionFn,
    setSelectedDivision,
  } = useGroups();

  const {
    data: ageDivisionsResponse,
    refetch: refetchDivisions,
    isSuccess,
  } = useGetEventDivisionsOrdered({ eventId });

  const poolNameType = useMemo(
    () => (poolName?.includes('Numbers') ? 'Numbers' : 'Letters'),
    [poolName]
  );

  // TODO ENG-140 fix useCreatePool hook unstable work caused by `selectedDivision` entity which is async and could be null
  // const { refetch: createPool, isSuccess: isPoolCreated, isError: isPoolCreateError } = useCreatePool({ eventId, divisionId: selectedDivision.id });

  const handleDeletePool = async (poolId: string) => {
    if (selectedDivision) {
      const newPools = selectedDivision.pools.filter(
        (pool) => pool.id !== poolId
      );

      const newDivision = {
        ...selectedDivision,
        pools: newPools,
      };

      setSelectedDivision(newDivision);

      await updateDivisionFn({ data: newDivision });
      if (currentSchedule) {
        await deleteSchedule();
      }
    }
  };

  const handleCeatePool = useCallback(
    async (divisionId) => {
      if (!selectedDivision || !divisionId) {
        return;
      }

      const poolOrder =
        poolNameType === 'Letters'
          ? findNextCharForPoolName(selectedDivision.pools).toString()
          : findNextNumberForPoolName(selectedDivision.pools).toString();

      // TODO ENG-140 Implement `createPool` callback instead of `EventService.createPool`
      // createPool();

      // TODO Order should be handled on BE
      await EventService.createPool(eventId, divisionId, { order: poolOrder });
      refetchDivisions();
    },
    [selectedDivision]
  );

  const handleDelete = (e: MouseEvent<HTMLSpanElement>, id: string) => {
    e.stopPropagation();
    confirm({
      onOk: () => handleDeletePool(id),
      message: 'Are you sure that you want to delete this pool?',
    });
  };

  const handleCreateTeam = useCallback(
    async (poolId, teams) => {
      if (!selectedDivision) {
        return;
      }

      // TODO Name and Order should be handled on BE
      await EventService.createTeamByPoolId(
        eventId,
        selectedDivision.id,
        poolId,
        { name: 'Team Placeholder', order: teams.length + 1 }
      );
      refetchDivisions();
    },
    [selectedDivision]
  );

  // This effect updates view on ageDivisions re-fetch
  useEffect(() => {
    if (!ageDivisionsResponse || !isSuccess || !selectedDivision) {
      return;
    }

    const { ageDivisions } = ageDivisionsResponse;
    const currentDivision = ageDivisions.find(
      (division) => division.id === selectedDivision.id
    );

    if (currentDivision) {
      setSelectedDivision(currentDivision);
    }
  }, [ageDivisionsResponse, isSuccess]);

  const sortedPools = useMemo(() => {
    if (!selectedDivision?.pools) return [];
    const pools = [...selectedDivision.pools];
    pools.sort((a, b) => a.order.localeCompare(b.order));

    return pools;
  }, [selectedDivision?.pools]);

  if (!selectedDivision) {
    return (
      <S.FallbackMessageWrapper>
        <TitleH4 $color='grey500'>
          Please select a division to view the details.
        </TitleH4>
      </S.FallbackMessageWrapper>
    );
  }

  return (
    <S.Container
      style={{ width: '100%' }}
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ y: 0, opacity: 1, scale: 1 }}
      transition={{
        duration: 0.4,
        ease: 'easeInOut',
      }}
      key={selectedDivision.id}
    >
      <Header onPoolCreate={handleCeatePool} />
      {sortedPools.length > 0 && (
        <S.PoolWrapper
          expandIconPosition='right'
          expandIcon={({ isActive }) =>
            isActive ? <Up size='18px' /> : <Down size='18px' />
          }
        >
          {sortedPools.map((item) => (
            <S.PoolPanel
              key={item.id}
              header={
                <S.Header>
                  <S.TextWrapper>
                    <S.PoolNameWrapper>
                      <BodyMBold $color='white'>{item.name}</BodyMBold>
                      <BodyMBold $color='white'>{item.order}</BodyMBold>
                    </S.PoolNameWrapper>
                    <BodyM $color='grey300'>{item.totalTeams} Teams</BodyM>
                  </S.TextWrapper>
                </S.Header>
              }
              extra={
                <Delete size={22} onClick={(e) => handleDelete(e, item.id)} />
              }
            >
              <Pool
                poolId={item.id}
                teams={item.teams}
                onTeamCreate={(poolId) => handleCreateTeam(poolId, item.teams)}
              />
            </S.PoolPanel>
          ))}
        </S.PoolWrapper>
      )}
    </S.Container>
  );
};
