/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import {
  useHistory,
  useParams,
  matchPath,
  useLocation,
} from 'react-router-dom';
import moment from 'moment';
import { compact } from 'lodash';
import { IconType } from '@icon-park/react/es/all';

import { User } from 'models/User/UserModel';

import Breadcrumb, {
  BreadcrumbItemType,
} from 'components/v3/Breadcrumb/Breadcrumb';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import AvatarStyled from 'components/v3/Elements/Avatares/AvatarStyled/AvatarStyled';

import { useProfile } from 'hooks/v3/profile/useProfile';

import DateUtil from 'util/DateUtil';
import ConvertUtil from 'util/ConvertUtil';

import { TitleH1 } from 'styles/v3/variables';
import AvatarPlaceholder from 'assets/imgs/avatares/avatar_placeholder.png';
import useScreenType from 'hooks/useScreenType';
import { truncateText } from 'util/truncateText';
import * as S from './styles';

interface TemplateProps {
  user?: User;
  children: React.ReactNode;
}

const CompleteUserTemplate: React.FC<TemplateProps> = ({ user, children }) => {
  const history = useHistory();
  const location = useLocation();
  const params: { userId: string } = useParams();
  const [loggedBreadCrumbs, setLoggedBreadCrumbs] = React.useState<
    BreadcrumbItemType[]
  >([]);
  const { currentUser, isAnAdult } = useProfile();

  function handlePreviousStep() {
    history.goBack();
  }

  const userBirthDate =
    typeof currentUser?.birthDate === 'string'
      ? currentUser?.birthDate?.split('T')[0]
      : moment();

  const userAge = DateUtil.getUserAge(userBirthDate);

  const avatarImage = currentUser?.photo
    ? { bgImage: `${ConvertUtil.getMinioUrl(currentUser?.photo)}` }
    : { icon: 'User' as IconType };

  const { isMobile } = useScreenType();
  
  const guardianActive = useMemo(() => {
    return currentUser.guardian || currentUser.guardian1;
  }, [currentUser.guardian, currentUser.guardian1]);

  const addressActive = useMemo(() => {
    return (
      (isAnAdult && currentUser.address) ||
      currentUser.guardian?.address ||
      currentUser.guardian1?.address
    );
  }, [isAnAdult, currentUser, currentUser.guardian, currentUser.guardian1]);

  const doccumentsActive = useMemo(() => {
    return currentUser.photo || currentUser.ageProof;
  }, [currentUser])

  React.useEffect(() => {
    const isActive = (tab: string) => matchPath(location.pathname, tab);
    const guardianStatus = () => {
      if (isActive('/v3/user/:userId/legal-guardian')) return 'active';
      if (currentUser.guardian || currentUser.guardian1) return 'checked';
      return 'locked';
    };

    const addressStatus = () => {
      if (isActive('/v3/user/:userId/address')) return 'active';
      if (
        (isAnAdult && currentUser.address) ||
        currentUser.guardian?.address ||
        currentUser.guardian1?.address
      )
        return 'checked';
      return 'locked';
    };

    const documentStatus = () => {
      if (isActive('/v3/user/:userId/documents')) return 'active';
      if (currentUser.photo || currentUser.ageProof) return 'checked';
      return 'locked';
    };

    const legalUserStep: BreadcrumbItemType | null = !isAnAdult
      ? {
          icon: guardianActive ? 'CheckSmall' : 'none',
          status: guardianStatus(),
          text: 'Legal Guardian',
          link: `/v3/user/${params.userId}/legal-guardian`,
          tooltipText: guardianActive ? '' :
            'To proceed with navigation, you must first complete the previous step',
        }
      : null;

    const newBreadcrumbItems: BreadcrumbItemType[] = compact([
      {
        icon: 'CheckSmall',
        status: (isActive('/v3/user/:userId/info') && 'active') || 'checked',
        text: 'User Info',
        link: `/v3/user/${params.userId}/info`,
      },
      legalUserStep,
      {
        icon: addressActive ? 'CheckSmall' : 'none',
        status: addressStatus(),
        text: 'Address',
        link: `/v3/user/${params.userId}/address`,
        tooltipText: addressActive
          ? ''
          : 'To proceed with navigation, you must first complete the previous step',
      },
      {
        icon: doccumentsActive ? 'CheckSmall' : 'none',
        status: documentStatus(),
        text: 'Documents',
        link: `/v3/user/${params.userId}/documents`,
        tooltipText: doccumentsActive ? '' :
          'To proceed with navigation, you must first complete the previous step',
      },
    ]);

    setLoggedBreadCrumbs(newBreadcrumbItems);
  }, [currentUser]);

  return (
    <S.TemplateUserWrapper>
      <TextButtonWithIcon
        color='light'
        align='flex-end'
        reverse
        icon='back'
        onClick={handlePreviousStep}
      >
        Go back
      </TextButtonWithIcon>
      {user ? (
        !isMobile ? (
          <S.UserDetail>
            <S.UserImage>
              <img
                src={
                  user.photo
                    ? ConvertUtil.getMinioUrl(user.photo)
                    : AvatarPlaceholder
                }
                alt='User Profile pic'
              />
            </S.UserImage>
            <TitleH1 $color='grey50'>
              {truncateText(`${user.firstName} ${user.lastName}`, 25)}
            </TitleH1>
          </S.UserDetail>
        ) : (
          <AvatarStyled
            {...avatarImage}
            size='extra-large'
            caption={`${userAge} y.o.`}
            text={`${currentUser?.firstName} ${currentUser?.lastName}`}
          />
        )
      ) : !isMobile ? (
        <S.TemplateUserHeader>
          <TitleH1>User Profile</TitleH1>
          <AvatarStyled
            {...avatarImage}
            size='extra-large'
            caption={`${userAge} y.o.`}
            text={`${currentUser?.firstName} ${currentUser?.lastName}`}
          />
        </S.TemplateUserHeader>
      ) : (
        <AvatarStyled
          {...avatarImage}
          size='extra-large'
          caption={`${userAge} y.o.`}
          text={`${currentUser?.firstName} ${currentUser?.lastName}`}
        />
      )}

      <Breadcrumb items={loggedBreadCrumbs as BreadcrumbItemType[]} />

      {children}
    </S.TemplateUserWrapper>
  );
};

export default CompleteUserTemplate;
