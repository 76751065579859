import { produce } from 'immer';
import { Reducer } from 'redux';
import { ProfileModalActionTypes, ProfileModalState } from './types';

export const initialState: ProfileModalState = {
  isOpen: false,
};

const reducer: Reducer<ProfileModalState> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ProfileModalActionTypes.ON_OPEN: {
        draft.isOpen = true;
        break;
      }
      case ProfileModalActionTypes.ON_CLOSE: {
        draft.isOpen = false;
        break;
      }
      case ProfileModalActionTypes.ON_TOGGLE: {
        draft.isOpen = !state.isOpen;
        break;
      }
      default:
        break;
    }
  });

export { reducer as profileModalReducer };
