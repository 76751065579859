import { AxiosRequestConfig } from 'axios';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import { ConfigApi } from '../../config.api';
import {
  ApproveOrDeclineApplicationDTO,
  GetApplicationsByEventIdResponse,
} from './types';
import APIServicesV3 from '../../util/ApiServicesV3';

class RefereeApplicationService {
  public static async getApplicationsByEventId(
    eventId?: string
  ): Promise<ServiceResponseV3<GetApplicationsByEventIdResponse[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/referee-application/applications/${eventId}`,
      method: 'GET',
    };
    return APIServicesV3.request(axiosConfig);
  }

  public static async approveOrDeclineApplication(
    dto: ApproveOrDeclineApplicationDTO
  ): Promise<any> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/referee-application/${dto.type}`,
      method: 'PATCH',
      data: {
        eventId: dto.eventId,
        refereeId: dto.refereeId,
      },
    };
    return APIServicesV3.request(axiosConfig);
  }
}

export default RefereeApplicationService;
