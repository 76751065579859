import React from 'react';

import { TeamPlacementProvider } from './context/TeamPlacementContext';
import { TeamWrapper } from './components/TeamWrapper';

const TeamPlacement = () => {
  return (
    <TeamPlacementProvider>
      <TeamWrapper />
    </TeamPlacementProvider>
  );
};

export default TeamPlacement;
