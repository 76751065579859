import React from 'react';
import { Link } from 'react-router-dom';

import { BodyM, BodyS } from 'styles/v3/variables';

import * as S from './styles';

const Footer: React.FC = () => {
  return (
    <S.FooterWrapper>
      <BodyS $color='grey500'>© 2020 UNITEDFUTSAL. All rights reserved.</BodyS>
      <S.FooterNav>
        <ul>
          <li>
            {/* <Link to=''> */}
            <BodyM $isUpper>Contact Us</BodyM>
            {/* </Link> */}
          </li>
          <li>
            {/* <Link to=''> */}
            <BodyM $isUpper>Terms of Use</BodyM>
            {/* </Link> */}
          </li>
          <li>
            {/* <Link to=''> */}
            <BodyM $isUpper>Privacy Policy</BodyM>
            {/* </Link> */}
          </li>
        </ul>
      </S.FooterNav>
    </S.FooterWrapper>
  );
};

export default Footer;
