import { useQuery } from '@tanstack/react-query';
import RefereeApplicationService from 'services/v3/RefereeApplication/RefereeApplicationService';

export function useGetApplicationsByEventId(eventId?: string) {
  const response = useQuery(['event-referee-applications', eventId], () =>
    RefereeApplicationService.getApplicationsByEventId(eventId)
  );

  return {
    ...response,
    data: response.data?.data,
  };
}
