import { loadStripe, RedirectToCheckoutOptions } from '@stripe/stripe-js';
import { KeycloakInstance } from 'keycloak-js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import sadCloud from '../../assets/imgs/icons/sad_cloud.svg';
import loadingGif from '../../assets/imgs/tail-spin.svg';
import { newUserReset } from '../../redux/newUser/actions';
import { ApplicationState } from '../../redux/store';
import PaymentServices from '../../services/Payment/PaymentServices';
import UserServices from '../../services/User/UserApi';
import TokenUtil from '../../util/TokenUtil';
import { setUserId } from '../../util/UsersUtil';

interface IWizardPaymentProps {
  auth?: KeycloakInstance;
}

const WizardPayment: React.FunctionComponent<IWizardPaymentProps> = (props) => {
  const { auth } = props;
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(false);
  const { newUser } = useSelector(
    (state: ApplicationState) => state.newUser
  ).data;
  const history = useHistory();
  const dispatch = useDispatch();
  function redirectStripe(sessionId: RedirectToCheckoutOptions) {
    PaymentServices.getPaymentKey().then(async (res) => {
      const stripe = await loadStripe(res.data.publicKey);
      stripe?.redirectToCheckout(sessionId);
    });
  }

  useEffect(() => {
    if (newUser?.type !== undefined) {
      setLoadingMessage('Creating new user');
      // Send Request to Create new User
      UserServices.newUser(newUser).then((res) => {
        if (res.success) {
          const { membershipId } = newUser;
          const accountId = res.data.id;

          // Set New user as the active one
          setUserId(accountId);

          // Clear preferences
          localStorage.removeItem('vault_preferences');

          // Refresh Token in localStorage to Update UserList
          TokenUtil.refreshToken(auth).then(() => {
            if (
              newUser.membershipPrice !== 0 &&
              newUser.membershipPrice !== undefined &&
              membershipId
            ) {
              setLoadingMessage('Redirecting to payment');
              PaymentServices.fetchCheckoutSession(
                membershipId,
                accountId
              ).then((response) => {
                redirectStripe(response.data);
              });
            } else {
              history.push('/home');
            }
          });

          dispatch(newUserReset());
        } else {
          setError(true);
        }
      });
    } else {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '50px',
        paddingTop: '100px',
      }}
    >
      {!error && (
        <>
          <div style={{ marginRight: '30px' }}>
            <img alt='' src={loadingGif} />
          </div>
          <h3>{loadingMessage}...</h3>
        </>
      )}

      {error && (
        <>
          <div style={{ marginRight: '30px' }}>
            <img alt='' src={sadCloud} />
          </div>
          <div>
            There was an error during your user creation.
            <br />
            Click <Link to='/wizard'>here</Link> and try again. If the problem
            persists, try to contact us.
          </div>
        </>
      )}
    </div>
  );
};

export default WizardPayment;
