import React, { FunctionComponent } from 'react';
import Icon, { IconType } from '@icon-park/react/es/all';
import ConvertUtil from 'util/ConvertUtil';

import { BodyM, COLORS, ColorsInterface } from 'styles/v3/variables';

import {
  Container,
  ContainerCircles,
  CirclesGroup,
  CircleInfo,
} from './BreadCrumbRosterInformationStyles';

interface CircleInformation {
  id: string;
  logo: string;
  name?: string;
}

interface CirclesNestedProps {
  circlesInfos: CircleInformation[];
  maxInformation?: number;
}

const CirclesNested: FunctionComponent<CirclesNestedProps> = ({
  circlesInfos,
  maxInformation = 4,
  ...props
}) => {
  return (
    <ContainerCircles>
      <CirclesGroup
        size='small'
        maxCount={maxInformation}
        maxStyle={{
          color: `${COLORS.white}`,
          backgroundColor: `${COLORS.grey700}`,
        }}
      >
        {circlesInfos?.map((circle) => (
          <CircleInfo
            key={circle.id}
            src={ConvertUtil.getMinioUrl(circle.logo)}
          />
        ))}
      </CirclesGroup>
    </ContainerCircles>
  );
};

interface BreadCrumbRosterInformation {
  text: string;
  status?: 'locked' | 'open';
  icon: IconType;
  bgColor?: string;
  colorText?: keyof ColorsInterface;
  fillIconColor?: string;
  circlesUser: CircleInformation[];
}

export const BreadCrumbRosterInformation: FunctionComponent<BreadCrumbRosterInformation> = ({
  text,
  status = 'open',
  icon,
  circlesUser,
  colorText = 'grey300',
  fillIconColor = COLORS.grey50,
  bgColor = COLORS.grey800,
}) => {
  return (
    <Container status={status} bgColor={bgColor} >
      <Icon type={icon} size='20' fill={fillIconColor} />
      <BodyM
        $isUpper
        $color={colorText}
      >{`${circlesUser.length} ${text}`}</BodyM>
      <CirclesNested circlesInfos={circlesUser} maxInformation={4} />
    </Container>
  );
};
