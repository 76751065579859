import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RosterService from 'services/v3/Rosters/RosterService';
import { IRosterNameUpdatePayload } from 'services/v3/Rosters/types';

export const useUpdateRosterName = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: IRosterNameUpdatePayload) =>
      RosterService.updateRosterName(payload),
    onSuccess: async (_, payload) => {
      notification.success({
        message: 'Roster name was updated successfully!',
      });
      queryClient.invalidateQueries([
        '/update-roster-name',
        payload.rosterId,
        payload.name,
      ]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to update Roster Name!',
        description: error.message,
      });
    },
  });
};
