import { useQuery } from '@tanstack/react-query';
import RequestService from 'services/v3/Request/RequestService';
import {
  GetMyRequestsToClubProps,
  defaultGetMyRequestsToClubProps,
} from 'services/v3/Request/types';

export function useGetRequestsByClubAndStatus({
  clubId,
  status,
  perPage = defaultGetMyRequestsToClubProps.perPage,
  page = defaultGetMyRequestsToClubProps.page,
}: GetMyRequestsToClubProps) {
  const { data, isLoading, error } = useQuery(
    ['get-request-by-club-status', clubId, perPage, page],
    async () =>
      await RequestService.getRequestsByClubAndStatus({
        clubId,
        status,
        perPage,
      })
  );

  return {
    ...data,
    data: data?.data,
    isLoading,
    error,
  };
}
