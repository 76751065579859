import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { ClubView } from 'admin/models/club/Club';
import { FilterDrawerProps } from '../components/FindFiltersDrawer/types';

export enum KeysProps {
  CITY = 'city',
  STATE = 'state',
  COUNTRY = 'country',
  TOTAL_MEMBERS = 'totalMembers',
}

export const useDrawerOptions = (
  items: ClubView[] | undefined,
  keys: KeysProps[] = Object.values(KeysProps)
) => {
  return useMemo(() => {
    if (!items) {
      return {
        city: [],
        state: [],
        country: [],
        totalMembers: [],
      } as FilterDrawerProps['drawerOptions'];
    }

    const uniqueItems: Partial<FilterDrawerProps['drawerOptions']> = {};

    keys.forEach((key) => {
      const keyOptions = uniqBy(items, key).map((item) => ({
        label: String(item[key as keyof ClubView]),
        value: item[key as keyof ClubView],
      }));

      uniqueItems[
        key as keyof FilterDrawerProps['drawerOptions']
      ] = keyOptions as any;
    });

    return uniqueItems as FilterDrawerProps['drawerOptions'];
  }, [items, keys]);
};
