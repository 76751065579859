import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const EventsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${toRem(30)};
`;

export const EventsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const NewEventButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;

  border: none;

  color: ${COLORS.white};
  text-transform: uppercase;
  font-size: ${toRem(16)};

  svg {
    margin-left: ${toRem(8)};
  }
`;
