import React, { FunctionComponent } from 'react';
import { Dot, Right, Unlock, Lock } from '@icon-park/react';
import { Switch } from 'antd';

import { BodyL, BodyS, BodySBold, COLORS, ColorsInterface } from 'styles/v3/variables';
import { PartialSwitch } from 'components/v3/PartialSwitch';

import {
  Container,
  ContainerLeft,
  ContainerRight,
  TitleAndInformationContainer,
  CircleAndTitleContainer,
  CircleDivisionColor,
  InformationOfDivision,
  RosterIsLockedContainer,
  TeamTargetContainer,
} from './styles';

interface DivisionTeamInformationProps {
  id: string;
  title?: string;
  gender?: 'MALE' | 'FEMALE' | 'COED';
  years?: number[];
  allowOtherGender?: boolean;
  teams?: number;
  targetTeams?: number;
  bgColor?: string;
  isLocked?: boolean;
  partiallyLocked?: boolean;
  handleSelectedDivision: (idDivision: string) => void;
  handleUpgradeDivisionStatus: () => void;
}

export const DivisionTeamInformation: FunctionComponent<DivisionTeamInformationProps> = ({
  id,
  title = 'Division Boyz Will be Boyz',
  gender = 'COED',
  years = [2000, 2001],
  allowOtherGender = false,
  teams = 14,
  targetTeams = 14,
  bgColor,
  isLocked,
  partiallyLocked,
  handleSelectedDivision,
  handleUpgradeDivisionStatus,
}) => {
  const handleSelectedDivisionOrSwitch = () => {
    handleSelectedDivision(id);
  };

  const getTextColor = (): keyof ColorsInterface => {
    if (partiallyLocked) {
      return 'grey400';
    }

    if (isLocked) {
      return 'brandPrimary';
    }

    return 'grey500';
  }

  const getTextLock = (): string => {
    if (partiallyLocked) {
      return 'Rosters Partially Locked';
    }

    if (isLocked) {
      return 'All Rosters Locked';
    }

    return 'All Rosters Unlocked';
  }

  const getIconLock = () => {
    if (partiallyLocked) {
      return <></>;
    }

    if (isLocked) {
      return <Lock fill={COLORS.brandPrimary} size={24} />
    }

    return <Unlock fill={COLORS.grey400} size={24} />
  }

  return (
    <Container>
      <ContainerLeft>
        {partiallyLocked ? (
          <PartialSwitch />
        ) : (
          <Switch checked={isLocked} className='switch_active' onChange={handleUpgradeDivisionStatus} />
        )}

        <TitleAndInformationContainer onClick={handleSelectedDivisionOrSwitch}>
          <CircleAndTitleContainer>
            <CircleDivisionColor bgColor={bgColor} />
            <BodyL $color='grey50'>{title}</BodyL>
          </CircleAndTitleContainer>

          <InformationOfDivision onClick={handleSelectedDivisionOrSwitch}>
            <BodyS $color='grey400'>{gender}</BodyS>
            <Dot fill={COLORS.grey800} />
            <BodyS $color='grey400'>
              {years.length > 1
                ? years.map((year) => `${year}, `)
                : `${years[0]} Only`}
            </BodyS>
            {allowOtherGender && (
              <>
                <Dot fill={COLORS.grey800} />
                <BodyS $color='grey400'>
                  Allow {gender === 'FEMALE' ? 'Boys' : 'Girls'}
                </BodyS>
              </>
            )}
          </InformationOfDivision>
        </TitleAndInformationContainer>
      </ContainerLeft>

      <RosterIsLockedContainer onClick={handleSelectedDivisionOrSwitch}>
        <BodyS $color={getTextColor()}>{getTextLock()}</BodyS>
        {getIconLock()}
      </RosterIsLockedContainer>

      <ContainerRight onClick={handleSelectedDivisionOrSwitch}>
        <TeamTargetContainer>
          <BodyS $color='grey300'>Teams / Target</BodyS>
          <BodySBold $color='grey300'>
            {teams} / {targetTeams}
          </BodySBold>
        </TeamTargetContainer>
        <Right fill={COLORS.grey50} size={24} />
      </ContainerRight>
    </Container>
  );
};
