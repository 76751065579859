import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const GeneralRulesForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  fieldset {
    padding: 0 ${toRem(30)} ${toRem(24)};
    border-bottom: 1px solid ${COLORS.grey900};

    & + fieldset {
      margin-top: ${toRem(24)};
    }

    .general-rules-input {
      margin-top: 1rem;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    .input-info {
      color: ${COLORS.grey400};
      padding: 0 ${toRem(16)} 0;
    }

    legend {
      margin: 0;
      line-height: normal;
    }
  }
`;

export const ElementCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 0;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  & > p {
    margin-left: ${toRem(12)};
  }
`;

export const ButtonWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div<{ gap: number; mt?: number }>`
  display: flex;
  flex-direction: column;

  gap: ${({ gap }) => toRem(gap)};
  margin-top: ${({ mt }) => mt && toRem(mt)};
`;
