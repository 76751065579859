import styled from 'styled-components';
import { Modal } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 16px;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const CancelButton = styled(OutlinedButton)`
  width: calc(50% - 6px);
  background-color: transparent !important; // Due to modal library nesty
  border-color: #a7a8a1;
  color: ${COLORS.white};
  transition-duration: 300ms;

  &:hover {
    color: ${COLORS.white};
    border-color: #a7a8a1;
  }
`;

export const ConfirmButton = styled(FilledButton)`
  min-width: calc(50% - 6px);
  align-self: center;
  background-color: ${COLORS.brandPrimary} !important;
  user-select: none;

  &:hover {
    background-color: transparent !important;
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
  }
`;
