import { CloseSmall } from '@icon-park/react';
import { TimePicker } from 'antd';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const StyledModal = styled.div`
  background-color: #363731;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  z-index: 20;
  padding: ${toRem(24)} ${toRem(30)} ${toRem(24)};
  min-height: ${toRem(750)};
`;
export const StyledModalHeader = styled.div`
  color: #ffffff;
  text-align: 'center';
  font-family: 'UF Display';
  font-size: 30px;
  line-height: 1.2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledModalBody = styled.div`
  color: #ffffff;
  text-align: 'center';
  font-family: 'UF Display';
  font-size: 30px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const StyledCloseSmall = styled(CloseSmall)`
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #52544c;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const StyledTimePicker = styled(TimePicker)`
  .ant-picker-input {
    border: 1px solid white;
    border-radius: 0.75rem;
    padding: 0;
    margin: 0;
    input {
      margin: 0;
      padding: 0.5rem 1rem;
      border-radius: 0.75rem !important;
      font-family: 'Boxed Regular';
      font-weight: 500;
      background: rgb(54, 55, 49) !important;
    }
    &:hover,
    &:focus {
      border-color: ${COLORS.brandPrimary};
      border-radius: 0.75rem !important;
    }
  }
  .ant-picker-input > input[disabled] {
    &:hover,
    &:focus {
      border: none;
    }
  }
  .ant-picker-clear {
    display: none;
  }

  .ant-picker-suffix {
    color: #fff;
    right: 16px;
    z-index: 0;
  }

  .ant-picker-panel-container {
    background-color: #282c34;
  }

  .ant-picker-time-panel-cell-inner {
    color: #61dafb;
  }
`;

export const StyledBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;

export const StyledButton =
  styled.button <
  { type: string } >
  `
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  color: ${COLORS.brandPrimary};
  background-color: transparent;
  background-color: ${({ type }) =>
    type === 'submit' && `${COLORS.brandPrimary}`};

  color: ${({ type }) => type === 'submit' && `${COLORS.grey900}`};
  border: 1px solid ${COLORS.brandPrimary};
  p {
    text-transform: uppercase;
  }
`;
