import styled from 'styled-components';

import { BREAKPOINTS } from 'styles/v3/constants';

export const Container = styled.div`
  display: flex;
  padding-right: 1.5rem;
  align-items: center;
  gap: 5.25rem;
  align-self: stretch;
  border-radius: 0.75rem;
  background: var(--grey-900, #363731);
`;

export const ContainerSpace = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  align-self: stretch;
`;

export const TagColor = styled.div`
  width: 0.5rem;
  align-self: stretch;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
`;

export const DivisionInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  display: flex;
  padding: 1rem 1rem 1rem 0rem;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-flow: wrap;
  }
`;

export const DivisionText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--grey-50, #fbfbfa);
`;

export const DivisionTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const DivisionTextBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  color: var(--grey-400, #a7a8a1);
`;

export const DivisionTagsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const DivisionTags = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--grey-50, #fbfbfa);
`;

export const DivisionNotFormated = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--grey-400, #a7a8a1);
`;
