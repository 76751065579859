import React, { useEffect, useMemo, useState } from 'react';

import { Avatar } from 'antd';
import { ClubView } from 'admin/models/club/Club';
import club_placeholder from 'assets/imgs/club_placeholder.png';
import { LocalTwo } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { BodyLBold, TitleH2 } from 'styles/v3/variables';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';

import { useHistory } from 'react-router-dom';
import FilledButton from 'components/v3/Buttons/FilledButton';
import UserType from 'models/User/UserTypeModel';
import { RequestStatus } from 'models/Request/RequestModel';
import { useGetMyRequestsToClub } from 'hooks/v3/requests/useGetMyRequestsToClub/useGetMyRequestsToClub';
import * as S from './styles';
import { JoinModal } from './components/JoinModal/JoinModal';

type HeaderProps = {
  club?: ClubView,
  maxWidth?: boolean,
};

export const Header = ({ club, maxWidth = true }: HeaderProps) => {
  const history = useHistory();
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);

  const {
    isDirectorOfThisClub,
    isMemberOfThisClub,
    isCoachOfThisClub,
    isPlayerOfThisClub,
  } = useClubPermission(club);

  const { data: myClubRequests } = useGetMyRequestsToClub({
    clubId: club?.id,
    status: [RequestStatus.PENDING],
  });

  const appliedAsPlayerAndCoach = useMemo(() => {
    if (!myClubRequests?.content?.length) return false;

    const appliedAs: UserType[] = [];

    myClubRequests.content.forEach((request) => {
      if (request.user.type && !appliedAs.includes(request.user.type))
        appliedAs.push(request.user.type);
    });

    if (isCoachOfThisClub && appliedAs.includes(UserType.PLAYER)) return true;
    if (isPlayerOfThisClub && appliedAs.includes(UserType.COACH)) return true;

    return (
      appliedAs.includes(UserType.COACH) && appliedAs.includes(UserType.PLAYER)
    );
  }, [myClubRequests]);

  useEffect(() => {
    if ((isDirectorOfThisClub || isMemberOfThisClub) && club) {
      history.push(`/v3/clubs/${club.id}/dashboard`);
    }
  }, [isDirectorOfThisClub, isMemberOfThisClub, club]);

  return (
    <S.Container
      $maxWidth={maxWidth}
      $image={
        club?.header ? ConvertUtil.getMinioUrl(club?.header) : club_placeholder
      }
    >
      {club ? (
        <JoinModal
          isOpen={isJoinModalOpen}
          handleIsOpen={setIsJoinModalOpen}
          club={club}
        />
      ) : null}

      <S.HeaderContent>
        <S.AvatarWrapper>
          <Avatar
            size={140}
            src={ConvertUtil.getMinioUrl(club?.logo)}
            style={{ backgroundColor: 'white' }}
          />
          <S.TitleWrapper>
            <TitleH2>{club?.name}</TitleH2>
            <S.SubTitleWrapper>
              <LocalTwo />
              <BodyLBold>
                {club?.city}, {club?.country}
              </BodyLBold>
            </S.SubTitleWrapper>
          </S.TitleWrapper>
        </S.AvatarWrapper>
        {club?.allowApplications &&
          (!isCoachOfThisClub || !isPlayerOfThisClub) && (
            <FilledButton
              className='btn-request'
              disabled={appliedAsPlayerAndCoach}
              isUpper
              onClick={() => setIsJoinModalOpen(true)}
            >
              {appliedAsPlayerAndCoach ? 'Waiting Approval' : 'Request to Join'}
            </FilledButton>
          )}
      </S.HeaderContent>
    </S.Container>
  );
};
