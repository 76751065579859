import React from 'react';
import { Avatar } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { BodyLBold, BodyM } from 'styles/v3/variables';
import { Delete } from '@icon-park/react';
import IconButton from 'components/v3/Buttons/IconButton';
import ConvertUtil from 'util/ConvertUtil';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import * as S from './styles';

type CardProps = {
  id?: string,
  name?: string,
  imgSrc?: string,
  address?: string,
  extraAddressInfo?: React.ReactNode,
  handleRemoveCard: (id: string) => void,
};

export const SimpleCard = ({
  id,
  name,
  imgSrc,
  address,
  handleRemoveCard,
  extraAddressInfo = null,
}: CardProps) => {
  const handleRemove = () => {
    confirm({
      message: 'Are you sure that you want to delete this member?',
      onOk: () => {
        if (id) handleRemoveCard(id);
      },
    });
  };

  return (
    <S.Container>
      <S.Flex>
        <Avatar
          size={62}
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(imgSrc)}
        />
      </S.Flex>
      <S.InfoWrapper>
        <S.BasicInfoWrapper>
          <BodyLBold>{name}</BodyLBold>
          {extraAddressInfo}
          <BodyM $color='grey400'>{address}</BodyM>
        </S.BasicInfoWrapper>
        <IconButton onClick={handleRemove} icon={<Delete size={22} />} />
      </S.InfoWrapper>
    </S.Container>
  );
};
