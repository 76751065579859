import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { BodyS } from 'styles/v3/variables';
import DateUtil from 'util/DateUtil';
import { GetApplicationsByEventIdResponse } from 'services/v3/RefereeApplication/types';
import { DocumentStatusField } from '../DocumentStatusField';
import { Action } from './components';

export const columns: ColumnsType<GetApplicationsByEventIdResponse> = [
  {
    title: 'First Name',
    dataIndex: ['referee', 'firstName'],
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Gender',
    dataIndex: ['referee', 'gender'],
    render(value) {
      return (
        <BodyS $color='grey400'>{value === 'MALE' ? 'Boy' : 'Girl'}</BodyS>
      );
    },
  },
  {
    title: 'Birth Year',
    dataIndex: ['referee', 'birthDate'],
    render(value) {
      return <BodyS $color='grey400'>{DateUtil.getUserAge(value)}</BodyS>;
    },
  },
  {
    title: 'Xp',
    dataIndex: ['referee', 'xp'],
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Document Status',
    render(
      _,
      { referee: { documentationVerified, photoVerified, ageVerified } }
    ) {
      return (
        <DocumentStatusField
          permission='REFEREE'
          ageStatus={ageVerified}
          photoStatus={photoVerified}
          safeSportStatus={documentationVerified}
        />
      );
    },
  },
  {
    title: '',
    render(_, ...rest) {
      return <Action {...rest[0]} />;
    },
  },
];
