export const options = [
  {
    key: 'see-all-games',
    name: 'See all games',
    description: 'Here you will see all games by day and courts',
    to: (eventId: string) => `/v3/event/${eventId}/schedule/see-all-games`,
  },
  {
    key: 'find-your-team',
    name: 'Find your team',
    description:
      'With this option you will be able to find games from a specific team',
    to: (eventId: string) => `/v3/event/${eventId}/schedule/find-your-team`,
  },
  {
    key: 'bracket-from-age-division',
    name: 'Bracket from Age Division',
    description: 'See what the brackets will look like',
    to: (eventId: string) =>
      `/v3/event/${eventId}/schedule/bracket-age-division`,
  },
  {
    key: 'see-results',
    name: 'See results',
    description: 'See results from your age division',
    to: (eventId: string) => `/v3/event/${eventId}/schedule/see-results`,
  },
];
