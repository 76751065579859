import { useQuery } from '@tanstack/react-query';

import RosterService from 'services/v3/Rosters/RosterService';

export function useGetRosterByEventIdDetailed(eventId?: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-roster-detailed-eventId', eventId],
    async () => await RosterService.findDetailedByEventId(eventId)
  );

  return {
    httpCode: data?.httpCode,
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
