import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const BracketDelete = styled.div<{
  $color?: string;
}>`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;

  color: ${({ $color }) => $color || COLORS.grey200};
  cursor: pointer;
`;

export const BracketItemContainer = styled.div`
  margin-top: 24px;
  border-radius: 16px;
  overflow: hidden;

  background: ${COLORS.grey850};
`;

export const BracketItemHeader = styled.header`
  padding: 24px 24px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    color: ${COLORS.grey400};
    font-family: 'Boxed Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const BracketRenderContainer = styled.div`
  overflow-x: auto;
  max-width: 100%;
  padding: 36px 0 24px;
`;

export const BracketRenderItem = styled.div`
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BracketMultiColumnGroupContainer= styled.div`
  width: fit-content;
`

export const BracketColumn = styled.div`
  height: 100%;
  width: 300px;
  padding: 0 50px;

  display: flex;
  gap: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.space-1 {
    gap: 196px;
  }
`;