import React from 'react';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useHistory } from 'react-router-dom';
import { TitleH2 } from 'styles/v3/variables';
import * as S from './styles';

export const Header = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <S.Container>
      <TextButtonWithIcon
        reverse
        icon='back'
        align='flex-end'
        color='brandPrimary'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <TitleH2 $isUpper $color='white'>
        Team placement
      </TitleH2>
    </S.Container>
  );
};
