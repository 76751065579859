import { Avatar } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

type CardWrapperProps = {
  isSelected?: boolean,
};

export const CardWrapper =
  styled.div <
  CardWrapperProps >
  `
  width: 282px;
  height: 310px;

  padding: 30px;
  border-radius: 24px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: 24px;

  cursor: pointer;

  background-color: ${(props) =>
    props.isSelected ? COLORS.brandPrimary : COLORS.grey900};

  color: ${(props) => (props.isSelected ? COLORS.grey950 : COLORS.grey50)};

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;

  max-width: 100%;

  h4 {
    overflow: hidden;
    margin-bottom: 6px;
  }
`;

export const FlexDiv = styled.div`
  flex: 1;

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const UserAvatar = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.grey700};

  span {
    display: flex;
  }
`;
