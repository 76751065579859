import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const CardWrapper =
  styled.div <
  { $selected: boolean } >
  `
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  grid-column-gap: 24px;
  padding: 50px 32px;
  border-radius: 16px;
  border: 1px solid #4b4d46;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
  }

  ${(props) =>
    props.$selected &&
    `
    &:before {
      border: 8px solid ${COLORS.brandPrimary};
    }
  `}
`;

export const Avatar = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: ${COLORS.grey400};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
`;

export const Title = styled.p`
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: 'Boxed Medium';
  font-size: 500;
  font-size: 14px;
  color: ${COLORS.grey200};
`;
