import styled from 'styled-components';
import { CalendarThree } from '@icon-park/react';

import { COLORS, BodyS } from 'styles/v3/variables';

export const DayPickerContainer = styled.div`
  width: 100%;
  font-family: 'Boxed Regular';
  font-weight: 500;

  .rmdp-wrapper {
    border-radius: 10px;
    border-color: ${COLORS.white};
  }

  .rmdp-container {
    width: 100%;
  }

  .date-picker-input {
    width: 100%;
    min-height: 50px;
    border: 1px solid #a7a8a1;
    border-radius: 0.75rem;
    background-color: transparent;
    font-size: 1rem;
    padding: 0 47px 0 16px;
    outline: none;

    &:hover,
    &:focus {
      border-color: ${COLORS.brandPrimary};
    }
  }

  button {
    width: 20px !important; // Due to styles nesty
    transition-duration: 200ms;

    i {
      margin-right: 2px;
      margin-top: 7px;
    }
  }

  .rmdp-ep-arrow {
    display: none;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #0074d9;
    box-shadow: none;
  }

  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden, .rmdp-selected) span:hover {
    background-color: #efefef;
    color: ${COLORS.grey900};
  }

  .rmdp-day.rmdp-today span {
    background-color: transparent;
  }

  .rmdp-day span {
    color: ${COLORS.grey900};
    transition-duration: 200ms;
  }

  .rmdp-arrow-container:hover {
    box-shadow: none;
  }
`;

export const DayPickerForm = styled.div`
  position: relative;
`;

export const ErrorMessage = styled(BodyS)`
  display: block;
  width: 100%;
  color: ${COLORS.supportError};
  padding-top: 6px;
`;

export const CalendarIcon = styled(CalendarThree)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }
`;
