import React from 'react';
import { Modal } from 'antd';
import { BodyL, BodyLBold } from 'styles/v3/variables';
import { useApproveOrDeclineApplication } from 'hooks/v3/referees/useApproveOrDeclineApplication';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { Container } from './styles';

type AlertModalProps = {
  isOpen: boolean;
  eventId: string;
  refereeId: string;
  onClose: () => void;
  type: 'disapprove' | 'approve';
};

export const AlertModal = ({
  type,
  isOpen,
  onClose,
  eventId,
  refereeId,
}: AlertModalProps) => {
  const { mutateAsync } = useApproveOrDeclineApplication({ eventId, onClose });

  const buttonText = type === 'disapprove' ? 'deny' : type;

  const handleAction = async () => {
    await mutateAsync({ type, eventId, refereeId });
  };

  return (
    <Modal
      centered
      footer={false}
      onOk={onClose}
      visible={isOpen}
      onCancel={onClose}
    >
      <Container $type={type}>
        <BodyL $color='grey50'>
          Are you sure you want to <BodyLBold $isUpper>{buttonText}</BodyLBold>{' '}
          this Referee?
        </BodyL>
        <FilledButton isUpper isBold={false} onClick={handleAction}>
          {buttonText}
        </FilledButton>
      </Container>
    </Modal>
  );
};
