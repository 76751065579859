import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import MatchService from 'services/v3/Match/MatchService';
import { MatchMainInfo } from 'services/v3/Match/types';

export const useUpdateMatchesEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (matches: MatchMainInfo[]) =>
      await MatchService.updateMatches(matches),
    onSuccess: async () => {
      notification.success({
        message: 'Game updated successfully',
      });

      queryClient.invalidateQueries(['/get-matches-event']);
    },
    onError: async () => {
      notification.error({
        message: 'An error occurs!',
      });
    },
  });
};
