import { message, Pagination, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ConfirmBox from '../../../../components/base/ConfirmBox/ConfirmBox';
import LightBox from '../../../../components/base/LightBox/LightBox';
import {
  MatchListModel,
  MatchModel,
} from '../../../../models/Match/MatchModel';
import { FilterPaginationMatch } from '../../../../models/Pagination/Pagination';
import { ApplicationState } from '../../../../redux/store';
import { UserType } from '../../../../redux/user/types';
import EventService from '../../../../services/Event/EventService';
import MatchService from '../../../../services/Match/MatchService';
import { color } from '../../../../styles/styleVariables';
import ConvertUtil from '../../../../util/ConvertUtil';
import DateUtil from '../../../../util/DateUtil';
import { AgeDivision, SpecialDivision } from '../../../models/AgeDivision';
import GameReportModal from '../GameReportModal';
import MatchEditModal from '../MatchEditModal';
import MatchesFilter from './MatchesFilter';

interface IRostersListProps {}

const MatchesList: React.FunctionComponent<IRostersListProps> = (props) => {
  const history = useHistory();
  const columns = [
    {
      title: 'Home Team',
      key: 'homeTeam',
      dataIndex: ['homeTeam', 'name'],
      sorter: { multiple: 1 },
      render: (text: any, record: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LogoTeam style={{ marginRight: '5px' }}>
            <img src={ConvertUtil.getMinioUrl(record.homeTeam.logo)} />
          </LogoTeam>
          <div>
            <div style={{ fontWeight: 100 }}>{record.homeTeam.clubName}</div>
            <div>{record.homeTeam.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Result',
      key: 'result',
      render: (text: any, record: any) => (
        <ResultBox>
          <div className='normal-time'>
            <div className='result'>
              {record.result?.homeTeamScore !== undefined
                ? record.result?.homeTeamScore
                : '-'}
            </div>
            <div className='versus'>X</div>
            <div className='result'>
              {record.result?.awayTeamScore !== undefined
                ? record.result?.awayTeamScore
                : '-'}
            </div>
          </div>
          {record.result?.awayTeamPenaltiShootout !== undefined &&
            record.result?.homeTeamPenaltiShootout !== undefined &&
            (record.result?.awayTeamPenaltiShootout !== 0 ||
              record.result?.homeTeamPenaltiShootout !== 0) && (
              <div className='pk'>
                <div className='result'>
                  {record.result?.homeTeamPenaltiShootout}
                </div>
                <div className='versus'>X</div>
                <div className='result'>
                  {record.result?.awayTeamPenaltiShootout}
                </div>
              </div>
            )}
        </ResultBox>
      ),
    },
    {
      title: 'Away Team',
      key: 'awayTeam',
      dataIndex: ['awayTeam', 'name'],
      sorter: { multiple: 1 },
      render: (text: any, record: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LogoTeam style={{ marginRight: '5px' }}>
            <img src={ConvertUtil.getMinioUrl(record.awayTeam.logo)} />
          </LogoTeam>
          <div>
            <div style={{ fontWeight: 100 }}>{record.awayTeam.clubName}</div>
            <div>{record.awayTeam.name}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Date (MM-DD-YYYY)',
      key: 'ageDivision.gender',
      dataIndex: 'date',
      sorter: { multiple: 1 },
      render: (text: any, record: any) => (
        <div>{moment(text).format('MM-DD-YYYY')}</div>
      ),
    },
    {
      title: 'Time',
      key: 'hour',
      dataIndex: 'hour',
      sorter: { multiple: 1 },
      render: (text: any, record: any) => (
        <div>{moment(record.date).format('HH:mm')}</div>
      ),
    },
    {
      title: 'Venue',
      key: 'venue',
      dataIndex: ['venue', 'name'],
      sorter: {
        multiple: 1,
      },
    },
    {
      title: 'Sub Venue',
      key: 'subvenues',
      dataIndex: ['subVenue', 'name'],
      sorter: {
        multiple: 1,
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: {
        multiple: 1,
      },
    },
    {
      title: '',
      key: 'print',
      dataIndex: 'type',

      // eslint-disable-next-line react/display-name
      render: (text: any, record: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {record.report && (
            <div
              className='bt-action i-icon-list'
              style={{ backgroundColor: '#fffb00' }}
              onClick={() =>
                setGameReport({ match: record.id, modalOpened: true })
              }
            />
          )}
          {user.data.type === UserType.STAFF && (
            <div
              className='bt-action i-icon-edit'
              onClick={() => setEditOpen({ match: record, modalOpened: true })}
            />
          )}
          <a href={`/admin-panel/match-sheet/${record.id}`} target='_blank'>
            <div className='bt-action i-icon-print' />
          </a>
          {user.data.type === UserType.STAFF && (
            <div
              className='bt-action i-icon-trash'
              onClick={() =>
                setConfirmDeleteGame({ match: record, modalOpened: true })
              }
            />
          )}
          <Link
            to={{
              pathname: `/admin-panel/tournaments/${eventId}/games/${record.id}/scoresheet`,
              state: history.location.pathname
            }}
          >
            <div className='bt-action i-icon-play' />
          </Link>
        </div>
      ),
    },
  ];

  const { eventId } = useParams<{ eventId: string }>();
  const user = useSelector((state: ApplicationState) => state.user);

  const [availableAgeDivisions, setAvailableAgeDivisions] = useState<
    AgeDivision[]
  >([]);
  const [gameList, setGameList] = useState<MatchListModel[]>([]);
  const [gameListFiltered, setGameListFiltred] = useState<MatchListModel[]>([]);
  const [specialDivisions, setSpecialDivisions] = useState<SpecialDivision[]>(
    []
  );
  const [filter, setFilter] = useState<FilterPaginationMatch>({
    query: '',
    pagination: {
      current: 0,
      size: 10,
      total: 0,
      loading: false,
    },
  });
  const [confirmDeleteGame, setConfirmDeleteGame] = useState<{
    modalOpened: boolean;
    match?: MatchModel;
  }>({
    modalOpened: false,
  });
  const [editOpen, setEditOpen] = useState<{
    modalOpened: boolean;
    match?: MatchModel;
  }>({
    modalOpened: false,
  });

  const [gameReport, setGameReport] = useState<{
    modalOpened: boolean;
    match?: string;
  }>({
    modalOpened: false,
  });

  const refreshMatchList = () => {
    if (eventId) {
      setFilter((filter) => ({
        ...filter,
        pagination: { ...filter.pagination, loading: true },
      }));
      MatchService.search({
        urlParams: ConvertUtil.convertPaginationUrlParam(filter.pagination),
        body: {
          eventIdList: [eventId],
          ...(filter.ageDivision && { ageDivisionList: [filter.ageDivision] }),
          ...(filter.status && { statusList: [filter.status] }),
          ...(filter.specialDivision && {
            specialDivision: filter.specialDivision,
          }),
          quickSearch: filter.query,
          ...(filter.startDate &&
            filter.endDate && {
              date: {
                option: 'BTW',
                values: [filter.startDate, filter.endDate],
              },
            }),
        },
      })
        .then((res) => {
          if (res.success) {
            setGameList(res.data.content);
            setGameListFiltred(res.data.content);
            setFilter((filter) => ({
              ...filter,
              pagination: {
                ...filter.pagination,
                size: res.data.pageSize,
                total: res.data.total,
                current: res.data.page,
              },
            }));
          } else {
            message.error({
              content: 'Problem during game listing load',
              duration: 5,
            });
          }
        })
        .finally(() => {
          setFilter((filter) => ({
            ...filter,
            pagination: {
              ...filter.pagination,
              loading: false,
            },
          }));
        });
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setFilter((filter) => ({
      ...filter,
      pagination: {
        ...filter.pagination,
        current: page - 1,
      },
    }));
  };

  useEffect(() => {
    refreshMatchList();
  }, [
    filter.pagination.current,
    filter.pagination.sort,
    filter.query,
    filter.startDate,
    filter.endDate,
    filter.ageDivision,
    filter.status,
    filter.specialDivision,
  ]);

  useEffect(() => {
    filterDivisions();
  }, [filter]);

  useEffect(() => {
    // Get available age divisions
    EventService.find(eventId).then((res) => {
      if (res.data.ageDivisions) {
        setAvailableAgeDivisions(res.data.ageDivisions);
      }
    });
    EventService.detail(eventId).then((res) => {
      if (res.success) {
        setSpecialDivisions(res.data.specialDivisions || []);
      }
    });
  }, []);

  const deleteGame = (gameId: string) => {
    MatchService.delete(gameId).then((res) => {
      if (res.success) {
        setConfirmDeleteGame({ modalOpened: false });
        refreshMatchList();
      }
    });
  };

  const closeEditAndRefresh = () => {
    setEditOpen({ ...editOpen, modalOpened: false });
    refreshMatchList();
  };

  const filterDivisions = (division?: string) => {
    if (division) {
      const special = division.split('|');
      if (special[0] === 'special') {
        setFilter((filter) => ({
          ...filter,
          specialDivision: special[1],
          ageDivision: undefined,
          pagination: {
            ...filter.pagination,
            current: 0,
          },
        }));
      } else if (parseInt(division) > -1) {
          setFilter((filter) => ({
            ...filter,
            ageDivision: availableAgeDivisions[parseInt(division)],
            specialDivision: undefined,
            pagination: {
              ...filter.pagination,
              current: 0,
            },
          }));
        } else {
          setFilter({
            ...filter,
            ageDivision: undefined,
            specialDivision: undefined,
            pagination: {
              ...filter.pagination,
              current: 0,
            },
          });
        }
    }
  };

  const handleSearchChange = (query: string) => {
    setFilter((filter) => ({
      ...filter,
      query,
      pagination: { ...filter.pagination, current: 0 },
    }));
  };

  const handleGameStatusChange = (
    event: React.FormEvent<HTMLSelectElement>
  ) => {
    const evt = event;
    setFilter({
      ...filter,
      status: evt.currentTarget.value,
      pagination: { ...filter.pagination, current: 0 },
    });
  };

  const handleDateChange = (
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined
  ) => {
    setFilter({
      ...filter,
      startDate:
        moment
          .utc(DateUtil.createDateAsUTC(startDate?.toString()!))
          .startOf('minute') || undefined,
      endDate:
        moment
          .utc(DateUtil.createDateAsUTC(endDate?.toString()!))
          .startOf('minute') || undefined,
      pagination: { ...filter.pagination, current: 0 },
    });
  };
  const handleClearDate = () => {
    setFilter({
      ...filter,
      startDate: undefined,
      endDate: undefined,
      pagination: { ...filter.pagination, current: 0 },
    });
  };

  const getSortParams = (sorter: any) => {
    const resultParam: string[] = [];
    if (sorter.length || sorter.order != undefined) {
      if (Array.isArray(sorter)) {
        for (const sort of sorter) {
          if (Array.isArray(sort.field)) {
            let param = '';
            param += sort.field.join('.');
            param += ':';
            param += sort.order === 'ascend' ? 'asc' : 'desc';
            resultParam.push(param);
          } else {
            let param = '';
            param += sort.field;
            param += ':';
            param += sort.order === 'ascend' ? 'asc' : 'desc';
            resultParam.push(param);
          }
        }
      } else if (Array.isArray(sorter.field)) {
          let param = '';
          param += sorter.field.join('.');
          param += ':';
          param += sorter.order === 'ascend' ? 'asc' : 'desc';
          resultParam.push(param);
        } else {
          let param = '';
          param += sorter.field;
          param += ':';
          param += sorter.order === 'ascend' ? 'asc' : 'desc';
          resultParam.push(param);
        }
    }
    return resultParam;
  };

  const handleTableChange = (
    page: TablePaginationConfig,
    filters: Record<string, (React.Key | boolean)[] | null>,
    sorter: any,
    extra: any
  ) => {
    setFilter((filter) => ({
      ...filter,
      pagination: {
        ...filter.pagination,
        sort: getSortParams(sorter),
      },
    }));
  };

  return (
    <div>
      <h1>Games</h1>

      <div className='group-box'>
        <MatchesFilter
          availableAgeDivisions={availableAgeDivisions}
          onChangeAgeDivision={filterDivisions}
          onChangeGameStatus={handleGameStatusChange}
          onChangeQuery={handleSearchChange}
          onChangeDates={handleDateChange}
          onClearDate={handleClearDate}
          specialDivisions={specialDivisions}
        />

        <Table
          loading={filter.pagination.loading}
          pagination={false}
          rowClassName={(record) => `
            ${record.status === 'FINISHED' ? ' finished-game-row' : ''}
            ${record.status === 'SCHEDULED' ? ' scheduled-game-row' : ''}
          `}
          dataSource={gameListFiltered}
          columns={columns}
          onChange={handleTableChange}
          rowKey={(record) => record.id || ''}
        />

        {filter.pagination.total !== 0 && (
          <div
            style={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              current={filter.pagination.current + 1}
              defaultPageSize={filter.pagination.size}
              total={filter.pagination.total}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <LightBox
        isOpen={editOpen.modalOpened}
        onClose={() => setEditOpen({ ...editOpen, modalOpened: false })}
      >
        <MatchEditModal match={editOpen.match} onSave={closeEditAndRefresh} />
      </LightBox>

      <LightBox
        isOpen={gameReport.modalOpened}
        onClose={() => setGameReport({ ...gameReport, modalOpened: false })}
      >
        <GameReportModal matchId={gameReport.match} />
      </LightBox>

      <ConfirmBox
        isOpen={confirmDeleteGame.modalOpened}
        onClose={() =>
          setConfirmDeleteGame({ ...confirmDeleteGame, modalOpened: false })
        }
        onConfirm={() => deleteGame(confirmDeleteGame.match?.id!)}
        onCancel={() =>
          setConfirmDeleteGame({ ...confirmDeleteGame, modalOpened: false })
        }
        title='Are you sure you want delete this Game?'
        labelConfirm='Yes'
        labelCancel='Cancel'
      />
    </div>
  );
};

const LogoTeam = styled.div`
  width: 25px;
  height: 25px;
  min-width: 25px;
  border-radius: 20px;
  background-color: #fff;
  padding: 2px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

const ResultBox = styled.div`
  .normal-time,
  .pk {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .result {
    padding: 5px;
    width: 30px;
    background-color: #25282d;
    font-size: 14px;
    border-radius: 5px;
  }
  .versus {
    width: 30px;
  }

  .pk {
    margin-top: 5px;
    .result {
      padding: 4px;
      width: 20px;
      background-color: #ffc107;
      font-size: 10px;
      border-radius: 5px;
      color: ${color.greyDark};
    }
  }
`;
export default MatchesList;
