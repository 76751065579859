import React from 'react';
import { PermissionFieldProps } from './types';

import * as S from './styles';

export const Permission = ({ permissions }: PermissionFieldProps) => {
  const permissionCapitalized = permissions.reduce<string[]>((acc, cur) => {
    acc.push(cur[0].toUpperCase() + cur.toLowerCase().slice(1));
    return acc;
  }, []);

  return <S.Container>{permissionCapitalized.join(' / ')}</S.Container>;
};
