import React, { FunctionComponent } from 'react';
import { COLORS } from 'styles/v3/variables';
import { BracketRenderProps } from '../../BracketRender';
import { BracketColumn } from '../../styles';
import { BracketGame, SingleItemBracketGame } from '../BracketGame';

export const SingleElimination2Render: FunctionComponent<BracketRenderProps> = ({
  bracketId,
  matches,
  handleOpenEditModal,
  onUpdate,
}) => {
  return (
    <>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={matches[0]}
          to={{
            color: COLORS.brandPrimary,
            side: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          showPens
          titleSourceEmpty='TBA'
          onUpdate={onUpdate}
        />
      </BracketColumn>
      <BracketColumn>
        <SingleItemBracketGame
          title='Winner'
          from={{ side: true, color: COLORS.brandPrimary }}
        />
      </BracketColumn>
    </>
  );
};
