import React from 'react';

import Loading from 'components/v3/Loading/Loading';
import { TitleH4 } from 'styles/v3/variables';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { useHistory } from 'react-router-dom';
import { GroupCreation } from '../GroupCreation';
import { DivisionsList } from '../DivisionsList';
import { DivisionsInfo } from '../DivisionsInfo';

import * as S from './styles';
import { useGroups } from '../../context/GroupsContext';
import { ContinueToBrackets } from '../ContinueToBrackets';

export const GroupWrapper = () => {
  const history = useHistory();

  const { eventId, divisions, isLoadingFetching } = useGroups();

  const handleRedirect = () => {
    history.push(`/v3/setup-event/my-events/${eventId}`);
  };

  if (isLoadingFetching) {
    return <Loading />;
  }

  if (!divisions?.length) {
    return (
      <S.FallbackMessageWrapper>
        <S.FallbackTextWrapper>
          <TitleH4 $color='grey500'>
            Currently, there are no divisions set up for this event. As the
            event manager, you have the ability to create and structure
            divisions to shape the event. Please use the button below to add
            your first division and start organizing your event effectively.
          </TitleH4>
        </S.FallbackTextWrapper>
        <FilledButton
          isUpper
          color='primary'
          isBold={false}
          onClick={handleRedirect}
        >
          Go to setup event
        </FilledButton>
      </S.FallbackMessageWrapper>
    );
  }

  return (
    <>
      <S.GroupWrapper>
        <GroupCreation />
        <DivisionsList />
        <DivisionsInfo />
      </S.GroupWrapper>
      <ContinueToBrackets />
    </>
  );
};
