import React, { FC } from 'react';

import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { TooltipAppears } from 'components/TooltipAppears/TooltipAppears';

import { BodyLBold, BodyM, BodyMBold } from 'styles/v3/variables';

import * as MainStyles from '../../styles';
import { playersOptions } from '../../constants';

interface IPlayerRulesProps {
  allowPlayerTeam: boolean;
  allowPlayerClub: boolean;
  minimumPlayersNumber?: number;
  maximumPlayersNumber?: number;
  setLabelToPlayersPermission: OptionsType | undefined;
  isPublishedEvent: boolean;
  setAllowPlayerTeam: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowPlayerClub: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PlayerRules: FC<IPlayerRulesProps> = ({
  allowPlayerTeam,
  allowPlayerClub,
  maximumPlayersNumber,
  minimumPlayersNumber,
  setLabelToPlayersPermission,
  isPublishedEvent,
  setAllowPlayerTeam,
  setAllowPlayerClub,
}) => {
  return (
    <fieldset>
      <legend>
        <BodyLBold $color='white'>Player Rules</BodyLBold>
      </legend>
      <TooltipAppears
        enabled={isPublishedEvent}
        placement='top'
        fullWidth
        title='This field cannot be changed when the event is published'
      >
        <InputText
          containerClassName='general-rules-input'
          id='minPlayerRules'
          placeholder='Min Number of Players'
          label='Min Number of Players'
          defaultValue={minimumPlayersNumber}
          type='number'
          min={0}
        />
      </TooltipAppears>
      <TooltipAppears
        enabled={isPublishedEvent}
        placement='top'
        fullWidth
        offsetBottom
        title='This field cannot be changed when the event is published'
      >
        <InputText
          containerClassName='general-rules-input'
          id='maxPlayerRules'
          placeholder='Max Number of Players'
          label='Max Number of Players'
          defaultValue={maximumPlayersNumber}
          type='number'
          min={0}
        />
      </TooltipAppears>
      <BodyMBold $color='grey200'>Permissions</BodyMBold>
      <TooltipAppears
        enabled={isPublishedEvent}
        placement='top'
        fullWidth
        title='This field cannot be changed when the event is published'
      >
        <MainStyles.ElementCheckboxWrapper>
          <InputCheckbox
            id='allowPlayersOnDifferentTeams'
            name='allowPlayersOnDifferentTeams'
            onChange={() => setAllowPlayerTeam(!allowPlayerTeam)}
            checked={allowPlayerTeam}
          />
          <BodyM>
            Allow players to play in{' '}
            <BodyMBold $color='brandPrimary'>different Teams</BodyMBold>
          </BodyM>
        </MainStyles.ElementCheckboxWrapper>
      </TooltipAppears>
      {allowPlayerTeam && (
        <>
          <TooltipAppears
            enabled={isPublishedEvent}
            placement='top'
            fullWidth
            offsetBottom
            title='This field cannot be changed when the event is published'
          >
            <MainStyles.ElementCheckboxWrapper>
              <InputCheckbox
                id='allowPlayersOnDifferentClubs'
                name='allowPlayersOnDifferentClubs'
                checked={allowPlayerClub}
                onChange={() => setAllowPlayerClub(!allowPlayerClub)}
              />
              <BodyM>
                Allow players to play in{' '}
                <BodyMBold $color='brandPrimary'>different Clubs</BodyMBold>
              </BodyM>
            </MainStyles.ElementCheckboxWrapper>
          </TooltipAppears>
          <TooltipAppears
            enabled={isPublishedEvent}
            placement='top'
            fullWidth
            title='This field cannot be changed when the event is published'
          >
            <Select
              name='playerAgeDivisionPermission'
              placeholder='Select Age Division Permission'
              defaultValue={setLabelToPlayersPermission}
              options={playersOptions}
            />
          </TooltipAppears>
        </>
      )}
    </fieldset>
  );
};
