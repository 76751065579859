import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 0 0 35%;
  gap: ${toRem(12)};

  padding: ${toRem(24)} ${toRem(16)} ${toRem(32)} ${toRem(32)};

  border-radius: ${toRem(24)};
  border: ${toRem(2)} dashed ${COLORS.grey700};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

export const Wrapper = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => `calc(${props.height}px - 58px)`};

  transition: height 200ms ease-in-out;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding-right: ${toRem(24)};

  overflow-y: auto;

  gap: ${toRem(12)};

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const TeamWrapper = styled.div`
  width: 100%;
`;
