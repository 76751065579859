import React, { FC, useEffect } from 'react';
import Keycloak from 'keycloak-js';

import Loading from 'components/v3/Loading/Loading';
import { FUTSAL_SEASON_CONFIRM_KEY } from 'components/v3/FutsalSeasonResetConfirm/configs';

interface IV3LogoutProps {
  auth?: Keycloak.KeycloakInstance | undefined;
}

const clearStorageExepectSpecificItems = () => {
  const futsalSeasonConfirmTime = localStorage.getItem(
    FUTSAL_SEASON_CONFIRM_KEY
  );

  localStorage.clear();

  if (futsalSeasonConfirmTime) {
    localStorage.setItem(FUTSAL_SEASON_CONFIRM_KEY, futsalSeasonConfirmTime);
  }
};

const V3Logout: FC<IV3LogoutProps> = ({ auth }) => {
  useEffect(() => {
    clearStorageExepectSpecificItems();
    auth?.logout();
  }, [auth]);

  return (
    <div>
      <Loading />
    </div>
  );
};

export default V3Logout;
