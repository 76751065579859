import React from 'react';

import { BodyS } from 'styles/v3/variables';

import { DivisionButton } from './components/DivisionButton';
import { useTeamPlacement } from '../../../../context/TeamPlacementContext';
import * as S from './styles';

export const MyDivisions = () => {
  const { divisions } = useTeamPlacement();

  return (
    <S.Container>
      {divisions && divisions.length > 0 && (
        <>
          <BodyS $isUpper $color='grey500'>
            Your age divisions
          </BodyS>

          {divisions.map((item) => {
            return (
              <DivisionButton key={item.id} targetColor='grey100' {...item} />
            );
          })}
        </>
      )}
    </S.Container>
  );
};
