import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { Dot as DotIcon } from '@icon-park/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(2)};

  .item-title {
    color: ${COLORS.grey50};
    margin: 0;
  }

  .item-description {
    color: ${COLORS.grey400};
    margin: 0;

    display: flex;
    align-items: center;
    column-gap: ${toRem(4)};
  }
`;

export const Dot = styled(DotIcon)`
  display: flex;
  align-items: center;
  justify-content: center;

  .i-icon svg {
    width: ${toRem(12)};
    height: ${toRem(12)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey800};
    }
  }
`;
