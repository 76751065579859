import React, { useState, useEffect } from 'react';
import Modal, { Styles } from 'react-modal';
import { MultiValue, SingleValue } from 'react-select';
import { Close } from '@icon-park/react';

import { PoolsOfEventResponse } from 'services/v3/Event/types';
import { BracketMatchDTO, BracketMatchOpponent, BracketView, UpdateBracketPut } from 'models/Bracket/BracketModel';

import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import FilledButton from 'components/v3/Buttons/FilledButton';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { toRem } from 'styles/v3/functions';
import { COLORS, TitleH4 } from 'styles/v3/variables';

import { BracketSource, MatchV3Model } from 'models/Match/MatchModel';
import { ModalHeader, ModalBody, ModalFooterButtons } from './styles';

const selectSourceOptions: OptionsType[] = [
  {
    label: 'Pool',
    value: 'pool',
  },
  {
    label: 'Division',
    value: 'division',
  },
  // {
  //   label: 'Winner',
  //   value: 'winner',
  // },
  // {
  //   label: 'Loser',
  //   value: 'loser',
  // }
];

export const ModalStyle: Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0 ,0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    borderRadius: toRem(12),
    background: COLORS.grey900,
    maxWidth: '384px',
    width: '100%',
    position: 'static',
    borderWidth: '0',
    padding: '0',
    margin: '0',
  },
};

interface MatchesForModal {
  id: string;
  matches: BracketMatchDTO[]
}

export interface BracketInformationModal {
  bracket?: BracketView;
  opponent: number;
  matchId: string;
}

interface ModalUpdateGameInfoProps {
  isOpen: boolean;
  matches: MatchesForModal[];
  pools: PoolsOfEventResponse[];
  bracketInformation?: BracketInformationModal;
  handleCloseModal: () => void;
  handleUpdateGameInfo: (
    data: {
      matchId?: string,
      homeTeamBracketSource?: BracketSource,
      awayTeamBracketSource?: BracketSource
    }
  ) => Promise<void>;
}

export const ModalUpdateGameInfo = ({
  isOpen,
  handleCloseModal,
  bracketInformation,
  matches,
  handleUpdateGameInfo,
  ...props
}: ModalUpdateGameInfoProps) => {
  const [defaultSource, setDefaultSource] = useState<number>();
  const [source, setSource] = useState<
    SingleValue<OptionsType> | MultiValue<OptionsType>
  >();
  const [defaultPool, setDefaultPool] = useState<number>();
  const [pool, setPool] = useState<
    SingleValue<OptionsType> | MultiValue<OptionsType>
  >();
  const [defaultSeed, setDefaultSeed] = useState<number>();
  const [seed, setSeed] = useState<
    SingleValue<OptionsType> | MultiValue<OptionsType>
    >();
  const [seedOptions, setSeedOptions] = useState<OptionsType[]>([]);
  const [game, setGame] = useState<
    SingleValue<OptionsType> | MultiValue<OptionsType>
    >();
  
  useEffect(() => {

    // Set seed options based on the pool selected
    if (pool && 'value' in pool) {
      const seedsQuantity = 
        bracketInformation?.bracket?.ageDivision.pools.find(
          (poolItem) => poolItem.id === pool.value
        )?.teams.length
      
       const seedsOptions: OptionsType[] = [...Array(seedsQuantity)].map(
         (_, index) => {
           return {
             label: `${index + 1}`,
             value: index + 1,
           };
         }
       );
      setSeedOptions(seedsOptions);
    }
  }, [pool]);

  const [isDisabled, setIsDisabled] = useState(true);
  const title = bracketInformation?.bracket ? `Source ${bracketInformation.bracket.name}` : `Source Game`;

  const handleMoreOptions = () => {
    const sourceView = source as SingleValue<OptionsType>;

    if (bracketInformation?.bracket?.type) {
      const { ageDivision } = bracketInformation.bracket

      if (sourceView?.value === "pool") {
        const poolsOptions: OptionsType[] = ageDivision.pools
          .sort((a, b) => a.order.localeCompare(b.order))
          .map((poolOfEvent) => {
            return {
              label: `${poolOfEvent.name} ${poolOfEvent.order}`,
              value: poolOfEvent.id,
            };
          });

        return <>
          <Select
            name='pool'
            placeholder='Select Pool'
            options={poolsOptions}
            menuPosition='fixed'
            defaultValue={defaultPool ? poolsOptions[defaultPool] : null}
            required
            onChange={(option) => setPool(option)}
          />
          <Select
            name='seed'
            placeholder='Select Seed'
            options={seedOptions}
            required
            menuPosition='fixed'
            defaultValue={defaultSeed ? seedOptions[defaultSeed] : null}
            onChange={(option) => setSeed(option)}
          />
        </>

      }

      if (sourceView?.value === 'division') {
        return <>
          <Select
            name='seed'
            placeholder='Select Seed'
            options={seedOptions}
            required
            menuPosition='fixed'
            defaultValue={defaultSeed ? seedOptions[defaultSeed] : null}
            onChange={(option) => setSeed(option)}
          />
        </>
      }

      if (sourceView?.value === 'winner' || sourceView?.value === 'loser') {
        return <>
          <Select
            name='game'
            placeholder='Select Game'
            options={seedOptions}
            required
            menuPosition='fixed'
            defaultValue={defaultSeed ? seedOptions[defaultSeed] : null}
            onChange={(option) => setGame(option)}
          />
        </>
      }

    }


    return <></>
  }

  useEffect(() => {
    const sourceView = source as SingleValue<OptionsType>

    if (sourceView?.value === "pool") {
      if (pool && seed) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }

    if (sourceView?.value === 'division') {
      if (seed) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }

    if (sourceView?.value === 'winner' || sourceView?.value === 'loser') {
      if (game) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }

  }, [source, pool, seed, game])

  const changeForDefault = (selected: BracketMatchOpponent) => {
    if (bracketInformation) {
      const { bracket } = bracketInformation

      if (bracket) {
        const { type, ageDivision } = bracket
        const seedsQuantity = +(type.substring(type.length - 1))

        const seedsOptions: OptionsType[] = [...Array(seedsQuantity)].map((_, index) => {
          return {
            label: `${index + 1}`,
            value: index + 1,
          }
        })

        const indexOfSource = selectSourceOptions.findIndex((option) => option.value === selected.source?.toLowerCase())
        setDefaultSource(indexOfSource)

        const sourceSelected = selectSourceOptions[indexOfSource]
        setSource(sourceSelected)

        if (sourceSelected.value === 'pool') {
          const poolsOptions: OptionsType[] = ageDivision.pools.map((poolOfEvent) => {
            return {
              label: `${poolOfEvent.name}`,
              value: poolOfEvent.id,
            }
          });

          const indexOfPool = poolsOptions.findIndex((option) => option.value === selected.poolId)
          const indexOfSeed = seedsOptions.findIndex((option) => option.value === selected.seed)

          setDefaultPool(indexOfPool);
          setDefaultSeed(indexOfSeed);
        }

        if (sourceSelected.value === 'division') {
          const indexOfSeed = seedsOptions.findIndex((option) => option.value === selected.seed)
          setDefaultSeed(indexOfSeed);
        }

        if (sourceSelected.value === 'winner') {
          const indexOfMatch = seedsOptions.findIndex((option) => option.value === selected.winnerGame)
          setDefaultSeed(indexOfMatch);
        }

        if (sourceSelected.value === 'loser') {
          const indexOfMatch = seedsOptions.findIndex((option) => option.value === selected.loserGame)
          setDefaultSeed(indexOfMatch);
        }
      }
    }
  }

  const setEverythingAsDefault = () => {
    setDefaultSource(undefined);
    setDefaultPool(undefined);
    setDefaultSeed(undefined);
    setSource(undefined);
    setPool(undefined);
    setSeed(undefined);
    setGame(undefined);
  }

  useEffect(() => {
    if (bracketInformation) {
      const { matchId, bracket, opponent } = bracketInformation

      if (bracket) {
        if (opponent === 1) {
          const selected = bracket.matches.find(match => match.id === matchId)?.opponent1
          if (selected?.source) {
            changeForDefault(selected)
          }  else {
            setEverythingAsDefault()
          }
        }

        if (opponent === 2) {
          const selected = bracket.matches.find(match => match.id === matchId)?.opponent2
          if (selected?.source) {
            changeForDefault(selected)
          } else {
            setEverythingAsDefault()
          }
        }
      }
    }
  }, [bracketInformation])

  const handleUpdateInfo = () => {
    const sourceView = source as SingleValue<OptionsType>
    const bracketId = bracketInformation?.bracket?.id
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { matchId, opponent } = bracketInformation!

    if (bracketId) {
      if (sourceView?.value === "pool") {
        const poolView = pool as SingleValue<OptionsType>
        const seedView = seed as SingleValue<OptionsType>

        if (poolView && seedView) {

          let infoToPass: MatchV3Model = {};

          if (opponent === 1) {
            infoToPass = {
              matchId,
              homeTeamBracketSource: {
                poolId: poolView.value as string,
                poolPosition: seedView.value as number,
              },
            };
          } else if (opponent === 2) {
            infoToPass = {
              matchId,
              awayTeamBracketSource: {
                poolId: poolView.value as string,
                poolPosition: seedView.value as number,
              },
            };
          }
          handleUpdateGameInfo(infoToPass);
        }
      }


      // if (sourceView?.value === 'division') {
      //   const seedView = seed as SingleValue<OptionsType>
      //   if (seedView) {
      //     let infoToPass: UpdateBracketPut = {}

      //     if (opponent === 1) {
      //       infoToPass = {
      //         opponent1: {
      //           source: 'DIVISION',
      //           seed: seedView.value as number,
      //         }
      //       }
      //     } else if (opponent === 2) {
      //       infoToPass = {
      //         opponent2: {
      //           source: 'DIVISION',
      //           seed: seedView.value as number,
      //         }
      //       }
      //     }

      //     handleUpdateGameInfo(bracketId, matchId, infoToPass);
      //   }
      // }

      // if (sourceView?.value === 'winner') {
      //   const gameView = game as SingleValue<OptionsType>
      //   if (gameView) {
      //     let infoToPass: UpdateBracketPut = {}

      //     if (opponent === 1) {
      //       infoToPass = {
      //         opponent1: {
      //           source: 'WINNER',
      //           winnerGame: gameView.value as number,
      //         }
      //       }
      //     } else if (opponent === 2) {
      //       infoToPass = {
      //         opponent2: {
      //           source: 'WINNER',
      //           winnerGame: gameView.value as number,
      //         }
      //       }
      //     }

      //     handleUpdateGameInfo(bracketId, matchId, infoToPass);
      //   }
      // }

      // if (sourceView?.value === 'loser') {
      //   const gameView = game as SingleValue<OptionsType>
      //   if (gameView) {
      //     let infoToPass: UpdateBracketPut = {}

      //     if (opponent === 1) {
      //       infoToPass = {
      //         opponent1: {
      //           source: 'LOSER',
      //           loserGame: gameView.value as number,
      //         }
      //       }
      //     } else if (opponent === 2) {
      //       infoToPass = {
      //         opponent2: {
      //           source: 'LOSER',
      //           loserGame: gameView.value as number,
      //         }
      //       }
      //     }

      //     handleUpdateGameInfo(bracketId, matchId, infoToPass);
      //   }
      // }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      style={ModalStyle}
      {...props}
    >
      <ModalHeader>
        <TitleH4>{title}</TitleH4>
        <Close size={16} onClick={handleCloseModal} />
      </ModalHeader>
      <ModalBody>
        <Select
          name='source'
          placeholder='Select Source'
          options={selectSourceOptions}
          required
          menuPosition='fixed'
          defaultValue={defaultSource ? selectSourceOptions[defaultSource] : null}
          onChange={(option) => setSource(option)}
        />
        {handleMoreOptions()}
      </ModalBody>
      <ModalFooterButtons>
        <OutlinedButton onClick={handleCloseModal}>Cancel</OutlinedButton>
        <FilledButton disabled={isDisabled} onClick={handleUpdateInfo}>Save</FilledButton>
      </ModalFooterButtons>
    </Modal>
  );
};
