import * as React from 'react';
import { useHistory, matchPath, useLocation } from 'react-router-dom';

import * as S from '../styles';

const Tabs: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const onChangeTab = (path: string) => {
    history.push(path);
  };

  const pathMatch = (path: string) => {
    const match = matchPath(location.pathname, path);
    const hasMatch = match && match.isExact;
    return hasMatch ? 'active' : '';
  };

  return (
    <S.Tabs>
      <S.TabItem
        onClick={() => onChangeTab('/v3/notifications')}
        className={pathMatch('/v3/notifications')}
      >
        All
      </S.TabItem>
      <S.TabItem
        // onClick={() => onChangeTab('/v3/notifications?tab=invites')}
        className={`${pathMatch('/v3/notifications?tab=invites')} disabled`}
      >
        Invites
      </S.TabItem>
    </S.Tabs>
  );
};

export default Tabs;
