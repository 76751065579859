import React, { useCallback } from 'react';

import { useGetJoinedEvents } from 'hooks/v3/event/useGetJoinedEvents/useGetJoinedEvents';

import MyEventCard from 'components/v3/Cards/MyEventCard/MyEventCard';
import Loading from 'components/v3/Loading/Loading';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

export const MyEvents = () => {
  const { data: response, isLoading } = useGetJoinedEvents();

  const permission = useCallback(
    (eventId: string) => {
      const isMember = (
        type: 'playersIds' | 'coachesIds' | 'refereesIds'
      ): boolean => {
        return (
          response?.data?.content
            ?.filter((event) => event.id === eventId)
            .some((filteredEvent) => {
              const selectedTypeIds: string[] = filteredEvent?.[type] || [];
              return filteredEvent.myIds?.some((i) =>
                selectedTypeIds.includes(i)
              );
            }) || false
        );
      };
      return {
        isPlayer: isMember('playersIds'),
        isCoach: isMember('coachesIds'),
        isReferee: isMember('refereesIds'),
      };
    },
    [response]
  );

  return (
    <S.EventsList>
      {isLoading && <Loading />}
      {!isLoading &&
        response?.data?.content?.map((item) => (
          <MyEventCard
            size='small'
            key={item.id}
            id={item.id}
            logo={item.logo}
            title={item.name}
            orientation='column'
            actionText='See event'
            eventStatus={item.draft ? 'draft' : 'published'}
            description={item.description}
            startDate={item.startDate}
            finalDate={item.endDate}
            link={`/v3/event/${item.id}`}
            permission={permission(item.id)}
          />
        ))}
      {!isLoading && !response?.data?.content.length && (
        <BodyM>Join the Event on the Discover Event tab</BodyM>
      )}
    </S.EventsList>
  );
};
