import React, { useState, useMemo, useEffect } from 'react';
import { EventSideMenu } from 'components/base/EventSideMenu';
import { useGetBracketsByEventId } from 'hooks/v3/brackets/useGetBracketsByEventId/useGetBracketsByEventId';
import { useParams } from 'react-router-dom';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { MenuTab as Division } from 'components/base/EventSideMenu/types';
import { ScheduleCalendarContainer } from './styles';
import ScheduleCalendarItem from './components/ScheduleCalendarItem/ScheduleCalendarItem';

const ScheduleCalendar = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const { data: eventBrackets } = useGetBracketsByEventId(eventId);

  const { data: currentSchedule } = useGetScheduleByEventId(eventId);

  const { data: currentEvent } = useGetEventById(eventId);

  const divisions: Division[] = useMemo(() => {
    if (!currentEvent?.ageDivisions) return [];

    const divisionList: Division[] = currentEvent?.ageDivisions.map((d) => {
      return {
        id: d.id,
        title: d.name ?? '',
        status: 'none',
      };
    });

    divisionList.sort((a, b) => a.title.localeCompare(b.title));
    return divisionList;
  }, [currentEvent]);

  const divisionColors = useMemo(() => {
    if (!currentEvent?.ageDivisions) return {};

    return currentEvent.ageDivisions.reduce((prev, value) => {
      return Object.assign(prev, {
        [value.id]: value.color,
      });
    }, {});
  }, [currentEvent]);

  const divisionsData = useMemo(() => {
    if (!currentEvent?.ageDivisions) return [];

    const divisionList = currentEvent?.ageDivisions.map((d) => ({
      id: d.id,
      name: d.name ?? '',
    }));

    divisionList.sort((a, b) => a.name.localeCompare(b.name));
    return divisionList;
  }, [currentEvent]);

  const [selectedId, setSelectedId] = useState<string>(
    divisions.length ? divisions[0].id : ''
  );

  useEffect(() => {
    if (divisions.length) {
      setSelectedId(divisions[0].id);
    }
  }, [eventBrackets]);

  return (
    <ScheduleCalendarContainer
      style={{
        position: 'relative',
      }}
    >
      <EventSideMenu
        hideGoBack
        isDropdown
        title='SCHEDULE'
        items={divisions}
        selectedId={selectedId}
        onSelection={(id: any) => setSelectedId(id)}
        schedule
      />
      <div
        style={{
          width: '100%',
          marginLeft: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <ScheduleCalendarItem
          activeDivision={selectedId}
          currentEvent={currentEvent}
          currentSchedule={currentSchedule}
          eventBrackets={eventBrackets}
          divisionsData={divisionsData}
          divisionColors={divisionColors}
        />
      </div>
    </ScheduleCalendarContainer>
  );
};

export default ScheduleCalendar;
