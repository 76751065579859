import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const BreadcrumbWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    overflow-x: auto;

    padding-bottom: ${toRem(12)};

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #BDBEB8;
    }
    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    ::-webkit-scrollbar-track:active {
      background: #333333;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`;

export const BreadcrumbItem = styled.div<{
  $status: 'active' | 'checked' | 'locked';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${toRem(16)} 0;
  color: ${(props) => {
    if (props.$status === 'active') {
      return COLORS.brandPrimary;
    }
    if (props.$status === 'locked') {
      return COLORS.grey700;
    }
    return COLORS.white;
  }};
  border-bottom: ${(props) =>
    props.$status === 'active' ? `2px solid ${COLORS.brandPrimary}` : 0};

  & + div {
    margin-left: ${toRem(26)};
  }
  cursor: ${(props) =>
    props.$status === 'active' || props.$status === 'locked'
      ? 'default'
      : 'pointer'};
  pointer-events: ${(props) =>
    props.$status === 'active' || props.$status === 'locked' ? 'hover' : 'all'};

  .i-icon {
    display: flex;
    margin-left: ${toRem(8)};
    &::before {
      display: none;
    }
  }
`;
