import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  max-height: 10vh;
  z-index: 2;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > h4 {
    margin: 0;
  }
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  gap: 8px;
  border-radius: 12px;
  background-color: ${COLORS.grey800};

  > span {
    height: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
