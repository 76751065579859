import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const AvatarWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: ${toRem(28)};
`;

export const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${toRem(32)};
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: ${toRem(256)};
`;

export const FallbackMessageWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${toRem(4)};

  text-align: center;

  margin-top: ${toRem(32)};
  padding: 0 ${toRem(64)} 0 ${toRem(64)};
`;
