import styled from 'styled-components';

import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const UpgradeMembershipPlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  h1 {
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;

    span {
      font-size: initial;
      line-height: initial;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: ${toRem(48)} ${toRem(24)} 0;

    h1,
    p {
      width: 100%;
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: ${toRem(140)};

  & > :first-child {
    margin-top: ${toRem(38)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column-reverse;
    gap: ${toRem(64)};
  }
`;

export const BackButtonWrapper = styled.div`
  max-width: 490px;
  width: 100%;
  padding: 0 16px 20px;
  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    max-width: none;
    padding: 0 0 20px;
  }
`;
