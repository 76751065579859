import React, { useEffect, useState } from 'react';
import {
  CameraOutlined,
  FileTextOutlined,
  IdcardOutlined,
} from '@ant-design/icons/lib';
import { Button, message, Space, Table, Tooltip } from 'antd';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'lodash'

import DownloadService from 'services/download/DownloadService';
import UserService from 'services/v3/User/UserService';

import DocumentApprovalBox from 'components/base/DocumentApprovalBox/DocumentApprovalBox';
import PageContainer from 'components/base/PageContainer/PageContainer';
import GroupBox from 'components/uielements/GroupBox/GroupBox';

import { RequestStatus } from 'models/Request/RequestModel';
import { User } from 'models/User/UserModel';

import { membershipsFetchRequest } from 'redux/memberships/actions';
import { BaseUser } from 'redux/v3/baseUsers/types';
import { ApplicationState } from 'redux/store';

import { COLORS } from 'styles/v3/variables';
import DocumentsVerificationServiceV3 from 'services/DocumentsVerification/DocumentsVerificationServiceV3';

const DocumentsApprovalListPageV3: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  const initialState = {
    data: [] as User[] | BaseUser[],
    pagination: {
      current: 0,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ['10', '30', '50', '100'],
    },
    loading: false,
  };

  const genericError = 'An error occurred';

  const [state, setState] = useState(initialState);
  const [urlParams, setUrlParams] = useState<URLSearchParams>(
    new URLSearchParams({
      size: '10',
      page: '0',
    })
  );
  const [documentBoxOpen, setDocumentBoxOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [documentTypeState, setDocumentTypeState] = useState<
    'photo' | 'age' | 'documents'
  >('photo');
  const [currentUser, setCurrentUser] = useState<User>();
  const [feedbackText, setFeedbackText] = useState<string>('');

  const approveDocument = () => {
    const key = 'documents';
    const baseUser = currentUser?.baseUser;
    message.loading({ content: 'Loading...', key });
    if (currentUser != null) {
      DocumentsVerificationServiceV3.approve(
        currentUser.id,
        documentTypeState,
        new URLSearchParams({ baseUser: String(baseUser) })
      ).then((response) => {
        if (response.success) {
          message.success({
            content: 'Document analysis performed.',
            key,
            duration: 3,
          });
          findUsersAndBaseUsers();
        } else {
          message.error({
            content: response.message ?? genericError,
            key,
            duration: 3,
          });
        }
      });
    }
  };

  const rejectDocument = () => {
    const key = 'documents';
    const baseUser = currentUser?.baseUser;
    message.loading({ content: 'Loading...', key });
    if (currentUser != null) {
      DocumentsVerificationServiceV3.decline(
        currentUser.id,
        documentTypeState,
        feedbackText,
        baseUser
      ).then((response) => {
        if (response.success) {
          message.success({
            content: 'Document analysis performed.',
            key,
            duration: 3,
          });
          findUsersAndBaseUsers();
        } else {
          message.error({
            content: response.message ?? genericError,
            key,
            duration: 3,
          });
        }
      });
    }
  };

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, (Key | boolean)[] | null>,
    sorter?: any
  ) => {
    const urlParamsTmp = new URLSearchParams();
    urlParamsTmp.set(
      'page',
      pagination?.current ? (pagination.current - 1).toString() : '0'
    );
    urlParamsTmp.set(
      'size',
      pagination?.pageSize ? pagination.pageSize.toString() : '10'
    );
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        current: pagination?.current ?? 1,
      },
    });
    // setUrlParams(urlParamsTmp);
  };

  useEffect(() => {
    handleTableChange();
  }, []);

  useEffect(() => {
    findUsersAndBaseUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  useEffect(() => {
    dispatch(membershipsFetchRequest());
  }, [dispatch]);

  const confirmRequest = () => {
    approveDocument();
    setDocumentBoxOpen(false);
  };

  const rejectRequest = () => {
    rejectDocument();
    setDocumentBoxOpen(false);
  };

  const downloadFile = async (file: string) => {
    await DownloadService.download(file).then((response) => {
      if (response.success) {
        setDocumentUrl(response.data || '');
        setDocumentBoxOpen(true);
      } else {
        message.error(response.message ?? genericError, 3);
      }
    });
  };

  const openDialog = async (
    user: User,
    documentType: 'photo' | 'age' | 'documents'
  ) => {
    setFeedbackText('');
    setCurrentUser(user);
    setDocumentTypeState(documentType);

    if (documentType === 'photo') {
      setDocumentUrl(user.photo ?? '');
      setDocumentBoxOpen(true);
    } else if (documentType === 'age' && user.ageProof) {
      downloadFile(user.ageProof);
    } else if (documentType === 'documents' && user.extraInfo?.certificate) {
      downloadFile(user.extraInfo?.certificate);
    }
  };

  const findUsersAndBaseUsers = async () => {
    setState({ ...state, loading: true });

    const { data } = await UserService.getAllProfiles();

    const baseUsersPendingDoc = data?.baseUsers?.map((baseUser) => {
      const isPending =
        baseUser.ageVerified === RequestStatus.PENDING ||
        baseUser.documentationVerified === RequestStatus.PENDING ||
        baseUser.photoVerified === RequestStatus.PENDING;

      return {
        ...baseUser,
        baseUser: true,
        pendingDocumentation: isPending,
      };
    });
    const usersPendingDoc = data?.users?.map((user) => {
      const isPending =
        user.ageVerified === RequestStatus.PENDING ||
        user.documentationVerified === RequestStatus.PENDING ||
        user.photoVerified === RequestStatus.PENDING;

      return { ...user, pendingDocumentation: isPending };
    });

    const newUsers = [
      ...(baseUsersPendingDoc || []),
      ...(usersPendingDoc || []),
    ];

    const filterPendingUsers = newUsers.filter((u) => u?.pendingDocumentation);

    if (filterPendingUsers) {
      setState({
        ...state,
        loading: false,
        data: filterPendingUsers,
        pagination: {
          ...state.pagination,
          current: 1,
          total: filterPendingUsers.length,
        },
      });
    } else {
      setState({ ...state, loading: false });
    }
  };

  function photoStatus(record: User) {
    let content;
    if (record.photoVerified === 'PENDING' && record.photo) {
      content = (
        <Tooltip placement='topLeft' title='Photo'>
          <Button
            shape='circle'
            icon={<CameraOutlined />}
            onClick={() => openDialog(record, 'photo')}
          />
        </Tooltip>
      );
    } else if (record.photoVerified === 'APPROVED') {
      content = (
        <Tooltip placement='topLeft' title='Photo Approved'>
          {' '}
          <CameraOutlined style={{ color: '#00cc00', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    } else if (record.photoVerified === 'DECLINED') {
      content = (
        <Tooltip placement='topLeft' title='Photo Declined'>
          {' '}
          <CameraOutlined style={{ color: '#ff0000', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    } else {
      content = (
        <Tooltip placement='topLeft' title='No photo to show'>
          {' '}
          <CameraOutlined
            style={{ color: COLORS.grey700, fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    }
    return content;
  }

  function ageStatus(record: User) {
    let content;
    if (record.ageVerified === 'PENDING' && record.ageProof) {
      content = (
        <Tooltip placement='topLeft' title='Age certificate'>
          <Button
            shape='circle'
            icon={<IdcardOutlined />}
            onClick={() => openDialog(record, 'age')}
          />
        </Tooltip>
      );
    } else if (record.ageVerified === 'APPROVED') {
      content = (
        <Tooltip placement='topLeft' title='Age certificate Approved'>
          {' '}
          <IdcardOutlined style={{ color: '#00cc00', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    } else if (record.ageVerified === 'DECLINED') {
      content = (
        <Tooltip placement='topLeft' title='Age certificate Declined'>
          {' '}
          <IdcardOutlined style={{ color: '#ff0000', fontSize: '20px' }} />{' '}
        </Tooltip>
      );
    } else {
      content = (
        <Tooltip placement='topLeft' title='No age certificate to show'>
          {' '}
          <IdcardOutlined
            style={{ color: COLORS.grey700, fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    }
    return content;
  }

  function documentStatus(record: User) {
    let content;
    if (
      record.documentationVerified === 'PENDING' &&
      record.extraInfo?.certificate
    ) {
      content = (
        <Tooltip placement='topLeft' title='Extra Documents'>
          <Button
            shape='circle'
            icon={<FileTextOutlined />}
            onClick={() => openDialog(record, 'documents')}
          />
        </Tooltip>
      );
    } else if (record.documentationVerified === 'APPROVED') {
      content = (
        <Tooltip placement='topLeft' title='Documents Approved'>
          {' '}
          <FileTextOutlined
            style={{ color: '#00cc00', fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    } else if (record.documentationVerified === 'DECLINED') {
      content = (
        <Tooltip placement='topLeft' title='Documents Declined'>
          {' '}
          <FileTextOutlined
            style={{ color: '#ff0000', fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    } else {
      content = (
        <Tooltip placement='topLeft' title='No documents to show'>
          {' '}
          <FileTextOutlined
            style={{ color: COLORS.grey700, fontSize: '20px' }}
          />{' '}
        </Tooltip>
      );
    }
    return content;
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      render: (text: string, record: User) =>
        replace(`${record.firstName} ${record.middleName} ${record.lastName} ${record.suffix}`, /undefined/ig, ''),
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      sorter: true,
      render: (text: string) => {
        const formatDate = text.length > 10 ? text.split('T')[0] : text;
        return moment(formatDate).format('MM/DD/YYYY');
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      render: (text: string | string[]) => {
        if (typeof text === 'string') return text;
        return '-';
      },
    },
    {
      title: 'Membership',
      dataIndex: 'membershipId',
      sorter: true,
      render: (text: string) =>
        memberships.data.find((e) => e.id === text)?.name ?? 'BASE USER',
    },
    {
      title: 'Date of birth',
      dataIndex: 'birthDate',
      sorter: true,
      render: (text: string) => {
        const formatDate = text.length > 10 ? text.split('T')[0] : text;
        return moment(formatDate).format('MM/DD/YYYY');
      },
    },
    {
      title: 'Pending approval',
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (text: User) => (
        <Space size='middle'>
          {photoStatus(text)}
          {ageStatus(text)}
          {documentStatus(text)}
        </Space>
      ),
    },
  ];

  return (
    <PageContainer title='Document Analysis'>
      <GroupBox title='List'>
        <Table
          rowKey='id'
          dataSource={state.data}
          loading={state.loading}
          pagination={state.pagination}
          columns={columns}
          onChange={handleTableChange}
        />
      </GroupBox>

      <DocumentApprovalBox
        isOpen={documentBoxOpen}
        onClose={() => setDocumentBoxOpen(false)}
        onConfirm={confirmRequest}
        onCancel={rejectRequest}
        documentType={documentTypeState}
        setFeedback={setFeedbackText}
        title='Check the uploaded file'
        documentUrl={documentUrl}
        user={currentUser}
        labelConfirm='Approve'
        labelCancel='Reject'
      />
    </PageContainer>
  );
};
export default DocumentsApprovalListPageV3;
