import React, { useState } from 'react';
import { BodyLBold } from 'styles/v3/variables';
import { SUB_TAB_KEYS } from './sub-tab-keys';
import { ConfirmedTable, PendingApprovalTable } from './components';
import * as S from './styles';

export const Referees = () => {
  const [activeTab, setActiveTab] = useState(SUB_TAB_KEYS.PENDING_APPROVAL);

  const handleOnChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <S.Container>
      <S.Tabs type='card' activeKey={activeTab} onChange={handleOnChange}>
        <S.TabPane
          key={SUB_TAB_KEYS.PENDING_APPROVAL}
          tab={<BodyLBold>Pending Approval</BodyLBold>}
        >
          <PendingApprovalTable />
        </S.TabPane>
        <S.TabPane
          key={SUB_TAB_KEYS.CONFIRMED}
          tab={<BodyLBold>Confirmed</BodyLBold>}
        >
          <ConfirmedTable />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};
