import * as React from 'react';
import styled from 'styled-components';
import { ArrowRight } from '@icon-park/react';

import OutlinedButton, { OutlinedButtonProps } from './OutlinedButton';

const OutlinedButtonWithIcon: React.FC<
  OutlinedButtonProps & { customIcon?: React.ReactNode, reverse?: boolean }
> = ({ customIcon, children, reverse = false, ...props }) => {
  return (
    <OutlinedButtonIcon reverse={reverse} {...props}>
      {reverse === false && children}
      {customIcon || <ArrowRight />}
      {reverse && children}
    </OutlinedButtonIcon>
  );
};

const OutlinedButtonIcon =
  styled(OutlinedButton) <
    { reverse: boolean } >
    `
  span {
    display: flex;
    font-size: 1.5rem;
    margin-left: ${({ reverse }) => (reverse ? '0rem' : '0.5rem')};
    margin-right: ${({ reverse }) => (reverse ? '0.5rem' : '0rem')};
    align-items: center;
  }
`;

export default OutlinedButtonWithIcon;
