import React, { FC, memo } from 'react';

import { IDetailRoster, IMatchResult } from 'services/v3/Match/interfaces';

import ConvertUtil from 'util/ConvertUtil';

import { BodyLBold } from 'styles/v3/variables';

import * as S from './styles';

interface IScoreboardProps {
  homeTeam: Maybe<IDetailRoster>;
  awayTeam: Maybe<IDetailRoster>;
  result: Maybe<IMatchResult>;
  getTeamFouls: (rosterId: string) => number;
}

export const Scoreboard: FC<IScoreboardProps> = memo(
  ({ homeTeam, awayTeam, result, getTeamFouls }) => {
    return (
      <S.ScoreboardWrapper>
        <S.TeamSection>
          <S.TeamAvatar>
            {homeTeam && (
              <img
                src={ConvertUtil.getMinioUrl(homeTeam.logo)}
                alt={homeTeam.name}
              />
            )}
          </S.TeamAvatar>
          <BodyLBold>{homeTeam?.name || 'TBA'}</BodyLBold>
          <S.TeamLabel>(Home)</S.TeamLabel>
        </S.TeamSection>
        <S.Scoreboard>
          <S.ScoreboardItem>
            <S.ScoreboardText>
              {result ? result.homeTeamScore : '0'}
            </S.ScoreboardText>
            <S.ScoreboardLabel>FOULS</S.ScoreboardLabel>
            <S.ScoreboardLabel>
              {homeTeam ? getTeamFouls(homeTeam.id) : '0'}
            </S.ScoreboardLabel>
          </S.ScoreboardItem>
          <S.ScoreboardText>X</S.ScoreboardText>
          <S.ScoreboardItem>
            <S.ScoreboardText>
              {result ? result.awayTeamScore : '0'}
            </S.ScoreboardText>
            <S.ScoreboardLabel>FOULS</S.ScoreboardLabel>
            <S.ScoreboardLabel>
              {awayTeam ? getTeamFouls(awayTeam.id) : '0'}
            </S.ScoreboardLabel>
          </S.ScoreboardItem>
        </S.Scoreboard>
        <S.TeamSection>
          <S.TeamAvatar>
            {awayTeam && (
              <img
                src={ConvertUtil.getMinioUrl(awayTeam.logo)}
                alt={awayTeam.name}
              />
            )}
          </S.TeamAvatar>
          <BodyLBold>{awayTeam?.name || 'TBA'}</BodyLBold>
          <S.TeamLabel>(Away)</S.TeamLabel>
        </S.TeamSection>
      </S.ScoreboardWrapper>
    );
  }
);

Scoreboard.displayName = 'Scoreboard';
