import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { ApplicationState } from 'redux/store';
import {
  b2bDraftEventFetchProductRequest,
  b2bDraftEventSetEventType,
} from 'redux/v3/b2bDraftEvent/actions';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import PlanCard from 'components/v3/Cards/PlanCard/PlanCard';
import Loading from 'components/v3/Loading/Loading';

import { TitleH1, BodyM, BodyMBold } from 'styles/v3/variables';

import { PlanListProps } from './types';
import { HIGHLIGHTED_PLAN_LIST, SHARED_PLAN_LIST } from './configs';

import * as S from './styles';

const PlanSelection: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const b2bDraftEvent = useSelector(
    (state: ApplicationState) => state.b2bDraftEvent
  );

  const {
    loading,
    data: { events, selectedEvent },
  } = b2bDraftEvent;

  function handleNextStep() {
    if (!selectedEvent) {
      return;
    }

    history.push('/v3/draft-event/general-event');
  }

  const handleSelectEvent = (eventId: string) => {
    dispatch(b2bDraftEventSetEventType(eventId));
  };

  const handleGoBack = useCallback(() => {
    history.push('/v3/events?tab=created-by-me');
  }, []);

  const planList: PlanListProps = {
    B2B_EVENT_LEAGUE: [
      {
        text: 'League Management',
        highlight: false,
      },
      ...[...SHARED_PLAN_LIST],
      {
        text: 'Players get XP (access multiplier system)',
        highlight: false,
      },
      ...[...HIGHLIGHTED_PLAN_LIST],
    ],
    B2B_EVENT_TOURNAMENT: [
      {
        text: 'Tournament Management',
        highlight: false,
      },
      ...[...SHARED_PLAN_LIST],
      ...[...HIGHLIGHTED_PLAN_LIST],
    ],
  };

  useEffect(() => {
    dispatch(b2bDraftEventFetchProductRequest());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  if (!events || events.length === 0 || !Array.isArray(events)) {
    return <h1>No events found... Please Reload the page</h1>;
  }

  if (events === undefined) {
    return <Loading />;
  }

  return (
    <S.PlanSelectionWrapper>
      <S.PlanSelectionText>
        <TextButtonWithIcon
          color='primary'
          align='flex-end'
          reverse
          icon='back'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH1 $spanColor='brandPrimary'>
          No Surprises.
          <br />
          <span>Discover now How much your event will cost.</span>
        </TitleH1>
        <BodyM $color='grey200'>
          Create your futsal event using all the amazing features of Vault.
          Choose between tournament or league, and we&apos;ll tell you the final
          cost.
          <br />
          <BodyMBold $color='white'>
            Fast and skillful like on the courts.
          </BodyMBold>
        </BodyM>
      </S.PlanSelectionText>
      <S.PlanSelectionContainer>
        {events.map((event) => (
          <PlanCard
            key={event.id}
            id={event.id}
            size='large'
            theme={event.type === 'B2B_EVENT_LEAGUE' ? 'secondary' : 'primary'}
            name='plans'
            selected={selectedEvent?.id}
            onClick={() => handleSelectEvent(event.id)}
            onMouseEnter={() => handleSelectEvent(event.id)}
            title={event.title}
            price='$500'
            orientation='column'
            description={
              event.type === 'B2B_EVENT_LEAGUE'
                ? 'Recurrent event with up to 12 weeks'
                : 'Short event with maximum 7 days'
            }
            details={planList[event.type]}
          >
            <FilledButtonWithIcon
              onClick={handleNextStep}
              color={
                event.type === 'B2B_EVENT_LEAGUE'
                  ? 'dark-white'
                  : 'dark-primary'
              }
              isUpper
            >
              {event.type === 'B2B_EVENT_LEAGUE'
                ? 'Continue League'
                : 'Continue Tournament'}
            </FilledButtonWithIcon>
          </PlanCard>
        ))}
      </S.PlanSelectionContainer>
    </S.PlanSelectionWrapper>
  );
};

export default withRouter(PlanSelection);
