import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion, useIsPresent } from 'framer-motion';

import { BodyM } from 'styles/v3/variables';
import { Delete, Edit, Plus } from '@icon-park/react';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { Teams } from 'admin/models/AgeDivision';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { useParams } from 'react-router-dom';
import { useGetDeleteSchedule } from 'hooks/v3/schedules/useGetDeleteSchedule';
import { useGroups } from 'pages/V3/EventManager/components/Groups/context/GroupsContext';
import * as S from './styles';

type PoolType = {
  poolId: string;
  teams: Teams[];
  onTeamCreate: (poolId: string) => void;
};

export const Pool = ({ poolId, teams, onTeamCreate }: PoolType) => {
  const {
    updateDivisionFn,
    selectedDivision,
    setSelectedDivision,
    isLoadingUpdateDivision,
  } = useGroups();
  const { eventId } = useParams<{ eventId: string }>();
  const { data: currentSchedule } = useGetScheduleByEventId(eventId);
  const { mutateAsync: deleteSchedule } = useGetDeleteSchedule(eventId);

  const inputRef = useRef<HTMLInputElement>(null);

  const [editState, setEditState] = useState({ id: '', name: '' });

  const handleEditTeam = (teamId: string, currentName: string) => {
    setEditState({ id: teamId, name: currentName });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSaveEdit();
    else if (event.key === 'Escape') setEditState({ id: '', name: '' });
  };

  const handleSaveEdit = async () => {
    if (selectedDivision && editState.id) {
      const newPools = selectedDivision.pools.map((pool) => {
        if (pool.id === poolId) {
          return {
            ...pool,
            teams: pool.teams.map((team) =>
              team.id === editState.id
                ? { ...team, name: editState.name }
                : team
            ),
          };
        }
        return pool;
      });

      const newDivision = {
        ...selectedDivision,
        pools: newPools,
      };

      setSelectedDivision(newDivision);

      await updateDivisionFn({ data: newDivision });

      setEditState({ id: '', name: '' });
    }
  };

  const handleCreateTeam = useCallback(() => {
    onTeamCreate(poolId);
  }, [poolId, onTeamCreate]);

  const handleDeleteTeam = async (teamIdToDelete: string) => {
    if (selectedDivision) {
      const updatedPools = selectedDivision.pools.map((pool) => {
        const updatedTeams = pool.teams.filter(
          (team) => team.id !== teamIdToDelete
        );

        return {
          ...pool,
          teams: updatedTeams,
          totalTeams: updatedTeams.length,
        };
      });

      const newDivision = {
        ...selectedDivision,
        pools: updatedPools,
      };

      setSelectedDivision(newDivision);

      await updateDivisionFn({ data: newDivision });
      if (currentSchedule) {
        await deleteSchedule()
      }
    }
  };

  const handleDelete = (teamId: string) => {
    confirm({
      message: 'Are you sure that you want to delete this team placeholder?',
      onOk: () => handleDeleteTeam(teamId)
    })
  }

  useEffect(() => {
    if (editState.id && inputRef.current) inputRef.current.focus();
  }, [editState.id]);

  const isPresent = useIsPresent();
  
  const animations = {
    style: {
      position: isPresent ? 'static' : 'absolute' as any,
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: 'spring', stiffness: 400, damping: 40 },
  };

  return (
    <S.Container>
      <S.PoolPlaceholderWrapper>
        <AnimatePresence>
          {teams?.map((item) => (
            <motion.div key={item.id} {...animations}>
              <S.PoolPlaceholder>
                {editState.id === item.id ? (
                  <S.PoolNameWrapper>
                    <input
                      type='text'
                      ref={inputRef}
                      value={editState.name}
                      onBlur={handleSaveEdit}
                      onKeyDown={handleKeyPress}
                      onChange={(e) =>
                        setEditState({ ...editState, name: e.target.value })
                      }
                    />
                    <BodyM $isUpper $color='white'>
                      {item.order}
                    </BodyM>
                  </S.PoolNameWrapper>
                ) : (
                  <S.PoolNameWrapper>
                    <BodyM $isUpper $color='white'>
                      {item.name}
                    </BodyM>
                    <BodyM $isUpper $color='white'>
                      {item.order}
                    </BodyM>
                  </S.PoolNameWrapper>
                )}

                <S.ButtonsAction>
                  <Edit
                    size={24}
                    onClick={() => handleEditTeam(item.id, item.name)}
                  />
                  <Delete size={22} onClick={() => handleDelete(item.id)} />
                </S.ButtonsAction>
              </S.PoolPlaceholder>
            </motion.div>
          ))}
        </AnimatePresence>
      </S.PoolPlaceholderWrapper>
      <S.AddPlaceholderWrapper>
        <OutlinedButtonWithIcon
          isUpper
          customIcon={<Plus />}
          onClick={handleCreateTeam}
          disabled={!selectedDivision || isLoadingUpdateDivision}
        >
          Add team placeholder
        </OutlinedButtonWithIcon>
      </S.AddPlaceholderWrapper>
    </S.Container>
  );
};
