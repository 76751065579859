import moment from 'moment';

import { IDailyEventTimes } from 'services/v3/B2bEvent/types';
import { DeepPartial } from 'util/types';

import { IDailyEventsTime } from './types';
import { TIME_FORMAT, DEFAULT_START_TIME, DEFAULT_END_TIME } from './config';

export const getDefaultDayPickerValues = (
  days?: DeepPartial<IDailyEventTimes[]>
) => {
  const clearDaysList =
    days?.map((value?: DeepPartial<IDailyEventTimes>) => {
      return value?.day ? new Date(value.day) : '';
    }) || [];

  return clearDaysList;
};

export const getValidDaysPayload = (eventTimes: IDailyEventsTime[]) => {
  return eventTimes.map((time) => ({
    day: time.day,
    startTime: time.startTime
      ? moment(time.startTime).format(TIME_FORMAT)
      : moment(`${time.day} ${DEFAULT_START_TIME}`).format(TIME_FORMAT),
    endTime: time.endTime
      ? moment(time.endTime).format(TIME_FORMAT)
      : moment(`${time.day} ${DEFAULT_END_TIME}`).format(TIME_FORMAT),
  }));
};
