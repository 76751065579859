import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS } from 'styles/v3/variables';

export const PrintWrapper = styled.div`
  background-color: #ffff;
  height: 100svh;
`;

export const BaseDesktopWrapper = styled.div`
  background-color: ${COLORS.grey1000};
  height: 100vh;
  color: ${COLORS.white};

  button {
    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
    padding: 10px;
    border-radius: 10px;
  }

  select {
    padding: 10px;
    margin-right: 10px;

    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 0;
  }
`;

export const BaseWrapper = styled.div`
  background-color: ${COLORS.grey1000};
  height: 100vh;
  color: ${COLORS.white};
  padding: 20px;

  button {
    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
    padding: 10px;
    border-radius: 10px;
  }

  select {
    padding: 10px;
    margin-right: 10px;

    color: ${COLORS.grey1000};
    background-color: ${COLORS.brandPrimary};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 0;
  }
`;
