import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import { ApproveOrDeclineApplicationDTO } from 'services/v3/RefereeApplication/types';
import RefereeApplicationService from 'services/v3/RefereeApplication/RefereeApplicationService';

type UseApproveOrDeclineApplicationProps = {
  eventId: string;
  onClose?: () => void;
};

export const useApproveOrDeclineApplication = ({
  eventId,
  onClose,
}: UseApproveOrDeclineApplicationProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: ApproveOrDeclineApplicationDTO) =>
      await RefereeApplicationService.approveOrDeclineApplication(body),
    onSuccess: async () => {
      notification.success({
        message: 'Successfully updated application',
      });

      queryClient.invalidateQueries(['event-referee-applications', eventId]);

      if (onClose) onClose();
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error?.message || 'Unexpected error.',
      });
    },
  });
};
