/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Right } from '@icon-park/react';
import { SelectedSideModal } from 'redux/sideModal/types';
import {
  sideModalOpenModal,
  sideModalSetSelected,
  sideModalSetTitle,
} from 'redux/sideModal/actions';
import { useDispatch } from 'react-redux';

import {
  InfoContainer,
  InfoIconTitle,
  InfoTitleStatus,
  InfoTitle,
  InfoArrow,
  Status,
} from './styles';

export interface InfoEventEditProps {
  title: string;
  status: string;
  selectedModal?: SelectedSideModal;
  children?: ReactElement;
  setCheckedSettings: (value: string) => void;
}

export const InfoEventEdit: React.FC<InfoEventEditProps> = ({
  title,
  status,
  selectedModal,
  children,
  setCheckedSettings,
}) => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    if (selectedModal) {
      dispatch(sideModalSetSelected(selectedModal));
      dispatch(sideModalSetTitle(title));
      dispatch(sideModalOpenModal());
      setCheckedSettings(title);
    }
  };

  return (
    <>
      <InfoContainer onClick={handleOpenModal}>
        <InfoIconTitle>
          {children}
          <InfoTitleStatus>
            <InfoTitle status={status}>{title}</InfoTitle>
          </InfoTitleStatus>
          {status === 'error' && (
            <Status status={status}>Something need your attention</Status>
          )}
          {status === 'drafted' && <Status status={status}>Drafted</Status>}
        </InfoIconTitle>
        <InfoArrow>
          <Right />
        </InfoArrow>
      </InfoContainer>
    </>
  );
};
