import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/v3/Loading/Loading';
import { BodyS } from 'styles/v3/variables';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { ScheduleMatch } from 'models/Schedule/ScheduleModel';
import { BottomContainer, Container, InformationText } from './styles';
import { ScheduleAccordionProps } from '../../types/scheduleAccordion';
import { formatDate, formatStartTime } from '../../utils';
import { Header } from '../../components';
import { ScheduleAccordion } from '../../components/ScheduleAccordion';
import { BottomCalendar } from '../../components/BottomCalendar';

const AllGames = () => {
  const params = useParams<{ eventId: string }>();
  const { data: response } = useGetEvent(params.eventId);
  const { data: currentSchedule, isLoading } = useGetScheduleByEventId(
    params.eventId
  );

  const [dateSelected, setDateSelected] = React.useState<string | undefined>(
    undefined
  );

  // ENG-240 TODO edited after change ScheduledModel/ScheduledDays
  const transformSchedule = () => {
    if (!currentSchedule || !currentSchedule.days) return [];
    const groupedSchedule: { [courtName: string]: ScheduleAccordionProps } = {};
    const allMatches: ScheduleMatch[] = currentSchedule.days.reduce(
      (acc, day) => {
        if (day?.matches) {
          acc.push(...day.matches);
        }
        return acc;
      },
      [] as ScheduleMatch[]
    );
    allMatches.forEach((match) => {
      if (match && match.ready) {
        const formattedDate = formatDate((match.endTime as unknown) as string);
        if (formattedDate === dateSelected) {
          const { courtName } = match;
          if (!groupedSchedule[courtName]) {
            groupedSchedule[courtName] = {
              courtName,
              divisionName: match.divisionName as string,
              gamesCount: '0',
              match: [] as any,
            };
          }
          groupedSchedule[courtName].match.push({
            divisionName: match.divisionName as string,
            opponent1: match.opponent1 as any,
            opponent2: match.opponent2 as any,
            startTime: formatStartTime(match.startTime as any),
          });
        }
      }
    });
    const transformedSchedule = Object.values(
      groupedSchedule
    ) as ScheduleAccordionProps[];
    transformedSchedule.forEach((group: any) => {
      // eslint-disable-next-line no-param-reassign
      group.gamesCount = `${group.match.length.toString()} ${
        group.match.length > 1 ? 'GAMES' : 'GAME'
      }`;
    });
    return transformedSchedule;
  };
  
  const generateDayArray = () => {
    if (!currentSchedule || !currentSchedule.days) return [];

    return currentSchedule?.days?.map((dayItem) => ({
      day: formatDate(dayItem?.day.toString() as string),
    }));
  };

  useEffect(() => {
    setDateSelected(generateDayArray()[0]?.day as string);
  }, [currentSchedule, setDateSelected]);

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <Container>
        <Header event={response?.data} />
        <InformationText>
          <BodyS $color='grey300'>All courts</BodyS>
        </InformationText>
        {transformSchedule &&
          transformSchedule().map((item) => (
            <ScheduleAccordion {...item} key={item.courtName} />
          ))}
      </Container>
      <BottomContainer>
        <BottomCalendar
          listDay={generateDayArray().map((item) => ({
            ...item,
            selected: item.day === dateSelected,
            onSelected: () => setDateSelected(item.day as string),
          }))}
        />
      </BottomContainer>
    </div>
  );
};

export default AllGames;
