import React, { FunctionComponent, ReactElement } from 'react';
import { BracketType } from 'models/Bracket/BracketModel';
import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

const Title: FunctionComponent<{title: string; teamCount: number}> = ({title, teamCount}) => {
  return (
    <p>{title} <span>{teamCount} Teams</span></p>
  )
}

export const TitleMap: Record<BracketType, ReactElement> = {
  SINGLE_ELIMINATION_2: <Title title='Single Elimination' teamCount={2} />,
  SINGLE_ELIMINATION_3: <Title title='Single Elimination' teamCount={3} />,
  SINGLE_ELIMINATION_4: <Title title='Single Elimination' teamCount={4} />,
  SINGLE_ELIMINATION_5: <Title title='Single Elimination' teamCount={5} />,
  SINGLE_ELIMINATION_6: <Title title='Single Elimination' teamCount={6} />,
  SINGLE_ELIMINATION_7: <Title title='Single Elimination' teamCount={7} />,
  SINGLE_ELIMINATION_8: <Title title='Single Elimination' teamCount={8} />,
  COMPASS_DRAW_4: <Title title='Compass Draw' teamCount={4} />,
  COMPASS_DRAW_8: <Title title='Compass Draw' teamCount={8} />,
};

export const BracketContainer = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: ${COLORS.grey900};

  :not(:first-child) {
    margin-top: 40px;
  }
`;

export const BracketHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;

  text-transform: uppercase;

  h5 {
    color: ${COLORS.grey100};
    font-family: 'Boxed Regular Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;

    span {
      cursor: pointer;
    }
  }

  p {
    display: inline-block;
    color: var(--grey-300, #bdbeb8);
    /* Body/S */
    font-family: 'Boxed Regular';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;

    margin: 8px 0 0;
    padding: 0;

    span {
      margin-left: 16px;
    }
  }
`;

export const EditBracketName = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(16)};
`;

export const BracketFooter = styled.footer`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`

export const BracketDelete = styled.div<{
  $color?: string;
}>`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;

  color: ${({ $color }) => $color || COLORS.grey200};
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }
`;
