import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { BodyMBold, COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  @media print {
    display: none;
  }
  cursor: pointer;
  z-index: 999;
  position: absolute;
  right: 0;
  background-color: ${COLORS.brandPrimary};
  font-weight: 800;
  padding: ${toRem(10)} ${toRem(10)} ${toRem(10)} ${toRem(15)};
  color: ${COLORS.grey500};
  width: ${toRem(50)};
  display: flex;
  width: ${toRem(50)};
  height: ${toRem(50)};
  overflow: hidden;
  align-items: center;

  border-bottom-left-radius: ${toRem(20)};

  &:hover {
    width: auto;
    div {
      display: block;
    }
  }
  div {
    display: none;
  }
`;

export const Title = styled(BodyMBold)`
  margin-left: ${toRem(20)};
  color: ${COLORS.grey950};
`;

export const Icon = styled.img`
  width: ${toRem(20)};
`;
