import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';

export const Wrapper = styled.div`
  border-radius: ${toRem(24)};
  border: 1px solid ${COLORS.grey800};
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  width: ${toRem(90)};
  height: ${toRem(90)};
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  background-color: ${COLORS.white};
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: ${toRem(8)};
  }
`;
export const Label = styled.span`
  border-radius: ${toRem(33)};
  padding: ${toRem(4)} 1rem;
  max-width: fit-content;
  border: 1px solid ${COLORS.grey400};
  margin-bottom: ${toRem(8)};
`;
export const Details = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.grey400};

  .i-icon {
    display: flex;
    margin-right: ${toRem(8)};
  }
  p {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
