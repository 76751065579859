import React, { useMemo, useState } from 'react';

import { Table } from 'components/v3/Table/Table';
import { useSubscribedReferees } from 'hooks/v3/event/useSubscribedReferees/useSubscribedReferees';
import { useParams } from 'react-router-dom';
import { useGetUsersStats } from 'hooks/v3/users/useGetUserStats/useGetUserStats';
import { columns } from './columns';
import { Filters } from './components';

export const ConfirmedTable = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useSubscribedReferees(eventId);

  const { data: usersStats } = useGetUsersStats(
    data?.referees?.map((m) => m.id) || [],
    Boolean(data?.referees)
  );

  const newReferees = useMemo(() => {
    return data?.referees?.map((referee) => ({
      ...referee,
      xp:
        usersStats?.find((u: { userId: string }) => u.userId === referee.id)
          ?.xp || 0,
    }));
  }, [data?.referees, usersStats]);

  return (
    <>
      <Filters handleChange={(text) => setSearchTerm(text)} />
      <Table columns={columns} dataSource={newReferees} />
    </>
  );
};
