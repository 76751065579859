import React, { useMemo } from 'react';

import { BodyLBold, BodyM } from 'styles/v3/variables';
import { CloseSmall, Left as BackButton } from '@icon-park/react';
import { useGetRosterByEventIdDetailed } from 'hooks/v3/rosters/useGetRosterByEventIdDetailed/useGetRosterByEventIdDetailed';
import { NewRoaster } from 'services/v3/Rosters/RosterService';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import FilledButton from 'components/v3/Buttons/FilledButton';
import * as S from './styles';
import { RostersContainerProps } from './types';
import { AgeDivisionItem } from './components/AgeDivisionItem/AgeDivisionItem';
import { DivisionInfo } from './components/DivisionInfo/DivisionInfo';
import { RosterItem } from './components/RosterItem/RosterItem';

export const RostersContainer = ({
  handleCloseModal,
  currentEvent,
  handleBack,
}: RostersContainerProps) => {
  const { data: eventRosters } = useGetRosterByEventIdDetailed(
    currentEvent?.id
  );

  const [selectedDivision, setSelectedDivision] = React.useState<
    { division: NewAgeDivision, rosters: NewRoaster[] } | undefined
  >(undefined);

  const ageDivisions = useMemo(() => {
    if (!currentEvent?.ageDivisions) return [];
    return currentEvent.ageDivisions;
  }, [currentEvent]);

  const handleGoBack = () => {
    if (selectedDivision === undefined) {
      handleBack();
    } else {
      setSelectedDivision(undefined);
    }
  };

  const handleSelectDivision = (division: NewAgeDivision) => {
    setSelectedDivision({
      division,
      rosters: eventRosters?.filter(
        (roster) => roster.ageDivision?.id === division.id
      ),
    });
  };

  const handlePrintAllRosters = () => {
    window.open(`/v3/print/event/${currentEvent?.id}/all-rosters`);
  };

  return (
    <S.Container>
      <S.HeaderWrapper>
        <BackButton onClick={handleGoBack} className='backButton' size='24px' />
        <S.HeaderTitleContainer>
          <BodyLBold>
            {selectedDivision !== undefined
              ? selectedDivision?.division.name ?? 'Unnamed Division'
              : 'Print Roster'}
          </BodyLBold>

          {selectedDivision !== undefined && (
            <DivisionInfo ageDivision={selectedDivision.division} />
          )}
        </S.HeaderTitleContainer>
        <CloseSmall
          onClick={handleCloseModal}
          className='closeButton'
          size='24px'
        />
      </S.HeaderWrapper>
      <S.HR />
      <S.BodyWrapper>
        {selectedDivision !== undefined ? (
          <>
            <FilledButton
              onClick={handlePrintAllRosters}
              isBold={false}
              className='btn-print-all'
              isUpper
              color='dark-white'
            >
              Print all Rosters
            </FilledButton>
            <BodyM className='title'>
              If you want to print an specific roster from this division, select
              the roster bellow
            </BodyM>

            {selectedDivision.rosters.map((roster) => (
              <RosterItem
                key={roster.id}
                event={currentEvent}
                roster={roster}
              />
            ))}
          </>
        ) : (
          <>
            <BodyM className='title'>
              Select from which age division you hant to print
            </BodyM>

            {ageDivisions.map((item) => (
              <AgeDivisionItem
                key={item.id}
                ageDivision={item}
                event={currentEvent}
                handleSelectDivision={() => handleSelectDivision(item)}
              />
            ))}
          </>
        )}
      </S.BodyWrapper>
    </S.Container>
  );
};
