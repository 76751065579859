import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ClubService from 'services/v3/Clubs/ClubService';

export function useGetMyClubs() {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-my-clubs', accountId],
    async () => await ClubService.getMyClubs()
  );

  return {
    ...data,
    data: data?.data || [],
    isLoading,
    error,
  };
}
