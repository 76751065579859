import * as React from 'react';
import { Star } from '@icon-park/react';
import { Popover } from 'antd';

import { ClubView } from 'admin/models/club/Club';
import ConvertUtil from 'util/ConvertUtil';
import { useProfile } from 'hooks/v3/profile/useProfile';
import UserServices from 'services/v3/User/UserService';

import { BodyL, BodyS, BodyXS } from 'styles/v3/variables';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import { RequestStatus } from 'models/Request/RequestModel';
import { notification } from 'components/v3/Notification/notification';

import { ApplicationState } from 'redux/store';
import { useSelector } from 'react-redux';
import * as S from './styles';

interface ClubCardProps {
  club: ClubView;
  isMainClub?: boolean;
  onClick?: () => void;
}

const ClubCard: React.FC<ClubCardProps> = ({ club, onClick, isMainClub }) => {
  const [iconStyle, setIconStyle] = React.useState<'filled' | 'outline'>(
    'outline'
  );

  const { isBaseUser, updateCurrentAccount } = useProfile();
  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const isPending =
    club.clubVerified && club.clubVerified === RequestStatus.PENDING;

  const popoverContent = () =>
    club.clubVerified && (
      <BodyS $isUpper>{DocumentStatusLabel[club.clubVerified]}</BodyS>
    );

  const renderImage = () => (
    <S.ImageWrapper>
      <img src={ConvertUtil.getMinioUrl(club.logo)} alt={`${club.name} logo`} />
    </S.ImageWrapper>
  );

  const handleHoverMain = () => {
    setIconStyle('filled');
  };

  const handleLeaveMain = () => {
    setIconStyle('outline');
  };

  const handleClickMain = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    const payload: any = {
      mainClub: club.id,
    };

    await UserServices.updateUser(`${currentUser.id}`, payload, isBaseUser, '')
      .then(async (res) => {
        updateCurrentAccount();
        notification.success({
          message: 'Main club updated',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error updating your main club',
        });
      });
  };

  React.useEffect(() => {
    if (isMainClub) {
      setIconStyle('filled');
    }
  }, [isMainClub]);

  return (
    <S.Wrapper $highlight={isMainClub} onClick={onClick}>
      <S.CardHeader>
        {isMainClub && (
          <BodyXS $isUpper $color='brandPrimary'>
            Main Club
          </BodyXS>
        )}
        <Star
          onMouseEnter={isMainClub ? () => {} : handleHoverMain}
          onMouseLeave={isMainClub ? () => {} : handleLeaveMain}
          onClick={isMainClub ? () => {} : handleClickMain}
          size={20}
          theme={iconStyle}
        />
      </S.CardHeader>
      {isPending ? (
        <Popover content={popoverContent()}>{renderImage()}</Popover>
      ) : (
        renderImage()
      )}
      <BodyL $color={isMainClub ? 'brandPrimary' : 'grey500'}>
        {club.name}
      </BodyL>
    </S.Wrapper>
  );
};

export default ClubCard;
