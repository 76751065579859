import { useQuery } from '@tanstack/react-query';

import MatchService from 'services/v3/Match/MatchService';

export const useGetPaginatedMatches = (
  eventId: string,
  { filters, payload }: any
) => {
  const response = useQuery(['games', filters, payload], () =>
    MatchService.getAllMatchesPaginated(eventId, filters, payload)
  );

  return {
    data: response.data?.data || null,
  };
};
