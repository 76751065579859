import { AxiosRequestConfig } from "axios";

import { MatchDetailsModel, MatchV3Model, MatchEventModel } from "models/Match/MatchModel";

import { ConfigApi } from "services/config.api";
import APIServicesV3 from "services/util/ApiServicesV3";
import ServiceResponseV3 from "services/util/ServiceResponseV3";

import { IFutsalEventPayload, IFutsalEventDeletionPayload, IGameReportPayload, IGameUpdatePayload } from "./payload-interfaces";
import { IMatchDetails, IMatchEventList } from "./interfaces";

class MatchService {
  public static async getDetailedEventMatches(
    eventId?: string
  ): Promise<ServiceResponseV3<MatchDetailsModel[]>> {
    if (!eventId) return ServiceResponseV3.success([], '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/matches/event/${eventId}/detailed`,
      method: 'GET',
    };

    return APIServicesV3.request<MatchDetailsModel[]>(axiosConfig);
  }

  // TODO adjust the return type
  public static async getMatchesByBracket(
    bracketId?: string
  ): Promise<ServiceResponseV3<any[]>> {
    if (!bracketId) return ServiceResponseV3.success([], '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/matches-v3/find-all-by-bracket/${bracketId}`,
      method: 'GET',
    };

    return APIServicesV3.request<any[]>(axiosConfig);
  }

  public static async updateMatches(
    matches: MatchV3Model[]
  ): Promise<ServiceResponseV3<any[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/matches-v3/update`,
      method: 'PATCH',
      data: matches,
    };

    return APIServicesV3.request<any[]>(axiosConfig);
  }

  public static async getMatchesEvent(
    eventId: string
  ): Promise<ServiceResponseV3<MatchEventModel[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/matches-v3/find-all-by-event/${eventId}`,
      method: 'GET',
    };

    return APIServicesV3.request<MatchEventModel[]>(axiosConfig);
  }

  public static async getAllMatchesPaginated(
    eventId: string,
    filters: any,
    data = {},
  ): Promise<ServiceResponseV3<IPaginationResponse<IMatchEventList[]>>> { 
    const { page, pageSize, name } = filters;

    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('size', pageSize.toString());

    if (name) params.append('name', name);

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/matches-v3/find-by-event-filtering/${eventId}`,
      method: 'POST',
      params,
      data,
    };

    return APIServicesV3.request<IPaginationResponse<IMatchEventList[]>>(axiosConfig);
  }

  public static async getMatchDetailById(matchId: string): Promise<ServiceResponseV3<IMatchDetails>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/matches-v3/${matchId}/details`,
      method: 'GET',
    };

    return APIServicesV3.request<IMatchDetails>(axiosConfig);
  }

  public static createFutsalEvent(payload: IFutsalEventPayload) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches-v3/futsal-events`,
      method: 'POST',
      data: payload,
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static deleteFutsalEvent({ futsalId }: IFutsalEventDeletionPayload) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches-v3/futsal-events/${futsalId}`,
      method: 'DELETE',
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static reportGame({ matchId, report }: IGameReportPayload) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/matches-v3/${matchId}/report`,
      method: 'PUT',
      data: {
        report,
      }
    };

    return APIServicesV3.request<any>(axiosConfig);
  }

  public static updateGame({ matchId, status }: IGameUpdatePayload) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/matches-v3/editor-update',
      method: 'PATCH',
      data: {
        matchId,
        status,
      }
    };

    return APIServicesV3.request<any>(axiosConfig);
  }
}

export default MatchService;
