import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${toRem(450)};
`;

export const StyledForm = styled.form`
  background-color: ${COLORS.grey950};
  border-radius: ${toRem(24)};
  flex-direction: column;
  display: flex;
  padding: ${toRem(24)};
  row-gap: 30px;
`;
