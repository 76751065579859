import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { FutsalSeasonResetConfirm } from 'components/v3/FutsalSeasonResetConfirm/FutsalSeasonResetConfirm';

import Routes from './routes/Routes';
import configureStore from './configStore';

import ResetStyle from './styles/v3/reset';
import ThemeProvider from './styles/v3/ThemeProvider';
import GlobalAntdStyles from './styles/v3/antd';

import './App.css';
import './styles/style.scss';
import './styles/v3/fonts.css';

const initialState = window.INITIAL_REDUX_STATE;

export const store = configureStore(initialState);

const persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC = () => (
  <Provider store={store}>
    <ResetStyle />
    <QueryClientProvider client={queryClient}>
      <GlobalAntdStyles />
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Routes />
          <FutsalSeasonResetConfirm />
        </ThemeProvider>
      </PersistGate>
    </QueryClientProvider>
  </Provider>
);

export default App;
