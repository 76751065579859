import React, { memo, FC, useState, useCallback, Fragment } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';

import {
  IFutsalEventView,
  IMatchResult,
  IRosterUser,
} from 'services/v3/Match/interfaces';
import { SoccerPeriod } from 'services/v3/Match/enums';
import { IFutsalEventDeletionPayload } from 'services/v3/Match/payload-interfaces';

import { IGroupedFutsalEvents } from 'pages/V3/GamesControl/interfaces';
import { TeamVenueType } from 'pages/V3/GamesControl/enums';
import { ActionGameCard } from 'components/v3/Cards/ActionGameCard/ActionGameCard';

import { RemoveConfirmModal } from './RemoveConfirmModal/RemoveConfirmModal';
import { MATCH_TIMELINE_SOCCERS } from './config';

import 'react-vertical-timeline-component/style.min.css';

import * as S from './styles';

interface ITimelineProps {
  events: IGroupedFutsalEvents[];
  result: Maybe<IMatchResult>;
  homePlayers: Maybe<IRosterUser[]>;
  awayPlayers: Maybe<IRosterUser[]>;
  homeCoaches: Maybe<IRosterUser[]>;
  awayCoaches: Maybe<IRosterUser[]>;
  penaltyShootout: Maybe<IFutsalEventView[]>;
  onDeleteFutsalEvent: (payload: IFutsalEventDeletionPayload) => void;
}

export const Timeline: FC<ITimelineProps> = memo(
  ({
    events,
    result,
    homePlayers,
    awayPlayers,
    homeCoaches,
    awayCoaches,
    penaltyShootout,
    onDeleteFutsalEvent,
  }) => {
    const [openedDeleteConfirmModal, setOpenedDeleteConfirmModal] =
      useState(false);
    const [futsalTempId, setFutsalTempId] = useState<Maybe<string>>(null);

    const getPlayerInfo = useCallback(
      (userId: string, players: Maybe<IRosterUser[]>) => {
        return players?.find((player) => player.id === userId) || null;
      },
      []
    );

    const getCoachInfo = useCallback(
      (userId: string, coaches: Maybe<IRosterUser[]>) => {
        return coaches?.find((coach) => coach.id === userId) || null;
      },
      []
    );

    const getRosterUser = useCallback(
      (userId: string, team: Maybe<TeamVenueType>) => {
        if (team === TeamVenueType.Home) {
          return (
            getPlayerInfo(userId, homePlayers) ||
            getCoachInfo(userId, homeCoaches) ||
            null
          );
        }

        if (team === TeamVenueType.Away) {
          return (
            getPlayerInfo(userId, awayPlayers) ||
            getCoachInfo(userId, awayCoaches) ||
            null
          );
        }

        return null;
      },
      [
        homePlayers,
        homeCoaches,
        awayPlayers,
        awayCoaches,
        getPlayerInfo,
        getCoachInfo,
      ]
    );

    const getSoccerTitle = useCallback((period: SoccerPeriod) => {
      return MATCH_TIMELINE_SOCCERS.find((soccer) => soccer.type === period);
    }, []);

    const handleOpenDeleteConfirmModal = useCallback((futsalId: string) => {
      setOpenedDeleteConfirmModal(true);
      setFutsalTempId(futsalId);
    }, []);

    const handleCloseDeleteConfirmModal = useCallback(() => {
      setOpenedDeleteConfirmModal(false);
    }, []);

    const handleConfirmDeletion = useCallback(() => {
      if (futsalTempId) {
        onDeleteFutsalEvent({ futsalId: futsalTempId });
      }

      setOpenedDeleteConfirmModal(false);
    }, [futsalTempId]);

    return (
      <S.Timeline>
        {penaltyShootout?.length && (
          <>
            <S.TimelineTitle>Penalty Shootout</S.TimelineTitle>
            <S.PenaltyShootoutStyled
              penaltyShootout={penaltyShootout}
              homePlayers={homePlayers}
              awayPlayers={awayPlayers}
              awayTeamPenaltiShootout={result?.awayTeamPenaltiShootout || 0}
              homeTeamPenaltiShootout={result?.homeTeamPenaltiShootout || 0}
            />
          </>
        )}
        {events.map((groupedEvent) => (
          <Fragment key={groupedEvent.type}>
            {groupedEvent.type !== SoccerPeriod.PENALTY_SHOOTOUT && (
              <S.TimelineTitle>
                {getSoccerTitle(groupedEvent.type)?.value || ''}
              </S.TimelineTitle>
            )}
            <VerticalTimeline animate lineColor='#4B4D46' layout='2-columns'>
              {groupedEvent.events.map((event) => (
                <VerticalTimelineElement
                  key={event.id}
                  className={
                    event.team === TeamVenueType.Away
                      ? 'vertical-timeline-element--right'
                      : 'vertical-timeline-element--left'
                  }
                >
                  <ActionGameCard
                    isRight={event.team === TeamVenueType.Away}
                    rosterUser={getRosterUser(event.userId, event.team ?? null)}
                    event={event}
                    onOpenConfirmModal={handleOpenDeleteConfirmModal}
                  />
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </Fragment>
        ))}
        <RemoveConfirmModal
          visible={openedDeleteConfirmModal}
          onClose={handleCloseDeleteConfirmModal}
          onConfirm={handleConfirmDeletion}
        />
      </S.Timeline>
    );
  }
);

Timeline.displayName = 'Timeline';
