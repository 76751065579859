import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { Down, Up } from '@icon-park/react';
import { BodyM, BodyMBold, TitleH4 } from 'styles/v3/variables';
import { Pools } from 'admin/models/AgeDivision';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { useHistory, useParams } from 'react-router-dom';
import { useTeamPlacement } from '../../context/TeamPlacementContext';
import { TeamCard } from '../TeamCard';
import { EmptyTeamCard } from '../EmptyTeamCard';
import { findRosterById } from '../../utils';
import * as S from './styles';

export const PoolsList = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();

  const { allRosters, setOpenedPools, selectedDivision } = useTeamPlacement();

  const countTeamsCompleted = (pool: Pools) => {
    return pool.teams.filter((item) => Boolean(item.rosterId)).length;
  };

  const handleRedirect = () => {
    history.push(`/v3/event/${eventId}/dashboard/groups`);
  };

  return (
    <AnimatePresence>
      <S.Container key={selectedDivision?.id} id='pool-container'>
        <motion.div
          style={{ width: '100%' }}
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          transition={{
            duration: 0.4,
            ease: 'easeInOut',
          }}
        >
          <S.Wrapper>
            <S.PoolWrapper
              expandIconPosition='right'
              expandIcon={({ isActive }) =>
                isActive ? <Up size='18px' /> : <Down size='18px' />
              }
              onChange={(e) => setOpenedPools(e as string[])}
            >
              {selectedDivision?.pools.length &&
              selectedDivision?.pools?.length > 0 ? (
                selectedDivision?.pools?.map((pool) => (
                  <S.PoolPanel
                    key={pool.id}
                    header={
                      <S.Header>
                        <S.TextWrapper>
                          <S.PoolNameWrapper>
                            <BodyMBold $color='white'>{pool.name} </BodyMBold>
                            <BodyMBold $color='white'>{pool.order}</BodyMBold>
                          </S.PoolNameWrapper>
                          <BodyM $color='grey300'>
                            {countTeamsCompleted(pool)} / {pool.totalTeams}
                          </BodyM>
                        </S.TextWrapper>
                      </S.Header>
                    }
                  >
                    <S.TeamsWrapper>
                      {pool.teams.map((team) => {
                        const roster = team.rosterId
                          ? findRosterById(team.rosterId, allRosters)
                          : null;

                        return team.rosterId ? (
                          <motion.div
                            key={team.rosterId}
                            initial={{ opacity: 0, scale: 0.6 }}
                            animate={{ y: 0, opacity: 1, scale: 1 }}
                            transition={{
                              duration: 0.3,
                              ease: 'easeOut',
                            }}
                          >
                            <TeamCard
                              withClearAction
                              teamId={team.id}
                              name={roster?.club?.name}
                              poolOrder={pool.order}
                              teamOrder={team.order}
                              rosterId={team.rosterId}
                              logoSrc={roster?.club.logo}
                            />
                          </motion.div>
                        ) : (
                          <motion.div
                            key={team.id}
                            initial={{ opacity: 0, scale: 0.6 }}
                            animate={{ y: 0, opacity: 1, scale: 1 }}
                            transition={{
                              duration: 0.3,
                              ease: 'easeOut',
                            }}
                          >
                            <EmptyTeamCard
                              name={team.name}
                              order={team.order}
                            />
                          </motion.div>
                        );
                      })}
                    </S.TeamsWrapper>
                  </S.PoolPanel>
                ))
              ) : (
                <S.FallbackMessageWrapper>
                  <TitleH4 $color='grey500'>
                    It looks like you dont have a pool yet, how about creating
                    one?
                  </TitleH4>
                  <FilledButton
                    isUpper
                    color='primary'
                    isBold={false}
                    onClick={handleRedirect}
                  >
                    Go to groups
                  </FilledButton>
                </S.FallbackMessageWrapper>
              )}
            </S.PoolWrapper>
          </S.Wrapper>
        </motion.div>
      </S.Container>
    </AnimatePresence>
  );
};
