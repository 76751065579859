import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { IMatchResult } from 'services/v3/Match/interfaces';

import { ControlButtons } from '../components/ControlButtons/ControlButtons';
import { GameScore } from '../components/GameScore/GameScore';
import { ITableMatches } from '../interfaces';

interface IDataType {
  venue: any;
  key: string;
  homeTeam: string;
  awayTeam: string;
  date: string;
}

export const useFormTableColums = ({ eventId }: { eventId: string }) => {
  const columns: ColumnsType<IDataType> = [
    {
      title: '#',
      dataIndex: 'key',
    },
    {
      title: 'Home team',
      dataIndex: 'homeTeam',
      sorter: (a, b) => a.homeTeam.localeCompare(b.homeTeam),
      sortDirections: ['descend'],
    },
    {
      title: 'Result',
      dataIndex: 'result',
      render: (result: Maybe<IMatchResult>) => <GameScore result={result} />,
    },
    {
      title: 'Away team',
      dataIndex: 'awayTeam',
      sorter: (a, b) => a.awayTeam.localeCompare(b.awayTeam),
      sortDirections: ['descend'],
    },
    {
      title: 'Date (MM-DD-YYYY)',
      dataIndex: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortDirections: ['descend'],
    },
    {
      title: 'Time',
      dataIndex: 'time',
    },
    {
      title: 'Venue Name',
      dataIndex: 'venueName',
      sorter: (a, b) => a.venue.localeCompare(b.venue),
      sortDirections: ['descend'],
    },
    {
      title: 'Sub Venue',
      dataIndex: 'subVenue',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: '',
      render: (match: ITableMatches) => (
        <ControlButtons
          matchId={match.matchId}
          eventId={eventId}
          report={match.report}
          status={match.status}
        />
      ),
    },
  ];

  return columns;
};
