import React, { FC, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Star, CheckOne } from '@icon-park/react';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import MembershipCard from 'components/v3/Cards/MembershipCard/MembershipCard';

import { BodyM, BodyS, COLORS, TitleH1 } from 'styles/v3/variables';

import { FREE_DETAILS, UPGRADE_DETAILS } from './configs';

import * as S from './styles';

const ChoosePlan: FC = () => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <S.UpgradeMembershipPlansWrapper>
      <S.BackButtonWrapper>
        <TextButtonWithIcon
          color='primary'
          align='flex-end'
          reverse
          icon='back'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
      </S.BackButtonWrapper>
      <BodyS>
        COMPLETE THE REGISTRATION TO JOIN THE UNITED FUTSAL COMMUNITY!
      </BodyS>
      <TitleH1 $isUpper>upgrade membership</TitleH1>
      <S.CardsWrapper>
        <MembershipCard
          logo='logo-vault-grey'
          orientation='column'
          size='small'
          theme='secondary'
          title='FREE'
          description='Community Player Membership'
        >
          <BodyM>What You’ll Get</BodyM>
          {FREE_DETAILS.map((detail) => (
            <div className='details-line' key={detail.text}>
              <CheckOne size={16} />
              <BodyM className={detail.highlight ? 'highlight' : ''}>
                {detail.text}
              </BodyM>
            </div>
          ))}
        </MembershipCard>
        <MembershipCard
          logo='logo-vault-green'
          orientation='column'
          size='small'
          theme='highlight'
          title='Season'
          hasSeason
          price='$29'
          link='/v3/profile/upgrade-membership/release-terms'
          description='Official Player (USA) Membership'
        >
          <BodyM>What You’ll Get</BodyM>
          {UPGRADE_DETAILS.map((detail) => (
            <div className='details-line' key={detail.text}>
              {detail.highlight ? (
                <Star size={16} color={COLORS.white} theme='filled' />
              ) : (
                <CheckOne size={16} />
              )}
              <BodyM className={detail.highlight ? 'highlight' : ''}>
                {detail.text}
              </BodyM>
            </div>
          ))}
        </MembershipCard>
      </S.CardsWrapper>
    </S.UpgradeMembershipPlansWrapper>
  );
};

export default withRouter(ChoosePlan);
