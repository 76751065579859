import React, { FC, useCallback, useEffect, useState, MouseEvent, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { SingleValue } from 'react-select';
import { useParams, withRouter } from 'react-router-dom';
import { Divider } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import type { Value as DayPickerValue } from 'react-multi-date-picker';
import { notification } from 'components/v3/Notification/notification';

import { sideModalCloseModal } from 'redux/sideModal/actions';

import FilledButton from 'components/v3/Buttons/FilledButton';
import InputText from 'components/v3/Forms/InputText/InputText';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { DayPickerField } from 'components/v3/DayPickerField/DayPickerField';

import ConvertUtil from 'util/ConvertUtil';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { BodyM, BodyLBold } from 'styles/v3/variables';

import { EventInfoFormData, IDailyEventsTime } from './types';
import { useGetEventInfoConfig } from './useGetEventInfoConfig';
import { useGetFormConfig } from './useGetFormConfig';
import { getDefaultDayPickerValues, getValidDaysPayload } from './utils';
import {
  optionsArray,
  DAY_FORMAT,
  TIME_FORMAT,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  MAX_DIFFER_TOURNEMENT_DAYS,
  MAX_DIFFER_OTHER_DAYS
} from './config';

import * as S from './styles';

const EventInfo: FC = () => {
  const params: { eventId: string } = useParams();

  const eventId: string = params?.eventId || '';

  const dispatch = useDispatch();

  const {
    eventData,
    logoImgRef,
    headerImgRef,
    imageUpdatedRef,
    isUploading,
    defaultValues,
    handleImageUpload,
    checkDateValid,
    handleChangeLogo,
    handleChangeHeader,
    unscheduleMatches,
  } = useGetEventInfoConfig();

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const [dayPickerValidationError, setDayPickerValidationError] = useState('');
  const [configureTimesShown, setConfiguredTimesShown] = useState(false);
  const [selectedDays, setSelectedDays] = useState<DayPickerValue[]>(
    getDefaultDayPickerValues(eventData.dailyEventTimes)
  );
  const [managedSelectedDays, setManagedSelectedDays] = useState<IDailyEventsTime[]>([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EventInfoFormData>({
    defaultValues,
  });

  const { rangeDays } = useGetFormConfig(selectedDays);

  const sortedSelectedDays = useMemo(() => {
    return managedSelectedDays.sort((a, b) => {
      return moment(`${a.day}`).diff(new Date()) - moment(`${b.day}`).diff(new Date());
    });
  }, [managedSelectedDays]);

  const maxDaysValidation = useMemo(() => {
    if (eventData?.eventType === 'TOURNAMENT') {
      return MAX_DIFFER_TOURNEMENT_DAYS;
    }

    return MAX_DIFFER_OTHER_DAYS;
  }, [eventData]);

  const handleSubmitForm = async (data: EventInfoFormData) => {
    try {
      let logo = eventData?.logo ?? '';
      let header = eventData?.data?.eventInfo?.header ?? '';

      const dailyEventTimes = getValidDaysPayload(managedSelectedDays);

      const isDateInvalid = checkDateValid(rangeDays.startDate, rangeDays.endDate, eventData.eventType ?? '');

      if (isDateInvalid) {
        setDayPickerValidationError(`Wrong date! Max difference is ${maxDaysValidation} days`);

        notification.error({
          message: 'Wrong date!',
          description: `Max difference is ${maxDaysValidation} days`,
        });

        return;
      }

      if (imageUpdatedRef.current.logo) {
        if (logoImgRef.current !== null && logoImgRef.current.files?.length) {
          logo = await handleImageUpload(logoImgRef.current.files);
        }
      }

      if (imageUpdatedRef.current.header) {
        if (
          headerImgRef.current !== null &&
          headerImgRef.current.files?.length
        ) {
          header = await handleImageUpload(headerImgRef.current.files);
        }
      }

      await mutateAsync({
        id: eventId,
        data: {
          name: data.name,
          startDate: rangeDays.startDate,
          endDate: rangeDays.endDate,
          description: data.description,
          logo,
          ...(dailyEventTimes && { dailyEventTimes }),
          data: {
            eventInfo: {
              poolName: data.poolName,
              teamIdentifier: data.teamIdentifier,
              header,
              emailContact: data.emailContact,
              phoneContact: data.phoneContact,
            },
          },
        }
      });

      if (dailyEventTimes.length) {
        await unscheduleMatches(dailyEventTimes);
      }

      dispatch(sideModalCloseModal());
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const handleShowConfigureTimes = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setConfiguredTimesShown((prevState) => !prevState);
  }, []);

  const handleChangeDayPicker = useCallback((values: DayPickerValue[]) => {
    setSelectedDays(values);

    const modifiedSelectedDays = values.map((value) => {
      const changeableDay = moment(new Date(value as Date)).format(DAY_FORMAT);
      const currentDay = managedSelectedDays.find(day => day.day === changeableDay);

      return {
        day: changeableDay,
        startTime: currentDay?.startTime ?? moment(`${changeableDay} ${DEFAULT_START_TIME}`),
        endTime: currentDay?.endTime ?? moment(`${changeableDay} ${DEFAULT_END_TIME}`),
      }
    });

    if (!modifiedSelectedDays.length) {
      setConfiguredTimesShown(false);
    }

    setManagedSelectedDays(modifiedSelectedDays);
  }, [managedSelectedDays]);

  const handleChangeDayTime = useCallback((values: any) => {
    const [startTime, endTime] = values || [];

    const changeableDay = startTime?.format(DAY_FORMAT);

    const formStartTime = startTime?.format(TIME_FORMAT);
    const formEndTime = endTime?.format(TIME_FORMAT);

    const modifiedSelectedDays = managedSelectedDays.map((item) => {
      if (item.day === changeableDay) {
        return {
          day: item.day,
          startTime: moment(`${item.day} ${formStartTime}`),
          endTime: moment(`${item.day} ${formEndTime}`),
        }
      }

      return item;
    });

    setManagedSelectedDays(modifiedSelectedDays);
  }, [managedSelectedDays]);

  useEffect(() => {
    const storedDays =  eventData?.dailyEventTimes?.map((time) => ({
      day: time?.day,
      startTime: moment(`${time?.day} ${time?.startTime}`),
      endTime: moment(`${time?.day} ${time?.endTime}`),
    })) ?? [];

    setManagedSelectedDays(storedDays);
  }, [eventData]);

  useEffect(() => {
    const isDateInvalid = checkDateValid(rangeDays.startDate, rangeDays.endDate, eventData.eventType ?? '');

    setDayPickerValidationError(isDateInvalid ? `Wrong date! Max difference is ${maxDaysValidation} days` : '');
  }, [rangeDays, eventData, maxDaysValidation]);

  return (
    <S.EventInfoWrapper>
      <S.EventInfoFormWrapper>
        <S.EventInfoForm onSubmit={handleSubmit(handleSubmitForm)} noValidate>
          <BodyLBold $color='white'>General info</BodyLBold>
          <fieldset>
            <Controller
              control={control}
              name='name'
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <InputText
                  id='name'
                  label='Event Name'
                  value={value}
                  error={!!errors?.name}
                  errorMessage={errors.name?.message ?? ''}
                  onChange={onChange}
                  placeholder='Event Name'
                  defaultValue={eventData?.name ?? ''}
                  key={`name_${String(eventData?.name)}`}
                  className='general-event-input'
                />
              )}
            />
          </fieldset>
          <DayPickerField
            errorMessage={dayPickerValidationError}
            defaultSelectedDays={getDefaultDayPickerValues(eventData.dailyEventTimes)}
            onChange={handleChangeDayPicker}
          />
          {!!sortedSelectedDays.length && (
            <S.ConfigureTimeButton
              type="button"
              onClick={handleShowConfigureTimes}
            >
              Configure time per day
            </S.ConfigureTimeButton>
          )}
          {configureTimesShown && (
            <div>
              {sortedSelectedDays.map((item) => (
                <div
                  key={item.day}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 10,
                    marginBottom: 10,
                  }}
                >
                  <BodyM>Day: {item.day}</BodyM>
                  <S.StyledTimePicker
                    use12Hours
                    value={item.startTime && item.endTime && [item.startTime, item.endTime]}
                    onChange={handleChangeDayTime}
                    format='h:mm a'
                    minuteStep={60}
                    showNow={false}
                  />
                </div>
              ))}
              <Divider style={{ margin: '22px 0 0' }} />
            </div>
          )}
          <fieldset>
            <Controller
              control={control}
              name='description'
              render={({ value, onChange }) => (
                <InputText
                  id='description'
                  value={value}
                  label='Description'
                  placeholder='Add a short description for your event'
                  key={`description_${String(eventData?.description)}`}
                  defaultValue={eventData?.description}
                  onChange={onChange}
                />
              )}
            />
          </fieldset>
          <fieldset>
            <Controller
              name='poolName'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.poolName ?? ''}
              render={({ onChange }) => (
                <Select
                  placeholder='Pool Name Format'
                  name='poolName'
                  error={!!errors?.poolName}
                  errorMessage={errors.poolName?.message ?? ''}
                  key={`poolName_${String(
                    eventData?.data?.eventInfo?.poolName
                  )}`}
                  defaultValue={
                    eventData?.data?.eventInfo?.poolName
                      ? {
                          value: eventData?.data.eventInfo.poolName,
                          label: eventData?.data?.eventInfo.poolName,
                        }
                      : null
                  }
                  options={optionsArray}
                  onChange={(option) =>
                    onChange((option as SingleValue<OptionsType>)?.value ?? '')
                  }
                />
              )}
            />
          </fieldset>
          <fieldset>
            <Controller
              name='teamIdentifier'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.teamIdentifier ?? ''}
              render={({ onChange }) => (
                <Select
                  placeholder='Team Identifier Format'
                  name='teamIdentifier'
                  key={`teamIdentifier_${String(
                    eventData?.data?.eventInfo?.teamIdentifier
                  )}`}
                  error={!!errors?.teamIdentifier}
                  errorMessage={errors.teamIdentifier?.message ?? ''}
                  defaultValue={
                    eventData?.data?.eventInfo?.teamIdentifier
                      ? {
                          value: eventData?.data?.eventInfo.teamIdentifier,
                          label: eventData?.data?.eventInfo.teamIdentifier,
                        }
                      : null
                  }
                  options={optionsArray}
                  onChange={(option) =>
                    onChange((option as SingleValue<OptionsType>)?.value ?? '')
                  }
                />
              )}
            />
          </fieldset>
          <Divider style={{ margin: 0 }} />
          <BodyLBold $color='white'>Contact Info</BodyLBold>
          <fieldset>
            <Controller
              name='emailContact'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.emailContact ?? ''}
              render={({ value, onChange }) => (
                <InputText
                  id='emailContact'
                  label='Email'
                  value={value}
                  placeholder='Email'
                  error={!!errors?.emailContact}
                  errorMessage='Email is required'
                  key={`name_${String(
                    eventData?.data?.eventInfo?.emailContact
                  )}`}
                  className='general-event-input'
                  onChange={onChange}
                />
              )}
            />
          </fieldset>
          <fieldset>
            <Controller
              name='phoneContact'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.phoneContact ?? ''}
              render={({ value, onChange }) => (
                <InputPhone
                  value={value}
                  id='phoneContact'
                  label='Phone Number'
                  defaultValue={
                    eventData?.data?.eventInfo?.phoneContact ?? ''
                  }
                  placeholder='Phone Number'
                  error={!!errors.phoneContact}
                  errorMessage='Phone number is required'
                  key={`name_${String(
                    eventData?.data?.eventInfo?.phoneContact
                  )}`}
                  onChange={onChange}
                  className='general-event-input'
                />
              )}
            />
          </fieldset>
          <Divider style={{ margin: 0 }} />
          <BodyLBold $color='white'>Events Visual identify</BodyLBold>
          <BodyM className='field-description' $color='white'>
            Upload a Logo for your event. The logo must be{' '}
            <span>512px by 512px</span>
          </BodyM>
          <fieldset>
            <S.EventInfoSummaryHeader>
              <InputFile
                format='circle'
                key={`logoImg_${String(eventData?.logo)}`}
                ref={logoImgRef}
                previewUrl={
                  eventData?.logo !== ''
                    ? ConvertUtil.getMinioUrl(eventData?.logo)
                    : undefined
                }
                id='logoImg'
                text='Add Logo'
                onChange={handleChangeLogo}
              />
            </S.EventInfoSummaryHeader>
          </fieldset>
          <BodyM className='field-description' $color='white'>
            Upload a Header for your event. The header will show up on your
            event page. It must be <span>1440px by 250px</span>
          </BodyM>
          <fieldset>
            <S.EventInfoSummaryHeader>
              <InputFile
                format='square'
                ref={headerImgRef}
                key={`header_${String(eventData?.data?.eventInfo?.header)}`}
                previewUrl={
                  eventData?.data?.eventInfo?.header
                    ? ConvertUtil.getMinioUrl(eventData.data.eventInfo.header)
                    : undefined
                }
                id='headerImg'
                text='Add Header'
                onChange={handleChangeHeader}
              />
            </S.EventInfoSummaryHeader>
          </fieldset>
          <FilledButton
            isUpper
            type='submit'
            color='primary'
            isLoading={isLoading || isUploading}
          >
            Save {isUploading && '...'}
          </FilledButton>
        </S.EventInfoForm>
      </S.EventInfoFormWrapper>
    </S.EventInfoWrapper>
  );
};

export default withRouter(EventInfo);
