import * as React from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import { message as MessageSnackbar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Upload } from '@icon-park/react';

import ExamplePhoto from 'assets/imgs/passport.jpg';

import InputFile from 'components/v3/Forms/InputFile/InputFile';
import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { StatusChip } from 'components/v3/Cards/DocumentCard/styles';

import ConvertUtil from 'util/ConvertUtil';

import UploadService from 'services/upload/UploadService';
import DownloadService from 'services/download/DownloadService';
import UserServices from 'services/v3/User/UserService';

import { ApplicationState } from 'redux/store';
import { baseUserUpdateData } from 'redux/v3/baseUsers/actions';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

import { BodyM, BodyMBold, BodyS, BodySBold } from 'styles/v3/variables';
import { RequestStatus } from 'models/Request/RequestModel';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import * as S from './styles';
import { FooterProps, ProfilePhotoModalProps } from './ProfilePhotoModal';
import OutlinedButtonWithIcon from '../Buttons/OutlinedButtonWithIcon';
import UploadModal from './UploadModal';

const UploadFileFooter: React.FC = () => (
  <>
    <BodyM $color='grey300'>Tips</BodyM>
    <BodyM className='tip-text' $color='grey300'>
      Your proof of age can be a Passport, Driver&apos;s License or Birth
      certificate.
    </BodyM>
  </>
);

const UploadFileFooterInfo: React.FC = () => (
  <>
    <BodyMBold $color='white'>Why do we need this?</BodyMBold>
    <BodyS $color='grey200' $spanColor='brandPrimary' className='tip-text'>
      We require this document to make sure all players will play in the
      appropriate age group.{' '}
      <span>We value fair and challenging competition</span> and we want to make
      sure there will be no cheating in relation to player&apos;s age.
    </BodyS>
  </>
);

const SendFileFooter: React.FC<FooterProps> = ({
  setStep,
  isLoading,
  sendToApproval,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButton
      isUpper
      onClick={() => setStep(0)}
      color='white-dark'
      disabled={isLoading}
    >
      Change File
    </OutlinedButton>
    <FilledButton
      className='button-approval'
      isUpper
      onClick={sendToApproval}
      disabled={isLoading}
    >
      Send to approval
    </FilledButton>
  </S.ButtonsWrapper>
);

const ResendFileStatusFooter: React.FC<{ setStep: (e: number) => void }> = ({
  setStep,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButtonWithIcon
      isUpper
      color='white-dark'
      customIcon={<Upload size={24} />}
      onClick={() => setStep(0)}
    >
      Reupload File
    </OutlinedButtonWithIcon>
  </S.ButtonsWrapper>
);

const ResendFileStatusFooterInfo: React.FC<{ reason: string }> = ({
  reason,
}) => (
  <>
    <BodyMBold>Why was my Proof of Age denied?</BodyMBold>
    <BodyS className='tip-text'>{reason}</BodyS>
  </>
);

const ProofOfAgeModal: React.FC<ProfilePhotoModalProps> = ({
  status,
  user,
  ...props
}) => {
  const [step, setStep] = React.useState<number>(0);
  const [title, setTitle] = React.useState<string>('');
  const [filePath, setFilePath] = React.useState<string>('');
  const [filePathConverted, setFilePathConverted] = React.useState<string>('');
  const [fileType, setFileType] = React.useState<'PDF' | 'IMAGE' | ''>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fileList, setFileList] = React.useState<FileList | null>(null);

  const dispatch = useDispatch();
  const currentUserState = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const currentUser = user || currentUserState;

  const isBaseUser = React.useMemo(() => {
    if (!currentUser) return false
    return Array.isArray(currentUser?.types)
  }, [currentUser]);

  const upload = async () => {
    setIsLoading(true);
    try {
      await UploadService.upload({
        file: fileList![0],
        prefix: 'user',
        public: false,
      }).then((response) => {
        if (!response.success) {
          MessageSnackbar.error({ content: response.message, duration: 5 });
          return;
        }
        const url = response.data.id;
        setFilePath(url);

        DownloadService.download(url).then((res) => {
          if (res.success) {
            setFilePathConverted(res.data);
            setIsLoading(false);
          }
        });
      });
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        MessageSnackbar.error({ content: e.message, duration: 5 });
      }
    }
  };

  const sendToApproval = async () => {
    const ageVerified = RequestStatus.PENDING;
    try {
      setIsLoading(true);



      if (isBaseUser) {
        const { data: userUpdated } = await UserServices.updateUser(`${currentUser.id}`, {
          ageProof: filePath,
        }, isBaseUser);

        if (userUpdated.message) {
          throw new Error(userUpdated.message);
        } else {
          dispatch(
            baseUserUpdateData({ ageProof: filePath, ageVerified, id: `${currentUser.id}` })
          );
          const { data: updatedProfile } = await UserServices.getProfiles()

          const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === currentUser.id)

          if (updatedBaseUser) {
            dispatch(setCurrentUser(updatedBaseUser));
            const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
            dispatch(usersFetchSuccess(newUsers));
          }
          setIsLoading(false);
          setStep(2);
        }

      } else {
        UserServices.updateUser(`${currentUser.id}`, {
          ageProof: filePath,
        }, isBaseUser).then(async (res) => {
          if (res.success) {
            dispatch(
              baseUserUpdateData({ ageProof: filePath, ageVerified, id: `${currentUser.id}` })
            );
            const { data: updatedProfile } = await UserServices.getProfiles()

            const myNewBaseUser = updatedProfile?.baseUsers?.find(u => u.userIds?.includes(`${currentUser.id}`))

            if (myNewBaseUser) {
              dispatch(setCurrentUser(myNewBaseUser));
              const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
              dispatch(usersFetchSuccess(newUsers));
            }

            setIsLoading(false);
            setStep(2);
          } else {
            setIsLoading(false);
            MessageSnackbar.error(`Error${res.data}`);
          }
        });

      }


    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        MessageSnackbar.error({ content: e.message, duration: 5 });
      }
    }
  };

  const step0 = () => (
    <S.ModalContentUploadPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Example photo</BodyM>
        <S.WrapperImage>
          <img src={ExamplePhoto} alt='Example' />
        </S.WrapperImage>
      </S.ModalContentWrapperPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Choose your file</BodyM>
        <InputFile
          id='proofOfAgeFile'
          text='Upload File'
          icon='UploadLogs'
          format='square'
          onChange={(e) => {
            setFileList(e.target.files);
          }}
        />
      </S.ModalContentWrapperPhoto>
    </S.ModalContentUploadPhoto>
  );

  const step1 = () =>
    fileType !== '' &&
    filePathConverted !== '' && (
      <S.ChoseFileWrapper $type='square'>
        {fileType === 'PDF' ? (
          <S.PdfWrapper>
            <PDFReader
              url={ConvertUtil.getPrivateMinioUrl(filePathConverted)}
              scale={0.5}
              showAllPage
            />
          </S.PdfWrapper>
        ) : (
          <img
            src={`${ConvertUtil.getPrivateMinioUrl(filePathConverted)}`}
            alt='User Document'
          />
        )}
      </S.ChoseFileWrapper>
    );

  const step2 = () => (
    <>
      <S.StatusHeader>
        <BodyM>DOCUMENT STATUS</BodyM>
        <StatusChip $status={status}>
          <BodySBold
            $isUpper
            $color={
              status === DocumentStatusLabel.DECLINED ? 'white' : 'grey800'
            }
          >
            {status}
          </BodySBold>
        </StatusChip>
      </S.StatusHeader>
      {fileType !== '' && filePathConverted !== '' && (
        <S.ChoseFileWrapper $status={status} $type='square'>
          {fileType === 'PDF' ? (
            <S.PdfWrapper>
              <PDFReader
                url={ConvertUtil.getPrivateMinioUrl(filePathConverted)}
                scale={0.5}
                showAllPage
              />
            </S.PdfWrapper>
          ) : (
            <img
              src={`${ConvertUtil.getPrivateMinioUrl(filePathConverted)}`}
              alt='User Document'
            />
          )}
        </S.ChoseFileWrapper>
      )}
    </>
  );

  const renderFooterInfo = () => {
    if (step === 0) {
      return <UploadFileFooterInfo />;
    }
    if (step === 2) {
      return (
        status === DocumentStatusLabel.DECLINED &&
        currentUser?.feedbacks?.age && (
          <ResendFileStatusFooterInfo reason={currentUser.feedbacks.age} />
        )
      );
    }
    return true;
  };

  const renderFooter = () => {
    switch (step) {
      case 0:
        return <UploadFileFooter />;
      case 1:
        return (
          <SendFileFooter
            setStep={setStep}
            isLoading={isLoading}
            sendToApproval={sendToApproval}
          />
        );
      case 2:
        return (
          status === DocumentStatusLabel.DECLINED && (
            <ResendFileStatusFooter setStep={setStep} />
          )
        );
      default:
        break;
    }
    return true;
  };

  React.useEffect(() => {
    if (fileList) {
      setStep(1);
      if (fileList[0]) {
        if (fileList[0].type === 'application/pdf') {
          setFileType('PDF');
        } else {
          setFileType('IMAGE');
        }
        upload();
      }
    }
  }, [fileList]);

  React.useEffect(() => {
    if (step === 0) {
      setTitle('Upload Proof of Age');
      setFilePath('');
      setFilePathConverted('');
    } else {
      setTitle('Your Proof of Age');
    }
  }, [step]);

  React.useEffect(() => {
    if (currentUser?.ageProof) {
      setStep(2);
    }
  }, []);

  React.useEffect(() => {
    if (currentUser?.ageProof) {
      DownloadService.download(currentUser?.ageProof).then((res) => {
        if (res.success) {
          setFilePathConverted(res.data);
          fetch(ConvertUtil.getPrivateMinioUrl(res.data))
            .then((blobResp) => blobResp.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setFileType('PDF');
              } else {
                setFileType('IMAGE');
              }
            });
        }
      });
    }
  }, [currentUser?.ageProof]);

  return (
    <UploadModal
      {...props}
      title={title}
      footer={renderFooter()}
      footerInfo={renderFooterInfo()}
      onAfterClose={() => {
        return currentUser?.ageProof ? false : setStep(0);
      }}
    >
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
    </UploadModal>
  );
};

export default ProofOfAgeModal;
