import { Modal } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(24)};

  margin-bottom: 2rem;

  span {
    cursor: pointer;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-bottom: 0;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: ${toRem(468)};
    border-radius: ${toRem(12)};
    background-color: ${COLORS.grey900};
  }

  .ant-modal-header {
    border-radius: ${toRem(12)};
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: ${toRem(12)};
    padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(12)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: ${toRem(16)};
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: ${toRem(1)} solid ${COLORS.grey600};

  h3 {
    margin: 0 !important;
  }
`;

export const CloseButton = styled.button`
  width: ${toRem(32)};
  height: ${toRem(32)};

  border: none;
  box-shadow: none;
  padding: ${toRem(7.4)} 0;
  border-radius: ${toRem(10)};
  background-color: unset;

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: unset;
  }
`;

export const MultiplierWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(8)};

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #bdbeb8;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    height: ${toRem(150)};

    padding-right: ${toRem(6)};
  }

  @media screen and (max-width: ${BREAKPOINTS.smallMobile}) {
    height: ${toRem(100)};

    padding-right: ${toRem(6)};
  }
`;
