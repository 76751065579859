import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const TeamsContainer = styled.div`
  padding: ${toRem(16)} 0;

  background-color: ${COLORS.grey950};
  border-radius: ${toRem(24)};
`;

export const TeamsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${toRem(24)} ${toRem(8)} ${toRem(24)};
`;

export const TeamsTitleBackBtnContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(24)};
`;

export const TeamsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(24)} ${toRem(20)};
  gap: ${toRem(16)};
`;

export const ChooseApplications = styled.div`
  display: flex;
  flex-direction: column;
  width: ${toRem(257)};
  padding: ${toRem(8)} 0;
  align-items: flex-start;
  gap: ${toRem(10)};

  background-color: ${COLORS.grey950};
  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(12)};

  position: absolute;
  right: 14%;
  top: 45%;

  transition: ease-in-out 0.4s;
`;

export const ApplicationAllSelect = styled.div`
  width: 100%;
  padding: ${toRem(8)} ${toRem(16)};

  cursor: pointer;
  transition: ease-in-out 0.5s;
  :hover {
    background-color: ${COLORS.grey800};
  }
`;
