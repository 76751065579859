import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BaseUserService from 'services/v3/BaseUsers/BaseUsersApi';
import UserServices from 'services/v3/User/UserService';

import { UserRelationship, BaseUser } from 'redux/v3/baseUsers/types';
import { baseUserSetUser, baseUserSetUserId } from 'redux/v3/baseUsers/actions';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';

import { BodyXL, TitleH1 } from 'styles/v3/variables';

import { Gender } from 'admin/models/Enums';
import InputText from 'components/v3/Forms/InputText/InputText';
import Select from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { notification } from 'components/v3/Notification/notification';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import { BackConfirmModal } from './BackConfirmModal/BackConfirmModal';
import { RELATIONSHIP_OPTIONS, GENDER_OPTIONS } from './configs';

import * as S from './styles';

interface IField {
  value: string;
  error: boolean;
}

interface IFields {
  birthDate: IField;
  firstName: IField;
  gender: IField;
  lastName: IField;
  phoneNumber: IField;
  relationship: IField;
}

const NewUser: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const formRef = useRef<HTMLFormElement>(null);

  const [fields, setFields] = useState<IFields>({
    birthDate: { value: '', error: false },
    firstName: { value: '', error: false },
    gender: { value: '', error: false },
    lastName: { value: '', error: false },
    phoneNumber: { value: '', error: false },
    relationship: { value: '', error: false },
  });
  const [validating, setValidating] = useState<boolean>(false);
  const [openedBackConfirmModal, setOpenedBackConfirmModal] = useState(false);

  const handleInputClear = (field: keyof IFields) => {
    setFields({
      ...fields,
      [field]: { value: '', error: fields[field].error },
    });

    setValidating(false);
  };

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: { value: e.target.value, error: false },
    });

    setValidating(false);
  };

  const handleChangeSelect = (field: string, e: any) => {
    setFields({ ...fields, [field]: { value: e.value, error: false } });

    setValidating(false);
  };

  const validate = () => {
    setValidating(true);

    for (const [key, values] of Object.entries(fields)) {
      if (values.value === '') {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };

  const saveUser = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);

        const payload: BaseUser = {
          id: '',
          clubs: [],
          birthDate: formData.get('birthDate') as string,
          firstName: formData.get('firstName') as string,
          gender: String(formData.get('gender')) as Gender,
          lastName: formData.get('lastName') as string,
          phoneNumber: formData.get('phoneNumber') as string,
          relationship: String(
            formData.get('relationship')
          ) as UserRelationship,
        };

        const { data } = await BaseUserService.saveUser(payload);

        if (data.message) {
          throw new Error(data.message);
        } else {
          dispatch(baseUserSetUser(data));

          if (data.id) dispatch(baseUserSetUserId(data.id));

          const { data: updatedProfile } = await UserServices.getProfiles();

          const updatedBaseUser = updatedProfile?.baseUsers?.find(
            (user) => user.id === data.id
          );

          if (updatedBaseUser) {
            const newUsers = [
              ...(updatedProfile.baseUsers || []),
              ...(updatedProfile.users || []),
            ];

            dispatch(setCurrentUser(updatedBaseUser));
            dispatch(usersFetchSuccess(newUsers));
          }

          history.push(`/v3/user/${data?.id}/created`);
        }
      }
    } catch (error) {
      notification.error({
        message: (error as any).message || 'Error when trying to create the new user',
      });
    }
  };

  const handleTriggerBackConfirmModal = useCallback((visible: boolean) => () => {
    setOpenedBackConfirmModal(visible);
  }, []);

  const handleConfirmToBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i.error);

      if (!hasError) saveUser();
    }
  }, [validating, fields]);

  return (
    <S.NewUserWrapper>
      <TextButtonWithIcon
        reverse
        icon='back'
        align='flex-end'
        color='primary'
        onClick={handleTriggerBackConfirmModal(true)}
      >
        Go back
      </TextButtonWithIcon>
      <TitleH1>New User</TitleH1>
      <S.NewUserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend>
            <BodyXL $color='brandPrimary' $isUpper>
              User Info
            </BodyXL>
          </legend>
          <Select
            name='relationship'
            placeholder='What is your connection with this user?'
            options={RELATIONSHIP_OPTIONS}
            onChange={(e) => handleChangeSelect('relationship', e)}
            required
            error={fields.relationship.error}
            className='new-user-input'
          />
          <InputText
            id='firstName'
            placeholder='First Name'
            label='First Name'
            defaultValue={fields.firstName.value}
            required
            onClearInput={(id) => handleInputClear(id)}
            error={fields.firstName.error}
            onChange={handleChangeInputValue}
            className='new-user-input'
          />
          <InputText
            id='lastName'
            placeholder='Last Name'
            label='Last Name'
            defaultValue={fields.lastName.value}
            required
            onClearInput={(id) => handleInputClear(id)}
            error={fields.lastName.error}
            onChange={handleChangeInputValue}
            className='new-user-input'
          />
          <InputPhone
            id='phoneNumber'
            placeholder='Telephone'
            label='Telephone'
            defaultValue={fields.phoneNumber.value}
            onChange={handleChangeInputValue}
            error={fields.phoneNumber.error}
            required
            className='new-user-input'
          />
        </fieldset>
        <S.FormFieldset>
          <InputDate
            id='birthDate'
            placeholder='Birthdate'
            label='Birthdate'
            defaultValue={fields.birthDate.value}
            error={fields.birthDate.error}
            onChange={handleChangeInputValue}
            required
          />
          <Select
            name='gender'
            placeholder='Gender'
            options={GENDER_OPTIONS}
            required
            error={fields.gender.error}
            onChange={(e) => handleChangeSelect('gender', e)}
          />
        </S.FormFieldset>
        <FilledButton isUpper onClick={validate}>
          Create User
        </FilledButton>
      </S.NewUserForm>
      <BackConfirmModal
        visible={openedBackConfirmModal}
        onCancel={handleTriggerBackConfirmModal(false)}
        onConfirm={handleConfirmToBack}
      />
    </S.NewUserWrapper>
  );
};

export default withRouter(NewUser);
