import styled from 'styled-components';
import { Modal } from 'antd';
import { TextField } from '@mui/material';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS, BodyM } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.grey600};

  h3 {
    margin: 0 !important;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 7px 0;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: unset;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 16px;
`;

export const TextareaField = styled(TextField)`
  width: 100%;

  fieldset {
    display: none;
  }

  .MuiInputBase-root {
    padding: 10px 12px;
    font-family: 'Boxed Regular';
    border: 1px solid ${COLORS.grey400};
    color: ${COLORS.white};
    border-radius: 12px;
  }

  .Mui-focused {
    border-color: ${COLORS.brandPrimary};
  }
`;

export const Label = styled(BodyM)`
  color: ${COLORS.supportError};
`;

export const ButtonsGroup = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const CancelButton = styled(OutlinedButton)`
  width: calc(50% - 6px);
  background-color: transparent !important; // Due to modal library nesty
  transition-duration: 300ms;

  &:hover {
    color: ${COLORS.white};
    border-color: #a7a8a1;
  }
`;

export const ReportFilledButton =
  styled(FilledButton) <
  { $disabled: boolean } >
  `
  min-width: calc(50% - 6px);
  align-self: center;
  background-color: ${COLORS.brandPrimary} !important;
  user-select: none;

  ${(props) =>
    props.$disabled &&
    `
    background-color: ${COLORS.grey500} !important;
    border-color: ${COLORS.grey500};
    pointer-events: none;
  `}

  &:hover {
    background-color: transparent !important;
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
  }
`;
