import { SoccerPeriod } from 'services/v3/Match/enums';

export const MATCH_TIMELINE_SOCCERS = [
  {
    type: SoccerPeriod.FIRST_HALF,
    value: 'First Half',
  },
  {
    type: SoccerPeriod.SECOND_HALF,
    value: 'Second Half',
  },
  {
    type: SoccerPeriod.FIRST_EXTRA_TIME,
    value: 'First Extra Time',
  },
  {
    type: SoccerPeriod.SECOND_EXTRA_TIME,
    value: 'Second Extra Time',
  },
];
