export const getFormattedDateTime = (date: string, format: 'date' | 'time') => {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/;
  const match = date.match(regex);

  if (!match) {
    return date;
  }

  const month = Number(match[2]);
  const day = Number(match[3]);
  const hours = Number(match[4]);
  const minutes = Number(match[5]);

  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  if (format === 'time') {
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  return `${month}/${day}`;
};

export const getFormattedDate = (dateStr: string, type: 'short' | 'long') => {
  const date = new Date(`${dateStr}T12:00:00`);

  console.log('>> DATE X', date);
  if (!date) return '';
  if (type === 'long') {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date(date));
  }
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
  }).format(new Date(date));
};
