import { OptionsType } from 'components/v3/Forms/Select/Select';

export const playersOptions: OptionsType[] = [
  {
    value: 'ALLOW_PLAYERS',
    label: 'Yes, players can play both in the same or different age divisions',
  },
  {
    value: 'ALLOW_PLAYERS_DIFFERENT_TEAMS_DIFFERENT_DIVISIONS',
    label:
      'Players can play in different teams, as long as they are in different age divisions',
  },
  {
    value: 'ALLOW_PLAYERS_DIFFERENT_TEAMS_SAME_DIVISION',
    label:
      'Players can play in different teams, as long as they are in same age divisions',
  },
];

export const coachesOptions: OptionsType[] = [
  {
    value: 'ALLOW_COACHES',
    label: 'Yes, coaches can coach both in the same or different age divisions',
  },
  {
    value: 'ALLOW_COACHES_DIFFERENT_TEAMS_DIFFERENT_DIVISIONS',
    label:
      'Coaches can coach in different teams, as long as they are in different age divisions',
  },
  {
    value: 'ALLOW_COACHES_DIFFERENT_TEAMS_SAME_DIVISION',
    label:
      'Coaches can coach in different teams, as long as they are in same age divisions',
  },
];

export const currencyOptions: OptionsType[] = [
  {
    value: 'USD',
    label: 'Dollar',
  },
];
