import React, { useMemo } from 'react';
import { BracketView } from 'models/Bracket/BracketModel';
import { TitleH1 } from 'styles/v3/variables';
import {
  BracketItemContainer,
  BracketItemHeader,
  BracketRenderContainer,
  BracketRenderItem,
} from './styles';
import { getBracketRender } from './components/BracketRenderByType';

interface BracketItemProps {
  bracket: BracketView;
  hideHeader?: boolean;
}

export const BracketItem: React.FunctionComponent<BracketItemProps> = ({
  bracket,
  hideHeader = false,
}) => {
  const BracketRender = useMemo(() => {
    return getBracketRender(bracket.type);
  }, [bracket]);

  return (
    <BracketItemContainer>
      {!hideHeader && (
        <BracketItemHeader>
          <TitleH1>{bracket.name}</TitleH1>
        </BracketItemHeader>
      )}
      <BracketRenderContainer>
        <BracketRenderItem>
          <BracketRender matches={bracket.matches} bracketId={bracket.id} />
        </BracketRenderItem>
      </BracketRenderContainer>
    </BracketItemContainer>
  );
};
