import React, { FunctionComponent, useMemo } from 'react';
import { COLORS } from 'styles/v3/variables';
import { BracketMatchDTO } from 'models/Bracket/BracketModel';
import { BracketRenderProps } from '../../BracketRender';
import {
  BracketColumn,
  BracketMultiColumnGroupContainer,
  BracketRenderItem,
} from '../../styles';
import {
  BracketGame,
  EmptyBracketGame,
  SingleItemBracketGame,
} from '../BracketGame';

export const CompassDraw8Render: FunctionComponent<BracketRenderProps> = ({
  bracketId,
  matches,
  handleOpenEditModal,
  onUpdate,
}) => {
  const [west, base, east, southwest, southeast] = useMemo(() => {
    const columns: BracketMatchDTO[][] = [[], [], [], [], []];

    matches.forEach((match) => {
      switch (match.compassDrawPosition) {
        case 'WEST':
          columns[0].push(match);
          break;
        case 'BASE':
          columns[1].push(match);
          break;
        case 'EAST':
          columns[2].push(match);
          break;
        case 'SOUTHWEST':
          columns[3].push(match);
          break;
        case 'SOUTHEAST':
          columns[4].push(match);
          break;
      }
    });

    return columns;
  }, [matches]);
  return (
    <BracketMultiColumnGroupContainer>
      <BracketRenderItem>
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={west[2]}
            from={{
              color: COLORS.supportError,
              side: true,
            }}
            to={{
              color: COLORS.supportError,
              up: true,
              down: true,
            }}
            extend
            handleOpenEditModal={handleOpenEditModal}
            onUpdate={onUpdate}
          />
        </BracketColumn>
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={west[0]}
            from={{
              color: COLORS.supportError,
              down: true,
            }}
            to={{
              color: COLORS.supportError,
              up: true,
              down: true,
            }}
            handleOpenEditModal={handleOpenEditModal}
            onUpdate={onUpdate}
          />
          <EmptyBracketGame />
          <BracketGame
            bracketId={bracketId}
            match={west[1]}
            from={{
              color: COLORS.supportError,
              up: true,
            }}
            to={{
              color: COLORS.supportError,
              down: true,
              up: true,
            }}
            handleOpenEditModal={handleOpenEditModal}
            onUpdate={onUpdate}
          />
        </BracketColumn>
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={base[0]}
            from={{
              color: COLORS.supportError,
              down: true,
            }}
            to={{
              down: true,
              color: COLORS.brandPrimary,
            }}
            handleOpenEditModal={handleOpenEditModal}
            showPens
            titleSourceEmpty='TBA'
            onUpdate={onUpdate}
          />
          <BracketGame
            bracketId={bracketId}
            match={base[1]}
            from={{
              color: COLORS.supportError,
              up: true,
            }}
            to={{
              up: true,
              color: COLORS.brandPrimary,
            }}
            handleOpenEditModal={handleOpenEditModal}
            showPens
            titleSourceEmpty='TBA'
            onUpdate={onUpdate}
          />
          <BracketGame
            bracketId={bracketId}
            match={base[2]}
            from={{
              color: COLORS.supportError,
              down: true,
            }}
            to={{
              down: true,
              color: COLORS.brandPrimary,
            }}
            handleOpenEditModal={handleOpenEditModal}
            showPens
            titleSourceEmpty='TBA'
            onUpdate={onUpdate}
          />
          <BracketGame
            bracketId={bracketId}
            match={base[3]}
            from={{
              color: COLORS.supportError,
              up: true,
            }}
            to={{
              up: true,
              color: COLORS.brandPrimary,
            }}
            handleOpenEditModal={handleOpenEditModal}
            showPens
            titleSourceEmpty='TBA'
            onUpdate={onUpdate}
          />
        </BracketColumn>
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={east[0]}
            from={{
              color: COLORS.brandPrimary,
              up: true,
              down: true,
            }}
            to={{
              down: true,
              color: COLORS.brandPrimary,
            }}
            handleOpenEditModal={handleOpenEditModal}
            onUpdate={onUpdate}
          />
          <EmptyBracketGame />
          <BracketGame
            bracketId={bracketId}
            match={east[1]}
            from={{
              color: COLORS.brandPrimary,
              up: true,
              down: true,
            }}
            to={{
              color: COLORS.brandPrimary,
              up: true,
            }}
            onUpdate={onUpdate}
            handleOpenEditModal={handleOpenEditModal}
          />
        </BracketColumn>
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={east[2]}
            from={{
              color: COLORS.brandPrimary,
              up: true,
              down: true,
            }}
            to={{
              side: true,
              color: COLORS.brandPrimary,
            }}
            extend
            onUpdate={onUpdate}
            handleOpenEditModal={handleOpenEditModal}
          />
        </BracketColumn>
      </BracketRenderItem>
      <BracketRenderItem>
        <BracketColumn>
          <EmptyBracketGame />
        </BracketColumn>
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={southwest[0]}
            from={{
              side: true,
              color: COLORS.supportError,
            }}
            onUpdate={onUpdate}
            handleOpenEditModal={handleOpenEditModal}
          />
        </BracketColumn>
        <BracketColumn />
        <BracketColumn>
          <BracketGame
            bracketId={bracketId}
            match={southeast[0]}
            to={{
              up: true,
              down: true,
              color: COLORS.brandPrimary,
            }}
            onUpdate={onUpdate}
            handleOpenEditModal={handleOpenEditModal}
          />
        </BracketColumn>
        <BracketColumn>
          <EmptyBracketGame />
        </BracketColumn>
      </BracketRenderItem>
    </BracketMultiColumnGroupContainer>
  );
};
