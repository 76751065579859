import { AxiosRequestConfig } from 'axios';

import { Tiebreakers } from 'admin/models/event/Event';
import { ConfigApi } from '../../config.api';
import APIServicesV3 from '../../util/ApiServicesV3';
import ServiceResponseV3 from '../../util/ServiceResponseV3';

class B2bTiebreakersService {
  public static async list(): Promise<ServiceResponseV3<Tiebreakers[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: '/tiebreakers',
      method: 'GET',
    };
    return APIServicesV3.request<Tiebreakers[]>(axiosConfig);
  }
}

export default B2bTiebreakersService;
