import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  gap: ${toRem(24)};

  padding: ${toRem(16)};
  border-radius: ${toRem(16)};
  background-color: ${COLORS.grey900};

  @media screen and (max-width: ${BREAKPOINTS.smallMobile}) {
    padding: ${toRem(10)};

    gap: ${toRem(10)};
  }
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(4)};
`;
