import { useQuery } from '@tanstack/react-query';
import BracketService from 'services/v3/Brackets/BracketService';

export function useGetBracketsByEventId(eventId?: string) {
  const { data, isLoading, error } = useQuery(
    ['get-brackets-by-event-id', eventId],
    async () => await BracketService.getBracketsByEventId(eventId)
  );
  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
