import { AgeDivision } from '../admin/models/AgeDivision';
import { PaginationModel } from '../models/Pagination/Pagination';

export default class ConvertUtil {
  static getMinioUrl(
    ref: string | undefined,
    size?: 100 | 200 | 500 | 1000
  ): string {
    if (size) {
      ref = ref?.replace('user/', `user/${size}/`);
    }

    if (ref !== undefined) {
      return process.env.REACT_APP_PUBLIC_STORAGE_ENDPOINT + ref;
    }
    return '';
  }

  static getPrivateMinioUrl(ref: string | undefined): string {
    if (ref !== undefined) {
      return process.env.REACT_APP_PRIVATE_STORAGE_ENDPOINT + ref;
    }
    return '';
  }

  static queryParameter(name: string, value: string): string {
    const replacedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${replacedName}=([^&#]*)`);
    const results = regex.exec(value);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  static arrayEquals(a: any[], b: any[]) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  static secToTime = (time: number) => {
    const hours = Math.floor(time / 3600) > 0 ? Math.floor(time / 3600) : 0;
    const minutes =
      Math.floor((time % 3600) / 60) > 0 ? Math.floor((time % 3600) / 60) : 0;
    const seconds = Math.floor(time % 60) > 0 ? Math.floor(time % 60) : 0;

    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
  };

  static convertPaginationUrlParam = (pagination: PaginationModel) => {
    const urlParams = new URLSearchParams();
    urlParams.append('page', pagination.current.toString());
    urlParams.append('size', pagination.size.toString());
    if (pagination.sort && pagination.sort.length !== 0) {
      urlParams.append('sort', pagination.sort.join(','));
    }
    return urlParams;
  };

  static convertStringAgeDivision = (
    ageDivisionStr: string
  ): AgeDivision | undefined => {
    const gender = ageDivisionStr.substr(0, ageDivisionStr.indexOf(' '));
    const rule = ageDivisionStr
      .substr(ageDivisionStr.indexOf(' ') + 1)
      .split(' ')
      .pop();

    const ageDivisionTemp = ageDivisionStr
      .substr(ageDivisionStr.indexOf(' ') + 1)
      .split(', ');
    ageDivisionTemp.pop();
    const ageDivision = ConvertUtil.arrayEquals(ageDivisionTemp, ['ADULT'])
      ? [1900]
      : ageDivisionTemp.map(Number);
    if (gender && rule && ageDivision) {
      return {
        gender,
        years: ageDivision,
        rule: rule!,
      };
    }
    return undefined;
  };

  static convertAgeDivisionToString = (ageDivision: AgeDivision): string => {
    return `${ageDivision?.gender} ${
      ageDivision?.years[0] === 1900
        ? 'ADULT'
        : ageDivision?.years.sort().join(', ')
    }, ${ageDivision?.rule}`;
  };

  static filterParams(params: any) {
    return Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== null && value !== undefined && value !== ''
      )
    );
  }
}
