import { ClubView } from 'admin/models/club/Club';
import { User } from 'models/User/UserModel';

export enum RequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

interface RequestModel {
  id: string;
  club: {
    id: string,
    city: string,
    country: string,
    logo: string,
    name: string,
    state: string,
  };
  user: any;
  status: RequestStatus;
}

export interface RequestWithClubAndUser {
  id: string;
  status: RequestStatus;
  user: User;
  club: ClubView;
}

export default RequestModel;
