import React from 'react';
import { BodyMBold, BodyS } from 'styles/v3/variables';
import { Printer } from '@icon-park/react';
import { PrintItemProps } from './types';

import * as S from './styles';

export const PrintItem = ({
  description,
  title,
  type,
  event,
  handleSelectType,
}: PrintItemProps) => {
  const handleOpenPrintPage = () => {
    if (!event?.id) return;

    switch (type) {
      case 'all-brackets':
      case 'all-teams':
      case 'schedule-by-time-per-location':
      case 'schedule-by-court':
        window.open(`/v3/print/event/${event.id}/${type}`);
        break;
      default:
        break;
    }
  };

  const handleSelectItem = () => {
    if (!(type !== 'rosters' && type !== 'score-sheet')) {
      handleSelectType();
    } else {
      handleOpenPrintPage();
    }
  };
  return (
    <S.Container onClick={handleSelectItem}>
      <S.ContentContainer>
        <BodyMBold className='title'>{title}</BodyMBold>
        <BodyS className='description'>{description}</BodyS>
      </S.ContentContainer>
      <S.PrintButtonContainer>
        <Printer className='reset' />
      </S.PrintButtonContainer>
    </S.Container>
  );
};
