import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { AvatarWrapper } from '../../components/Header/styles';

export const Container = styled.div`
  ${AvatarWrapper} {
    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      width: 100%;

      padding-top: 0;

      align-items: flex-end;
      justify-content: space-between;
    }
  }
`;

export const AgeDivisionsWrapper = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    flex-direction: column;

    gap: ${toRem(24)};

    padding: 0 ${toRem(16)};
  }
`;

export const RostersWrapper = styled.div`
  height: 100%;
  background-color: ${COLORS.grey1000};
`;
