import { Col, Row } from 'antd';
import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../routes/PrivateRoute';
import TeamApplications from '../TeamApplications';
import TournamentsSidemenu from '../TournamentsSidemenu';
import RostersList from '../RostersList';
import RosterPrint from '../RosterPrint';
import MatchCreation from '../MatchCreation/MatchCreation';
import MatchesList from '../MatchesList/MatchesList';
import GameControl from '../GameControl';
import TournamentSelection from '../TournamentSelection.page';
import StaffRosterEdit from '../StaffRosterEdit';
import { UserType } from '../../../../redux/user/types';
import TournamentPage from '../Tournament.page';
import MatchesListV3 from '../MatchesList/MatchesListV3';

interface ITournamentsRoutesProps {
  auth: Keycloak.KeycloakInstance | undefined;
}

const tournamentRoutes = [
  {
    path: '/admin-panel/tournaments/',
    name: 'Tournament Selection',
    component: TournamentSelection,
    role: [UserType.STAFF, UserType.REFEREE],
    exact: true,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/',
    name: 'Team Applications',
    component: TournamentPage,
    role: [UserType.STAFF, UserType.REFEREE],
    exact: true,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/team-applications',
    name: 'Team Applications',
    component: TeamApplications,
    role: [UserType.STAFF],
    exact: true,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/rosters',
    name: 'Rosters',
    component: RostersList,
    role: [UserType.STAFF],
    exact: true,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/roster-edit/:rosterId',
    name: 'Rosters',
    component: StaffRosterEdit,
    role: [UserType.STAFF],
    exact: true,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/game-creation',
    name: 'Game Creation',
    component: MatchCreation,
    role: [UserType.STAFF],
    private: true,
    exact: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/game-creation/edit/:matchId',
    name: 'Game Creation',
    component: MatchCreation,
    role: [UserType.STAFF],
    private: true,
    exact: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/games2/',
    name: 'Games V3',
    exact: true,
    role: [UserType.STAFF, UserType.REFEREE],
    component: MatchesListV3,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/games/',
    name: 'Games',
    exact: true,
    role: [UserType.STAFF, UserType.REFEREE],
    component: MatchesList,
    private: true,
  },
  {
    path: '/admin-panel/tournaments/:eventId/games/:matchId/',
    name: 'Game Control',
    component: GameControl,
    private: true,
    layout: 'single',
  },
];

const TournamentsRoutes: React.FunctionComponent<ITournamentsRoutesProps> = (
  props
) => {
  const { auth } = props;
  const isInMatch = useRouteMatch(
    '/admin-panel/tournaments/:eventId/games/:matchId/'
  );
  const isInMain = useRouteMatch({
    path: '/admin-panel/tournaments/',
    exact: true,
  });
  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={30}>
        {!isInMatch && !isInMain && <TournamentsSidemenu />}

        <Col xs={24} sm={24} md={24} lg={!isInMatch && !isInMain ? 18 : 24}>
          <Switch>
            {tournamentRoutes.map((route, i) =>
              !route.private ? (
                <Route
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  render={() => <route.component />}
                />
              ) : (
                <PrivateRoute
                  key={route.path}
                  auth={auth}
                  userSelectedNotRequired
                  roles={route.role}
                  exact={route.exact ? route.exact : false}
                  path={route.path}
                  render={() => <route.component />}
                />
              )
            )}
          </Switch>
        </Col>
      </Row>
    </div>
  );
};

export { TournamentsRoutes, tournamentRoutes };
