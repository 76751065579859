import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Wrapper =
  styled.div <
  { $theme: 'success' | 'error' } >
  `
  .simple-card-wrapper {
    border-width: 4px;
    padding: ${toRem(30)};
    justify-content: flex-start;
    border-color: ${(props) =>
      props.$theme === 'success' ? COLORS.brandPrimary : COLORS.supportError};

    .card-icon {
      margin-top: ${toRem(-10)};
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .simple-card-wrapper {
      flex-direction: column;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${toRem(32)};

  h3,
  p {
    margin-bottom: 1rem;
  }

  a {
    width: fit-content;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-left: 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${toRem(10)};
`;
