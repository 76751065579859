import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';
import * as React from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { TitleH2 } from 'styles/v3/variables';

import UserType from 'models/User/UserTypeModel';
import { setSignupClub } from 'redux/v3/signupEvent/actions';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { ClubIcon } from './components/ClubIcon/ClubIcon';

import * as S from './styles';

const SigNupAsClubDirector: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const userRole = location.pathname?.includes('signup-as-club-director')
    ? UserType.CLUB_DIRECTOR
    : UserType.TEAM_MANAGER;
  const chekoutAs =
    userRole === UserType.CLUB_DIRECTOR
      ? 'checkout-as-club-director'
      : 'checkout-as-team-manager';
  const { clubId: selectedClubId } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  const params: { eventId: string } = useParams();

  const dispatch = useDispatch();
  const { data: myClubs } = useGetMyClubsByUserType(userRole);

  const mySelectedClubs = React.useMemo(() => {
    if (!myClubs) return [];

    return myClubs.map((club) => ({
      ...club,
      selected: selectedClubId === club.id,
    }));
  }, [selectedClubId, myClubs]);

  const enableContinue = React.useMemo<boolean>(() => {
    return (
      mySelectedClubs.filter((club) => club.selected).length > 0 &&
      !!myClubs?.find((club) => club.id === selectedClubId)
    );
  }, [mySelectedClubs]);

  function handlePrevious() {
    history.push(`/v3/event/${params.eventId}`);
  }

  const handleSelectClub = (id: string) => {
    dispatch(setSignupClub(id));
  };

  const handleNextPage = () => {
    dispatch(setSignupClub(selectedClubId));
    history.push(`/v3/event/${params.eventId}/${chekoutAs}`);
  };

  return (
    <S.SignUpAsClubDirectorContainer>
      <S.Header>
        <TextButtonWithIcon
          color='primary'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePrevious}
        >
          Go back
        </TextButtonWithIcon>

        <TitleH2>SELECT THE CLUB JOINING THE TOURNAMENT</TitleH2>
      </S.Header>

      <S.ClubListWrapper>
        {mySelectedClubs?.map((club) => (
          <ClubIcon
            onClick={() => handleSelectClub(club.id)}
            key={club.id}
            selected={club.selected}
            name={club.name}
            image={club.logo}
          />
        ))}
      </S.ClubListWrapper>

      <FilledButton
        className='btn-continue'
        disabled={!enableContinue}
        onClick={handleNextPage}
        color='primary'
        isUpper
      >
        Continue
      </FilledButton>
    </S.SignUpAsClubDirectorContainer>
  );
};

export default withRouter(SigNupAsClubDirector);
