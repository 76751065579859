import React, { memo, FC } from 'react';
import { CloseSmall } from '@icon-park/react';

import { BodyM, BodyMBold } from 'styles/v3/variables';

import * as S from './styles';

interface IOverviewReportModalProps {
  visible: boolean;
  report: string;
  onClose: () => void;
}

export const OverviewReportModal: FC<IOverviewReportModalProps> = memo(
  ({ visible, report, onClose }) => {
    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={onClose}
      >
        <S.Header>
          <BodyM $color='grey50'>Game Report</BodyM>
          <S.CloseButton onClick={onClose}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.Header>
        <S.Container>
          <BodyMBold>{report}</BodyMBold>
        </S.Container>
      </S.StyledModal>
    );
  }
);

OverviewReportModal.displayName = 'OverviewReportModal';
