import React from 'react';
import { Right } from '@icon-park/react';
import { BodyMBold, BodyS } from 'styles/v3/variables';
import { useHistory } from 'react-router-dom';
import { ScoreSheetItemProps } from './types';

import * as S from './styles';

export const ScoreSheetItem = ({
  description,
  eventId,
  title,
  type,
}: ScoreSheetItemProps) => {
  const history = useHistory();

  const handleSelect = () => {
    if (type === 'all-scoresheet') {
      window.open(`/v3/print/event/${eventId}/all-scoresheet`);
    } else {
      history.push(`/v3/event/${eventId}/schedule/print-all-games`);
    }
  };

  return (
    <S.Container onClick={handleSelect}>
      <S.InfoContainer>
        <BodyMBold>{title}</BodyMBold>
        <BodyS>{description}</BodyS>
      </S.InfoContainer>
      <S.SelectButtonContainer>
        <Right className='reset' />
      </S.SelectButtonContainer>
    </S.Container>
  );
};
