import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'redux/store';
import { B2bEventItem } from 'redux/v3/b2bSetupEvent/types';
import { b2bSetupEventFetchEventsSuccess } from 'redux/v3/b2bSetupEvent/actions';
import { B2bEventStatus } from 'models/v3/Event/Event';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';

import { EventPublishedStatus } from 'admin/models/event/Event';
import EventCard from 'components/v3/Cards/EventCard/EventCard';
import Loading from 'components/v3/Loading/Loading';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

export const CreatedByMe: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { content: storedEvents } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent.data
  );

  const handleLoadEvents = async () => {
    setIsLoading(true);

    try {
      const { data: fetchedEvents } = await B2bEventService.listEvents(100);

      dispatch(b2bSetupEventFetchEventsSuccess(fetchedEvents));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleLoadEvents();
  }, []);

  const getEventStatus = (currentEvent: B2bEventItem): B2bEventStatus => {
    if (!currentEvent?.data?.eventPublished) return 'draft';

    switch (currentEvent.data.eventPublished) {
      case EventPublishedStatus.PUBLISHED:
        return 'published';
      case EventPublishedStatus.PENDING:
        return 'waiting approval';
      case EventPublishedStatus.EXPIRED:
        return 'expired';
      default:
        return 'draft';
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <S.EventsList>
          {storedEvents?.map((i) => {
            const eventStatus = getEventStatus(i);
            const linkToRedirect =
              eventStatus === 'published'
                ? `/v3/event/${i.id}/dashboard`
                : `/v3/setup-event/my-events/${i.id}`;

            return (
              <EventCard
                key={i.id}
                eventStatus={eventStatus}
                orientation='column'
                size='small'
                title={i.name}
                description={i.description}
                startDate={i.startDate}
                finalDate={i.endDate}
                id={i.id}
                logo={i.logo}
                divisions={i.ageDivisions}
                link={linkToRedirect}
              />
            );
          })}
        </S.EventsList>
      )}
      {!isLoading && !storedEvents.length && (
        <BodyM>No events of your own. Create a new event</BodyM>
      )}
    </>
  );
};
