import React, { FC, useEffect } from 'react';
import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';

import { BodyXL, BodyM } from 'styles/v3/variables';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { PlayerOfCoachInformation } from './PlayerOrCoachInformation';

import { Container, HeaderList, BodyList, ContainerOfInfos } from './styles';
import { UserWithJersey } from '../../types';

interface IListOfUserProps {
  typeOfUser: string;
  coachesList?: User[];
  playersList?: UserWithJersey[];
  rosterId?: Maybe<string>;
  handleOpenModal: (title: UserType) => void;
  handleOpenUserInfoModal: (user: User) => void;
}

export const ListOfUser: FC<IListOfUserProps> = ({
  typeOfUser,
  coachesList,
  playersList,
  rosterId,
  handleOpenModal,
  handleOpenUserInfoModal,
}) => {
  const userType = typeOfUser === 'Coaches' ? UserType.COACH : UserType.PLAYER;
  const listOfUser = typeOfUser === 'Coaches' ? coachesList : playersList;

  return (
    <Container>
      <HeaderList>
        <BodyXL $color='white'>
          {typeOfUser} ({listOfUser?.length})
        </BodyXL>
        <TextButtonWithIcon
          customIcon='PeoplePlusOne'
          color='primary'
          align='flex-end'
          isUpper
          isBold={false}
          onClick={() => handleOpenModal(userType)}
        >
          ADD {typeOfUser}
        </TextButtonWithIcon>
      </HeaderList>
      <BodyList>
        {!!listOfUser?.length && (
          <ContainerOfInfos>
            {listOfUser.map((user) => (
              <PlayerOfCoachInformation
                key={user.id}
                user={user}
                rosterId={rosterId}
                handleOpenUserInfoModal={handleOpenUserInfoModal}
              />
            ))}
          </ContainerOfInfos>
        )}
        {!listOfUser?.length && (
          <BodyM $color='grey400' style={{ paddingTop: 50, paddingBottom: 50 }}>
            You don&apos;t have{' '}
            {typeOfUser === 'Coaches' ? 'coaches' : 'players'} in this roster.
            Add now
          </BodyM>
        )}
      </BodyList>
    </Container>
  );
};
