import styled from 'styled-components';
import { color } from 'styles/styleVariables';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  background: ${COLORS.white};
  @media print {
    @page {
      size: landscape;
    }
    background: none !important;
  }

  .printButton {
    @media print {
      display: none;
    }
    cursor: pointer;
    position: absolute;
    right: 0;
    background-color: ${color.orange};
    font-weight: 800;
    padding: 10px;
    color: ${color.greyDark};
    width: 50px;
    display: flex;
    width: 50px;
    height: 50px;
    overflow: hidden;
    align-items: center;
    &:hover {
      width: auto;
      div {
        display: block;
      }
    }
    div {
      display: none;
    }
  }
`;

export const Content = styled.div`
  padding: 50px;
  color: ${COLORS.grey500};
`;

const font = {
  druk: 'Druk Wide Cyr',
  montserrat: 'Montserrat, sans-serif',
};

export const PrintWrapper =
  styled.div <
  { isMultiple: boolean } >
  `
  ${(props) => props.isMultiple && 'border-bottom: 1px solid #000;'};
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;

  font-weight: 400;
  @media print {
    .pagebreak {
      page-break-before: always;
    } /* page-break-after works, as well */
  }
  .top {
    width: 100%;
    display: flex;
    .box-tournament,
    .box-club {
      width: 200px;
      .wrapper {
        padding: 20px;
        border-radius: 10px;
        border: 2px solid ${color.greyDark};

        .top-box {
          .title {
            .type {
              font-size: 10px;
            }
            .name {
              font-family: ${font.druk};
              font-size: 12px;
            }
          }
        }
        .logo {
          width: 100px;
          height: 100px;
          border-radius: 80px;
          border: 1px solid #ccc;
          margin: 0 auto;
          margin-top: 20px;
          background-color: ${color.greyDark};
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          align-items: center;
          .img {
            width: 70%;
            margin: 0 auto;

            img {
              width: 100%;
            }
          }
        }
      }
    }
    .box-info {
      text-align: center;
      padding: 10px;
      flex: 1;
      .table {
        text-align: left;
        display: grid;
        grid-column-gap: 5px;
        grid-template-columns: 1fr 2fr;
        width: 80%;
        margin: 20px auto;
      }
    }
  }
`;
export const PlayersTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;
export const PlayerCard = styled.div`
  padding: 10px;
  border-radius: 10px;
  display: flex;
  .number {
    font-weight: 600;
    font-size: 30px;
    padding: 0 10px;
  }
  .image {
    border-radius: 5px;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
    }
  }
`;
