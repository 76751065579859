import styled from 'styled-components';
import { Modal } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 360px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.grey600};

  h3 {
    margin: 0 !important;
  }
`;

export const Container = styled.div`
  padding-top: 16px;
`;

export const FormInput = styled.input`
  width: 100%;
  height: 40px;
  background-color: black;
  border: 1px solid black;
  border-radius: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 12px;
  margin-top: 16px;

  button {
    min-width: 120px;
    min-height: 40px;
    padding: 8px 16px;
  }
`;

export const ConfirmFilledButton =
  styled(FilledButton) <
  { $disabled: boolean } >
  `
  background-color: ${COLORS.brandPrimary} !important;

  ${(props) =>
    props.$disabled &&
    `
    background-color: ${COLORS.grey500} !important;
    border-color: ${COLORS.grey500};
    color: ${COLORS.grey900};
    pointer-events: none;
  `}

  &:hover {
    background-color: ${COLORS.brandPrimary} !important;
    color: ${COLORS.grey900};
    border-color: ${COLORS.brandPrimary};
  }
`;

export const CloseOutlinedButton = styled(OutlinedButton)`
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
  }
`;
