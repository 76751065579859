import styled from 'styled-components';
import { BodyLBold, BodyXLBold, COLORS } from 'styles/v3/variables';

export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

export const StyledGamesContainer = styled(BodyXLBold)`
  width: 100%;
  color: ${COLORS.grey500};
  margin-bottom: 10px;
`;

export const TableContainer = styled.div`
  position: relative;
  padding-left: 45px;
  height: 600px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledDays = styled.div`
  padding-left: 40px;

  font-size: 14px;
  font-weight: bold;
  text-align: center;

  div {
    &:first-child {
      margin-bottom: 20px;
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .item {
    padding: 10px 0px;
    border-bottom: 1px solid #000;
  }

  .active {
  }
`;

export const StyledDay =
  styled(BodyLBold) <
  { active: boolean } >
  `
  color: ${({ active }) => (active ? '#cafc01' : '#fff')};
  border-bottom: ${({ active }) => (active ? '4px solid #cafc01' : 'none')};
  padding-bottom: 8px;
`;

export const HeaderCourts =
  styled.div <
  { columns: number } >
  `
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  display: grid;
  position: relative;

  .item {
    border-right: 1px solid #bdc3c7;
  }
`;

export const ControllButtons = styled.div`
  display: flex;
  column-gap: 20px;

  button {
    padding: 6px 15px;
    background-color: ${COLORS.grey500};
    color: ${COLORS.white};
    border-radius: 10px;
    cursor: pointer;

    &:disabled {
      cursor: default;
      background-color: rgba(143, 145, 138, 0.3);
    }
  }
`;

export const StyledInfoTooltip = styled.div`
  background-color: ${COLORS.white};
  color: #5c5c5c;
  position: absolute;
  display: none;
  min-width: 270px;
  height: 100px;
  z-index: 10;
`;

export const StyledTooltipContainer =
  styled.div <
  { variant: string } >
  `
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 10;
  font-size: 12px;
  font-family: 'Boxed Book';
  flex-direction: column;
  column-gap: 10px;
  div {
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      font-weight: bold;
      color: #5c5c5c;
    }
  }

  ${({ variant }) =>
    variant === 'arrow_down_left' &&
    `
      &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid white;
      left: 10px;

      bottom: -16px;
    }
  `}

  ${({ variant }) =>
    variant === 'arrow_down_rigth' &&
    `
      &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid white;
      right: 10px;

      bottom: -16px;
    }
  `}

  ${({ variant }) =>
    variant === 'arrow_up_left' &&
    `
      &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid white;
      left: 10px;

      top: -16px;
    }
  `}

    ${({ variant }) =>
      variant === 'arrow_up_right' &&
      `
      &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid white;
      right: 10px;

      top: -16px;
    }
  `}
`;

export const ErrorStyledInfoTooltip = styled(StyledInfoTooltip)`
  height: 70px;
  width: 230px;
  background-color: #ff2c2c;
  color: ${COLORS.white};
`;

export const ErrorStyledTooltipContainer = styled(StyledTooltipContainer)`
  display: flex;
  column-gap: 10px;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px 20px;
  font-size: 14px;
  opacity: 1;

  p {
    margin: 0;
    span {
      font-weight: 700;
    }
  }

  ${({ variant }) =>
    variant === 'arrow_down_left' &&
    `&:before {
      border-top: 16px solid #ff2c2c;
    }
  `}

  ${({ variant }) =>
    variant === 'arrow_down_rigth' &&
    `
      &:before {
      border-top: 16px solid #ff2c2c;
    }
  `}

  ${({ variant }) =>
    variant === 'arrow_up_left' &&
    `
      &:before {
      border-bottom: 16px solid #ff2c2c;
    }
  `}

    ${({ variant }) =>
    variant === 'arrow_up_right' &&
    `
      &:before {
      border-bottom: 16px solid #ff2c2c;
    }
  `}
`;

export const DaysTableContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 17px;
  padding: 40px 15px 40px 10px;
  border-radius: 10px;
  border: 1px solid black;
  -webkit-box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.75);
`;
