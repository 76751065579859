import { useQuery } from '@tanstack/react-query';
import ProductsServices from 'services/Products/ProductsServices';

export function useGetProductById(id?: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-product-by-id', id],
    () => ProductsServices.getProductById(id),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
