import styled from 'styled-components';

import InputText from 'components/v3/Forms/InputText/InputText';
import IconButton from 'components/v3/Buttons/IconButton';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderInformationsContainer = styled.div``;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 12px;
`;

export const EditFormWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const EditForm = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const EditInputField = styled(InputText)`
  min-height: 51px;
  min-width: 300px;
`;

export const EditRosterConfirm = styled(IconButton)`
  background-color: ${COLORS.brandPrimary};

  &[disabled] {
    background-color: ${COLORS.grey800};
  }
`;

export const AvatarEventInformation = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: ${toRem(8)} ${toRem(16)} ${toRem(8)} ${toRem(8)};
  background-color: ${COLORS.grey800};
  border-radius: ${toRem(121)};
  gap: ${toRem(8)};
`;

export const EventImage = styled.div`
  width: ${toRem(24)};
  height: ${toRem(24)};
  border-radius: ${toRem(24)};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(24)};
    height: ${toRem(24)};
  }
`;

export const RulesInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(10)};
  padding-top: ${toRem(26)};
`;

export const Rules = styled.div`
  display: flex;
  gap: ${toRem(10)};
`;

export const ButtonsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const LocksContainer = styled.div`
  display: flex;
  padding: ${toRem(16)};
  gap: ${toRem(5)};

  background-color: ${COLORS.grey900};
  border-radius: ${toRem(16)};
`;

export const EditBt = styled.button`
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
  font-size: ${toRem(12)};
  cursor: pointer;
  background-color: ${COLORS.grey800};
  padding: ${toRem(6)} ${toRem(12)};
  border-radius: ${toRem(16)};
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  font-family: 'Boxed Regular';
  border-style: none;
  text-transform: uppercase;
  transition-duration: 300ms;

  &:hover {
    background-color: ${COLORS.brandPrimary};
    color: ${COLORS.grey900};
  }

  .i-icon {
    font-size: 0;
  }
`;
