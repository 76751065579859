import React, { FC, memo, useCallback, useMemo } from 'react';

import { IFutsalEventView, IRosterUser } from 'services/v3/Match/interfaces';
import { FutsalAction } from 'services/v3/Match/enums';

import { TeamVenueType } from 'pages/V3/GamesControl/enums';

import { PenaltyState } from './enums';

import * as S from './styles';

const AVAILABLE_PENALTYS = 5;

interface IPenaltyShootoutProps {
  penaltyShootout: Maybe<IFutsalEventView[]>;
  homeTeamPenaltiShootout: number;
  awayTeamPenaltiShootout: number;
  homePlayers: Maybe<IRosterUser[]>;
  awayPlayers: Maybe<IRosterUser[]>;
  className?: string;
}

export const PenaltyShootout: FC<IPenaltyShootoutProps> = memo(
  ({
    homeTeamPenaltiShootout,
    awayTeamPenaltiShootout,
    penaltyShootout,
    homePlayers,
    awayPlayers,
    className,
  }) => {
    const getPlayerNumber = useCallback(
      (userId: string, players: Maybe<IRosterUser[]>) => {
        return players?.find((player) => player.id === userId)?.number || '-';
      },
      []
    );

    const homePenaltyShootout = useMemo(() => {
      return (
        penaltyShootout?.filter(
          (penalty) => penalty.team === TeamVenueType.Home
        ) || null
      );
    }, [penaltyShootout]);

    const awayPenaltyShootout = useMemo(() => {
      return (
        penaltyShootout?.filter(
          (penalty) => penalty.team === TeamVenueType.Away
        ) || null
      );
    }, [penaltyShootout]);

    const getPenaltyResults = useCallback(
      (players: Maybe<IRosterUser[]>, action: Maybe<IFutsalEventView[]>) => {
        if (action && action.length <= 5) {
          return Array.from(Array(AVAILABLE_PENALTYS)).map((_, index) => {
            const event = action?.[index];

            if (event) {
              return (
                <S.GoalItem
                  $state={
                    event.action === FutsalAction.PK_GOAL
                      ? PenaltyState.Goal
                      : PenaltyState.Miss
                  }
                >
                  {getPlayerNumber(event.userId, players)}
                </S.GoalItem>
              );
            }

            return <S.GoalItem $state={PenaltyState.Default} />;
          });
        }

        return action?.map((event) => (
          <S.GoalItem
            $state={
              event.action === FutsalAction.PK_GOAL
                ? PenaltyState.Goal
                : PenaltyState.Miss
            }
          >
            {getPlayerNumber(event.userId, players)}
          </S.GoalItem>
        ));
      },
      [getPlayerNumber]
    );

    return (
      <S.Container className={className}>
        <S.RosterUserListWrapper>
          <S.RosterUserList>
            {getPenaltyResults(homePlayers, homePenaltyShootout)}
          </S.RosterUserList>
        </S.RosterUserListWrapper>
        <S.Score>
          <span>{homeTeamPenaltiShootout}</span>x
          <span>{awayTeamPenaltiShootout}</span>
        </S.Score>
        <S.RosterUserListWrapper className='is-right'>
          <S.RosterUserList className='is-right'>
            {getPenaltyResults(awayPlayers, awayPenaltyShootout)}
          </S.RosterUserList>
        </S.RosterUserListWrapper>
      </S.Container>
    );
  }
);

PenaltyShootout.displayName = 'PenaltyShootout';
