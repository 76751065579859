import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { BodyS } from 'styles/v3/variables';
import { User } from 'models/User/UserModel';
import DateUtil from 'util/DateUtil';
import { DocumentStatusField } from '../DocumentStatusField';
import { ViewMember } from '../ViewMember';

export const columns: ColumnsType<User[]> = [
  {
    title: 'First Name',
    dataIndex: ['firstName'],
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Gender',
    dataIndex: ['gender'],
    render(value) {
      return (
        <BodyS $color='grey400'>{value === 'MALE' ? 'Boy' : 'Girl'}</BodyS>
      );
    },
  },
  {
    title: 'Birth Year',
    dataIndex: ['birthDate'],
    render(value) {
      return <BodyS $color='grey400'>{DateUtil.getUserAge(value)}</BodyS>;
    },
  },
  {
    title: 'Xp',
    dataIndex: ['xp'],
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Document Status',
    render(referee) {
      return (
        <DocumentStatusField
          permission='REFEREE'
          ageStatus={referee.ageVerified}
          photoStatus={referee.photoVerified}
          safeSportStatus={referee.documentationVerified}
        />
      );
    },
  },
  {
    title: '',
    render(referee) {
      return <ViewMember {...referee} />;
    },
  },
];
