import { Attention } from '@icon-park/react';
import React, { forwardRef, useMemo } from 'react';
import { colorGroup, stepperIntervals } from './lib';
import { StyledGamesContainer } from './styles';
import { UnscheduledMatchProps } from './types';

const UnscheduledMatches = forwardRef<HTMLDivElement, UnscheduledMatchProps>(
  (
    {
      unscheduledMatch,
      errorElement,
      divisionColors,
      scheduleRules,
      getAwayTeamName,
      getHomeTeamName,
      getMatchTitle,
    },
    ref
  ) => {
    const matches = useMemo(() => {
      return unscheduledMatch?.map((match) => {
        const lockedIco =
          colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
            ? 'locked_item_white'
            : 'locked_item_black';
        const unlockedIco =
          colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
            ? 'unlocked_item_white'
            : 'unlocked_item_black';
        const icon = match.gridLocked ? lockedIco : unlockedIco;

        return {
          ...match,
          class: icon,
        };
      });
    }, [unscheduledMatch]);

    return (
      <div ref={ref} className='setupMatches'>
        <StyledGamesContainer>
          Unscheduled games{' '}
          <span style={{ fontSize: '15px' }}>(Drag and Drop)</span>
        </StyledGamesContainer>
        {matches.length === 0 && (
          <div className='empty-games'>
            <Attention style={{ marginRight: '10px' }} />
            No games to schedule
          </div>
        )}

        {matches.length > 0 && (
          <div className='unscheduled-grid'>
            {matches.map((match) => {
              return (
                <div
                  key={`unsceduled-match-${match.matchId}`}
                  id={`match-${match.matchId}`}
                  className='dragNDrop grid-stack-item'
                  gs-w={1}
                  gs-h={
                    (match.sourceType === 'POOL'
                      ? scheduleRules.poolGamesLength
                      : scheduleRules.bracketGamesLength) / stepperIntervals
                  }
                  style={{
                    display:
                      errorElement?.id !== `match-${match.matchId}`
                        ? 'block'
                        : 'none',
                  }}
                >
                  <div className='grid-stack-item-content'>
                    <div
                      id={match.matchId}
                      className={`widget card ${match.class}`}
                      style={{
                        backgroundColor: divisionColors[match.ageDivisionId],
                        color: colorGroup[divisionColors[match.ageDivisionId]], // update in the future
                      }}
                    >
                      <div>
                        <div className='title'>{getMatchTitle(match)}</div>
                        <div className='team'>{getHomeTeamName(match)}</div>
                        <div className='team'>{getAwayTeamName(match)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

UnscheduledMatches.displayName = 'UnscheduledMatches';

export default UnscheduledMatches;
