import React, { memo, FC, useCallback } from 'react';

import { BodyM } from 'styles/v3/variables';
import { removeMatchInfoFromLocalStorage } from 'pages/V3/GamesControl/utils';

import * as S from './styles';

interface ILeaveConfirmModalProps {
  visible: boolean;
  onLeave: () => void;
  onCancel: () => void;
}

export const LeaveConfirmModal: FC<ILeaveConfirmModalProps> = memo(
  ({ visible, onLeave, onCancel }) => {
    const handleLeaveGameControl = useCallback(() => {
      onLeave();
      removeMatchInfoFromLocalStorage();
    }, [onLeave]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={onCancel}
      >
        <S.Container>
          <BodyM>
            This game is not finished. If you leave you will lost the time
            details. Are you sure want to leave?
          </BodyM>
          <S.ButtonsGroup>
            <S.LeaveButton isUpper onClick={handleLeaveGameControl}>
              Leave
            </S.LeaveButton>
            <S.CancelButton isUpper onClick={onCancel}>
              Cancel
            </S.CancelButton>
          </S.ButtonsGroup>
        </S.Container>
      </S.StyledModal>
    );
  }
);

LeaveConfirmModal.displayName = 'LeaveConfirmModal';
