import React, { FunctionComponent } from 'react';
import { COLORS } from 'styles/v3/variables';
import { MatchV3Model } from 'models/Match/MatchModel';
import { BracketGame } from './components/BracketGame';
import { BracketColumn } from './styles';

export interface BracketRenderProps {
  bracketId: string;
  matches: MatchV3Model[];
  onUpdate?: () => void;
  handleOpenEditModal?: (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => void;
}

export const BracketRender: FunctionComponent<BracketRenderProps> = ({
  bracketId,
  matches,
  handleOpenEditModal,
}) => {
  return (
    <>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={matches[0]}
          to={{
            up: true,
            down: true,
            color: COLORS.supportError,
          }}
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={matches[0]}
          from={{
            down: true,
            color: COLORS.supportError,
          }}
          to={{
            color: COLORS.brandPrimary,
            down: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
        />
        <BracketGame
          bracketId={bracketId}
          match={matches[0]}
          from={{
            color: COLORS.supportError,
            up: true,
          }}
          to={{
            color: COLORS.brandPrimary,
            up: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={matches[0]}
          from={{
            up: true,
            down: true,
            color: COLORS.brandPrimary,
          }}
          to={{
            side: true,
            color: COLORS.brandPrimary,
          }}
          handleOpenEditModal={handleOpenEditModal}
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={matches[0]}
          from={{
            side: true,
            color: COLORS.brandPrimary,
          }}
          handleOpenEditModal={handleOpenEditModal}
        />
      </BracketColumn>
    </>
  );
};
