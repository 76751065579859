import { useQuery } from '@tanstack/react-query';
import { MembersFilterTypes } from 'models/v3/Club/ClubModel';
import ClubService from 'services/v3/Clubs/ClubService';

type UseGetClubMembersByIdProps = {
  id?: string,
  size?: number,
  page?: number,
  type?: MembersFilterTypes,
};

export const useGetClubMembersById = ({
  id,
  page,
  size,
  type,
}: UseGetClubMembersByIdProps) => {
  const response = useQuery(['clubs-members', id, page, type], () =>
    ClubService.getClubsMembersById(id, size, page, type)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
