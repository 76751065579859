import React from 'react';

import { DivisionsEvent } from './components/DivisionsEvent';
import * as S from './styles';

type FormatProps = {
  id: string,
};

export const Format = ({ id }: FormatProps) => {
  return (
    <S.Container>
      <DivisionsEvent eventId={id} />
    </S.Container>
  );
};
