import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${toRem(24)};

  background-color: ${COLORS.grey900};
  border-radius: ${toRem(24)};

  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(28)};

  span {
    ::before {
      display: none;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-flow: wrap;
    justify-content: center;
    margin-bottom: ${toRem(16)};
  }
`;

export const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(12)};
`;

export const ClubIcon = styled.div`
  width: ${toRem(32)};
  height: ${toRem(32)};

  overflow: hidden;

  background-color: ${COLORS.brandPrimary};
  border-radius: 100%;

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(32)};
    height: ${toRem(32)};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${toRem(28)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;

export const CoachesAndPlayersConainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;
