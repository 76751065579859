import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { CloseOne, CheckOne } from '@icon-park/react';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(16)};
`;

export const DenyButton = styled(CloseOne)`
  cursor: pointer;
  svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.supportError};
    }
  }
`;

export const ApproveButton = styled(CheckOne)`
  cursor: pointer;
  svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.brandPrimary};
    }
  }
`;
