/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ScheduleAccordionProps } from 'pages/V3/Schedule/types/scheduleAccordion';
import { ScheduleAccordionHeader } from './ScheduleAccordionHeader';
import { Container } from './styles';
import { ScheduleAccordionContent } from './ScheduleAccordionContent';

export const ScheduleAccordion = ({
  courtName,
  gamesCount,
  match,
}: ScheduleAccordionProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Container>
      <ScheduleAccordionHeader
        onClick={() => setIsExpanded(!isExpanded)}
        title={courtName}
        gamesCount={gamesCount}
        expanded={isExpanded}
      />
      {isExpanded &&
        match.map((item, index) => (
          <ScheduleAccordionContent {...item} key={index} />
        ))}
    </Container>
  );
};
