import * as React from 'react';
import ColorPicker from 'components/base/ColorPicker/ColorPicker';
import { BodyM } from 'styles/v3/variables';
import InputText, { InputTextProps } from '../InputText/InputText';

import * as S from './styles';

interface InputColorProps extends InputTextProps {
  defaultColor: string;
  showHex?: boolean;
}

const InputColor: React.FC<InputColorProps> = ({
  defaultColor,
  showHex = true,
  placeholder,
  ...props
}) => {
  const [color, setColor] = React.useState<string>(defaultColor);
  React.useEffect(() => {
    if (defaultColor) setColor(defaultColor);
  }, [defaultColor]);

  return (
    <ColorPicker
      className='color-picker'
      selectedColor={color}
      onSelectColor={(c) => setColor(c)}
    >
      <S.InputWrapper>
        <InputText
          {...props}
          autoComplete='false'
          placeholder={color ? '' : placeholder}
          label='Pick a color'
          value={color}
        />
        <S.SelectedColor $color={color} />
        {showHex && <BodyM>{color}</BodyM>}
      </S.InputWrapper>
    </ColorPicker>
  );
};

export default InputColor;
