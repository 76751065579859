import React, { useState } from 'react';

import { BodyM, TitleH3 } from 'styles/v3/variables';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { useHistory } from 'react-router-dom';
import { B2bDraftEvent } from 'redux/v3/b2bDraftEvent/types';
import B2bDraftEventService from 'services/v3/B2bDraftEvent/B2bDraftEventService';
import useScreenType from 'hooks/useScreenType';
import * as S from './styles';

type PaymentCardProps = {
  props: any;
  userEmail: string;
  eventData: B2bDraftEvent;
};

export const PaymentCard = ({
  props,
  userEmail,
  eventData,
}: PaymentCardProps) => {
  const history = useHistory();

  const screenType = useScreenType();

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const paymentStatus =
    new URLSearchParams((props as any).location.search).get('success') ===
    'true'
      ? 'success'
      : 'error';

  const handleCardButton = () => {
    if (isLoadingPayment) {
      return;
    }
    if (paymentStatus === 'success') {
      history.push('/v3/events?tab=created-by-me');
    } else {
      handleBuyEvent();
    }
  };

  // TODO: update only the payment link
  const handleBuyEvent = async () => {
    setIsLoadingPayment(true);

    if (!eventData?.selectedEvent) {
      history.push('/v3/draft-event/general-event');
      return;
    }

    try {
      const paymentRequest = await B2bDraftEventService.buyEvent({
        eventData,
        userEmail,
      });

      const {
        data: { paymentUrl },
      } = paymentRequest;

      if (paymentUrl) {
        window.location.href = paymentUrl;
      }
      setIsLoadingPayment(false);
    } catch (e) {
      setIsLoadingPayment(false);
    }
  };

  const bankCardImgSrc = `/assets/imgs/icons/bank-card-${paymentStatus}.svg`;

  return (
    <S.CardContainer $isSuccess={paymentStatus === 'success'}>
      {screenType.isMobile ? (
        <S.ImageWrapperWithTitle>
          <S.Image src={bankCardImgSrc} alt='bank card' />
          <TitleH3>
            {paymentStatus === 'success'
              ? 'Payment was successful'
              : 'something went wrong'}
          </TitleH3>
        </S.ImageWrapperWithTitle>
      ) : (
        <S.Image src={bankCardImgSrc} alt='bank card' />
      )}
      <S.Container>
        {!screenType.isMobile && (
          <TitleH3>
            {paymentStatus === 'success'
              ? 'Payment was successful'
              : 'something went wrong'}
          </TitleH3>
        )}
        <BodyM>
          {paymentStatus === 'success'
            ? 'You will be redirected soon. If you were not redirected, just click the button below.'
            : 'Please make sure your payment information is correct and try again'}
        </BodyM>
        <FilledButtonWithIcon color='dark-white' onClick={handleCardButton}>
          {paymentStatus === 'success'
            ? 'Go to my events'
            : 'Continue to payment'}
        </FilledButtonWithIcon>
      </S.Container>
    </S.CardContainer>
  );
};
