import React, { FC, memo, useCallback, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import type { Value } from 'react-multi-date-picker';

import * as S from './styles';

interface IDayPickerFieldProps {
  errorMessage: string;
  defaultSelectedDays: Value[];
  onChange: (days: Value[]) => void;
}

export const DayPickerField: FC<IDayPickerFieldProps> = memo(
  ({ errorMessage, defaultSelectedDays, onChange }) => {
    const [selected, setSelected] = useState<Value[]>(defaultSelectedDays);

    const handleChangeDayPicker = useCallback((days: Value[]) => {
      setSelected(days);
    }, []);

    const handleBlurDayPicker = useCallback(() => {
      onChange(selected);
    }, [selected, onChange]);

    return (
      <S.DayPickerContainer>
        <S.DayPickerForm>
          <DatePicker
            multiple
            shadow={false}
            value={selected}
            placeholder='Configure event days'
            inputClass='date-picker-input'
            format='DD.MM.YYYY'
            onChange={handleChangeDayPicker}
            onClose={handleBlurDayPicker}
          />
          <S.CalendarIcon fill='#a7a8a1' />
        </S.DayPickerForm>
        {!!errorMessage.length && (
          <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        )}
      </S.DayPickerContainer>
    );
  }
);

DayPickerField.displayName = 'DayPickerField';
