import React from 'react';
import { BodyM } from 'styles/v3/variables';
import { ButtonSelect, Container } from './styles';

interface BottomCalendarProps {
  listDay: {
    day: string | unknown;
    selected: boolean;
    onSelected: () => void;
  }[];
}

export const BottomCalendar = ({ listDay }: BottomCalendarProps) => {
  return (
    <Container>
      {listDay.map((item) => (
        <ButtonSelect
          key={item.day as string}
          active={item.selected}
          onClick={item.onSelected}
        >
          <BodyM $color={item.selected ? 'grey50' : 'grey300'}>
            {item.day as string}
          </BodyM>
        </ButtonSelect>
      ))}
    </Container>
  );
};
