import React, { useState } from 'react';
import { Pagination } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetClubMembersById } from 'hooks/v3/clubs/useGetClubMembersById/useGetClubMembersById';
import { MembersFilterTypes } from 'models/v3/Club/ClubModel';
import { Spinner } from 'components/v3/Spinner/Spinner';
import { TitleH4 } from 'styles/v3/variables';
import { AvatarWithName } from '../AvatarWithName/AvatarWithName';
import * as S from './styles';

export const Members = () => {
  const { clubId } = useParams<{ clubId: string }>();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: response, isLoading } = useGetClubMembersById({
    id: clubId,
    page: currentPage - 1,
    type: MembersFilterTypes.MEMBERS,
  });

  const members = response?.content || [];
  const hasMembers = members.length > 0;

  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.MembersWrapper>
      {hasMembers ? (
        <>
          <S.AvatarWrapper>
            {members.map((member) => (
              <AvatarWithName
                key={member.id}
                photo={member.photo}
                name={member.firstName}
              />
            ))}
          </S.AvatarWrapper>
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            showSizeChanger={false}
            total={response?.total}
            onChange={setCurrentPage}
            pageSize={response?.pageSize ?? 10}
          />
        </>
      ) : (
        <S.FallbackMessageWrapper>
          <TitleH4 $color='grey500'>
            Oops! Looks like there aren&apos;t any members in this club yet. Are
            you the first to join?
          </TitleH4>
        </S.FallbackMessageWrapper>
      )}
    </S.MembersWrapper>
  );
};
