import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: ${toRem(16)};
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: ${toRem(4)};

  h4 {
    color: ${COLORS.white};
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(4)};

  .i-icon svg {
    width: ${toRem(12)};
    height: ${toRem(12)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey800};
    }
  }
`;

export const AvataWrapper = styled.div`
  flex: 1;
`;
