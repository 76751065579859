import React, { useState } from 'react';
import { BodyL, BodyS, COLORS } from 'styles/v3/variables';
import { Dot } from '@icon-park/react';
import { Pools } from 'admin/models/AgeDivision';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import EventService from 'services/v3/Event/EventService';
import { AgeRulesEnums } from "pages/V3/setupEvent/Divisions/constants";
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import {
  Container,
  ContainerSpace,
  DivisionInformation,
  DivisionText,
  DivisionTextBox,
  DivisionTextWrapper,
  TagColor,
} from './styles';

interface EventDivisionProps {
  eventId: string;
  id: string;
  name: string;
  gender: 'MALE' | 'FEMALE' | 'COED';
  totalYears: number;
  firstYear: number;
  allowMixedTeams: boolean;
  bracketType: string;
  pools: Pools[];
  format: string;
  color: string;
  years: number[];
  rule:
    | AgeRulesEnums.EQUAL
    | AgeRulesEnums.YOUNGER
    | AgeRulesEnums.YOUNGER_WITH_MORE_ONE_YEAR
    | AgeRulesEnums.YOUNGER_WITH_MORE_TWO_YEAR;
}

export const formatOptions: OptionsType[] = [
  {
    value: 'Groups & Playoffs',
    label: 'Groups & Playoffs',
  },
];

export const DivisionSetFormat = ({
  id,
  name,
  gender,
  totalYears,
  firstYear,
  allowMixedTeams,
  bracketType,
  pools,
  format,
  color,
  rule,
  years,
  eventId
}: EventDivisionProps) => {
  const [selectedFormat, setSelectedFormat] = useState(format);

  const getGenderLabel = (): string => {
    if (gender === 'MALE') {
      return `Boys`;
    }
    if (gender === 'FEMALE') {
      return `Girls`;
    }
    return '';
  };

  const getAllowedOtherGender = (): string => {
    if (allowMixedTeams) {
      if (gender === 'MALE') {
        return `Allow Girls`;
      }
      if (gender === 'FEMALE') {
        return `Allow Boys`;
      }
    }
    return '';
  };

  const getYear = (): string => {
    if (totalYears > 1) {
      return `${firstYear} ${totalYears}`;
    }
    return `${firstYear} Only`;
  };

  const handleFormatChange = (
    newValue: MultiValue<OptionsType> | SingleValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) {
      return;
    }

    const selectedOption = newValue as OptionsType;
    setSelectedFormat(String(selectedOption.value));
    EventService.updateDivision(eventId, {
      pools,
      id,
      years,
      gender,
      rule,
      format: String(selectedOption.value),
    });
  };

  return (
    <>
      <Container>
        <ContainerSpace>
          <TagColor style={{ backgroundColor: color }} />
          <DivisionInformation>
            <DivisionText>
              <BodyL>{name}</BodyL>
              <DivisionTextWrapper>
                <DivisionTextBox>
                  <BodyS>{getGenderLabel()}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  <Dot fill={COLORS.grey800} />
                  <BodyS>{getYear()}</BodyS>
                </DivisionTextBox>
                <DivisionTextBox>
                  <Dot fill={COLORS.grey800} />
                  <BodyS>{getAllowedOtherGender()}</BodyS>
                </DivisionTextBox>
              </DivisionTextWrapper>
            </DivisionText>
            <Select
              name='format'
              placeholder='Select Format'
              defaultValue={formatOptions.find(
                (option) => option.value === format
              )}
              options={formatOptions}
              value={formatOptions.find(
                (option) => option.value === selectedFormat
              )}
              onChange={handleFormatChange}
            />
          </DivisionInformation>
        </ContainerSpace>
      </Container>
    </>
  );
};
