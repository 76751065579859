/* eslint-disable import/no-cycle */
import * as React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import PlanSelection from '../draftEvent/PlanSelection';
import GeneralEvent from '../draftEvent/GeneralEvent';
import Payment from '../draftEvent/Payment';
import LandingPage from '../LandingPage';
import V3Login from '../V3Login';
import Home from '../Home';
import RedirectToHome from '../Home/redirect';
import MyEvents from '../setupEvent/MyEvents/MyEvents';
import EditEvent from '../setupEvent/EditEvent';
import ChoosePlan from '../profile/UpgradeMembership/ChoosePlan/ChoosePlan';
import ReleaseTermsPage from '../profile/UpgradeMembership/ReleaseTerms/ReleaseTerms';
import CovidTermsPage from '../profile/UpgradeMembership/CovidTerms/CovidTerms';
import OrderSummary from '../profile/UpgradeMembership/OrderSummary/OrderSummary';
import UpgradeMembershipPayment from '../profile/UpgradeMembership/Payment/Payment';
import Profile from '../profile/Profile/Profile';
import NewUser from '../profile/NewUser/NewUser';
import UserCreated from '../profile/NewUser/UserCreated';
import Event from '../event/Event';
import Events from '../Events/Events';
import UserInfo from '../profile/CompleteProfile/UserInfo/UserInfo';
import LegalGuardian from '../profile/CompleteProfile/LegalGuardian/LegalGuardian';
import Address from '../profile/CompleteProfile/Address/Address';
import RefereeApplication from '../RefereeApplication';
import InformationScreen from '../RefereeApplication/InformationScreen';
import Documents from '../profile/CompleteProfile/Documents/Documents';
import V3Logout from '../V3Logout';
import CheckoutAsClubDirector from '../event/CheckoutAsClubDirector/CheckoutAsClubDirector';
import SignUpAsClubDirector from '../event/SignUpAsClubDirector/SignUpAsClubDirector';
import ClubDirectorPayment from '../event/ClubDirectorPayment/ClubDirectorPayment';
import Clubs from '../clubs/Clubs';
import FindClubs from '../clubs/Find';
import NewClub from '../clubs/New';
import PendingClub from '../clubs/Pending';
import EventManager from '../EventManager/EventManager';
import InviteClubs from '../Invites/Clubs/Clubs';
import InviteReferees from '../Invites/Referees/Referees';
import InviteEmail from '../Invites/Email/Email';
import ClubDashboard from '../ClubDashboard/ClubDashboard';
import Club from '../Club/Club';
import RosterEditPage from '../Roster/RosterEditPage';
import InviteMembersToClub from '../Invites/InviteMembersToClub/InviteMembersToClub';
import RosterDetails from '../Roster/RosterViewPage/components';
import MySettings from '../profile/MySettings/MySettings';
import Groups from '../EventManager/components/Groups';
import Notifications from '../Notifications';
import TeamPlacement from '../EventManager/components/TeamPlacement';
import FormatBrackets from '../setupEvent/FormatEvent/FormatBrackets';
import PrintAllTeamsList from '../Print/AllTeamsList/AllTeamsList';
import PrintAllRosters from '../Print/AllRosters/AllRosters';
import ScheduleByCourt from '../Print/ScheduleByCourt/ScheduleByCourt';
import Schedule from '../Schedule';
import ScheduleByTimePerLocation from '../Print/ScheduleByTimePerLocation/ScheduleByTimePerLocation';
import PrintAllBrackets from '../Print/AllBrackets/AllBrackets';
import PrintAllScoreSheet from '../Print/AllScoreSheet/AllScoreSheet';
import AllGames from '../Schedule/pages/AllGames';
import FindYourTeam from '../Schedule/pages/FindYourTeam';
import TeamView from '../Schedule/pages/TeamView';
import BracketResult from '../Schedule/pages/BracketResult';
import PrintAllGames from '../Schedule/pages/PrintAllGames';
import GamesControl from '../GamesControl/GamesControl';
import CompleteRegistration from '../CompleteRegistration/CompleteRegistration';

export const routes = [
  {
    path: '/v3',
    component: LandingPage,
    exact: true,
    private: false,
    layout: 'base',
    roles: undefined,
    userSelectedNotRequired: undefined,
  },
  {
    path: '/v3/login',
    component: V3Login,
    private: false,
    layout: 'base',
  },
  {
    path: '/v3/logout',
    component: V3Logout,
    private: false,
    layout: 'base',
  },
  {
    path: ['/v3/home', '/'],
    component: RedirectToHome,
    exact: true,
    private: false,
    layout: 'base',
  },
  {
    path: '/v3/home-page',
    component: Home,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/draft-event/plan-selection',
    component: PlanSelection,
    exact: true,
    private: false,
    layout: 'draftEvent',
  },
  {
    path: '/v3/draft-event/general-event',
    component: GeneralEvent,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/draft-event/payment',
    component: Payment,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/setup-event/my-events',
    component: MyEvents,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/setup-event/my-events/:eventId',
    component: EditEvent,
    exact: true,
    private: true,
    layout: 'editEvent',
  },
  {
    path: '/v3/event/:eventId/dashboard/brackets',
    component: FormatBrackets,
    exact: true,
    private: true,
    layout: 'editEvent',
  },
  {
    path: '/v3/profile/upgrade-membership',
    component: ChoosePlan,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/profile/upgrade-membership/release-terms',
    component: ReleaseTermsPage,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/profile/upgrade-membership/covid-terms',
    component: CovidTermsPage,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/profile/upgrade-membership/order-summary',
    component: OrderSummary,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/profile/upgrade-membership/payment',
    component: UpgradeMembershipPayment,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/profile/my-settings',
    component: MySettings,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/profile',
    component: Profile,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/referee-application',
    component: RefereeApplication,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/referee-application-status',
    component: InformationScreen,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/referee-application/:eventId',
    component: RefereeApplication,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/events',
    exact: true,
    private: true,
    component: Events,
    layout: 'setupEvent',
  },
  {
    path: '/v3/event',
    exact: true,
    private: true,
    component: Events,
    layout: 'setupEvent',
  },
  {
    path: '/v3/event/:eventId',
    exact: true,
    private: true,
    component: Event,
    layout: 'setupEvent',
    noPaddingOnMobile: true,
  },
  {
    path: '/v3/event',
    exact: true,
    private: true,
    component: Event,
    layout: 'setupEvent',
  },
  {
    path: '/v3/event/:eventId/(signup-as-club-director|signup-as-team-manager)',
    component: SignUpAsClubDirector,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path:
      '/v3/event/:eventId/(checkout-as-club-director|checkout-as-team-manager)',
    component: CheckoutAsClubDirector,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/event/:eventId/club-director-payment',
    component: ClubDirectorPayment,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/event/:eventId/signup-as-referee',
    component: RefereeApplication,
    exact: true,
    private: true,
    layout: 'profile',
  },
  {
    path: '/v3/user',
    component: NewUser,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/user/new',
    component: NewUser,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/user/:userId/created',
    component: UserCreated,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/user/:userId/info',
    component: UserInfo,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/user/:userId/legal-guardian',
    component: LegalGuardian,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/user/:userId/address',
    component: Address,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/user/:userId/documents',
    component: Documents,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  // {
  //   // TODO -- Delete, use dashboard instead
  //   path: '/v3/setup-event/my-events/:eventId/published',
  //   component: PublishedEvent,
  //   exact: true,
  //   private: true,
  //   layout: 'setupEvent',
  // },
  {
    path: '/v3/clubs',
    component: Clubs,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/clubs/find',
    component: FindClubs,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/clubs/new',
    component: NewClub,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/clubs/:rosterId/dashboard/roster',
    component: RosterDetails,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/clubs/:clubId/dashboard',
    component: ClubDashboard,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/clubs/:clubId/pending',
    component: PendingClub,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/event/:eventId/dashboard',
    component: EventManager,
    exact: true,
    private: true,
    layout: 'eventDashboard',
  },
  {
    path: '/v3/event/:eventId/games/:gameId/scoresheet',
    component: GamesControl,
    exact: true,
    private: true,
    layout: 'eventDashboard',
  },
  {
    // TODO - Create a roster page,
    // added this route to prevent error stuck loading page on F5 inside edit roster page
    path: '/v3/roster',
    component: Clubs,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/roster/:rosterId/edit',
    component: RosterEditPage,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/invites/clubs/:eventId',
    component: InviteClubs,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/invites/clubs/',
    component: InviteClubs,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/invites/referees/:eventId',
    component: InviteReferees,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/invites/referees/',
    component: InviteReferees,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/invites/email',
    component: InviteEmail,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/club/:clubId',
    component: Club,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/club/',
    component: Club,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/invites/members/club/:clubId',
    component: InviteMembersToClub,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/invites/members/club',
    component: InviteMembersToClub,
    exact: true,
    private: true,
    layout: 'draftEvent',
  },
  {
    path: '/v3/event/:eventId/dashboard/groups',
    component: Groups,
    exact: true,
    private: true,
    layout: 'editEvent',
  },
  {
    path: '/v3/event/dashboard/groups',
    component: Groups,
    exact: true,
    private: true,
    layout: 'editEvent',
  },

  {
    path: '/v3/notifications',
    component: Notifications,
    exact: true,
    private: true,
    layout: 'setupEvent',
  },
  {
    path: '/v3/team-placement/:eventId',
    component: TeamPlacement,
    exact: true,
    private: true,
    layout: 'editEvent',
  },
  // ENG-320 temporary disable Team placement
  // {
  //   path: '/v3/event/:eventId/dashboard/team-placement',
  //   component: TeamPlacement,
  //   exact: true,
  //   private: true,
  //   layout: 'editEvent',
  // },
  {
    path: '/v3/print/event',
    component: ScheduleByCourt,
    exact: true,
    private: true,
  },
  {
    path: '/v3/print/event/:eventId/schedule-by-court',
    component: ScheduleByCourt,
    exact: true,
    private: true,
    layout: 'print',
  },
  {
    path: '/v3/print/event/:eventId/schedule-by-time-per-location',
    component: ScheduleByTimePerLocation,
    exact: true,
    private: true,
    layout: 'print',
  },
  {
    path: '/v3/print/event/:eventId/all-teams',
    component: PrintAllTeamsList,
    exact: true,
    private: true,
    layout: 'print',
  },
  {
    path: '/v3/print/event/:eventId/all-rosters',
    component: PrintAllRosters,
    exact: true,
    private: true,
    layout: 'print',
  },
  {
    path: '/v3/print/event/:eventId/all-scoresheet',
    component: PrintAllScoreSheet,
    exact: true,
    private: true,
    layout: 'print',
  },
  {
    path: '/v3/print/event/:eventId/all-brackets',
    component: PrintAllBrackets,
    exact: true,
    private: true,
    layout: 'print',
  },
  {
    path: '/v3/event/:eventId/schedule',
    exact: true,
    private: false,
    component: Schedule,
    layout: 'base',
  },
  {
    path: '/v3/event/:eventId/schedule/see-all-games',
    exact: true,
    private: false,
    component: AllGames,
    layout: 'base',
  },
  {
    path: '/v3/event/:eventId/schedule/print-all-games',
    exact: true,
    private: true,
    component: PrintAllGames,
    layout: 'base-desktop',
  },

  {
    path: '/v3/event/:eventId/schedule/find-your-team',
    component: FindYourTeam,
    exact: true,
    private: false,
  },
  {
    path: '/v3/event/:eventId/schedule/team/:clubId',
    component: TeamView,
    exact: true,
    private: false,
  },
  {
    path: '/v3/event/:eventId/schedule/bracket-age-division',
    component: BracketResult,
    exact: true,
    private: false,
  },
  {
    path: '/v3/complete-registration',
    component: CompleteRegistration,
    layout: 'setupEvent',
    private: false,
  },
];

export const v3Routes = routes.sort((a, b) =>
  a.path.length < b.path.length ? 1 : -1
);

interface PrivateRouteProps {
  auth: Keycloak.KeycloakInstance | undefined;
}

const V3PublicRoutes: React.FunctionComponent<PrivateRouteProps> = (props) => {
  return (
    <>
      {(routes as any[])
        .filter((r) => !r.private)
        .map((route, i) => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={() => <route.component />}
          />
        ))}
    </>
  );
};

const V3PrivateRoutes: React.FunctionComponent<PrivateRouteProps> = (props) => {
  const { auth } = props;
  return (
    <>
      {(routes as any[])
        .filter((r) => r.private)
        .map((route, i) => (
          <PrivateRoute
            key={route.path}
            auth={auth}
            userSelectedNotRequired
            exact={route.exact ? route.exact : false}
            path={route.path}
            render={() => {
              return <route.component auth={auth} />;
            }}
          />
        ))}
    </>
  );
};

export { V3PublicRoutes, V3PrivateRoutes };
