import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div``;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;

  gap: ${toRem(12)};

  padding: ${toRem(16)} ${toRem(20)} ${toRem(32)} ${toRem(20)};
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: ${toRem(12)};
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(12)};
`;

export const ClubInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(8)};

  span {
    min-width: ${toRem(28)};
  }
`;

export const Tag = styled.div`
  width: fit-content;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  gap: ${toRem(4)};

  padding: ${toRem(6)} ${toRem(12)};
  border-radius: ${toRem(24)};
  background-color: ${COLORS.grey800};

  span {
    margin-top: ${toRem(4)};
  }
`;

export const BottomContainer = styled.div`
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;

  z-index: 1;
`;
