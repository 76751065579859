import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${toRem(60)} 1fr ${toRem(52)};
  column-gap: ${toRem(16)};
  background: ${COLORS.grey950};
  cursor: pointer;
  &:hover {
    background: ${COLORS.grey850};
  }

  align-content: center;
  align-items: center;

  padding: 8px 0;

  border-bottom: 1px solid ${COLORS.grey800};
`;

export const Icon = styled.img`
  margin-left: ${toRem(25)};

  border-radius: 50%;

  width: ${toRem(32)};
  height: ${toRem(32)};
`;

export const PrintButtonContainer = styled.div`
  margin-right: ${toRem(32)};

  display: flex;
  width: ${toRem(40)};
  height: ${toRem(40)};
  align-items: center;
  justify-content: center;

  ${Container}:hover & {
    border-radius: ${toRem(8)};
    background: ${COLORS.grey800};
  }

  cursor: pointer;

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey50};
    }
  }
`;
