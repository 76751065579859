import React, { memo, FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';

import { MatchStatus } from 'models/Match/MatchModel';

import { OverviewReportModal } from '../OverviewReportModal/OverviewReportModal';

import * as S from './styles';

interface IControlButtonsProps {
  eventId: string;
  matchId: string;
  report: string;
  status: MatchStatus;
}

export const ControlButtons: FC<IControlButtonsProps> = memo(
  ({ eventId, matchId, report, status }) => {
    const history = useHistory();

    const [openedOverviewReportModal, setOpenedOverviewReportModal] =
      useState(false);

    const handleTriggerOverviewReportModal = useCallback(
      (isOpened: boolean) => () => {
        setOpenedOverviewReportModal(isOpened);
      },
      []
    );

    const handleClickPlay = () => {
      history.push(`/v3/event/${eventId}/games/${matchId}/scoresheet`);
    };

    return (
      <S.ButtonsWrapper>
        {!!report.length && (
          <div
            role='presentation'
            className='bt-action i-icon-list'
            onClick={handleTriggerOverviewReportModal(true)}
          />
        )}
        {status === MatchStatus.UNSCHEDULED && (
          <Tooltip
            placement='top'
            color='gold'
            title='Add the game to the schedule to have access to game control'
          >
            <div
              role='presentation'
              className='bt-action i-icon-play inactive'
            />
          </Tooltip>
        )}
        {status !== MatchStatus.UNSCHEDULED && (
          <div
            role='presentation'
            className='bt-action i-icon-play'
            onClick={handleClickPlay}
          />
        )}
        <OverviewReportModal
          visible={openedOverviewReportModal}
          report={report}
          onClose={handleTriggerOverviewReportModal(false)}
        />
      </S.ButtonsWrapper>
    );
  }
);

ControlButtons.displayName = 'ControlButtons';
