import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

// Plan Selection Page
export const PlanSelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  gap: ${toRem(16)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;

    gap: ${toRem(50)};

    margin-bottom: ${toRem(40)};
  }
`;

export const PlanSelectionText = styled.div`
  max-width: 384px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    align-items: center;

    gap: ${toRem(30)};

    flex-direction: column;
  }
`;

export const PlanSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  max-width: 714px;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;

    gap: ${toRem(72)};
  }
`;

// Boosters Selection Page
export const BoostersSelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  gap: ${toRem(30)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;

    margin-bottom: ${toRem(42)};
  }
`;

export const BoostersSelectionText = styled.div`
  max-width: ${toRem(384)};

  display: flex;
  flex-direction: column;

  gap: ${toRem(30)};

  button {
    &:not(:first-child) {
      margin-top: ${toRem(30)};
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    max-width: none;
  }
`;

export const BoostersSelectionItems = styled.div`
  width: 100%;
  max-width: 690px;

  h4 {
    margin-bottom: ${toRem(5)};
  }
`;

export const BoostersSelectionList = styled.div`
  margin-top: ${toRem(40)};

  .booster-item {
    & + .booster-item {
      margin-top: 1.5rem;
    }
  }
`;

// General Event Page
export const GeneralEventWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  gap: ${toRem(32)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;

    margin-bottom: ${toRem(28)};
  }
`;

export const GeneralEventFormWrapper = styled.div`
  max-width: 486px;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: ${toRem(48)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;

export const GeneralEventForm = styled.form`
  legend {
    margin: ${toRem(6)} 0 ${toRem(24)};
  }

  fieldset {
    & + fieldset {
      margin-top: 1.5rem;
    }
    .general-event-info {
      margin: ${toRem(4)} 0 ${toRem(24)} ${toRem(16)};
    }
    .general-event-input {
      & + .general-event-input {
        margin-top: 1.5rem;
      }
    }
    .booster-item {
      & + .booster-item {
        margin-top: ${toRem(24)};
      }
    }
  }
`;

export const GeneralEventFieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;

  &.style-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    &.style-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }
`;

export const GeneralEventSummary = styled.div`
  width: 100%;
  max-width: 384px;

  .summary-subtitle {
    margin-bottom: 1rem;
  }
  .trophy-image {
    max-width: 50px;
  }
  .general-event-selected {
    margin: ${toRem(14)} 0 ${toRem(30)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    max-width: 486px;
  }
`;

export const GeneralEventSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${toRem(30)};

  .summary-title {
    margin: ${toRem(30)} 0 ${toRem(4)};
  }
`;

export const GeneralEventSummaryBody = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${toRem(10)};
`;

export const GeneralEventSummaryMapWrapper = styled.div`
  max-width: 324px;
  max-height: 110px;
  display: flex;
  margin-bottom: ${toRem(30)};
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    position: absolute;
    margin: 0 2px 0 10px;
    z-index: 1;
  }

  img {
    object-fit: cover;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    img {
      width: 100%;
    }
  }
`;

export const GeneralEventSummaryEventSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

  h4,
  p {
    margin: 0;
  }
`;
