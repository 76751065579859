import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { Table } from 'components/v3/Table/Table';
import RightDrawer from 'components/v3/RightDrawer';

import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';

import { ApplicationState } from 'redux/store';
import { clubDashboardToggleSelectedMemberPoolUser } from 'redux/v3/clubDashboard/actions';

import { User } from 'models/User/UserModel';
import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';
import DateUtil from 'util/DateUtil';
import { BodyLBold } from 'styles/v3/variables';

import { columns } from './columns';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';

import { FilterModalBody } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/FilterModalBody';
import { InvitationFiltersType } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/types';
import { Filters } from './components';

export const MembersPoolTable = () => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { data: members } = useGetClubMembersPool(params.clubId, true);

  const [filterMember, setFilterMember] = useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentMember, setCurrentMember] = React.useState<User | undefined>(
    undefined
  );
  const [userIds, setUserIds] = React.useState<string[]>([]);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    documentStatus: [],
    gender: [],
    membership: [],
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const dispatch = useDispatch();

  const handleToggleSelectedUser = (userId: string) => {
    dispatch(clubDashboardToggleSelectedMemberPoolUser(userId));
  };

  const handleFilter = (text: string) => {
    setFilterMember(text);
  };

  const membersData = useMemo(() => {
    if (!members?.length || !currentClub) return [];

    const getPermissionType = (
      userId: string,
      userType?: UserType
    ): UserType => {
      if (currentClub.directorIds?.includes(userId))
        return UserType.CLUB_DIRECTOR;
      if (currentClub.coachIds?.includes(userId)) return UserType.COACH;
      if (currentClub.teamManagers?.map((t) => t.userId).includes(userId))
        return UserType.TOURNAMENT_DIRECTOR;
      if (currentClub.playerIds?.includes(userId)) return UserType.PLAYER;
      return userType || UserType.PLAYER;
    };

    const getDocumentStatus = (
      documentationVerified?: RequestStatus,
      ageVerified?: RequestStatus,
      type?: UserType
    ): RequestStatus => {
      if (!type) return RequestStatus.APPROVED;

      switch (type) {
        case UserType.PLAYER:
          return ageVerified || RequestStatus.DECLINED;
        default:
          return documentationVerified || RequestStatus.DECLINED;
      }
    };

    const userWithMoreThanOneRole = uniqBy(
      members,
      (member) => member.baseUserId ?? member
    );
    const filterRemovedUser = userWithMoreThanOneRole.filter(
      (member) => !userIds.includes(member.id)
    );

    const getGender = (currentGender?: string) => {
      switch (currentGender) {
        case 'MALE':
          return 'Boy';
        case 'FEMALE':
          return 'Girl';
        default:
          return 'Other';
      }
    };

    return filterRemovedUser
      .map((member) => ({
        id: member.id,
        name: `${member.firstName} ${member.lastName}`,
        permission: getPermissionType(member.id, member.type),
        age: DateUtil.getYearFromDateString(member.birthDate?.toString()),
        gender: getGender(member.gender),
        membership: member.membership?.type || 'COMMUNITY',
        documentStatus: getDocumentStatus(
          member.documentationVerified,
          member.ageVerified,
          member.type
        ),
        photoStatus: member.photoVerified,
        ageStatus: member.ageVerified,
        safeSportStatus: member.documentationVerified,
        photo: member.photo,
        selected: selectedUserIds.includes(member.id),
        toggleSelected: () => handleToggleSelectedUser(member.id),
        openDrawer: () => handleOpenDrawer(member),
      }))
      .filter((member) =>
        !filterMember
          ? true
          : member.name.toLowerCase().includes(filterMember.toLowerCase())
      )
      .filter((member) => {
        const ageFilter =
          advancedFilter.age.length === 0
            ? true
            : advancedFilter.age.includes(Number(member.age));

        const genderFilter =
          advancedFilter.gender.length === 0
            ? true
            : advancedFilter.gender.includes(member.gender);

        const membershipFilter =
          advancedFilter.membership.length === 0
            ? true
            : advancedFilter.membership.includes(member.membership);

        const documentStatusFilter =
          advancedFilter.documentStatus.length === 0
            ? true
            : advancedFilter.documentStatus.includes(
                member.documentStatus.toString()
              );

        return (
          ageFilter && genderFilter && membershipFilter && documentStatusFilter
        );
      });
  }, [
    members,
    selectedUserIds,
    filterMember,
    currentClub,
    userIds,
    advancedFilter,
  ]);

  const handleOpenDrawer = (member: User) => {
    setCurrentMember(member);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentMember(undefined);
    setIsOpen(false);
  };

  const handleOpenFilterModal = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);
  };

  return (
    <>
      <Filters
        handleOpenFilterModal={handleOpenFilterModal}
        handleChange={handleFilter}
      />
      <Table columns={columns} dataSource={membersData} />
      <RightDrawer
        isOpen={isOpen && !!currentMember}
        handleCloseModal={handleCloseModal}
        headerComponent={<ModalHeader photo={currentMember?.photo} />}
      >
        {currentMember && (
          <ModalBody
            user={currentMember}
            onClose={handleCloseModal}
            setUserIds={(ids) => setUserIds(ids)}
          />
        )}
      </RightDrawer>

      <RightDrawer
        isOpen={isFilterOpen}
        handleCloseModal={handleCloseFilterModal}
        headerComponent={<BodyLBold $color='brandPrimary'>Filters</BodyLBold>}
      >
        <FilterModalBody
          filters={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </RightDrawer>
    </>
  );
};
