import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  background-color: ${COLORS.grey300};
  align-items: center;
  justify-content: center;
  border-radius: ${toRem(100)};

  width: ${toRem(44)};
  height: ${toRem(22)};
`;

export const DotMiddle = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  width: ${toRem(18)};
  height: ${toRem(18)};

  border-radius: 110%;
  background-color: ${COLORS.grey600};
  box-shadow: 0 ${toRem(2)} ${toRem(4)} 0 rgba(0, 35, 11, 0.2);
`;

export const Line = styled.div`
  background-color: ${COLORS.grey50};
  padding: 0 ${toRem(5)};
  border-radius: ${toRem(100)};
  height: ${toRem(2)};
`;
