import React from 'react';
import { ApplicationsTable } from './components/ApplicationsTable/ApplicationsTable';

export const ApplicationsTab = () => {
  return (
    <>
      <ApplicationsTable />
    </>
  );
};
