import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { BREAKPOINTS } from 'styles/v3/constants';

type StripeCardWrapperProps = { $width?: string };

export const StripeCardWrapper =
  styled.div <
  StripeCardWrapperProps >
  `
  ${(props) => !!props.$width && `width: 100%;`}
  .simple-card-wrapper {
    width: 100%;
    height: 100%;
    border-color: ${COLORS.grey800};
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      flex-flow: wrap;
      justify-content: center;
    }
  }

`;
export const Stripe =
  styled.span <
  { $color: string } >
  `
  width: 9px;
  min-height: 10vh;
  border-radius: 21px;
  background-color: ${(props) => props.$color};
  margin-right: 1rem;

  &:nth-child(2n) {
    background-color: ${COLORS.blue};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    min-height: ${toRem(6)};
    margin-right: 0;
    margin-bottom: ${toRem(16)};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const BgIcon = styled.div`
  border-radius: 12px;
  padding: ${toRem(8)};
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${COLORS.grey800};

  & + div {
    margin-top: ${toRem(8)};
  }
`;
export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
