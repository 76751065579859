import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import React from 'react';
import { BodyLBold, BodyM, BodyMBold, BodyS } from 'styles/v3/variables';

import InputText from 'components/v3/Forms/InputText/InputText';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { InputFormat } from 'components/v3/Forms/InputFormat/InputFormat';
import { currencyOptions } from '../../constants';

import * as S from '../../styles';
import { Fields } from '../../types';

type RefereeConditionsProps = {
  fields: Fields;
  requirements?: string;
  travelConditions?: string;
  allowRefereesToSignUp: boolean;
  setLabelToPaymentCurrency: OptionsType | undefined;
  setAllowRefereesToSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeInputValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RefereeConditions = ({
  fields,
  requirements,
  travelConditions,
  allowRefereesToSignUp,
  handleChangeInputValue,
  setAllowRefereesToSignUp,
  setLabelToPaymentCurrency,
}: RefereeConditionsProps) => {
  return (
    <fieldset>
      <legend>
        <BodyLBold $color='white'>Referee Conditions</BodyLBold>
      </legend>
      <S.ElementCheckboxWrapper>
        <InputCheckbox
          id='allowRefereesToSignUp'
          name='allowRefereesToSignUp'
          checked={allowRefereesToSignUp}
          onChange={() => setAllowRefereesToSignUp((prev) => !prev)}
        />
        <BodyM>Allow referees to sign up</BodyM>
      </S.ElementCheckboxWrapper>
      <Select
        name='paymentCurrency'
        options={currencyOptions}
        placeholder='Payment Currency'
        defaultValue={setLabelToPaymentCurrency}
      />

      <S.Flex gap={4} mt={32}>
        <BodyMBold $color='grey200'>Pay per game</BodyMBold>
        <BodyM $color='grey400'>
          Set the value that you will pay the referees
        </BodyM>
      </S.Flex>

      <InputFormat
        id='refereeSolo'
        label='Referee Solo'
        placeholder='Referee Solo'
        error={fields.refereeSolo.error}
        onChange={handleChangeInputValue}
        defaultValue={fields.refereeSolo.value}
        containerClassName='general-rules-input'
        errorMessage='The field referee solo is required'
      />

      <InputFormat
        id='refereePair'
        label='Referee Pair'
        placeholder='Referee Pair'
        error={fields.refereePair.error}
        onChange={handleChangeInputValue}
        defaultValue={fields.refereePair.value}
        containerClassName='general-rules-input'
        errorMessage='The field referee pair is required'
      />

      <BodyS className='input-info'>
        For Referee Pair, set the price that each referee will receive.
      </BodyS>

      <InputFormat
        id='scoreKeeper'
        label='Score Keeper'
        placeholder='Score Keeper'
        error={fields.scoreKeeper.error}
        onChange={handleChangeInputValue}
        defaultValue={fields.scoreKeeper.value}
        containerClassName='general-rules-input'
        errorMessage='The field score keeper is required'
      />

      <S.Flex gap={4} mt={32}>
        <BodyMBold $color='grey200'>Travel Conditions</BodyMBold>
        <InputText
          containerClassName='general-rules-input'
          id='travelConditions'
          placeholder='Describe the conditions for travel'
          label='Describe the conditions for travel'
          defaultValue={travelConditions}
        />
      </S.Flex>

      <S.Flex gap={4} mt={32}>
        <BodyMBold $color='grey200'>Requirements</BodyMBold>
        <InputText
          containerClassName='general-rules-input'
          id='requirements'
          placeholder='Describe the requirements'
          label='Describe the requirements'
          defaultValue={requirements}
        />
      </S.Flex>
    </fieldset>
  );
};
