import React, { useMemo } from 'react';

import moment from 'moment';
import { BracketMatchOpponent } from 'models/Bracket/BracketModel';
import { PoolMatch } from 'admin/models/AgeDivision';
import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import { useParams } from 'react-router-dom';
import CustomCalendar from '../CustomCalendar';

import {
  CalendarDay,
  SportEventType,
  getEventsAvailableSpaces,
} from '../../types';

import { ScheduleCalendarItemProps } from './types';

const ScheduleCalendarItem = ({
  currentEvent,
  currentSchedule,
  divisionsData,
  activeDivision,
  divisionColors,
  eventBrackets,
}: ScheduleCalendarItemProps) => {
  const getBracketSourceTeamName = (
    opponent: BracketMatchOpponent,
    defaultValue = 'TBA'
  ) => {
    let name = defaultValue;
    if (opponent?.source) {
      switch (opponent.source) {
        case 'DIVISION':
          if (opponent.seed !== null) name = `Division ${opponent.seed}`;
          break;
        case 'POOL':
          if (opponent.seed !== null) name = `Pool ${opponent.seed}`;
          break;
        case 'LOSER':
          if (opponent.loserGame !== null) name = `Loser ${opponent.loserGame}`;
          break;
        case 'WINNER':
          if (opponent.winnerGame !== null)
            name = `Winner ${opponent.winnerGame}`;
          break;
        default:
          break;
      }
    }
    return name;
  };

  const poolMatches = useMemo(() => {
    return (
      currentEvent?.ageDivisions?.reduce((acc, division) => {
        if (division.matches) {
          const matches = division.matches.map((match) => ({
            ...match,
          }));
          acc.push(...matches);
        }
        return acc;
      }, [] as PoolMatch[]) ?? ([] as PoolMatch[])
    );
  }, [currentEvent]);

  const gamesData = useMemo(() => {
    const dataResult: SportEventType[] = [];
    if (eventBrackets) {
      dataResult.push(
        ...eventBrackets.reduce<SportEventType[]>((acc, item) => {
          item.matches.forEach((match) => {
            const { opponent1, opponent2 } = match;
            const text1 = item?.ageDivision?.name ?? 'unnamed Division';
            const text2 = match?.name ?? `Game ${match?.number}`;
            const text3 = getBracketSourceTeamName(opponent1, 'TBA');
            const text4 = getBracketSourceTeamName(opponent2, 'TBA');
            acc.push({
              id: match.id,
              bracketId: item.id,
              scheduleType: 'BRACKET_GAMES',
              labelData: {
                text1,
                text2,
                text3,
                text4,
              },
              bg: item?.ageDivision?.color ?? '#400e41',
              name: match?.name,
              scheduled: false,
              division: item?.ageDivision?.id ?? '',
            });
          });
          return acc;
        }, [])
      );
    }

    if (
      !(!currentEvent?.ageDivisions || !activeDivision) &&
      currentEvent.ageDivisions
    ) {
      dataResult.push(
        ...currentEvent.ageDivisions.reduce<SportEventType[]>((acc, item) => {
          item.matches?.forEach((match) => {
            const text1 = item.name ?? 'unnamed Division';
            const text2 = match.name;
            const text3 = match.opponent1.label;
            const text4 = match.opponent2.label;

            acc.push({
              id: match.id,
              bracketId: match.id,
              scheduleType: 'POOL_GAMES',
              labelData: {
                text1,
                text2,
                text3,
                text4,
              },
              bg: item.color ?? '#400e41',
              name: match.name,
              scheduled: false,
              division: item.id ?? '',
            });
          });

          return acc;
        }, [])
      );
    }

    return dataResult;
  }, [eventBrackets, currentEvent, activeDivision]);

  const allDays: CalendarDay[] = useMemo(() => {
    if (!currentEvent) return [];

    const currDate = moment(currentEvent.startDate).startOf('day');
    const lastDate = moment(currentEvent.endDate).startOf('day');
    const dates: Date[] = [];

    dates.push(currDate.clone().toDate());

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }

    dates.push(lastDate.clone().toDate());
    const schedules = getEventsAvailableSpaces();
    return dates.map((d) => {
      const courts = currentEvent.venues.reduce<any[]>((acc, v) => {
        v.subVenues.forEach((s) => {
          acc.push({
            id: `${v.id}_${s.name}`,
            venueId: v.id,
            name: s.name,
            schedules,
          });
        });
        return acc;
      }, []);
      courts.sort((a, b) =>
        String(a?.name)
          .toLocaleLowerCase()
          .localeCompare(String(b?.name).toLocaleLowerCase())
      );
      return {
        id: `${currentEvent.id}_${d.toISOString()}`,
        date: d.toISOString(),
        active: true,

        courts,
      };
    });
  }, [currentEvent]);

  const scheduleRules = {
    minTimeBetweenGames:
      currentEvent?.data?.matchSettings?.minTimeBetweenGames ?? 60,
    maxGamesPerTeam: currentEvent?.data?.matchSettings?.maxGamesPerTeam ?? 0,
    poolGamesLength: currentEvent?.data?.matchSettings?.poolGamesLength ?? 0,
    bracketGamesLength:
      currentEvent?.data?.matchSettings?.bracketGamesLength ?? 0,
  };

  return (
    <CustomCalendar
      activeDivision={activeDivision}
      divisionColors={divisionColors}
      scheduleRules={scheduleRules}
      allSportEvent={gamesData}
      allDays={allDays}
      divisionsData={divisionsData}
      poolMatches={poolMatches}
      currentSchedule={currentSchedule}
    />
  );
};

export default ScheduleCalendarItem;
