import React, { FunctionComponent } from 'react';
import { COLORS } from 'styles/v3/variables';
import Icon from '@icon-park/react/es/all';
import { MatchV3Model } from 'models/Match/MatchModel';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import {
  BracketColumn,
  BracketDelete,
  BracketItemContainer,
  BracketItemHeader,
  BracketRenderContainer,
  BracketRenderItem,
} from './styles';
import { BracketGame, SingleItemBracketGame } from './components/BracketGame';

interface ConsolationBracketRenderProps {
  bracketId: string;
  // match: MatchV3Model;
  match: any;
  onDelete: () => void;
  handleOpenEditModal?: (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => void;
}

export const ConsolationBracketRender: FunctionComponent<ConsolationBracketRenderProps> = ({
  match,
  bracketId,
  onDelete,
  handleOpenEditModal,
}) => {
  const handleDelete = () => {
    confirm({
      message: 'Are you sure that you want to delete this consolidation game?',
      onOk: onDelete,
    });
  };
  return (
    <BracketItemContainer>
      <BracketItemHeader>
        <p>Consolation Game</p>
        <BracketDelete $color={COLORS.grey200} onClick={handleDelete}>
          <Icon type='delete' size={24} />
        </BracketDelete>
      </BracketItemHeader>
      <BracketRenderContainer>
        <BracketRenderItem>
          <BracketColumn>
            <BracketGame
              bracketId={bracketId}
              match={match}
              to={{
                side: true,
                color: COLORS.brandPrimary,
              }}
              showPens
              handleOpenEditModal={handleOpenEditModal}
            />
          </BracketColumn>
          <BracketColumn>
            <SingleItemBracketGame
              title='Winner'
              from={{
                side: true,
                color: COLORS.brandPrimary,
              }}
            />
          </BracketColumn>
        </BracketRenderItem>
      </BracketRenderContainer>
    </BracketItemContainer>
  );
};
