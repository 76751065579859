import styled from 'styled-components';
import { Modal } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS, BodyM } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLORS.grey600};

  h3 {
    margin: 0 !important;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 7px 0;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: unset;
  }
`;

export const Container = styled.div`
  padding-top: 16px;
`;

export const Text = styled(BodyM)`
  display: flex;
  justify-content: center;
`;

export const GroupActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 8px;
  margin-top: 20px;

  button {
    min-width: 120px;
  }
`;

export const ConfirmFilledButton = styled(FilledButton)`
  background-color: ${COLORS.brandPrimary} !important; // Due to modal library nesty

  &:hover {
    background-color: ${COLORS.brandPrimary} !important; // Due to modal library nesty
    color: ${COLORS.grey900};
    border-color: ${COLORS.brandPrimary};
  }
`;

export const CloseOutlinedButton = styled(OutlinedButton)`
  background-color: transparent !important; // Due to modal library nesty

  &:hover {
    background-color: transparent !important; // Due to modal library nesty
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
  }
`;
