import styled from 'styled-components';
import { Modal } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS, TitleH1, BodyM } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  position: relative;
  margin-top: 50px;

  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 200px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-top: 0;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 400px;
  height: 283px;
  transform: translate(-50%, -20%);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ConfirmButton = styled(FilledButton)`
  background-color: ${COLORS.brandPrimary} !important;

  &:hover {
    background-color: ${COLORS.grey700} !important;
  }
`;

export const Title = styled(TitleH1)`
  text-align: center;
  color: ${COLORS.brandPrimary};
`;

export const Description = styled(BodyM)`
  text-align: center;
  color: ${COLORS.grey200};
  line-height: 22px;
`;
