import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/uielements/Button/Button';

const PaymentSuccess: React.FunctionComponent = (props) => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <h1>Congratulations!</h1>
      <p>Your new user is ready to enjoy the Vault Membership!</p>
      <div>
        <Link to='/home'>
          <Button>GO TO DASHBOARD</Button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
