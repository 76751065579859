import React from 'react';

import { GroupWrapper } from './components';
import { GroupsProvider } from './context/GroupsContext';
import * as S from './styles';

const Groups = () => {
  return (
    <GroupsProvider>
      <S.Container>
        <GroupWrapper />
      </S.Container>
    </GroupsProvider>
  );
};

export default Groups;
