import React from 'react';
import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';

import { BodyXL, BodyM } from 'styles/v3/variables';
import { Container, HeaderList, BodyList, ContainerOfInfos } from './styles';
import { PlayerOfCoachInformation } from './PlayerOrCoachInformation';

interface ListOfUserProps {
  typeOfUser: string;
  coachesList?: User[];
  playersList?: User[];
}

export const ListOfUser = ({
  typeOfUser,
  coachesList,
  playersList,
}: ListOfUserProps) => {
  const userType = typeOfUser === 'Coaches' ? UserType.COACH : UserType.PLAYER;
  const listOfUser = typeOfUser === 'Coaches' ? coachesList : playersList;

  return (
    <Container>
      <HeaderList>
        <BodyXL $color='white'>
          {typeOfUser} ({listOfUser?.length})
        </BodyXL>
      </HeaderList>

      <BodyList>
        {listOfUser && (
          <>
            {listOfUser.length === 0 ? (
              <BodyM
                $color='grey400'
                style={{ paddingTop: 50, paddingBottom: 50 }}
              >
                You don&apos;t have{' '}
                {typeOfUser === 'Coaches' ? 'coaches' : 'players'} in this
                roster. Add now
              </BodyM>
            ) : (
              <ContainerOfInfos>
                {listOfUser.map((user) => (
                  <PlayerOfCoachInformation
                    key={`key_play_or_coach_${user.id}`}
                    user={user}
                  />
                ))}
              </ContainerOfInfos>
            )}
          </>
        )}
      </BodyList>
    </Container>
  );
};
