import React, { useCallback } from 'react';

import { BodyLBold, BodyM } from 'styles/v3/variables';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { useGroups } from '../../../../context/GroupsContext';
import * as S from './styles';

type HeaderProps = {
  onPoolCreate: (divisionId: string) => void;
}

export const Header = (props: HeaderProps) => {
  const { onPoolCreate } = props;
  const {
    selectedDivision,
    isLoadingUpdateDivision,
  } = useGroups();

  const totalPools = selectedDivision?.pools?.length ?? 0;

  const handleCreatePool = useCallback(() => {
    if (!selectedDivision) {
      return;
    }

    onPoolCreate(selectedDivision.id);
  }, [selectedDivision, onPoolCreate]);

  return (
    <S.Container>
      <S.HeaderWrapper>
        <BodyLBold $color='grey100'>{selectedDivision?.name}</BodyLBold>
        <BodyM $color='grey300'>{totalPools} pools</BodyM>
      </S.HeaderWrapper>
      <OutlinedButton
        isUpper
        onClick={handleCreatePool}
        disabled={isLoadingUpdateDivision}
      >
        Create new pool
      </OutlinedButton>
    </S.Container>
  );
};
