import { Whistling, Shield } from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';
import { AllowedApplyEventRoles } from './types';

export const allowedRoles = [
  {
    title: 'Referee',
    name: UserType.REFEREE as AllowedApplyEventRoles,
    icon: Whistling,
  },
  {
    title: 'Club',
    name: UserType.CLUB_DIRECTOR as AllowedApplyEventRoles,
    icon: Shield,
  },
];
