import styled from 'styled-components';
import { Avatar } from 'antd';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container =
  styled.div <
  { status: 'locked' | 'open', bgColor: string } >
  `
  padding: ${toRem(6)} ${toRem(6)} ${toRem(6)} ${toRem(8)};

  display: flex;
  border-radius: ${toRem(24)};
  align-items: center;
  background-color: ${({ status, bgColor }) =>
    status === 'open' ? COLORS.grey800 : bgColor};
  gap: ${toRem(8)};
  cursor: default;

  transition: ease-in-out 0.2s;
  span {
    display: flex;
  }
`;

export const ContainerCircles = styled.div`
  display: flex;
`;

export const CirclesGroup = styled(Avatar.Group)`
  span {
    border: none !important;
  }
`;

export const CircleInfo = styled(Avatar)`
  .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -10px !important;
  }
`;
