import styled from 'styled-components';
import { Avatar } from 'antd';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

import backgroundEvents from 'assets/imgs/my-events-background.jpeg';

export const Container = styled.div`
  max-width: 1044px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  @media screen and (max-width: 822px) {
    padding-top: 32px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 0;
  }
`;

export const ContainerUser = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.grey950};
  padding: ${toRem(32)} ${toRem(24)};
  border-radius: ${toRem(24)};

  @media screen and (max-width: ${BREAKPOINTS.tablet}) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: ${toRem(16)};
  }
`;

export const ContainerUserProfile = styled.div`
  display: flex;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${toRem(16)};
    margin-bottom: ${toRem(16)};
  }
`;

export const UserProfileImage = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${toRem(160)};
  background-color: ${COLORS.brandPrimary};
  border: ${toRem(6)} solid ${COLORS.grey800};

  svg {
    fill: ${COLORS.grey800};
  }
`;

export const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-left: ${toRem(32)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    padding-left: 0;
  }
`;

export const UserNameAndTeams = styled.div`
  flex: auto;
  word-break: break-word;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    text-align: center;

    h2 {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ContainerEvents = styled.div`
  width: 100%;
  margin-top: ${toRem(111)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const EventsContent = styled.div`
  display: flex;
  gap: ${toRem(30)};

  &.full-width {
    gap: ${toRem(55)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
  }
`;

export const EventsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${toRem(20)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    justify-content: flex-start;
  }
`;

export const EventMore = styled.div`
  width: ${toRem(48)};
  height: ${toRem(48)};
  margin-left: ${toRem(12)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${toRem(48)};
  background-color: ${COLORS.grey900};
  cursor: pointer;
`;

export const EventTitleWrapper = styled.div`
  display: block;
  padding-top: 40px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-top: 0;
  }
`;

export const ButtonCarousel =
  styled.button <
  { disabled: boolean } >
  `
  background-color: ${COLORS.grey900};
  display: flex;
  align-items: center;
  padding: ${toRem(8)};
  max-height: ${toRem(32)};
  border-radius: ${toRem(8)};
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.5s;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}

  &:hover {
    background-color: ${COLORS.grey800};
  }
`;

export const SlideItem = styled.div`
  padding-right: 16px;
  width: fit-content;
`;

export const EmptyEventCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 282px;
  height: 321px;
  border-radius: 24px;
  background: linear-gradient(86deg, #252622 40.19%, rgba(37, 38, 34, 0.91)),
    url(${backgroundEvents}) lightgray no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${COLORS.grey400};
  border: 1px solid ${COLORS.grey800};
  cursor: pointer;
`;
