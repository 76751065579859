import Axios, { AxiosRequestConfig } from 'axios';
import { ClubCreation, ClubView } from '../../admin/models/club/Club';
import { EventView } from '../../admin/models/event/Event';
import RequestModel from '../../models/Request/RequestModel';
import { RosterListing } from '../../models/Roster/RosterModel';
import { User } from '../../models/User/UserModel';
import { ConfigApi } from '../config.api';
import APIServices from '../util/ApiServices';
import PageResponse from '../util/PageResponse';
import ServiceResponse from '../util/ServiceResponse';
import { InviteClubFilters } from 'services/v3/Clubs/types';

class ClubService {
  public static async list(
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/clubs',
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async paginatedList(
    filters: InviteClubFilters
  ): Promise<ServiceResponse<PageResponse<ClubView>>> {
    const { page, pageSize, name } = filters;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('size', pageSize.toString());
    if (name) params.append('name', name);

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/clubs/clubs-to-invite',
      method: 'GET',
      params,
    };

    return APIServices.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async quickSearch(
    quickSearch: string,
    urlParams?: URLSearchParams,
  ): Promise<ServiceResponse<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/clubs/search',
      method: 'POST',
      params: urlParams,
      data: { quickSearch, },
    };
    return APIServices.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async quickSearchPending(
    quickSearch: string,
    urlParams?: URLSearchParams,
  ): Promise<ServiceResponse<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/clubs/search',
      method: 'POST',
      params: urlParams,
      data: { quickSearch, clubVerified: "PENDING" },
    };
    return APIServices.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async create(
    body: ClubCreation
  ): Promise<ServiceResponse<ClubView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/clubs',
      method: 'POST',
      data: body,
    };
    return APIServices.request<ClubView>(axiosConfig);
  }

  public static async update(
    id: string,
    body: ClubCreation
  ): Promise<ServiceResponse<ClubView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${id}`,
      method: 'PUT',
      data: body,
    };
    return APIServices.request<ClubView>(axiosConfig);
  }

  public static async verifyClubApproved(
    clubId: string,
  ): Promise<ServiceResponse<ClubView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/verify-club/${clubId}`,
      method: 'PUT',
    };
    return APIServices.request<ClubView>(axiosConfig);
  }

  public static async delete(id: string): Promise<ServiceResponse<ClubView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${id}`,
      method: 'DELETE',
    };
    return APIServices.request<ClubView>(axiosConfig);
  }

  public static async find(id: string): Promise<ServiceResponse<ClubView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/clubs/${id}`,
      method: 'GET',
    };
    return APIServices.request<ClubView>(axiosConfig);
  }

  public static async requestJoin(
    body: any
  ): Promise<ServiceResponse<PageResponse<ClubView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: '/requests',
      method: 'POST',
      data: body,
    };
    return APIServices.request<PageResponse<ClubView>>(axiosConfig);
  }

  public static async getRequests(): Promise<
    ServiceResponse<PageResponse<RequestModel>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/requests/my-requests/`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<RequestModel>>(axiosConfig);
  }

  public static async getClubRequests(
    clubId: string,
    status: string
  ): Promise<ServiceResponse<PageResponse<RequestModel>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/requests/club/${clubId}/status/${status}`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<RequestModel>>(axiosConfig);
  }

  public static async getRosters(
    clubId: string,
    urlParams?: URLSearchParams
  ): Promise<ServiceResponse<PageResponse<RosterListing>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${clubId}/rosters`,
      method: 'GET',
      params: urlParams,
    };
    return APIServices.request<PageResponse<RosterListing>>(axiosConfig);
  }

  public static async getMyClubs(): Promise<
    ServiceResponse<PageResponse<RequestModel>>
  > {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/my-clubs`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<RequestModel>>(axiosConfig);
  }

  public static async getMemberpool(
    clubId: string
  ): Promise<ServiceResponse<User[]>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${clubId}/memberpool`,
      method: 'GET',
    };
    return APIServices.request<User[]>(axiosConfig);
  }

  public static async exportMemberpool(clubId: string): Promise<any> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${clubId}/memberpool/export`,
      method: 'GET',
    };
    return Axios.request(axiosConfig);
  }

  public static async acceptRequest(
    id: string
  ): Promise<ServiceResponse<PageResponse<RequestModel>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/requests/${id}/approval`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<RequestModel>>(axiosConfig);
  }

  public static async rejectRequest(
    id: string
  ): Promise<ServiceResponse<PageResponse<RequestModel>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/requests/${id}/decline`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<RequestModel>>(axiosConfig);
  }

  public static async getActiveEventsClub(
    clubId: string
  ): Promise<ServiceResponse<PageResponse<EventView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${clubId}/events`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<EventView>>(axiosConfig);
  }

  public static async getPastEventsClub(
    clubId: string
  ): Promise<ServiceResponse<PageResponse<EventView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/${clubId}/events/past`,
      method: 'GET',
    };
    return APIServices.request<PageResponse<EventView>>(axiosConfig);
  }

  public static async addUserToMemberpool(body: {
    userId: string;
    clubId: string;
  }): Promise<ServiceResponse<PageResponse<EventView>>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerAndAccountConfig(),
      url: `/clubs/memberpool`,
      method: 'POST',
      data: body,
    };
    return APIServices.request<PageResponse<EventView>>(axiosConfig);
  }
}

export default ClubService;
