import { CloseSmall } from '@icon-park/react';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { BodyM, BodyS, BodySBold, COLORS } from 'styles/v3/variables';

export const StyledModal =
  styled.div <
  { height?: number } >
  `
  background-color: #363731;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  z-index: 20;
  min-height: ${({ height }) => height ? toRem(height) : toRem(750)};
  padding: ${toRem(40)} 0;
`;
export const StyledModalHeader = styled.div`
  color: #ffffff;
  text-align: 'center';
  font-family: 'UF Display';
  font-size: 30px;
  line-height: 1.2;
  display: flex;
  justify-content: space-between;
  padding: 0 ${toRem(35)} ${toRem(15)} ${toRem(35)};
  align-items: center;
  border-bottom: 1px solid #72746f;
`;

export const StyledModalBody = styled.div`
  color: #ffffff;
  text-align: 'center';
  height: 100%;
  justify-content: space-between;
  font-family: 'UF Display';
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  padding: 0 ${toRem(35)};
  row-gap: 15px;
`;

export const StyledCloseSmall = styled(CloseSmall)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const StyledTitle = styled.div`
  display: flex;
  column-gap: 10px;
  p {
    text-transform: uppercase;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ScheduleDay = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: ${toRem(550)};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const GameItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  row-gap: 15px;
  cursor: pointer;
`;

export const StyledGameInfoItemWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  background-color: #252622;
  padding: ${toRem(15)};
  border-radius: 12px;
`;

export const StyledGameContainer = styled(StyledGameInfoItemWrapper)`
  flex-direction: column;
  row-gap: 10px;
  padding: ${toRem(30)} ${toRem(15)};
  position: relative;
`;

export const StyledDivisionTitle = styled(BodyS)`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  font-weight: 400px;
  text-transform: uppercase;
`;

export const StyledGameEventInfoContainer = styled(StyledGameInfoItemWrapper)`
  column-gap: 10px;
  padding: 0 ${toRem(10)};
  align-items: starts;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  row-gap: 10px;
  flex-direction: column;
`;

export const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const TeamWrapperGameInfo = styled.div`
  display: grid;
  grid-template-columns: 8fr 1fr 8fr;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
  align-items: center;
`;

export const TeamWrapperEventIfo = styled(TeamWrapper)`
  padding-bottom: ${toRem(15)};
  width: 100%;
  border-bottom: 1px solid #72746f;
  display: grid;
  grid-template-columns: 8fr 1fr 8fr;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
  align-items: center;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${toRem(10)};
`;

export const StyledBodyS = styled(BodyS)`
  text-transform: uppercase;
`

export const TeamItem = styled(TeamWrapper)`
  background-color: #9747ff;
  border-radius: 30px;
  column-gap: 10px;
  cursor: pointer;
  padding: ${toRem(10)};
`;

export const TeamItemEventInfo = styled(TeamItem)`
  padding: ${toRem(10)} ${toRem(10)};
  justify-content: center;
  height: 100%;
`;

export const TeamName = styled(BodySBold)`
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
`;

export const TeamLogo = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${toRem(24)};
  height: ${toRem(24)};
  overflow: hidden;
  min-width: 25px;
  img {
    width: ${toRem(18)};
    height: ${toRem(18)};
    max-width: 100%;
    object-fit: cover;
  }
`;
export const PageWrapper = styled.div`
  margin: 0 auto;
`