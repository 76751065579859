import React, { useMemo, useState } from 'react';
import { Table } from 'components/v3/Table/Table';
import { useGetApplicationsByEventId } from 'hooks/v3/referees/useGetApplicationsByEventId';
import { useParams } from 'react-router-dom';
import { useGetUsersStats } from 'hooks/v3/users/useGetUserStats/useGetUserStats';
import { Filters } from './components';
import { columns } from './columns';

export const PendingApprovalTable = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useGetApplicationsByEventId(eventId);

  const { data: usersStats } = useGetUsersStats(
    data?.map(({ referee }) => referee.id) || [],
    Boolean(data)
  );

  const newApplications = useMemo(() => {
    return data?.map((item) => ({
      ...item,
      referee: {
        ...item.referee,
        xp:
          usersStats?.find(
            (u: { userId: string }) => u.userId === item.referee.id
          )?.xp || 0,
      },
    }));
  }, [data, usersStats]);

  return (
    <>
      <Filters handleChange={(text) => setSearchTerm(text)} />
      <Table columns={columns} dataSource={newApplications} />
    </>
  );
};
