import styled from 'styled-components';
import { Modal } from 'antd';

import { BREAKPOINTS } from 'styles/v3/constants';
import { BodyLBold, BodyMBold, COLORS } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 468px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.grey600};
  padding-bottom: 10px;

  h3 {
    margin: 0 !important;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  padding: 7px 0;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background-color: unset;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.div`
  padding-top: 16px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 4px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #414141;
  }
`;

export const CardAddress = styled(BodyMBold)`
  color: ${COLORS.grey400};
  text-transform: uppercase;
`;
