import styled from 'styled-components';
import { color } from 'styles/styleVariables';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  background: ${COLORS.white};
  @media print {
    @page {
      size: landscape;
    }
    background: none !important;
  }

  .printButton {
    @media print {
      display: none;
    }
    cursor: pointer;
    position: absolute;
    right: 0;
    background-color: ${color.orange};
    font-weight: 800;
    padding: 10px;
    color: ${color.greyDark};
    width: 50px;
    display: flex;
    width: 50px;
    height: 50px;
    overflow: hidden;
    align-items: center;
    &:hover {
      width: auto;
      div {
        display: block;
      }
    }
    div {
      display: none;
    }
  }
`;
