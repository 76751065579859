import React from 'react';

import { Container, DotMiddle, Line } from './styles';

export const PartialSwitch = () => {
  return (
    <Container>
      <DotMiddle>
        <Line />
      </DotMiddle>
    </Container>
  );
};
