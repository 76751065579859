import { useQuery } from '@tanstack/react-query';
import EventService from 'services/v3/Event/EventService';

export const useSubscribedReferees = (id: string) => {
  const response = useQuery(['event-subscribed-referees', id], () =>
    EventService.findSubscribedReferees(id)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
