import React, { FC, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Plus } from '@icon-park/react';

import { ApplicationState } from 'redux/store';
import { b2bDraftEventClearEvent } from 'redux/v3/b2bDraftEvent/actions';
import { b2bSetupEventFetchEventsSuccess } from 'redux/v3/b2bSetupEvent/actions';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';

import TabBar from 'components/v3/Elements/TabBar/TabBar';
import EventCard from 'components/v3/Cards/EventCard/EventCard';

import { TitleH2 } from 'styles/v3/variables';

import { TAB_ITEMS } from './configs';

import * as S from './styles';

const MyEvents: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { content: storedEvents } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent.data
  );

  const handleLoadEvents = async () => {
    setIsLoading(true);

    try {
      const { data: fetchedEvents } = await B2bEventService.listEvents();
      if (fetchedEvents.content.length !== storedEvents.length) {
        dispatch(b2bSetupEventFetchEventsSuccess(fetchedEvents));
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadEvents();
  }, [storedEvents]);

  const handleNewEvent = () => {
    dispatch(b2bDraftEventClearEvent());
  };

  return (
    <>
      <TitleH2>Events</TitleH2>
      <S.EventsHeader>
        <TabBar items={TAB_ITEMS} />
        <Link to='/v3/draft-event/plan-selection'>
          <S.NewEventButton onClick={handleNewEvent}>
            New Event <Plus />
          </S.NewEventButton>
        </Link>
      </S.EventsHeader>
      {isLoading && <p>Loading...</p>}
      {!isLoading && (
        <S.EventsList>
          {storedEvents.map((event) => {
            const eventStatus = event.draft ? 'draft' : 'published';
            const published = eventStatus === 'published' ? '/published' : '';
            const link = `/v3/setup-event/my-events/${event.id}${published}`;

            return (
              <EventCard
                key={event.id}
                eventStatus={eventStatus}
                orientation='column'
                size='small'
                title={event.name}
                description={event.description}
                startDate={event.startDate}
                finalDate={event.endDate}
                id={event.id}
                logo={event.logo}
                divisions={event.ageDivisions}
                link={link}
              />
            );
          })}
        </S.EventsList>
      )}
    </>
  );
};

export default withRouter(MyEvents);
