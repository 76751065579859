export enum MembersFilterTypes {
  STAFF = 'STAFF',
  MEMBERS = 'MEMBERS',
}

export type JoinClubRole =
  | 'all'
  | 'coach'
  | 'player'
  | 'team-manager'
  | 'club-director';
