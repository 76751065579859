export const DEFAULT_FIELDS_STATE = {
  name: { value: '', required: true },
  displayName: { value: '', required: true },
  description: { value: '', required: false },
  allowApplications: { value: true, required: false },
  country: { value: '', required: true },
  state: { value: '', required: false },
  city: { value: '', required: true },
  instagram: { value: '', required: false },
  tiktok: { value: '', required: false },
  x: { value: '', required: false },
  facebook: { value: '', required: false },
  logo: { value: '', required: true },
  header: { value: '', required: false },
};
