import React, {
  memo,
  FC,
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';
import { CloseSmall } from '@icon-park/react';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IFinishGameModalProps {
  visible: boolean;
  requiredField: boolean;
  defaultReport: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const FinishGameModal: FC<IFinishGameModalProps> = memo(
  ({ visible, requiredField, defaultReport, onClose, onSubmit }) => {
    const [reportValue, setReportValue] = useState('');

    const handleChangeReport = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        setReportValue(event.currentTarget.value);
      },
      []
    );

    const handleSubmitForm = useCallback(() => {
      onSubmit(reportValue);
      setReportValue(reportValue);
      onClose();
    }, [reportValue, onSubmit, onClose]);

    const handleClose = useCallback(() => {
      onClose();
      setReportValue(defaultReport);
    }, [defaultReport, onClose]);

    useEffect(() => {
      setReportValue(defaultReport);
    }, [defaultReport]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={handleClose}
      >
        <S.Header>
          <BodyM $color='grey50'>Game Report</BodyM>
          <S.CloseButton onClick={handleClose}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.Header>
        <S.Container>
          {requiredField && (
            <S.Label>
              There is one or more RED or DOUBLE YELLOW CARDs in this match.
              Please provide the report.
            </S.Label>
          )}
          <S.TextareaField
            id='report'
            multiline
            rows={8}
            value={reportValue}
            placeholder='Write your game report here...'
            onChange={handleChangeReport}
          />
          <S.ButtonsGroup>
            <S.CancelButton isUpper onClick={handleClose}>
              Cancel
            </S.CancelButton>
            <S.ReportFilledButton
              isUpper
              $disabled={reportValue.length < 3 && requiredField}
              onClick={handleSubmitForm}
            >
              Finish game
            </S.ReportFilledButton>
          </S.ButtonsGroup>
        </S.Container>
      </S.StyledModal>
    );
  }
);

FinishGameModal.displayName = 'FinishGameModal';
