import React, { FunctionComponent } from 'react';
import { Lock, PreviewOpen, Unlock } from '@icon-park/react';
import { Switch } from 'antd';

import ConvertUtil from 'util/ConvertUtil';
import IconButton from 'components/v3/Buttons/IconButton';
import { COLORS, TitleH4 } from 'styles/v3/variables';
import { BreadCrumbRosterInformation } from 'components/v3/Breadcrumb/BreadCrumbRosterInformation';
import { CircleInformation } from '../../../../../Home/components/CirclesNested';

import {
  Container,
  RightContainer,
  TitleIconContainer,
  ClubIcon,
  LeftContainer,
  CoachesAndPlayersConainer,
} from './styles';

interface RosterInformationProps {
  logoClub: string;
  nameClub: string;
  isSubmitted: boolean;
  isDivisionLocked: boolean;
  coachesCircles: CircleInformation[];
  playersCircles: CircleInformation[];
  handleUpgradeRosterStatus: () => void;
  handleSelectedRoster: () => void;
}

export const RosterInformation: FunctionComponent<RosterInformationProps> = ({
  logoClub,
  nameClub,
  isSubmitted,
  isDivisionLocked,
  coachesCircles,
  playersCircles,
  handleUpgradeRosterStatus,
  handleSelectedRoster,
}) => {
  return (
    <Container>
      <RightContainer>
        <Switch
          checked={isSubmitted}
          onChange={handleUpgradeRosterStatus}
          disabled={isDivisionLocked}
        />

        {isSubmitted || isDivisionLocked ? (
          <Lock fill={COLORS.brandPrimary} size={24} />
        ) : (
          <Unlock fill={COLORS.grey400} size={24} />
        )}

        <TitleIconContainer>
          <ClubIcon>
            <img src={ConvertUtil.getMinioUrl(logoClub)} alt='Club Logo' />
          </ClubIcon>
          <TitleH4 $color='grey50'>{nameClub}</TitleH4>
        </TitleIconContainer>
      </RightContainer>

      <LeftContainer>
        <CoachesAndPlayersConainer>
          <BreadCrumbRosterInformation
            icon='HeadsetOne'
            text='Coaches'
            circlesUser={coachesCircles}
            status={isSubmitted ? 'locked' : 'open'}
            fillIconColor={COLORS.white}
            bgColor={isSubmitted ? COLORS.brandSecondary : COLORS.grey800}
            colorText={isSubmitted ? 'white' : 'grey300'}
          />
          <BreadCrumbRosterInformation
            icon='SoccerOne'
            text='Players'
            circlesUser={playersCircles}
            status={isSubmitted ? 'locked' : 'open'}
            fillIconColor={isSubmitted ? COLORS.grey950 : COLORS.white}
            bgColor={isSubmitted ? COLORS.brandPrimary : COLORS.grey800}
            colorText={isSubmitted ? 'grey950' : 'grey300'}
          />
        </CoachesAndPlayersConainer>

        <IconButton
          icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey800 }}
          onClick={handleSelectedRoster}
        />
      </LeftContainer>
    </Container>
  );
};
