import DraftEventBody from 'components/v3/DraftEvent/Body';
import DraftEventHeader from 'components/v3/DraftEvent/Header';
import SideModalHeader from 'components/v3/SideModal/Header';
import SideModalBody from 'components/v3/SideModal/Body';
import SetupEventHeader from 'components/v3/SetupEvent/Header';
import SetupEventBody from 'components/v3/SetupEvent/Body';
import EventDashboardBody from 'components/v3/EventDashboardLayout/Body';
import Footer from 'components/v3/SetupEvent/Footer';
import EditEventBody from 'components/v3/EditEvent/Body';
import { DraftEventContentWrapper } from 'components/v3/DraftEvent/styles';
import { SideModalContentWrapper } from 'components/v3/SideModal/styles';
import { SetupEventContentWrapper } from 'components/v3/SetupEvent/styles';
import * as React from 'react';
import * as S from './styles';

interface V3LayoutBaseProps {
  layout?: string;
  auth?: Keycloak.KeycloakInstance | undefined;
  noPaddingOnMobile?: boolean;
}

const V3LayoutBase: React.FunctionComponent<V3LayoutBaseProps> = (props) => {
  const { children, layout, auth, noPaddingOnMobile } = props;

  switch (layout) {
    case 'base':
      return <S.BaseWrapper className={layout}>{children}</S.BaseWrapper>;
    case 'base-desktop':
      return (
        <S.BaseDesktopWrapper className={layout}>
          {children}
        </S.BaseDesktopWrapper>
      );

    case 'draftEvent':
      return (
        <>
          <DraftEventContentWrapper className={layout}>
            <DraftEventHeader auth={auth} />
            <DraftEventBody>{children}</DraftEventBody>
          </DraftEventContentWrapper>
        </>
      );
    case 'sidebarModal':
      return (
        <SideModalContentWrapper className={layout}>
          <SideModalHeader />
          <SideModalBody>{children}</SideModalBody>
        </SideModalContentWrapper>
      );
    case 'setupEvent':
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} />
            <SetupEventBody noPaddingOnMobile={noPaddingOnMobile}>
              {children}
            </SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        </>
      );
    case 'editEvent':
      return (
        <SetupEventContentWrapper className={layout}>
          <SetupEventHeader auth={auth} />
          <EditEventBody>{children}</EditEventBody>
        </SetupEventContentWrapper>
      );
    case 'print':
      return <S.PrintWrapper>{children}</S.PrintWrapper>;
    case 'profile':
      return (
        <>
          <SetupEventContentWrapper className={layout}>
            <SetupEventHeader auth={auth} hasMenu={false} />
            <SetupEventBody>{children}</SetupEventBody>
            <Footer />
          </SetupEventContentWrapper>
        </>
      );
    case 'eventDashboard':
      return (
        <SetupEventContentWrapper className={layout}>
          <SetupEventHeader auth={auth} />
          <EventDashboardBody>{children}</EventDashboardBody>
          <Footer />
        </SetupEventContentWrapper>
      );
    default:
      return <S.BaseWrapper className={layout}>{children}</S.BaseWrapper>;
  }
};

export default V3LayoutBase;
