import { ScheduleMatch, ScheduleView } from 'models/Schedule/ScheduleModel';
import { getModalStyles } from 'pages/V3/EventManager/components/ScheduleCalendar/components/Scheduler/lib';
import ScheduleViewModal from 'pages/V3/EventManager/components/ScheduleCalendar/components/ScheduleViewModal/ScheduleViewModal';
import GameDetailsModal from 'pages/V3/EventManager/components/ScheduleCalendar/components/ScheduleViewModal/GameDetailsModal';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

type IProps = {
  currentSchedule: ScheduleView;
  opened: boolean;
  setOpened: (value: boolean) => void;
};

const PublishedSchedule: FC<IProps> = ({
  currentSchedule, opened,
  setOpened,
}) => {
  const location = useLocation();
  const [openedGameModal, setOpenedGameModal] = useState<boolean>(false);
  const [game, setGame] = useState<ScheduleMatch>({} as ScheduleMatch);
  const [day, setDay] = useState<Date | string>('');

  const modalStyles = useMemo(() => {
    return getModalStyles('530px');
  }, []);


  useEffect(() => { 
    if (currentSchedule?.published && location.state) {
      setOpened(true);
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={opened}
        shouldCloseOnEsc
        ariaHideApp={false}
        preventScroll
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpened(false)}
        style={modalStyles}
      >
        <ScheduleViewModal
          schedule={currentSchedule}
          setOpened={setOpened}
          setGame={setGame}
          setDay={setDay}
          openDetailModal={() => setOpenedGameModal(true)}
        />
      </Modal>
      <Modal
        isOpen={openedGameModal}
        shouldCloseOnEsc
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          setOpenedGameModal(false);
          setOpened(true);
        }}
        style={modalStyles}
      >
        <GameDetailsModal
          day={day}
          game={game}
          setOpened={setOpenedGameModal}
          setOpenedScheduleView={setOpened}
        />
      </Modal>
    </>
  );
};

export default PublishedSchedule;
