import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .i-icon-trash {
    &:before {
      left: 2px;
    }
  }

  .i-icon-play {
    &::before {
      top: 7px !important;
      left: 7px !important;
    }
  }

  .inactive {
    cursor: auto;

    &:hover {
      background-color: ${COLORS.white};
    }
  }
`;
