import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  position: relative;
`;

export const AlertMessageContainer = styled.div`
  padding: 20px 0 0;
  color: ${COLORS.supportError};
`;
