import { IGameReportPayload } from 'services/v3/Match/payload-interfaces';

import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import MatchService from 'services/v3/Match/MatchService';

export const useReportGame = () => {
  return useMutation({
    mutationFn: async (payload: IGameReportPayload) =>
      MatchService.reportGame(payload),
    onSuccess: async () => {
      notification.success({
        message: 'The game report was sent successfully!',
      });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to send to game report!',
        description: error.message,
      });
    },
  });
};
