import styled from 'styled-components';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { COLORS, BodyLBold, BodyXLBold, BodyL } from 'styles/v3/variables';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 104px 56px 56px;
  margin-top: 28px;
  border-radius: 24px;
  background-color: ${COLORS.grey950};
`;

export const ReportButton = styled(OutlinedButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  min-width: 108px;
`;

export const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid ${COLORS.brandPrimary};
`;

export const GameStatus = styled(BodyLBold)`
  margin-bottom: 8px;
  color: ${COLORS.white};
`;

export const Soccer = styled.span`
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.grey200};
`;

export const Time = styled.span`
  font-family: 'UF Display';
  font-size: 49px;
  line-height: 51px;
  color: ${COLORS.white};
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  padding-top: 8px;

  button {
    padding-left: 0;
    padding-right: 0;
    min-width: 96px;
  }
`;

export const TeamsSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 16px;
  margin-bottom: 10px;
`;

export const ExchangeIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  margin: 0;
  background-color: transparent;
  opacity: 0.5;
  pointer-events: none;

  &:hover {
    background-color: transparent;
  }

  &:before {
    position: static;
  }
`;

export const ColomnWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 44px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: calc(100% - 22px);

  button {
    width: 100%;
  }
`;

export const GroupSoccer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
  margin-bottom: 16px;
`;

export const SoccerItem =
  styled.button <
  { $active: boolean, $disabled: boolean } >
  `
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.grey200};
  border: 1px solid ${COLORS.brandPrimary};
  background-color: transparent;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.$active &&
    `
    color: ${COLORS.grey900};
    background-color: ${COLORS.brandPrimary};
  `}

  ${(props) =>
    props.$disabled &&
    `
    color: ${COLORS.grey200};
    border-color: ${COLORS.grey200};
    opacity: 0.6;
    pointer-events: none;
  `}
`;

export const TimePickerWrapper = styled.div`
  width: 200px;
  align-self: center;
  margin-bottom: 12px;

  .ant-picker-input input {
    margin: 0;
    text-align: center;
    padding-left: 0 !important; // Due to library restriction
    font-family: 'Boxed Medium';
    font-weight: 500;
    font-size: 22px;
    border-color: #484848 !important; // Due to library restriction
    background-color: ${COLORS.grey1000} !important; // Due to library restriction
    transition-duration: 300ms;

    &:hover,
    &:focus {
      border-color: ${COLORS.brandPrimary} !important; // Due to library restriction
    }
  }

  .ant-picker-focused {
    box-shadow: none;
  }
`;

export const OutlinedButtonStyled = styled(OutlinedButton)`
  &:hover {
    background-color: transparent;
    color: ${COLORS.white};
    border-color: ${COLORS.grey400};
  }
`;

export const PenaltyTitle = styled(BodyXLBold)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 22px;
  text-transform: uppercase;
  color: ${COLORS.brandPrimary};
`;

export const PenaltyActionButtons = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  padding-top: 22px;

  button {
    padding-left: 0;
    padding-right: 0;
    min-width: 140px;
  }
`;

export const StartButton =
  styled(FilledButton) <
  { $paused: boolean } >
  `
  ${(props) =>
    props.$paused &&
    `
    background-color: #8d0000;
    border-color: #8d0000;
    color: ${COLORS.white};

    &:hover {
      background-color: ${COLORS.supportError};
      border-color: ${COLORS.supportError};
      color: ${COLORS.white};
    }
  `}
`;

export const ActionEmptyState = styled(BodyL)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
`;
