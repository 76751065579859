import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr ${toRem(40)};
  column-gap: ${toRem(24)};
  background: ${COLORS.grey950};
  cursor: pointer;
  &:hover {
    background: ${COLORS.grey850};
  }

  align-content: center;
  align-items: center;

  padding: 0;

  border-bottom: 1px solid ${COLORS.grey800};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${toRem(25)};
  row-gap: ${toRem(2)};
  padding: ${toRem(8)} 0;
`;

export const SelectButtonContainer = styled.div`
  display: flex;
  width: ${toRem(40)};
  height: ${toRem(40)};
  align-items: center;
  justify-content: center;
  margin-left: ${toRem(-12)};

  ${Container}:hover & {
    border-radius: ${toRem(8)};
    background: ${COLORS.grey800};
  }

  cursor: pointer;

  .i-icon svg {
    width: ${toRem(24)};
    height: ${toRem(24)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.grey100};
    }
  }
`;
