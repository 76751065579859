import React, { memo, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CalendarThree, Local } from '@icon-park/react';
import moment from 'moment';

import { EventView } from 'admin/models/event/Event';
import { InfoCard } from 'pages/V3/event/components/InfoCard/InfoCard';
import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { EventTierCard } from 'components/v3/Cards/EventTierCard/EventTierCard';

import { useGetProductById } from 'hooks/v3/products/useGetProductById/useGetProductById';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';

import {
  BodyLBold,
  BodyM,
  BodyMBold,
  COLORS,
  TitleH2,
  TitleH3,
} from 'styles/v3/variables';

import { VenueModal } from './components/VenueModal/VenueModal';
import { ApplyModal } from './components/ApplyModal/ApplyModal';
import { InfoModal } from './components/InfoModal';
import { MultiplierInfoModal } from './components/MultiplierInfoModal/MultiplierInfoModal';
import PublishedSchedule from './components/PublishedSchedule/PublishedSchedule';

import * as S from './styles';

interface IInformationSectionProps {
  event?: EventView;
}

export const InformationSection = memo((props: IInformationSectionProps) => {
  const { event } = props;

  const { eventId } = useParams<{ eventId: string }>();
  const { data: currentSchedule } = useGetScheduleByEventId(eventId);

  const [isVenueModalOpen, setIsVenueModalOpen] = useState(false);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [publishedOpened, setPublishedOpened] = useState(false);

  const { data: rosterFeeProduct, isLoading } = useGetProductById(
    event?.data?.paymentInfo?.rosterFeeProductId ?? ''
  );

  const multiplier = useMemo(() => {
    return event?.data?.multipliers?.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.multiplierValue as number),
      1
    )
  }, [event]);

  const venue = useMemo(() => {
    if (event?.venues.length) {
      return event?.venues[0];
    }

    return null;
  }, [event]);

  const venues = event?.venues ?? [];

  const venueAbbr = venue ? venue.abbreviation : 'TBA';

  const venueAddress = venue
    ? `${venue.address.line1}${venue.address.line2 ? `, ${venue.address.line2}` : ''}, ${venue.address.city}`
    : '';

  const handleOpenVenueModal = useCallback(() => {
    setIsVenueModalOpen(true);
  }, []);

  const handleCloseVenueModal = useCallback(() => {
    setIsVenueModalOpen(false);
  }, []);

  return (
    <>
      <VenueModal
        visible={isVenueModalOpen}
        venues={venues}
        onClose={handleCloseVenueModal}
      />
      <ApplyModal
        isOpen={isApplyModalOpen}
        handleIsOpen={setIsApplyModalOpen}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleIsOpen={setIsInfoModalOpen}
        emailContact={event?.data?.eventInfo?.emailContact}
        phoneContact={event?.data?.eventInfo?.phoneContact}
      />
      <S.InformationSection>
        <S.MainInformationSection>
          <S.TextsWrapper>
            <S.GeneralInfoTextWrapper>
              <TitleH3 $isUpper>{event?.name}</TitleH3>
              {venue && (
                <BodyLBold $color='grey200'>
                  {venue.address.city}, {venue.address.state}
                </BodyLBold>
              )}
              <BodyM $color='grey300'>{event?.description}</BodyM>
            </S.GeneralInfoTextWrapper>
            <S.PriceTextWrapper>
              {rosterFeeProduct?.formattedPrice && (
                <TitleH2 $color='brandPrimary'>
                  ${rosterFeeProduct?.formattedPrice}
                </TitleH2>
              )}
              {!rosterFeeProduct?.formattedPrice && !isLoading && (
                <BodyM $color='brandPrimary'>
                  There is no set price for this event yet.
                </BodyM>
              )}
              {rosterFeeProduct?.formattedPrice && (
                <BodyM $color='brandPrimary'>PER TEAM</BodyM>
              )}
            </S.PriceTextWrapper>
          </S.TextsWrapper>
          <S.ActionsWrapper>
            <FilledButton isUpper onClick={() => setIsApplyModalOpen(true)}>
              Apply now
            </FilledButton>
            <OutlinedButton
              isUpper
              color='dark-white'
              onClick={() => setIsInfoModalOpen(true)}
            >
              Contact Event Manager
            </OutlinedButton>
            <OutlinedButton
              isUpper
              onClick={() => setPublishedOpened(true)}
              disabled={!currentSchedule?.published}
            >
              View Schedule
            </OutlinedButton>
          </S.ActionsWrapper>
        </S.MainInformationSection>
        <S.CardsWrapper>
          <EventTierCard
            multiplier={multiplier ?? 1}
            extra={
              <MultiplierInfoModal
                multiplier={multiplier}
                multipliers={event?.data?.multipliers}
              />
            }
          />
          <InfoCard
            title='Dates'
            icon={<CalendarThree color={`${COLORS.grey700}`} size={50} />}
          >
            <S.DatesText>
              <BodyMBold $isUpper $color='grey400'>
                Starts
              </BodyMBold>
              <BodyM $isUpper $color='grey100'>
                {moment(event?.startDate).format('MMM DD yy')}
              </BodyM>
            </S.DatesText>
            <S.DatesText>
              <BodyMBold $color='grey400' $isUpper>
                Finishes
              </BodyMBold>
              <BodyM $isUpper $color='grey100'>
                {moment(event?.endDate).format('MMM DD yy')}
              </BodyM>
            </S.DatesText>
          </InfoCard>
          <InfoCard title={`Venue: ${venueAbbr}`} icon={<Local size={50} />}>
            <S.BodyUppercase>
              <BodyMBold $color='grey400'>
                {venueAddress}
                {venues.length > 1 && (
                  <S.ShowMore onClick={handleOpenVenueModal}>
                    Show More
                  </S.ShowMore>
                )}
              </BodyMBold>
            </S.BodyUppercase>
          </InfoCard>
        </S.CardsWrapper>
        {currentSchedule && (
          <PublishedSchedule
            currentSchedule={currentSchedule}
            opened={publishedOpened}
            setOpened={setPublishedOpened}
          />
        )}
      </S.InformationSection>
    </>
  );
});

InformationSection.displayName = 'InformationSection';
