import React, { memo, FC } from 'react';
import { CloseSmall } from '@icon-park/react';

import { VenueView } from 'admin/models/event/Venue';

import { BodyLBold, BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IVenueModalProps {
  visible: boolean;
  venues: VenueView[];
  onClose: () => void;
}

export const VenueModal: FC<IVenueModalProps> = memo(
  ({ visible, venues, onClose }) => {
    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={onClose}
      >
        <S.Header>
          <BodyM $color='grey50'>Venues</BodyM>
          <S.CloseButton onClick={onClose}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.Header>
        <S.Container>
          {venues.map((venue) => (
            <S.Card>
              <BodyLBold>Venue: {venue.abbreviation || 'TBA'}</BodyLBold>
              <S.CardAddress>
                {venue.address.line1}
                {venue.address.line2 ? `, ${venue.address.line2}` : ''},{' '}
                {venue.address.city}
              </S.CardAddress>
            </S.Card>
          ))}
        </S.Container>
      </S.StyledModal>
    );
  }
);

VenueModal.displayName = 'VenueModal';
