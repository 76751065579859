import React, { FC, MouseEvent } from 'react';
import Keycloak from 'keycloak-js';
import { matchPath, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@icon-park/react/es/all';

import UserType from 'models/User/UserTypeModel';

import { onClose, onToggle } from 'redux/v3/profileModal/actions';
import { ApplicationState } from 'redux/store';

import useScreenType from 'hooks/useScreenType';

import Logo from 'components/v3/Elements/Logo/Logo';
import AvatarAction from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';
import Notifications from 'components/v3/Elements/Notifications/Notifications';
import { AccountModal } from 'components/v3/AccountModal';

import { BodyL } from 'styles/v3/variables';

import ConvertUtil from 'util/ConvertUtil';

import menu from '../menu.json';
import * as S from './styles';

interface IHeaderProps {
  auth?: Keycloak.KeycloakInstance;
  hasMenu?: boolean;
}

const Header: FC<IHeaderProps> = ({ auth, hasMenu = true }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isMobile } = useScreenType();

  const userName = ((auth?.tokenParsed as any)?.name as string) ?? '';

  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );

  const isProfileModalOpen = useSelector(
    (state: ApplicationState) => state.profileModal.isOpen
  );

  const handleModalState = () => {
    if (isProfileModalOpen) dispatch(onClose());
  };

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    handleModalState();

    window.location.href = `${window.location.protocol}//${window.location.host}/admin-panel`;
  };

  return (
    <S.Wrapper hasMenu={hasMenu}>
      <AccountModal />
      {isMobile && (
        <>
          <S.MenuWrapper>
            {hasMenu && (
              <S.MenuContainer>
                {menu.map((i) => {
                  const isAdmin = i.key === 'admin';
                  if (
                    currentUser?.type === UserType.STAFF ||
                    currentUser?.types?.includes(UserType.STAFF)
                  ) {
                    return (
                      <S.MenuItem
                        $icon={i.icon}
                        key={i.name}
                        className={
                          matchPath(location.pathname, i.path) ? 'active' : ''
                        }
                      >
                        <Link
                          to={i.link}
                          onClick={isAdmin ? handleClick : handleModalState}
                        >
                          <Icon type={i.icon} />
                          <BodyL>{i.name}</BodyL>
                        </Link>
                      </S.MenuItem>
                    );
                  }
                  return (
                    !isAdmin && (
                      <S.MenuItem
                        $icon={i.icon}
                        key={i.name}
                        className={
                          matchPath(location.pathname, i.path) ? 'active' : ''
                        }
                      >
                        <Link to={i.link} onClick={handleModalState}>
                          <Icon type={i.icon} />
                          <BodyL>{i.name}</BodyL>
                        </Link>
                      </S.MenuItem>
                    )
                  );
                })}
                {userName && (
                  <>
                    <S.MenuItem
                      key='mobile_profile'
                      onClick={() => dispatch(onToggle())}
                      className={isMobile && isProfileModalOpen ? 'active' : ''}
                    >
                      <S.MobileProfileWrapper>
                        <S.UserProfileImgMobile>
                          <img
                            src={ConvertUtil.getMinioUrl(currentUser.photo)}
                            alt='Profile User'
                          />
                        </S.UserProfileImgMobile>
                        <BodyL>Profile</BodyL>
                      </S.MobileProfileWrapper>
                    </S.MenuItem>
                  </>
                )}
              </S.MenuContainer>
            )}
          </S.MenuWrapper>
        </>
      )}
      {!isMobile && (
        <>
          <S.LogoContainer>
            <Link to='/v3/home-page'>
              <Logo minimal color='grey1000' />
            </Link>
          </S.LogoContainer>
          <S.MenuWrapper>
            {hasMenu && (
              <S.MenuContainer>
                {menu.map((i) => {
                  const isAdmin = i.key === 'admin';
                  if (
                    currentUser?.type === UserType.STAFF ||
                    currentUser?.types?.includes(UserType.STAFF)
                  ) {
                    return (
                      <S.MenuItem
                        $icon={i.icon}
                        key={i.name}
                        className={
                          matchPath(location.pathname, i.path) ? 'active' : ''
                        }
                      >
                        <Link
                          to={i.link}
                          onClick={isAdmin ? handleClick : () => {}}
                        >
                          <Icon type={i.icon} />
                          <BodyL>{i.name}</BodyL>
                        </Link>
                      </S.MenuItem>
                    );
                  }
                  return (
                    !isAdmin && (
                      <S.MenuItem
                        $icon={i.icon}
                        key={i.name}
                        className={
                          matchPath(location.pathname, i.path) ? 'active' : ''
                        }
                      >
                        <Link to={i.link}>
                          <Icon type={i.icon} />
                          <BodyL>{i.name}</BodyL>
                        </Link>
                      </S.MenuItem>
                    )
                  );
                })}
              </S.MenuContainer>
            )}
          </S.MenuWrapper>
          <S.ProfileContainer>
            <Notifications />
            {userName && <AvatarAction />}
          </S.ProfileContainer>
        </>
      )}
    </S.Wrapper>
  );
};

export default Header;
