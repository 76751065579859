import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import RequestService from 'services/v3/Request/RequestService';
import {
  GetMyRequestsToClubProps,
  defaultGetMyRequestsToClubProps,
} from 'services/v3/Request/types';

export function useGetMyRequestsToClub({
  clubId,
  status,
  perPage = defaultGetMyRequestsToClubProps.perPage,
  page = defaultGetMyRequestsToClubProps.page,
}: GetMyRequestsToClubProps) {
  const { id: accountId, types } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const { data, isLoading, error } = useQuery(
    ['get-my-request-to-club', clubId, perPage, page, types, accountId],
    async () =>
      await RequestService.getMyRequestsToClub({ clubId, status, perPage })
  );

  return {
    ...data,
    data: data?.data,
    isLoading,
    error,
  };
}
