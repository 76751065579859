import { BracketType } from 'models/Bracket/BracketModel';
import { FunctionComponent } from 'react';
import { BracketRender, BracketRenderProps } from '../../BracketRender';
import { CompassDraw4Render } from './CompassDraw4';
import { SingleElimination2Render } from './SingleElimination2';
import { SingleElimination3Render } from './SingleElimination3';
import { SingleElimination4Render } from './SingleElimination4';
import { SingleElimination5Render } from './SingleElimination5';
import { SingleElimination6Render } from './SingleElimination6';
import { CompassDraw8Render } from './CompassDraw8';

export const getBracketRender: (
  type: BracketType
) => FunctionComponent<BracketRenderProps> = (type) => {
  switch (type) {
    case 'COMPASS_DRAW_4':
      return CompassDraw4Render;
    case 'COMPASS_DRAW_8':
      return CompassDraw8Render;
    case 'SINGLE_ELIMINATION_2':
      return SingleElimination2Render;
    case 'SINGLE_ELIMINATION_3':
      return SingleElimination3Render;
    case 'SINGLE_ELIMINATION_4':
      return SingleElimination4Render;
    case 'SINGLE_ELIMINATION_5':
      return SingleElimination5Render;
    case 'SINGLE_ELIMINATION_6':
      return SingleElimination6Render;
  }

  return BracketRender;
};
