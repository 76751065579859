import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRight } from '@icon-park/react';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';
import OrderService from 'services/v3/Order/OrderService';
import { CheckoutProps } from 'services/v3/Order/types';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButton from 'components/v3/Buttons/FilledButton';
import Loading from 'components/v3/Loading/Loading';

import { useGetMyClubsByUserType } from 'hooks/v3/clubs/useGetMyClubsByUserType/useGetMyClubsByUserType';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useGetProductById } from 'hooks/v3/products/useGetProductById/useGetProductById';

import { TitleH2 } from 'styles/v3/variables';

import { notification } from 'components/v3/Notification/notification';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import useGetRosterFee from 'hooks/v3/products/useGetRosterFee/useGetRosterFee';
import { DivisionInfo } from './components/DivisionInfo/DivisionInfo';
import { CheckoutHeader } from './components/CheckoutHeader/CheckoutHeader';
import { CheckoutGeneralInfo } from './components/CheckoutGeneralInfo/CheckoutHeader';
import { CheckoutBox } from './components/CheckoutBox/CheckoutBox';
import { getDivisionInfo } from './utils';

import * as S from './styles';

const CheckoutAsClubDirector: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userRole = location.pathname?.includes('checkout-as-club-director')
    ? UserType.CLUB_DIRECTOR
    : UserType.TEAM_MANAGER;

  const chekoutAs =
    userRole === UserType.CLUB_DIRECTOR
      ? 'signup-as-club-director'
      : 'signup-as-team-manager';

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );
  const { data: currentUser } = useSelector(
    (state: ApplicationState) => state.currentUser
  );
  const isUFEvent = !!eventData?.UFEvent;
  const isStaff =
    currentUser?.type === UserType.STAFF ||
    currentUser?.types?.includes(UserType.STAFF);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const params: { eventId: string } = useParams();

  const { data: currentEvent } = useGetEventById(params.eventId);
  const { data: rosterFeeUF } = useGetRosterFee();

  const { data: rosterFeeProduct } = useGetProductById(
    currentEvent?.data?.paymentInfo?.rosterFeeProductId
  );

  const { data: myClubs } = useGetMyClubsByUserType(userRole);

  const { divisions, currentDivisions, clubId, coupon } = useSelector(
    (state: ApplicationState) => state.signupEvent.data
  );

  const ageDivisions = useMemo(() => {
    return currentDivisions.filter((div) => !!div.targetNumberOfTeams) ?? [];
  }, [currentDivisions]);

  const myClub = useMemo(() => {
    if (!myClubs || !clubId) return null;

    return myClubs?.find((club) => club.id === clubId) || null;
  }, [myClubs, clubId]);

  const price = useMemo(() => {
    if (isUFEvent) {
      let defaultRosterFee = rosterFeeUF?.relatedPrices.find(
        (feeUF) => feeUF.id === eventData.data?.paymentInfo?.rosterFeePriceId
      )?.unitAmount;

      if (!defaultRosterFee) {
        defaultRosterFee = rosterFeeUF?.product.price ?? 129500;
      }

      return defaultRosterFee / 100;
    }
    return rosterFeeProduct?.formattedPrice ?? 0;
  }, [isUFEvent, rosterFeeUF, eventData, rosterFeeProduct]);

  function handlePrevious() {
    history.push(`/v3/event/${params.eventId}/${chekoutAs}`);
  }

  const divisionInfoData = useMemo(() => {
    return ageDivisions.reduce<{ years: number[], genders: string[] }>(
      (cur, acc) => {
        acc.years.forEach((y) => {
          if (!cur.years.includes(y)) {
            cur.years.push(y);
          }
        });
        if (!cur.genders.includes(acc.gender)) {
          cur.genders.push(acc.gender);
        }
        return cur;
      },
      {
        years: [],
        genders: [],
      }
    );
  }, [ageDivisions]);

  const handleCheckout = async () => {
    if (rosterFeeProduct) {
      let cartItem = {
        productId: rosterFeeProduct.id || '',
        quantity: divisions.reduce((cur, acc) => {
          return cur + acc.quantity;
        }, 0),
        type: 'EVENT_ROSTER_FEE',
        b2bEventRosterFee: {
          divisions: divisions.map((division) => ({
            id: division.divisionId,
            name: division.name,
            description: getDivisionInfo(
              ageDivisions.find((d) => d.id === division.divisionId)
            ),
            quantity: division.quantity,
            itemPrice: rosterFeeProduct.formattedPrice,
            total: division.quantity * rosterFeeProduct.formattedPrice,
          })),
          eventId: currentEvent?.id || '',
          total: divisions.reduce((cur, acc) => {
            return cur + acc.quantity * rosterFeeProduct.formattedPrice;
          }, 0),
          club: {
            name: myClub?.name || '',
            id: myClub?.id || '',
            logo: myClub?.logo || '',
          },
        },
      };
      const type = isUFEvent ? 'UF_EVENT_ROSTER_FEE' : 'EVENT_ROSTER_FEE';
      const priceId = eventData.data?.paymentInfo?.rosterFeePriceId ?? '';

      if (isUFEvent && priceId) {
        cartItem = Object.assign(cartItem, { priceId });
      }
      const checkoutDto: CheckoutProps = {
        cart: [cartItem],
        type,
        cancelUrl: `/v3/event/${currentEvent?.id}/${
          userRole === UserType.CLUB_DIRECTOR
            ? 'checkout-as-club-director'
            : 'checkout-as-team-manager'
        }`,
        couponId: coupon?.id || '',
      };

      setIsLoadingPayment(true);

      OrderService.getCheckoutStripeUrl(checkoutDto)
        .then((res) => {
          if (res.httpCode === 200) {
            if (isUFEvent && isStaff) {
              history.push('/v3/events?tab=created-by-me');
              notification.success({
                message: 'The payment was completed successfully.',
              });
            } else {
              window.location.href = res.data.paymentUrl;
            }
          } else if (
            res.message === 'This order was already paid for this Club'
          ) {
            // eslint-disable-next-line no-alert
            alert('You have already paid for this event');
          } else {
            // eslint-disable-next-line no-alert
            alert(
              res.message ||
                'Error on checkout check event information and try again'
            );
          }
        })
        .finally(() => {
          setIsLoadingPayment(false);
        });
    }
  };

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));
  }, [eventId]);

  if (isLoadingPayment) {
    return <Loading />;
  }

  return (
    <S.CheckoutAsClubDirectorContainer>
      <S.DivisionWrapper>
        <S.Header>
          <TextButtonWithIcon
            color='primary'
            align='flex-end'
            reverse
            icon='back'
            onClick={handlePrevious}
          >
            Go back
          </TextButtonWithIcon>
          <TitleH2>SELECT YOUR AGE DIVISIONS</TitleH2>
        </S.Header>
        <DivisionInfo
          ageDivisions={ageDivisions}
          years={divisionInfoData.years}
          genders={divisionInfoData.genders}
          price={price}
          divisions={divisions}
        />
      </S.DivisionWrapper>
      <S.CheckoutWrapper>
        {currentEvent && (
          <>
            <CheckoutHeader
              image={currentEvent.logo}
              name={currentEvent.name}
            />
            <CheckoutGeneralInfo event={currentEvent} />
            <CheckoutBox
              price={price}
              divisions={divisions}
              event={currentEvent}
            />
            <FilledButton
              className='btn-checkout'
              disabled={divisions.length === 0 || isLoadingPayment}
              onClick={handleCheckout}
              color='primary'
              isUpper
            >
              Continue To Payment <ArrowRight /> {isLoadingPayment && '...'}
            </FilledButton>
          </>
        )}
      </S.CheckoutWrapper>
    </S.CheckoutAsClubDirectorContainer>
  );
};

export default withRouter(CheckoutAsClubDirector);
