import React, { forwardRef } from 'react';
import { StyledInfoTooltip, StyledTooltipContainer } from './styles';
import { InfoTooltipProps } from './types';

type Props = InfoTooltipProps & {
  info: {
    home: string,
    away: string,
    time: string,
    message?: string,
  },
};

const InfoTooltip = forwardRef<HTMLDivElement, Props>(
  ({ variant, info }, ref) => {
    return (
      <StyledInfoTooltip ref={ref} className='tooltip'>
        <StyledTooltipContainer variant={variant}>
          {info.message ? (
            <div>{info.message}</div>
          ) : (
            <>
              <div>
                <span>Home: {info.home}</span>
                <span>Away: {info.away}</span>
              </div>
              <div>{info.time}</div>
            </>
          )}
        </StyledTooltipContainer>
      </StyledInfoTooltip>
    );
  }
);

InfoTooltip.displayName = 'InfoTooltip';

export default InfoTooltip;
