import React, { FunctionComponent, useMemo } from 'react';
import { COLORS } from 'styles/v3/variables';
import { BracketMatchDTO } from 'models/Bracket/BracketModel';
import { BracketRenderProps } from '../../BracketRender';
import { BracketColumn } from '../../styles';
import { BracketGame } from '../BracketGame';

export const CompassDraw4Render: FunctionComponent<BracketRenderProps> = ({
  matches,
  bracketId,
}) => {
  const [west, base, east] = useMemo(() => {
    const columns: BracketMatchDTO[][] = [[], [], []];

    matches.forEach((match) => {
      switch (match.compassDrawPosition) {
        case 'WEST':
          columns[0].push(match);
          break;
        case 'BASE':
          columns[1].push(match);
          break;
        case 'EAST':
          columns[2].push(match);
          break;
      }
    });

    return columns;
  }, [matches]);
  return (
    <>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={west[0]}
          to={{
            color: COLORS.supportError,
            up: true,
            down: true,
          }}
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={base[0]}
          from={{
            color: COLORS.supportError,
            down: true,
          }}
          to={{
            down: true,
            color: COLORS.brandPrimary,
          }}
          showPens
          titleSourceEmpty='TBA'
        />
        <BracketGame
          bracketId={bracketId}
          match={base[1]}
          from={{
            color: COLORS.supportError,
            up: true,
          }}
          to={{
            up: true,
            color: COLORS.brandPrimary,
          }}
          showPens
          titleSourceEmpty='TBA'
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={east[0]}
          from={{
            color: COLORS.brandPrimary,
            up: true,
            down: true,
          }}
        />
      </BracketColumn>
    </>
  );
};
