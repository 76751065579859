import React, { useState } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { BodyLBold, BodyM } from 'styles/v3/variables';
import { ArrowRight, Check, Close } from '@icon-park/react';
import ConvertUtil from 'util/ConvertUtil';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import { useTeamPlacement } from '../../context/TeamPlacementContext';
import * as S from './styles';
import { findRosterById } from '../../utils';

type TeamCardType = {
  name?: string;
  teamId?: string;
  logoSrc?: string;
  rosterId?: string;
  teamOrder?: string;
  poolOrder?: string;
  withClearAction?: boolean;
};

export const TeamCard = ({
  name,
  teamId,
  logoSrc,
  rosterId,
  poolOrder,
  teamOrder,
  withClearAction = false,
}: TeamCardType) => {
  const {
    rosters,
    setRosters,
    allRosters,
    selectedDivision,
    updateDivisionFn,
    setSelectedDivision,
  } = useTeamPlacement();

  const [selectedPoolId, setSelectedPoolId] = useState('');
  const [teamMenuVisible, setTeamMenuVisible] = useState(false);

  const findIfTeamAlreadySelected = (id: string) => {
    if (selectedDivision) {
      const team = selectedDivision?.pools
        .flatMap((pool) => pool.teams)
        .find((item) => item.id === id);

      if (team?.rosterId) return true;

      return false;
    }

    return false;
  };

  const handlePoolSelect = (poolId: any) => {
    setSelectedPoolId(poolId);
    setTeamMenuVisible(true);
  };

  const handleSelectTeam = async (id: string) => {
    if (selectedDivision) {
      let updatedRosters = rosters;

      const newPools = selectedDivision.pools.map((pool) => {
        if (pool.id === selectedPoolId) {
          const teams = pool.teams.map((team) => {
            if (team.id === id) {
              if (team.rosterId && rosterId && team.rosterId !== rosterId) {
                const previousRoster = findRosterById(
                  team.rosterId,
                  allRosters
                );
                updatedRosters = [...(updatedRosters as any), previousRoster];
              }

              if (updatedRosters) {
                updatedRosters = updatedRosters
                  .filter((r) => r.id !== rosterId)
                  .map((r) =>
                    r.id === team.rosterId ? { ...r, rosterId } : r
                  );
              }

              return { ...team, rosterId };
            }

            return team;
          });

          return { ...pool, teams };
        }

        return pool;
      });

      const newDivision = {
        ...selectedDivision,
        pools: newPools,
      };

      setSelectedDivision(newDivision);
      setRosters(updatedRosters);
      setTeamMenuVisible(false);

      await updateDivisionFn({ data: newDivision });
    }
  };

  const handleRemoveTeam = async () => {
    if (selectedDivision) {
      let updatedRosters = rosters;

      const newPools = selectedDivision.pools.map((pool) => ({
        ...pool,
        teams: pool.teams.map((team) =>
          team.id === teamId ? { ...team, rosterId: undefined } : team
        ),
      }));

      const rosterToAdd = findRosterById(rosterId, allRosters);

      if (rosterToAdd) {
        if (updatedRosters) {
          updatedRosters = [...updatedRosters, rosterToAdd];
        }
      }

      const newDivision = {
        ...selectedDivision,
        pools: newPools,
      };

      setSelectedDivision(newDivision);
      setRosters(updatedRosters);

      await updateDivisionFn({ data: newDivision });
    }
  };

  const handleDelete = () => {
    confirm({
      message: 'Are you sure that you want to delete this club?',
      onOk: handleRemoveTeam,
    });
  }

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.Flex>
          <Avatar src={ConvertUtil.getMinioUrl(logoSrc)} />
        </S.Flex>
        <S.NameWrapper>
          <BodyLBold $color='white'>{name}</BodyLBold>
          {poolOrder && teamOrder && (
            <S.OrderWrapper>
              <BodyLBold $color='white'>
                ({poolOrder}
                {teamOrder})
              </BodyLBold>
            </S.OrderWrapper>
          )}
        </S.NameWrapper>
      </S.HeaderWrapper>
      {!withClearAction ? (
        <Dropdown
          disabled={!selectedDivision?.pools?.length}
          trigger={['contextMenu']}
          overlay={
            <Menu
              selectable
              onSelect={({ key }) => handleSelectTeam(key.toString())}
            >
              {selectedDivision?.pools
                .find((pool) => pool.id === selectedPoolId)
                ?.teams.map((team) => (
                  <Menu.Item key={team.id}>
                    <S.MenuTextWrapper>
                      {findIfTeamAlreadySelected(team.id) && (
                        <Check size={20} />
                      )}
                      <S.TeamNameWrapper>
                        <BodyM $color='grey200'>{team.name}</BodyM>
                        <BodyM $color='grey200'>{team.order}</BodyM>
                      </S.TeamNameWrapper>
                    </S.MenuTextWrapper>
                  </Menu.Item>
                ))}
            </Menu>
          }
          visible={teamMenuVisible}
          onVisibleChange={setTeamMenuVisible}
        >
          <Dropdown
            trigger={['click']}
            disabled={!selectedDivision?.pools?.length}
            overlay={
              <S.CustomMenu onClick={({ key }) => handlePoolSelect(key)}>
                {selectedDivision?.pools.map((pool) => (
                  <S.CustomMenu.Item key={pool.id}>
                    <S.MenuTextWrapper>
                      {pool.totalTeams ===
                        pool.teams.filter((item) => Boolean(item.rosterId))
                          .length && <Check size={20} />}
                      <S.TeamNameWrapper>
                        <BodyM $color='grey200'>{pool.name}</BodyM>
                        <BodyM $color='grey200'>{pool.order}</BodyM>
                      </S.TeamNameWrapper>
                    </S.MenuTextWrapper>
                  </S.CustomMenu.Item>
                ))}
              </S.CustomMenu>
            }
          >
            <S.MenuAction>
              <ArrowRight size={20} />
            </S.MenuAction>
          </Dropdown>
        </Dropdown>
      ) : (
        <Close size={24} onClick={handleDelete} />
      )}
    </S.Container>
  );
};
