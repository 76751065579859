import React from 'react';
import { Right } from '@icon-park/react';
import { AgeDivisionItemProps } from './types';

import * as S from './styles';
import { DivisionInfo } from '../DivisionInfo/DivisionInfo';

export const AgeDivisionItem = ({
  ageDivision,
  event,
  handleSelectDivision,
}: AgeDivisionItemProps) => {
  return (
    <S.Container onClick={handleSelectDivision}>
      <S.Bar $color={ageDivision.color ?? '#000000'} />
      <DivisionInfo ageDivision={ageDivision} showTitle />
      <S.SelectButtonContainer>
        <Right className='reset' />
      </S.SelectButtonContainer>
    </S.Container>
  );
};
