import React from 'react';

import { BodyL } from 'styles/v3/variables';
import * as S from '../../styles';

export const EmptyState = () => (
  <S.EmptyState>
    <BodyL $color='grey400'>
      We did&apos;t find what you are looking for. Try redoing your search.
    </BodyL>
  </S.EmptyState>
);
