import React from 'react';

import { Avatar, Image } from 'antd';

import ConvertUtil from 'util/ConvertUtil';
import FallbackImage from 'assets/imgs/event_placeholder.png';
import FallbackAvatarImage from 'assets/imgs/avatar_placeholder.png';
import * as S from './styles';

type EventHeaderProps = {
  logoSrc?: string;
  backgroundSrc?: string;
};

export const EventHeader = ({ logoSrc, backgroundSrc }: EventHeaderProps) => {
  return (
    <>
      <S.HeaderWrapper>
        <Image
          width='100%'
          height='150px'
          preview={false}
          alt='background'
          src={
            backgroundSrc
              ? ConvertUtil.getMinioUrl(backgroundSrc)
              : FallbackImage
          }
        />
      </S.HeaderWrapper>
      <S.AvatarWrapper>
        <Avatar
          size={140}
          style={{ backgroundColor: 'white' }}
          src={logoSrc ? ConvertUtil.getMinioUrl(logoSrc) : FallbackAvatarImage}
        />
      </S.AvatarWrapper>
    </>
  );
};
