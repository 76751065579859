import { TimePicker } from 'antd';
import styled from 'styled-components';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const EventInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 ${toRem(30)} ${toRem(24)};
`;

export const EventInfoFormWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: ${toRem(48)};
  }
`;

export const EventInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  legend {
    margin: ${toRem(6)} 0 ${toRem(24)};
  }

  .field-description {
    font-weight: 500;
    > span {
      font-weight: 700;
    }
  }

  fieldset {
    button:last-child {
      width: 100%;
    }

    .general-event-info {
      margin: ${toRem(4)} 0 ${toRem(24)} ${toRem(16)};
    }
    .general-event-input {
      & + .general-event-input {
        margin-top: 1.5rem;
      }
    }
    .booster-item {
      & + .booster-item {
        margin-top: ${toRem(24)};
      }
    }
  }

  bottom {
    width: 100%;
  }
`;

export const EventInfoSummary = styled.div`
  width: 100%;
  max-width: 384px;

  .summary-subtitle {
    margin-bottom: 1rem;
  }

  .trophy-image {
    max-width: 50px;
  }

  .general-event-selected {
    margin: ${toRem(14)} 0 ${toRem(30)};
  }
`;

export const EventInfoSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${toRem(30)};

  .summary-title {
    margin: ${toRem(30)} 0 ${toRem(4)};
  }
`;

export const EventInfoSummaryBody = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${toRem(10)};
`;

export const EventInfoSummaryMapWrapper = styled.div`
  max-width: 324px;
  max-height: 110px;
  display: flex;
  margin-bottom: ${toRem(30)};
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    position: absolute;
    z-index: 1;
  }

  img {
    object-fit: cover;
  }
`;

export const EventInfoSummaryEventSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

  h4,
  p {
    margin: 0;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  position: relative;
  font-family: 'Boxed Regular';
  font-weight: 500;
  border: 1px solid ${COLORS.grey400};
  border-radius: ${toRem(12)};
  padding: ${toRem(8)} ${toRem(16)};

  &:hover {
    border-color: ${COLORS.brandPrimary};
  }

  > select {
  }
`;

export const Select = styled.select`
  width: 100%;
  color: ${COLORS.white};
  font-size: 1rem;
  background-color: ${COLORS.grey950};
  border: 0;
  flex: 1;
  padding: 0;
  line-height: 1rem;

  &:focus {
    outline: none;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

export const StyledTimePicker = styled(TimePicker.RangePicker)`
  .ant-picker-panel-container {
    background: #252622 !important;
  }

  .ant-picker-time-panel-cell,
  .ant-picker-time-panel-cell-selected,
  .ant-picker-time-panel-cell-inner {
    background: #252622 !important;
  }

  .ant-picker-input {
    position: relative;
    border: 1px solid white;
    border-radius: 0.75rem;
    padding: 0;
    margin: 0;
    input {
      margin: 0;
      padding: 0.5rem 1rem;
      border-radius: 0.75rem !important;
      font-family: 'Boxed Regular';
      font-weight: 500;
      font-size: 1rem;
      background: #252622 !important;
      ::placeholder {
        color: white;
      }
    }
    &:hover,
    &:focus {
      border-color: ${COLORS.brandPrimary};
      border-radius: 0.75rem !important;
    }
  }

  .ant-picker-input > input[disabled] {
    &:hover,
    &:focus {
      border: none;
    }
  }

  .ant-picker-clear {
    display: none;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker-panel-container {
    background-color: #282c34;
  }

  .ant-picker-time-panel-cell-inner {
    color: #61dafb;
  }
`;

export const ConfigureTimeButton = styled(FilledButton)`
  width: fit-content;
  min-height: auto;
  border-radius: 8px;
  padding: 8px 12px;

  &:hover {
    background-color: ${COLORS.brandPrimary};
    color: ${COLORS.grey900};
    border-color: ${COLORS.brandPrimary};
    opacity: 0.8;
  }
`;
