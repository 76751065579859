import React, { useMemo } from 'react';
import { BodyMBold, BodyS } from 'styles/v3/variables';
import * as S from './styles';
import { DivisionInfoProps } from './types';

export const DivisionInfo = ({ ageDivision, showTitle }: DivisionInfoProps) => {
  const currentDivision = useMemo(() => {
    const years = ageDivision.years.join('/');
    const yearsText = ageDivision.years.length > 1 ? years : `${years} Only`;

    const genreObj = {
      MALE: 'Boys',
      FEMALE: 'Girls',
      COED: 'Coed',
    };

    return {
      ...ageDivision,
      gender: genreObj[ageDivision.gender] ?? 'Boys',
      years: yearsText,
      color: ageDivision.color ?? '#000000',
    };
  }, [ageDivision]);

  return (
    <S.Container>
      {showTitle && (
        <BodyMBold className='item-title'>{currentDivision.name}</BodyMBold>
      )}
      <BodyS className='item-description'>
        {currentDivision.gender} <S.Dot /> {currentDivision.years}{' '}
        {currentDivision.allowGirlBoysOnAnotherDivision && (
          <>
            <S.Dot /> Allow Girls
          </>
        )}
      </BodyS>
    </S.Container>
  );
};
