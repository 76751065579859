import { useQuery } from '@tanstack/react-query';
import ClubService from 'services/Club/ClubService';
import { InviteClubFilters } from 'services/v3/Clubs/types';

type UseGetPaginatedClubsProps = {
  filters: InviteClubFilters;
};

export const useGetClubs = () => {
  return useQuery(['clubs'], () => ClubService.list());
};

export const useGetPaginatedClubs = ({ filters }: UseGetPaginatedClubsProps) => {
const response = useQuery(['clubs', filters], () => ClubService.paginatedList(filters));

  return {
    ...response,
    data: response.data?.data,
  };
};
