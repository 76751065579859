import React from 'react';

import FilledButton from 'components/v3/Buttons/FilledButton';
import { BodyS } from 'styles/v3/variables';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './styles';

export const ContinueToGroups = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();

  const updateDivisionAndGoGroups = () => {
    history.push(`/v3/event/${eventId}/dashboard/groups`);
  };

  return (
    <S.Container>
      <BodyS $color='grey400'>
        All team placeholders will be able to be edited later.
      </BodyS>
      <FilledButton
        onClick={updateDivisionAndGoGroups}
        isUpper
        color='primary'
        isBold={false}
      >
        Continue to groups
      </FilledButton>
    </S.Container>
  );
};
