import React from 'react';
import { BodyXLBold } from 'styles/v3/variables';
import loadingGif from 'assets/imgs/UnitedFutsal_Loading.gif';

import * as S from './styles';
import { LoadingProps } from './types';

const Loading = ({ text }: LoadingProps) => {
  return (
    <S.Wrapper>
      <S.LogoContainer>
        <img src={loadingGif} alt='Loading GIF Animated' />
        <BodyXLBold $color='grey300'> {text ?? 'Loading...'}</BodyXLBold>
      </S.LogoContainer>
    </S.Wrapper>
  );
};
export default Loading;
