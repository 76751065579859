import React, { FunctionComponent, useEffect, useState } from 'react';
import Icon from '@icon-park/react/es/all';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import { Check, Close, Edit } from '@icon-park/react';
import { BracketView } from 'models/Bracket/BracketModel';
import BracketService from 'services/v3/Brackets/BracketService';

import InputText from 'components/v3/Forms/InputText/InputText';
import IconButton from 'components/v3/Buttons/IconButton';
import { COLORS } from 'styles/v3/variables';
import MatchService from 'services/v3/Match/MatchService';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';
import {
  BracketContainer,
  BracketDelete,
  BracketFooter,
  BracketHeader,
  TitleMap,
  EditBracketName,
} from './styles';
import { BracketItem } from './BracketItem';
import { ConsolationBracketRender } from './BracketItem/ConsolationBracketItem';

interface BracketProps {
  bracket: BracketView;
  onUpdate?: () => void;
  handleOpenEditModal?: (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => void;
}

const Bracket: FunctionComponent<BracketProps> = ({
  bracket,
  onUpdate,
  handleOpenEditModal,
}) => {
  const [editBracketName, setEditBracketName] = useState(false);
  const [bracketName, setBracketName] = useState(bracket.name);
  const [consolationMatches, setConsolationMatches] = useState<any[]>([]);

  useEffect(() => {
    fetchMatches();
  }, [bracket]);

  const fetchMatches = async () => {
    const { data: fectchedMatches } = await MatchService.getMatchesByBracket(
      bracket.id
    );
    const consMatches = fectchedMatches.filter(
      (match) => match.sourceType === 'CONSOLATION'
    );
    setConsolationMatches(consMatches);
  };

  const handleUpdate = () => {
    if (onUpdate && typeof onUpdate === 'function') onUpdate();
  };

  const handleDeleteBracket = async () => {
    try {
      await BracketService.deleteBracket(bracket.id);

      handleUpdate();
    } catch (e) {
      console.warn(e);
    }
  };

  const handleDelete = () => {
    confirm({
      message: 'Are you sure that you want to delete this bracket?',
      onOk: handleDeleteBracket,
    });
  };

  const handleCreateConsolationGame = async () => {
    await BracketService.createConsolationGame(bracket.id);

    handleUpdate();
  };

  const handleDeleteConsolationGame = async (consolationGameId: string) => {
    await BracketService.deleteConsolationGame(bracket.id, consolationGameId);

    handleUpdate();
  };

  const handleUpdateBracketName = async () => {
    await BracketService.updateBracketName(bracket.id, bracketName);

    handleUpdate();
    setEditBracketName(false);
  };

  return (
    <BracketContainer>
      <BracketHeader>
        <div>
          {editBracketName ? (
            <EditBracketName>
              <InputText
                id={`id_edit_bracket_name_${bracket.id}`}
                label='Bracket Name'
                defaultValue={bracket.name}
                onChange={(e) => setBracketName(e.target.value)}
              />
              <IconButton
                icon={
                  <Close
                    size={20}
                    className='reset'
                    onClick={() => setEditBracketName(false)}
                  />
                }
              />

              <IconButton
                $backgroundColor='brandPrimary'
                icon={
                  <Check
                    size={20}
                    fill={COLORS.grey900}
                    className='reset'
                    onClick={() => setEditBracketName(false)}
                  />
                }
                disabled={
                  bracketName.length === 0 || bracketName === bracket.name
                }
                onClick={handleUpdateBracketName}
              />
            </EditBracketName>
          ) : (
            <EditBracketName>
              <h5>{bracket.name}</h5>
              <IconButton
                icon={<Edit size={20} className='reset' />}
                onClick={() => setEditBracketName(true)}
              />
            </EditBracketName>
          )}

          {TitleMap[bracket.type]}
        </div>
        <BracketDelete onClick={handleDelete}>
          <Icon type='delete' size={24} />
        </BracketDelete>
      </BracketHeader>
      <BracketItem
        bracket={bracket}
        handleOpenEditModal={handleOpenEditModal}
        onUpdate={onUpdate}
      />

      {consolationMatches.map((match) => (
        <ConsolationBracketRender
          key={match.id}
          bracketId={bracket.id}
          match={match}
          onDelete={() => handleDeleteConsolationGame(match.matchId)}
          handleOpenEditModal={handleOpenEditModal}
        />
      ))}
      <BracketFooter>
        <OutlinedButtonWithIcon
          color='white-dark'
          customIcon={<Icon type='plus' />}
          onClick={handleCreateConsolationGame}
        >
          ADD CONSOLATION GAME
        </OutlinedButtonWithIcon>
      </BracketFooter>
    </BracketContainer>
  );
};

export default Bracket;
