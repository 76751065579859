export enum B2bDraftEventActionTypes {
  UPDATE_EVENT_TYPE = '@@b2bDraftEvent/UPDATE_EVENT_TYPE',
  UPDATE_EVENT_INFO = '@@b2bDraftEvent/UPDATE_EVENT_INFO',
  CLEAR_EVENT = '@@b2bDraftEvent/CLEAR_EVENT',

  FETCH_PRODUCT_REQUEST = '@@b2bDraftEvent/FETCH_PRODUCT_REQUEST',
  FETCH_PRODUCT_SUCCESS = '@@b2bDraftEvent/FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_ERROR = '@@b2bDraftEvent/FETCH_PRODUCT_ERROR',
}
export interface B2bDraftEventState {
  readonly loading: boolean;
  readonly data: B2bDraftEvent;
}

export interface B2bDraftEvent {
  selectedEvent?: B2bEventProduct;
  events: Array<B2bEventProduct>;
  eventInfo: B2bEventInfo;
}

export interface B2bEventProduct {
  description: string;
  id: string;
  image: string;
  price: number;
  title: string;
  type: 'B2B_EVENT_TOURNAMENT' | 'B2B_EVENT_LEAGUE';
}

export interface B2bEventInfo {
  logoImg: string;
  name: string;
  location: string;
  startDate: Date;
  endDate: Date;
  description: string;
  timeSlots: string;
  courtCount: number;
}
