import React from 'react';
import { DocumentStatusFieldProps } from './types';
import { Item } from './components/Item/Item';
import * as S from './styles';

export const DocumentStatusField = ({
  ageStatus,
  permission,
  safeSportStatus,
  photoStatus,
}: DocumentStatusFieldProps) => {
  return (
    <S.Container>
      <Item name='PH' permission={permission} status={photoStatus} />
      <Item name='AV' permission={permission} status={ageStatus} />
      <Item name='SS' permission={permission} status={safeSportStatus} />
    </S.Container>
  );
};
