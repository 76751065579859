import React, { FC } from 'react';
import { Attention } from '@icon-park/react';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { BodyM, BodySBold, COLORS, TitleH4 } from 'styles/v3/variables';
import { useHistory } from 'react-router-dom';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { Tooltip } from 'antd';

import * as S from './styles';

export const AccountStatus: FC = () => {
  const { missingInformation, currentUser } = useProfile();

  const history = useHistory();
  const handleCompleteAccount = () => {
    history.push(`/v3/user/${currentUser?.id}/info`);
  };

  return (
    <S.Container>
      <S.Box>
        <Tooltip
          color={COLORS.brandSecondary}
          title={
            <S.ToolTipContainer>
              {missingInformation.map((item) => (
                <BodySBold key={item}>- {item}</BodySBold>
              ))}
            </S.ToolTipContainer>
          }
        >
          <S.TitleWrapper>
            <Attention />
            <TitleH4>COMPLETE YOUR ACCOUNT</TitleH4>
          </S.TitleWrapper>
        </Tooltip>

        <BodyM>
          To be a part of an event, you need to complete your account.{' '}
        </BodyM>
      </S.Box>
      <FilledButton
        onClick={handleCompleteAccount}
        color='secondary'
        className='btn-complete'
      >
        COMPLETE NOW
      </FilledButton>
    </S.Container>
  );
};
