/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import { TitleH4 } from 'styles/v3/variables';
import { useParams } from 'react-router-dom';
import Loading from 'components/v3/Loading/Loading';
import ConvertUtil from 'util/ConvertUtil';
import { useQueryParams } from 'hooks/v3/util/useQueryParams';
import { useGetDetailedEventMatches } from 'hooks/v3/matches/useGetDetailedEventMatches/useGetDetailedEventMatches';
import moment from 'moment';
import { Content, Wrapper } from './styles';
import { PrintButton } from '../components/PrintButton/PrintButton';

import * as S from './styles';

const AllScoreSheet: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const {
    data: eventMatches,
    isLoading: isLoadingMatches,
  } = useGetDetailedEventMatches(eventId);

  const queryParams = useQueryParams();

  const selectedMatch = queryParams.get('match') ?? undefined;

  const matches = useMemo(() => {
    if (!eventMatches?.length) return [];

    if (selectedMatch) {
      return eventMatches.filter((match) => match.matchId === selectedMatch);
    }
    return eventMatches;
  }, [eventMatches, selectedMatch]);

  if (isLoadingMatches) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <PrintButton text='Print ScoreSheet' />

      <Content>
        {!matches?.length ? (
          <TitleH4>No match on this event</TitleH4>
        ) : (
          matches.map((details) => (
            <S.PrintWrapper
              key={details.matchId}
              isMultiple={matches.length > 1}
            >
              <S.MainContainer>
                <S.Row>
                  <div />
                  <div>
                    <h3>Team A</h3>
                  </div>
                  <div>
                    <h3>FINAL SCORE</h3>
                  </div>
                  <div>
                    <h3>Team B</h3>
                  </div>
                  <div />

                  <div className='img'>
                    <img
                      src={ConvertUtil.getMinioUrl(details?.homeTeam.logo)}
                      alt=''
                    />
                  </div>
                  <div>
                    <S.Box>
                      <div className='text-md bold'>
                        {details?.homeTeam.clubName}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {details?.homeTeam.name}
                      </div>
                    </S.Box>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <S.Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100px',
                        height: '65px',
                      }}
                    >
                      <h3>{details?.result?.homeTeamScore ?? ''}</h3>
                    </S.Box>
                    <S.Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100px',
                        height: '65px',
                      }}
                    >
                      <h3>{details?.result?.awayTeamScore ?? ''}</h3>
                    </S.Box>
                  </div>
                  <div>
                    <S.Box>
                      <div className='text-md bold'>
                        {details?.awayTeam.clubName}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {details?.awayTeam.name}
                      </div>
                    </S.Box>
                  </div>
                  <div>
                    <div className='img'>
                      <img
                        src={ConvertUtil.getMinioUrl(details?.awayTeam.logo)}
                        alt=''
                      />
                    </div>
                  </div>
                </S.Row>

                <S.Row>
                  <div>
                    <div className='text-sm'>FOULS</div>
                    <div>1st HALF</div>
                  </div>

                  <div className='wrapper-foul'>
                    <div>
                      <div className='number'>1</div>
                    </div>
                    <div>
                      <div className='number'>2</div>
                    </div>
                    <div>
                      <div className='number'>3</div>
                    </div>
                    <div>
                      <div className='number'>4</div>
                    </div>
                    <div>
                      <div className='number'>5</div>
                    </div>
                    <div>
                      <div className='number'>6</div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      padding: '0 10px',
                      justifyContent: 'center',
                    }}
                  >
                    <S.Box style={{ height: '50px', width: '30%' }} />
                    <div
                      style={{ height: '50px', padding: '5px', width: '40%' }}
                    >
                      <div className='text-xs' style={{ fontSize: '12px' }}>
                        TIME OUT
                      </div>
                      <div>1st HALF</div>
                    </div>
                    <S.Box style={{ height: '50px', width: '30%' }} />
                  </div>

                  <div className='wrapper-foul'>
                    <div>
                      <div className='number'>1</div>
                    </div>
                    <div>
                      <div className='number'>2</div>
                    </div>
                    <div>
                      <div className='number'>3</div>
                    </div>
                    <div>
                      <div className='number'>4</div>
                    </div>
                    <div>
                      <div className='number'>5</div>
                    </div>
                    <div>
                      <div className='number'>6</div>
                    </div>
                  </div>

                  <div>
                    <div className='text-sm'>FOULS</div>
                    <div>1st HALF</div>
                  </div>
                </S.Row>

                <S.Row>
                  <div>
                    <div className='text-sm'>FOULS</div>
                    <div>2nd HALF</div>
                  </div>

                  <div className='wrapper-foul'>
                    <div>
                      <div className='number'>1</div>
                    </div>
                    <div>
                      <div className='number'>2</div>
                    </div>
                    <div>
                      <div className='number'>3</div>
                    </div>
                    <div>
                      <div className='number'>4</div>
                    </div>
                    <div>
                      <div className='number'>5</div>
                    </div>
                    <div>
                      <div className='number'>6</div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      padding: '0 10px',
                      justifyContent: 'center',
                    }}
                  >
                    <S.Box style={{ height: '50px', width: '30%' }} />
                    <div
                      style={{ height: '50px', padding: '5px', width: '40%' }}
                    >
                      <div className='text-xs' style={{ fontSize: '12px' }}>
                        TIME OUT
                      </div>
                      <div>2nd HALF</div>
                    </div>
                    <S.Box style={{ height: '50px', width: '30%' }} />
                  </div>

                  <div className='wrapper-foul'>
                    <div>
                      <div className='number'>1</div>
                    </div>
                    <div>
                      <div className='number'>2</div>
                    </div>
                    <div>
                      <div className='number'>3</div>
                    </div>
                    <div>
                      <div className='number'>4</div>
                    </div>
                    <div>
                      <div className='number'>5</div>
                    </div>
                    <div>
                      <div className='number'>6</div>
                    </div>
                  </div>

                  <div>
                    <div className='text-sm'>FOULS</div>
                    <div>2nd HALF</div>
                  </div>
                </S.Row>

                <S.Main>
                  <div style={{ padding: '10px' }}>
                    <div className='listPlayers'>
                      <div
                        className='row'
                        style={{ textAlign: 'center', fontWeight: 800 }}
                      >
                        <div>#</div>
                        <div>NAME</div>
                        <div>Y</div>
                        <div>R</div>
                      </div>

                      {[...Array(15)].map((x, i) => (
                        <div className='row' key={i}>
                          <div>{`# ${
                            details?.homeTeam.players.sort(
                              (a: any, b: any) => a.number - b.number
                            )[i]?.number || ''
                          }`}</div>
                          <div>{`${
                            details?.homeTeam.players.sort(
                              (a: any, b: any) => a.number - b.number
                            )[i]?.firstName || ''
                          } ${
                            details?.homeTeam.players.sort(
                              (a: any, b: any) => a.number - b.number
                            )[i]?.lastName || ''
                          }`}</div>
                          <div />
                          <div />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <div style={{ fontWeight: 800, textAlign: 'center' }}>
                      TEAM A GOALS
                    </div>
                    <div className='goalsTable'>
                      {[...Array(27)].map((x, i) => (
                        <div key={i}>
                          <div className='number'>{i + 1}</div>
                        </div>
                      ))}
                    </div>
                    <div className='coachList'>
                      <div>{`${details?.homeTeam.coaches[0]?.firstName || ''} ${
                        details?.homeTeam.coaches[0]?.lastName || ''
                      }`}</div>
                      <div>{`${details?.homeTeam.coaches[1]?.firstName || ''} ${
                        details?.homeTeam.coaches[1]?.lastName || ''
                      }`}</div>
                      <div>{`${details?.homeTeam.coaches[2]?.firstName || ''} ${
                        details?.homeTeam.coaches[2]?.lastName || ''
                      }`}</div>
                    </div>
                    <div className='coachList'>
                      <div style={{ height: '45px' }} />
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        fontWeight: 800,
                        fontSize: '9px',
                      }}
                    >
                      TEAM A HEAD COACH SIGNATURE
                    </div>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <div style={{ fontWeight: 800, textAlign: 'center' }}>
                      TEAM B GOALS
                    </div>
                    <div className='goalsTable'>
                      {[...Array(27)].map((x, i) => (
                        <div key={i}>
                          <div className='number'>{i + 1}</div>
                        </div>
                      ))}
                    </div>
                    <div className='coachList'>
                      <div>{`${details?.awayTeam.coaches[0]?.firstName || ''} ${
                        details?.awayTeam.coaches[0]?.lastName || ''
                      }`}</div>
                      <div>{`${details?.awayTeam.coaches[1]?.firstName || ''} ${
                        details?.awayTeam.coaches[1]?.lastName || ''
                      }`}</div>
                      <div>{`${details?.awayTeam.coaches[2]?.firstName || ''} ${
                        details?.awayTeam.coaches[2]?.lastName || ''
                      }`}</div>
                    </div>
                    <div className='coachList'>
                      <div style={{ height: '45px' }} />
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        fontWeight: 800,
                        fontSize: '9px',
                      }}
                    >
                      TEAM B HEAD COACH SIGNATURE
                    </div>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <div className='listPlayers'>
                      <div
                        className='row'
                        style={{ textAlign: 'center', fontWeight: 800 }}
                      >
                        <div>#</div>
                        <div>NAME</div>
                        <div>Y</div>
                        <div>R</div>
                      </div>

                      {[...Array(15)].map((x, i) => (
                        <div className='row' key={i}>
                          <div>{`# ${
                            details?.awayTeam.players.sort(
                              (a: any, b: any) => a.number - b.number
                            )[i]?.number || ''
                          }`}</div>
                          <div>{`${
                            details?.awayTeam.players.sort(
                              (a: any, b: any) => a.number - b.number
                            )[i]?.firstName || ''
                          } ${
                            details?.awayTeam.players.sort(
                              (a: any, b: any) => a.number - b.number
                            )[i]?.lastName || ''
                          }`}</div>
                          <div />
                          <div />
                        </div>
                      ))}
                    </div>
                  </div>
                </S.Main>
              </S.MainContainer>
              <S.SideContent>
                <div className='content'>
                  <div>
                    <div className='title'>SIGNATURE</div>
                    <div />
                  </div>
                  <div>
                    <div className='title'>TIME</div>
                    <div className='info'>
                      {moment(details?.date).format('HH:mm')}
                    </div>
                  </div>
                  <div>
                    <div className='title'>SCOREKEEPER</div>
                  </div>
                  <div>
                    <div className='title'>DATE</div>
                    <div className='info'>
                      {moment(details?.date).format('YYYY-DD-MM')}
                    </div>
                  </div>
                  <div>
                    <div className='title'>SIGNATURE</div>
                  </div>
                  <div>
                    <div className='title'>SUBVENUE</div>
                    <div className='info'>{details?.subVenue?.name}</div>
                  </div>
                  <div>
                    <div className='title'>REFEREE 2</div>
                  </div>
                  <div>
                    <div className='title'>VENUE</div>
                    <div className='info'>{details?.venue.name}</div>
                  </div>
                  <div>
                    <div className='title'>SIGNATURE</div>
                  </div>
                  <div>
                    <div className='title'>AGE DIVISION</div>
                    <div className='info'>{`${details?.ageDivision?.gender} 
                ${
                  details?.ageDivision?.years[0] === 1900
                    ? 'ADULT'
                    : details?.ageDivision?.years.sort().join(', ')
                }`}</div>
                  </div>
                  <div>
                    <div className='title'>REFEREE 1</div>
                    <div className='info' />
                  </div>
                  <div>
                    <div className='title'>TOURNAMENT</div>
                    <div className='info'>{details?.event.name}</div>
                  </div>
                </div>
              </S.SideContent>
            </S.PrintWrapper>
          ))
        )}
      </Content>
    </Wrapper>
  );
};

export default AllScoreSheet;
