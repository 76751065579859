import { useQuery } from '@tanstack/react-query';

import StripeService from 'services/v3/Stripe/StripeService';

export function useGetStripeAccount(id: string) {
  const { data, isLoading, error, refetch } = useQuery(
    ['stripe-connected-account', id],
    () => StripeService.getStripeAccount(id),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return {
    data: data?.data ? data.data : undefined,
    isLoading,
    error,
    refetch,
  };
}
