import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const GroupWrapper = styled.div`
  width: 100%;

  display: flex;
  grid-template-columns: 10% 65% 25%;

  gap: ${toRem(24)};
  padding: 0 ${toRem(180)};
  margin-bottom: ${toRem(120)};
`;

export const FallbackMessageWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;

  margin-top: ${toRem(32)};

  gap: ${toRem(16)};

  button {
    border: none;
    background-color: unset;
    color: ${COLORS.brandPrimary};
  }
`;

export const FallbackTextWrapper = styled.div`
  width: ${toRem(1000)};
`;
