import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from '@ramonak/react-progress-bar';

import { ApplicationState } from 'redux/store';
import { UserStats } from 'models/Stats/UserStatsModel';
import { xpInfo } from 'models/v3/xpInformation';
import StatsService from 'services/Stats/StatsService';

import { RadioGroup } from 'components/v3/Forms/RadioGroup/RadioGroup';

import { BodyM, BodyS, COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

import {
  Container,
  UserXPContainer,
  UserLevelInfo,
  UserXPInfo,
} from './styles';

interface UserXPLevel {
  key: string;
  level: string;
  xp: string;
}

interface UserInfoXP {
  actual: UserXPLevel;
  next: UserXPLevel;
}

export interface UserTypesInformation {
  label: string;
  value: string;
}
interface UserXPInformationProps {
  userOptions: UserTypesInformation[];
}

export const UserXPInformation: FC<UserXPInformationProps> = ({ userOptions }) => {
  const { user } = useSelector((state: ApplicationState) => state);

  const [userXP, setUserXP] = useState<number>(0);

  const [userStats, setUserStats] = useState<{
    loading: boolean;
    data: UserStats[];
  }>({ loading: true, data: [] });

  const [userLevel, setUserLevel] = useState<UserInfoXP>({
    actual: {
      key: '1',
      level: '0',
      xp: '0',
    },
    next: {
      key: '1',
      level: '1',
      xp: '16',
    },
  });

  useEffect(() => {
    if (user.data.id) {
      setUserStats({ loading: true, data: [] });

      StatsService.getUserStats({
        userIdList: [user.data.id],
        timeFrame: 'CURRENT_SEASON',
      }).then((res) => {
        if (res.success) {
          setUserStats({ loading: false, data: res.data.content });
          setUserXP(
            userStats.data
              .map((stats) => {
                return stats.xp;
              })
              .reduce((accumulator, value) => {
                return accumulator + value;
              }, 0)
          );

          let indexNext = 1;
          const findUserLevel = xpInfo.find((info, index) => {
            if (
              Number(info.xp) === userXP ||
              (Number(info.xp) <= userXP && Number(xpInfo[index + 1]) > userXP)
            ) {
              indexNext = index + 1;
              return info;
            }

            return '';
          });

          const updateUserLevel = {
            actual: findUserLevel,
            next: xpInfo[indexNext],
          };
          setUserLevel(updateUserLevel as UserInfoXP);
        } else {
          setUserStats({ loading: false, data: [] });
        }
      });
    }
  }, [user.data.id]);

  return (
    <Container>
      <RadioGroup onChange={(e) => null} options={userOptions} />
      <UserXPContainer>
        <UserLevelInfo>
          <BodyM $color='grey200'>Lvl. {userLevel.actual.level}</BodyM>
          <BodyM $color='grey400'>Lvl. {userLevel.next.level}</BodyM>
        </UserLevelInfo>
        <ProgressBar
          completed={Number(userLevel.actual.xp)}
          maxCompleted={Number(userLevel.next.xp)}
          bgColor={COLORS.brandPrimary}
          baseBgColor={COLORS.grey600}
          labelColor={COLORS.brandPrimary}
          height={`${toRem(4)}`}
          borderRadius={`${toRem(25)}`}
          customLabel=''
        />
        <UserXPInfo>
          <BodyS $color='brandPrimary'>XP {userXP}</BodyS>
          <BodyS $color='grey400'>XP {userLevel.next.xp}</BodyS>
        </UserXPInfo>
      </UserXPContainer>
    </Container>
  );
};
