import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 24px;
  background-color: ${COLORS.grey950};

  border: 1px solid ${COLORS.grey800};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    border: none;
    border-radius: unset;
    background-color: unset;
  }
`;
