import { useQuery } from '@tanstack/react-query';
import RosterService from 'services/v3/Rosters/RosterService';

type UseGetEventRostersProps = {
  eventId: string,
};

export const useGetEventRostersAvailableForTeams = ({
  eventId,
}: UseGetEventRostersProps) => {
  const response = useQuery(
    ['rosters-event-available-for-teams', eventId],
    () => RosterService.findAvailableRostersToTeamPlacementByEventId(eventId)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
