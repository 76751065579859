import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { MatchStatus } from 'models/Match/MatchModel';
import { useGetMatchDetailById } from 'hooks/v3/matches/useGetMatchDetailById/useGetMatchDetailById';
import { useChangeMemberAttendance } from 'hooks/v3/rosters/useChangeMemberAttendance/useChangeMemberAttendance';
import { useCreateFutsalEvent } from 'hooks/v3/matches/useCreateFutsalEvent/useCreateFutsalEvent';
import { useDeleteFutsalEvent } from 'hooks/v3/matches/useDeleteFutsalEvent/useDeleteFutsalEvent';
import { useUpdatePlayerNumber } from 'hooks/v3/rosters/useUpdatePlayerNumber/useUpdatePlayerNumber';
import { useReportGame } from 'hooks/v3/matches/useReportGame/useReportGame';
import { useUpdateGame } from 'hooks/v3/matches/useUpdateGame/useUpdateGame';
import {
  IFutsalEventPayload,
  IFutsalEventDeletionPayload,
  IGameReportPayload,
} from 'services/v3/Match/payload-interfaces';
import { FutsalAction, SoccerPeriod } from 'services/v3/Match/enums';
import { IPlayerNumberPayload } from 'services/v3/Rosters/types';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { Tabs } from 'components/v3/EventManagerDashboard/Tabs/Tabs';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { RosterCheckIn } from './components/RosterCheckIn/RosterCheckIn';
import { GameFacts } from './components/GameFacts/GameFacts';
import { Scoresheet } from './components/Scoresheet/Scoresheet';
import { IMatchInfo } from './components/Scoresheet/interfaces';
import { LeaveConfirmModal } from './components/LeaveConfirmModal/LeaveConfirmModal';

import { TeamVenueType } from './enums';
import { PERIOD_ORDER, DEFAULT_MATCH_INFO_VALUE } from './config';
import { IGroupedFutsalEvents } from './interfaces';
import {
  getMatchInfoFromLocalStorage,
  storeMatchInfoInLocalStorage,
  removeMatchInfoFromLocalStorage,
} from './utils';
import { useNavigationBlocker } from './hooks/useNavigationBlocker';

import * as S from './styles';

export enum GameControlTabsKeys {
  RosterCheckIn = 'roster-check-in',
  GameFacts = 'game-facts',
  Scoresheet = 'scoresheet',
}

const GamesControl: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { gameId } = useParams<{ gameId: string }>();

  const searchParams = new URLSearchParams(location.search);

  const activeTab =
    searchParams.get('tab') ?? GameControlTabsKeys.RosterCheckIn;

  const { data: match = null, refetch: fetchMatchDetailByGameId } =
    useGetMatchDetailById(gameId);

  const { mutateAsync: changeMemberAttendance } = useChangeMemberAttendance();
  const { mutateAsync: createFutsalEvent } = useCreateFutsalEvent();
  const { mutateAsync: deleteFutsalEvent } = useDeleteFutsalEvent();
  const { mutateAsync: updatePlayerNumber } = useUpdatePlayerNumber();
  const { mutateAsync: reportGame } = useReportGame();
  const { mutateAsync: updateGame } = useUpdateGame();

  const [enabledEditFinishedGame, setEnabledEditFinishedGame] = useState(false);

  const [matchInfo, setMatchInfo] = useState<IMatchInfo>(
    getMatchInfoFromLocalStorage()
  );

  const {
    openedPrompt,
    initialisedBlocker,
    handlePromptCancel,
    handlePromptConfirm,
    handleInitialiseBlocker,
    handleResetNavigationBlocker,
  } = useNavigationBlocker();

  const getTeamFouls = useCallback(
    (rosterId: string) => {
      if (match?.matchEvents) {
        const fouls = match.matchEvents.filter(
          (event) =>
            event.action === FutsalAction.FOUL && event.rosterId === rosterId
        );

        return fouls.length;
      }

      return 0;
    },
    [match]
  );

  const futsalEvents = useMemo(() => {
    if (!match?.matchEvents || !match.homeTeam || !match.awayTeam) {
      return null;
    }

    const identifiedEvents = match.matchEvents.map((event) => ({
      ...event,
      team:
        event.rosterId === match.homeTeam?.id
          ? TeamVenueType.Home
          : TeamVenueType.Away,
    }));

    const sortedEvents = identifiedEvents.sort((a, b) => {
      const periodComparison =
        PERIOD_ORDER.indexOf(a.period) - PERIOD_ORDER.indexOf(b.period);

      if (periodComparison !== 0) return periodComparison;

      const timeA = a.time.split(':').map(Number);
      const timeB = b.time.split(':').map(Number);

      return timeA[0] - timeB[0] || timeA[1] - timeB[1] || timeA[2] - timeB[2];
    });

    const groupedEvents = sortedEvents.reduce(
      (acc: IGroupedFutsalEvents[], event) => {
        let periodGroup = acc.find((group) => group.type === event.period);

        if (!periodGroup) {
          periodGroup = { type: event.period, events: [] };
          acc.push(periodGroup);
        }

        periodGroup.events.push(event);

        return acc;
      },
      []
    );

    return groupedEvents;
  }, [match]);

  const penaltyShootout = useMemo(() => {
    return (
      futsalEvents?.find(
        (event) => event.type === SoccerPeriod.PENALTY_SHOOTOUT
      )?.events || null
    );
  }, [futsalEvents]);

  const handleBack = useCallback(() => {
    history.goBack();
  }, []);

  const handleTabsChange = useCallback(
    (activeKey: string) => {
      history.replace(`${location.pathname}?tab=${activeKey}`);
    },
    [history, location.pathname]
  );

  const handleAttendanceMemberClick = useCallback(
    (matchId: string, rosterId: string, userId: string, attend: boolean) => {
      changeMemberAttendance({
        rosterId,
        matchId,
        users: [
          {
            userId,
            attend,
          },
        ],
      }).then(() => {
        fetchMatchDetailByGameId();
      });
    },
    [changeMemberAttendance]
  );

  const handleSubmitGameEvents = useCallback(() => {
    setEnabledEditFinishedGame(false);
  }, []);

  const handleCreateFutsalEvent = useCallback(
    (payload: IFutsalEventPayload) => {
      createFutsalEvent(payload).then(() => {
        fetchMatchDetailByGameId();
      });
    },
    []
  );

  const handleDeleteFutsalEvent = useCallback(
    (payload: IFutsalEventDeletionPayload) => {
      deleteFutsalEvent(payload).then(() => {
        fetchMatchDetailByGameId();
      });
    },
    []
  );

  const handleEnableEditFinishedGame = useCallback(() => {
    setEnabledEditFinishedGame(true);
  }, []);

  const handleUpdatePlayerNumber = useCallback(
    (payload: IPlayerNumberPayload) => {
      updatePlayerNumber(payload).then(() => {
        fetchMatchDetailByGameId();
      });
    },
    [updatePlayerNumber]
  );

  const handleReportGame = useCallback(
    (payload: IGameReportPayload) => {
      reportGame(payload);
    },
    [reportGame]
  );

  const handleStartGame = useCallback(() => {
    if (match?.matchId) {
      updateGame({
        matchId: match.matchId,
        status: MatchStatus.IN_PROGRESS,
      }).then(() => {
        fetchMatchDetailByGameId();
      });
    }
  }, [match, updateGame]);

  const handleFinishGame = useCallback(() => {
    if (match?.matchId) {
      updateGame({
        matchId: match.matchId,
        status: MatchStatus.FINISHED,
      }).then(() => {
        fetchMatchDetailByGameId();
        setMatchInfo(DEFAULT_MATCH_INFO_VALUE);
      });
    }
  }, [match, updateGame]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    fetchMatchDetailByGameId();
  }, [fetchMatchDetailByGameId]);

  useEffect(() => {
    if (match?.status === MatchStatus.IN_PROGRESS) {
      storeMatchInfoInLocalStorage(matchInfo);
    }
    if (match?.status === MatchStatus.FINISHED) {
      removeMatchInfoFromLocalStorage();
    }
  }, [match, matchInfo]);

  useEffect(() => {
    if (match?.status === MatchStatus.IN_PROGRESS && !initialisedBlocker) {
      handleInitialiseBlocker();
    }
  }, [match, initialisedBlocker, handleInitialiseBlocker]);

  useEffect(() => {
    if (match?.status === MatchStatus.FINISHED) {
      handleResetNavigationBlocker();
    }
  }, [match, handleResetNavigationBlocker]);

  return (
    <S.Container>
      <TextButtonWithIcon
        reverse
        icon='back'
        color='primary'
        align='flex-end'
        onClick={handleBack}
      >
        Go back
      </TextButtonWithIcon>
      <S.Title>Games Control</S.Title>
      <S.ActionsWrapper>
        <Tabs
          activeKey={activeTab}
          tabs={[
            {
              title: 'Roster Check-in',
              key: GameControlTabsKeys.RosterCheckIn,
              children: (
                <RosterCheckIn
                  matchId={match?.matchId || null}
                  awayTeam={match?.awayTeam || null}
                  homeTeam={match?.homeTeam || null}
                  onAttendanceMemberClick={handleAttendanceMemberClick}
                  onUpdatePlayerNumber={handleUpdatePlayerNumber}
                />
              ),
            },
            {
              title: 'Game Facts',
              key: GameControlTabsKeys.GameFacts,
              children: (
                <GameFacts
                  match={match}
                  penaltyShootout={penaltyShootout}
                  futsalEvents={futsalEvents}
                  getTeamFouls={getTeamFouls}
                  onDeleteFutsalEvent={handleDeleteFutsalEvent}
                />
              ),
            },
            {
              title: 'Scoresheet',
              key: GameControlTabsKeys.Scoresheet,
              children: (
                <Scoresheet
                  match={match}
                  matchInfo={matchInfo}
                  penaltyShootout={penaltyShootout}
                  enabledEditFinishedGame={enabledEditFinishedGame}
                  getTeamFouls={getTeamFouls}
                  setMatchInfo={setMatchInfo}
                  onCreateFutsalEvent={handleCreateFutsalEvent}
                  onReportGame={handleReportGame}
                  onStartGame={handleStartGame}
                  onFinishGame={handleFinishGame}
                />
              ),
            },
          ]}
          onChange={handleTabsChange}
        />
        {match?.status === MatchStatus.FINISHED && (
          <S.SubmitButtonWrapper>
            {enabledEditFinishedGame && (
              <FilledButton isUpper onClick={handleSubmitGameEvents}>
                Finish editing the game
              </FilledButton>
            )}
            {!enabledEditFinishedGame && (
              <FilledButton isUpper onClick={handleEnableEditFinishedGame}>
                Enable edit finished game
              </FilledButton>
            )}
          </S.SubmitButtonWrapper>
        )}
      </S.ActionsWrapper>
      <LeaveConfirmModal
        visible={openedPrompt}
        onCancel={handlePromptCancel}
        onLeave={handlePromptConfirm}
      />
    </S.Container>
  );
};

export default GamesControl;
