export const formatStartTime = (startTime: string) => {
  const date = new Date(startTime);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short' };
  return date.toLocaleDateString('en-US', options as any).toUpperCase();
};
