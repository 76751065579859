import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

import { CONTENT_WIDTH } from '../../DraftEvent/styles';

export const Wrapper = styled.div<{ hasMenu: boolean }>`
  width: 100%;
  height: 72px;
  display: flex;
  background: ${COLORS.grey950};
  padding-right: ${toRem(56)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    position: fixed;
    bottom: 0;
    display: flex;
    z-index: 1;
    padding: 0;
    height: ${({ hasMenu }) => hasMenu ? toRem(72) : toRem(0)};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: ${COLORS.brandPrimary};

  @media (max-width: ${CONTENT_WIDTH}) {
    padding: 0 ${toRem(10)};
  }

  a {
    display: flex;
  }
`;

export const MenuWrapper = styled.nav`
  flex: 1;
`;

export const MenuContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    justify-content: space-evenly;
  }
`;

export const MenuItem =
  styled.li <
  { $icon?: string } >
  `
  height: 100%;
  max-width: ${toRem(290)};
  width: 100%;
  transition: 0.3s;
  margin: 0 ${toRem(12)};
  border-bottom: 2px solid transparent;

  &:first-child {
    margin-left: 1.5rem;
  }
  &:last-child {
    margin-right: 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: fit-content;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      line-height: normal;
      margin-right: 1rem;

      @media screen and (max-width: ${BREAKPOINTS.mobile}) {
        margin: 0;
      }
    }

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &.active,
  &:hover {
    color: ${COLORS.brandPrimary};
    border-color: ${COLORS.brandPrimary};
    background: linear-gradient(
      196deg,
      rgba(202, 252, 1, 0.2) 0%,
      rgba(202, 252, 1, 0) 100%
    );
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;

  .notifications-wrapper {
    margin-right: 1.5rem;
  }
`;

export const UserProfileImgMobile = styled.div`
  width: ${toRem(24)};
  height: ${toRem(24)};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};
  border-radius: 100%;

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(24)};
    height: ${toRem(24)};
  }
`;

export const MobileProfileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
