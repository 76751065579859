import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const ScoreboardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 41px;
  margin-bottom: 57px;
  color: ${COLORS.white};
`;

export const TeamSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TeamAvatar = styled.div`
  width: 160px;
  height: 160px;
  margin-bottom: 16px;
  border-radius: 50%;
  background-color: ${COLORS.grey400};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const TeamLabel = styled.span`
  display: block;
  font-family: 'Boxed Medium';
  font-size: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.grey200};
`;

export const Scoreboard = styled.div`
  display: flex;
  align-items: flex-start;
  grid-column-gap: 24px;
`;

export const ScoreboardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScoreboardLabel = styled.span`
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.grey200};
`;

export const ScoreboardText = styled.span`
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 25px;
  color: ${COLORS.grey200};
`;
