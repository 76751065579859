import React, { memo, ReactNode } from 'react';
import { Lock } from '@icon-park/react';

import { BodyXLBold } from 'styles/v3/variables';

import * as S from './styles';

interface ITabs {
  key: string;
  title: string;
  isLocked?: boolean;
  children?: ReactNode;
}

export interface ITabsProps {
  activeKey: string;
  tabs: ITabs[];
  onChange: (activeKey: string) => void;
}

export const Tabs = memo((props: ITabsProps) => {
  const { tabs, activeKey, onChange } = props;

  return (
    <S.Container>
      <S.Tabs type='card' onChange={onChange} activeKey={activeKey}>
        {tabs?.map((tab) => (
          <S.TabPane
            key={tab.key}
            tabKey={tab.key}
            disabled={tab.isLocked}
            tab={
              <S.TabPaneTitle>
                <BodyXLBold>{tab.title}</BodyXLBold>
                {tab.isLocked && <Lock size='22px' />}
              </S.TabPaneTitle>
            }
          >
            {tab.children}
          </S.TabPane>
        ))}
      </S.Tabs>
    </S.Container>
  );
});

Tabs.displayName = 'Tabs';
