import React from 'react';
import { Avatar } from 'antd';
import { BodyM, BodyS } from 'styles/v3/variables';
import AvatarPlaceholder from 'assets/imgs/avatar_placeholder.png';
import { Printer } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { ScheduleAccordionContentProps } from 'pages/V3/Schedule/types/scheduleAccordion';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import {
  Container,
  Header,
  Score,
  Tag,
  Team,
  TeamContainer,
  TeamItem,
} from './styles';

export const ScheduleAccordionContent = ({
  opponent1,
  opponent2,
  startTime,
  divisionName,
  divisionColor,
}: ScheduleAccordionContentProps) => {
  const handlePrintSchedule = () => {
    // TODO - print schedule with game match id
    // window.open(`/v3/print/event/:eventId/all-scoresheet?match=matchId`)
  };
  return (
    <Container divisionColor={divisionColor}>
      <Header>
        <BodyM $color='grey300'>{startTime}</BodyM>
        <Tag>
          <BodyS $color='white' $isUpper>
            {divisionName}
          </BodyS>
        </Tag>
      </Header>
      <TeamContainer>
        <TeamItem>
          <Team>
            <Avatar
              size={32}
              src={
                opponent1?.clubLogo
                  ? ConvertUtil.getMinioUrl(opponent1?.clubLogo)
                  : AvatarPlaceholder
              }
            />
            <BodyM $color='grey300'>{opponent1?.clubName}</BodyM>
          </Team>
          <Score>
            <BodyM $color='white'>{opponent1?.score}</BodyM>
          </Score>
        </TeamItem>
        <TeamItem>
          <Team>
            <Avatar
              size={32}
              src={
                opponent2?.clubLogo
                  ? ConvertUtil.getMinioUrl(opponent2?.clubLogo)
                  : AvatarPlaceholder
              }
            />
            <BodyM $color='grey300'>{opponent2?.clubName}</BodyM>
          </Team>
          <Score>
            <BodyM $color='white'>{opponent2?.score}</BodyM>
          </Score>
        </TeamItem>
      </TeamContainer>
      <FilledButtonWithIcon
        className='btn-print'
        customIcon={<Printer />}
        isUpper
        onClick={handlePrintSchedule}
      >
        Print ScoreSheet
      </FilledButtonWithIcon>
    </Container>
  );
};
