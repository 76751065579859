import React, { FunctionComponent } from 'react';
import {
  BracketMatchDTO,
  BracketMatchOpponent,
} from 'models/Bracket/BracketModel';

import {
  BracketGameContainer,
  BracketGameItem,
  BracketGameLine,
  BracketGameTitle,
  BracketLines,
  EmptyBracketGameContainer,
  SingleBracketGameContainer,
  SingleBracketGameItem,
} from './styles';
import { BracketMatchOpponentWithClub } from './types';

interface BracketGameProps {
  match: BracketMatchDTO;
  bracketId: string;
  to?: BracketGameLine;
  from?: BracketGameLine;
  extend?: boolean;
  showPens?: boolean;
  titleSourceEmpty?: string;
  onlyOneOpponent?: 'ALL-OPPONENTS' | 'ONLY-OPPONENT1';
  onUpdate?: () => void;
  handleOpenEditModal?: (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => void;
}

interface SingleItemBracketGameProps {
  title: string;
  to?: BracketGameLine;
  from?: BracketGameLine;
}

export const SingleItemBracketGame: FunctionComponent<SingleItemBracketGameProps> = ({
  title,
  from,
  to,
}) => {
  return (
    <SingleBracketGameContainer>
      <SingleBracketGameItem>{title}</SingleBracketGameItem>
      <BracketLines to={to} from={from} isSingleItem />
    </SingleBracketGameContainer>
  );
};

export const BracketGame: FunctionComponent<BracketGameProps> = ({
  match,
  from,
  to,
  extend,
  titleSourceEmpty = 'TBA',
  onlyOneOpponent = 'ALL-OPPONENTS',
  showPens = false,
}) => {
  const getBracketSourceTeamName = (
    opponent: BracketMatchOpponent,
    opponentNumber: 1 | 2
  ) => {
    const opponentWithClub = opponent as unknown as BracketMatchOpponentWithClub

    let name =
      onlyOneOpponent === 'ONLY-OPPONENT1' && opponentNumber === 2
        ? 'TBA'
        : titleSourceEmpty;
    if(opponentWithClub?.clubName) {
      name = opponentWithClub.clubName
    } else if (opponent?.source) {
      switch (opponent.source) {
        case 'DIVISION':
          if (opponent.seed !== null) name = `Division ${opponent.seed}`;
          break;
        case 'POOL':
          if (opponent.seed !== null) name = `Pool ${opponent.seed}`;
          break;
        case 'LOSER':
          if (opponent.loserGame !== null) name = `Loser ${opponent.loserGame}`;
          break;
        case 'WINNER':
          if (opponent.winnerGame !== null)
            name = `Winner ${opponent.winnerGame}`;
          break;
        default:
          break;
      }
    }
  
    return name;
  };

  return (
    <BracketGameContainer>
      <BracketGameItem>
        <p>{getBracketSourceTeamName(match.opponent1, 1) ?? 'TBA'}</p>
      </BracketGameItem>
      <BracketGameItem>
        <p>{getBracketSourceTeamName(match.opponent2, 2) ?? 'TBA'}</p>
      </BracketGameItem>
      <BracketGameTitle>
        <p>{match.name}</p>
      </BracketGameTitle>
      <BracketLines to={to} from={from} extend={extend} />
    </BracketGameContainer>
  );
};

export const EmptyBracketGame: FunctionComponent<
  Omit<BracketGameProps, 'match' | 'bracketId'>
> = ({ to, from }) => {
  return (
    <EmptyBracketGameContainer>
      <div />
      <BracketLines noDots to={to} from={from} />
    </EmptyBracketGameContainer>
  );
};

export default BracketGame;
