import { Moment } from 'moment';
import { AgeDivision } from '../../admin/models/AgeDivision';
import { VenueView } from '../../admin/models/event/Venue';
import {
  GameEventModel,
  GamePeriod,
  GameResult,
} from '../GameEvent/GameEventModel';

export interface BracketSource {
  type?: 'POOL' | 'MATCH';
  poolId?: string;
  poolPosition?: number;
  matchId?: string;
  matchResult?: 'WINNER' | 'LOSER';
}

export enum SourceMatchType {
  POOL = 'POOL', 
  BRACKET = 'BRACKET',
  CONSOLATION = 'CONSOLATION',
}

export interface MatchV3Model {
  // Two ids are needed because the matchId is created before insert the match in the database
  id?: string; // ObjectId
  matchId?: string; // UUID

  ageDivisionId?: string;
  homePlaceHolderId?: string;
  awayPlaceHolderId?: string;
  homeTeamBracketSource?: BracketSource;
  awayTeamBracketSource?: BracketSource;
  sourceType?: SourceMatchType;

  bracketMatchCount?: number;

  date?: Moment | Date | string;
  status?: MatchStatus;
  name?: string;
}

export interface MatchModel {
  id?: string;
  ageDivision: AgeDivision;
  homeTeamId: string;
  awayTeamId: string;
  eventId: string;
  date: Moment | Date | string;
  time?: Moment | Date;
  startedTime?: Moment | Date;
  venueId: string;
  venue?: VenueView;
  subVenue: {
    abbreviation: string;
    name: string;
  };
  status?: MatchStatus;
}
export interface Player {
  birthDate: Date;
  firstName: string;
  id: string;
  lastName: string;
  number: number;
  photo: string;
  attend?: boolean; // TODO: change this a different interface outside Player
}

export interface Coach {
  birthDate: Date;
  firstName: string;
  id: string;
  lastName: string;
  photo: string;
  attend?: boolean;
}

export interface GamePreferences {
  swapped?: boolean;
  colorTeamA?: string;
  colorTeamB?: string;
  timing: {
    timerStart: boolean;
    initialTime: number;
    timerInSeconds: number;
    timerInSecondsTotal: number;
    period: GamePeriod;
    editOpen: boolean;
    editPeriod: GamePeriod;
  };
}

export enum MatchStatus {
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface MatchDetailsModel {
  matchId: string;
  ageDivision: AgeDivision;
  specialDivision?: string;
  homeTeam: {
    id: string;
    city: string;
    clubId: string;
    clubName: string;
    players: Player[];
    coaches: Coach[];
    name: string;
    logo: string;
  };
  awayTeam: {
    id: string;
    city: string;
    clubId: string;
    clubName: string;
    players: Player[];
    coaches: Coach[];
    name: string;
    logo: string;
  };
  event: {
    id: string;
    logo: string;
    name: string;
  };
  matchEvents: GameEventModel[];
  date: Moment | Date | string;
  time?: Moment | Date;
  venue: {
    id: string;
    name: string;
    address: {
      city: string;
      country: string;
      line1: string;
      state: string;
      zipCode: string;
    };
  };
  result?: GameResult;
  report?: string;
  subVenue?: {
    abbreviation: string;
    name: string;
  };
  status?: MatchStatus;
}

export interface MatchListModel {
  id?: string;
  ageDivision: AgeDivision;
  homeTeam: {
    id: string;
    city: string;
    clubId: string;
    clubName: string;
    coachesIds: string[];
    name: string;
    logo: string;
  };
  awayTeam: {
    id: string;
    city: string;
    clubId: string;
    clubName: string;
    coachesIds: string[];
    name: string;
    logo: string;
  };
  eventId: string;
  date: Moment | Date | string;
  time?: Moment | Date;
  venueId: string;
  subVenue: {
    abbreviation: string;
    name: string;
  };
  result?: {
    awayTeamPenaltiShootout: number;
    awayTeamScore: number;
    homeTeamPenaltiShootout: number;
    homeTeamScore: number;
  };
  report?: boolean;
  status?: string;
}

export interface Roster {
  id: string;
  city: string;
  clubId: string;
  clubName: string;
  players?: Player[];
  coaches?: Coach[];
  name: string;
  logo: string;
}

export interface MatchEventModel {
  id: string;
  absentIds: string[];
  ageDivisionId: string;
  eventId: string;
  gridLocked: boolean;
  awayPlaceHolder: {
    id: string;
    name: string;
  };
  homePlaceHolder: {
    id: string;
    name: string;
  };
  matchId: string;
  pools: { id: string; name?: string }[];
  sourceType: SourceMatchType;
  status: MatchStatus;
  venue: {
    venueId: string;
    venueName: string;
  };
  subVenue?: string;
  date?: string;
  homeTeam: Roster;
  awayTeam: Roster;
  homeTeamBracketSource?: BracketSource;
  awayTeamBracketSource?: BracketSource;
  bracketMatchCount?: number;
  name: string;
  report?: string;
}

export class MatchUtil {
  public static MatchDetailsToMatch(matchDetails: MatchDetailsModel) {
    const match: MatchModel = {
      id: matchDetails.matchId,
      ageDivision: matchDetails.ageDivision,
      homeTeamId: matchDetails.homeTeam.id,
      awayTeamId: matchDetails.awayTeam.id,
      eventId: matchDetails.event.id,
      date: matchDetails.date,
      time: matchDetails.time,
      venueId: matchDetails.venue.id!,
      subVenue: {
        abbreviation: matchDetails.subVenue?.abbreviation!,
        name: matchDetails.subVenue?.name!,
      },
      status: matchDetails.status,
    };

    return match;
  }
}
