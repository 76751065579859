import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  max-width: 25%;

  display: flex;
  flex-direction: column;

  flex: 0 0 25%;

  gap: ${toRem(24)};
`;

export const ElementCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 0;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  & > p {
    margin-left: ${toRem(12)};
  }
`;
