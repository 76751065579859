import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const CovidTermsWrapper = styled.div`
  margin: 0 auto;
  max-width: ${toRem(588)};

  h1 {
    margin-bottom: 1rem;
  }

  .caption {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: ${toRem(48)} ${toRem(24)} 0;
  }
`;
