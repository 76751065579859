import { AxiosRequestConfig } from 'axios';
import { B2bEventsList } from 'redux/v3/b2bSetupEvent/types';
import DateUtil from 'util/DateUtil';
import { UFRosterFeeParams } from 'hooks/v3/event/price/usePostUpdatePriceInfo/types';
import { ConfigApi } from '../../config.api';
import APIServicesV3 from '../../util/ApiServicesV3';
import ServiceResponseV3 from '../../util/ServiceResponseV3';
import { EventUpdatePricing } from './types';

class B2bSetupEventService {
  public static async listEvents(
    email: string
  ): Promise<ServiceResponseV3<B2bEventsList>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: '/events/b2b',
      method: 'GET',
      data: { email },
    };
    return APIServicesV3.request<B2bEventsList>(axiosConfig);
  }

  public static async createStripeOnboardingUrl(
    eventId: string,
    returnUrl: string
  ): Promise<ServiceResponseV3<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/create-stripe-onboard-url`,
      params: {
        returnUrl,
      },
      method: 'POST',
    };
    return APIServicesV3.request<string>(axiosConfig);
  }

  public static async updateStripeOnboardingUrl(
    eventId: string,
    accountId: string,
    returnUrl: string
  ): Promise<ServiceResponseV3<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/update-stripe-onboard-url`,
      params: {
        returnUrl,
        accountId,
      },
      method: 'POST',
    };
    return APIServicesV3.request<string>(axiosConfig);
  }

  public static async updatePricingInfo(
    eventId: string,
    data: EventUpdatePricing
  ): Promise<ServiceResponseV3<string>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/update-price-info`,
      data: {
        rosterFee: data.rosterFee,
        draftCoupons: data.draftCoupons.map((draft) => ({
          ...draft,
          createdAt: DateUtil.dateTimeToDateString(draft.createdAt),
          expiresAt: DateUtil.dateTimeToDateString(draft.expiresAt),
        })),
      },
      method: 'POST',
    };
    return APIServicesV3.request<string>(axiosConfig);
  }

  public static setUfEventRosterFee(eventId: string, data: UFRosterFeeParams): Promise<ServiceResponseV3<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getBearerConfig(),
      url: `/events/${eventId}/set-uf-event-roster-fee`,
      data,
      method: 'POST',
    };
    return APIServicesV3.request<string>(axiosConfig);
  }
}

export default B2bSetupEventService;
