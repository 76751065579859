import {
  AgeRulesEnums,
  AgeRulesEnumsToLabel,
} from 'pages/V3/setupEvent/Divisions/constants';
/* eslint-disable no-plusplus */
import { Pools } from 'admin/models/AgeDivision';

export const getColorByNumberOfTeam = (
  countAllTeams: number,
  targetNumberOfTeams?: number
) => {
  if (countAllTeams === 0) return 'grey100';
  if (countAllTeams === targetNumberOfTeams) return 'brandPrimary';

  return 'supportError';
};

export const findNextCharForTeamName = (pool: Pools) => {
  const maxChar = pool.teams
    ? pool.teams
        .map((team) => {
          const match = team.order.match(/([A-Z])/);
          return match ? match[1].charCodeAt(0) : 0;
        })
        .reduce((max, curr) => Math.max(max, curr), 0)
    : 64;

  return maxChar < 90 ? String.fromCharCode(maxChar + 1) : 'A';
};

export const findNextNumberForTeamName = (pool: Pools) => {
  const maxNumber = pool.teams
    ? pool.teams
        .map((team) => {
          const match = team.order.match(/(\d+)/);
          return match ? parseInt(match[1], 10) : 0;
        })
        .reduce((max, curr) => Math.max(max, curr), 0)
    : 0;

  return (maxNumber + 1).toString();
};

export const findNextCharForPoolName = (pools: Pools[]) => {
  const usedNames = pools?.map((p) => p.order) || [];
  // range from charCode A to Z
  for (let i = 65; i <= 90; i++) {
    const char = String.fromCharCode(i);
    if (!usedNames.includes(char)) {
      return char;
    }
  }
  return 'A';
};

export const findNextNumberForPoolName = (pools: Pools[]) => {
  const usedNames = pools?.map((p) => p.order) || [];
  for (let i = 1; i <= 100; i++) {
    const char = String(i);
    if (!usedNames.includes(char)) {
      return char;
    }
  }
  return '1';
};

export const getRuleText = (rule?: string) => {
  switch (rule) {
    case AgeRulesEnums.EQUAL:
      return AgeRulesEnumsToLabel.EQUAL;
    case AgeRulesEnums.YOUNGER:
      return AgeRulesEnumsToLabel.YOUNGER;
    case AgeRulesEnums.YOUNGER_WITH_MORE_ONE_YEAR:
      return AgeRulesEnumsToLabel.YOUNGER_WITH_MORE_ONE_YEAR;
    case AgeRulesEnums.YOUNGER_WITH_MORE_TWO_YEAR:
      return AgeRulesEnumsToLabel.YOUNGER_WITH_MORE_TWO_YEAR;
    default:
      return null;
  }
};
