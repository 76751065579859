import { useQuery } from '@tanstack/react-query';
import { ClubFilters } from 'services/v3/Clubs/types';
import ClubService from 'services/v3/Clubs/ClubService';

type UseFindAllWithFiltersProps = {
  filters?: ClubFilters;
};

export const useFindAllWithFilters = ({
  filters,
}: UseFindAllWithFiltersProps) => {
  const response = useQuery(['clubs', filters], () =>
    ClubService.findAllWithFilters(filters)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
