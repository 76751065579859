export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum GenderCategory {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  COED = 'COED',
}
