import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import ScheduleService from 'services/v3/Schedule/ScheduleService';
import { UpdateScheduleProps } from 'services/v3/Schedule/types';

type UseUpdateSchedule = {
  id: string,
  dto: UpdateScheduleProps,
};

export const useUpdateScheduleByEventId = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, dto }: UseUpdateSchedule) =>
      ScheduleService.updateScheduleByEventId(id, dto),
    onSuccess: async (_, { id }) => {
      notification.success({
        message: 'Schedule saved successfully!',
      });

      await queryClient.invalidateQueries(['get-schedule-by-event-id', id]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
