import React, { FunctionComponent, useMemo, useState } from 'react';
import Modal from 'react-modal';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import Icon from '@icon-park/react/es/all';
import { BracketBaseType, BracketType } from 'models/Bracket/BracketModel';
import {
  SideModalContainer,
  SideModalFooter,
  SideModalHeader,
  SideModalList,
  SideModalListItem,
  SideModalMain,
  SideModalMenuItem,
} from './styles';

interface BracketOption {
  title: string;
  type: BracketType;
  imgUrl?: string;
}

const BRACKET_OPTIONS: Array<BracketOption> = [
  {
    title: 'Single Elimination 2 Teams',
    type: 'SINGLE_ELIMINATION_2',
  },
  {
    title: 'Single Elimination 3 Teams',
    type: 'SINGLE_ELIMINATION_3',
  },
  {
    title: 'Single Elimination 4 Teams',
    type: 'SINGLE_ELIMINATION_4',
  },
  {
    title: 'Single Elimination 5 Teams',
    type: 'SINGLE_ELIMINATION_5',
    // imgUrl: 'https://as1.ftcdn.net/v2/jpg/02/21/70/88/1000_F_221708834_nq4b44iq4mPfZxT3OxF3JY8DhQC5ETor.jpg',
  },
  {
    title: 'Single Elimination 6 Teams',
    type: 'SINGLE_ELIMINATION_6',
  },
  {
    title: 'Single Elimination 7 Teams',
    type: 'SINGLE_ELIMINATION_7',
  },
  {
    title: 'Single Elimination 8 Teams',
    type: 'SINGLE_ELIMINATION_8',
  },
  {
    title: 'Compass Draw 4 Teams',
    type: 'COMPASS_DRAW_4',
  },
  {
    title: 'Compass Draw 8 Teams',
    type: 'COMPASS_DRAW_8',
  },
];

interface BracketFilterOption {
  title: string;
  type: BracketBaseType;
  enabled?: boolean;
}
const BRACKET_FILTER_OPTIONS: Array<BracketFilterOption> = [
  {
    title: 'Single Elimination',
    type: 'SINGLE_ELIMINATION',
    enabled: true,
  },
  {
    title: 'Compass Draw',
    type: 'COMPASS_DRAW',
    enabled: true,
  },
  {
    title: 'Double Elimination',
    type: 'DOUBLE_ELIMINATION',
  },
  {
    title: 'Three Game Guarantee',
    type: 'THREE_GAME_GUARANTEE',
  },
  {
    title: 'Miscellaneous',
    type: 'MISCELLANEOUS',
  },
  {
    title: 'Winners Right Losers Left',
    type: 'WINNERS_RIGHT_LOSERS_LEFT',
  },
];

interface CreateBracketModalProps {
  isOpen: boolean;
  onCreate: (type: BracketType) => void;
  onClose: () => void;
}

const CreateBracketModal: FunctionComponent<CreateBracketModalProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const [filterType, setFilterType] = useState<BracketBaseType | null>();
  const [selectedType, setSelectedType] = useState<BracketType | null>();

  const items: Array<BracketOption> = useMemo(() => {
    if (!filterType) {
      return BRACKET_OPTIONS;
    }

    const filteredItems = BRACKET_OPTIONS.filter((bc) =>
      bc.type.includes(filterType)
    );

    return filteredItems ?? [];
  }, [filterType]);

  const handleChangeFilter = (newFilter?: BracketBaseType) => {
    if (newFilter && !selectedType?.includes(newFilter)) {
      setSelectedType(null);
    }
    setFilterType(newFilter ?? null);
  };

  const handleCloseModal = () => {
    setFilterType(null);
    setSelectedType(null);
    onClose();
  };

  const handleCreateBracket = () => {
    if (!selectedType) {
      return;
    }

    onCreate(selectedType);
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0 ,0, 0.4)',
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        content: {
          background: 'inherit',
          maxWidth: 'fit-content',
          position: 'static',
          borderRadius: '0',
          borderWidth: 0,
          padding: '0',
          margin: '0',
        },
      }}
    >
      <SideModalContainer>
        <SideModalHeader>
          <h4>Select Bracket</h4>
        </SideModalHeader>
        <SideModalMain>
          <aside>
            <SideModalMenuItem
              $selected={!filterType}
              onClick={() => handleChangeFilter()}
            >
              All
            </SideModalMenuItem>
            {BRACKET_FILTER_OPTIONS.map((bf) => (
              <SideModalMenuItem
                key={bf.type}
                $selected={Boolean(filterType) && filterType === bf.type}
                $disabled={!bf.enabled}
                onClick={() => bf.enabled && handleChangeFilter(bf.type)}
              >
                {bf.title}
              </SideModalMenuItem>
            ))}
          </aside>
          <SideModalList>
            {items.map((item) => (
              <SideModalListItem
                key={item.title}
                $selected={Boolean(selectedType) && item.type === selectedType}
                onClick={() => setSelectedType(item.type)}
              >
                {item.imgUrl ? (
                  <img src={item.imgUrl} alt={item.title} />
                ) : (
                  <p>{item.title}</p>
                )}
              </SideModalListItem>
            ))}
          </SideModalList>
        </SideModalMain>
        <SideModalFooter>
          <OutlinedButtonWithIcon
            color='primary'
            customIcon={<Icon type='plus' />}
            disabled={!selectedType}
            onClick={handleCreateBracket}
          >
            Create
          </OutlinedButtonWithIcon>
        </SideModalFooter>
      </SideModalContainer>
    </Modal>
  );
};

export default CreateBracketModal;
