import styled from 'styled-components';
import { Modal } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { BREAKPOINTS } from 'styles/v3/constants';
import { COLORS, BodyXLBold, BodyM } from 'styles/v3/variables';

import {
  YELLOW_CARD_CLASS,
  DOUBLE_YELLOW_CARD_CLASS,
  RED_CARD_CLASS,
} from './config';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 520px;
    border-radius: 12px;
    background-color: ${COLORS.grey900};

    button {
      margin-bottom: 0;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 12px;
    padding: 6px 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #424242;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const FutsalTitle = styled(BodyXLBold)`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
`;

export const TimePickerWrapper = styled.div`
  width: 200px;
  align-self: center;
  margin-bottom: 16px;

  .ant-picker-input input {
    margin: 0;
    text-align: center;
    padding-left: 0 !important; // Due to library restriction
    font-family: 'Boxed Medium';
    font-weight: 500;
    font-size: 22px;
    border-color: #484848 !important; // Due to library restriction
    background-color: ${COLORS.grey1000} !important; // Due to library restriction
    transition-duration: 300ms;

    &:hover,
    &:focus {
      border-color: ${COLORS.brandPrimary} !important; // Due to library restriction
    }
  }

  .ant-picker-focused {
    box-shadow: none;
  }
`;

export const Label = styled(BodyM)`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${COLORS.white};
`;

export const PlayerNumberList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 12px;
  row-gap: 12px;
  padding-top: 12px;
  margin-bottom: 16px;
  border-top: 1px solid #424242;
`;

export const PlayerNumberItem =
  styled.div <
  { $selected: boolean } >
  `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 22px;
  background-color: ${COLORS.grey400};
  color: ${COLORS.grey1000};
  cursor: pointer;
  transition-duration: 300ms;
  user-select: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -3px;
    left: -4px;
    display: none;
    width: 15px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid ${COLORS.grey900};
  }

  &.${YELLOW_CARD_CLASS} {
    &:before {
      display: block;
      background-color: ${COLORS.brandPrimary};
    }
  }

  &.${DOUBLE_YELLOW_CARD_CLASS} {
    pointer-events: none;
    background-color: rgba(167, 168, 161, 0.6);

    &:before {
      display: block;
      width: 12px;
      height: 16px;
      border-radius: 2px;
      background-color: ${COLORS.brandPrimary};
    }

    &:after {
      display: block;
      background-color: red;
      top: 2px;
      left: 0;
      width: 11px;
      height: 15px;
      border-radius: 2px;
    }
  }

  &.${RED_CARD_CLASS} {
    pointer-events: none;
    background-color: rgba(167, 168, 161, 0.6);

    &:before {
      display: block;
      background-color: red;
    }
  }

  ${(props) =>
    props.$selected &&
    `
    background-color: ${COLORS.brandPrimary};
  `}
`;

export const CoachList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
  row-gap: 12px;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: ${COLORS.grey1000};
`;

export const CoachItem =
  styled(BodyM) <
  { $selected: boolean } >
  `
  position: relative;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: ${COLORS.grey900};
  color: white;
  cursor: pointer;
  transition-duration: 300ms;
  user-select: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    display: none;
    width: 15px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid ${COLORS.grey1000};
  }

  &.${YELLOW_CARD_CLASS} {
    &:before {
      display: block;
      background-color: ${COLORS.brandPrimary};
    }
  }

  &.${DOUBLE_YELLOW_CARD_CLASS} {
    pointer-events: none;
    background-color: #31322d;
    color: #aaaaaa;

    &:before {
      display: block;
      width: 12px;
      height: 16px;
      border-radius: 2px;
      background-color: ${COLORS.brandPrimary};
    }

    &:after {
      display: block;
      background-color: red;
      top: -2px;
      left: -3px;
      width: 11px;
      height: 15px;
      border-radius: 2px;
    }
  }

  &.${RED_CARD_CLASS} {
    pointer-events: none;
    background-color: #31322d;
    color: #aaaaaa;

    &:before {
      display: block;
      background-color: red;
    }
  }

  ${(props) =>
    props.$selected &&
    `
    background-color: ${COLORS.brandPrimary};
    color: ${COLORS.grey1000};
  `}
`;

export const ActionGroup = styled.div`
  display: flex;
  width: 100%;
  column-gap: 12px;
  padding-top: 16px;
`;

export const ConfirmButton =
  styled(FilledButton) <
  { $disabled: boolean } >
  `
  width: calc(50% - 6px);
  background-color: ${
    COLORS.brandPrimary
  } !important; // Due to modal library nesty
  transition-duration: 300ms;

  ${(props) =>
    props.$disabled &&
    `
    background-color: ${COLORS.grey500} !important;
    border-color: ${COLORS.grey500};
    color: ${COLORS.grey900};
    pointer-events: none;
  `}

  &:hover {
    background-color: #272923 !important; // Due to modal library nesty
    color: ${COLORS.brandPrimary};
    border-color: #272923;
  }
`;

export const CancelButton = styled(OutlinedButton)`
  width: calc(50% - 6px);
  background-color: transparent !important; // Due to modal library nesty
  transition-duration: 300ms;

  &:hover {
    color: ${COLORS.white};
    border-color: #a7a8a1;
  }
`;
