import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${COLORS.grey900};
  border-radius: 0rem 0rem ${toRem(8)} ${toRem(8)};
  padding: 0 ${toRem(20)} ${toRem(20)} ${toRem(20)};

  gap: ${toRem(24)};

  button {
    padding: 0;
    border: none;
    min-height: 0;
    background-color: unset;
    color: ${COLORS.grey200};

    &:hover {
      border: none;
      background-color: unset;
      color: ${COLORS.grey300};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(24)};
`;

export const PoolPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(8)};
`;

export const PoolPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${toRem(16)};
  border-radius: ${toRem(8)};
  background-color: ${COLORS.grey800};

  input {
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    font-family: 'Boxed Regular';
    background-color: transparent;
  }
`;

export const AddPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    span {
      margin-bottom: ${toRem(1.5)};
    }
  }
`;

export const ButtonsAction = styled.div`
  display: flex;

  gap: ${toRem(24)};

  span {
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      opacity: 0.8;
    }

    &::before {
      display: none;
    }
  }
`;

export const PoolNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(6)};
`;
