import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { useLocation, useParams } from 'react-router-dom';
import Loading from 'components/v3/Loading/Loading';
import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import { BodyS } from 'styles/v3/variables';
import { Avatar } from 'antd';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import ConvertUtil from 'util/ConvertUtil';
import { BottomCalendar } from '../../components/BottomCalendar';
import { formatDate } from '../../utils';
import { Header } from '../../components';
import { ScheduleAccordionContent } from '../../components/ScheduleAccordion/ScheduleAccordionContent';
import { Day, Match } from './types';
import * as S from './styles';

const TeamView = () => {
  const location = useLocation();
  const { eventId, clubId } = useParams<{ eventId: string, clubId: string }>();

  const [divisionId, setDivisionId] = useState<string>();
  const [dateSelected, setDateSelected] = useState<string>();

  const { data: club } = useGetClubById(clubId);
  const { data: event } = useGetEvent(eventId);
  const { data: schedule, isLoading } = useGetScheduleByEventId(eventId);

  const transformMatches = (): Match[] => {
    if (!schedule?.days?.length) return [];

    const matches: Match[] = [];

    schedule.days.forEach((day) => {
      day.matches?.forEach((matchItem) => {
        if (matchItem?.ready && matchItem.ageDivisionId === divisionId) {
          const formattedDate = formatDate(
            (matchItem?.endTime ?? '').toString()
          );
          if (formattedDate === dateSelected) {
            matches.push({
              ...matchItem,
            });
          }
        }
      });
    });

    return matches;
  };

  const generateDayArray = (): Day[] => {
    if (!schedule?.days?.length) return [];

    return schedule?.days?.map((dayItem) => ({
      day: formatDate(dayItem?.day.toString()),
    }));
  };

  const handleDaySelect = useCallback(
    (day: string) => () => setDateSelected(day),
    []
  );

  const division = useMemo(
    () => event?.data.ageDivisions?.find((item) => item.id === divisionId),
    [divisionId, event?.data]
  );

  const transformedMatches = useMemo(() => transformMatches(), [
    schedule,
    dateSelected,
    divisionId,
  ]);

  const dayArray = useMemo(() => generateDayArray(), [schedule, divisionId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryDivisionId = queryParams.get('division');

    if (queryDivisionId) setDivisionId(queryDivisionId);
  }, [location, eventId, clubId]);

  useEffect(() => {
    setDateSelected(generateDayArray()[0]?.day);
  }, [schedule]);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <Header event={event?.data} />
      <S.InfoWrapper>
        <S.TextsWrapper>
          <BodyS $color='grey300' $isUpper>
            SEEING GAMES FOR
          </BodyS>
          <BodyS $color='grey300' $isUpper>
            DIVISION
          </BodyS>
        </S.TextsWrapper>
        <S.InfosContainer>
          <S.ClubInfoWrapper>
            <Avatar size={28} src={ConvertUtil.getMinioUrl(club?.logo)} />
            <BodyS $color='grey300' $isUpper>
              {club?.name}
            </BodyS>
          </S.ClubInfoWrapper>
          <S.Tag>
            <BodyS $isUpper>{division?.name}</BodyS>
          </S.Tag>
        </S.InfosContainer>
      </S.InfoWrapper>
      {transformedMatches.length > 0 &&
        transformedMatches.map((match) => (
          <ScheduleAccordionContent
            key={match.startTime}
            divisionColor={division?.color}
            {...match}
          />
        ))}
      <S.BottomContainer>
        <BottomCalendar
          listDay={dayArray.map((item) => ({
            ...item,
            selected: item.day === dateSelected,
            onSelected: () => handleDaySelect(item.day),
          }))}
        />
      </S.BottomContainer>
    </S.Container>
  );
};

export default TeamView;
