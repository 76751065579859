import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Pagination } from 'antd';

import ClubCardInline from 'components/v3/Cards/ClubCardInline/ClubCardInline';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputSearch from 'components/v3/Forms/InputSearch/InputSearch';
import TabBar from 'components/v3/Elements/TabBar/TabBar';
import Loading from 'components/v3/Loading/Loading';

import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';

import { TitleH2 } from 'styles/v3/variables';

import { useFindAllWithFilters } from 'hooks/v3/clubs/useFindAllWithFilters/useFindAllWithFilters';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { EmptyState, FindFiltersDrawer } from './components';
import { InvitationFiltersType } from './components/FindFiltersDrawer/types';
import { useDrawerOptions } from './hooks';
import * as S from './styles';

const FindClubs = () => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [clubName, setClubName] = React.useState<string>('');
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const debouncedValue = useDebounce<string>(clubName, 500);

  const [
    advancedFilter,
    setAdvancedFilter,
  ] = React.useState<InvitationFiltersType>({
    city: '',
    state: '',
    country: '',
    totalMembers: '',
  });

  const { data, isLoading } = useFindAllWithFilters({
    filters: {
      ...advancedFilter,
      pageSize: 8,
      name: debouncedValue,
      page: currentPage - 1,
    },
  });

  const drawerOptions = useDrawerOptions(data?.content);

  function handlePreviousStep() {
    history.goBack();
  }

  const handleGoToDashboard = (clubId: string) => {
    history.push(`/v3/club/${clubId}`);
  };

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);
  };

  return (
    <>
      <S.PageTitle $maxWidth={1044}>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $isUpper>Clubs</TitleH2>
      </S.PageTitle>

      <S.Wrapper $maxWidth={1044}>
        <S.WrapperHeader>
          <TabBar
            items={[
              {
                name: 'my clubs',
                link: '/v3/clubs',
                path: '/v3/clubs',
              },
              {
                name: 'find clubs',
                link: '/v3/clubs/find',
                path: '/v3/clubs/find',
              },
            ]}
          />
        </S.WrapperHeader>

        <S.WrapperSubHeader>
          <TextButtonWithIcon
            noPadding
            color='grey400'
            customIcon='Filter'
            onClick={() => setIsDrawerOpen((prev) => !prev)}
          >
            FILTERS
          </TextButtonWithIcon>

          <FindFiltersDrawer
            isLoading={isLoading}
            isOpen={isDrawerOpen}
            drawerOptions={drawerOptions}
            advancedFilter={advancedFilter}
            onClose={() => setIsDrawerOpen(false)}
            handleChange={handleSetAdvancedFilter}
          />

          <InputSearch
            id='search'
            onChange={({ target }) => setClubName(target?.value)}
          />
        </S.WrapperSubHeader>

        {!isLoading ? (
          <S.WrapperBody
            $noMarginTop
            $gridTemplateColumns='2,1'
            $empty={data?.content.length === 0}
          >
            {data?.content?.length ? (
              data?.content?.map((club) => (
                <>
                  <ClubCardInline
                    key={club.name}
                    name={club.name}
                    locality={`${club.city}, ${club.state}`}
                    logo={club.logo}
                    members={club.totalMembers}
                    onClick={() => handleGoToDashboard(club.id)}
                    status={
                      club.clubVerified
                        ? DocumentStatusLabel[club.clubVerified]
                        : DocumentStatusLabel.APPROVED
                    }
                  />
                </>
              ))
            ) : (
              <EmptyState />
            )}
          </S.WrapperBody>
        ) : (
          <Loading />
        )}

        {data?.total ? (
          <S.WrapperPagination>
            <Pagination
              total={data?.total}
              defaultPageSize={8}
              current={currentPage}
              showSizeChanger={false}
              onChange={setCurrentPage}
            />
          </S.WrapperPagination>
        ) : null}
      </S.Wrapper>
    </>
  );
};

export default withRouter(FindClubs);
