import { useQuery } from '@tanstack/react-query';

import NotificationsService from 'services/v3/Notifications/NotificationsService';

export const useGetMyNotifications = () => {
  const response = useQuery(['get-my-notifications'], () =>
    NotificationsService.getMyNotifications()
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
