import React, { useMemo } from 'react';
import { BodyLBold, BodyMBold, BodyS } from 'styles/v3/variables';
import { Switch } from 'antd';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { notification } from 'components/v3/Notification/notification';
import { useParams } from 'react-router-dom';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import ClubService from 'services/v3/Clubs/ClubService';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { MultiValue, SingleValue } from 'react-select';
import { PermissionsProps } from './types';
import * as S from './styles';

export const Permissions = ({ user }: PermissionsProps) => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const [isProcessing, setIsProcessing] = React.useState(false);
  const queryClient = useQueryClient();

  const permissionInfo = useMemo(() => {
    if (!currentClub || !user)
      return {
        isCoach: false,
        isPlayer: false,
        isTeamManager: false,
        teamManagerId: user.id,
        ageDivisions: [],
        formattedAgeDivisions: [],
      };

    const userIds = [user.id, ...(user.userIds || [])];
    const { playerIds = [], coachIds = [], teamManagers = [] } = currentClub;
    const foundManagers = teamManagers.filter((p) =>
      userIds.includes(p.userId)
    );
    const sortedDivisions =
      foundManagers[0]?.ageDivision.sort((a, b) => {
        return b - a;
      }) || [];
    return {
      isPlayer: playerIds.filter((p) => userIds.includes(p)).length > 0,
      isCoach: coachIds.filter((p) => userIds.includes(p)).length > 0,
      isTeamManager: foundManagers.length > 0,
      teamManagerId: foundManagers[0]?.userId || user.id,
      ageDivisions: sortedDivisions,
      formattedAgeDivisions: sortedDivisions.map((d) => ({
        label: `${d}`,
        value: d,
      })),
    };
  }, [user, currentClub]);

  const handleOnChange = async (
    active: boolean,
    userType: 'PLAYER' | 'COACH' | 'TEAM_MANAGER',
    divisions?: number[]
  ) => {
    if (!isProcessing) {
      setIsProcessing(true);

      let ageDivisions =
        userType === 'TEAM_MANAGER' ? permissionInfo.ageDivisions : [];

      if (divisions !== undefined) {
        ageDivisions = divisions;
      }

      ClubService.updateClubMembersPool(params.clubId, {
        userType,
        actionType: active ? 'ADD' : 'REMOVE',
        memberId:
          userType === 'TEAM_MANAGER' ? permissionInfo.teamManagerId : user.id,
        ageDivisions,
      })
        .then((res) => {
          if (res.httpCode === 200) {
            notification.success({
              duration: 2,
              message: 'Success',
              description: 'Member updated',
            });
          } else {
            notification.error({
              duration: 2,
              message: 'Error',
              description: res.message || 'Something went wrong',
            });
          }
          setIsProcessing(false);
        })
        .catch((res) => {
          notification.error({
            duration: 2,
            message: 'Error 2',
            description: res.message || 'Something went wrong',
          });
        })
        .finally(() => {
          setIsProcessing(false);
          queryClient.invalidateQueries(['/get-club-by-id', params.clubId]);
          queryClient.invalidateQueries(['profiles']);
          queryClient.invalidateQueries([
            'get-club-members-pool',
            params.clubId,
            accountId,
          ]);
        });
    }
  };

  const ageDivisionOptions = () => {
    const MIN_AGE = 5;
    const MAX_AGE = 80;

    const currentYear = new Date().getFullYear();
    return Array.from({ length: MAX_AGE - MIN_AGE }, (_, i) => ({
      label: `${currentYear - i - MIN_AGE}`,
      value: currentYear - i - MIN_AGE,
    }));
  };

  const handleSetAgeDivision = (
    value: MultiValue<OptionsType> | SingleValue<OptionsType>
  ) => {
    if (Array.isArray(value)) {
      // prevent duplicate
      const years = value.reduce<number[]>((acc, cur) => {
        if (!acc.includes(cur.value)) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
      handleOnChange(true, 'TEAM_MANAGER', years);
    }
  };

  return (
    <S.Container>
      <BodyLBold className='title'>Permissions</BodyLBold>
      <S.PermissionList>
        <Switch
          disabled={isProcessing}
          onChange={(checked) => handleOnChange(checked, 'PLAYER')}
          defaultChecked={permissionInfo.isPlayer}
        />
        <S.PermissionItem>
          <BodyMBold>Player</BodyMBold>
          <BodyS>Can be added to rosters as a player.</BodyS>
        </S.PermissionItem>

        <Switch
          disabled={isProcessing}
          onChange={(checked) => handleOnChange(checked, 'COACH')}
          defaultChecked={permissionInfo.isCoach}
        />
        <S.PermissionItem>
          <BodyMBold>Coach</BodyMBold>
          <BodyS>Can be added to rosters as a coach.</BodyS>
        </S.PermissionItem>

        <Switch
          disabled={isProcessing}
          onChange={(checked) => handleOnChange(checked, 'TEAM_MANAGER')}
          defaultChecked={permissionInfo.isTeamManager}
        />
        <S.PermissionItem>
          <BodyMBold>Team Manager</BodyMBold>
          <BodyS>Can edit and create rosters.</BodyS>
        </S.PermissionItem>

        {permissionInfo.isTeamManager && (
          <>
            <span />
            <Select
              placeholder='Select Age Division'
              name='ageDivision'
              isMulti
              options={ageDivisionOptions()}
              defaultValue={permissionInfo.formattedAgeDivisions}
              onChange={(value) => handleSetAgeDivision(value)}
            />
            <span />
            <BodyS>You can select more than one year.</BodyS>
          </>
        )}
      </S.PermissionList>
    </S.Container>
  );
};
