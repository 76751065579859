import React from 'react';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import { Plus } from '@icon-park/react';
import { BodyM, TitleH1 } from 'styles/v3/variables';

import { useHistory } from 'react-router-dom';
import * as S from './styles';

export const Header = () => {
  const history = useHistory();

  const handleCreateUser = () => {
    history.push('/v3/user/new');
  };

  return (
    <S.HeaderWrapper>
      <S.TitlesWrapper>
        <BodyM>SELECT OR CREATE A NEW USER</BodyM>
        <TitleH1>ALREADY GOT AN USER?</TitleH1>
      </S.TitlesWrapper>
      <OutlinedButtonWithIcon
        onClick={handleCreateUser}
        color='white-dark'
        customIcon={<Plus />}
      >
        New User
      </OutlinedButtonWithIcon>
    </S.HeaderWrapper>
  );
};
