import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-end;

  @media screen and (max-width: ${BREAKPOINTS.desktop}) {
    align-items: flex-start;
  }
`;
