export const currencySymbol = (currency: string, country = 'en-US') => {
  const convert = (0)
    .toLocaleString(country, {
      maximumFractionDigits: 0,
      style: 'currency',
      currency,
    })
    .replace(/\d/g, '')
    .trim();

  return convert;
};

export const NumberToUSDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrencyValues = (value: string): string => {
  const cleanedString = value.replace(/[^\d.]/g, '');
  return parseFloat(cleanedString).toString();
};
