import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div``;

export const InformationText = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: ${toRem(270)};

    padding: ${toRem(24)} ${toRem(16)};
  }
`;

export const BottomContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${toRem(588)};
  width: 100%;
`;
