import styled from 'styled-components';

import { Tabs as AntdTabs } from 'antd';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 32px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav {
    ::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    visibility: visible !important;
    background: ${COLORS.brandPrimary};
  }

  .ant-tabs-nav-list {
    gap: 24px;
  }

  .ant-tabs-tab {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 8px !important;
    background-color: unset !important;
  }

  .ant-tabs-tab:hover {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab-btn:active {
    color: ${COLORS.brandPrimary};
  }
`;

export const TabPane = styled(AntdTabs.TabPane)`
  margin-top: 24px;
`;

export const NewEventButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;

  border: none;

  color: ${COLORS.white};
  text-transform: uppercase;
  font-size: ${toRem(16)};

  cursor: pointer;

  svg {
    margin-left: ${toRem(8)};
  }
`;

export const StyledExtraContent = styled.div`
  display: flex;
  column-gap: 10px;
`;
