import React, {
  memo,
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useProfile } from 'hooks/v3/profile/useProfile';

import futsalSeasonBackgroundImage from 'assets/imgs/futsal-season-bg-image.png';

import { FUTSAL_SEASON_CONFIRM_KEY, ONE_YEAR_IN_MS } from './configs';

import * as S from './styles';

export const FutsalSeasonResetConfirm: FC = memo(() => {
  const { currentUser } = useProfile();

  const [visible, setVisible] = useState(false);

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  const handleClose = useCallback(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    localStorage.setItem(
      FUTSAL_SEASON_CONFIRM_KEY,
      JSON.stringify(firstDayOfMonth.getTime())
    );

    setVisible(false);
  }, []);

  useEffect(() => {
    const currentDate = Date.now();
    const storedFutsalSeasonDate = localStorage.getItem(
      FUTSAL_SEASON_CONFIRM_KEY
    );

    if (!currentUser) return;

    if (storedFutsalSeasonDate) {
      const storedDateNumber = JSON.parse(storedFutsalSeasonDate);

      if (currentDate - storedDateNumber >= ONE_YEAR_IN_MS) {
        setVisible(true);
      }

      return;
    }

    setVisible(true);
  }, [currentUser]);

  return (
    <S.StyledModal
      centered
      visible={visible}
      footer={false}
      closable={false}
      onCancel={handleClose}
    >
      <S.Container>
        <S.ImageWrapper>
          <img
            src={futsalSeasonBackgroundImage}
            alt='futsal season background'
          />
        </S.ImageWrapper>
        <S.Title>
          {currentYear}/{currentYear + 1} Season Begins NOW!
        </S.Title>
        <S.Description>
          As of September 1st, all XP from the previous season have been reset
          for the start of the new season which will run until August 31,{' '}
          {currentYear + 1}. Don’t worry though, all your career accomplishments
          have been saved and are viewable at any time in your dashboard. See
          you on the courts!
        </S.Description>
        <S.ConfirmButton isUpper onClick={handleClose}>
          I understand
        </S.ConfirmButton>
      </S.Container>
    </S.StyledModal>
  );
});

FutsalSeasonResetConfirm.displayName = 'FutsalSeasonResetConfirm';
