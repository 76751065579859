import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: ${toRem(10)};
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr ${toRem(38)};
  align-items: center;
  padding: ${toRem(8)} ${toRem(16)};

  height: ${toRem(50)};
  border-radius: ${toRem(12)};
  border: 1px solid ${COLORS.grey400};

  p {
    cursor: pointer;
  }

  @media screen and (max-width: ${BREAKPOINTS.smallMobile}) {
    input {
      width: ${toRem(170)};
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: ${toRem(8)} ${toRem(10)};
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: 0;
  outline: 0;
`;
