import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'components/v3/Table/Table';
import RightDrawer from 'components/v3/RightDrawer';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';

import { ApplicationState } from 'redux/store';
import { clubDashboardToggleSelectedMemberPoolUser } from 'redux/v3/clubDashboard/actions';

import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';
import DateUtil from 'util/DateUtil';
import { BodyLBold } from 'styles/v3/variables';

import { useGetRequestsByClubAndStatus } from 'hooks/v3/requests/useGetRequestsByClubAndStatus/useGetRequestsByClubAndStatus';
import { columns } from './columns';

import { FilterModalBody } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/FilterModalBody';
import { InvitationFiltersType } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/types';
import { Filters } from './components';
import { GroupedRequest } from './types';
import { ApproveRejectModal } from './components/Modal/ApproveRejectModal/ApproveRejectModal';

export const ApplicationsTable = () => {
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { data: requests } = useGetRequestsByClubAndStatus({
    clubId: params.clubId,
    status: [RequestStatus.PENDING],
    perPage: 200, // TODO use backend pagination
  });

  const [filterMember, setFilterMember] = useState('');
  const [isApproveModalOpen, setIsApproveModalOpen] = React.useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = React.useState(false);

  const [currentIds, setCurrentIds] = React.useState<string[]>([]);

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    documentStatus: [],
    gender: [],
    membership: [],
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const dispatch = useDispatch();

  const handleToggleSelectedUser = (userId: string) => {
    dispatch(clubDashboardToggleSelectedMemberPoolUser(userId));
  };

  const handleFilter = (text: string) => {
    setFilterMember(text);
  };

  const membersData = useMemo(() => {
    if (!requests || !requests?.content?.length || !currentClub) return [];

    const getPermissionType = (
      userId: string,
      userType?: UserType
    ): UserType => {
      if (currentClub.directorIds?.includes(userId))
        return UserType.CLUB_DIRECTOR;
      if (currentClub.coachIds?.includes(userId)) return UserType.COACH;
      if (currentClub.teamManagers?.map((t) => t.userId).includes(userId))
        return UserType.TOURNAMENT_DIRECTOR;
      if (currentClub.playerIds?.includes(userId)) return UserType.PLAYER;
      return userType || UserType.PLAYER;
    };

    const getDocumentStatus = (
      documentationVerified?: RequestStatus,
      ageVerified?: RequestStatus,
      type?: UserType
    ): RequestStatus => {
      if (!type) return RequestStatus.APPROVED;

      switch (type) {
        case UserType.PLAYER:
          return ageVerified || RequestStatus.DECLINED;
        default:
          return documentationVerified || RequestStatus.DECLINED;
      }
    };

    const getGender = (currentGender?: string) => {
      switch (currentGender) {
        case 'MALE':
          return 'Boy';
        case 'FEMALE':
          return 'Girl';
        default:
          return 'Other';
      }
    };

    /* eslint-disable no-param-reassign */
    const groupedRequests = requests.content.reduce<GroupedRequest[]>(
      (acc, cur) => {
        if (!cur.user.baseUserId) {
          acc.push({
            ...cur,
            userTypes: [cur.user.type || UserType.PLAYER],
            userIds: [cur.user.id],
            ids: [cur.id],
          });
          return acc;
        }
        const index = acc.findIndex(
          (item) => item.user.baseUserId === cur.user.baseUserId
        );
        if (index === -1) {
          acc.push({
            ...cur,
            userTypes: [cur.user.type || UserType.PLAYER],
            userIds: [cur.user.id],
            ids: [cur.id],
          });
          return acc;
        }
        acc[index] = {
          ...acc[index],
          user: {
            ...acc[index].user,
            clubs: [...acc[index].user.clubs, ...cur.user.clubs],
          },
          userTypes: [
            ...acc[index].userTypes,
            cur.user.type || UserType.PLAYER,
          ],
          userIds: [...acc[index].userIds, cur.user.id],
          ids: [...acc[index].ids, cur.id],
        };
        return acc;
      },
      []
    );

    return groupedRequests
      .map((request) => {
        const member = request.user;

        return {
          id: member.id,
          name: `${member.firstName}`,
          permission: getPermissionType(member.id, member.type),
          age: DateUtil.getYearFromDateString(member.birthDate?.toString()),
          gender: getGender(member.gender),
          membership: member.membership?.type || 'COMMUNITY',
          documentStatus: getDocumentStatus(
            member.documentationVerified,
            member.ageVerified,
            member.type
          ),
          numberOfClubs: member.clubs.length,
          userTypes: request.userTypes,
          requestIds: request.ids,
          photoStatus: member.photoVerified,
          ageStatus: member.ageVerified,
          safeSportStatus: member.documentationVerified,
          photo: member.photo,
          selected: selectedUserIds.includes(member.id),
          toggleSelected: () => handleToggleSelectedUser(member.id),
          openApproveModal: () => handleOpenRequest(request.ids, 'approve'),
          openRejectModal: () => handleOpenRequest(request.ids, 'reject'),
        };
      })
      .filter((member) =>
        !filterMember
          ? true
          : member.name.toLowerCase().includes(filterMember.toLowerCase())
      )
      .filter((member) => {
        const ageFilter =
          advancedFilter.age.length === 0
            ? true
            : advancedFilter.age.includes(Number(member.age));

        const genderFilter =
          advancedFilter.gender.length === 0
            ? true
            : advancedFilter.gender.includes(member.gender);

        const membershipFilter =
          advancedFilter.membership.length === 0
            ? true
            : advancedFilter.membership.includes(member.membership);

        const documentStatusFilter =
          advancedFilter.documentStatus.length === 0
            ? true
            : advancedFilter.documentStatus.includes(
                member.documentStatus.toString()
              );

        return (
          ageFilter && genderFilter && membershipFilter && documentStatusFilter
        );
      });
  }, [requests, selectedUserIds, filterMember, currentClub, advancedFilter]);

  const handleOpenRequest = (
    requestIds: string[],
    type: 'approve' | 'reject'
  ) => {
    setCurrentIds(requestIds);
    if (type === 'approve') {
      setIsApproveModalOpen(true);
    } else {
      setIsRejectModalOpen(true);
    }
  };

  const handleOpenFilterModal = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);
  };

  return (
    <>
      <Filters
        handleOpenFilterModal={handleOpenFilterModal}
        handleChange={handleFilter}
      />
      <Table columns={columns} dataSource={membersData} />

      <>
        <ApproveRejectModal
          type='approve'
          isOpen={isApproveModalOpen}
          handleIsOpen={setIsApproveModalOpen}
          requestIds={currentIds}
        />
        <ApproveRejectModal
          type='reject'
          isOpen={isRejectModalOpen}
          handleIsOpen={setIsRejectModalOpen}
          requestIds={currentIds}
        />
      </>

      <RightDrawer
        isOpen={isFilterOpen}
        handleCloseModal={handleCloseFilterModal}
        headerComponent={<BodyLBold $color='brandPrimary'>Filters</BodyLBold>}
      >
        <FilterModalBody
          filters={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </RightDrawer>
    </>
  );
};
