/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import { BodyM, BodyS, TitleH4 } from 'styles/v3/variables';
import { useParams } from 'react-router-dom';
import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import Loading from 'components/v3/Loading/Loading';
import { Content, Wrapper, Card, CardHeader } from './styles';
import { AllTeamsListData, AllTeamsListDataItem } from './types';
import { PrintButton } from '../components/PrintButton/PrintButton';

const AllTeamsList: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const {
    data: eventRosters,
    isLoading: isLoadingRoster,
  } = useGetEventRosters({ eventId });
  const { data: currentEvent, isLoading: isLoadingEvent } = useGetEventById(
    eventId
  );

  const allTeamsData = useMemo(() => {
    const defaultValue: AllTeamsListData = {
      items: [],
    };

    if (!eventRosters || !currentEvent) return defaultValue;

    return {
      items: eventRosters.reduce<AllTeamsListDataItem[]>((acc, curr) => {
        let description = 'Division';
        const currentDivision = currentEvent.ageDivisions?.find(
          (division) => division.id === curr.ageDivision.id
        );
        if (currentDivision) {
          description = currentDivision.name ?? 'Division';
          const divisionYears = currentDivision.years.join('/');
          const divisionGender =
            currentDivision.gender[0].toUpperCase() +
            currentDivision.gender.toLowerCase().slice(1);
          description = `${description} ${divisionYears} ${divisionGender}`;
        }

        const index = acc.findIndex((c) => c.name === curr.club.name);
        if (index !== -1) {
          acc[index].membersCount += curr.players.length + curr.coaches.length;
        } else {
          acc.push({
            id: curr.id,
            name: curr.club.name,
            description,
            membersCount: curr.players.length + curr.coaches.length,
          });
        }

        return acc;
      }, []),
    };
  }, [eventRosters, currentEvent]);

  if (isLoadingRoster || isLoadingEvent) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <PrintButton text='Print All Teams' />

      <Content>
        {!allTeamsData.items.length ? (
          <TitleH4>No teams on this event</TitleH4>
        ) : (
          allTeamsData.items.map((item) => (
            <Card key={item.id}>
              <CardHeader>
                <TitleH4>{item.name}</TitleH4>
                <BodyS>{item.description}</BodyS>
              </CardHeader>
              <BodyM>{item.membersCount} members</BodyM>
            </Card>
          ))
        )}
      </Content>
    </Wrapper>
  );
};

export default AllTeamsList;
