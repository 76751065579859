/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import Keycloak from 'keycloak-js';
import React, {
  FunctionComponent,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';
import {
  Court,
  Dollar,
  Error,
  LinkBreak,
  ListTop,
  MapDraw,
  Ruler,
  SettingTwo,
  Sport,
} from '@icon-park/react';

// styles properties
import { TitleH2, BodyL, COLORS, TitleH4 } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { InfoEventEdit } from 'components/v3/Cards/InfoEventEdit';
import FilledButton from 'components/v3/Buttons/FilledButton';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useDispatch, useSelector } from 'react-redux';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { ApplicationState } from 'redux/store';
import SetupEventModal from 'components/v3/SetupEvent/Modal';

// TYPES STATUS FOR PUBLISH
import {
  EventInfoModel,
  GeneralRulesModel,
  MatchSettingsModel,
} from 'models/v3/setupEvent';
import { EventPublishedStatus } from 'admin/models/event/Event';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import Loading from 'components/v3/Loading/Loading';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import _ from 'lodash';
import {
  EditContainer,
  HeaderEdit,
  HeaderTitle,
  EventStatusCrumb,
  EditContentList,
  PublishEventContainer,
  Container,
  EventSetupAndProgressBarContainer,
} from './styles';
import { SettingInfo } from './types';

const settingInfoArray: SettingInfo[] = [
  {
    title: 'Event Info',
    selectedModal: 'eventInfo',
    component: <ListTop size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'General Rules',
    selectedModal: 'generalRules',
    component: <Ruler size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Match Setting',
    selectedModal: 'matchSettings',
    component: <SettingTwo size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Divisions',
    selectedModal: 'divisions',
    component: <Sport size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Venues',
    selectedModal: 'venues',
    component: <MapDraw size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Courts',
    selectedModal: 'courts',
    component: <Court size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Pricing',
    selectedModal: 'pricing',
    component: <Dollar size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Multipliers',
    selectedModal: 'multipliers',
    component: <Error size={28} />,
    color: COLORS.grey50,
  },
  {
    title: 'Tiebreaker',
    selectedModal: 'tiebreakers',
    color: COLORS.grey50,
    component: <LinkBreak size={28} />,
  },
];

interface EventParams {
  auth: Keycloak.KeycloakInstance | undefined;
}

type NestedObject = { [key: string]: any };

const EditEvent: FunctionComponent = (param) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: ApplicationState) => state.sideModal);
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const statusObject = {
    [COLORS.grey50]: 'notOpened',
    [COLORS.brandPrimary]: 'ok',
    [COLORS.supportError]: 'error',
    [COLORS.grey200]: 'drafted',
  };

  const { auth } = param as EventParams;
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isStaff } = useProfile();
  const { data: currentEvent } = useGetEventById(params.eventId);
  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const [totallyInfos, setTotallyInfos] = useState<number>(0);
  const [infosToEdit, setInfosToEdit] = useState<SettingInfo[]>(
    settingInfoArray
  );
  const [checkedSettings, setCheckedSettings] = useState<string[]>([]);

  const handleBack = useCallback(() => {
    const locationState: any = history.location.state || {};

    if (locationState?.from === 'dashboard') {
      history.goBack();
    } else {
      history.push('/v3/events?tab=created-by-me')
    }
  }, []);

  const isObjectFullPopulated = (obj: NestedObject): boolean => {
    let allValid = true;

    function checkValue(value: any) {
      if (_.isNull(value) || _.isUndefined(value) || value === '') {
        allValid = false;
      } else if (_.isObject(value) && !_.isArray(value)) {
        _.forOwn(value, (v, k) => {
          if (!allValid) return;
          checkValue(v);
        });
      }
    }

    _.forOwn(obj, (value) => {
      if (!allValid) return;
      checkValue(value);
    });

    return allValid;
  };

  const handlePublishEvent = async () => {
    try {
      const payload = {
        data: {
          eventPublished: EventPublishedStatus.PENDING,
        },
      };

      await mutateAsync({ id: eventId, data: payload });

      history.push('/v3/events?tab=created-by-me');
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const saveCheckedSettings = (value: string) => {
    setCheckedSettings([...checkedSettings, value]);
  };

  const allowPublishEvent = useMemo(() => {
    const eventPublished = eventData?.data?.eventPublished;

    return (
      !eventPublished || eventPublished === EventPublishedStatus.UNPUBLISHED
    );
  }, [eventData]);

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(eventId));
  }, [eventId, dispatch]);

  useEffect(() => {
    if (!isOpen && eventData) {
      const eventInfoStatus: EventInfoModel = {
        name: eventData.name,
        description: eventData.description,
        startDate: eventData.startDate as Date,
        endDate: eventData.endDate as Date,
        logo: eventData.logo,
        data: {
          eventInfo: {
            emailContact: eventData.data?.eventInfo?.emailContact,
            phoneContact: eventData.data?.eventInfo?.phoneContact,
          },
        },
      };

      const generalRulesStatus: GeneralRulesModel = {
        minimumPlayersNumber: eventData.minimumPlayersNumber,
        maximumPlayersNumber: eventData.maximumPlayersNumber,
        minimumCoachesNumber: eventData.minimumCoachesNumber,
        maximumCoachesNumber: eventData.maximumCoachesNumber,
        roasterLockDate: eventData.data?.generalRules?.rosterLockDate as Date,
        refereePair:
          eventData.data?.generalRules?.refereeConditions?.refereePair,
        refereeSolo:
          eventData.data?.generalRules?.refereeConditions?.refereeSolo,
        scoreKeeper:
          eventData.data?.generalRules?.refereeConditions?.scoreKeeper,
      };

      const matchSettingsStatus: MatchSettingsModel = {
        pointsToWin: eventData.data?.matchSettings?.pointsToWin,
        pointsToDraw: eventData.data?.matchSettings?.pointsToDraw,
        pointsToLose: eventData.data?.matchSettings?.pointsToLose,
        pointsToShutout: eventData.data?.matchSettings?.pointsToShutout,
        recordForForfeit: eventData.data?.matchSettings?.recordForForfeit,
        poolGamesLength: eventData.data?.matchSettings?.poolGamesLength,
        bracketGamesLength: eventData.data?.matchSettings?.bracketGamesLength,
        maxTimeBetweenGames: eventData.data?.matchSettings?.maxTimeBetweenGames,
        minTimeBetweenGames: eventData.data?.matchSettings?.minTimeBetweenGames,
        maxGamesPerTeam: eventData.data?.matchSettings?.maxGamesPerTeam,
      };

      const { ageDivisions, venues, data } = eventData;
      const multipliers = data?.multipliers;
      const tiebrakers = data?.tieBreakers;
      const pricing = data?.paymentInfo;
      const courts = venues?.map((venue) => venue?.subVenues);

      const infosTitles: string[] = [];
      const drafredInfosTitles: string[] = [];
      const isFilledAllRequiredEventInfoFields = isObjectFullPopulated(
        eventInfoStatus
      );
      const isFilledAllEventInfoFields = isObjectFullPopulated({
        ...eventInfoStatus,
        data: { eventInfo: { ...eventData.data?.eventInfo } },
      });
      const isFilledAllRequiredGeneralRules = isObjectFullPopulated(
        generalRulesStatus
      );
      const isFilledAllGeneralRules = isObjectFullPopulated({
        ...generalRulesStatus,
        refereeConditions: {
          ...eventData.data?.generalRules?.refereeConditions,
        },
      });

      if (isFilledAllRequiredEventInfoFields && !isFilledAllEventInfoFields) {
        drafredInfosTitles.push('Event Info');
      } else if (
        isFilledAllRequiredEventInfoFields &&
        isFilledAllEventInfoFields
      ) {
        infosTitles.push('Event Info');
      }

      if (isFilledAllRequiredGeneralRules && !isFilledAllGeneralRules) {
        drafredInfosTitles.push('General Rules');
      } else if (isFilledAllRequiredGeneralRules && isFilledAllGeneralRules) {
        infosTitles.push('General Rules');
      }
      isObjectFullPopulated(matchSettingsStatus) &&
        infosTitles.push('Match Setting');

      ageDivisions && ageDivisions.length > 0 && infosTitles.push('Divisions');
      venues && venues.length > 0 && infosTitles.push('Venues');
      courts && courts?.length > 0 &&
        courts.every((courtArray) => courtArray && courtArray?.length > 0) &&
        infosTitles.push('Courts');
      pricing && isObjectFullPopulated(pricing) && infosTitles.push('Pricing');

      multipliers && multipliers.length > 0 && infosTitles.push('Multipliers');
      tiebrakers && tiebrakers.length > 0 && infosTitles.push('Tiebreaker');

      setInfosToEdit((prev) => {
        return prev.map((item) => {
          if (!!drafredInfosTitles && drafredInfosTitles.includes(item.title)) {
            return {
              ...item,
              color: COLORS.grey200,
            };
          }
          if (!!infosTitles && infosTitles.includes(item.title)) {
            return {
              ...item,
              color: COLORS.brandPrimary,
            };
          }
          if (!!checkedSettings && checkedSettings.includes(item.title)) {
            return {
              ...item,
              color: COLORS.supportError,
            };
          }

          return { ...item, color: COLORS.grey50 };
        });
      });

      setTotallyInfos(infosTitles.length);
    }
  }, [isOpen, eventData, checkedSettings]);

  if (!currentEvent) return <Loading />;

  if (!currentEvent?.owner && !isStaff) {
    if (currentEvent?.draft) {
      history.push(`/v3/events`);
    } else {
      history.push(`/v3/event/${currentEvent.id}`);
    }
  }

  return (
    <Container>
      <EditContainer>
        <HeaderEdit>
          <TextButtonWithIcon
            color='primary'
            align='flex-end'
            reverse
            icon='back'
            onClick={handleBack}
          >
            Go back
          </TextButtonWithIcon>
          <HeaderTitle>
            <TitleH2 style={{ lineHeight: 0 }}>{eventData?.name || ''}</TitleH2>
            <EventStatusCrumb>
              {eventData?.data?.eventPublished || 'UNPUBLISHED'}
            </EventStatusCrumb>
          </HeaderTitle>
          <TitleH4>Event Settings</TitleH4>
        </HeaderEdit>
        <EditContentList>
          {infosToEdit.map((info) => (
            <InfoEventEdit
              status={statusObject[info.color]}
              key={info.title}
              title={info.title}
              setCheckedSettings={saveCheckedSettings}
              selectedModal={info.selectedModal}
            >
              {cloneElement(info.component, { fill: info.color })}
            </InfoEventEdit>
          ))}
        </EditContentList>
        <SetupEventModal auth={auth} />
      </EditContainer>
      <PublishEventContainer>
        <EventSetupAndProgressBarContainer>
          <BodyL $color='brandPrimary'>Event Setup</BodyL>
          <ProgressBar
            completed={totallyInfos}
            maxCompleted={9}
            bgColor={COLORS.brandPrimary}
            baseBgColor={COLORS.grey600}
            labelColor={COLORS.brandPrimary}
            width={`${toRem(441)}`}
            height={`${toRem(4)}`}
            borderRadius={`${toRem(25)}`}
            customLabel=''
          />
          <BodyL $color='brandPrimary'>
            {totallyInfos === 0 ? '' : totallyInfos + 1}0%
          </BodyL>
        </EventSetupAndProgressBarContainer>
        <FilledButton
          color='white-dark'
          isLoading={isLoading}
          onClick={handlePublishEvent}
          disabled={totallyInfos < 9 || !allowPublishEvent}
        >
          Publish Event
        </FilledButton>
      </PublishEventContainer>
    </Container>
  );
};

export default withRouter(EditEvent);
