import { OptionsType } from 'components/v3/Forms/Select/Select';

export const RELATIONSHIP_OPTIONS: OptionsType[] = [
  {
    label: 'Son / Daughter',
    value: 'SON',
  },
  {
    label: 'Nephew / Niece',
    value: 'NEPHEW',
  },
  {
    label: 'Club Participant',
    value: 'CLUB',
  },
  {
    label: 'Team Participant',
    value: 'TEAM',
  },
];

export const GENDER_OPTIONS: OptionsType[] = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
];
