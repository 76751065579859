import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const ModalHeader = styled.div`
  padding: ${toRem(24)} ${toRem(24)} ${toRem(10)} ${toRem(24)};
  display: flex;
  justify-content: space-between;

  .i-icon {
    display: flex;
    cursor: pointer;

    &::before {
      display: none;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;
  width: 100%;

  & > div {
    width: 100%;
    padding: ${toRem(25)} ${toRem(20)} 0 ${toRem(20)};
  }
`;

export const ModalFooterButtons = styled.div`
  padding: ${toRem(25)} ${toRem(20)} ${toRem(20)} ${toRem(20)};
  display: flex;
  justify-content: space-between;
  gap: ${toRem(16)};

  button {
    width: 50%;
  }
`;
