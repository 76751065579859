import { useQuery } from '@tanstack/react-query';

import RosterService from 'services/v3/Rosters/RosterService';

export function useGetRosterByIdDetailed(id: string) {
  const { data, refetch } = useQuery(
    ['/get-roster-detailed-id', id],
    async () => await RosterService.findDetailed(id)
  );

  return {
    data: data?.data || null,
    refetch,
  };
}
