import styled from 'styled-components';
import { color } from 'styles/styleVariables';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  color: ${COLORS.grey500};

  background: ${COLORS.white};

  @media print {
    @page {
      size: landscape;
    }
    background: none !important;
  }

  .printButton {
    @media print {
      display: none;
    }
    cursor: pointer;
    position: absolute;
    right: 0;
    background-color: ${color.orange};
    font-weight: 800;
    padding: 10px;
    color: ${color.greyDark};
    width: 50px;
    display: flex;
    width: 50px;
    height: 50px;
    overflow: hidden;
    align-items: center;
    &:hover {
      width: auto;
      div {
        display: block;
      }
    }
    div {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const CustomTableContainer = styled.div`
  padding: ${toRem(16)} 0;

  display: flex;
  flex-direction: column;
  row-gap: ${toRem(8)};
  background-color: ${COLORS.white};
`;

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const CustomTableHeader = styled.th`
  border-bottom: 0.2px solid ${COLORS.grey400};
  padding: 16px;
  text-align: left;
`;

export const CustomTableData = styled.td`
  border-bottom: 0.2px solid ${COLORS.grey400};
  color: ${COLORS.grey900};
  padding: 12px;
  text-align: left;
  font-size: 12px;
`;

function isEven(number: number) {
  return number % 2 === 0;
}
interface CustomTableRowProps {
  index: number;
}
export const CustomTableRow =
  styled.tr <
  CustomTableRowProps >
  `
  background-color: ${({ index }) =>
    isEven(index) ? COLORS.grey50 : 'transparent'};
`;
