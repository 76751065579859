export const SHARED_PLAN_LIST = [
  {
    text: 'Club and teams Registration',
    highlight: false,
  },
  {
    text: 'Bracket Management',
    highlight: false,
  },
  {
    text: 'Schedule Management',
    highlight: false,
  },
  {
    text: 'Game Results Tracking',
    highlight: false,
  },
  {
    text: 'Access to Referees database',
    highlight: false,
  },
  {
    text: 'Current VAULT members play free',
    highlight: false,
  },
  {
    text: 'Player registration and verification',
    highlight: false,
  },
  {
    text: 'Event Publish in public VAULT calendar',
    highlight: false,
  },
  {
    text: 'Communication',
    highlight: false,
  },
];

export const HIGHLIGHTED_PLAN_LIST = [
  {
    text: 'Qualification pathway for World Futsal Championships for teams',
    highlight: true,
  },
  {
    text: 'Sanctioned by United Futsal to US Soccer',
    highlight: true,
  },
];
