import React from 'react';

import { Header } from './components/Header';
import { MyDivisions } from './components/MyDivisions';
import * as S from './stlyes';

export const GroupCreation = () => {
  return (
    <S.Container>
      <Header />
      <MyDivisions />
    </S.Container>
  );
};
