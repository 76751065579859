import { Modal } from 'antd';
import React, { FC } from 'react';
import { COLORS } from 'styles/v3/variables';
import './styles.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Wrapper } from './styles';

const Styles = {
  height: '40px',
  width: '100%',
  fontFamily: 'Boxed Medium',
  borderRadius: '10px',
  fontSize: 20,
};

interface IProps {
  message: string;
}

const ConfirmModal: FC<IProps> = ({ message }) => {
  return <Wrapper>{message}</Wrapper>;
};

type Params = {
  title?: string,
  onOk: (arg: any) => any,
  message: string,
};

export const confirm = (params: Params) => {
  const { title, message, onOk } = params;

  Modal.confirm({
    title: title ?? 'Confirm',
    content: <ConfirmModal message={message} />,
    onOk,
    cancelText: 'Cancel',
    className: 'modalMain',
    okButtonProps: {
      style: {
        ...Styles,
        backgroundColor: COLORS.brandPrimary,
      },
      className: 'okButton',
    },
    cancelButtonProps: {
      style: {
        ...Styles,
        backgroundColor: COLORS.white,
      },
      className: 'okButton',
    },
    icon: (
      <InfoCircleOutlined color={COLORS.brandPrimary} className='customIcon' />
    ),
  });
};
