import React, { useMemo } from 'react';
import { useGetBracketsByEventId } from 'hooks/v3/brackets/useGetBracketsByEventId/useGetBracketsByEventId';
import { useParams } from 'react-router-dom';
import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { ScheduleMatch } from 'models/Schedule/ScheduleModel';
import { BracketItem } from '../Bracket/BracketItem';
import { Wrapper } from './styles';
import { PrintButton } from '../components/PrintButton/PrintButton';

const AllBrackets: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const { data: brackets } = useGetBracketsByEventId(eventId);
  const { data: currentSchedule } = useGetScheduleByEventId(eventId);

  // ENG-240 TODO edited after change ScheduledModel/ScheduledDays
  const bracketsData = useMemo(() => {
    if (!brackets || !currentSchedule) return [];
    const allMatches: ScheduleMatch[] = currentSchedule.days.reduce((acc, day) => {
      if (day?.matches) {
        acc.push(...day.matches);
      }
      return acc;
    }, [] as ScheduleMatch[]);
    return brackets.map((bracket) => ({
      ...bracket,
      matches: bracket.matches.map((match) => ({
        ...match,
        opponent1: {
          ...match.opponent1,
          clubName: allMatches.find(
            (m) => m?.bracketId === bracket.id && m?.matchId === match.id
          )?.opponent1?.clubName,
        },
        opponent2: {
          ...match.opponent2,
          clubName: allMatches.find(
            (m) => m.bracketId === bracket.id && m.matchId === match.id
          )?.opponent2?.clubName,
        },
      })),
    }));
  }, [currentSchedule, brackets]);

  return (
    <Wrapper>
      <PrintButton text='Print All Brackets' />

      {bracketsData.map((bracket) => (
        <>
          <BracketItem bracket={bracket} key={bracket.id} />
        </>
      ))}
    </Wrapper>
  );
};

export default AllBrackets;
