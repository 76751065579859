import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: ${toRem(8)};

  padding: ${toRem(24)} ${toRem(20)};
  border-bottom: ${toRem(1)} solid ${COLORS.grey800};
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${toRem(12)};
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(4)};
`;

export const ActionWrapper = styled.div`
  span {
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      opacity: 0.5;
    }
  }
`;
