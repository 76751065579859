import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-family: 'Boxed Regular';
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`;

export const Score = styled.span`
  font-family: 'Boxed Regular Bold';
  font-size: 18px;
  padding: 2px 10px;
  background-color: #1b1c19;
  border-radius: 6px;
  color: ${COLORS.white};
`;

export const PenaltiScore = styled.span`
  font-family: 'Boxed Regular Bold';
  font-size: 14px;
  padding: 0px 6px;
  background-color: #d3d4d0;
  border-radius: 4px;
  color: #1b1c19;
`;
