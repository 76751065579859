import React from 'react';

import { useQueryParams } from 'hooks/v3/util/useQueryParams';
import PrintSchedule from '../components/Schedule/PrintSchedule';

const ScheduleByCourt: React.FC = () => {
  const queryParams = useQueryParams();

  const court = queryParams.get('court') ?? undefined;

  return <PrintSchedule type='byCourt' selectedCourt={court} />;
};

export default ScheduleByCourt;
