import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  background-color: ${COLORS.grey900};
  padding: ${toRem(8)};
  border-radius: ${toRem(43)};
  border: 1px solid ${COLORS.grey800};
  display: flex;
  max-width: 268px;

  .avatar-wrapper {
    border: 1px solid ${COLORS.grey800};
  }

  .avatar-text {
    text-transform: capitalize;
    margin-right: 1rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    max-width: unset;
  }
`;
