import styled from 'styled-components';

import { Tabs as AntdTabs } from 'antd';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  border-radius: 24px;
  padding: 16px 30px 0px;
  background-color: ${COLORS.grey950};
`;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav {
    ::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: block !important;
    visibility: visible !important;
    background: ${COLORS.brandPrimary};
  }

  .ant-tabs-nav-list {
    gap: 24px;
  }

  .ant-tabs-nav {
    margin: 0;
    padding: 0;
  }

  .ant-tabs-tab {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 8px !important;
    background-color: unset !important;
  }

  .ant-tabs-tab:hover {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab-btn:active {
    color: ${COLORS.brandPrimary};
  }

  .ant-tabs-extra-content {
    button {
      border: none;
      background-color: unset;

      &:hover {
        border: none;
        background-color: unset;
        color: ${COLORS.brandPrimary};
      }
    }
  }
`;

export const TabPane = styled(AntdTabs.TabPane)`
  margin-top: 24px;
`;
