import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message as MessageSnackbar } from 'antd';
import { ImageFiles } from '@icon-park/react';

import { BodyM, BodyMBold, BodyS, BodySBold } from 'styles/v3/variables';
import { StatusChip } from 'components/v3/Cards/DocumentCard/styles';
import EditPhoto from 'components/base/EditPhoto/EditPhoto';

import ExamplePhoto from 'assets/imgs/example-photo.png';

import UploadService from 'services/upload/UploadService';
import UserServices from 'services/v3/User/UserService';

import { baseUserUpdateData } from 'redux/v3/baseUsers/actions';
import { BaseUser, DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import { ApplicationState } from 'redux/store';
import { RequestStatus } from 'models/Request/RequestModel';

import ConvertUtil from 'util/ConvertUtil';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import { usersFetchSuccess } from 'redux/v3/users/actions';
import OutlinedButtonWithIcon from '../Buttons/OutlinedButtonWithIcon';
import OutlinedButton from '../Buttons/OutlinedButton';
import FilledButton from '../Buttons/FilledButton';
import InputFile from '../Forms/InputFile/InputFile';
import TextIcon from '../Elements/Texts/TextIcon/TextIcon';
import UploadModal, { UploadModalProps } from './UploadModal';

import * as S from './styles';

export interface FooterProps {
  setStep: (e: number) => void;
  isLoading: boolean;
  sendToApproval?: () => any;
}

interface PhotoStatusFooterProps {
  setStep: (e: number) => void;
}

const UploadPhotoFooter: React.FC = () => (
  <>
    <BodyM $color='grey300'>Tips</BodyM>
    <TextIcon className='tip-text' color='grey300' icon='ImageFiles'>
      File size must be at maximum 2mb
    </TextIcon>
    <TextIcon className='tip-text' color='grey300' icon='Material'>
      Use a white background in the picture
    </TextIcon>
    <TextIcon className='tip-text' color='grey300' icon='UserPositioning'>
      Center you face in the picture
    </TextIcon>
  </>
);

const SendPhotoFooter: React.FC<FooterProps> = ({
  setStep,
  isLoading,
  sendToApproval,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButton
      isUpper
      onClick={() => setStep(0)}
      color='white-dark'
      disabled={isLoading}
    >
      Change Photo
    </OutlinedButton>
    <FilledButton
      className='button-approval'
      isUpper
      onClick={sendToApproval}
      disabled={isLoading}
    >
      Send to approval
    </FilledButton>
  </S.ButtonsWrapper>
);

const PhotoStatusFooter: React.FC<PhotoStatusFooterProps> = ({ setStep }) => (
  <S.ButtonsWrapper>
    <OutlinedButtonWithIcon
      isUpper
      color='white-dark'
      customIcon={<ImageFiles size={24} />}
      onClick={() => setStep(0)}
    >
      Change Photo
    </OutlinedButtonWithIcon>
  </S.ButtonsWrapper>
);

const PhotoStatusFooterInfo: React.FC<{ reason: string }> = ({ reason }) => (
  <>
    <BodyMBold>Why was my photo denied?</BodyMBold>
    <BodyS className='tip-text'>{reason}</BodyS>
  </>
);

export interface ProfilePhotoModalProps extends UploadModalProps {
  status: DocumentStatusLabel;
  user?: BaseUser;
}

const ProfilePhotoModal: React.FC<ProfilePhotoModalProps> = ({
  status,
  user,
  ...props
}) => {
  const [step, setStep] = React.useState<number>(0);
  const [fileList, setFileList] = React.useState<FileList | null>(null);
  const [editedImg, setEditedImg] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [newFile, setNewFile] = React.useState<File | null>(null);
  const [title, setTitle] = React.useState<string>('');

  const dispatch = useDispatch();
  const currentUserState = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  const currentUser = user || currentUserState

  const isBaseUser = React.useMemo(() => {
    if (!currentUser) return false
    return Array.isArray(currentUser?.types)
  }, [currentUser]);

  const sendToApproval = async () => {
    setIsLoading(true);
    try {
      if (newFile) {
        await UploadService.upload({
          file: newFile,
          prefix: 'user',
          public: true,
        }).then(async (response) => {
          if (!response.success) {
            MessageSnackbar.error({ content: response.message, duration: 5 });
            return;
          }
          const photo = response.data.id;
          const photoVerified = RequestStatus.PENDING;

          const idForPatch = `${currentUser.id}`

          if (isBaseUser) {
            const { data: userUpdated } = await UserServices.updateUser(`${currentUser.id}`, { photo }, isBaseUser)

            if (userUpdated.message) {
              throw new Error(userUpdated.message);
            } else {
              dispatch(baseUserUpdateData({ photo, photoVerified, id: idForPatch }));
              const { data: updatedProfile } = await UserServices.getProfiles()

              const updatedBaseUser = updatedProfile?.baseUsers?.find(u => u.id === currentUser.id)

              if (updatedBaseUser) {
                dispatch(setCurrentUser(updatedBaseUser));
                const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
                dispatch(usersFetchSuccess(newUsers));
              }

              setIsLoading(false);
              setEditedImg(photo);
              setStep(3);
            }
          } else {
            UserServices.updateUser(`${currentUser.id}`, { photo }, isBaseUser).then(async (res) => {
              if (res.success) {
                dispatch(baseUserUpdateData({ photo, photoVerified, id: idForPatch }));

                const { data: updatedProfile } = await UserServices.getProfiles()

                const myNewBaseUser = updatedProfile?.baseUsers?.find(u => u.userIds?.includes(`${currentUser.id}`))

                if (myNewBaseUser) {
                  dispatch(setCurrentUser(myNewBaseUser));
                  const newUsers = [...(updatedProfile.baseUsers || []), ...(updatedProfile.users || [])];
                  dispatch(usersFetchSuccess(newUsers));
                }

                setIsLoading(false);
                setStep(2);
              } else {
                setIsLoading(false);
                MessageSnackbar.error(`Error${res.data}`);
              }
            });
          }

        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  const upload = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        UploadService.upload(
          {
            file: fileList![0],
            prefix: 'user',
            public: true,
          },
          true
        ).then((response) => {
          if (response.success) {
            setEditedImg(response.data.id);
            setIsLoading(false);
          }
        }),
      ]);
    } catch (error) {
      setIsLoading(false);
      if (error instanceof Error) {
        MessageSnackbar.error({ content: error.message, duration: 5 });
      }
    }
  };

  const handleAfterEditPhoto = (file: File) => {

    const type = 'photo';
    UploadService.upload({
      file,
      prefix: 'user',
      public: type === 'photo',
    }).then((response) => {
      if (!response.success) {
        return;
      }
      setNewFile(file);
      setStep(2);
      const url = response.data.id;
      setEditedImg(url);

    });
  };

  const step0 = () => (
    <S.ModalContentUploadPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Example photo</BodyM>
        <img src={ExamplePhoto} alt='Example' />
      </S.ModalContentWrapperPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Choose your file</BodyM>
        <InputFile
          id='profilePhoto'
          text='Upload Photo'
          icon='AddPic'
          format='square'
          onChange={(e) => {
            setFileList(e.target.files);
            setEditedImg(e.target.value);
          }}
        />
      </S.ModalContentWrapperPhoto>
    </S.ModalContentUploadPhoto>
  );

  const step1 = () => (
    <S.EditPhotoWrapper>
      <EditPhoto
        newStyle
        imgURL={`${ConvertUtil.getMinioUrl(editedImg)}?t=rand`}
        afterEdit={handleAfterEditPhoto}
        changePhoto={() => setStep(0)}
        onClose={() => { }}
      />
    </S.EditPhotoWrapper>
  );

  const step2 = () => (
    <S.ChoseFileWrapper $type='circle'>
      <img src={ConvertUtil.getMinioUrl(editedImg)} alt='User' />
    </S.ChoseFileWrapper>
  );

  const step3 = () => (
    <>
      <S.StatusHeader>
        <BodyM>PHOTO STATUS</BodyM>
        <StatusChip $status={status}>
          <BodySBold
            $isUpper
            $color={
              status === DocumentStatusLabel.DECLINED ? 'white' : 'grey800'
            }
          >
            {status}
          </BodySBold>
        </StatusChip>
      </S.StatusHeader>
      <S.ChoseFileWrapper $status={status} $type='circle'>
        <img src={`${ConvertUtil.getMinioUrl(currentUser?.photo || '')}`} alt='User' />
      </S.ChoseFileWrapper>
    </>
  );

  const renderFooter = () => {
    switch (step) {
      case 0:
        return <UploadPhotoFooter />;
      case 2:
        return (
          <SendPhotoFooter
            setStep={setStep}
            isLoading={isLoading}
            sendToApproval={sendToApproval}
          />
        );
      case 3:
        return (
          status !== DocumentStatusLabel.PENDING &&
          status !== DocumentStatusLabel.VIEW && (
            <PhotoStatusFooter setStep={setStep} />
          )
        );
      default:
        break;
    }
    return true;
  };

  const renderFooterInfo = () => {
    if (step === 3) {
      return (
        status === DocumentStatusLabel.DECLINED &&
        currentUser?.feedbacks?.photo && (
          <PhotoStatusFooterInfo reason={currentUser.feedbacks.photo} />
        )
      );
    }
    return true;
  };

  React.useEffect(() => {
    if (fileList) {
      setStep(1);
      if (fileList[0]) {
        upload();
      }
    }
  }, [fileList]);

  React.useEffect(() => {
    switch (step) {
      case 0:
        setTitle('Upload Profile Photo');
        break;
      case 1:
        setTitle('Edit Profile Photo');
        break;
      default:
        setTitle('Your Profile Photo');
        break;
    }
  }, [step]);

  React.useEffect(() => {
    if (currentUser?.photo) {
      setStep(3);
    }
  }, []);

  return (
    <UploadModal
      {...props}
      footer={renderFooter()}
      footerInfo={renderFooterInfo()}
      onAfterClose={() => {
        return currentUser?.photo ? false : setStep(0);
      }}
      title={title}
    >
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
      {step === 3 && step3()}
    </UploadModal>
  );
};

export default ProfilePhotoModal;
