import React, { FC } from 'react';
import { User } from 'models/User/UserModel';
import ConvertUtil from 'util/ConvertUtil';

import Logo from 'components/v3/Elements/Logo/Logo';

import { BodyM, BodyS, COLORS, TitleH3 } from 'styles/v3/variables';

import { Dot } from '@icon-park/react';
import { UpgradeMembershipButton } from 'components/v3/Buttons/UpgradeMembershipButton/UpgradeMembershipButton';
import { useParams } from 'react-router-dom';
import { Container, UserImage, UserMemberType } from './styles';

interface ModalHeaderMemberInfoProps {
  member: User;
}

const ModalHeaderMemberInfo: FC<ModalHeaderMemberInfoProps> = ({ member }) => {
  const params: { rosterId: string } = useParams();

  const userYear = new Date(member.birthDate as string).getFullYear();

  return (
    <>
      <Container>
        <UserImage>
          <img src={ConvertUtil.getMinioUrl(member.photo)} alt='User Img' />
        </UserImage>
        <TitleH3>
          {member.firstName} {member.lastName}
        </TitleH3>
        <UserMemberType>
          <Logo
            width={20}
            height={20}
            minimal
            color={
              member.membership?.type === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
            }
          />
          <BodyM
            $color={member.membership?.type === 'OFFICIAL' ? 'grey50' : 'grey300'}
          >
            {member.membership?.type === 'OFFICIAL' ? 'Official Member' : 'Community Member'}
          </BodyM>
        </UserMemberType>
        <BodyS>
          {member.gender} <Dot fill={COLORS.grey800} /> {userYear}
        </BodyS>
        {member.membership?.type !== 'OFFICIAL' && (
          <UpgradeMembershipButton
            cancelUrl={`/v3/roster/${params.rosterId}/edit`}
            successUrl={`/v3/roster/${params.rosterId}/edit`}
            userIds={[member.id]}
            text='Upgrade Membership'
            type="outlined"
            buttonProps={{
              color: 'white-dark',
              isUpper: true,
              isBold: false
            }}
          />
        )}
      </Container>
    </>
  );
};

export default ModalHeaderMemberInfo;
