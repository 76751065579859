import React from 'react';
import * as S from './styles';

export interface BodyProps {
  children: React.ReactNode;
  noPaddingOnMobile?: boolean;
}

const Body: React.FC<BodyProps> = ({ children, noPaddingOnMobile }) => {
  return (
    <S.Wrapper>
      <S.Container noPadding={noPaddingOnMobile}>{children}</S.Container>
    </S.Wrapper>
  );
};

export default Body;
