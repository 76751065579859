import styled from 'styled-components';

import { BodyM, COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const MainWrapper = styled.div`
  padding: 62px 24px 62px 24px;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: 1;

  gap: 24px;

  h3 {
    margin-bottom: 0 !important;
  }

  ${BodyM} {
    max-width: 493px;
  }
`;

export const ActionsWrapper = styled.div`
  max-width: 300px;

  display: flex;
  flex-direction: column;

  flex: 1;

  gap: 10px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    max-width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const InformationSection = styled.section`
  display: flex;
  flex-direction: column;

  gap: 48px;
`;

export const PriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-height: 51px;
  gap: 8px;

  h2 {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 2px;
  }
`;

export const GeneralInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

export const MainInformationSection = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    gap: ${toRem(16)};
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${toRem(24)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
  }
`;

export const DatesText = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;

export const BodyUppercase = styled.div`
  p {
    text-transform: uppercase;
  }
`;

export const ShowMore = styled.button`
  display: flex;
  padding: 0;
  margin-top: 8px;
  background-color: transparent;
  border: none;
  color: ${COLORS.white};
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    color: ${COLORS.brandPrimary};
  }
`;
