import React, {
  memo,
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { TimePicker } from 'antd';
import { Moment } from 'moment';

import { IMatchDetails } from 'services/v3/Match/interfaces';
import { FutsalAction, SoccerPeriod } from 'services/v3/Match/enums';
import { IFutsalEventPayload } from 'services/v3/Match/payload-interfaces';

import { CardTypes } from './enums';
import {
  YELLOW_CARD_CLASS,
  DOUBLE_YELLOW_CARD_CLASS,
  RED_CARD_CLASS,
} from './config';

import { IFutsalSelection, RosterType } from '../interfaces';

import * as S from './styles';

interface IFutsalEventModalProps {
  visible: boolean;
  match: Maybe<IMatchDetails>;
  coreTime: Moment;
  soccer: SoccerPeriod;
  futsal: Maybe<IFutsalSelection>;
  onClose: () => void;
  onCreateFutsalEvent: (
    payload: IFutsalEventPayload,
    roster: Maybe<RosterType>
  ) => void;
}

export const FutsalEventModal: FC<IFutsalEventModalProps> = memo(
  ({
    visible,
    match,
    coreTime,
    soccer,
    futsal,
    onClose,
    onCreateFutsalEvent,
  }) => {
    const [time, setTime] = useState<Moment>(coreTime);

    const [refreshedTime, setRefreshedTime] = useState(false);

    const [selectedPlayer, setSelectedPlayer] = useState<Maybe<string>>(null);
    const [selectedCoach, setSelectedCoach] = useState<Maybe<string>>(null);

    const currentRoster = useMemo(() => {
      if (futsal?.roster === RosterType.Home) {
        return match?.homeTeam;
      }

      if (futsal?.roster === RosterType.Away) {
        return match?.awayTeam;
      }

      return null;
    }, [match, futsal]);

    const attendedPlayers = useMemo(() => {
      const players = currentRoster?.players?.filter((player) => player.attend);

      return players;
    }, [currentRoster]);

    const attendedCoaches = useMemo(() => {
      const coaches = currentRoster?.coaches?.filter((coach) => coach.attend);

      return coaches;
    }, [currentRoster]);

    const getClassNameByCardType = useCallback(
      (userId: string) => {
        const cards = {
          [CardTypes.Red]: '',
          [CardTypes.Yellow]: '',
          [CardTypes.DoubleYellow]: '',
        };

        match?.matchEvents.forEach((event) => {
          if (event.userId !== userId) {
            return;
          }

          if (event.action === FutsalAction.YELLOW_CARD) {
            if (cards[CardTypes.Yellow].length) {
              cards[CardTypes.Yellow] = '';
              cards[CardTypes.DoubleYellow] = DOUBLE_YELLOW_CARD_CLASS;
            } else {
              cards[CardTypes.Yellow] = YELLOW_CARD_CLASS;
            }
          }

          if (event.action === FutsalAction.RED_CARD) {
            cards[CardTypes.Red] = RED_CARD_CLASS;
          }
        });

        return (
          cards[CardTypes.Red] ||
          cards[CardTypes.Yellow] ||
          cards[CardTypes.DoubleYellow] ||
          ''
        );
      },
      [match]
    );

    const handleSelectPlayer = useCallback(
      (userId: string) => () => {
        setSelectedCoach(null);
        setSelectedPlayer((prevState) => {
          if (prevState === userId) {
            return null;
          }

          return userId;
        });
      },
      []
    );

    const handleSelectCoach = useCallback(
      (userId: string) => () => {
        setSelectedPlayer(null);
        setSelectedCoach((prevState) => {
          if (prevState === userId) {
            return null;
          }

          return userId;
        });
      },
      []
    );

    const handleChangeTime = useCallback((selectedTime: Maybe<Moment>) => {
      if (selectedTime) {
        setTime(selectedTime);
      }
    }, []);

    const handleClose = useCallback(() => {
      onClose();
      setSelectedPlayer(null);
      setSelectedCoach(null);
      setRefreshedTime(false);
    }, [onClose]);

    const handleConfirmFutsalEvent = useCallback(() => {
      if (
        (selectedPlayer || selectedCoach) &&
        currentRoster &&
        match &&
        futsal &&
        soccer
      ) {
        onCreateFutsalEvent(
          {
            userId: selectedPlayer || selectedCoach || '',
            time: time.toDate().toLocaleTimeString('it-IT'),
            rosterId: currentRoster?.id,
            matchId: match?.id,
            period: soccer,
            action: futsal?.action,
          },
          futsal.roster
        );

        setRefreshedTime(false);
        setSelectedPlayer(null);
        setSelectedCoach(null);
        onClose();
      }
    }, [
      match,
      futsal,
      soccer,
      time,
      selectedPlayer,
      selectedCoach,
      currentRoster,
      onClose,
    ]);

    useEffect(() => {
      if (visible && !refreshedTime) {
        setTime(coreTime);
        setRefreshedTime(true);
      }
    }, [visible, refreshedTime, coreTime]);

    return (
      <S.StyledModal
        centered
        visible={visible}
        footer={false}
        closable={false}
        onCancel={handleClose}
      >
        <S.Header>
          <S.FutsalTitle>{futsal?.text}</S.FutsalTitle>
        </S.Header>
        <S.Container>
          <S.TimePickerWrapper>
            <TimePicker
              value={time}
              onChange={handleChangeTime}
              format='mm:ss'
              placeholder='00:00'
              popupClassName='scoresheet-time-picker'
              inputReadOnly
            />
          </S.TimePickerWrapper>
          {!!attendedPlayers?.length && (
            <S.PlayerNumberList>
              <S.Label>Select the Player:</S.Label>
              {attendedPlayers.map((player) => (
                <S.PlayerNumberItem
                  key={player.id}
                  className={getClassNameByCardType(player.id)}
                  $selected={player.id === selectedPlayer}
                  onClick={handleSelectPlayer(player.id)}
                >
                  {player.number}
                </S.PlayerNumberItem>
              ))}
            </S.PlayerNumberList>
          )}
          {(futsal?.action === FutsalAction.RED_CARD ||
            futsal?.action === FutsalAction.YELLOW_CARD) &&
            !!attendedCoaches?.length && (
              <S.CoachList>
                <S.Label>Card to Coache:</S.Label>
                {attendedCoaches.map((coach) => (
                  <S.CoachItem
                    key={coach.id}
                    className={getClassNameByCardType(coach.id)}
                    $selected={coach.id === selectedCoach}
                    onClick={handleSelectCoach(coach.id)}
                  >{`${coach.firstName} ${coach.lastName}`}</S.CoachItem>
                ))}
              </S.CoachList>
            )}
          <S.ActionGroup>
            <S.CancelButton onClick={handleClose}>Cancel</S.CancelButton>
            <S.ConfirmButton
              $disabled={!selectedPlayer && !selectedCoach}
              onClick={handleConfirmFutsalEvent}
            >
              Confirm
            </S.ConfirmButton>
          </S.ActionGroup>
        </S.Container>
      </S.StyledModal>
    );
  }
);

FutsalEventModal.displayName = 'FutsalEventModal';
