import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(12)};
`;

export const DivisionsInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  gap: ${toRem(12)};
`;

export const Flex = styled.div`
  flex: 1;

  overflow: hidden;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
