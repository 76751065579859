import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import { GenderCategory } from 'admin/models/Enums';
import RosterService from 'services/v3/Rosters/RosterService';

import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useUpdateRosterName } from 'hooks/v3/rosters/useUpdateRosterName/useUpdateRosterName';
import { useUpdateDivisionLockStatus } from 'hooks/v3/rosters/useUpdateDivisionLockStatus/useUpdateDivisionLockStatus';

import { notification } from 'components/v3/Notification/notification';
import RightDrawer from 'components/v3/RightDrawer';
import { useUpgradeMembersNotification } from 'components/v3/Buttons/UpgradeMembershipButton/hook/useUpgradeMembersNotification';

import { HeaderRosterEdit, ListOfUser } from './components';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';
import ModalBodyMemberInfo from './components/Modal/ModalBodyMemberInfo';
import { UserWithJersey } from './types';
import { AGE_RULES_VALUES } from './configs';

const RosterEditPage: FC = () => {
  const params: { rosterId: string } = useParams();

  const queryClient = useQueryClient();
  const history = useHistory();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [savedUsers, setSavedUsers] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | false>(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState<UserType>();

  const { currentUser: { accountId } } = useProfile()
  const { data: rosterDetailed, refetch: fetchRosterDetails } = useGetRosterByIdDetailed(params.rosterId);
  const { data: members } = useGetClubMembersPool(rosterDetailed?.club?.id);
  const { mutateAsync: updateRosterName } = useUpdateRosterName();
  const { mutateAsync: updateDivisionLockStatus } = useUpdateDivisionLockStatus();

  useUpgradeMembersNotification([
    ['get-club-members-pool', rosterDetailed?.club?.id, accountId]
  ]);

  const handleGoBack = async () => {
    const id = rosterDetailed?.club?.id ?? '';
    history.push(`/v3/clubs/${id}/dashboard`);
  };

  const handleOpenModal = (headerModal: UserType) => {
    setModalTitle(
      headerModal === UserType.COACH
        ? `Add ${headerModal}es`
        : `Add ${headerModal}s`
    );

    setModalType(headerModal);
    setIsOpen(true);
  };

  const handleOpenUserInfoModal = (user: User) => {
    setIsUserInfoOpen(true);
    setUserSelected(user);
  };

  const handleCloseModal = () => {
    setModalTitle('');
    setIsOpen(false);
    setUserSelected(false);
  };

  const handleSaveNewUser = async () => {
    const failedUsers : string[] = []

    if (selectedUsers.length > 0) {
      for await (const userId of selectedUsers) {
        try {
          await RosterService.addPlayer(params.rosterId, userId);
          setSavedUsers((oldState) => [...oldState, userId]);
          setSelectedUsers((oldState) => [
            ...oldState.filter((curId) => curId !== userId),
          ]);
          queryClient.invalidateQueries(['/get-roster-detailed-id', params.rosterId])
        } catch (e) {
          const error = e as any
          notification.error({
            message: error?.message || 'Error adding roster',
          });
          failedUsers.push(userId)
        }
      }

      if(failedUsers.length){
        setSelectedUsers((oldState) => [
          ...oldState.filter((userId) => !failedUsers.includes(userId)),
        ]);
      }

      handleCloseModal();
    }
  };

  const handleRemoveUser = async () => {
    if (userSelected) {
      try {
        await RosterService.removePlayer(params.rosterId, userSelected.id);
        setSavedUsers((oldState) => [
          ...oldState.filter((userId) => userId !== userSelected.id),
        ]);
        setSelectedUsers((oldState) => [
          ...oldState.filter((userId) => userId !== userSelected.id),
        ]);
        queryClient.invalidateQueries(['/get-roster-detailed-id', params.rosterId])
      } catch (e) {
        console.log(e);
      }

      handleCloseModal();
    }
  };

  const handleUpdateRosterName = useCallback(
    (rosterId: string, name: string) => {
      updateRosterName({ rosterId, name }).then(() => {
        fetchRosterDetails();
      });
    },
    [updateRosterName, fetchRosterDetails]
  );

  const handleUpdateDivisionLockStatus = useCallback(() => {
    const eventId = rosterDetailed?.event?.id;
    const divisionId = rosterDetailed?.ageDivision?.id;
    const locked = rosterDetailed?.ageDivision?.locked;

    if (eventId && divisionId) {
      updateDivisionLockStatus({
        eventId,
        divisionId,
        locked: !!locked
      }).then(() => {
        fetchRosterDetails();
      })
    }
  }, [rosterDetailed, updateDivisionLockStatus]);

  const coachesData = useMemo(() => {
    if (!members?.length) return [];

    return members.filter((member) => member.type === UserType.COACH);
  }, [handleSaveNewUser, handleCloseModal]);

  const coachesSelected = useMemo(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === UserType.COACH && savedUsers.includes(member.id)
    );
  }, [handleSaveNewUser, handleCloseModal]);

  const playersData = useMemo(() => {
    if (rosterDetailed?.ageDivision && members) {
      const { gender } = rosterDetailed.ageDivision;
      const ageOffset = AGE_RULES_VALUES[rosterDetailed.ageDivision.rule];

      const filteredPlayers = members.filter((member) => {
        const allowedGirlsPlaysWithBoys = rosterDetailed.ageDivision?.allowGirlBoysOnAnotherDivision;
        const birthYear = new Date(member.birthDate as string).getFullYear();
        const ageDivisionRule = Number(rosterDetailed.ageDivision?.years.join('/') ?? 0);
        const ageAllowed = ageDivisionRule + ageOffset;

        const allowedByAgeRule = ageDivisionRule <= birthYear && birthYear <= ageAllowed;
        const allowedGender = gender === GenderCategory.COED || (gender === member.gender || 
          (gender === GenderCategory.MALE && allowedGirlsPlaysWithBoys));

        return member.type === UserType.PLAYER && allowedGender && allowedByAgeRule;
      });

      return filteredPlayers;
    }

    return [];
  }, [members, rosterDetailed, handleSaveNewUser, handleCloseModal]);

  const playersSelected = useMemo < UserWithJersey[]>(() => {
    if (!members?.length) return [];

    return members.filter(
      (member) => member.type === UserType.PLAYER && savedUsers.includes(member.id)
    ).map( member => ({
      ...member,
      jersey: rosterDetailed?.players.find(p => p.id === member.id)?.number,
    }));
  }, [handleSaveNewUser, handleCloseModal]);

  useEffect(() => {
    const selectedPlayers = rosterDetailed?.players.map((player) => player.id);
    const selectedCoaches = rosterDetailed?.coaches.map((player) => player.id);

    if (selectedPlayers && selectedPlayers?.length > 0) {
      setSavedUsers((oldSelected) => [...oldSelected, ...selectedPlayers]);
    }

    if (selectedCoaches && selectedCoaches?.length > 0) {
      setSavedUsers((oldSelected) => [...oldSelected, ...selectedCoaches]);
    }
  }, [members]);

  return (
    <>
      {rosterDetailed?.ageDivision && (
        <HeaderRosterEdit
          rosterId={params.rosterId || null}
          name={rosterDetailed?.name ?? ''}
          eventName={rosterDetailed?.event?.name ?? ''}
          eventLogo={rosterDetailed?.event?.logo ?? ''}
          division={rosterDetailed.ageDivision}
          locksIn={rosterDetailed?.event?.data.generalRules?.rosterLockDate}
          onGoBack={handleGoBack}
          onUpdateRosterName={handleUpdateRosterName}
          onUpdateDivisionLockStatus={handleUpdateDivisionLockStatus}
        />
      )}
      {rosterDetailed?.coaches && (
        <ListOfUser
          typeOfUser='Coaches'
          coachesList={coachesSelected}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
        />
      )}
      {rosterDetailed?.players && (
        <ListOfUser
          typeOfUser='Players'
          rosterId={params.rosterId || null}
          playersList={playersSelected}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
        />
      )}
      {isOpen && modalType && (
        <RightDrawer
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          headerComponent={<ModalHeader title={modalTitle} />}
        >
          <ModalBody
            userType={modalType}
            members={modalType === 'COACH' ? coachesData : playersData}
            selectedUsers={selectedUsers}
            savedUsers={savedUsers}
            handleSelectedUsers={setSelectedUsers}
            handleUpdateUsers={handleSaveNewUser}
          />
        </RightDrawer>
      )}
      {isUserInfoOpen && userSelected && (
        <RightDrawer
          isOpen={isUserInfoOpen}
          handleCloseModal={handleCloseModal}
        >
          <ModalBodyMemberInfo
            member={userSelected}
            rosterDetailed={rosterDetailed}
            handleRemoveUser={handleRemoveUser}
          />
        </RightDrawer>
      )}
    </>
  );
};

export default RosterEditPage;
