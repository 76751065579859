import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

interface CardContainerProps {
  $isSuccess: boolean;
}

export const CardContainer =
  styled.div <
  CardContainerProps >
  `
  display: flex;
  padding: 16px 30px 30px 30px;
  gap: 32px;
  border-radius: 32px;

  border: 4px solid
    ${(props) =>
      props.$isSuccess ? COLORS.brandPrimary : COLORS.supportError};

  > img {
    align-self: baseline;
    margin-top: -8px; /* Figma image has padding white space */
  }
  > div {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    h3 {
      margin: 0;
      color: ${(props) =>
        props.$isSuccess ? COLORS.brandPrimary : COLORS.supportError};
    }

    button {
      color: ${COLORS.grey900};
      max-width: fit-content;
      padding: 8px 24px;
      background-color: ${(props) =>
        props.$isSuccess ? COLORS.brandPrimary : COLORS.white};

      font-family: 'Boxed Regular';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      text-transform: uppercase;

      svg path {
        fill: ${COLORS.grey900};
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;

    gap: ${toRem(16)};

    > div {
      button {
        width: 100%;
        max-width: none;
      }
    }
  }
`;

export const ImageWrapperWithTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row !important;

  gap: ${toRem(10)};

  h3 {
    word-break: break-word;
  }
`;

export const Container = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    p {
      text-align: center;
    }
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;
