import React, { FC } from 'react';

import InputText from 'components/v3/Forms/InputText/InputText';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { TooltipAppears } from 'components/TooltipAppears/TooltipAppears';

import { BodyLBold, BodyM, BodyMBold } from 'styles/v3/variables';

import { coachesOptions } from '../../constants';

import * as MainStyles from '../../styles';

interface ICoachesRulesProps {
  allowCoachTeam: boolean;
  allowCoachClub: boolean;
  minimumCoachesNumber?: number;
  maximumCoachesNumber?: number;
  setLabelToCoachesPermission: OptionsType | undefined;
  isPublishedEvent: boolean;
  setAllowCoachClub: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowCoachTeam: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CoachesRules: FC<ICoachesRulesProps> = ({
  allowCoachTeam,
  allowCoachClub,
  maximumCoachesNumber,
  minimumCoachesNumber,
  setLabelToCoachesPermission,
  isPublishedEvent = false,
  setAllowCoachClub,
  setAllowCoachTeam,
}) => {
  return (
    <fieldset>
      <legend>
        <BodyLBold $color='white'>Coaches Rules</BodyLBold>
      </legend>
      <TooltipAppears
        enabled={isPublishedEvent}
        placement='top'
        fullWidth
        title='This field cannot be changed when the event is published'
      >
        <InputText
          containerClassName='general-rules-input'
          id='minCoachesRules'
          placeholder='Min Number of Coaches'
          label='Min Number of Coaches'
          defaultValue={minimumCoachesNumber}
          type='number'
          min={0}
        />
      </TooltipAppears>
      <TooltipAppears
        enabled={isPublishedEvent}
        placement='top'
        fullWidth
        offsetBottom
        title='This field cannot be changed when the event is published'
      >
        <InputText
          containerClassName='general-rules-input'
          id='maxCoachesRules'
          placeholder='Max Number of Coaches'
          label='Max Number of Coaches'
          defaultValue={maximumCoachesNumber}
          type='number'
          min={0}
        />
      </TooltipAppears>
      <BodyMBold $color='grey200'>Permissions</BodyMBold>
      <TooltipAppears
        enabled={isPublishedEvent}
        placement='top'
        fullWidth
        title='This field cannot be changed when the event is published'
      >
        <MainStyles.ElementCheckboxWrapper>
          <InputCheckbox
            id='allowCoachesOnDifferentTeams'
            name='allowCoachesOnDifferentTeams'
            onChange={() => setAllowCoachTeam(!allowCoachTeam)}
            checked={allowCoachTeam}
          />
          <BodyM>
            Allow coaches to coach in{' '}
            <BodyMBold $color='brandPrimary'>different Teams</BodyMBold>
          </BodyM>
        </MainStyles.ElementCheckboxWrapper>
      </TooltipAppears>
      {allowCoachTeam && (
        <>
          <TooltipAppears
            enabled={isPublishedEvent}
            placement='top'
            fullWidth
            offsetBottom
            title='This field cannot be changed when the event is published'
          >
            <MainStyles.ElementCheckboxWrapper>
              <InputCheckbox
                id='allowCoachesOnDifferentClubs'
                name='allowCoachesOnDifferentClubs'
                onChange={() => setAllowCoachClub(!allowCoachClub)}
                checked={allowCoachClub}
              />
              <BodyM>
                Allow coaches to coach in{' '}
                <BodyMBold $color='brandPrimary'>different Clubs</BodyMBold>
              </BodyM>
            </MainStyles.ElementCheckboxWrapper>
          </TooltipAppears>
          <TooltipAppears
            enabled={isPublishedEvent}
            placement='top'
            fullWidth
            title='This field cannot be changed when the event is published'
          >
            <Select
              name='coachAgeDivisionPermission'
              placeholder='Select Age Division Permission'
              defaultValue={setLabelToCoachesPermission}
              options={coachesOptions}
            />
          </TooltipAppears>
        </>
      )}
    </fieldset>
  );
};
