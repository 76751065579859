import { useState, useEffect, useCallback } from 'react';
import { BREAKPOINTS as DEFAULT_BREAKPOINTS } from 'styles/v3/constants';

type ScreenTypeIndicators = {
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  isLargeDesktop: boolean,
};

type Breakpoints = {
  mobile?: string,
  tablet?: string,
  desktop?: string,
  largeDesktop?: string,
};

function useScreenType(
  customBreakpoints: Partial<Breakpoints> = {}
): ScreenTypeIndicators {
  const breakpoints = { ...DEFAULT_BREAKPOINTS, ...customBreakpoints };

  const parseBreakpoint = (value: string): number =>
    parseInt(value.replace('px', ''), 10);

  const calculateScreenType = (): ScreenTypeIndicators => {
    const width = window.innerWidth;
    return {
      isMobile: width < parseBreakpoint(breakpoints.mobile),
      isTablet:
        width >= parseBreakpoint(breakpoints.tablet) &&
        width < parseBreakpoint(breakpoints.desktop),
      isDesktop:
        width >= parseBreakpoint(breakpoints.desktop) &&
        width < parseBreakpoint(breakpoints.largeDesktop),
      isLargeDesktop: width >= parseBreakpoint(breakpoints.largeDesktop),
    };
  };

  const [screenType, setScreenType] = useState<ScreenTypeIndicators>(
    calculateScreenType()
  );

  const handleResize = useCallback(() => {
    setScreenType(calculateScreenType());
  }, [breakpoints]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return screenType;
}

export default useScreenType;
