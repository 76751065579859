/* eslint-disable no-param-reassign */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Left, More } from '@icon-park/react';
import { Divider } from 'antd';

import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';

import { BodyL, BodyM, COLORS } from 'styles/v3/variables';
import IconButton from 'components/v3/Buttons/IconButton';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import RosterService, { RosterByEventId } from 'services/v3/Rosters/RosterService';
import RightDrawer from 'components/v3/RightDrawer';
import { CircleInformation } from '../../../Home/components/CirclesNested';
import { DivisionTeamInformation, RosterInformation, Modal } from './components';

import { TeamsContainer, TeamsHeader, TeamsTitleBackBtnContainer, TeamsContent, ChooseApplications, ApplicationAllSelect } from './styles';


export const Teams: FunctionComponent = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const [openAllOptions, setOpenAll] = useState(false);
  const [divisions, setDivisions] = useState<NewAgeDivision[]>();
  const [divisionsCount, setDivisionsCount] = useState<{ [x: string]: number; }>();
  const [selectedDivision, setSelectedDivision] = useState<NewAgeDivision>();
  const [allRosters, setAllRosters] = useState<RosterByEventId[]>();
  const [selectedRoster, setSelectedRoster] = useState<RosterByEventId>();
  const [isOpenModalRoster, setOpenModalRoster] = useState<boolean>(false);

  const { data, refetch } = useGetEventRosters({ eventId });

  useEffect(() => {
    if (data) {
      const updateDivsions: NewAgeDivision[] = data
        ?.map((division) => division.ageDivision)
        .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i) as NewAgeDivision[];

      const divisionsMap = data
        ?.map((division) => division.ageDivision.id) as string[];

      const counts: { [x: string]: number; } = {};
      divisionsMap.forEach((x) => {
        counts[x] = (counts[x] || 0) + 1;
      });

      setDivisions(updateDivsions);
      setDivisionsCount(counts);
      setAllRosters(data);
    }
  }, [data, refetch]);

  const handleSelectDivision = (idDivision: string) => {
    const divisionFind = divisions?.find((division) => division.id === idDivision);
    setSelectedDivision(divisionFind);
  }

  const handleUpdateAllDivision = async (divisionStatus: boolean) => {
    divisions?.forEach(async (division) => {
      await B2bEventService.changeDivisionSetStatus(eventId, division.id, divisionStatus)
      refetch();
      setOpenAll(!openAllOptions)
    })
  }

  const handleUpdateDivision = async (eventId: string, divisionId: string) => {
    if (divisionId) {
      await B2bEventService.changeDivisionStatus({
        eventId,
        divisionId,
      });
      refetch();
    }
  }

  const handleUpdateAllRosters = async (rosterStatus: boolean) => {
    if (allRosters && selectedDivision) {
      allRosters
        .filter((division) => division.ageDivision.id === selectedDivision.id)
        .map(async (roster) => {
          await RosterService.changeRosterSetStatus(roster.id, rosterStatus)
          refetch();
          setOpenAll(!openAllOptions)
        })
    }
  }

  const handleUpdateRoster = async (rosterId: string) => {
    if (selectedDivision) {
      await RosterService.changeSubmitStatus(rosterId)

      refetch();
    }
  }

  const findPartially = (allLockeds = 0, allUnlockeds = 0): boolean => {
    if (allLockeds > 0) {
      if (allLockeds > 0 && allUnlockeds === allLockeds) {
        return false
      }
      return true
    }

    return false
  }

  const handleCloseRosterModal = () => {
    setOpenModalRoster(false);
    setSelectedRoster(undefined);
  }

  const handleSelectRosterToOpenModal = (rosterSelected: RosterByEventId) => {
    setSelectedRoster(rosterSelected);
    setOpenModalRoster(true);
  }

  const viewAllDivisions = () => {
    return <>
      <TeamsHeader>
        <BodyL>Your Divisions</BodyL>

        <IconButton
          icon={<More size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey800 }}
          onClick={() => setOpenAll(!openAllOptions)}
        />
        {openAllOptions && (
          <ChooseApplications>
            <ApplicationAllSelect onClick={() => handleUpdateAllDivision(false)}>
              <BodyM>Open all applications</BodyM>
            </ApplicationAllSelect>
            <ApplicationAllSelect onClick={() => handleUpdateAllDivision(true)}>
              <BodyM>Close all applications</BodyM>
            </ApplicationAllSelect>
          </ChooseApplications>
        )}
      </TeamsHeader>
      <Divider style={{ margin: 0 }} />
      <TeamsContent>
        {(divisions && divisionsCount) &&
          divisions.map((division) => {
            const numberOfTeams = divisionsCount[division.id];
            const allUnlockeds = allRosters?.filter(roster => roster.ageDivision.id === division.id && roster.status === 'PENDING').length as number;
            const allLockeds = allRosters?.filter(roster => roster.ageDivision.id === division.id && roster.status === 'SUBMITTED').length as number;

            return (
              <DivisionTeamInformation
                key={`key_for_division_team_${division.id}`}
                id={division.id}
                handleSelectedDivision={handleSelectDivision}
                handleUpgradeDivisionStatus={() => handleUpdateDivision(eventId, division.id)}
                title={division.name}
                isLocked={division.lockDivision}
                partiallyLocked={findPartially(allLockeds, allUnlockeds)}
                gender={division.gender}
                years={division.years}
                allowOtherGender={
                  division.allowGirlBoysOnAnotherDivision
                }
                teams={numberOfTeams || 0}
                targetTeams={division.targetNumberOfTeams}
                bgColor={division.color}
              />
            );
          })}
      </TeamsContent>
    </>
  }

  const viewDivisionSelected = () => {
    if (selectedDivision && allRosters) {
      return <>
        <TeamsHeader>
          <TeamsTitleBackBtnContainer>
            <IconButton
              icon={<Left size={24} fill={COLORS.grey50} />}
              style={{ backgroundColor: COLORS.grey800 }}
              onClick={() => setSelectedDivision(undefined)}
            />
            <BodyL>{selectedDivision.name}</BodyL>
          </TeamsTitleBackBtnContainer>

          <IconButton
            icon={<More size={24} fill={COLORS.grey50} />}
            style={{ backgroundColor: COLORS.grey800 }}
            onClick={() => setOpenAll(!openAllOptions)}
          />

          {openAllOptions && (
            <ChooseApplications>
              <ApplicationAllSelect onClick={() => handleUpdateAllRosters(false)}>
                <BodyM>Open all applications</BodyM>
              </ApplicationAllSelect>
              <ApplicationAllSelect onClick={() => handleUpdateAllRosters(true)}>
                <BodyM>Close all applications</BodyM>
              </ApplicationAllSelect>
            </ChooseApplications>
          )}
        </TeamsHeader>

        <Divider style={{ margin: 0 }} />

        <TeamsContent>
          {
            allRosters
              .filter((division) => division.ageDivision.id === selectedDivision.id)
              .map((roster) => {
                const coachesCircles: CircleInformation[] = roster.coaches.map((coach) => {
                  return {
                    id: coach.id,
                    logo: coach.photo
                  }
                });

                const playersCircles: CircleInformation[] = roster.players.map((player) => {
                  return {
                    id: player.id,
                    logo: player.photo
                  }
                });

                const statusRoster = roster.status === 'SUBMITTED';

                return <RosterInformation
                  key={`key_for_roster_${roster.id}`}
                  handleUpgradeRosterStatus={() => handleUpdateRoster(roster.id)}
                  isSubmitted={statusRoster}
                  isDivisionLocked={roster.ageDivision.lockDivision as boolean}
                  logoClub={roster.club.logo}
                  nameClub={roster.club.name}
                  coachesCircles={coachesCircles}
                  playersCircles={playersCircles}
                  handleSelectedRoster={() => handleSelectRosterToOpenModal(roster)}
                />
              })
          }
        </TeamsContent>
      </>
    }

    return <></>
  }

  return (
    <TeamsContainer>
      {
        selectedDivision ? viewDivisionSelected() : viewAllDivisions()
      }

      <RightDrawer
        handleCloseModal={handleCloseRosterModal}
        isOpen={isOpenModalRoster && !!selectedRoster}
      >
        {selectedRoster && isOpenModalRoster && allRosters &&(
          <Modal
            roster={allRosters.find((roster) => roster.id === selectedRoster.id) as RosterByEventId}
            handleUpgradeRosterStatus={() => handleUpdateRoster(selectedRoster.id)}
          />
        )}
      </RightDrawer>
    </TeamsContainer>
  );
};
