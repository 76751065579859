import { useQuery } from '@tanstack/react-query';
import MatchService from 'services/v3/Match/MatchService';

export function useGetMatchesByBracketId(bracketId?: string) {
  const { data, isLoading, error } = useQuery(
    ['get-matches-by-bracket', bracketId],
    async () => await MatchService.getMatchesByBracket(bracketId)
  );
  return {
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
