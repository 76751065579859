import React, { memo, FC, useMemo } from 'react';

import { IMatchResult } from 'services/v3/Match/interfaces';

import * as S from './styles';

interface IGameScoreProps {
  result: Maybe<IMatchResult>;
}

export const GameScore: FC<IGameScoreProps> = memo(({ result }) => {
  const {
    homeTeamScore = 0,
    awayTeamScore = 0,
    homeTeamPenaltiShootout = 0,
    awayTeamPenaltiShootout = 0,
  } = result ?? {};

  const hasPenaltyScores = useMemo(() => {
    return homeTeamPenaltiShootout > 0 || awayTeamPenaltiShootout > 0;
  }, [homeTeamPenaltiShootout, awayTeamPenaltiShootout]);

  return (
    <S.Wrapper>
      <S.Row>
        <S.Score>{homeTeamScore}</S.Score>x<S.Score>{awayTeamScore}</S.Score>
      </S.Row>
      {hasPenaltyScores && (
        <S.Row>
          <S.PenaltiScore>{homeTeamPenaltiShootout}</S.PenaltiScore>x
          <S.PenaltiScore>{awayTeamPenaltiShootout}</S.PenaltiScore>
        </S.Row>
      )}
    </S.Wrapper>
  );
});

GameScore.displayName = 'GameScore';
