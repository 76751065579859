import moment from 'moment';

import { IMatchEventList } from 'services/v3/Match/interfaces';

import { ITableMatches } from '../interfaces';

export const getTableContentData = (
  matches: IMatchEventList[]
): ITableMatches[] => {
  return (
    matches?.map((match, index) => ({
      key: index + 1,
      matchId: match.matchId,
      homeTeam: match?.homeTeam?.name ?? 'TBA',
      result: match?.result ?? null,
      awayTeam: match?.awayTeam?.name ?? 'TBA',
      date: match?.date ? moment(match?.date).format('MM-DD-YYYY') : '-',
      time: match?.date ? moment(match?.date).format('HH:mm A') : '-',
      venueName: match?.venue?.venueName ?? '-',
      subVenue: match?.subVenue ?? '-',
      status: match?.status ?? '',
      report: match?.report ?? '',
    })) || []
  );
};
