import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { accountReducer } from './account/reducers';
import { AccountState } from './account/types';
import { clubReducer } from './club/reducers';
import { ClubState } from './club/types';
import { dsConnectionReducer } from './dsConnection/reducers';
import { DSConnectionState } from './dsConnection/types';
import { matchDetailsReducer } from './matchDetails/reducers';
import { MatchDetailsState } from './matchDetails/types';
import { memberpoolReducer } from './memberpool/reducers';
import { MemberpoolState } from './memberpool/types';
import { memberpoolRosterReducer } from './memberpoolRoster/reducers';
import { MemberpoolRosterState } from './memberpoolRoster/types';
import { membershipsReducer } from './memberships/reducers';
import { MembershipsState } from './memberships/types';
import { newUserReducer } from './newUser/reducers';
import { NewUserState } from './newUser/types';
import { rosterReducer } from './roster/reducers';
import { RosterState } from './roster/types';
import { userReducer } from './user/reducers';
import { UserState } from './user/types';
import { B2bSetupEventState } from './v3/b2bSetupEvent/types';
import { b2bSetupEventReducer } from './v3/b2bSetupEvent/reducers';
import { B2bDraftEventState } from './v3/b2bDraftEvent/types';
import { b2bDraftEventReducer } from './v3/b2bDraftEvent/reducers';
import { BaseUsersState } from './v3/baseUsers/types';
import { baseUsersReducer } from './v3/baseUsers/reducers';
import { SideModalState } from './sideModal/types';
import { sideModalReducer } from './sideModal/reducers';
import { UsersState } from './v3/users/types';
import { usersReducer } from './v3/users/reducers';
import { CurrentUserState } from './v3/currentUser/types';
import { currentUserReducer } from './v3/currentUser/reducers';
import { EventsState } from './v3/events/types';
import { eventsReducer } from './v3/events/reducers';
import { SignupEventState } from './v3/signupEvent/types';
import { signupEventReducer } from './v3/signupEvent/reducers';
import { ClubDashboardState } from './v3/clubDashboard/types';
import { ClubDashboardReducer } from './v3/clubDashboard/reducer';
import { profileModalReducer } from './v3/profileModal/reducer';
import { ProfileModalState } from './v3/profileModal/types';

const newUserReducerPersisted = persistReducer<NewUserState>(
  {
    key: 'newUser',
    storage,
  },
  newUserReducer
);

const matchDetailsPersisted = persistReducer<MatchDetailsState>(
  {
    key: 'matchDetails',
    storage,
  },
  matchDetailsReducer
);

const dsConnectionPersisted = persistReducer<DSConnectionState>(
  {
    key: 'dsConnection',
    storage,
  },
  dsConnectionReducer
);

const b2bDraftEventReducerPersisted = persistReducer<B2bDraftEventState>(
  {
    key: 'b2bDraftEvent',
    storage,
  },
  b2bDraftEventReducer
);

const b2bSetupEventReducerPersisted = persistReducer<B2bSetupEventState>(
  {
    key: 'b2bSetupEvent',
    storage,
  },
  b2bSetupEventReducer
);

const baseUsersReducerPersisted = persistReducer<BaseUsersState>(
  {
    key: 'baseUsers',
    storage,
  },
  baseUsersReducer
);

const sideModalReducerPersisted = persistReducer<SideModalState>(
  {
    key: 'sideModal',
    storage,
  },
  sideModalReducer
);

const usersReducerPersisted = persistReducer<UsersState>(
  {
    key: 'users',
    storage,
  },
  usersReducer
);

const currentUserReducerPersisted = persistReducer<CurrentUserState>(
  {
    key: 'currentUser',
    storage,
  },
  currentUserReducer
);

const signupEventReducerPersisted = persistReducer<SignupEventState>(
  {
    key: 'signupEvent',
    storage,
  },
  signupEventReducer
);

export interface ApplicationState {
  account: AccountState;
  user: UserState;
  club: ClubState;
  newUser: NewUserState & PersistPartial;
  memberships: MembershipsState;
  roster: RosterState;
  matchDetails: MatchDetailsState & PersistPartial;
  memberpool: MemberpoolState;
  memberpoolRoster: MemberpoolRosterState;
  dsConnection: DSConnectionState & PersistPartial;
  b2bDraftEvent: B2bDraftEventState & PersistPartial;
  b2bSetupEvent: B2bSetupEventState & PersistPartial;
  baseUsers: BaseUsersState & PersistPartial;
  sideModal: SideModalState & PersistPartial;
  users: UsersState & PersistPartial;
  currentUser: CurrentUserState & PersistPartial;
  signupEvent: SignupEventState & PersistPartial;
  events: EventsState;
  clubDashboard: ClubDashboardState;
  profileModal: ProfileModalState;
}

export const createRootReducer = () =>
  combineReducers({
    account: accountReducer,
    user: userReducer,
    club: clubReducer,
    newUser: newUserReducerPersisted,
    memberships: membershipsReducer,
    roster: rosterReducer,
    matchDetails: matchDetailsPersisted,
    memberpool: memberpoolReducer,
    memberpoolRoster: memberpoolRosterReducer,
    dsConnection: dsConnectionPersisted,
    b2bDraftEvent: b2bDraftEventReducerPersisted,
    b2bSetupEvent: b2bSetupEventReducerPersisted,
    baseUsers: baseUsersReducerPersisted,
    sideModal: sideModalReducerPersisted,
    users: usersReducerPersisted,
    currentUser: currentUserReducerPersisted,
    events: eventsReducer,
    signupEvent: signupEventReducerPersisted,
    clubDashboard: ClubDashboardReducer,
    profileModal: profileModalReducer,
  });
