import { useQuery } from '@tanstack/react-query';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { useDispatch } from 'react-redux';
import { validateSignupDivisions } from 'redux/v3/signupEvent/actions';
import EventService from 'services/v3/Event/EventService';

export function useGetEventById(id: string) {
  const dispatch = useDispatch();

  const {
    currentUser: { accountId },
  } = useProfile();

  const { data, isLoading, error } = useQuery(
    ['/get-event-by-id', accountId, id],
    async () => await EventService.find(id),
    {
      onSuccess(response) {
        if (response.httpCode === 200) {
          const divisions = response.data?.ageDivisions || [];
          dispatch(validateSignupDivisions(divisions));
        }
      },
    }
  );

  return {
    ...data,
    data: data?.data ?? undefined,
    isLoading,
    error,
  };
}
