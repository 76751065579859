/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  sideModalHasBackButton,
  sideModalSetTitle,
  sideModalCloseModal,
} from 'redux/sideModal/actions';
import { ApplicationState } from 'redux/store';

import FilledButton from 'components/v3/Buttons/FilledButton';
import { EventTierCard } from 'components/v3/Cards/EventTierCard/EventTierCard';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { Right } from '@icon-park/react';
import { BodyLBold, BodyM, COLORS } from 'styles/v3/variables';

import {
  MultiplierContainer,
  MultiplierDescription,
  MultiplierForm,
  MultiplierContent,
  MultiplierContainerContent,
  MultiplierContentDescription,
  MultiplierContentValue,
  TotalMultiplierContainer,
  MultipliersSave,
  MultiplierSelectContent,
  MultiplierSave,
  MultiplyContentdescription,
  MultiplyContentValue,
} from './styles';

interface MultiplierSection {
  textContent: string;
  multiplierValue: number;
  isSelected?: boolean;
}

interface Multiplier {
  title: string;
  description: string;
  isSelected?: boolean;
  content: MultiplierSection[];
}

interface CommingSelected {
  title: string;
  description: string;
  value: number;
}

const MULTIPLIERS: Multiplier[] = [
  {
    title: 'Clocks',
    description:
      'Select the type of clock will be used in your tournament. Every type of clock will add to the Multiplier for your event.',
    content: [
      { textContent: 'Running clock', multiplierValue: 0, isSelected: true },
      { textContent: 'End of half time', multiplierValue: 1 },
      { textContent: 'Total stopped clock', multiplierValue: 2 },
    ],
  },
  {
    title: 'Court',
    description:
      'Select the court size used in your event. The court size will impact the multiplier for your event.',
    content: [
      {
        textContent: 'Less than 100 x 50',
        multiplierValue: 0,
        isSelected: true,
      },
      { textContent: '100 x 50 or more', multiplierValue: 1 },
    ],
  },
  {
    title: 'Matches',
    description:
      'Select many guaranteed matches will be offered in your event. The more games are guaranteed, more attractive your event will be to players.',
    content: [
      {
        textContent: 'Up to 3 Games guaranteed',
        multiplierValue: 0,
        isSelected: true,
      },
      { textContent: '3 Games + Playoffs for Winners', multiplierValue: 1 },
      { textContent: '4 Games guaranteed', multiplierValue: 2 },
      { textContent: '5+ Games guaranteed', multiplierValue: 3 },
    ],
  },
  {
    title: 'Sanctioned Refs',
    description: 'Select how many sanctioned referees will your event have.',
    content: [
      {
        textContent: '1 Count (Ref also SK)',
        multiplierValue: 0,
        isSelected: true,
      },
      { textContent: '2 Count (1 Ref 1 SK)', multiplierValue: 1 },
      { textContent: '3 Count (2 Ref 1 SK)', multiplierValue: 2 },
      { textContent: '3 Count + Assessor', multiplierValue: 3 },
    ],
  },
  {
    title: 'Game Slots',
    description:
      'How are you planning to organize the game slots for your event?',
    content: [
      { textContent: '<1h Game slots', multiplierValue: 0, isSelected: true },
      { textContent: '1h Game slots', multiplierValue: 1 },
      { textContent: '>1h Game slots', multiplierValue: 2 },
    ],
  },
];

const Multipliers: React.FC = () => {
  const dispatch = useDispatch();

  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const { backButtonClickCount, hasBackButton } = useSelector(
    (state: ApplicationState) => state.sideModal
  );

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const [totalMultiplier, setTotalMultiplier] = useState<number>(1);
  const [multipliers, setMultipliers] = useState<Multiplier[]>(MULTIPLIERS);
  const [
    selectedMultiplier,
    setSelectedMultiplier,
  ] = useState<Multiplier | null>();

  const addToTotal = () => {
    let total = 1;
    multipliers.forEach((multiplier) => {
      multiplier.content.forEach((content) => {
        if (content.isSelected) {
          total += content.multiplierValue;
        }
      });
    });

    setTotalMultiplier(total);
  };

  useEffect(() => {
    (async () => {
      if (eventData.data?.multipliers) {
        const selectedMultpliers = eventData.data?.multipliers?.map(
          (multiplier) => {
            return {
              title: multiplier?.title,
              description: multiplier?.textContent,
              value: multiplier?.multiplierValue,
            };
          }
        ) as CommingSelected[];

        const updateMultipliers = multipliers.map((multiplierConstant) => {
          const findMultiplierSelected = selectedMultpliers.find(
            (multiplier) => multiplier.title === multiplierConstant.title
          ) as CommingSelected;

          multiplierConstant.content.forEach((contentMultiplier) => {
            if (
              contentMultiplier.multiplierValue === findMultiplierSelected.value
            ) {
              contentMultiplier.isSelected = true;
            } else {
              contentMultiplier.isSelected = false;
            }
          });

          return multiplierConstant;
        });

        setMultipliers(updateMultipliers);
        addToTotal();
      }
    })();
  }, [eventData]);

  useEffect(() => {
    const handleBackButton = () => {
      if (hasBackButton && backButtonClickCount > 0) {
        setSelectedMultiplier(null);
        dispatch(sideModalHasBackButton(false));
        dispatch(sideModalSetTitle('Multiplier'));
      }
    };

    handleBackButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backButtonClickCount, hasBackButton]);

  const handleSaveMultipliers = async () => {
    try {
      const multipliersData = MULTIPLIERS.map((multiplier) => {
        const selected = multiplier.content.find(
          (content) => content.isSelected
        ) as MultiplierSection;

        return {
          title: multiplier.title,
          textContent: selected.textContent,
          multiplierValue: selected.multiplierValue,
        };
      });

      const payload = {
        data: {
          multipliers: multipliersData,
        },
      };

      await mutateAsync({ id: eventId, data: payload });

      dispatch(sideModalCloseModal());
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  const handleSelectMultiply = (value: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newContent = selectedMultiplier!.content.map((content) => {
      if (content.multiplierValue === value) {
        content.isSelected = true;
      } else {
        content.isSelected = false;
      }

      return content;
    });

    const payload = {
      title: selectedMultiplier!.title,
      description: selectedMultiplier!.description,
      content: newContent,
    };

    setSelectedMultiplier(payload);
  };

  const handleUpdteSelected = () => {
    const updateMultiplier = multipliers.map((multiplier) => {
      if (selectedMultiplier?.title === multiplier.title) {
        multiplier.content = selectedMultiplier.content;
      }

      return multiplier;
    });

    setMultipliers(updateMultiplier);
    setSelectedMultiplier(null);
    dispatch(sideModalHasBackButton(false));
    dispatch(sideModalSetTitle('Multiplier'));
    addToTotal();
  };

  const handleSelectMultiplier = (multiplierTitle: string) => {
    setSelectedMultiplier(
      multipliers.find((multiplier) => multiplier.title === multiplierTitle)
    );
    dispatch(sideModalHasBackButton(true));
    dispatch(sideModalSetTitle(multiplierTitle));
  };

  const textMultipliers = (totalMultiplierValue: number): string => {
    if (totalMultiplier < 4) {
      return `You have just started. Go ahead and add more XP points, this will elevate your event tier.`;
    }

    if (totalMultiplierValue >= 5 && totalMultiplierValue < 7) {
      return `Level up! It's good but it can be better.`;
    }

    if (totalMultiplier >= 8 && totalMultiplierValue <= 11) {
      return `Almost there. Add more points and see the XP power.`;
    }

    return `Your tournament is going to be Awesome! Every XP in your tournament will be multiplied by ${totalMultiplierValue}x.`;
  };

  return selectedMultiplier ? (
    <MultiplierContainer>
      <MultiplierDescription>
        {selectedMultiplier.description}
      </MultiplierDescription>
      {selectedMultiplier.content.map((content) => {
        return (
          <MultiplierSelectContent key={`key_content_${content.textContent}`}>
            <MultiplyContentdescription
              value={content.multiplierValue}
              isSelected={content.isSelected as boolean}
              onClick={() => handleSelectMultiply(content.multiplierValue)}
            >
              <span>{content.textContent}</span>
              <MultiplyContentValue
                value={content.multiplierValue}
                isSelected={content.isSelected as boolean}
              >
                <span>+{content.multiplierValue}x</span>
              </MultiplyContentValue>
            </MultiplyContentdescription>
          </MultiplierSelectContent>
        );
      })}
      <MultiplierSave>
        <FilledButton onClick={handleUpdteSelected} color='primary' isUpper>
          Save
        </FilledButton>
      </MultiplierSave>
    </MultiplierContainer>
  ) : (
    <MultiplierContainer>
      <MultiplierDescription>
        The multiplier will add to the event experience. All XP gained in this
        tournament will be multiplied.
      </MultiplierDescription>
      <MultiplierForm onSubmit={(e) => e.preventDefault()}>
        {multipliers.map((multiplier) => {
          return (
            <MultiplierContent
              key={`key_${multiplier.title}`}
              onClick={() => handleSelectMultiplier(multiplier.title)}
            >
              <BodyLBold style={{ lineHeight: 0 }}>
                {multiplier.title}
              </BodyLBold>

              {multiplier.content.map((content) => {
                if (content.isSelected) {
                  return (
                    <MultiplierContainerContent>
                      <MultiplierContentDescription
                        key={`key_content_${content.textContent}`}
                        value={content.multiplierValue}
                        isSelected={content.isSelected as boolean}
                      >
                        <span>{content.textContent}</span>
                        <MultiplierContentValue
                          value={content.multiplierValue}
                          isSelected={content.isSelected as boolean}
                        >
                          <span>+{content.multiplierValue}x</span>
                        </MultiplierContentValue>
                      </MultiplierContentDescription>
                      <Right theme='outline' size='24' fill={COLORS.white} />
                    </MultiplierContainerContent>
                  );
                }
                return <></>;
              })}
            </MultiplierContent>
          );
        })}

        <TotalMultiplierContainer>
          <EventTierCard multiplier={totalMultiplier} />
          <BodyM style={{ paddingTop: 12 }}>
            {textMultipliers(totalMultiplier)}
          </BodyM>
        </TotalMultiplierContainer>

        <MultipliersSave>
          <FilledButton
            isUpper
            color='primary'
            isLoading={isLoading}
            onClick={handleSaveMultipliers}
          >
            Save {isLoading && '...'}
          </FilledButton>
        </MultipliersSave>
      </MultiplierForm>
    </MultiplierContainer>
  );
};

export default withRouter(Multipliers);
