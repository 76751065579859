export enum SoccerPeriod {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
  FIRST_EXTRA_TIME = 'FIRST_EXTRA_TIME',
  SECOND_EXTRA_TIME = 'SECOND_EXTRA_TIME',
  PENALTY_SHOOTOUT = 'PENALTY_SHOOTOUT',
  FINISHED_GAME = 'FINISHED_GAME',
}

export enum FutsalAction {
  APPEARANCE = 'APPEARANCE',
  GOAL = 'GOAL',
  PK_GOAL = 'PK_GOAL',
  PK_MISS = 'PK_MISS',
  OWN_GOAL = 'OWN_GOAL',
  FOUL = 'FOUL',
  YELLOW_CARD = 'YELLOW_CARD',
  RED_CARD = 'RED_CARD',
}

export enum GenderCategory {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  COED = 'COED',
}
