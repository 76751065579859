import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import EventService from 'services/v3/Event/EventService';

export type MutationFnProps = {
  data: NewAgeDivision,
};

type UseUpdateDivisionType = {
  eventId: string,
  invalidateRosters?: boolean,
};

export const useUpdateDivision = ({
  eventId,
  invalidateRosters = false,
}: UseUpdateDivisionType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ data }: MutationFnProps) =>
      await EventService.updateDivision(eventId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['event-divisions-ordered', eventId]);

      if (invalidateRosters) {
        await queryClient.invalidateQueries([
          'rosters-event-available-for-teams',
          eventId,
        ]);
      }
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error?.message || 'Unexpected error.',
      });
    },
  });
};

export const useUpdateDivisionTest = ({
  eventId,
  invalidateRosters = false,
}: UseUpdateDivisionType) => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ data }: MutationFnProps) =>
      await EventService.updateTeamPlacement(eventId, data),
    onSuccess: async () => {
      // await queryClient.invalidateQueries(['event-team-placement', eventId]);
      // if (invalidateRosters) {
      //   await queryClient.invalidateQueries([
      //     'rosters-event-available-for-teams',
      //     eventId,
      //   ]);
      // }
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error?.message || 'Unexpected error.',
      });
    },
  });
};
