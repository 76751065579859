import { ClubView } from 'admin/models/club/Club';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';

export default function useClubPermission(club?: ClubView) {
  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const isDirectorOfThisClub = useMemo(() => {
    if (!club || !currentUser) return false;

    return (
      club.directorIds.findIndex((id) =>
        [...(currentUser?.activeUserIds ?? [currentUser.id])].includes(id)
      ) > -1
    );
  }, [currentUser, club]);

  const isTeamManagerOfThisClub = useMemo(() => {
    if (!club || !currentUser) return false;

    const teamManagerIds = club?.teamManagers?.map((t) => t.userId) || [];
    return (
      teamManagerIds.findIndex((id) =>
        [...(currentUser?.activeUserIds ?? [currentUser.id])].includes(id)
      ) > -1
    );
  }, [currentUser, club]);

  const isDirectorOrManagerOfThisClub = useMemo(() => {
    if (!club || !currentUser) return false;

    const teamManagerIds = club?.teamManagers?.map((t) => t.userId) || [];
    const clubManagerAndDirectorIds = [...club.directorIds, ...teamManagerIds];
    return (
      clubManagerAndDirectorIds.findIndex((id) =>
        [...(currentUser?.activeUserIds ?? [currentUser.id])].includes(id)
      ) > -1
    );
  }, [currentUser, club]);

  const isMemberOfThisClub = useMemo(() => {
    if (!club || !currentUser) return false;
    const teamManagerIds = club?.teamManagers?.map((t) => t.userId) || [];

    return (
      [
        ...(club?.directorIds || []),
        ...(club?.playerIds || []),
        ...(club?.coachIds || []),
        ...teamManagerIds,
      ].findIndex((id) =>
        [...(currentUser?.activeUserIds ?? [currentUser.id])].includes(id)
      ) > -1
    );
  }, [currentUser, club]);

  const isPlayerOfThisClub = useMemo(() => {
    if (!club || !currentUser) return false;

    return (
      [...(club?.playerIds || [])].findIndex((id) =>
        [...(currentUser?.activeUserIds ?? [currentUser.id])].includes(id)
      ) > -1
    );
  }, [currentUser, club]);

  const isCoachOfThisClub = useMemo(() => {
    if (!club || !currentUser) return false;

    return (
      [...(club?.coachIds || [])].findIndex((id) =>
        [...(currentUser?.activeUserIds ?? [currentUser.id])].includes(id)
      ) > -1
    );
  }, [currentUser, club]);

  if (!currentUser || !club) {
    return {
      isDirectorOfThisClub: false,
      isMemberOfThisClub: false,
      isPlayerOfThisClub: false,
      isCoachOfThisClub: false,
    };
  }

  return {
    isDirectorOfThisClub,
    isTeamManagerOfThisClub,
    isDirectorOrManagerOfThisClub,
    isMemberOfThisClub,
    isPlayerOfThisClub,
    isCoachOfThisClub,
  };
}
