import React from 'react';

import { BodyS } from 'styles/v3/variables';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { useTeamPlacement } from 'pages/V3/EventManager/components/TeamPlacement/context/TeamPlacementContext';
import * as S from './styles';

type DivisionButtonType = NewAgeDivision & {
  targetColor: 'grey100' | 'brandPrimary' | 'supportError',
};

export const DivisionButton = ({ ...rest }: DivisionButtonType) => {
  const { id, name } = rest;

  const { selectedDivision, setSelectedDivision } = useTeamPlacement();

  const isSelected = id === selectedDivision?.id;

  return (
    <S.Container
      isSelected={isSelected}
      onClick={() => setSelectedDivision(rest)}
    >
      <BodyS $isUpper>{name}</BodyS>
    </S.Container>
  );
};
