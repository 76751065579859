import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import { IDetailRoster } from 'services/v3/Match/interfaces';
import { IPlayerNumberPayload } from 'services/v3/Rosters/types';

import {
  TeamEventCard,
  TeamRivalsType,
} from 'components/v3/Cards/TeamEventCard/TeamEventCard';
import {
  MemberCard,
  MemberType,
} from 'components/v3/Cards/MemberCard/MemberCard';

import * as S from './styles';

interface IRosterCheckInProps {
  matchId: Maybe<string>;
  awayTeam: Maybe<IDetailRoster>;
  homeTeam: Maybe<IDetailRoster>;
  onAttendanceMemberClick: (
    matchId: string,
    rosterId: string,
    userId: string,
    attend: boolean
  ) => void;
  onUpdatePlayerNumber: (payload: IPlayerNumberPayload) => void;
}

export const RosterCheckIn: FC<IRosterCheckInProps> = memo(
  ({
    matchId,
    awayTeam,
    homeTeam,
    onAttendanceMemberClick,
    onUpdatePlayerNumber,
  }) => {
    const [selectedTeam, setSelectedTeam] = useState<TeamRivalsType>(
      TeamRivalsType.TeamHome
    );

    const hasAwayTeamMembers = useMemo(
      () =>
        selectedTeam === TeamRivalsType.TeamAway &&
        (!!awayTeam?.coaches?.length || !!awayTeam?.players?.length),
      [awayTeam, selectedTeam]
    );

    const hasHomeTeamMembers = useMemo(
      () =>
        selectedTeam === TeamRivalsType.TeamHome &&
        (!!homeTeam?.coaches?.length || !!homeTeam?.players?.length),
      [homeTeam, selectedTeam]
    );

    const handleTeamEventCardClick = useCallback((team: TeamRivalsType) => {
      setSelectedTeam(team);
    }, []);

    return (
      <S.Wrapper>
        <S.TextPlaceholder>
          Select the team to do Roster Check-In
        </S.TextPlaceholder>
        <S.TeamsWrapper>
          <TeamEventCard
            type={TeamRivalsType.TeamHome}
            team={homeTeam}
            selected={TeamRivalsType.TeamHome === selectedTeam}
            onClick={handleTeamEventCardClick}
          />
          <TeamEventCard
            type={TeamRivalsType.TeamAway}
            team={awayTeam}
            selected={TeamRivalsType.TeamAway === selectedTeam}
            onClick={handleTeamEventCardClick}
          />
        </S.TeamsWrapper>
        {(hasAwayTeamMembers || hasHomeTeamMembers) && (
          <S.MembersWrapper>
            {selectedTeam === TeamRivalsType.TeamHome &&
              homeTeam?.coaches?.map((coach) => (
                <MemberCard
                  key={coach.id}
                  user={coach}
                  matchId={matchId || ''}
                  rosterId={homeTeam.id}
                  type={MemberType.Coach}
                  onAttendanceClick={onAttendanceMemberClick}
                />
              ))}
            {selectedTeam === TeamRivalsType.TeamAway &&
              awayTeam?.coaches?.map((coach) => (
                <MemberCard
                  key={coach.id}
                  user={coach}
                  matchId={matchId || ''}
                  rosterId={awayTeam.id}
                  type={MemberType.Coach}
                  onAttendanceClick={onAttendanceMemberClick}
                />
              ))}
            {selectedTeam === TeamRivalsType.TeamHome &&
              homeTeam?.players?.map((player) => (
                <MemberCard
                  key={player.id}
                  user={player}
                  matchId={matchId || ''}
                  rosterId={homeTeam.id}
                  type={MemberType.Player}
                  players={homeTeam.players}
                  onAttendanceClick={onAttendanceMemberClick}
                  onUpdatePlayerNumber={onUpdatePlayerNumber}
                />
              ))}
            {selectedTeam === TeamRivalsType.TeamAway &&
              awayTeam?.players?.map((player) => (
                <MemberCard
                  key={player.id}
                  user={player}
                  matchId={matchId || ''}
                  rosterId={awayTeam.id}
                  type={MemberType.Player}
                  players={awayTeam.players}
                  onAttendanceClick={onAttendanceMemberClick}
                  onUpdatePlayerNumber={onUpdatePlayerNumber}
                />
              ))}
          </S.MembersWrapper>
        )}
        {!hasAwayTeamMembers && !hasHomeTeamMembers && (
          <S.MembersEmptyState>
            The list of members is absent
          </S.MembersEmptyState>
        )}
      </S.Wrapper>
    );
  }
);

RosterCheckIn.displayName = 'RosterCheckIn';
