import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const ModalCardWrapper = styled.div`
  .simple-card-wrapper {
    width: 100%;
    height: 100%;
    border-color: ${COLORS.grey800};
    justify-content: space-between;
    align-items: center;
  }

  .i-icon-edit {
    &::before {
      display: none;
    }
  }
`;

export const Stripe = styled.span<{ $color?: string }>`
  width: 9px;
  min-height: 10vh;
  border-radius: 21px;
  background-color: ${(props) => props.$color ?? COLORS.pink};
  margin-right: 1rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BgIcon = styled.div`
  border-radius: 12px;
  padding: ${toRem(8)};
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${COLORS.grey800};

  & + div {
    margin-top: ${toRem(8)};
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
