import React, { memo, FC, useMemo, useCallback } from 'react';

import { IRosterUser, IFutsalEventView } from 'services/v3/Match/interfaces';

import {
  FUTSAL_EVENTS,
  PENALTY_FUTSAL_EVENTS,
} from 'pages/V3/GamesControl/components/Scoresheet/config';

import ConvertUtil from 'util/ConvertUtil';

import * as S from './styles';

interface IActionGameCardProps {
  isRight: boolean;
  event: IFutsalEventView;
  rosterUser: Maybe<IRosterUser>;
  onOpenConfirmModal: (futsalId: string) => void;
}

export const ActionGameCard: FC<IActionGameCardProps> = memo(
  ({ isRight = false, event, rosterUser, onOpenConfirmModal }) => {
    const { id, action, time } = event;

    const convertTime = useMemo(() => {
      if (time.startsWith('00:')) {
        return time.slice(3);
      }

      return time;
    }, [time]);

    const futsalActionText = useMemo(() => {
      return (
        FUTSAL_EVENTS.find((event) => event.type === action) ||
        PENALTY_FUTSAL_EVENTS.find((event) => event.type === action)
      );
    }, [action]);

    const handleRemoveFutsalEvent = useCallback(() => {
      onOpenConfirmModal(id);
    }, [id, onOpenConfirmModal]);

    return (
      <S.Wrapper $isLeft={!isRight}>
        <S.RemoveIcon
          type='Delete'
          size={24}
          $isRight={isRight}
          onClick={handleRemoveFutsalEvent}
        />
        <S.Avatar $isRight={isRight}>
          {rosterUser && (
            <img
              src={ConvertUtil.getMinioUrl(rosterUser.photo)}
              alt={`${rosterUser.firstName} ${rosterUser.lastName}`}
            />
          )}
        </S.Avatar>
        <S.Content $isRight={isRight}>
          <S.FixTime>
            {convertTime} {futsalActionText?.value || ''}
          </S.FixTime>
          {rosterUser && (
            <S.PlayerInfo>
              {rosterUser.number && (
                <S.PlayerNumber>{rosterUser.number}</S.PlayerNumber>
              )}
              <S.PlayerUsername>
                {rosterUser.firstName} {rosterUser.lastName}
              </S.PlayerUsername>
            </S.PlayerInfo>
          )}
        </S.Content>
      </S.Wrapper>
    );
  }
);

ActionGameCard.displayName = 'ActionGameCard';
