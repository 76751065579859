import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import { BracketView, UpdateBracketPut, BracketType } from 'models/Bracket/BracketModel';

class BracketService {
  public static async getBracketsByEventId(
    eventId?: string
  ): Promise<ServiceResponseV3<BracketView[]>> {

    if (!eventId) return ServiceResponseV3.success<BracketView[]>([], '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/event/${eventId}`,
      method: 'GET',
    };
    return APIServicesV3.request<BracketView[]>(axiosConfig);
  }

  public static async updateBracketsByBracketIdAndMatchId(
    bracketId: string,
    matchId: string,
    bracketUpdateInfo: UpdateBracketPut
  ): Promise<ServiceResponseV3<BracketView>> {

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/${bracketId}/match/${matchId}`,
      method: 'PUT',
      data: bracketUpdateInfo
    };
    return APIServicesV3.request<BracketView>(axiosConfig);
  }

  public static async getBracketsByEventIdAndAgeDivisionId(
    eventId: string,
    ageDivisionId: string
  ): Promise<ServiceResponseV3<BracketView[]>> {

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/event/${eventId}/age-division/${ageDivisionId}`,
      method: 'GET',
    };
    return APIServicesV3.request<BracketView[]>(axiosConfig);
  }

  public static async createConsolationGame(bracketId: string) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/${bracketId}/consolation-game`,
      method: 'POST',
      data: {}
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async deleteConsolationGame(bracketId: string, consolationGameId: string) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/${bracketId}/consolation-game/${consolationGameId}`,
      method: 'DELETE',
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async createBracket(eventId: string, ageDivisionId: string, type: BracketType):
    Promise<ServiceResponseV3<BracketView>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets`,
      method: 'POST',
      data: {
        eventId,
        ageDivisionId,
        type,
      }
    };

    return APIServicesV3.request<BracketView>(axiosConfig);
  }

  public static async deleteBracket(bracketId: string) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/${bracketId}`,
      method: 'DELETE',
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async updateBracketName(
    bracketId: string,
    bracketName: string
  ): Promise<ServiceResponseV3<BracketView>> {

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/brackets/${bracketId}/change-bracket-name`,
      method: 'PATCH',
      data: {
        name: bracketName,
      }
    };

    return APIServicesV3.request<BracketView>(axiosConfig);
  }
}

export default BracketService;
