import React, { useState } from 'react';
import { Filter as FilterIcon } from '@icon-park/react';

import { BodyMBold } from 'styles/v3/variables';
import InputText from 'components/v3/Forms/InputText/InputText';
import { FiltersProps } from './types';
import { FilterDrawer } from './components';
import * as S from './styles';

export const Filters = ({ handleChange }: FiltersProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <S.Container>
      <S.FilterWrapper onClick={() => setIsDrawerOpen((prev) => !prev)}>
        <BodyMBold $isUpper $color='grey400'>
          Filters
        </BodyMBold>
        <FilterIcon className='btn-filter-modal' />
      </S.FilterWrapper>
      <FilterDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
      <InputText
        id='search'
        icon='Search'
        placeholder='Search'
        onChange={(e) => handleChange(e.target.value)}
      />
    </S.Container>
  );
};
