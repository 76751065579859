import React, { useState } from 'react';
import { PreviewOpen } from '@icon-park/react';

import { User } from 'models/User/UserModel';
import * as S from './styles';
import { ViewMemberDrawer } from './components/ViewMemberDrawer';

export const ViewMember = (props: User) => {
  const [isViewMemberOpen, setIsViewMemberOpen] = useState(false);

  return (
    <S.Container>
      <ViewMemberDrawer
        user={props}
        isOpen={isViewMemberOpen}
        onClose={() => setIsViewMemberOpen(false)}
      />
      <PreviewOpen onClick={() => setIsViewMemberOpen((prev) => !prev)} />
    </S.Container>
  );
};
