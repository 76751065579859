import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import { RequestStatus } from 'models/Request/RequestModel';
import UserType from 'models/User/UserTypeModel';
import { MembershipView } from 'admin/models/membership/Membership';

import { BodyS } from 'styles/v3/variables';

import { DocumentStatus } from '../../../MembersPoolTab/components/MembersPoolTable/components';

import * as F from './components';

export type DataType = {
  id: string,
  requestIds: string[],
  name: string,
  permission: 'PLAYER'| 'COACH' | 'CLUB_DIRECTOR' |'REFEREE' | 'TOURNAMENT_DIRECTOR' | 'STAFF',
  age: number,
  gender: string,
  membership: MembershipView['type'],
  photoStatus: RequestStatus,
  ageStatus: RequestStatus,
  safeSportStatus: RequestStatus,
  photo: string,
  selected: boolean,
  numberOfClubs: number,
  userTypes: UserType[]
  toggleSelected: () => void,
  openApproveModal: () => void,
  openRejectModal: () => void,
};

export const columns: ColumnsType<DataType> = [
  {
    title: 'First Name',
    dataIndex: 'name',
    render(_, {name, photo}) {
      return <F.Name name={name} photo={photo} />;
    },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'B.Y.',
    dataIndex: 'age',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: '# of Clubs',
    dataIndex: 'numberOfClubs',
    render(value) {
      return <BodyS $color='grey400'>{value} clubs</BodyS>;
    },
  },
  {
    title: 'Permission',
    dataIndex: 'userTypes',
    render(value) {
      return  <F.Permission permissions={value} />;
    },
  },
  {
    title: 'Documents',
    render(_, { ageStatus, photoStatus, safeSportStatus, permission}) {
      return (
        <DocumentStatus
          ageStatus={ageStatus}
          photoStatus={photoStatus}
          safeSportStatus={safeSportStatus}
          permission={permission}
        />
      );
    },
  },
  {
    title: '',
    render(_, { openApproveModal, openRejectModal }) {
      return <F.ApproveReject openApproveModal={openApproveModal} openRejectModal={openRejectModal} />;
    },
  },
];
