import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const PaymentWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  height: '100%';
  background-color: ${COLORS.grey1000};

  div.summary {
    h1 {
      margin: 0;

      &:nth-child(2) {
        color: ${COLORS.brandPrimary};
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-bottom: ${toRem(30)};
  }
`;

export const PaymentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 30px;
  max-width: 588px;
  height: fit-content;
`;

export const PaymentSummary = styled.div`
  h1 {
    margin: 0;
    &:nth-child(2) {
      color: ${COLORS.brandPrimary};
    }
  }
`;

export const Container = styled.div``;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const PaymentEvent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  align-items: center;

  h4 {
    margin: 0;
  }

  p {
    color: ${COLORS.grey300};
    width: 222px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;

    gap: ${toRem(12)};
  }
`;

export const EventLogoContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 135px;
  min-width: 135px;
  height: 135px;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: ${COLORS.grey900};
`;

export const PaymentText = styled.div`
  max-width: 40%;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  /* BodyL */
  > p {
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: row;
    column-gap: 12px;

    div.title {
      color: ${COLORS.grey300};
      display: flex;
      flex-direction: row;
      column-gap: 4px;
    }

    div.value {
      color: ${COLORS.grey50};
    }
  }
`;

export const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;

  border-radius: 24px;

  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url('/assets/imgs/map-placeholder.png'), lightgray 50% / cover no-repeat;

  div {
    display: flex;
    column-gap: 7px;
  }
`;

export const EventCardContainer = styled.div`
  display: flex;
  padding: 20px;
  column-gap: 18px;

  border-radius: 12px;
  background: #373731;

  h4 {
    color: ${COLORS.brandPrimary};
  }
`;
