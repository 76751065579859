import message from 'antd/lib/message';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Button from '../../../components/uielements/Button/Button';
import SocketProvider from '../../../context/SocketContext';
import { MatchStatus } from '../../../models/Match/MatchModel';
import { matchDetailsFetchRequest } from '../../../redux/matchDetails/actions';
import { ApplicationState } from '../../../redux/store';
import MatchService from '../../../services/Match/MatchService';
import { color, font } from '../../../styles/styleVariables';
import GameFacts from './GameControl/GameFacts';
import RosterCheckIn from './GameControl/RosterCheck/RosterCheckIn';
import Scoresheet from './GameControl/Scoresheet';
// import { tournamentRoutes } from './routes/Tournaments.routes';

interface IGameControlProps {}

const GameControl: React.FunctionComponent<IGameControlProps> = (props) => {
  const { matchId } = useParams<{ matchId: string }>();
  const [tabSelected, setTabSelected] = useState<string>('');
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [editFinishedGame, setEditFinishedGame] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const matchDetails = useSelector(
    (state: ApplicationState) => state.matchDetails
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const isScheduled = () => {
    return matchDetails.data.match?.status === MatchStatus.SCHEDULED;
  };

  useEffect(() => {
    if (matchId) {
      dispatch(matchDetailsFetchRequest(matchId));
    }
  }, [matchId]);

  useEffect(() => {
    if (firstRender) setFirstRender(false);
  }, [firstRender]);

  useEffect(() => {
    if (
      matchDetails.data.match?.status === MatchStatus.FINISHED &&
      !firstRender
    ) {
      history.push(`${history.location.state}`);
    } else if (matchDetails.data.match?.status === MatchStatus.SCHEDULED) {
      setTabSelected('roster-check');
    } else {
      setTabSelected('scoresheet');
    }
  }, [matchDetails.data.match?.status]);

  const resubmitGame = () => {
    setIsSubmitting(true);
    if (matchDetails.data.match) {
      MatchService.resubmitGame(matchDetails.data.match.matchId).then((res) => {
        setIsSubmitting(false);
        setEditFinishedGame(false);
        if (res.success) {
          message.success('Game fixed');
        } else {
          message.error(
            'Ops... Something wrong happened please try again later'
          );
        }
      });
    }
  };

  return (
    <div>
      <h1>Game Control</h1>
      {/* <Breadcrumbs routes={tournamentRoutes} /> */}
      <div style={{ marginBottom: '10px' }}>
        {matchDetails.data.match?.status === MatchStatus.FINISHED &&
          !editFinishedGame && (
            <Button
              style={{ backgroundColor: '#a3a3a3' }}
              onClick={() => {
                setEditFinishedGame(true);
              }}
            >
              Enable Edit Finished Game
            </Button>
          )}
        {matchDetails.data.match?.status === MatchStatus.FINISHED &&
          editFinishedGame && (
            <Button
              style={{ backgroundColor: '#fd502f' }}
              onClick={() => {
                if (!isSubmitting) {
                  resubmitGame();
                }
              }}
            >
              {!isSubmitting
                ? 'Save and Resubmit Game Events'
                : 'Submitting...'}
            </Button>
          )}
      </div>
      <GameMenu className='styled-scrollbar'>
        {/* <div
          className={`item ${tabSelected === 'game-details' ? 'active' : ''}`}
          onClick={() => setTabSelected('game-details')}
        >
          Game Details
        </div> */}

        <div
          className={`item ${tabSelected === 'roster-check' ? 'active' : ''}`}
          onClick={() => setTabSelected('roster-check')}
        >
          Roster Check-In
        </div>
        <div
          className={`item ${tabSelected === 'game-facts' ? 'active' : ''} ${
            isScheduled() ? 'disabled' : ''
          }`}
          onClick={() => (!isScheduled() ? setTabSelected('game-facts') : null)}
        >
          Game Facts
        </div>
        <div
          className={`item ${tabSelected === 'scoresheet' ? 'active' : ''} ${
            isScheduled() ? 'disabled' : ''
          }`}
          onClick={() => (!isScheduled() ? setTabSelected('scoresheet') : null)}
        >
          Scoresheet
        </div>
      </GameMenu>

      {/* <div
        style={{
          display: tabSelected === 'game-details' ? 'block' : 'none',
          padding: '30px 20px',
          backgroundColor: '#2e3137',
        }}
      >
        <GameDetails />
      </div> */}
      <div
        style={{
          display: tabSelected === 'scoresheet' ? 'block' : 'none',
          padding: '30px 20px',
          backgroundColor: '#2e3137',
        }}
      >
        <SocketProvider>
          <Scoresheet editFinishedGame={editFinishedGame} />
        </SocketProvider>
      </div>
      <div
        style={{
          display: tabSelected === 'game-facts' ? 'block' : 'none',
          padding: '30px 20px',
          backgroundColor: '#2e3137',
        }}
      >
        <GameFacts />
      </div>
      <div
        style={{
          display: tabSelected === 'roster-check' ? 'block' : 'none',
          padding: '30px 20px',
          backgroundColor: '#2e3137',
        }}
      >
        <RosterCheckIn />
      </div>
    </div>
  );
};

const GameMenu = styled.div`
  display: flex;
  overflow-x: auto;
  .item {
    width: 50%;
    padding: 10px 20px;
    text-align: center;
    color: #fff;
    font-family: ${font.druk};
    text-transform: uppercase;
    font-size: 9px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    background: ${color.greyLight};
    align-items: center;
    border-bottom: 1px solid ${color.silver};
    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      border-radius: 0 10px 0 0;
    }
    &:hover {
      opacity: 0.5;
    }
    &.disabled {
      opacity: 0.5;
    }
    &.active {
      border-bottom: 2px solid #fff;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export default GameControl;
