import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ApplicationState } from 'redux/store';
import { sideModalSetStep, sideModalSetTitle } from 'redux/sideModal/actions';

import { mountYearCategories } from 'util/setAgeOptions';
import { DeepPartial } from 'util/types';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import FilledButton from 'components/v3/Buttons/FilledButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import InputText from 'components/v3/Forms/InputText/InputText';
import InputColor from 'components/v3/Forms/InputColor/InputColor';
import Select from 'components/v3/Forms/Select/Select';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import ModalCard from 'components/v3/Cards/ModalCard/ModalCard';
import { Spinner } from 'components/v3/Spinner/Spinner';
import { confirm } from 'components/v3/ConfirmModal/ConfirmModal';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { BodyM } from 'styles/v3/variables';

import { Fields } from './types';
import { ageRuleOptions, genderOptions } from './constants';
import { getDefaultStateFields } from './utils';

import * as S from './styles';

type StatusType = 'creating' | 'editing' | 'deleting';

type ButtonLabelType = 'Create' | 'Edit' | 'Delete';

const Divisions: React.FC = () => {
  const params: { eventId: string } = useParams();

  const eventId: string = params?.eventId || '';

  const { eventData, loading } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const dispatch = useDispatch();

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const { step } = useSelector((state: ApplicationState) => state.sideModal);

  const formRef = useRef<HTMLFormElement>(null);

  const divisionsData: DeepPartial<NewAgeDivision[]> = useMemo(
    () => eventData.ageDivisions ?? [],
    [eventData]
  );

  const [divisionIndex, setDivisionIndex] = useState<number | null>(null);

  const isPublishedEvent = useMemo(() => {
    return !eventData.draft;
  }, [eventData]);

  const currentDivision: DeepPartial<NewAgeDivision> | undefined = useMemo(
    () => (divisionIndex !== null ? divisionsData[divisionIndex] : {}),
    [divisionIndex]
  );

  const stateFields = getDefaultStateFields(currentDivision);

  const [
    allowGirlBoysOnAnotherDivision,
    setAllowGirlBoysOnAnotherDivision,
  ] = useState<boolean>(
    currentDivision?.allowGirlBoysOnAnotherDivision ?? false
  );
  const [rulesForCoed, setRulesForCoed] = useState<boolean>(!!currentDivision?.coedRules);
  const [fields, setFields] = useState<Fields>(stateFields);
  const [status, setStatus] = useState<StatusType>('creating');
  const [buttonLabel, setButtonLabel] = useState<ButtonLabelType>('Create');

  const setLabelToAgeCategory = mountYearCategories().find((y) =>
    currentDivision?.years ? currentDivision?.years[0] === y.value : null
  );

  const setLabelToAgeRule = ageRuleOptions.find(
    (r) => r.value === currentDivision?.rule
  );

  const setLabelToGender = genderOptions.find(
    (g) => g.value === currentDivision?.gender
  );

  const isFieldKey = (key: string): key is keyof Fields => {
    return key in stateFields;
  };

  const validate = () => {
    let hasError = false;

    for (const [key, values] of Object.entries(fields)) {
      if (key === 'targetNumberOfTeams') {
        const numericValue = parseFloat(values.value);

        if (isNaN(numericValue) || numericValue <= 0) {
          hasError = true;

          setFields((newFields) => ({
            ...newFields,
            [key]: {
              error: true,
              value: values.value,
              required: values.required,
            },
          }));
        }
      } else if (!values.value && values.required) {
        hasError = true;

        setFields((newFields) => ({
          ...newFields,
          [key]: {
            error: true,
            value: values.value,
            required: values.required,
          },
        }));
      }
    }

    if (!hasError) {
      if (status === 'deleting') {
        confirm({
          message:
            'Are you sure that you want to delete this division?',
            onOk: () => {
              handleSaveDivisions();
            }
        });
      } else {
        handleSaveDivisions();
      }
    } 
  };

  const handleSaveDivisions = async () => {
    try {
      if (formRef.current) {
        const formData = new FormData(formRef.current);

        let data: any;

        const payload = {
          ...currentDivision,
          years: [Number(formData.get('years'))],
          gender: formData.get('gender') as 'MALE' | 'FEMALE' | 'COED',
          rule: formData.get('rule') as 'YOUNGER' | 'EQUAL',
          name: formData.get('name') as string,
          allowGirlBoysOnAnotherDivision: Boolean(
            formData.get('allowGirlBoysOnAnotherDivision')
          ),
          color: formData.get('color') as string,
          coedRules: formData.get('coedRules') as string,
          targetNumberOfTeams: Number(formData.get('targetNumberOfTeams')),
          lockDivision: Boolean(formData.get('lockDivision')),
        };

        const filterCurrentDivision = divisionsData.filter(
          (d) => d?.id !== currentDivision?.id
        );

        switch (status) {
          case 'creating':
            data = {
              ageDivisions: [
                ...divisionsData,
                {
                  ...payload,
                  id: uuidv4(),
                },
              ],
            };
            break;
          case 'editing':
            data = {
              ageDivisions: [
                ...filterCurrentDivision,
                {
                  ...payload,
                  id: currentDivision?.id,
                },
              ],
            };
            break;
          case 'deleting':
            data = {
              ageDivisions: [...filterCurrentDivision],
            };
            break;
        }

        await mutateAsync({ id: eventId, data });
      }
    } catch (e) {
      console.error(`Error when ${status} division: ${e}`);
    }
  };

  const handleCreateDivision = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDivisionIndex(null);
    dispatch(sideModalSetStep(1));
    setStatus('creating');
  };

  const handleEditDivision = (index: number) => {
    setDivisionIndex(index);
    dispatch(sideModalSetStep(1));
    setStatus('editing');
  };

  const handleDeleteDivision = (index: number) => {
    setDivisionIndex(index);
    dispatch(sideModalSetStep(1));
    setStatus('deleting');
  };

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof Fields;
    const newValue = e.target.value;

    if (isFieldKey(fieldName)) {
      setFields((prevFields) => ({
        ...prevFields,
        [fieldName]: {
          ...prevFields[fieldName],
          error: false,
          value: newValue,
        },
      }));
    }
  };

  const handleChangeSelect = (field: string, e: any) => {
    const newValue = e.value;

    if (isFieldKey(field)) {
      setFields((prevFields) => ({
        ...prevFields,
        [field]: {
          ...prevFields[field],
          error: false,
          value: newValue,
        },
      }));
    }
  };

  const getDivisionRules = useCallback((division: Maybe<DeepPartial<NewAgeDivision>>) => {
    if (!division) return '';

    let baseInfo = `${division.gender} - ${division.years?.[0]}`;

    if (division.gender === 'MALE' && division.allowGirlBoysOnAnotherDivision) {
      baseInfo = `${baseInfo} - Allow Girls`;
    }

    if (division.gender === 'COED' && division.coedRules) {
      baseInfo = `${baseInfo} - ${division.coedRules}`;
    }

    return baseInfo;
  }, []);

  useEffect(() => {
    if (step === 0) {
      dispatch(sideModalSetTitle('Divisions'));
      setDivisionIndex(null);
    } else if (status === 'editing') {
      dispatch(sideModalSetTitle('Edit Division'));
      setButtonLabel('Edit');
    } else if (status === 'deleting') {
      dispatch(sideModalSetTitle('Delete Division'));
      setButtonLabel('Delete');
    } else {
      dispatch(sideModalSetTitle('New division'));
      setButtonLabel('Create');
    }
  }, [step, dispatch]);

  useEffect(() => {
    if (currentDivision) {
      const entries = Object.entries(currentDivision);
      if (entries) {
        entries.map(([key, val]) => {
          if (isFieldKey(key)) {
            setFields((newFields) => ({
              ...newFields,
              [key]: {
                ...newFields[key],
                value: val,
              },
            }));
          }
        });
      }
    }
  }, [currentDivision]);

  useEffect(() => {
    if (!divisionIndex) {
      setFields(stateFields);
    }
  }, [divisionIndex]);

  return (
    <S.DivisionsForm
      id='ageDivisions'
      ref={formRef}
      onSubmit={(e) => e.preventDefault()}
    >
      {step === 0 ? (
        <>
          <S.ButtonWrapper role='group' aria-labelledby='form-fieldset'>
            <OutlinedButton color='dark-white' onClick={handleCreateDivision}>
              New Division
            </OutlinedButton>
          </S.ButtonWrapper>
          <S.DivisionsWrapper>
            {loading && (
              <S.SpinnerWrapper>
                <Spinner />
              </S.SpinnerWrapper>
            )}
            {!loading && divisionsData?.map((division, index) => (
              <ModalCard
                key={division?.name}
                stripeColor={division?.color}
                title={division?.name ?? ''}
                isPublishedEvent={isPublishedEvent}
                description={getDivisionRules(division || null)}
                size='small'
                orientation='row'
                handleDelete={() => handleDeleteDivision(index)}
                handleEdit={() => handleEditDivision(index)}
              />
            ))}
          </S.DivisionsWrapper>
        </>
      ) : (
        step === 1 && (
          <>
            {status === 'creating' || status === 'editing' ? (
              <fieldset>
                <InputText
                  id='name'
                  label='Division Name'
                  error={fields.name.error}
                  placeholder='Division Name'
                  onChange={handleChangeInputValue}
                  defaultValue={
                    status === 'editing' ? fields.name.value : undefined
                  }
                  errorMessage='The field division name is required'
                />
                <Select
                  name='years'
                  defaultValue={setLabelToAgeCategory}
                  placeholder='Age Category'
                  options={mountYearCategories()}
                  onChange={(e) => handleChangeSelect('years', e)}
                  className='general-rules-input'
                  error={fields.years.error}
                  errorMessage='The field age category is required'
                />
                <Select
                  name='rule'
                  defaultValue={setLabelToAgeRule}
                  placeholder='Age Rule'
                  options={ageRuleOptions}
                  onChange={(e) => handleChangeSelect('rule', e)}
                  className='general-rules-input'
                  error={fields.rule.error}
                  errorMessage='The field age rule is required'
                />
                <Select
                  name='gender'
                  defaultValue={setLabelToGender}
                  placeholder='Gender'
                  options={genderOptions}
                  onChange={(e) => handleChangeSelect('gender', e)}
                  className='general-rules-input'
                  error={fields.gender.error}
                  errorMessage='The field gender is required'
                />
                {fields.gender.value === 'MALE' && (
                  <S.ElementCheckboxWrapper>
                    <InputCheckbox
                      id='allowGirlBoysOnAnotherDivision'
                      name='allowGirlBoysOnAnotherDivision'
                      checked={allowGirlBoysOnAnotherDivision}
                      onChange={() =>
                        setAllowGirlBoysOnAnotherDivision(
                          !allowGirlBoysOnAnotherDivision
                        )
                      }
                    />
                    <BodyM>Allow girls to play on male division</BodyM>
                  </S.ElementCheckboxWrapper>
                )}
                {fields.gender.value === 'COED' && (
                  <S.ElementCheckboxWrapper>
                    <InputCheckbox
                      id='rulesForCoed'
                      name='rulesForCoed'
                      checked={rulesForCoed}
                      onChange={() => setRulesForCoed(!rulesForCoed)}
                    />
                    <BodyM>Define rules for COED</BodyM>
                  </S.ElementCheckboxWrapper>
                )}
                {rulesForCoed && fields.gender.value === 'COED' && (
                  <InputText
                    id='coedRules'
                    placeholder='Describe Rules'
                    label='Describe Rules'
                    defaultValue={fields.coedRules.value}
                    onChange={handleChangeInputValue}
                    error={fields.coedRules.error}
                  />
                )}
                <InputColor
                  id='color'
                  showHex={false}
                  placeholder='Pick a color'
                  defaultColor={fields.color.value}
                />
                <InputText
                  id='targetNumberOfTeams'
                  placeholder='Target number of teams'
                  label='Target number of teams'
                  defaultValue={
                    status === 'editing'
                      ? fields.targetNumberOfTeams.value
                      : undefined
                  }
                  onChange={handleChangeInputValue}
                  className='general-rules-input'
                  type='number'
                  min={1}
                  error={fields.targetNumberOfTeams.error}
                  errorMessage='The field target number of teams is required'
                />

                <S.ElementCheckboxWrapper>
                  <InputCheckbox
                    id='lockDivision'
                    name='lockDivision'
                    checked={fields.lockDivision.value}
                    onChange={() =>
                      setFields((newFields) => ({
                        ...newFields,
                        lockDivision: {
                          ...newFields.lockDivision,
                          value: !newFields.lockDivision.value,
                        },
                      }))
                    }
                  />
                  <BodyM>Lock this division when you hit the target goal</BodyM>
                </S.ElementCheckboxWrapper>
              </fieldset>
            ) : (
              <S.DivisionsWrapper>
                <ModalCard
                  stripeColor={currentDivision?.color}
                  title={currentDivision?.name ?? ''}
                  description={
                    `${currentDivision?.gender} - ${
                      currentDivision?.years?.[0]
                    }${
                      currentDivision?.allowGirlBoysOnAnotherDivision
                        ? ' - Allow Girls'
                        : ''
                    }` ?? ''
                  }
                  size='small'
                  orientation='row'
                />
              </S.DivisionsWrapper>
            )}
            <S.ButtonWrapper role='group' aria-labelledby='form-fieldset'>
              <FilledButton isLoading={isLoading} onClick={validate}>
                {buttonLabel} division
              </FilledButton>
            </S.ButtonWrapper>
          </>
        )
      )}
    </S.DivisionsForm>
  );
};

export default withRouter(Divisions);
