import { useQuery } from '@tanstack/react-query';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import EventService from 'services/v3/Event/EventService';

type UseGetEventInvitesProps = {
  id: string,
  type: EventInvitesTypes,
};

export const useGetEventInvites = ({ id, type }: UseGetEventInvitesProps) => {
  const response = useQuery(['event-invites', id, type], () =>
    EventService.getEventInvites(id, type)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
