import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;

interface ImageProps {
  $image: string;
  $selected: boolean;
}

export const Image =
  styled.div <
  ImageProps >
  `
  display: flex;
  width: 160px;
  height: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 139px;
  ${(props) =>
    props.$selected
      ? `border: 6px solid ${COLORS.brandPrimary}`
      : 'border: transparent'};
  background: url(${(props) => props.$image}), lightgray 50%;
  background-size: cover;
  background-position: center;
`;
