import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';

import EventService from 'services/v3/Event/EventService';

export function useGetDiscoverEvents(size = 10, page = 0) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  return useQuery(['get-discover-events', accountId], () => {
    return EventService.getActiveEventsPaged(size, page);
  });
}
