import React, { useEffect, useMemo } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import { Plus, More } from '@icon-park/react';

import { EventListView } from 'admin/models/event/Event';
import EventService from 'services/v3/Event/EventService';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import EventCard from 'components/v3/Cards/EventCard/EventCard';
import Loading from 'components/v3/Loading/Loading';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

export const DiscoverEvents = () => {
  const fetchEvents = async ({ pageParam }: QueryFunctionContext) => {
    const response = await EventService.getActiveEventsPaged(10, pageParam);

    return response.data;
  };

  const {
    data: content,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['discover-events'],
    queryFn: fetchEvents,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  });

  const eventCardsList = useMemo(() => {
    const events: EventListView[] = [];

    content?.pages.forEach((page) => {
      events.push(...page.content);
    });

    return events;
  }, [content]);

  return (
    <S.EventsList>
      {status === 'loading' && <Loading />}
      {eventCardsList.map((item) => (
        <EventCard
          size='small'
          key={item.id}
          id={item.id}
          logo={item.logo}
          title={item.name}
          orientation='column'
          actionText='See event'
          eventStatus={item.draft ? 'draft' : 'published'}
          description={item.description}
          startDate={item.startDate}
          finalDate={item.endDate}
          link={`/v3/event/${item.id}`}
        />
      ))}
      {!eventCardsList.length && status !== 'loading' && (
        <BodyM>Events coming soon</BodyM>
      )}
      {hasNextPage && (
        <S.ButtonLoadMore>
          <OutlinedButtonWithIcon
            reverse
            customIcon={isFetchingNextPage ? <More /> : <Plus />}
            disabled={!hasNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More Events
          </OutlinedButtonWithIcon>
        </S.ButtonLoadMore>
      )}
    </S.EventsList>
  );
};
