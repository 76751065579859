import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { setCurrentUser } from 'redux/v3/currentUser/actions';
import {
  usersFetchError,
  usersFetchRequest,
  usersFetchSuccess,
} from 'redux/v3/users/actions';
import UserService from 'services/v3/User/UserService';

export function useGetProfiles() {
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  useQuery(
    ['profiles'],
    () => {
      dispatch(usersFetchRequest());
      return UserService.getProfiles();
    },
    {
      onSuccess: ({ data }) => {
        const newUsers = [...(data?.baseUsers || []), ...(data?.users || [])];

        if (isEmpty(currentUser)) {
          dispatch(setCurrentUser(newUsers[0]));
        } else {
          const updatedUser = newUsers.find((u) => u.id === currentUser.id);

          if (updatedUser) {
            dispatch(setCurrentUser(updatedUser));
          }
        }

        dispatch(usersFetchSuccess(newUsers));
      },
      // TODO: Verificar como o backend retorna o erro
      onError: () => {
        dispatch(usersFetchError('An error occurred'));
      },
    }
  );
}
