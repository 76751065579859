import styled from 'styled-components';

import { COLORS, BodyM } from 'styles/v3/variables';

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 56px 64px;
  margin-top: 28px;
  border-radius: 24px;
  background-color: ${COLORS.grey950};
`;

export const TextPlaceholder = styled.p`
  display: flex;
  justify-content: center;
  margin-bottom: 44px;
  font-family: 'Boxed Medium';
  font-size: 25px;
  color: ${COLORS.grey200};
`;

export const TeamsWrapper = styled.div`
  display: flex;
  grid-column-gap: 24px;
  padding-bottom: 24px;
`;

export const MembersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(213px, 1fr));
  gap: 24px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #4b4d46;
`;

export const MembersEmptyState = styled(BodyM)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #4b4d46;
`;

export const EmptyState = styled(BodyM)`
  display: flex;
  justify-content: center;
  width: 100%;
`;
