import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  padding: ${toRem(20)};

  justify-content: space-between;
  align-items: center;

  background-color: ${COLORS.grey900};
  border-radius: ${toRem(16)};
  margin-top: ${toRem(16)};
`;

export const ContainerInformationDivision = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(16)};
  flex-wrap: wrap;
`;

export const AvatarEventInformation = styled.div`
  display: flex;
  padding: ${toRem(8)} ${toRem(16)} ${toRem(8)} ${toRem(8)};
  align-items: center;

  background-color: ${COLORS.grey800};
  border-radius: ${toRem(121)};
  gap: ${toRem(8)};
`;

export const EventImage = styled.div`
  width: ${toRem(24)};
  height: ${toRem(24)};
  border-radius: ${toRem(24)};

  margin: 0 auto;
  overflow: hidden;

  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;

    width: ${toRem(24)};
    height: ${toRem(24)};
  }
`;
