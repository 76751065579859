import React, { FC } from 'react';

import { Item } from './components/Item/Item';

import { DocumentStatusFieldProps } from './types';

import * as S from './styles';

export const DocumentStatus: FC<DocumentStatusFieldProps> = ({
  ageStatus,
  safeSportStatus,
  photoStatus,
  permission,
}) => {
  return (
    <S.Container>
      <Item name='PH' permission={permission} status={photoStatus} />
      <Item name='AV' permission={permission} status={ageStatus} />
      <Item name='SS' permission={permission} status={safeSportStatus} />
    </S.Container>
  );
};
