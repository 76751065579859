import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Wrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: ${toRem(12)};
  overflow-y: auto;
  width: 588px;
  height: 333px;
  font-family: 'Boxed Regular';
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.grey950};
  padding: ${toRem(24)};
  margin-bottom: 1.5rem;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;
export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    margin-left: ${toRem(12)};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;
export const InfoContainer = styled.div`
  width: 100%;
  border-radius: 1.5rem;
  padding: ${toRem(24)};
  background-color: ${COLORS.grey900};
`;
