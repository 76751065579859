import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.aside<{ schedule: boolean }>`
  width: 15%;

  ${({ schedule }) =>
    schedule &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 200px;
      z-index: 20;
    `}
`;

export const Title = styled.h2`
  color: ${COLORS.white};
  font-family: 'UF Display';
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 105%;
`;

export const Subtitle = styled.p`
  color: ${COLORS.grey500};
  font-family: 'Boxed Medium';
  margin: 32px 0px 12px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 120%;
`;

export const TabItem = styled.li<{
  $selected: boolean;
  $status: 'none' | 'warn' | 'ok';
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: ${toRem(8)} ${toRem(12)};
  :not(:last-child) {
    margin-bottom: 4px;
  }

  border-radius: 12px;

  ${(props) => (props.$selected ? `background: ${COLORS.grey900};` : '')}

  cursor: ${(props) => (props.$selected ? 'default' : 'pointer')};
  color: ${(props) =>
    props.$status === 'warn' ? COLORS.supportError : COLORS.white};

  font-family: 'Boxed Medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

  span {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.$status === 'ok' ? `color: ${COLORS.brandPrimary};` : ''}
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  font-family: 'Boxed Medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
`;
