import React, { FunctionComponent, useMemo } from 'react';
import { COLORS } from 'styles/v3/variables';
import { BracketMatchDTO } from 'models/Bracket/BracketModel';
import { BracketRenderProps } from '../../BracketRender';
import { BracketColumn } from '../../styles';
import { BracketGame, SingleItemBracketGame } from '../BracketGame';

export const SingleElimination4Render: FunctionComponent<BracketRenderProps> = ({
  bracketId,
  matches,
  handleOpenEditModal,
  onUpdate,
}) => {
  const [column1, column2] = useMemo(() => {
    const columns: BracketMatchDTO[][] = [[], []];

    matches.forEach((match) => {
      if (match.level === 0) {
        columns[0].push(match);
      } else if (match.level === 1) {
        columns[1].push(match);
      }
    });

    return columns;
  }, [matches]);

  return (
    <>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={column1[0]}
          to={{
            color: COLORS.brandPrimary,
            down: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          showPens
          titleSourceEmpty='TBA'
          onUpdate={onUpdate}
        />
        <BracketGame
          bracketId={bracketId}
          match={column1[1]}
          to={{
            color: COLORS.brandPrimary,
            up: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          showPens
          titleSourceEmpty='TBA'
          onUpdate={onUpdate}
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={column2[0]}
          to={{
            color: COLORS.brandPrimary,
            side: true,
          }}
          from={{
            up: true,
            down: true,
            color: COLORS.brandPrimary,
          }}
          handleOpenEditModal={handleOpenEditModal}
          onUpdate={onUpdate}
        />
      </BracketColumn>
      <BracketColumn>
        <SingleItemBracketGame
          title='Winner'
          from={{ side: true, color: COLORS.brandPrimary }}
        />
      </BracketColumn>
    </>
  );
};
