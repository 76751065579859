import React from 'react';
import { Avatar } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { BodyLBold, BodyM, BodyS } from 'styles/v3/variables';
import ConvertUtil from 'util/ConvertUtil';
import { Check } from '@icon-park/react';
import { truncateText } from 'util/truncateText';
import * as S from './styles';

type CardProps = {
  id?: string,
  name?: string,
  year?: string,
  logoSrc?: string,
  address?: string,
  selected: boolean,
  onSelect: (id: string) => void,
  extraBasicInfo?: React.ReactNode,
  extraAddressInfo?: React.ReactNode,
};

export const Card = ({
  id,
  name,
  year,
  address,
  logoSrc,
  selected,
  onSelect,
  extraBasicInfo = null,
  extraAddressInfo = null,
}: CardProps) => {
  const handleClick = () => {
    if (id) onSelect(id);
  };

  return (
    <S.Container isSelected={selected} onClick={handleClick}>
      <S.Flex gap={10} alignItems='center'>
        {selected && (
          <S.Flex>
            <Check size={20} />
          </S.Flex>
        )}
        <S.Flex>
          <Avatar
            size={62}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(logoSrc)}
          />
        </S.Flex>
      </S.Flex>
      <S.InfoWrapper>
        <S.BasicInfoWrapper>
          <S.NameWrapper>
            <BodyLBold>{name && truncateText(name, 20)}</BodyLBold>
            {year && <BodyS $color='grey400'>{year}</BodyS>}
          </S.NameWrapper>
          {extraBasicInfo}
        </S.BasicInfoWrapper>
        <S.AddressWrapper>
          {extraAddressInfo}
          <BodyM $color='grey400'>{address}</BodyM>
        </S.AddressWrapper>
      </S.InfoWrapper>
    </S.Container>
  );
};
