import React, { useState } from 'react';

import { CloseSmall, Info } from '@icon-park/react';
import { BodyM, BodyMBold, COLORS } from 'styles/v3/variables';

import { EventTierCard } from 'components/v3/Cards/EventTierCard/EventTierCard';
import { EventMultipliers } from 'admin/models/event/Event';
import { Multiplier } from './Multiplier/Multiplier';

import * as S from './styles';

type MultiplierInfoModalProps = {
  multiplier?: number,
  multipliers?: EventMultipliers[],
};
interface MultiplierForModal {
  title: string;
  totalValue: number;
}

const MULTIPLIERS: MultiplierForModal[] = [
  {
    title: 'Clocks',
    totalValue: 2,
  },
  {
    title: 'Court',
    totalValue: 1,
  },
  {
    title: 'Matches',
    totalValue: 3,
  },
  {
    title: 'Sanctioned Refs',
    totalValue: 3,
  },
  {
    title: 'Game Slots',
    totalValue: 2,
  },
];

export const MultiplierInfoModal = ({
  multiplier,
  multipliers,
}: MultiplierInfoModalProps) => {
  const [isMultiplierModalOpen, setIsMultiplierModalOpen] = useState(false);

  return (
    <S.Container>
      <Info
        size={24}
        fill={COLORS.grey400}
        onClick={() => setIsMultiplierModalOpen(true)}
      />
      <S.StyledModal
        centered
        footer={false}
        closable={false}
        visible={isMultiplierModalOpen}
        onOk={() => setIsMultiplierModalOpen(false)}
        onCancel={() => setIsMultiplierModalOpen(false)}
      >
        <S.Container>
          <S.HeaderWrapper>
            <BodyM $color='grey50'>Point Multiplier</BodyM>
            <S.CloseButton onClick={() => setIsMultiplierModalOpen(false)}>
              <CloseSmall size='24px' />
            </S.CloseButton>
          </S.HeaderWrapper>
          <BodyM $color='grey200'>
            The <BodyMBold>Point Multipliers </BodyMBold>represents how much
            times the points gained in the event will be multiplied. Every event
            starts with 1x and the multipliers are added. This helps you
            understand how the event is going to be.
          </BodyM>
          <S.MultiplierWrapper>
            {multipliers?.map((item) => {
              const maxDots = MULTIPLIERS.find(
                (multplInfo) => item.title === multplInfo.title
              )?.totalValue;
              return (
                <Multiplier
                  key={item.title}
                  name={item.title}
                  description={item.textContent}
                  multiplier={item.multiplierValue || 0}
                  maxDots={maxDots}
                />
              );
            })}
          </S.MultiplierWrapper>
        </S.Container>
        <EventTierCard multiplier={multiplier ?? 1} />
      </S.StyledModal>
    </S.Container>
  );
};
