import { HeadsetOne, SoccerOne, UserBusiness, People } from '@icon-park/react';
import UserType from 'models/User/UserTypeModel';

export const allowedRoles = [
  {
    title: 'Coach',
    name: UserType.COACH,
    icon: HeadsetOne,
    slug: 'coach',
    selected: false,
  },
  {
    title: 'Player',
    name: UserType.PLAYER,
    icon: SoccerOne,
    slug: 'player',
    selected: false,
  },
  {
    title: 'Team Manager',
    name: UserType.TEAM_MANAGER,
    icon: People,
    slug: 'team-manager',
    selected: false,
  },
  {
    title: 'Club Director',
    name: UserType.CLUB_DIRECTOR,
    icon: UserBusiness,
    slug: 'club-director',
    selected: false,
  },
];
