import { useQuery } from '@tanstack/react-query';
import MatchService from 'services/v3/Match/MatchService';

export function useGetDetailedEventMatches(eventId?: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-detailed-event-matches', eventId],
    async () => await MatchService.getDetailedEventMatches(eventId)
  );

  return {
    httpCode: data?.httpCode,
    ...data,
    data: data?.data ?? [],
    isLoading,
    error,
  };
}
