import moment from 'moment';

import { SoccerPeriod } from 'services/v3/Match/enums';

import { NORMAL_TIME } from './components/Scoresheet/config';
import { IMatchInfo } from './components/Scoresheet/interfaces';

export const PERIOD_ORDER = [
  SoccerPeriod.PENALTY_SHOOTOUT,
  SoccerPeriod.SECOND_EXTRA_TIME,
  SoccerPeriod.FIRST_EXTRA_TIME,
  SoccerPeriod.SECOND_HALF,
  SoccerPeriod.FIRST_HALF,
];

export const DEFAULT_MATCH_INFO_VALUE: IMatchInfo = {
  time: moment('00:00', 'mm:ss').add(NORMAL_TIME, 'seconds'),
  soccer: SoccerPeriod.FIRST_HALF,
  playGame: false,
};
