import React from 'react';

import { BodyS, COLORS } from 'styles/v3/variables';
import { Attention, CheckSmall } from '@icon-park/react';
import { useGroups } from 'pages/V3/EventManager/components/Groups/context/GroupsContext';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import { getColorByNumberOfTeam } from 'pages/V3/EventManager/components/Groups/utils';
import * as S from './styles';

type DivisionButtonType = NewAgeDivision & {
  targetColor: 'grey100' | 'brandPrimary' | 'supportError',
};

export const DivisionButton = ({ ...rest }: DivisionButtonType) => {
  const { id, name, pools, targetNumberOfTeams } = rest;

  const { selectedDivision, setSelectedDivision } = useGroups();

  const isSelected = id === selectedDivision?.id;

  const countAllTeams =
    pools?.reduce((total, pool) => total + (pool.teams?.length || 0), 0) || 0;

  const targetColor = getColorByNumberOfTeam(
    countAllTeams,
    targetNumberOfTeams
  );

  return (
    <S.Container
      isSelected={isSelected}
      onClick={() => setSelectedDivision(rest)}
    >
      <BodyS
        $isUpper
        $color={targetColor === 'supportError' ? targetColor : 'white'}
      >
        {name}
      </BodyS>

      {targetColor === 'supportError' ? (
        <Attention style={{ color: COLORS.supportError }} size={20} />
      ) : (
        targetColor === 'brandPrimary' && (
          <CheckSmall style={{ color: COLORS.brandPrimary }} size={20} />
        )
      )}
    </S.Container>
  );
};
