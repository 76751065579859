import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { User } from '../../models/User/UserModel';
import UserType from '../../models/User/UserTypeModel';
import { myClubsFetchRequest } from '../../redux/club/actions';
import { ApplicationState } from '../../redux/store';
import { userFetchRequest, userUpdateActive } from '../../redux/user/actions';
import { DOWNLOAD_STORAGE_ENDPOINT } from '../../services/config.api';
import { color, font } from '../../styles/styleVariables';
import TokenUtil from '../../util/TokenUtil';
import { getUserId, setUserId } from '../../util/UsersUtil';

const MobileMenu: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [openPlayers, setOpenPlayers] = useState(false);
  const userData = useSelector((state: ApplicationState) => state.account);
  const user = useSelector((state: ApplicationState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const userId = getUserId();
    if (userId) {
      setUserId(userId);
    }
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
  };
  const toggleMenuPlayers = () => {
    setOpenPlayers(!openPlayers);
  };

  const handleClickToNewVault = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    window.location.href = `${window.location.protocol}//${window.location.host}/v3/home-page`;
  };

  const selectUser = (userId: string) => {
    // TODO: implement Redux to auto refresh components
    const userTemp: User | undefined = userData.data.accounts?.find(
      (account) => account.id === userId
    );
    setUserId(userId);
    if (userId) {
      dispatch(userFetchRequest(userId));
    }
    if (userTemp) {
      setOpenPlayers(false);
      dispatch(userUpdateActive(userTemp));
      dispatch(myClubsFetchRequest());
      TokenUtil.refreshToken();
      history.push('/home');
    }
  };
  return (
    <MobileMenuWrapper
      countSubItens={
        userData.data.accounts?.length ? userData.data.accounts?.length : 0
      }
    >
      <div className={`menu-content ' ${open ? 'opened' : ''}`}>
        <ul>
          <li>
            <Link to='/home'>Home</Link>
          </li>
          {user.data.type !== UserType.CLUB_DIRECTOR && (
            <li>
              <Link to='/my-club'>My Club</Link>
            </li>
          )}
          {user.data.type === UserType.CLUB_DIRECTOR && (
            <li>
              <Link to='/club-director'>Club Director</Link>
            </li>
          )}

          {user.data.type === UserType.STAFF && (
            <li>
              <Link to='/admin-panel/tournaments' className='link'>
                My Tournaments
              </Link>
            </li>
          )}
          {user.data.type === UserType.STAFF && (
            <li>
              <Link to='/admin-panel'>Admin Panel</Link>
            </li>
          )}
          <li>
            <Link to='/ranking' className='link'>
              Ranking
            </Link>
          </li>
          <li>
            <a
              href='/v3/home-page'
              className='link'
              onClick={handleClickToNewVault}
            >
              New Vault
            </a>
          </li>
          {user.data.type !== UserType.CLUB_DIRECTOR && (
            <li>
              <Link to='/membership-upgrade' className='text-orange'>
                Upgrade Membership
              </Link>
            </li>
          )}

          <li>
            <Link to='/logout'>logout</Link>
          </li>
        </ul>
      </div>
      <div className='menu-bottom'>
        <div
          role='button'
          tabIndex={0}
          className='left-bt'
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
        >
          <img alt='' src='/assets/imgs/icons/ico_menu.svg' />
        </div>
        <div className='middle-bt'>
          {/* <div className='avatar'></div>
          <div>PLAYER</div> */}
        </div>

        <div
          className='right-bt'
          style={{
            backgroundImage: 'url(/assets/imgs/icons/ico_manage.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '30%',
          }}
        >
          <NavLink to='my-settings' activeClassName='active'>
            <div style={{ width: '100%', height: '100%' }} />
          </NavLink>
        </div>

        <div
          className={`players-list styled-scrollbar ${
            openPlayers ? 'opened' : ''
          }`}
        >
          <div
            className='item'
            onClick={() => toggleMenuPlayers()}
            onKeyDown={() => toggleMenuPlayers()}
            role='button'
            aria-label=''
            tabIndex={0}
          >
            <div className='avatar-box'>
              <div
                className='avatar'
                style={{
                  backgroundImage: `url(${DOWNLOAD_STORAGE_ENDPOINT}${user.data.photo})`,
                }}
              />
            </div>
            <div className='name'>{user.data.firstName}</div>
          </div>

          {userData.data?.accounts
            ?.filter((account) => account.id !== getUserId())
            .map((userItem) => {
              return (
                <div
                  key={userItem.id}
                  className='item'
                  onClick={() => selectUser(userItem.id)}
                  onKeyDown={() => selectUser(userItem.id)}
                  role='button'
                  aria-label=''
                  tabIndex={0}
                >
                  <div className='avatar-box'>
                    <div
                      className='avatar'
                      style={{
                        backgroundImage: `url(${DOWNLOAD_STORAGE_ENDPOINT}${userItem.photo})`,
                      }}
                    />
                  </div>
                  <div className='name'>{userItem.firstName}</div>
                </div>
              );
            })}
          <div
            className='item'
            onClick={() => history.push('/wizard')}
            onKeyDown={() => history.push('/wizard')}
            role='button'
            aria-label=''
            tabIndex={0}
          >
            <div className='name' style={{ width: '100%' }}>
              + ADD NEW USER
            </div>
          </div>
        </div>
      </div>
    </MobileMenuWrapper>
  );
};

const MobileMenuWrapper =
  styled.div <
  { countSubItens: number } >
  `
  @media (min-width: 769px) {
    display: none;
  }
  .menu-content {
    width: 70%;
    height: 100%;
    position: fixed;
    background-color: ${color.greyDark};
    z-index: 50;
    left: -70%;
    transition: all 0.5s;
    border-right: solid 1px ${color.orange};
    overflow: auto;
    &.opened {
      left: 0;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    ul li {
      font-family: ${font.druk};
      text-transform: uppercase;
      padding: 20px 30px;
      font-size: 11px;
      &:hover {
        background-color: ${color.orange};
        color: ${color.greyDark};
      }
    }
  }
  .menu-bottom {
    height: 60px;
    width: 100%;
    background-color: ${color.greyDark};
    position: fixed;
    display: flex;
    bottom: 0;
    z-index: 50;
    .left-bt,
    .right-bt {
      width: 20%;
      padding: 17px;
      text-align: center;
      background-color: ${color.greyDark};
      z-index: 10;
      cursor: pointer;
      img {
        max-width: 30px;
        width: 100%;
      }
    }
    .middle-bt {
      width: 60%;
      padding: 20px 0;
      text-align: center;
      font-family: ${font.druk};
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      z-index: 1;
      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 25px;
      }
      .name {
        width: 80%;
        text-align: center;
        font-family: ${font.druk};
      }
    }
    .players-list {
      position: absolute;
      width: 70%;
      background-color: ${color.orange};
      z-index: 5;
      bottom: ${(props) =>
        props.countSubItens ? `${props.countSubItens * -60}px` : '-1000px'};
      border-radius: 20px 20px 0px 0px;
      overflow: hidden;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition: all 0.5s;
      &.opened {
        bottom: 0;
        max-height: 90vh;
        overflow-y: auto;
        .item {
          &:first-child {
            .name {
              &:after {
                content: '▲';
              }
            }
          }
        }
      }
      .item {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 10px 50px;
        cursor: pointer;
        background-color: ${color.greyLight};
        border-bottom: solid 1px ${color.silver};
        &:hover {
          background-color: ${color.silver};
        }
        &:first-child {
          padding-top: 25px;
          height: 75px;
          background-color: ${color.orange};
          .name {
            color: ${color.greyDark};
            &::after {
              font-size: 10px;
              content: '▼';
              height: 10px;
              width: 10px;
              top: -1px;
              position: absolute;
              margin-left: 10px;
            }
          }
        }
        .avatar-box {
          width: 20%;
          .avatar {
            height: 40px;
            width: 40px;
            border-radius: 25px;
            margin: 0 auto;

            background-size: cover;
          }
        }
        .name {
          width: 80%;
          text-align: center;
          font-size: 10px;
          font-family: ${font.druk};
          //line-height: 40px;
          text-transform: uppercase;
          color: #fff;
          position: relative;
        }
      }
    }
  }
`;
export default MobileMenu;
