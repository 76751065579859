import { useQuery } from '@tanstack/react-query';
import StatsService from 'services/v3/Stats/StatsService';

export function useGetUserStats(userId: string) {
  const { data, isLoading, error } = useQuery(
    ['/get-user-stats', userId],
    async () => await StatsService.getUserStats({ userIdList: [userId] })
  );
  const content = data?.data?.content;
  const stats = content?.length ? content[0] : undefined;
  return {
    ...data,
    data: stats,
    isLoading,
    error,
  };
}

export function useGetUsersStats(userIds: string[], enabled = true) {
  const { data, isLoading, error } = useQuery(
    ['/get-users-stats', userIds],
    async () => await StatsService.getUserStats({ userIdList: userIds }),
    {
      enabled,
    }
  );
  const content = data?.data?.content;
  const stats = content?.length ? content : [];
  return {
    ...data,
    data: stats,
    isLoading,
    error,
  };
}
