import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 ${toRem(120)};

  display: flex;
  gap: 24px;
`;

export const MainContainer = styled.main`
  flex: 1;
  background: ${COLORS.grey950};
  border-radius: 24px;
  padding: 24px;
  height: 100%;
  min-width: 0;
  width: 100%;
`;

export const MainHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const MainBody = styled.div`
  height: 100%;
  padding: 24px 0;
  overflow-y: auto;
`;

export const MainHeaderTitle = styled.h4`
  color: ${COLORS.grey100};
  font-family: 'UF Display';
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
`;

export const MainHeaderTitleDetail = styled.p`
  color: ${COLORS.grey300};
  font-family: 'Boxed Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

  margin: 0;
  margin-left: 24px;
`;

export const MainHeaderCTA = styled.div`
  color: ${COLORS.brandPrimary};
  font-family: 'Boxed Regular Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;

  margin-left: auto;
  cursor: pointer;
`;
