import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const SideModalContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: ${COLORS.grey850};
  display: flex;
  flex-direction: column;
`;

export const SideModalHeader = styled.header`
  padding: 24px 24px 36px;

  h4 {
    color: ${COLORS.grey100};
    font-family: 'UF Display';
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const SideModalMain = styled.div`
  padding-left: 26px;
  min-height: 0;
  max-height: fit-content;

  flex: 1;
  display: flex;
`;

export const SideModalMenuItem = styled.div<{
  $selected?: boolean;
  $disabled?: boolean;
}>`
  padding: 12px;
  margin-bottom: 4px;
  border-radius: 12px;
  cursor: not-allowed;
  cursor: ${({$selected, $disabled}) => {
    if($disabled) return 'not-allowed'
    if(!$selected) return 'pointer'
    return 'default'
  }};
  color: ${({$disabled}) => $disabled ?  COLORS.grey700 : COLORS.white};
  font-family: 'Boxed Medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  background: ${({ $selected }) => $selected ? COLORS.grey900 : 'transparent'};
`;

export const SideModalList = styled.div`
  display: grid;
  max-height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 46px 12px;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 214px;
`;

export const SideModalListItem = styled.div<{
  $selected?: boolean;
}>`
  border: 2px solid
    ${({ $selected }) => ($selected ? COLORS.brandPrimary : 'transparent')};
  width: 214px;
  height: 214px;
  border-radius: 16px;
  background: ${COLORS.grey950};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  p {
    font-family: 'Boxed Medium';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    margin: 0 12px;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const SideModalFooter = styled.footer`
  padding: 16px 24px;
  border-top: 1px solid ${COLORS.grey800};
  background: ${COLORS.grey900};
  display: flex;
  justify-content: flex-end;

  button {
    color: ${COLORS.grey50};
  }
`;
