import styled from 'styled-components';
import { toRem } from './functions';

export interface ColorsInterface {
  brandPrimary: string;
  brandSecondary: string;
  brandTertiary: string;
  supportError: string;
  supportNeutral: string;
  greenFull: string;
  shadowBrandPrimary: string;
  pink: string;
  blue: string;
  grey1000: string;
  grey970: string;
  grey950: string;
  grey900: string;
  grey850: string;
  grey800: string;
  grey700: string;
  grey600: string;
  grey500: string;
  grey400: string;
  grey300: string;
  grey200: string;
  grey100: string;
  grey50: string;
  white: string;
  bronzeTier: string;
  silverTier: string;
  goldTier: string;
  platinumTier: string;
  diamondTier: string;
  green: string;
}

export interface FontWeightInterface {
  '700': string;
  '500': string;
  '400': string;
}

// B2b colors
export const COLORS: ColorsInterface = {
  // brand colors
  brandPrimary: '#CAFC01',
  brandSecondary: '#9747FF',
  brandTertiary: '#D60D62',
  supportError: '#FA5656',
  supportNeutral: '#68E4FF',
  pink: '#FA29C0',
  blue: '#29FAEE',
  greenFull: '#539E4C',
  shadowBrandPrimary: '#CAFC0114',
  // grey scale
  grey1000: '#1B1C19',
  grey970: '#262721',
  grey950: '#252622',
  grey900: '#363731',
  grey850: '#42433C',
  grey800: '#52544C',
  grey700: '#62645B',
  grey600: '#7D7F7A',
  grey500: '#8F918A',
  grey400: '#A7A8A1',
  grey300: '#BDBEB8',
  grey200: '#D3D4D0',
  grey100: '#E9E9E7',
  grey50: '#FBFBFA',
  white: '#FFFFFF',
  bronzeTier: '#C6826D',
  silverTier: '#F1F2F2',
  goldTier: '#F7D29B',
  diamondTier: '#65FBFE',
  platinumTier: '#24A3F9',
  green: '#00cc00',
};

// Title tags
export const TitleH1 = styled.h1<{
  $color?: keyof ColorsInterface;
  $spanColor?: keyof ColorsInterface;
  $isUpper?: boolean;
}>`
  color: ${(props) => (props.$color ? COLORS[props.$color] : 'inherit')};
  font-family: 'UF Display';
  font-size: 61px;
  line-height: 64px;
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};
  span {
    color: ${(props) =>
      props.$spanColor ? COLORS[props.$spanColor] : 'inherit'};
  }
`;
export const TitleH2 = styled.h2<{
  $color?: keyof ColorsInterface;
  $spanColor?: keyof ColorsInterface;
  $isUpper?: boolean;
}>`
  color: ${(props) => (props.$color ? COLORS[props.$color] : 'inherit')};
  font-family: 'UF Display';
  font-size: ${toRem(49)};
  line-height: 51px;
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};
  span {
    color: ${(props) =>
      props.$spanColor ? COLORS[props.$spanColor] : 'inherit'};
  }
`;
export const TitleH3 = styled.h3<{
  $color?: keyof ColorsInterface;
  $spanColor?: keyof ColorsInterface;
  $isUpper?: boolean;
}>`
  color: ${(props) => (props.$color ? COLORS[props.$color] : 'inherit')};
  font-family: 'UF Display';
  font-size: 39px;
  line-height: 40px;
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};
  span {
    color: ${(props) =>
      props.$spanColor ? COLORS[props.$spanColor] : 'inherit'};
  }
`;
export const TitleH4 = styled.h4<{
  $color?: keyof ColorsInterface;
  $spanColor?: keyof ColorsInterface;
  $isUpper?: boolean;
}>`
  color: ${(props) => (props.$color ? COLORS[props.$color] : 'inherit')};
  font-family: 'UF Display';
  font-size: 31px;
  line-height: 32px;
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};
  span {
    color: ${(props) =>
      props.$spanColor ? COLORS[props.$spanColor] : 'inherit'};
  }
`;

// Text sizes
const TagP = styled.p<{
  $color?: keyof ColorsInterface;
  $spanColor?: keyof ColorsInterface;
  $isUpper?: boolean;
  $fontWeight?: keyof FontWeightInterface;
}>`
  color: ${(props) => (props.$color ? COLORS[props.$color] : 'inherit')};
  margin: 0;
  text-transform: ${(props) => (props.$isUpper ? 'uppercase' : 'none')};
  font-weight: ${(props) => props.$fontWeight};

  p {
    margin: 0 !important;
    display: initial;
  }

  span {
    color: ${(props) =>
      props.$spanColor ? COLORS[props.$spanColor] : 'inherit'};
  }
`;

export const BodyXL = styled(TagP)`
  font-family: 'Boxed Regular';
  font-size: 25px;
  line-height: 30px;
`;
export const BodyXLBold = styled(TagP)`
  font-family: 'Boxed Regular Bold';
  font-size: 25px;
  line-height: 30px;
`;
export const BodyL = styled(TagP)`
  font-family: 'Boxed Regular';
  font-size: 20px;
  line-height: 24px;
`;
export const BodyLBold = styled(TagP)`
  font-family: 'Boxed Regular Bold';
  font-size: 20px;
  line-height: 24px;
`;
export const BodyM = styled(TagP)`
  font-family: 'Boxed Regular';
  font-size: 16px;
  line-height: 19px;
`;
export const BodyMBold = styled(TagP)`
  font-family: 'Boxed Regular Bold';
  font-size: 16px;
  line-height: 19px;
`;
export const BodyS = styled(TagP)`
  font-family: 'Boxed Regular';
  font-size: 13px;
  line-height: 15px;
`;
export const BodySBold = styled(TagP)`
  font-family: 'Boxed Regular Bold';
  font-size: 13px;
  line-height: 15px;
`;
export const BodyXS = styled(TagP)`
  font-family: 'Boxed Regular';
  font-size: 10px;
  line-height: 12px;
`;
export const BodyXSBold = styled(TagP)`
  font-family: 'Boxed Regular Bold';
  font-size: 10px;
  line-height: 12px;
`;
