import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import ScheduleService from 'services/v3/Schedule/ScheduleService';

const usePublishSchedule = (eventId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (published: boolean) => {
      await ScheduleService.publishSchedule(eventId, published);
    },
    onSuccess: async () => {
      notification.success({
        message: 'Schedule saved successfully!',
      });

      await queryClient.invalidateQueries([
        'get-schedule-by-event-id',
        eventId,
      ]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};

export default usePublishSchedule;
