import { NewAgeDivision } from 'admin/models/AgeDivision';

import { AgeRulesEnumsToLabel } from 'pages/V3/setupEvent/Divisions/constants';

export const getDivisionInfo = (division?: NewAgeDivision) => {
  if (!division) return '';

  let baseInfo = `${division.gender} - ${division.years.join('/')} ${
    AgeRulesEnumsToLabel[division.rule]
  }`;

  if (division.gender === 'MALE' && division.allowGirlBoysOnAnotherDivision) {
    baseInfo = `${baseInfo} - Allow Girls`;
  }

  if (division.gender === 'COED') {
    baseInfo = `${baseInfo} - ${division.coedRules}`;
  }

  return baseInfo;
};
