import React, { FC } from 'react';
import { MatchEventModel } from 'models/Match/MatchModel';
import { BodyM, BodyXLBold } from 'styles/v3/variables';
import InputSelect from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import moment, { Moment } from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateMatchesEvent } from 'hooks/v3/matches/useUpdateMatchesEvent/useUpdateMatchesEvent';
import { GridStack } from 'gridstack';
import { MatchMainInfo } from 'services/v3/Match/types';
import { useLocation } from 'react-router-dom';
import {
  StyledBottom,
  StyledButton,
  StyledCloseSmall,
  StyledContainer,
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledTimePicker,
} from './styles';
import { CalendarCourt } from '../../types';
import { useGetGameData } from './useGetGameData';

type Option = {
  label: string,
  value: string,
};

type FormData = {
  court: Option,
  date: string,
  startTime: Moment,
  homeTeam: Option,
  awayTeam: Option,
};

interface EditGameModalProps {
  days: string[];
  dayStartTime?: string;
  courts: CalendarCourt[];
  selectedMatch: MatchEventModel;
  grid: GridStack | null;
  matchLength: number;
  divisionColors: { [key: string]: string };
  handleCloseGameEditor: () => void;
  mapPositionToTime: (position: number, stepperIntervals: number) => string;
  getHomeTeamName: (match: MatchEventModel) => string;
  getAwayTeamName: (match: MatchEventModel) => string;
  getMatchTitle: (match: MatchEventModel) => string | string[] | undefined;
}

const EditGameModal: FC<EditGameModalProps> = ({
  days,
  dayStartTime,
  courts,
  selectedMatch,
  grid,
  matchLength,
  handleCloseGameEditor,
  getHomeTeamName,
  getAwayTeamName,
  divisionColors,
  getMatchTitle,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const day = params.get('day');
  const { mutateAsync: updateSchedule } = useUpdateMatchesEvent();
  const {
    initialAwayTeam,
    clubs,
    courtsOptions,
    initialCourtSelected,
    initialHomeTeam,
    startTime,
    initialDateSelected,
    updateWidgets,
    removeWidget,
  } = useGetGameData({
    grid,
    days,
    dayStartTime,
    courts,
    selectedMatch,
    getHomeTeamName,
    getAwayTeamName,
    divisionColors,
    getMatchTitle,
  });

  // TO DO ENG-127 - move get/HomeT.../AwayT/... to utils;
  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      court: initialCourtSelected,
      date: initialDateSelected,
      startTime,
      homeTeam: initialHomeTeam,
      awayTeam: initialAwayTeam,
    },
  });

  const startTimeValue = watch('startTime');

  // ENG-127 TODO make updates with subm
  const submit = async (data: FormData) => {
    try {
      const { date, court, startTime } = data;
      if (
        !(
          moment(date) >= moment(days[0]) &&
          moment(date) <= moment(days[days.length - 1])
        )
      ) {
        setError('date', {
          message: `Invalid date. Date should be in range ${moment(
            days[0]
          ).format('YYYY-MM-DD')} - ${moment(days[days.length - 1]).format(
            'YYYY-MM-DD'
          )}`,
        });
      } else {
        const time = startTime.format('HH:mm A');
        const newDate = moment(`${date} ${time}`, 'YYYY-MM-DD h:mm A').format(
          'YYYY-MM-DDTHH:mm'
        );
        if (selectedMatch) {
          let matchToUpdateWidget = { ...selectedMatch };
          const { homeTeam, awayTeam } = data;
          let matchInfo: MatchMainInfo = {
            matchId: selectedMatch?.matchId ?? '',
            date: newDate ?? selectedMatch?.date ?? '',
            subVenue: court.value ?? selectedMatch.subVenue ?? '',
          };
          if (homeTeam?.value && homeTeam?.label) {
            matchInfo = {
              ...matchInfo,
              homeTeam: {
                poolId: selectedMatch?.pools[0]?.id,
                teamId: selectedMatch?.homePlaceHolder?.id,
                rosterId: homeTeam?.value ?? selectedMatch.homeTeam.id,
                label: homeTeam?.label ?? selectedMatch.homeTeam.clubName,
              },
            };
            !!matchInfo.homeTeam &&
              (matchToUpdateWidget = {
                ...matchToUpdateWidget,
                homeTeam: {
                  ...matchToUpdateWidget.homeTeam,
                  ...matchInfo.homeTeam,
                  clubName: matchInfo.homeTeam.label,
                },
              });
          }
          if (awayTeam?.value && awayTeam?.label) {
            matchInfo = {
              ...matchInfo,
              awayTeam: {
                poolId: selectedMatch?.pools[0]?.id,
                teamId: selectedMatch?.awayPlaceHolder?.id,
                rosterId: awayTeam?.value ?? selectedMatch.awayTeam.id,
                label: awayTeam?.label ?? selectedMatch.awayTeam.clubId,
              },
            };
            !!matchInfo.awayTeam &&
              (matchToUpdateWidget = {
                ...matchToUpdateWidget,
                awayTeam: {
                  ...matchToUpdateWidget.awayTeam,
                  ...matchInfo.awayTeam,
                  clubName: matchInfo.awayTeam.label,
                },
              });
          }
          if (moment(newDate).format('YYYY-MM-DD') === day) {
            updateWidgets(matchToUpdateWidget, newDate, court.value);
          } else {
            removeWidget(matchToUpdateWidget);
          }
          await updateSchedule([matchInfo]);
          handleCloseGameEditor();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <StyledModal>
      <StyledModalHeader>
        <BodyXLBold $color='brandPrimary'>Update Game</BodyXLBold>
        <StyledCloseSmall size='20px' onClick={handleCloseGameEditor} />
      </StyledModalHeader>

      <form onSubmit={handleSubmit(submit)} noValidate>
        <StyledModalBody>
          <StyledContainer>
            <BodyM $color='white'>Location</BodyM>
            <Controller
              control={control}
              name='court'
              render={({ value, onChange }) => (
                <InputSelect
                  placeholder='Location'
                  options={courtsOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Date</BodyM>
            <Controller
              name='date'
              control={control}
              render={({ value, onChange }) => (
                <InputDate
                  id='matchScheduledDate'
                  placeholder='Date'
                  value={value}
                  error={!!errors?.date}
                  errorMessage={errors?.date?.message ?? ''}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Start time</BodyM>
            <Controller
              name='startTime'
              control={control}
              render={({ value, onChange }) => (
                <StyledTimePicker
                  value={value}
                  use12Hours
                  format='h:mm a'
                  minuteStep={5}
                  showNow={false}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>End time</BodyM>
            <StyledTimePicker
              use12Hours
              format='h:mm a'
              minuteStep={5}
              value={moment(startTimeValue).add(matchLength / 60, 'hours')}
              disabled
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Home team</BodyM>
            <Controller
              control={control}
              name='homeTeam'
              render={({ value, onChange }) => (
                <InputSelect
                  placeholder='Home team'
                  options={clubs}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledContainer>
            <BodyM $color='white'>Away team</BodyM>
            <Controller
              control={control}
              name='awayTeam'
              render={({ value, onChange }) => (
                <InputSelect
                  placeholder='Away team'
                  options={clubs}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </StyledContainer>
          <StyledBottom>
            <StyledButton type='submit'>
              <BodyM>Save</BodyM>
            </StyledButton>
            <StyledButton type='button'>
              <BodyM onClick={handleCloseGameEditor}>Cancel</BodyM>
            </StyledButton>
          </StyledBottom>
        </StyledModalBody>
      </form>
    </StyledModal>
  );
};

export default EditGameModal;
