import { AxiosRequestConfig } from 'axios';

// Faking API endPoint
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_STATS_ENDPOINT = process.env.REACT_APP_STATS_API_ENDPOINT;

export const DOWNLOAD_STORAGE_ENDPOINT =
  process.env.REACT_APP_PUBLIC_STORAGE_ENDPOINT;

export class ConfigApi {
  public static getBearerAndAccountConfig(module?: string): AxiosRequestConfig {
    return {
      baseURL: module === 'stats' ? API_STATS_ENDPOINT : API_ENDPOINT,
      timeout: 30000,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
        Account: localStorage.getItem('vault_account_id'),
      },
    };
  }

  public static getBearerConfig(module?: string): AxiosRequestConfig {
    return {
      baseURL: module === 'stats' ? API_STATS_ENDPOINT : API_ENDPOINT,
      timeout: 10000,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
      },
    };
  }

  public static getPublicConfig(module?: string): AxiosRequestConfig {
    return {
      baseURL: module === 'stats' ? API_STATS_ENDPOINT : API_ENDPOINT,
      timeout: 10000,
      headers: {
        'Content-type': 'application/json',
      },
    };
  }

  public static getNoAccountConfig(module?: string): AxiosRequestConfig {
    return {
      baseURL: module === 'stats' ? API_STATS_ENDPOINT : API_ENDPOINT,
      timeout: 10000,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
        'No-account': 'true',
      },
    };
  }

  public static getBearerConfigExport(module?: string): AxiosRequestConfig {
    return {
      baseURL: module === 'stats' ? API_STATS_ENDPOINT : API_ENDPOINT,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
      },
    };
  }

  public static getUploadConfig(noAccount = false): AxiosRequestConfig {
    return {
      baseURL: API_ENDPOINT,
      timeout: 10000,
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
        'No-account': noAccount ? 'true' : '',
      },
    };
  }

  public static getSelectedAccountConfig(module?: string): AxiosRequestConfig {
    return {
      baseURL: module === 'stats' ? API_STATS_ENDPOINT : API_ENDPOINT,
      timeout: 10000,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
        'No-account': 'true',
        'Account-Type': localStorage.getItem('account_type'),
        'Account-Id': localStorage.getItem('account_id'),
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  }
}
