import React from 'react';

import { CloseSmall } from '@icon-park/react';

import { BodyL, BodyLBold } from 'styles/v3/variables';

import FilledButton from 'components/v3/Buttons/FilledButton';
import { useApproveOrRejectRequests } from 'hooks/v3/clubs/useApproveOrRejectRequests/useApproveOrRejectRequests';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import { ApproveRejectModalProps } from './types';

export const ApproveRejectModal = ({
  isOpen,
  handleIsOpen,
  requestIds,
  type,
}: ApproveRejectModalProps) => {
  const params: { clubId: string } = useParams();

  const approveRejectTitle = type === 'approve' ? 'APPROVE' : 'DENY';

  const handleApproveReject = async () => {
    if (isRequesting) return;

    processRequests({
      action: type === 'approve' ? 'APPROVE' : 'REJECT',
      requestIds,
    }).finally(() => {
      handleIsOpen(false);
    });
  };

  const {
    mutateAsync: processRequests,
    isLoading: isRequesting,
  } = useApproveOrRejectRequests([
    ['get-request-by-club-status', params.clubId],
    ['get-club-members-pool', params.clubId],
  ]);

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => handleIsOpen(false)}
      onCancel={() => handleIsOpen(false)}
    >
      <S.Container>
        <S.HeaderWrapper>
          {type === 'approve' ? (
            <S.HeaderTitle>
              <BodyL>Are you sure you want to</BodyL>
              <BodyLBold>APPROVE</BodyLBold>
              <BodyL>this player?</BodyL>
            </S.HeaderTitle>
          ) : (
            <S.HeaderTitle>
              <BodyL>Are you sure you want to</BodyL>
              <BodyLBold>DENY</BodyLBold>
              <BodyL>this player?</BodyL>
            </S.HeaderTitle>
          )}
          <S.CloseButton onClick={() => handleIsOpen(false)}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>

        <FilledButton
          disabled={isRequesting}
          isUpper
          onClick={handleApproveReject}
          color={type === 'approve' ? 'primary' : 'support-error'}
        >
          {isRequesting ? 'Processing...' : approveRejectTitle}
        </FilledButton>
      </S.Container>
    </S.StyledModal>
  );
};
