import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { debounce } from 'lodash';
import { Moment } from 'moment';
import * as React from 'react';
import { useRef, useState } from 'react';
import Button from '../../../../components/uielements/Button/Button';
import { color, font } from '../../../../styles/styleVariables';
import { AgeDivision, SpecialDivision } from '../../../models/AgeDivision';

interface IMatchesFilterProps {
  onChangeAgeDivision?: (division?: string) => void;
  onChangeGameStatus?: (event: any) => void;
  onChangeDates?: (
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined
  ) => void;
  onChangeQuery?: (event: any) => void;
  onClearDate?: () => void;

  availableAgeDivisions?: AgeDivision[];
  specialDivisions?: SpecialDivision[];
}

const MatchesFilter: React.FunctionComponent<IMatchesFilterProps> = (props) => {
  const {
    availableAgeDivisions,
    onChangeAgeDivision,
    onChangeGameStatus,
    onChangeDates,
    onChangeQuery,
    onClearDate,
    specialDivisions,
  } = props;

  const ageDivisionRef = useRef<HTMLSelectElement>(null);
  const debounceSearch = useRef(
    debounce((filter) => {
      onChangeQuery?.(filter);
    }, 300)
  ).current;

  const handleSearchChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    debounceSearch(event.currentTarget.value);
  };

  const handleStartDateChange = (event: Moment | null) => {
    setStartDate(event);
    onChangeDates?.(event!, endDate!);
  };

  const handleEndDateChange = (event: Moment | null) => {
    setEndDate(event);
    onChangeDates?.(startDate!, event!);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    onClearDate?.();
  };

  const ageDivisionToString = (item: AgeDivision) => {
    return `${item.gender} ${
      item.years[0] === 1900 ? 'ADULT' : item.years.sort().join(', ')
    }, ${item.rule}`;
  };

  const [startDate, setStartDate] = useState<Moment | null>();
  const [endDate, setEndDate] = useState<Moment | null>();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: font.montserrat,
      fontSize: 12,
    },
  });

  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ display: 'flex' }}>
        <div className='form-group' style={{ marginRight: '20px' }}>
          <div style={{ display: 'flex' }}>
            <select
              defaultValue=''
              onChange={
                onChangeAgeDivision
                  ? (event) => onChangeAgeDivision(event.currentTarget.value)
                  : undefined
              }
              ref={ageDivisionRef}
            >
              <option value='' disabled>
                Select Age Division
              </option>
              <option value='-1'>All Divisions</option>
              {availableAgeDivisions?.map((item, index) => (
                <option key={`${index}`} value={index}>
                  {ageDivisionToString(item)}
                </option>
              ))}
              <option value='' disabled>
                &nbsp;&nbsp;&nbsp;Special Divisions
              </option>
              {specialDivisions &&
                specialDivisions
                  .sort((a, b) =>
                    a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
                      ? 1
                      : -1
                  )
                  .map((item) => {
                    return (
                      <option value={`special|${  item.name}`}>
                        {item.name}
                      </option>
                    );
                  })}
            </select>

            <select defaultValue='' onChange={onChangeGameStatus}>
              <option value='' disabled>
                Game Status
              </option>
              <option value=''>All</option>
              <option value='SCHEDULED'>Scheduled</option>
              <option value='READY'>Ready</option>
              <option value='IN_PROGRESS'>In Progress</option>
              <option value='FINISHED'>Finished</option>
            </select>
          </div>
        </div>
        <div className='form-group'>
          <span className='icon icon-search' />
          <input
            name='query'
            onChange={handleSearchChange}
            type='text'
            className='icon'
            placeholder='Search'
          />
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    style={{
                      marginRight: '20px',
                      backgroundColor: color.greyDark,
                    }}
                    {...props}
                  />
                )}
                label='From Date/Time'
                value={startDate}
                onChange={handleStartDateChange}
              />

              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    style={{ backgroundColor: color.greyDark }}
                    {...props}
                  />
                )}
                label='From Date/Time'
                value={endDate}
                onChange={handleEndDateChange}
              />
            </LocalizationProvider>
          </ThemeProvider>
          <Button
            style={{
              width: '100px',
              padding: '10px 20px',
              margin: '10px',
              fontSize: '9px',
              minWidth: 'auto',
            }}
            onClick={handleClearDates}
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MatchesFilter;
