import React from 'react';

import { BodyM, BodyMBold, BodyS } from 'styles/v3/variables';
import { useGroups } from '../../../../context/GroupsContext';
import { getColorByNumberOfTeam, getRuleText } from '../../../../utils';
import { fallbackValue } from './constants';
import * as S from './styles';

export const DivisionsRules = () => {
  const { selectedDivision, countAllTeams } = useGroups();

  const targetColor = getColorByNumberOfTeam(
    countAllTeams,
    selectedDivision?.targetNumberOfTeams
  );

  return (
    <S.Container>
      <BodyS $isUpper $color='grey500'>
        Divisions rules
      </BodyS>
      <S.DivisionsInfoWrapper>
        <S.Flex>
          <BodyM $color='grey100'>Gender</BodyM>
        </S.Flex>
        <S.Flex>
          <BodyMBold $isUpper $color='grey100'>
            {selectedDivision?.gender || fallbackValue}
          </BodyMBold>
        </S.Flex>
      </S.DivisionsInfoWrapper>
      <S.DivisionsInfoWrapper>
        <S.Flex>
          <BodyM $color='grey100'>Age</BodyM>
        </S.Flex>
        <S.Flex>
          <BodyMBold $isUpper $color='grey100'>
            {getRuleText(selectedDivision?.rule) || fallbackValue}
          </BodyMBold>
        </S.Flex>
      </S.DivisionsInfoWrapper>
      <S.DivisionsInfoWrapper>
        <S.Flex>
          <BodyM $color='grey100'>Teams</BodyM>
        </S.Flex>
        <S.Flex title="Teams / Target">
          <BodyMBold $isUpper $color={targetColor}>
            {selectedDivision?.targetNumberOfTeams
              ? `${countAllTeams} / ${selectedDivision.targetNumberOfTeams}`
              : fallbackValue}
          </BodyMBold>
        </S.Flex>
      </S.DivisionsInfoWrapper>
      <S.DivisionsInfoWrapper>
        <S.Flex>
          <BodyM $color='grey100'>Coed Rules</BodyM>
        </S.Flex>
        <S.Flex>
          <BodyMBold $isUpper $color='grey100'>
            {selectedDivision?.coedRules || fallbackValue}
          </BodyMBold>
        </S.Flex>
      </S.DivisionsInfoWrapper>
    </S.Container>
  );
};
