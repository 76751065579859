import React, { FC, memo } from 'react';
import { Divider } from 'antd';

import { EventView } from 'admin/models/event/Event';

import { AgeSection, InformationSection } from './components';

import * as S from './styles';

interface IEventMainProps {
  event?: EventView;
}

export const EventMain: FC<IEventMainProps> = memo(({ event }) => {
  return (
    <S.MainWrapper>
      <InformationSection event={event} />
      <Divider style={{ margin: '48px 0' }} />
      <AgeSection ageDivisions={event?.ageDivisions} />
    </S.MainWrapper>
  );
});

EventMain.displayName = 'EventMain';
