import { RequestStatus } from 'models/Request/RequestModel';

export type UserRequest = {
  id: string,
  clubId: string,
  userId: string,
  status: RequestStatus,
};

export type GetMyRequestsToClubProps = {
  clubId?: string,
  status?: RequestStatus[],
  perPage?: number,
  page?: number,
};

export const defaultGetMyRequestsToClubProps: GetMyRequestsToClubProps = {
  clubId: undefined,
  status: undefined,
  perPage: 30,
  page: 0,
};

export interface ApproveOrRejectRequestsProps {
  requestIds: string[];
  action: 'APPROVE' | 'REJECT';
}
