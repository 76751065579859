import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { BREAKPOINTS } from 'styles/v3/constants';

export const SignUpAsClubDirectorContainer = styled.div`
  padding: 0 ${toRem(50)};

  display: flex;
  flex-direction: column;

  .btn-continue {
    margin-top: 50px;
    width: 100%;
    max-width: 324px;
    align-self: center;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: ${toRem(24)} ${toRem(10)};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ClubListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${toRem(28)};
  margin: ${toRem(30)} 0;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    justify-content: center;
  }
`;
