import { FutsalAction, SoccerPeriod } from 'services/v3/Match/enums';

export const NORMAL_TIME = 1200; // seconds

export const MATCH_SOCCER = [
  {
    type: SoccerPeriod.FIRST_HALF,
    value: '1st Half',
  },
  {
    type: SoccerPeriod.SECOND_HALF,
    value: '2nd Half',
  },
  {
    type: SoccerPeriod.PENALTY_SHOOTOUT,
    value: 'Penalty Shootout',
  },
  {
    type: SoccerPeriod.FINISHED_GAME,
    value: 'Finish Game',
  },
];

export const FUTSAL_EVENTS = [
  {
    type: FutsalAction.GOAL,
    value: 'Goal',
  },
  {
    type: FutsalAction.FOUL,
    value: 'Foul',
  },
  {
    type: FutsalAction.YELLOW_CARD,
    value: 'Yellow card',
  },
  {
    type: FutsalAction.RED_CARD,
    value: 'Red card',
  },
  {
    type: FutsalAction.OWN_GOAL,
    value: 'Own goal',
  },
];

export const PENALTY_FUTSAL_EVENTS = [
  {
    type: FutsalAction.PK_GOAL,
    value: 'PK Goal',
  },
  {
    type: FutsalAction.PK_MISS,
    value: 'PK Miss',
  },
  {
    type: FutsalAction.YELLOW_CARD,
    value: 'Yellow card',
  },
  {
    type: FutsalAction.RED_CARD,
    value: 'Red card',
  },
];
