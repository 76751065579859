import { useQuery } from '@tanstack/react-query';
import RosterService from 'services/v3/Rosters/RosterService';

type UseGetEventRostersProps = {
  eventId?: string,
};

export const useGetEventRosters = ({ eventId }: UseGetEventRostersProps) => {
  const response = useQuery(['rosters-event', eventId], () =>
    RosterService.getRosterByEventId(eventId)
  );

  return {
    ...response,
    data: response.data?.data,
  };
};
