import Axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import TokenUtil from 'util/TokenUtil';
import ServiceResponseV3 from './ServiceResponseV3';

class APIServicesV3<T> {
  static async request<T>(
    axiosConfig: AxiosRequestConfig
  ): Promise<ServiceResponseV3<T>> {
    return Axios.request(axiosConfig)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          return ServiceResponseV3.success(response.data, '');
        }

        return ServiceResponseV3.error(response.data, '');
      })
      .catch((error) => {
        // If unautorized try refresh token and request again
        if (error.response?.status === 401) {
          return TokenUtil.refreshToken().then(() => {
            // Replace previous axiosConfig with new token
            const newAxiosConfig = {
              ...axiosConfig,
              headers: {
                ...axiosConfig.headers,
                Authorization: `Bearer ${localStorage.getItem('kc_token')}`,
              },
            };

            // Resend request
            return Axios.request(newAxiosConfig).then((response) => {
              if (response.status >= 200 && response.status < 400) {
                return ServiceResponseV3.success(response.data, '');
              }

              return ServiceResponseV3.error(response.data, '');
            });
          });
        }
        Sentry.captureException(error, error.response?.data?.message);

        return ServiceResponseV3.error(
          error,
          error.response?.data?.message,
          error.response?.status
        );
      });
  }
}

export default APIServicesV3;
