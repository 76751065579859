enum UserType {
  PLAYER = 'PLAYER',
  COACH = 'COACH',
  CLUB_DIRECTOR = 'CLUB_DIRECTOR',
  REFEREE = 'REFEREE',
  TOURNAMENT_DIRECTOR = 'TOURNAMENT_DIRECTOR',
  STAFF = 'STAFF',
  TEAM_MANAGER = 'TEAM_MANAGER',
}

export default UserType;
