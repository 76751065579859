import React from 'react';

import { BodyM } from 'styles/v3/variables';
import * as S from './styles';

type EmptyTeamCardType = {
  name: string,
  order: string,
};

export const EmptyTeamCard = ({ name, order }: EmptyTeamCardType) => {
  return (
    <S.Container>
      <BodyM $isUpper $color='white'>
        {name}
      </BodyM>
      <BodyM $isUpper $color='white'>
        {order}
      </BodyM>
    </S.Container>
  );
};
