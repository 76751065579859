import { MatchEventModel } from 'models/Match/MatchModel';
import moment from 'moment';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { GridStackNode, GridStackWidget } from 'gridstack';
import { colorGroup, stepperIntervals } from '../lib';

interface WidgetData {
  x: number;
  y: number;
  match: MatchEventModel;
  matchLenght: number;
}

export const useGetScheduleConfig = (
  eventId: string,
  divisionColors: { [key: string]: string }
) => {
  const pools = useGetEventById(eventId).data?.ageDivisions?.flatMap(
    (division) => {
      return division?.pools?.map((poolOfEvent) => {
        return {
          id: poolOfEvent.id,
          label: `${poolOfEvent.name} ${poolOfEvent.order}`,
          value: poolOfEvent.id,
        };
      });
    }
  );
  const getPoolName = (poolMatches: { id: string, name?: string }[]) => {
    const poolsFromMatch = pools
      ? pools?.filter((pool) =>
          poolMatches.some((item) => pool?.id === item?.id)
        )
      : [];

    return poolsFromMatch.map((pool) => `${pool.label}`);
  };
  const getMatchTitle = (match: MatchEventModel) => {
    if (match.sourceType === 'POOL') {
      return getPoolName(match.pools);
    }
    return `Bracket: Game ${match.name}`;
  };

  const getHomeTeamName = (match: MatchEventModel) => {
    if (!match?.homePlaceHolder?.name) {
      return 'TBA';
    }
    return match?.homeTeam?.clubName
      ? match?.homeTeam?.clubName
      : match?.homePlaceHolder?.name;
  };

  const getAwayTeamName = (match: MatchEventModel) => {
    if (!match?.awayPlaceHolder?.name) {
      return 'TBA';
    }
    return match?.awayTeam?.clubName
      ? match?.awayTeam?.clubName
      : match?.awayPlaceHolder?.name;
  };

  const changeTimeInnerScheduledElem = (
    element: GridStackNode,
    title: string,
    time: string,
    homeTeam: string,
    awayClub: string,
    match: MatchEventModel
  ) => {
    const el = element.el?.querySelector('.grid-stack-item-content .card');
    const editBg =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? '#fff'
        : '#000';
    const editIcon =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'edit_black'
        : 'edit_white';
    if (el) {
      el.innerHTML = `
                  <div class='widget'>
                    <div class="title">${title} - ${time}</div>
                    <div class="team">${homeTeam ?? ''}</div>
                    <div class="team">${awayClub ?? ''}</div>
                  </div>
                    <div class='edit_container' style='background-color:${editBg}'>
                        <div class='${editIcon}' />
                    </div>
                  `;
    }
  };

  const createWidget = (data: WidgetData) => {
    const { match, matchLenght, x, y } = data;
    const lockedIco =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'locked_item_white'
        : 'locked_item_black';
    const unlockedIco =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'unlocked_item_white'
        : 'unlocked_item_black';
    const icon = match.gridLocked ? lockedIco : unlockedIco;
    const editBg =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? '#fff'
        : '#000';
    const editIcon =
      colorGroup[divisionColors[match.ageDivisionId]] === '#fff'
        ? 'edit_black'
        : 'edit_white';

    const newWidget: GridStackWidget = {
      id: `match-${match.matchId}`,
      x,
      y,
      w: 1,
      locked: true,
      noResize: match.gridLocked,
      noMove: match.gridLocked,
      h: matchLenght / stepperIntervals,
      content: `
                  <div class='card ${icon}' id='${
        match.matchId
      }' style='background-color:${
        divisionColors[match.ageDivisionId] ?? '#fff'
      }; color:${colorGroup[divisionColors[match.ageDivisionId]]};'>
                    <div class='widget'>
                      <div class='title'>${getMatchTitle(match)} - ${moment
        .utc(match.date)
        .format('hh:mm A')}</div>
                       <div class='team'>${getHomeTeamName(match)}</div>
                       <div class='team'>${getAwayTeamName(match)}</div>
                    </div>
                    <div class='edit_container' style='background-color:${editBg}'>
                        <div class='${editIcon}' />
                    </div>
                  </div>
              `,
    };

    return newWidget;
  };

  return {
    pools,
    getMatchTitle,
    getHomeTeamName,
    getAwayTeamName,
    changeTimeInnerScheduledElem,
    createWidget,
  };
};
