import { AxiosRequestConfig } from 'axios';
import { ScheduleView } from 'models/Schedule/ScheduleModel';
import { ConfigApi } from 'services/config.api';
import APIServicesV3 from 'services/util/ApiServicesV3';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import {
  ScheduleBaseModel,
  GetGamesCountByEventIdProps,
  UpdateScheduleProps,
} from './types';

class ScheduleService {
  public static async getSchedulesByEventId(
    eventId?: string
  ): Promise<ServiceResponseV3<ScheduleView | undefined>> {
    if (!eventId)
      return ServiceResponseV3.success<ScheduleView | undefined>(undefined, '');
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules/event/${eventId}`,
      method: 'GET',
    };
    return APIServicesV3.request<ScheduleView>(axiosConfig);
  }

  public static async getGamesCountByEventId(
    eventId?: string
  ): Promise<ServiceResponseV3<GetGamesCountByEventIdProps>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules/event/games-count/${eventId}`,
      method: 'GET',
    };
    return APIServicesV3.request<GetGamesCountByEventIdProps>(axiosConfig);
  }

  public static async createSchedule(
    data: ScheduleBaseModel
  ): Promise<ServiceResponseV3<ScheduleView | undefined>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules`,
      method: 'POST',
      data,
    };
    return APIServicesV3.request<ScheduleView>(axiosConfig);
  }

  public static async updateScheduleByEventId(
    eventId: string,
    data: UpdateScheduleProps
  ): Promise<ServiceResponseV3<ScheduleView | undefined>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules/event/${eventId}`,
      method: 'PUT',
      data,
    };
    return APIServicesV3.request<ScheduleView>(axiosConfig);
  }

  public static async publishSchedule(eventId: string, published: boolean) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules/event/${eventId}/published-status?publish=${published}`,
      method: 'PUT',
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async deleteSchedule(eventId: string) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules/event/${eventId}`,
      method: 'DELETE',
    };

    return APIServicesV3.request(axiosConfig);
  }

  public static async sendNotification(eventId: string) {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/schedules/event/${eventId}/schedule-notification`,
      method: 'POST',
    };

    return APIServicesV3.request(axiosConfig);
  }
}

export default ScheduleService;
