/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import { TitleH4 } from 'styles/v3/variables';
import { useParams } from 'react-router-dom';
import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import Loading from 'components/v3/Loading/Loading';
import ConvertUtil from 'util/ConvertUtil';
import { useQueryParams } from 'hooks/v3/util/useQueryParams';
import { Content, Wrapper } from './styles';
import { PrintButton } from '../components/PrintButton/PrintButton';

import * as S from './styles';

const AllRosters: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const queryParams = useQueryParams();

  const selectedRoster = queryParams.get('roster') ?? undefined;

  const {
    data: eventRosters,
    isLoading: isLoadingRoster,
  } = useGetEventRosters({ eventId });
  const { data: currentEvent, isLoading: isLoadingEvent } = useGetEventById(
    eventId
  );

  const rosters = useMemo(() => {
    if (!eventRosters?.length) return [];

    if (selectedRoster) {
      return eventRosters.filter((roster) => roster.id === selectedRoster);
    }
    return eventRosters;
  }, [eventRosters, selectedRoster]);

  function mapInSlices(array: any, sliceSize: any, sliceFunc: any) {
    const out = [];
    for (let i = 0; i < array.length; i += sliceSize) {
      const slice = array.slice(i, i + sliceSize);
      out.push(sliceFunc(slice, i));
    }
    return out;
  }

  if (isLoadingRoster || isLoadingEvent) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <PrintButton text='Print Rosters' />

      <Content>
        {!rosters?.length ? (
          <TitleH4>No rosters on this event</TitleH4>
        ) : (
          rosters.map((roster) => (
            <S.PrintWrapper key={roster.id} isMultiple={rosters.length > 1}>
              <div className='top'>
                <div className='box-tournament'>
                  <div className='wrapper'>
                    <div className='top-box'>
                      <div className='vault-icon' />
                      <div className='title'>
                        <div className='type'>Tournament</div>
                        <div className='name'>{roster.event?.name}</div>
                      </div>
                    </div>
                    <div className='logo'>
                      <div className='img'>
                        <img
                          alt='logo'
                          src={ConvertUtil.getMinioUrl(roster.event?.logo)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='box-info'>
                  <h4>{roster.name}</h4>
                  <div>
                    {`${roster?.ageDivision?.gender} 
                ${
                  roster?.ageDivision?.years[0] === 1900
                    ? 'ADULT'
                    : roster?.ageDivision?.years.sort().join(', ')
                }
        `}
                  </div>
                  <div className='table'>
                    <div className='bold'>Location: </div>
                    <div>{/* TODO: insert club Location */}</div>

                    <div className='bold'>Dates: </div>
                    <div>
                      {currentEvent?.startDate} - {currentEvent?.endDate}
                    </div>

                    <div className='bold'>Club: </div>
                    <div>{roster.club?.name}</div>

                    <div className='bold'>Club Director: </div>
                    <div>{/* TODO: insert club director's name */}</div>
                  </div>
                </div>
                <div className='box-club'>
                  <div className='wrapper'>
                    <div className='top-box'>
                      <div className='vault-icon' />
                      <div className='title'>
                        <div className='type'>Club</div>
                        <div className='name'>{roster.club?.name}</div>
                      </div>
                    </div>
                    <div className='logo'>
                      <div className='img'>
                        <img
                          alt='logo'
                          src={ConvertUtil.getMinioUrl(roster.club?.logo)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '20px' }}>
                <h3>Players</h3>
                {mapInSlices(
                  // roster.players.sort(
                  //   (a, b) => a?.number ?? 0 - b?.number ?? 0
                  // ),
                  roster.players,
                  16,
                  (slice: any, index: number) => (
                    <>
                      <S.PlayersTable>
                        {slice.map((player: any) => (
                          <>
                            <S.PlayerCard>
                              <div className='number'>{player.number}</div>
                              <div>
                                <div className='image'>
                                  <img
                                    alt='logo'
                                    src={ConvertUtil.getMinioUrl(player.photo)}
                                  />
                                </div>
                                <div className='name'>
                                  {player.firstName} {player.lastName}
                                </div>
                              </div>
                            </S.PlayerCard>
                          </>
                        ))}
                      </S.PlayersTable>
                    </>
                  )
                )}
                <h3>Coaches</h3>
                <S.PlayersTable>
                  {roster.coaches.map((coach) => (
                    <S.PlayerCard key={coach.id}>
                      <div>
                        <div className='image'>
                          <img
                            alt='logo'
                            src={ConvertUtil.getMinioUrl(coach.photo)}
                          />
                        </div>
                        <div className='name'>
                          {coach.firstName} {coach.lastName}
                        </div>
                      </div>
                    </S.PlayerCard>
                  ))}
                </S.PlayersTable>
              </div>
            </S.PrintWrapper>
          ))
        )}
      </Content>
    </Wrapper>
  );
};

export default AllRosters;
