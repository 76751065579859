import React from 'react';
import { Drawer } from 'antd';
import { BodyLBold } from 'styles/v3/variables';
import Select from 'components/v3/Forms/Select/Select';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { FilterDrawerProps } from './types';
import * as S from './styles';

export const FilterDrawer = ({ isOpen, onClose }: FilterDrawerProps) => {
  const handleApply = () => {
    onClose();
  };

  return (
    <Drawer
      visible={isOpen}
      placement='right'
      onClose={onClose}
      title={<BodyLBold>Filters</BodyLBold>}
      footer={
        <S.Footer>
          <FilledButton isUpper onClick={handleApply}>
            Apply filters
          </FilledButton>
        </S.Footer>
      }
    >
      <S.Container>
        <S.Section>
          <BodyLBold $color='grey50'>Location</BodyLBold>
          <Select name='country' isClearable placeholder='Country' />
          <Select name='state' isClearable placeholder='State / Region' />
          <Select name='city' isClearable placeholder='City' />
        </S.Section>
      </S.Container>
    </Drawer>
  );
};
