import React, { ChangeEvent, useState } from 'react';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { RequestStatus } from 'models/Request/RequestModel';
import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import UserServices from 'services/v3/User/UserService';
import ProfilePhotoModal from 'components/v3/UploadModal/ProfilePhotoModal';
import { BodyXL } from 'styles/v3/variables';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import DocumentCard from 'components/v3/Cards/DocumentCard/DocumentCard';
import { notification } from 'components/v3/Notification/notification';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { StyledForm, StyledWrapper } from './styles';
import { statusMap, userTypesRole } from './lib';

const CompleteRegistration = () => {
    const history = useHistory();
    const { currentUser, isBaseUser } = useProfile();
    const [modalId, setModalId] = React.useState<'photo' | ''>('');
    const [birthDate, setBirthDate] = useState<string>();
    const [userType, setUserType] = useState<
      MultiValue<OptionsType> | SingleValue<OptionsType>
    >();
    const [errors, setErrors] = useState({
      birthDate: '',
      userTypeRole: ''
    });
   
    const photoStatus = React.useMemo(() => {
        return (
            statusMap[currentUser?.photoVerified as RequestStatus] ||
            DocumentStatusLabel.EMPTY
        );
    }, [currentUser?.photoVerified]);

    const handleChangeBirth = (e: ChangeEvent<HTMLInputElement>) => {
        setBirthDate(e.target.value);
        setErrors({...errors, birthDate: ''});
    }

    const handleChangeType = (
      newValue: MultiValue<OptionsType> | SingleValue<OptionsType>,
      actionMeta: ActionMeta<OptionsType>
    ) => {
      if (newValue) {
        setUserType(newValue);
        setErrors({...errors, userTypeRole: ''})
      } else {
        setUserType({ label: '', value: '' });
        setErrors({ ...errors, userTypeRole: 'Role is required' });
      }
    };

    const updateUser = async () => {
        if (!birthDate || !userType) {
          setErrors({
            birthDate: !birthDate ? 'Birth date is required!' : '',
            userTypeRole: !userType ? 'Role is required!' : ''
          });
          return;
        }
        if (moment().isBefore(moment(birthDate))) {
            setErrors({...errors, birthDate: `Birth date cant't be greater than current date`})
            return;
        }
        try {
            if (currentUser.id) {
              await UserServices.updateUser(
                currentUser.id,
                { birthDate },
                isBaseUser,
                (userType as OptionsType).value.toString(),
              );

              history.push('/v3/home-page');
            }
          } catch (e) {
            notification.error({ message: 'Something went wrong!' });
          }
    };
    return (
      <StyledWrapper>
        <StyledForm onSubmit={(e) => e.preventDefault()} noValidate>
          <BodyXL $color='brandPrimary' $isUpper>
            Complete registration
          </BodyXL>
          <InputDate
            required
            id='birthDate'
            errorMessage={errors.birthDate}
            placeholder='Birthdate'
            label='Birthdate'
            key={`birthDate_${currentUser?.id}`}
            onChange={handleChangeBirth}
            error={!!errors.birthDate}
            containerClassName='user-info-input'
          />
          <Select
            required
            value={userType}
            error={!!errors.userTypeRole}
            errorMessage={errors.userTypeRole}
            onChange={handleChangeType}
            placeholder='Role'
            name='user-role'
            options={userTypesRole}
          />
          <DocumentCard
            isRequired
            title='Profile Photo'
            caption='This photo will be used so referees can verify your identity before the matches.'
            status={
              currentUser?.photo && currentUser?.photo !== ''
                ? photoStatus
                : DocumentStatusLabel.EMPTY
            }
            onClick={() => setModalId('photo')}
          />
          <FilledButtonWithIcon
            onClick={updateUser}
            disabled={
              !currentUser?.photo || !!errors.birthDate || !!errors.userTypeRole
            }
          >
            Submit
          </FilledButtonWithIcon>
        </StyledForm>
        <ProfilePhotoModal
          isOpen={modalId === 'photo'}
          status={photoStatus}
          handleCloseModal={() => setModalId('')}
        />
      </StyledWrapper>
    );
};

export default CompleteRegistration;
