import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div``;

export const InformationText = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: ${toRem(270)};

    padding: ${toRem(24)} ${toRem(16)};
  }
`;

export const OptionWrapper = styled.div`
  width: 100%;

  background: ${COLORS.grey800};

  padding: ${toRem(24)} ${toRem(20)};
  border-bottom: 1px solid ${COLORS.grey700};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      opacity: 0.5;
    }

    &::before {
      display: none;
    }
  }
`;

export const OptionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SeeAllGamesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  gap: ${toRem(16)};
`;
