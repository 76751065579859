import React from 'react';
import { Down, Up } from '@icon-park/react';
import { BodyL, BodyS } from 'styles/v3/variables';
import { Container, TitleContainer } from './styles';

interface ScheduleAccordionHeaderProps {
  title: string;
  expanded: boolean;
  gamesCount?: string;
  onClick: () => void;
}

export const ScheduleAccordionHeader = ({
  title,
  expanded,
  gamesCount,
  onClick,
}: ScheduleAccordionHeaderProps) => {
  return (
    <Container onClick={onClick}>
      <TitleContainer>
        <BodyL $color='grey100'>{title}</BodyL>
        {gamesCount && <BodyS $color='grey400'>{gamesCount}</BodyS>}
      </TitleContainer>
      <div>{expanded ? <Up size={24} /> : <Down size={24} />}</div>
    </Container>
  );
};
