import React from 'react';

import { useParams } from 'react-router-dom';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { PrintModalProps } from './types';
import * as S from './styles';
import { PrintItemProps, PrintItemType } from './components/PrintItem/types';
import { DefaultContainer } from './components/DefaultContainer/DefaultContainer';
import { RostersContainer } from './components/RostersContainer/RostersContainer';
import { ScoreSheetContainer } from './components/ScoreSheetContainer/ScoreSheetContainer';

export const PrintModal = ({ isOpen, handleIsOpen }: PrintModalProps) => {
  const params: { eventId: string } = useParams();

  const { data: currentEvent } = useGetEventById(params.eventId);

  const [selectedType, setSelectedType] = React.useState<
    PrintItemType | undefined
  >(undefined);

  const printItems: Pick<PrintItemProps, 'title' | 'description' | 'type'>[] = [
    {
      title: 'Rosters',
      description: 'Print rosters with images from each player',
      type: 'rosters',
    },
    {
      title: 'Scoresheet',
      description: 'Print scoresheet for your games',
      type: 'score-sheet',
    },
    {
      title: 'All Brackets',
      description: 'Print all brackets from your event',
      type: 'all-brackets',
    },
    {
      title: 'Schedule by Court',
      description: 'Print schedule organized by court',
      type: 'schedule-by-court',
    },
    {
      title: 'Schedule by time per location',
      description: 'Print the schedule in chronological order',
      type: 'schedule-by-time-per-location',
    },
    {
      title: 'All teams',
      description: 'Print the list of all teams in your event',
      type: 'all-teams',
    },
  ];

  const handleCloseModal = () => {
    handleIsOpen(false);
    setSelectedType(undefined);
  };

  return (
    <S.StyledModal
      $color={
        selectedType === 'rosters' || selectedType === 'score-sheet'
          ? 'grey950'
          : 'grey900'
      }
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
    >
      {!selectedType && (
        <DefaultContainer
          currentEvent={currentEvent}
          handleCloseModal={handleCloseModal}
          printItems={printItems}
          handleSelectType={setSelectedType}
        />
      )}

      {selectedType === 'rosters' && (
        <RostersContainer
          currentEvent={currentEvent}
          handleCloseModal={handleCloseModal}
          handleBack={() => setSelectedType(undefined)}
        />
      )}

      {selectedType === 'score-sheet' && (
        <ScoreSheetContainer
          currentEvent={currentEvent}
          handleCloseModal={handleCloseModal}
          handleBack={() => setSelectedType(undefined)}
        />
      )}
    </S.StyledModal>
  );
};
