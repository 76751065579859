import { Modal } from 'antd';

import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

interface RoleCard {
  isEnabled?: boolean;
}

export const StyledModal = styled(Modal)<{ $color: keyof typeof COLORS }>`
  .ant-modal-content {
    max-width: ${toRem(431)};
    border-radius: ${toRem(24)};
    background-color: ${(props) => COLORS[props.$color]};

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      border-radius: 0;
      border-top-left-radius: ${toRem(24)};
      border-top-right-radius: ${toRem(24)};
    }
  }

  .ant-modal-body {
    padding: 0 ${toRem(0)} ${toRem(24)} 0;
  }

  .ant-modal-header {
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: ${toRem(24)};
    padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(12)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

export const CloseButton = styled.button`
  width: ${toRem(38)};
  height: ${toRem(38)};

  border: none;
  box-shadow: none;
  background-color: ${COLORS.grey700};

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0 !important;
  }

  .closeButton {
    cursor: pointer;
  }
`;

export const HR = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.grey800};
  &::after {
    content: ' ';
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};

  .title {
    margin-bottom: ${toRem(32)};
  }
`;
