import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container =
  styled.div <
  { $image: string | undefined } >
  `
  width: 100%;
  height: ${toRem(150)};

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1b1c19 83.85%),
    url(${({ $image }) => $image}),
    lightgray -205.466px -80.312px / 178.923% 274.484% no-repeat;

  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${toRem(16)};
  background-color: ${COLORS.grey800};
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -100px;
  align-items: center;

  button {
    height: ${toRem(50)};
  }

  .btn-request:disabled {
    background-color: ${COLORS.grey400};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: flex-end;
    margin-bottom: 0;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(11)};

  & > span:first-child {
    min-width: ${toRem(68)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-top: ${toRem(50)};
    align-items: flex-start;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(6)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    gap: ${toRem(16)};

    span {
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
  align-items: center;

  .i-icon svg {
    width: 20px;
    height: 20px;
    display: flex;
    &::before {
      display: none;
    }

    path {
      stroke: ${COLORS.grey400};
    }
  }
`;
