import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';

import RosterService from 'services/v3/Rosters/RosterService';
import { IPlayerNumberPayload } from 'services/v3/Rosters/types';

export const useUpdatePlayerNumber = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: IPlayerNumberPayload) =>
      RosterService.updatePlayerNumber(dto),
    onSuccess: async (_, dto) => {
      notification.success({
        message: 'Jersey was updated successfully!',
      });
      queryClient.invalidateQueries(['/get-roster-detailed-id', dto.rosterId]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to update Jersey Number!',
        description: error.message,
      });
    },
  });
};
