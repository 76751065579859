import React, { forwardRef } from 'react';
import { ErrorStyledInfoTooltip, ErrorStyledTooltipContainer } from './styles';
import { InfoTooltipProps } from './types';
import attention from '../../../../../../../assets/imgs/icons/attention.svg';

type Props = InfoTooltipProps & {
  error: { message: string, type?: string },
};

const ErrorTooltip = forwardRef<HTMLDivElement, Props>(
  ({ variant, error }, ref) => {
    return (
      <ErrorStyledInfoTooltip ref={ref}>
        <ErrorStyledTooltipContainer variant={variant}>
          <img width={18} height={18} src={attention} alt='Attention' />
          {error.type === 'minTimePerTeam' && (
            <p>
              You need to set at least <span>{error.message} minutes</span>{' '}
              between team matches
            </p>
          )}
          {error.type === 'maxTimePerTeam' && (
            <p>
              The maximum number of games is <span>{error.message}</span> for
              each team per day.
            </p>
          )}
          {error.type === 'maxGamesPerteam' && (
            <p>
              Maximum of <span>{error.message}</span> games per team per day.
            </p>
          )}
        </ErrorStyledTooltipContainer>
      </ErrorStyledInfoTooltip>
    );
  }
);

ErrorTooltip.displayName = 'ErrorTooltip';

export default ErrorTooltip;
