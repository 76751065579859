import React, { useEffect, useMemo, useState } from 'react';
import { useGetEventDivisions } from 'hooks/v3/event/useGetEventDivisons/useGetEventDivisons';
import { BodyL, COLORS } from 'styles/v3/variables';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { Info } from '@icon-park/react';
import { Divider } from 'antd';
import * as S from '../../styles';
import { DivisionCard } from './components/DivisionCard/DivisionCard';
import { DivisionSetFormat } from '../DivisionSetFormat/DivisionSetFormat';
import { Filters } from './components';
import { ContinueToGroups } from '../DivisionSetFormat/components/ContinueToGroups/ContinueToGroups';

type DivisionsEventProps = {
  eventId: string;
};

export const DivisionsEvent = ({ eventId }: DivisionsEventProps) => {
  const [filterDivision, setFilterDivision] = useState('');
  const [selectFormat, setSelectFormat] = useState(false);

  const { data } = useGetEventDivisions({
    eventId,
  });

  const handleFilter = (text: string) => {
    setFilterDivision(text);
  };

  const handleChange = () => {
    setSelectFormat(true);
  };

  useEffect(() => {
    setSelectFormat(false);
  }, []);

  const eventDivisionsData = useMemo(() => {
    if (!data?.content.length || !data) return [];

    return data?.content?.flatMap((eventDivisionData) => {
      return eventDivisionData?.ageDivisions
        ?.map((division) => {
          return {
            id: division.id as string,
            name: division.name ?? '',
            gender: division.gender,
            totalYears: division.years.length,
            firstYear: division.years[0],
            allowMixedTeams: division.allowGirlBoysOnAnotherDivision ?? false,
            bracketType: division.bracketType ?? '',
            pools: division.pools ?? [],
            format: division.format ?? '',
            color: division.color ?? '',
            rule: division.rule,
            years: division.years,
          };
        })
        .filter((division) =>
          !filterDivision
            ? true
            : division.name.toLowerCase().includes(filterDivision.toLowerCase())
        );
    });
  }, [filterDivision, data]);

  return (
    <>
      {!selectFormat ? (
        <>
          <S.Header>
            <S.TitleBox>
              <BodyL>Your Divisions</BodyL>
            </S.TitleBox>
            <FilledButton
              className='button-formating'
              onClick={handleChange}
              isUpper
            >
              Start Formating
            </FilledButton>
          </S.Header>
          <Divider style={{ margin: 0 }} />
          <Filters handleChange={handleFilter} />
          <Divider style={{ margin: 0 }} />
          <S.DivisionsBox>
            {eventDivisionsData.map((division) => (
              <DivisionCard
                key={`key_roster_${division.name}`}
                id={division.id}
                name={division.name}
                gender={division.gender}
                totalYears={division.totalYears}
                firstYear={division.firstYear}
                allowMixedTeams={division.allowMixedTeams}
                bracketType={division.bracketType}
                pools={division.pools}
                format={division.format}
                color={division.color}
              />
            ))}
          </S.DivisionsBox>
        </>
      ) : (
        <>
          <S.Header>
            <S.TitleBox>
              <BodyL>Set format</BodyL>
            </S.TitleBox>
            <S.DivisionIcon>
              <Info
                size={24}
                fill={COLORS.grey50}
                style={{ display: 'flex' }}
              />
            </S.DivisionIcon>
          </S.Header>
          <Divider style={{ margin: 0 }} />
          <Filters handleChange={handleFilter} />
          <Divider style={{ margin: 0 }} />
          <S.DivisionsBox>
            {eventDivisionsData.map((division) => (
              <DivisionSetFormat
                key={`key_roster_${division.name}`}
                id={division.id}
                name={division.name}
                gender={division.gender}
                totalYears={division.totalYears}
                firstYear={division.firstYear}
                allowMixedTeams={division.allowMixedTeams}
                bracketType={division.bracketType}
                pools={division.pools}
                format={division.format}
                color={division.color}
                rule={division.rule}
                years={division.years}
                eventId={eventId}
              />
            ))}
          </S.DivisionsBox>
          <ContinueToGroups />
        </>
      )}
    </>
  );
};
