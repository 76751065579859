import styled from 'styled-components';

import { BodyM, COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;
  padding: 40px 56px 64px;
  margin-top: 28px;
  border-radius: 24px;
  background-color: ${COLORS.grey950};
`;

export const BorderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid ${COLORS.brandPrimary};
`;

export const EmptyState = styled(BodyM)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  padding-top: 64px;
  padding-bottom: 8px;
`;
