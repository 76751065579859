import React, { useMemo } from 'react';
import { Printer } from '@icon-park/react';
import DefaultLogo from 'assets/imgs/club_placeholder.png';
import ConvertUtil from 'util/ConvertUtil';
import { BodyMBold } from 'styles/v3/variables';
import { RosterItemProps } from './types';

import * as S from './styles';

export const RosterItem = ({ roster, event }: RosterItemProps) => {
  const logo = useMemo(() => {
    if (!roster?.club?.logo) return DefaultLogo;
    return ConvertUtil.getMinioUrl(roster.club.logo);
  }, [roster]);

  const handlePrintRoster = () => {
    window.open(`/v3/print/event/${event?.id}/all-rosters?roster=${roster.id}`);
  };

  return (
    <S.Container onClick={handlePrintRoster}>
      <S.Icon src={logo} />
      <BodyMBold>{roster.name}</BodyMBold>
      <S.PrintButtonContainer>
        <Printer className='reset' />
      </S.PrintButtonContainer>
    </S.Container>
  );
};
