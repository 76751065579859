import { Collapse } from 'antd';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;

  flex: 0 0 50%;

  gap: ${toRem(24)};
`;

export const PoolWrapper = styled(Collapse)`
  width: 100%;

  display: flex;
  flex-direction: column;
  background-color: unset;

  gap: ${toRem(24)};
`;

export const PoolPanel = styled(Collapse.Panel)`
  justify-content: space-between;
  background-color: ${COLORS.grey900};
  border-radius: ${toRem(8)} !important;

  .ant-collapse-header {
    justify-content: space-between;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: ${toRem(20)};
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex: 1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(24)};
`;

export const FallbackMessageWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;

  margin-top: ${toRem(32)};

  gap: ${toRem(16)};

  button {
    border: none;
    background-color: unset;
    color: ${COLORS.brandPrimary};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const TeamsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(16)};
`;

export const PoolNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${toRem(6)};
`;
