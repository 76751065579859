import moment from 'moment';
import { stepperIntervals } from '../lib';

export const useGetTimeData = (
  currentDailyEventTime:
    | { day?: string, startTime?: string, endTime?: string }
    | undefined
) => {
  const baseLines = moment
    .duration(
      moment(currentDailyEventTime?.endTime ?? '20:00:00', 'HH:mm:ss').diff(
        moment(currentDailyEventTime?.startTime ?? '08:00:00', 'HH:mm:ss')
      )
    )
    .asHours();
  const countOfLines = baseLines * (60 / stepperIntervals);
  const fromDayStart = moment
    .duration(
      moment(currentDailyEventTime?.startTime ?? '', 'HH:mm:ss').diff(
        moment('00:00:00', 'HH:mm:ss')
      )
    )
    .asHours();

  return {
    baseLines,
    countOfLines,
    fromDayStart,
  };
};
