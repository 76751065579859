import * as React from 'react';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import { Remind } from '@icon-park/react';

import { useGetMyNotifications } from 'hooks/v3/notifications/useGetMyNotifications/useGetMyNotifications';

import * as S from './styles';

const Notifications: React.FC = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const path = '/v3/notifications';
  const pathMatch = matchPath(location.pathname, path);
  const hasMatch = pathMatch && pathMatch.isExact;

  const { data } = useGetMyNotifications();
  const hasNotificationsToRead =
    data && data.length > 0 && data.filter((n) => !n.alreadySeen).length > 0;
  const setActiveClass = hasNotificationsToRead || hasMatch ? 'active' : '';

  return (
    <S.Container
      className={`${setActiveClass} notifications-wrapper`}
      onClick={() => history.push(path)}
    >
      <Remind size={24} {...props} />
    </S.Container>
  );
};

export default Notifications;
