import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  width: 100%;
`;

export const TeamWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  align-self: stretch;

  gap: ${toRem(24)};
  padding: 0 ${toRem(180)};
  margin-bottom: ${toRem(120)};
`;
