import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { Header } from './Header/Header';
import { Tabs, TabsProps } from './Tabs/Tabs';
import * as S from './styles';
import Loading from '../Loading/Loading';
import TextButtonWithIcon from '../Buttons/TextButtonWithIcon';

export const ClubManagerDashboard = ({ tabs }: TabsProps) => {
  const history = useHistory();
  const params: { clubId: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const { isMemberOfThisClub } = useClubPermission(currentClub);

  if (!currentClub) return <Loading />;

  const handleGoBack = () => {
    history.push('/v3/clubs');
  };

  if (!isMemberOfThisClub) {
    history.push(`/v3/club/${currentClub.id}`);
  }

  return (
    <>
      <TextButtonWithIcon
        reverse
        icon='back'
        align='flex-end'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <S.Container>
        <Header club={currentClub} maxWidth={false} />
        <Tabs tabs={tabs} />
      </S.Container>
    </>
  );
};
