import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const EmptyBracketGameContainer = styled.div`
  width: 220px;
  height: 80px;
  position: relative;
`;

export const BracketGameContainer = styled.div`
  width: 220px;
  height: 80px;
  position: relative;
  color: ${COLORS.grey50};
  font-family: 'Boxed Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  border-radius: 4px;
  border: 1px solid ${COLORS.grey400};

  p {
    padding: 0;
    margin: 0;
  }
`;

export const BracketGameItem = styled.div`
  height: 40px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :nth-child(2) {
    border-top: 1px solid ${COLORS.grey400};
  }
`;

export const EditBracketName = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(16)};
  transition: ease-in-out 0.3s;
`;

export const BracketEditButton = styled.div`
  height: 24px;
  width: 24px;

  color: ${COLORS.grey200};

  cursor: pointer;

  span {
    ::before {
      content: ''
    }
  }
`

export const SingleBracketGameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  height: 80px;
  position: relative;
  color: ${COLORS.grey50};
  font-family: 'Boxed Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  p {
    padding: 0;
    margin: 0;
  }
`;

export const SingleBracketGameItem = styled.div`
  height: 40px;
  width: 100%;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${COLORS.grey400};
`

export const BracketLineDot =
  styled.div <
  {
    color: string,
    isSingleItem?: boolean;
    position: 'left' | 'right',
  } >
  `
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${({ color }) => color};

  position: absolute;
  top: 50%;
  ${({ position, isSingleItem }) =>
    position === 'right'
      ? `
    left: 100%;
    transform: translate(-${isSingleItem ? 3 : 2}px, -1px);
  `
      : `
    right: 100%;
    transform: translate(${isSingleItem ? 3 : 2}px, -1px);
  `}
`;

export const BracketHorizontalLine = styled.div<{ color: string; position: 'left' | 'right'; isSingleItem?: boolean }>`
  position: absolute;
  bottom: ${({isSingleItem}) => isSingleItem ? '38px' : '37px'};
  width: 52px;
  height: 42px;
  border-bottom: 1px solid ${({ color }) => color};

  ${({position}) => position === 'right' ? 'left: 100%;' : 'right: 100%;'}
`;

export const BracketLine = styled.div<{
  color: string;
  position: 'left' | 'right';
  extend?: boolean;
  extendTo?: boolean;
  isSingleItem?: boolean;
  reverseY?: boolean;
}>`
  position: absolute;
  bottom: ${({ isSingleItem }) => (isSingleItem ? '38px' : '37px')};
  height: ${({ extend }) => (extend ? '96px' : '42px')};
  border-bottom: 1px solid ${({ color }) => color};
  ${({ position, color }) =>
    position === 'right'
      ? `
    width: 52px;
    left: 100%;
    border-right: 1px solid ${color};
    border-bottom-right-radius: 8px;
  `
      : `
    width: 31px;
    right: 100%;
    border-left: 1px solid ${color};
    border-bottom-left-radius: 8px;
  `};
  ${({ reverseY, isSingleItem, position, extend }) => {
    let transform = '';

    if (reverseY) {
      transform += `scaleY(-1) ${
        extend ? 'translateY(-95px)' : 'translateY(-41px)'
      }`;
    }

    if (isSingleItem) {
      transform +=
        position === 'right' ? 'translateX(-1px)' : 'translateX(1px)';
    }

    if (transform) {
      return `transform: ${transform};`;
    }
  }}
`;

export const BracketGameTitle = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  left: 0;
  bottom: 100%;
  padding-bottom: 6px;
  color: #fff;
  font-family: 'Boxed Regular Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  input {
    background-color: transparent;
    border: none;
    border-bottom: ${toRem(1)} solid ${COLORS.white};
    color: ${COLORS.white};
    width: 100%;

    :focus {
      outline: none;
    }
  }

  span {
    cursor: pointer;
  }
`;

export interface BracketGameLine {
  up?: boolean;
  down?: boolean;
  side?: boolean;
  color?: string;
}

export interface BracketLinesProps {
  to?: BracketGameLine;
  from?: BracketGameLine;
  isSingleItem?: boolean;
  noDots?: boolean;
  extend?: boolean;
  extendTo?: boolean;
}

export const BracketLines: FunctionComponent<BracketLinesProps> = ({
  from,
  to,
  isSingleItem,
  noDots = false,
  extend,
  extendTo,
}) => {
  return (
    <>
      {from && (
        <>
          {!noDots && (
            <BracketLineDot
              position='left'
              isSingleItem={isSingleItem}
              color={from.color ?? COLORS.supportError}
            />
          )}
          {from.up && (
            <BracketLine
              position='left'
              isSingleItem={isSingleItem}
              color={from.color ?? COLORS.supportError}
              extend={extend}
            />
          )}
          {from.down && (
            <BracketLine
              position='left'
              isSingleItem={isSingleItem}
              color={from.color ?? COLORS.supportError}
              extend={extend}
              reverseY
            />
          )}
          {from.side && (
            <BracketHorizontalLine
              position='left'
              isSingleItem={isSingleItem}
              color={from.color ?? COLORS.supportError}
            />
          )}
        </>
      )}
      {to && (
        <>
          {!noDots && (
            <BracketLineDot
              position='right'
              isSingleItem={isSingleItem}
              color={to.color ?? COLORS.brandPrimary}
            />
          )}
          {to.up && (
            <BracketLine
              position='right'
              isSingleItem={isSingleItem}
              color={to.color ?? COLORS.brandPrimary}
              extend={extend || extendTo}
            />
          )}
          {to.down && (
            <BracketLine
              position='right'
              isSingleItem={isSingleItem}
              color={to.color ?? COLORS.brandPrimary}
              extend={extend || extendTo}
              reverseY
            />
          )}
          {to.side && (
            <BracketHorizontalLine
              position='right'
              isSingleItem={isSingleItem}
              color={to.color ?? COLORS.brandPrimary}
            />
          )}
        </>
      )}
    </>
  );
};
