import React from 'react';
import { BodyLBold, BodyM } from 'styles/v3/variables';
import { DocumentsProps } from './types';

import * as S from './styles';

export const Documents = ({ user }: DocumentsProps) => {
  return (
    <S.Container>
      <BodyLBold className='title'>Documents</BodyLBold>
      <S.DocumentList>
        <BodyM>Photo</BodyM>
        <S.StatusTag $status={user.photoVerified}>
          {user.photoVerified}
        </S.StatusTag>
        <BodyM>Proof Of Age</BodyM>
        <S.StatusTag $status={user.ageVerified}>{user.ageVerified}</S.StatusTag>
        <BodyM>SafeSport</BodyM>
        <S.StatusTag $status={user.documentationVerified}>
          {user.documentationVerified}
        </S.StatusTag>
      </S.DocumentList>
    </S.Container>
  );
};
