import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { BodyM, COLORS } from 'styles/v3/variables';

interface MultiplierWrapper {
  multiplierColor: string;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${toRem(24)};

  padding: ${toRem(16)};
  border-radius: ${toRem(16)};
  background-color: ${COLORS.grey900};

  @media screen and (max-width: ${BREAKPOINTS.smallMobile}) {
    gap: ${toRem(10)};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${BodyM} {
    width: ${toRem(83)};
    text-align: left !important;
  }
`;

export const MultiplierWrapper =
  styled.div <
  MultiplierWrapper >
  `
  display: flex;
  gap: ${toRem(22)};

  h3 {
    color: ${(props) => props.multiplierColor};
    margin-bottom: 0 !important;
  }
`;
