import styled from 'styled-components';

import { COLORS, BodyLBold } from 'styles/v3/variables';

import { PenaltyShootout } from './PenaltyShootout/PenaltyShootout';

export const Timeline = styled.div`
  width: 100%;

  .vertical-timeline {
    max-width: none;
    width: 100%;

    &:before {
      left: 50%;
      width: 1px;
      margin-left: 0;
      transform: translateX(-50%);
    }
  }

  .vertical-timeline-element-content {
    display: flex;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-left: 0;
    width: 46%;
  }

  .vertical-timeline-element-content-arrow {
    display: none;
  }

  .vertical-timeline-element-icon {
    width: 5px;
    height: 5px;
    top: 50%;
    left: 50%;
    margin-left: 0;
    box-shadow: none;
    background-color: ${COLORS.white};
    transform: translateX(-50%);
    animation: none !important; // Due to library nesty
  }

  .vertical-timeline-element {
    margin: 20px 0;

    &--right {
      .vertical-timeline-element-content {
        float: right;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const TimelineTitle = styled(BodyLBold)`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${COLORS.brandPrimary};
  margin: 15px 0;
`;

export const PenaltyShootoutStyled = styled(PenaltyShootout)`
  margin-bottom: 22px;
`;
