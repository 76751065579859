import React from 'react';

import { Avatar } from 'antd';
import ConvertUtil from 'util/ConvertUtil';
import { TitleH4 } from 'styles/v3/variables';
import { ArrowLeft, ShareOne } from '@icon-park/react';
import { EventView } from 'admin/models/event/Event';

import EventPlaceholder from 'assets/imgs/event_placeholder.png';
import AvatarPlaceholder from 'assets/imgs/avatar_placeholder.png';

import { useHistory } from 'react-router-dom';
import * as S from './styles';

type HeaderType = {
  event?: EventView,
  withLogo?: boolean,
  customName?: string,
  withGobBack?: boolean,
};

export const Header = ({
  event,
  customName,
  withLogo = true,
  withGobBack = false,
}: HeaderType) => {
  const history = useHistory();

  return (
    <S.Container
      $image={
        event?.data?.eventInfo?.header
          ? ConvertUtil.getMinioUrl(event?.data?.eventInfo?.header)
          : EventPlaceholder
      }
    >
      <S.HeaderContent>
        <S.AvatarWrapper>
          {withLogo && (
            <Avatar
              size={68}
              src={
                event?.logo
                  ? ConvertUtil.getMinioUrl(event?.logo)
                  : AvatarPlaceholder
              }
            />
          )}
          <S.TitleWrapper>
            {withGobBack && (
              <ArrowLeft size={24} onClick={() => history.goBack()} />
            )}
            <TitleH4>{customName || event?.name}</TitleH4>
          </S.TitleWrapper>
        </S.AvatarWrapper>
        <ShareOne size={24} />
      </S.HeaderContent>
    </S.Container>
  );
};
