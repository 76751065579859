import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from '../../config.api';
import APIServicesV3 from '../../util/ApiServicesV3';
import ServiceResponseV3 from '../../util/ServiceResponseV3';
import { CheckoutReturnProps, CheckoutProps, SimpleOrderView, MembershipCheckoutProps } from './types';

class OrderService {
  public static async getCheckoutStripeUrl(
    data: CheckoutProps
  ): Promise<ServiceResponseV3<CheckoutReturnProps>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/orders',
      method: 'POST',
      data,
    };
    return APIServicesV3.request<CheckoutReturnProps>(axiosConfig);
  }

  public static async getMembershipCheckoutStripeUrl(
    data: MembershipCheckoutProps
  ): Promise<ServiceResponseV3<CheckoutReturnProps>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: '/orders/membership',
      method: 'POST',
      data,
    };
    return APIServicesV3.request<CheckoutReturnProps>(axiosConfig);
  }

  public static async getContinueCheckoutStripeUrl(
    orderId: string
  ): Promise<ServiceResponseV3<CheckoutReturnProps>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/orders/${orderId}/continue-checkout`,
      method: 'POST',
    };
    return APIServicesV3.request<CheckoutReturnProps>(axiosConfig);
  }

  public static async getOrderById(
    id?: string
  ): Promise<ServiceResponseV3<SimpleOrderView | undefined>> {
    if (!id)
      return ServiceResponseV3.success<SimpleOrderView | undefined>(
        undefined,
        ''
      );

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/orders/${id}`,
      method: 'GET',
    };
    return APIServicesV3.request<SimpleOrderView>(axiosConfig);
  }

  public static async getMyRosterEventOrders(
    eventId?: string
  ): Promise<ServiceResponseV3<SimpleOrderView[]>> {
    if (!eventId) return ServiceResponseV3.success<SimpleOrderView[]>([], '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/orders/event/${eventId}/my-roster-orders`,
      method: 'GET',
    };
    return APIServicesV3.request<SimpleOrderView[]>(axiosConfig);
  }

  public static async getRosterEventOrdersForMyEvent(
    eventId?: string,
    status?: SimpleOrderView['paymentStatus']
  ): Promise<ServiceResponseV3<SimpleOrderView[]>> {
    if (!eventId) return ServiceResponseV3.success<SimpleOrderView[]>([], '');

    const statusParam = status ? `?status=${status}` : '';
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getSelectedAccountConfig(),
      url: `/orders/event/${eventId}/roster-orders${statusParam}`,
      method: 'GET',
    };
    return APIServicesV3.request<SimpleOrderView[]>(axiosConfig);
  }
}
export default OrderService;
