import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from 'components/v3/Elements/Logo/Logo';

import AvatarAction from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';
import useScreenType from 'hooks/useScreenType';
import * as S from './styles';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = ({ auth }) => {
  const userName = ((auth?.tokenParsed as any)?.name as string) ?? '';
  const history = useHistory();

  const screenType = useScreenType();

  const handleGoToHome = () => {
    if (userName) {
      history.push('/v3/home-page');
    } else {
      window.location.href = '/v3/home-page';
    }
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoContainer onClick={handleGoToHome}>
          <Logo minimal={screenType.isMobile} />
        </S.LogoContainer>
        <S.LocaleContainer>{userName && <AvatarAction />}</S.LocaleContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default Header;
