import styled from 'styled-components';
import { lighten } from 'polished';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${toRem(24)};

  background-color: ${COLORS.grey900};
  border-radius: ${toRem(24)};

  justify-content: space-between;
  transition: ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${lighten(0.04, COLORS.grey900)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-flow: wrap;
    justify-content: center;
    gap: ${toRem(16)};
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const TitleAndInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(4)};
  padding-left: ${toRem(28)};
`;

interface CircleDivisionColorProps {
  bgColor?: string;
}

export const CircleAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(8)};
`;

export const CircleDivisionColor =
  styled.div <
  CircleDivisionColorProps >
  `
  width: ${toRem(16)};
  height: ${toRem(16)};
  border-radius: ${toRem(21)};

  background-color: ${({ bgColor }) => bgColor ?? '#FA29C0'};
`;

export const InformationOfDivision = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(5)};
`;

export const RosterIsLockedContainer = styled.div`
  display: flex;
  align-items: center;

  padding-left: ${toRem(28)};
  gap: ${toRem(8)};
  span {
    display: flex;
    ::before {
      display: none;
    }
  }
`;

export const TeamTargetContainer = styled.div`
  margin-right: ${toRem(24)};
  padding: ${toRem(8)} ${toRem(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${toRem(4)};

  border-radius: ${toRem(8)};
  border: ${toRem(1)} solid ${COLORS.grey800};
`;
