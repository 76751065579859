import React, { useState } from 'react';
import { CheckOne, CloseOne } from '@icon-park/react';
import { GetApplicationsByEventIdResponse } from 'services/v3/RefereeApplication/types';
import { AlertModal } from './components/AlertModal';
import * as S from './styles';

type ActionProps = GetApplicationsByEventIdResponse;

export const Action = ({ eventId, referee }: ActionProps) => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [type, setType] = useState<'disapprove' | 'approve'>('approve');

  const onOpen = (openType: 'disapprove' | 'approve') => {
    setType(openType);
    setIsAlertModalOpen(true);
  };

  return (
    <S.Container>
      <AlertModal
        type={type}
        eventId={eventId}
        refereeId={referee.id}
        isOpen={isAlertModalOpen}
        onClose={() => setIsAlertModalOpen(false)}
      />
      <CloseOne onClick={() => onOpen('disapprove')} />
      <CheckOne onClick={() => onOpen('approve')} />
    </S.Container>
  );
};
