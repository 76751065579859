import styled from 'styled-components';

import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  border-radius: 24px;
  padding: 16px 24px 24px 24px;
  background-color: ${COLORS.grey950};
`;

export const Header = styled.div`
  display: flex;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  justify-content: space-between;
  align-items: center;

  button {
    color: ${COLORS.grey50};
    border: 0px 0px 4px 0px;
    border-color: ${COLORS.grey800};
    background-color: ${COLORS.grey800};
    padding: 0.5rem;
    min-height: 0px;
    &:hover {
      color: ${COLORS.grey50};
    }
  }

  .button-formating {
    font-family: 'Boxed Regular';
  }
`;

export const TitleBox = styled.div`
  display: flex;
  height: 3.5rem;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const Title = styled.div`
  color: var(--grey-200, #d3d4d0);
  /* Body/L */
  font-family: Boxed;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.5rem */
`;

export const DivisionsBox = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const DivisionIcon = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--grey-800, #52544c);
`;
