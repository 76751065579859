/* eslint-disable react/display-name */
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { useHistory } from 'react-router-dom';
import Icon, { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { MenuTab } from './types';
import { Container, StyledMenuItem, Subtitle, TabItem, Title } from './styles';

type Strategy = 'division' | 'games';

interface SideMenuProps {
  hideGoBack?: boolean;
  isDropdown?: boolean;
  strategy?: Strategy;
  title: string;
  subtitle?: string;
  items: MenuTab[];
  selectedId?: string;
  onSelection: (id: string) => void;
  onGoBack?: () => void;
  schedule?: boolean;
}

export const EventSideMenu: FunctionComponent<SideMenuProps> = ({
  hideGoBack,
  isDropdown,
  title,
  subtitle = 'YOUR AGE DIVISIONS',
  items,
  selectedId,
  onSelection,
  onGoBack,
  schedule,
}) => {
  const history = useHistory();

  const selectedItem = useMemo(() => {
    return items.find((item) => item.id === selectedId);
  }, [items, selectedId]);

  const handleGoBack = () => {
    if (onGoBack && typeof onGoBack === 'function') {
      onGoBack();
      return;
    }

    history.goBack();
  };

  const handleItemClick = (id: string) => {
    if (id !== selectedId) {
      onSelection(id);
    }
  };

  useEffect(() => {
    if (!selectedId && items.length > 0) {
      const firstItem = items[0];
      onSelection(firstItem.id);
    }
  }, []);

  return (
    <Container schedule={!!schedule}>
      {!hideGoBack && (
        <TextButtonWithIcon
          reverse
          icon='back'
          color='primary'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
      )}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {isDropdown ? (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu selectable>
              {items.map((item) => (
                <StyledMenuItem
                  key={item.id}
                  onClick={() => handleItemClick(item.id)}
                >
                  {item.title}
                </StyledMenuItem>
              ))}
            </Menu>
          }
        >
          {selectedItem && (
            <TabItem
              key={selectedItem.id}
              $status={selectedItem.status}
              $selected={selectedItem.id === selectedId}
            >
              {selectedItem.title}
              {selectedItem.status === 'warn' && (
                <Icon type='attention' size={24} />
              )}
              {selectedItem.status === 'ok' && (
                <Icon type='check-small' color='#CAFC01' size={24} />
              )}
              <DownOutlined height={20} width={20} />
            </TabItem>
          )}
        </Dropdown>
      ) : (
        <ul>
          {items.map((item) => (
            <TabItem
              key={item.id}
              onClick={() => handleItemClick(item.id)}
              $status={item.status}
              $selected={item.id === selectedId}
            >
              {item.title}
              {item.status === 'warn' && <Icon type='attention' size={24} />}
              {item.status === 'ok' && (
                <Icon type='check-small' color='#CAFC01' size={24} />
              )}
            </TabItem>
          ))}
        </ul>
      )}
    </Container>
  );
};
