import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: ${toRem(16)};
  justify-content: center;

  gap: ${toRem(6)};

  border-radius: ${toRem(8)};
  border: ${toRem(2)} dashed ${COLORS.grey800};
`;
