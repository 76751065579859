import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import React from 'react';
import { BodyLBold, BodyM } from 'styles/v3/variables';

import * as S from '../../styles';

type RosterRulesProps = {
  rosterLockDate?: string;
  allowMultipleTeamsSameClubAndSameAge: boolean;
  setAllowMultipleTeamsSameClubAndSameAge: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export const RosterRules = ({
  rosterLockDate,
  allowMultipleTeamsSameClubAndSameAge,
  setAllowMultipleTeamsSameClubAndSameAge,
}: RosterRulesProps) => {
  return (
    <fieldset>
      <legend>
        <BodyLBold $color='white'>Roster Rules</BodyLBold>
      </legend>
      <InputDate
        id='rosterLockDate'
        placeholder='Roster lock date'
        label='Roster lock date'
        defaultValue={rosterLockDate}
        containerClassName='general-rules-input'
      />
      <S.ElementCheckboxWrapper>
        <InputCheckbox
          id='allowMultipleTeamsSameClubAndSameAge'
          name='allowMultipleTeamsSameClubAndSameAge'
          onChange={() =>
            setAllowMultipleTeamsSameClubAndSameAge((prev) => !prev)
          }
          checked={allowMultipleTeamsSameClubAndSameAge}
        />
        <BodyM>
          Allow multiple teams from the same club to play in the same age
          division?
        </BodyM>
      </S.ElementCheckboxWrapper>
    </fieldset>
  );
};
