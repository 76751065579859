import moment from 'moment';

import { SoccerPeriod } from 'services/v3/Match/enums';

import { IMatchInfo } from './components/Scoresheet/interfaces';
import { DEFAULT_MATCH_INFO_VALUE } from './config';

const MATCH_INFO_KET = 'match-info';

export const storeMatchInfoInLocalStorage = ({
  soccer,
  time,
  playGame,
}: IMatchInfo) => {
  const seconds = time.minutes() * 60 + time.seconds();

  localStorage.setItem(
    MATCH_INFO_KET,
    JSON.stringify({
      soccer,
      time: seconds,
      playGame,
    })
  );
};

export const getMatchInfoFromLocalStorage = (): IMatchInfo => {
  const matchInfo = localStorage.getItem(MATCH_INFO_KET);

  if (matchInfo) {
    const parsedMatchInfo: {
      soccer: SoccerPeriod,
      time: string,
      playGame: boolean,
    } = JSON.parse(matchInfo);

    return {
      soccer: parsedMatchInfo.soccer,
      time: moment('00:00', 'mm:ss').add(parsedMatchInfo.time, 'seconds'),
      playGame: parsedMatchInfo.playGame,
    };
  }

  return DEFAULT_MATCH_INFO_VALUE;
};

export const removeMatchInfoFromLocalStorage = () => {
  localStorage.removeItem(MATCH_INFO_KET);
};
