import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';

export const CalendarHeaderContainer = styled.div`
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CalendarHeaderGameSelector = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  list-style: none;
`;

export const CalendarHeaderGameSelectorItem = styled.li`
  cursor: pointer;
`;

export const CalendarHeaderButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  .btn-place,
  .btn-save {
    img {
      width: 24px;
      height: 24px;
    }

    background-color: transparent;
    border: 1px solid #cafc01;
    color: #cafc01;

    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  }
`;

export const OverlayInnerStyle = {
  color: COLORS.white,
  borderRadius: '10px',
  padding: '10px 20px',
  fontFamily: 'Boxed Regular',
  fontSize: '13px',
  lineHeight: '15px',
  cursor: 'pointer',
};
