import * as React from 'react';
import styled from 'styled-components';
import { camelCase } from 'lodash';

import { COLORS } from 'styles/v3/variables';
import BasicButton, { ButtonProps } from './BasicButton';

type ThemeColor = 'primary' | 'dark-primary' | 'dark-white' | 'white-dark' | 'secondary' | 'support-error';
export interface FilledButtonProps extends ButtonProps {
  color?: ThemeColor;
  type?: 'submit' | 'reset' | 'button';
}

const FilledButton: React.FC<FilledButtonProps> = ({
  color,
  children,
  ...props
}) => (
  <FilledButtonStyle $theme={color} {...props}>
    {children}
  </FilledButtonStyle>
);

const FilledButtonStyle = styled(BasicButton)<{
  $theme?: ThemeColor;
}>`
  color: ${(props) => {
    if (props.$theme) {
      return props.theme.buttons.filled[camelCase(props.$theme)].color;
    }
    return props.theme.buttons.default.color;
  }};
  background-color: ${(props) => {
    if (props.$theme) {
      return props.theme.buttons.filled[camelCase(props.$theme)].bgColor;
    }
    return props.theme.buttons.default.bgColor;
  }};
  border-color: ${(props) => {
    if (props.$theme) {
      return props.theme.buttons.filled[camelCase(props.$theme)].bgColor;
    }
    return props.theme.buttons.default.bgColor;
  }};

  &:disabled {
    cursor: not-allowed;

    border: 0;
    opacity: 1;
    color: ${COLORS.grey300};
    background-color: ${COLORS.grey600};
  }

  &:hover {
    color: ${(props) => {
      if (props.$theme) {
        return props.theme.buttons.filled[camelCase(props.$theme)].bgColor;
      }
      return props.theme.buttons.default.bgColor;
    }};
    background-color: ${(props) => {
      if (props.$theme) {
        return props.theme.buttons.filled[camelCase(props.$theme)].color;
      }
      return props.theme.buttons.default.color;
    }};
    border-color: ${(props) => {
      if (props.$theme) {
        return props.theme.buttons.filled[camelCase(props.$theme)].color;
      }
      return props.theme.buttons.default.color;
    }};
  }
`;

export default FilledButton;
