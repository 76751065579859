import { Styles } from 'react-modal';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const cellHeight = 10;
export const stepperIntervals = 5;
export const baseLine = 24;
export const lines = baseLine * (60 / stepperIntervals);
export const matchLenght = 30;
export const perTableMaxColumn = 8;
export const tooltipHeight = 100;
export const tooltipWidth = 270;
export const infoTooltipHeight = 100;
export const infoTooltipWidth = 270;
export const errorTooltipHeight = 70;
export const errorTooltipWidth = 230;

export const getModalStyles = (width?: string): Styles => {
  return {
    overlay: {
      zIndex: 999,
      backgroundColor: 'rgba(0, 0 ,0, 0.9)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      borderRadius: toRem(12),
      background: COLORS.grey900,
      maxWidth: width ?? '384px',
      width: '100%',
      position: 'static',
      borderWidth: '0',
      padding: '0',
      margin: '0',
    },
  };
};

export const colorGroup: Record<string, string> = {
  '#000000': '#fff',
  '#434343': '#fff',
  '#666666': '#fff',
  '#980000': '#fff',
  '#ff0000': '#fff',
  '#0000ff': '#fff',
  '#9900ff': '#fff',
  '#ff00ff': '#fff',
  '#cc4125': '#fff',
  '#a61c00': '#fff',
  '#cc0000': '#fff',
  '#45818e': '#fff',
  '#3c78d8': '#fff',
  '#3d85c6': '#fff',
  '#674ea7': '#fff',
  '#a64d79': '#fff',
  '#85200c': '#fff',
  '#990000': '#fff',
  '#b45f06': '#fff',
  '#38761d': '#fff',
  '#134f5c': '#fff',
  '#1155cc': '#fff',
  '#0b5394': '#fff',
  '#351c75': '#fff',
  '#741b47': '#fff',
  '#5b0f00': '#fff',
  '#660000': '#fff',
  '#783f04': '#fff',
  '#7f6000': '#fff',
  '#274e13': '#fff',
  '#0c343d': '#fff',
  '#1c4587': '#fff',
  '#073763': '#fff',
  '#20124d': '#fff',
  '#4c1130': '#fff',
  '#999999': '#000',
  '#b7b7b7': '#000',
  '#cccccc': '#000',
  '#d9d9d9': '#000',
  '#efefef': '#000',
  '#f3f3f3': '#000',
  '#ffffff': '#000',
  '#ff9900': '#000',
  '#ffff00': '#000',
  '#00ff00': '#000',
  '#00ffff': '#000',
  '#4a86e8': '#000',
  '#e6b8af': '#000',
  '#f4cccc': '#000',
  '#fce5cd': '#000',
  '#fff2cc': '#000',
  '#d9ead3': '#000',
  '#d0e0e3': '#000',
  '#c9daf8': '#000',
  '#cfe2f3': '#000',
  '#d9d2e9': '#000',
  '#ead1dc': '#000',
  '#dd7e6b': '#000',
  '#ea9999': '#000',
  '#f9cb9c': '#000',
  '#ffe599': '#000',
  '#b6d7a8': '#000',
  '#a2c4c9': '#000',
  '#a4c2f4': '#000',
  '#9fc5e8': '#000',
  '#b4a7d6': '#000',
  '#d5a6bd': '#000',
  '#e06666': '#000',
  '#f6b26b': '#000',
  '#ffd966': '#000',
  '#93c47d': '#000',
  '#76a5af': '#000',
  '#6d9eeb': '#000',
  '#6fa8dc': '#000',
  '#8e7cc3': '#000',
  '#c27ba0': '#000',
  '#e69138': '#000',
  '#f1c232': '#000',
  '#6aa84f': '#000',
  '#bf9000': '#000',
};
