import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: ${toRem(64)};

  @media screen and (max-width: ${BREAKPOINTS.desktop}) {
    padding: ${toRem(32)} ${toRem(32)};
  }

  @media screen and (max-width: ${BREAKPOINTS.tablet}) {
    gap: ${toRem(32)};
    align-items: center;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: ${toRem(32)};

  @media screen and (max-width: ${BREAKPOINTS.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${toRem(128)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: ${toRem(32)};
  }
`;
