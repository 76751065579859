import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import Icon from '@icon-park/react/es/all';
import { Check, Close } from '@icon-park/react';

import BracketService from 'services/v3/Brackets/BracketService';
import { COLORS } from 'styles/v3/variables';

import { BracketSource, MatchV3Model } from 'models/Match/MatchModel';
import { useParams } from 'react-router-dom';
import { useGetBracketsByEventId } from 'hooks/v3/brackets/useGetBracketsByEventId/useGetBracketsByEventId';
import {
  BracketEditButton,
  BracketGameContainer,
  BracketGameItem,
  BracketGameLine,
  BracketGameTitle,
  BracketLines,
  EmptyBracketGameContainer,
  SingleBracketGameContainer,
  SingleBracketGameItem,
  EditBracketName,
} from './styles';
import { useUpdateMatchesEvent } from 'hooks/v3/matches/useUpdateMatchesEvent/useUpdateMatchesEvent';

interface BracketGameProps {
  match: MatchV3Model;
  bracketId: string;
  to?: BracketGameLine;
  from?: BracketGameLine;
  extend?: boolean;
  extendTo?: boolean;
  showPens?: boolean;
  titleSourceEmpty?: string;
  onlyOneOpponent?: 'ALL-OPPONENTS' | 'ONLY-OPPONENT1';
  onUpdate?: () => void;
  handleOpenEditModal?: (
    bracketId: string,
    opponent: number,
    matchId: string
  ) => void;
}

interface SingleItemBracketGameProps {
  title: string;
  to?: BracketGameLine;
  from?: BracketGameLine;
}

export const SingleItemBracketGame: FunctionComponent<SingleItemBracketGameProps> = ({
  title,
  from,
  to,
}) => {
  return (
    <SingleBracketGameContainer>
      <SingleBracketGameItem>{title}</SingleBracketGameItem>
      <BracketLines to={to} from={from} isSingleItem />
    </SingleBracketGameContainer>
  );
};

export const BracketGame: FunctionComponent<BracketGameProps> = ({
  match,
  bracketId,
  from,
  to,
  extend,
  extendTo,
  handleOpenEditModal,
  onUpdate,
  titleSourceEmpty = 'TBA',
  onlyOneOpponent = 'ALL-OPPONENTS',
  showPens = false,
}) => {
  const [editBracketName, setEditBracketName] = useState(false);
  const [matchName, setMatchName] = useState('');

  const params: { eventId: string } = useParams();
  const { data: brackets } = useGetBracketsByEventId(params.eventId);
  const { mutateAsync: updateMatchName } = useUpdateMatchesEvent();

  const pools = useMemo(() => {
    return brackets.find((bracket) => bracket.id === bracketId)?.ageDivision
      .pools;
  }, [brackets]);

  // const getBracketSourceTeamName = (
  //   opponent: BracketMatchOpponent,
  //   opponentNumber: 1 | 2
  // ) => {
  //   let name =
  //     onlyOneOpponent === 'ONLY-OPPONENT1' && opponentNumber === 2
  //       ? 'TBA'
  //       : titleSourceEmpty;
  //   if (opponent?.source) {
  //     switch (opponent.source) {
  //       case 'DIVISION':
  //         if (opponent.seed !== null) name = `Division ${opponent.seed}`;
  //         break;
  //       case 'POOL':
  //         if (opponent.seed !== null) name = `Pool ${opponent.seed}`;
  //         break;
  //       case 'LOSER':
  //         if (opponent.loserGame !== null) name = `Loser ${opponent.loserGame}`;
  //         break;
  //       case 'WINNER':
  //         if (opponent.winnerGame !== null)
  //           name = `Winner ${opponent.winnerGame}`;
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  //   return name;
  // };

  const getPoolName = (poolId: string | undefined) => {
    const pool = pools?.find((p) => p.id === poolId);
    return `${pool?.name} ${pool?.order}`;
  };

  const getPosition = (position: number | undefined) => {
    if (position === undefined) return '';
    if (position === 1) return '1st';
    if (position === 2) return '2nd';
    if (position === 3) return '3rd';
    return `${position}th`;
  };

  useEffect(() => { 
    const name = match?.name || 'Name Placeholder';
    setMatchName(name);
  }, [match]);

  const getBracketSourceTeamName2 = (bracketSource?: BracketSource) => {
    if (bracketSource?.matchId || bracketSource?.poolId) {
      switch (bracketSource.type) {
        case 'POOL':
          if (bracketSource.poolPosition)
            return `${getPosition(bracketSource.poolPosition)} in ${getPoolName(
              bracketSource?.poolId
            )}`;
          break;
        case 'MATCH':
          return `TBA`;
        default:
          break;
      }
    } else {
      return 'Select Source';
    }
    return '';
  };

  const handleUpdateMatchName = useCallback(async () => { 
    await updateMatchName([{ matchId: match.matchId!, name: matchName }]);
    if (onUpdate) onUpdate();
    setEditBracketName(false);
  }, [matchName]);

  const handleChangeSource = (opponent: 1 | 2) => {
    if (handleOpenEditModal) {
      handleOpenEditModal(bracketId, opponent, match?.matchId ?? '');
    }
  };

  return (
    <BracketGameContainer>
      <BracketGameItem>
        <p>
          {getBracketSourceTeamName2(match?.homeTeamBracketSource) ??
            'Select Source'}
        </p>
        {match?.homeTeamBracketSource?.type !== 'MATCH' && showPens && (
          <BracketEditButton onClick={() => handleChangeSource(1)}>
            <Icon type='edit' size={24} />
          </BracketEditButton>
        )}
      </BracketGameItem>
      <BracketGameItem>
        <p>
          {getBracketSourceTeamName2(match?.awayTeamBracketSource) ??
            'Select Source'}
        </p>
        {match?.awayTeamBracketSource?.type !== 'MATCH' && showPens && (
          <BracketEditButton onClick={() => handleChangeSource(2)}>
            <Icon type='edit' size={24} />
          </BracketEditButton>
        )}
      </BracketGameItem>
      <BracketGameTitle>
        {editBracketName ? (
          <>
            <EditBracketName>
              <input
                id={`id_edit_bracket_name_${match.id}`}
                defaultValue={matchName}
                onChange={(e) => setMatchName(e.target.value)}
              />
              <Close
                size={20}
                className='reset'
                fill={COLORS.grey600}
                onClick={() => setEditBracketName(false)}
              />

              <Check
                size={20}
                fill={COLORS.brandPrimary}
                className='reset'
                onClick={handleUpdateMatchName}
              />
            </EditBracketName>
          </>
        ) : (
          <>
            <p>{matchName}</p>
              <BracketEditButton onClick={() => setEditBracketName(true)}>
              <Icon type='edit' size={20} />
            </BracketEditButton>
          </>
        )}
      </BracketGameTitle>
      <BracketLines to={to} from={from} extend={extend} extendTo={extendTo} />
    </BracketGameContainer>
  );
};

export const EmptyBracketGame: FunctionComponent<
  Omit<BracketGameProps, 'match' | 'bracketId'>
> = ({ to, from }) => {
  return (
    <EmptyBracketGameContainer>
      <div />
      <BracketLines noDots to={to} from={from} />
    </EmptyBracketGameContainer>
  );
};

export default BracketGame;
