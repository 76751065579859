import React, { useMemo } from 'react';
import { TitleH4, BodyM, COLORS, BodyL } from 'styles/v3/variables';
import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';
import { useParams } from 'react-router-dom';

import { useGetScheduleByEventId } from 'hooks/v3/schedules/useGetScheduleByEventId/useGetScheduleByEventId';
import { ScheduleDay, ScheduleMatch } from 'models/Schedule/ScheduleModel';
import * as S from './styles';
import { ScheduleByCourtData, ScheduleProps } from './types';
import { getFormattedDate, getFormattedDateTime } from '../../util';
import { PrintButton } from '../PrintButton/PrintButton';

const PrintSchedule: React.FC<ScheduleProps> = ({
  type,
  selectedCourt,
  selectedLocation,
}: ScheduleProps) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data: currentEvent } = useGetEventById(eventId);

  const { data: currentSchedule } = useGetScheduleByEventId(eventId);

  const period = useMemo(() => {
    if (!currentEvent) return '';
    return `${getFormattedDate(
      `${currentEvent.startDate}`,
      'short'
    )} - ${getFormattedDate(`${currentEvent.endDate}`, 'long')}`;
  }, [currentEvent]);

  // ENG-240 TODO edited after change ScheduledModel/ScheduledDays
  const scheduleDataList = useMemo(() => {
    const defaultValue: ScheduleByCourtData[] = [
      {
        id: 0,
        tournamentName: 'Loading',
        venue: 'Loading',
        period: 'not set',
        items: [],
      },
    ];
    if (!currentEvent) return defaultValue;

    const days: ScheduleDay[] = currentSchedule?.days ?? [];

    const groupedMatches: ScheduleMatch[][] = [];
    const allMatches: ScheduleMatch[] = days.reduce((acc, day) => {
      if (day?.matches) {
        acc.push(...day.matches);
      }
      return acc;
    }, [] as ScheduleMatch[]);

    if (type === 'byCourt') {
      allMatches.sort((a, b) => a.courtName.localeCompare(b.courtName));

      allMatches.forEach((match) => {
        if (!groupedMatches.length) {
          groupedMatches.push([match]);
        } else {
          const lastIndex = groupedMatches.length - 1;
          if (
            groupedMatches[lastIndex].find(
              (m) => m.courtName !== match.courtName
            )
          ) {
            groupedMatches.push([match]);
          } else {
            groupedMatches[lastIndex].push(match);
          }
        }
      });
    }

    if (type === 'byTimePerLocation') {
      allMatches.sort((a, b) =>
        `${a?.venueName}`.localeCompare(`${b?.venueName}`)
      );

      allMatches.forEach((match) => {
        if (!groupedMatches.length) {
          groupedMatches.push([match]);
        } else {
          const lastIndex = groupedMatches.length - 1;
          if (
            groupedMatches[lastIndex].find(
              (m) => m?.venueName !== match?.venueName
            )
          ) {
            groupedMatches.push([match]);
          } else {
            groupedMatches[lastIndex].push(match);
          }
        }
      });
    }

    return groupedMatches.map((group, index) => {
      group.sort(
        (a, b) =>
          new Date(a?.startTime ?? '').getTime() -
          new Date(b?.startTime ?? '').getTime()
      );

      const venue =
        type === 'byTimePerLocation'
          ? group[0]?.venueName ?? ''
          : `${group[0]?.venueName ?? ''} - ${group[0]?.courtName ?? ''}`;

      return {
        id: index,
        tournamentName: currentEvent?.name ?? '',
        venue,
        period,
        items: group.map((day) => {
          const currentDivision = currentEvent.ageDivisions?.find(
            (division) => division.id === day.ageDivisionId
          );

          let divisionName = 'Division';

          if (currentDivision) {
            divisionName = currentDivision.name ?? 'Division';
            const divisionYears = currentDivision.years.join('/');
            const divisionGender =
              currentDivision.gender[0].toUpperCase() +
              currentDivision.gender.toLowerCase().slice(1);
            divisionName = `${divisionName} ${divisionYears} ${divisionGender}`;
          }

          return {
            ...day,
            divisionName,
          };
        }),
      };
    });
  }, [currentSchedule, currentEvent, type, selectedCourt, selectedLocation]);

  return (
    <S.Wrapper>
      <PrintButton text='Print Schedule' />
      <S.Header>
        <TitleH4>{currentEvent?.name ?? ''}</TitleH4>
        <BodyM style={{ color: COLORS.grey400 }}>{period}</BodyM>
      </S.Header>

      {currentEvent && scheduleDataList.length === 0 && (
        <BodyM style={{ textAlign: 'center' }}>
          No games scheduled for this tournament
        </BodyM>
      )}

      {scheduleDataList.map((scheduleData, idx) => (
        <S.CustomTableContainer key={scheduleData.id}>
          <BodyL style={{ marginBottom: '20px', textAlign: 'center' }}>
            {scheduleData.venue}
          </BodyL>

          <>
            <S.CustomTable>
              <thead>
                <tr>
                  <S.CustomTableHeader>Date</S.CustomTableHeader>
                  <S.CustomTableHeader>Time</S.CustomTableHeader>
                  <S.CustomTableHeader>Venue</S.CustomTableHeader>
                  <S.CustomTableHeader>Division</S.CustomTableHeader>
                  <S.CustomTableHeader>Team</S.CustomTableHeader>
                  <S.CustomTableHeader style={{ textAlign: 'center' }}>
                    Score
                  </S.CustomTableHeader>
                  <S.CustomTableHeader style={{ textAlign: 'center' }}>
                    Score
                  </S.CustomTableHeader>
                  <S.CustomTableHeader>Team</S.CustomTableHeader>
                  <S.CustomTableHeader>Type</S.CustomTableHeader>
                </tr>
              </thead>
              <tbody>
                {scheduleData.items.map((item, index) => (
                  <S.CustomTableRow index={index} key={item.matchId}>
                    <S.CustomTableData>
                      {getFormattedDateTime(`${item.startTime ?? ''}`, 'date')}
                    </S.CustomTableData>
                    <S.CustomTableData>
                      {getFormattedDateTime(`${item.startTime}`, 'time')}
                    </S.CustomTableData>
                    <S.CustomTableData>
                      {item.venueName} - {item.courtName}
                    </S.CustomTableData>
                    <S.CustomTableData>{item.divisionName}</S.CustomTableData>
                    <S.CustomTableData>
                      {item.opponent1.clubName ?? 'TBA'}
                    </S.CustomTableData>
                    <S.CustomTableData style={{ textAlign: 'center' }}>
                      {item.opponent1.score}
                    </S.CustomTableData>
                    <S.CustomTableData style={{ textAlign: 'center' }}>
                      {item?.opponent2.score}
                    </S.CustomTableData>
                    <S.CustomTableData>
                      {item?.opponent2.clubName ?? 'TBA'}
                    </S.CustomTableData>
                    <S.CustomTableData>
                      {item.type === 'POOL_GAMES'
                        ? 'Pool game'
                        : 'Bracket game'}
                    </S.CustomTableData>
                  </S.CustomTableRow>
                ))}
              </tbody>
            </S.CustomTable>
          </>
        </S.CustomTableContainer>
      ))}
    </S.Wrapper>
  );
};

export default PrintSchedule;
