import styled from 'styled-components';
import { Avatar } from 'antd';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { COLORS, BodyM } from 'styles/v3/variables';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 8px;
  width: fit-content;
`;

export const AvatarProfile = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 213px;
  height: 213px;
  margin-bottom: 6px;
  border-radius: 12px;
  background-color: #676865;

  .anticon {
    width: 40%;
    height: 40%;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #cbcbcb;
  }
`;

export const Name = styled.p`
  display: block;
  margin-bottom: 0;
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.grey50};
`;

export const NumberSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const EditNumberButton = styled(FilledButton)`
  background-color: ${COLORS.white};
  padding: 0 8px;
  min-height: 28px;
  border-radius: 6px;
  border-color: ${COLORS.white};
  font-family: 'Boxed Medium';
  font-weight: 500;
  font-size: 14px;
`;

export const TogglerWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 12px;
`;

export const PlayerAttendStatus = styled(BodyM)`
  &.selected {
    color: ${COLORS.brandPrimary};
  }
`;
