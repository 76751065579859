/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { BodyM, TitleH3 } from 'styles/v3/variables';
import useScreenType from 'hooks/useScreenType';
import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard';

import * as S from './styles';

interface PaymentCardProps extends RoundedCardProps {
  title: string;
  description: string;
  theme: 'success' | 'error';
  buttonLabel: string;
  link?: string;
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CardSuccess = () => (
  <svg
    className='card-icon'
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='100'
    viewBox='0 0 100 100'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M94.6129 67.8874C96.2401 69.5146 96.2401 72.1528 94.6129 73.7799L77.9463 90.4466C76.3191 92.0738 73.6809 92.0738 72.0537 90.4466L63.7204 82.1133C62.0932 80.4861 62.0932 77.8479 63.7204 76.2207C65.3476 74.5935 67.9858 74.5935 69.6129 76.2207L75 81.6078L88.7204 67.8874C90.3476 66.2602 92.9858 66.2602 94.6129 67.8874Z'
      fill='#CAFC01'
    />
    <path
      d='M25 62.4997C25 60.1985 26.8655 58.333 29.1667 58.333H45.8333C48.1345 58.333 50 60.1985 50 62.4997C50 64.8009 48.1345 66.6663 45.8333 66.6663H29.1667C26.8655 66.6663 25 64.8009 25 62.4997Z'
      fill='#CAFC01'
    />
    <path d='M8.33398 37.5H91.6673V45.8333H8.33398V37.5Z' fill='#CAFC01' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.3286 16.667H67.6727C71.0268 16.6669 73.795 16.6669 76.0499 16.8511C78.392 17.0425 80.5449 17.4532 82.5672 18.4836C85.7032 20.0814 88.2529 22.6311 89.8508 25.7672C90.8811 27.7894 91.2918 29.9423 91.4832 32.2844C91.6674 34.5393 91.6674 37.3075 91.6673 40.6615V54.167C91.6673 56.4682 89.8018 58.3337 87.5007 58.3337C85.1995 58.3337 83.334 56.4682 83.334 54.167V40.8337C83.334 37.2646 83.3307 34.8384 83.1775 32.963C83.0283 31.1363 82.7578 30.2021 82.4257 29.5504C81.6268 27.9824 80.3519 26.7076 78.7839 25.9086C78.1322 25.5766 77.1981 25.306 75.3713 25.1568C73.496 25.0036 71.0697 25.0003 67.5007 25.0003H32.5007C28.9316 25.0003 26.5054 25.0036 24.63 25.1568C22.8032 25.306 21.8691 25.5766 21.2174 25.9086C19.6494 26.7076 18.3745 27.9824 17.5756 29.5504C17.2436 30.2021 16.973 31.1363 16.8238 32.963C16.6706 34.8384 16.6673 37.2646 16.6673 40.8337V59.167C16.6673 62.7361 16.6706 65.1623 16.8238 67.0377C16.973 68.8644 17.2436 69.7986 17.5756 70.4502C18.3745 72.0183 19.6494 73.2931 21.2174 74.092C21.8691 74.4241 22.8032 74.6946 24.63 74.8439C26.5054 74.9971 28.9316 75.0003 32.5007 75.0003H50.0007C52.3018 75.0003 54.1673 76.8658 54.1673 79.167C54.1673 81.4682 52.3018 83.3337 50.0007 83.3337H32.3287C28.9746 83.3337 26.2064 83.3338 23.9514 83.1495C21.6093 82.9582 19.4564 82.5475 17.4341 81.5171C14.2981 79.9192 11.7484 77.3695 10.1505 74.2335C9.12017 72.2113 8.70948 70.0583 8.51813 67.7163C8.33389 65.4613 8.33393 62.6931 8.33399 59.3391V40.6616C8.33393 37.3075 8.33389 34.5394 8.51813 32.2844C8.70948 29.9423 9.12017 27.7894 10.1505 25.7672C11.7484 22.6311 14.2981 20.0814 17.4341 18.4836C19.4564 17.4532 21.6093 17.0425 23.9514 16.8511C26.2064 16.6669 28.9745 16.6669 32.3286 16.667Z'
      fill='#CAFC01'
    />
  </svg>
);
const CardError = () => (
  <svg
    className='card-icon'
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='100'
    viewBox='0 0 100 100'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.8864 67.8874C69.5136 66.2602 72.1518 66.2602 73.779 67.8874L79.166 73.2744L84.5531 67.8874C86.1803 66.2602 88.8184 66.2602 90.4456 67.8874C92.0728 69.5146 92.0728 72.1528 90.4456 73.7799L85.0586 79.167L90.4456 84.554C92.0728 86.1812 92.0728 88.8194 90.4456 90.4466C88.8184 92.0738 86.1803 92.0738 84.5531 90.4466L79.166 85.0595L73.779 90.4466C72.1518 92.0738 69.5136 92.0738 67.8864 90.4466C66.2592 88.8194 66.2592 86.1812 67.8864 84.554L73.2735 79.167L67.8864 73.7799C66.2592 72.1528 66.2592 69.5146 67.8864 67.8874Z'
      fill='#FA5656'
    />
    <path
      d='M25 62.4997C25 60.1985 26.8655 58.333 29.1667 58.333H45.8333C48.1345 58.333 50 60.1985 50 62.4997C50 64.8009 48.1345 66.6663 45.8333 66.6663H29.1667C26.8655 66.6663 25 64.8009 25 62.4997Z'
      fill='#FA5656'
    />
    <path d='M8.33398 37.5H91.6673V45.8333H8.33398V37.5Z' fill='#FA5656' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.3286 16.667H67.6727C71.0268 16.6669 73.795 16.6669 76.0499 16.8511C78.392 17.0425 80.5449 17.4532 82.5672 18.4836C85.7032 20.0814 88.2529 22.6311 89.8508 25.7672C90.8811 27.7894 91.2918 29.9423 91.4832 32.2844C91.6674 34.5393 91.6674 37.3075 91.6673 40.6615V54.167C91.6673 56.4682 89.8018 58.3337 87.5007 58.3337C85.1995 58.3337 83.334 56.4682 83.334 54.167V40.8337C83.334 37.2646 83.3307 34.8384 83.1775 32.963C83.0283 31.1363 82.7578 30.2021 82.4257 29.5504C81.6268 27.9824 80.3519 26.7076 78.7839 25.9086C78.1322 25.5766 77.1981 25.306 75.3713 25.1568C73.496 25.0036 71.0697 25.0003 67.5007 25.0003H32.5007C28.9316 25.0003 26.5054 25.0036 24.63 25.1568C22.8032 25.306 21.8691 25.5766 21.2174 25.9086C19.6494 26.7076 18.3745 27.9824 17.5756 29.5504C17.2436 30.2021 16.973 31.1363 16.8238 32.963C16.6706 34.8384 16.6673 37.2646 16.6673 40.8337V59.167C16.6673 62.7361 16.6706 65.1623 16.8238 67.0377C16.973 68.8644 17.2436 69.7986 17.5756 70.4502C18.3745 72.0183 19.6494 73.2931 21.2174 74.092C21.8691 74.4241 22.8032 74.6946 24.63 74.8439C26.5054 74.9971 28.9316 75.0003 32.5007 75.0003H54.1673C56.4685 75.0003 58.334 76.8658 58.334 79.167C58.334 81.4682 56.4685 83.3337 54.1673 83.3337H32.3287C28.9746 83.3337 26.2064 83.3338 23.9514 83.1495C21.6093 82.9582 19.4564 82.5475 17.4341 81.5171C14.2981 79.9192 11.7484 77.3695 10.1505 74.2335C9.12017 72.2113 8.70948 70.0583 8.51813 67.7163C8.33389 65.4613 8.33393 62.6931 8.33399 59.3391V40.6616C8.33393 37.3075 8.33389 34.5394 8.51813 32.2844C8.70948 29.9423 9.12017 27.7894 10.1505 25.7672C11.7484 22.6311 14.2981 20.0814 17.4341 18.4836C19.4564 17.4532 21.6093 17.0425 23.9514 16.8511C26.2064 16.6669 28.9745 16.6669 32.3286 16.667Z'
      fill='#FA5656'
    />
  </svg>
);

const PaymentCard: React.FC<PaymentCardProps> = ({
  title,
  description,
  theme,
  link,
  buttonLabel,
  action,
  ...props
}) => {
  const isSuccess = theme === 'success';
  const history = useHistory();

  const screenType = useScreenType();

  return (
    <S.Wrapper $theme={theme}>
      <RoundedCard {...props}>
        {screenType.isMobile ? (
          <S.CardHeader>
            {isSuccess ? <CardSuccess /> : <CardError />}
            <TitleH3 $color={isSuccess ? 'brandPrimary' : 'supportError'}>
              {title}
            </TitleH3>
          </S.CardHeader>
        ) : isSuccess ? (
          <CardSuccess />
        ) : (
          <CardError />
        )}

        <S.InfoContainer>
          {!screenType.isMobile && (
            <TitleH3 $color={isSuccess ? 'brandPrimary' : 'supportError'}>
              {title}
            </TitleH3>
          )}
          <BodyM>{description}</BodyM>
          {link && (
            <FilledButtonWithIcon
              isUpper
              onClick={() => history.push(link)}
              color={isSuccess ? 'primary' : 'white-dark'}
            >
              {buttonLabel}
            </FilledButtonWithIcon>
          )}
          {action && (
            <FilledButtonWithIcon
              onClick={action}
              isUpper
              color={isSuccess ? 'primary' : 'white-dark'}
            >
              {buttonLabel}
            </FilledButtonWithIcon>
          )}
        </S.InfoContainer>
      </RoundedCard>
    </S.Wrapper>
  );
};

export default PaymentCard;
