import React, { FunctionComponent, useMemo } from 'react';
import { COLORS } from 'styles/v3/variables';
import { BracketMatchDTO } from 'models/Bracket/BracketModel';
import { MatchV3Model } from 'models/Match/MatchModel';
import { BracketRenderProps } from '../../BracketRender';
import { BracketColumn } from '../../styles';
import {
  BracketGame,
  EmptyBracketGame,
  SingleItemBracketGame,
} from '../BracketGame';

export const SingleElimination5Render: FunctionComponent<BracketRenderProps> = ({
  matches,
  bracketId,
  handleOpenEditModal,
  onUpdate,
}) => {
  const [column1, column2, column3] = useMemo(() => {
    const columns: MatchV3Model[][] = [[], [], []];

    matches.forEach((match) => {
      if (match.bracketMatchCount === 1) {
        columns[0].push(match);
      } else if (
        match.bracketMatchCount === 2 ||
        match.bracketMatchCount === 3
      ) {
        columns[1].push(match);
      } else if (match.bracketMatchCount === 4) {
        columns[2].push(match);
      }
    });
    return columns;
  }, [matches]);

  return (
    <>
      <BracketColumn>
        {/* TODO: Change to pass the source of columns[1][0].opponent1 */}
        <SingleItemBracketGame
          title='Team'
          to={{
            down: true,
            color: COLORS.brandPrimary,
          }}
        />
        <BracketGame
          bracketId={bracketId}
          match={column1[0]}
          to={{
            color: COLORS.brandPrimary,
            up: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          showPens
          titleSourceEmpty='Select Source'
          onUpdate={onUpdate}
        />
        <EmptyBracketGame />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={column2[1]}
          to={{
            color: COLORS.brandPrimary,
            down: true,
          }}
          from={{
            up: true,
            down: true,
            color: COLORS.brandPrimary,
          }}
          handleOpenEditModal={handleOpenEditModal}
          titleSourceEmpty='Select Source'
          showPens
          onUpdate={onUpdate}
        />
        <BracketGame
          bracketId={bracketId}
          match={column2[0]}
          to={{
            color: COLORS.brandPrimary,
            up: true,
          }}
          handleOpenEditModal={handleOpenEditModal}
          onUpdate={onUpdate}
          showPens
        />
      </BracketColumn>
      <BracketColumn>
        <BracketGame
          bracketId={bracketId}
          match={column3[0]}
          from={{
            up: true,
            down: true,
            color: COLORS.brandPrimary,
          }}
          to={{
            color: COLORS.brandPrimary,
            side: true,
          }}
          onUpdate={onUpdate}
          handleOpenEditModal={handleOpenEditModal}
        />
      </BracketColumn>
      <BracketColumn>
        <SingleItemBracketGame
          title='Winner'
          from={{ side: true, color: COLORS.brandPrimary }}
        />
      </BracketColumn>
    </>
  );
};
