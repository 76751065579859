import React from 'react';

import { BodyS } from 'styles/v3/variables';

import { DivisionButton } from './components/DivisionButton';
import { useGroups } from '../../../../context/GroupsContext';
import * as S from './styles';
import { getColorByNumberOfTeam } from '../../../../utils';

export const MyDivisions = () => {
  const { divisions, countAllTeams } = useGroups();

  return (
    <S.Container>
      {divisions && divisions.length > 0 && (
        <>
          <BodyS $isUpper $color='grey500'>
            Your age divisions
          </BodyS>

          {divisions.map((item) => {
            const targetColor = getColorByNumberOfTeam(
              countAllTeams,
              item?.targetNumberOfTeams
            );

            return (
              <DivisionButton
                key={item.id}
                targetColor={targetColor}
                {...item}
              />
            );
          })}
        </>
      )}
    </S.Container>
  );
};
