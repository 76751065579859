import React, { FC, memo } from 'react';

import { IDetailRoster } from 'services/v3/Match/interfaces';

import ConvertUtil from 'util/ConvertUtil';

import { BodyLBold } from 'styles/v3/variables';

import * as S from './styles';

interface IScoresheetTeamCardProps {
  team: Maybe<IDetailRoster>;
  venue: string;
}

export const ScoresheetTeamCard: FC<IScoresheetTeamCardProps> = memo(
  ({ team, venue }) => {
    return (
      <S.Wrapper>
        <S.Avatar>
          {team && (
            <img src={ConvertUtil.getMinioUrl(team.logo)} alt={team.name} />
          )}
        </S.Avatar>
        <S.Content>
          <BodyLBold>{team?.name || 'TBA'}</BodyLBold>
          <S.Text>({venue})</S.Text>
        </S.Content>
      </S.Wrapper>
    );
  }
);

ScoresheetTeamCard.displayName = 'ScoresheetTeamCard';
