import React from 'react';

import { BodyLBold, BodyM } from 'styles/v3/variables';
import { CloseSmall } from '@icon-park/react';
import * as S from './styles';
import { PrintItem } from '../PrintItem/PrintItem';
import { DefaultContainerProps } from './types';

export const DefaultContainer = ({
  printItems,
  handleCloseModal,
  handleSelectType,
  currentEvent,
}: DefaultContainerProps) => {
  return (
    <S.Container>
      <S.HeaderWrapper>
        <BodyLBold>What do you want to print?</BodyLBold>
        <CloseSmall
          onClick={handleCloseModal}
          className='closeButton'
          size='24px'
        />
      </S.HeaderWrapper>
      <S.HR />
      <S.BodyWrapper>
        <BodyM className='title'>Select what do you want to print</BodyM>
        {printItems.map((item) => (
          <PrintItem
            handleSelectType={() => handleSelectType(item.type)}
            key={item.title}
            title={item.title}
            description={item.description}
            type={item.type}
            event={currentEvent}
          />
        ))}
      </S.BodyWrapper>
    </S.Container>
  );
};
